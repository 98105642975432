import { Grid } from '@mui/material'
import { AppBox, AppButton, AppDialog, AppTypography, Authorize, TextWithToolTip } from 'components'
import { useAuthorizeHelper, useMembershipHelper, useRolesHelper } from 'hooks'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { AuthSliceSelector } from 'store/slices'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'
import { AddSubUserDialog } from '../add-user-dialog/AddSubUserDialog'
import { ChangeMemberShipStepperDialog } from '../change-membership-dialog/ChangeMembershipStepperDialog'
import { timestampToDate } from 'globals/functions'

export const AccountOwnerBillingCard = () => {
  const { DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)
  const { IsPaidMember } = useMembershipHelper()
  const { IsTrialUser } = useRolesHelper()
  const { enqueueSnackbar } = useSnackbar()
  const { ActiveMembership } = useMembershipHelper()
  const { isAuthorized } = useAuthorizeHelper()

  const { CurrentUser, TrialExpiry, Roles, Memberships } = useSelector(AuthSliceSelector)

  return (
    <>
      <AppBox border={2} borderColor={'grey.200'} borderRadius={6} padding={2} backgroundColor="white">
        <AppBox display={'flex'} flexDirection={'column'} gap={2}>
          <AppBox paddingBottom={1} borderBottom={1} borderColor={'grey.100'}>
            <TextWithToolTip
              tooltipText={
                <AppTypography>
                  As the <b>Account Owner</b> you are the only person that can make changes to your plan and add users."
                </AppTypography>
              }
            >
              <AppTypography color={'grey.900'} fontSize={16} fontWeight={700}>
                Account Owner
              </AppTypography>
            </TextWithToolTip>
          </AppBox>
          <AppBox>
            <Grid container spacing={2} paddingBottom={2}>
              <Grid item xs={8}>
                {!!TrialExpiry}
                {IsTrialUser() ? (
                  <AppTypography color={'grey.500'} fontSize={14}>
                    You are on a 7 day trial period, ending on {timestampToDate(TrialExpiry!)}
                  </AppTypography>
                ) : (
                  <>{JSON.stringify(Roles)}</>
                )}
              </Grid>
              <Grid item xs={4}>
                <Authorize hiddenFromSubAccounts={true}>
                  <AppBox display={'flex'} gap={2}>
                    <AppDialog
                      hideCancelButton={true}
                      hideFooterCloseBtn={true}
                      hideTitleBottomBorder={true}
                      title="Manage Your ParkSitesIO Subscription Plan"
                      maxWidth="md"
                      titleProps={{ fontSize: 20, fontWeight: 400, textAlign: 'center' }}
                      renderChild={(closeDialog, isOpen) => (
                        <>{isOpen && <ChangeMemberShipStepperDialog closeDialog={closeDialog} />}</>
                      )}
                      renderElement={(openDialog) => (
                        <>
                          <AppButton size="large" variant="contained" onClick={openDialog}>
                            Change Plan
                          </AppButton>
                        </>
                      )}
                    />
                    <AppDialog
                      hideCancelButton={true}
                      hideTitleBottomBorder={true}
                      stickyCloseBtn={true}
                      hideFooterCloseBtn={true}
                      closeIconProps={{ size: 'medium' }}
                      title="The more users you include in your plan the bigger your discount!"
                      maxWidth="md"
                      titleProps={{ fontSize: 32, fontWeight: 700, textAlign: 'center', paddingX: 12 }}
                      renderChild={(closeDialog) => <AddSubUserDialog closeDialog={closeDialog} />}
                      renderElement={(openDialog) => (
                        <>
                          <AppButton
                            size="large"
                            variant="contained"
                            onClick={openDialog}
                            disabled={!isAuthorized({ allowedToPaidUsersAndAdmin: true })}
                          >
                            Add Users
                          </AppButton>
                        </>
                      )}
                    />
                  </AppBox>
                </Authorize>
              </Grid>
            </Grid>
            <Authorize allowedToPaidUsers={true} hiddenFromTrialUsers={true}>
              <AppBox paddingY={2} borderBottom={1} borderColor={'grey.200'}>
                <AppBox display={'flex'} gap={2}>
                  <AppBox borderRadius={50} height={'10px'} width={'10px'} backgroundColor={'#2563EB'} />
                  {/* <AppTypography>
                    Your plan is scheduled to renew on {ActiveMembership()?.CurrentPeriodEnd?.toString() ?? ''}
                  </AppTypography> */}
                </AppBox>
                <AppBox display={'flex'} gap={2}>
                  <AppBox borderRadius={12} minWidth={10} minHeight={10} color={'primary.100'}></AppBox>
                </AppBox>
              </AppBox>
            </Authorize>
          </AppBox>
        </AppBox>
      </AppBox>
    </>
  )
}
