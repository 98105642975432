import { Grid, Typography } from '@mui/material'
import { AppBox } from 'components'
import { AppDatePicker } from 'components/base/date-picker/AppDatePicker'
import { AppTextArea } from 'components/base/text-area/AppTextArea'
import dayjs from 'dayjs'

interface Props {
  control: any
}
export const AddEditNoteForm = ({ control }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <AppDatePicker
          required="This field is required."
          label="Date"
          control={control}
          name="Date"
          defaultValue={dayjs(new Date())}
          LabelProps={{ fontWeight: 'bold' }}
        />
      </Grid>
      <Grid item md={12}>
        <AppTextArea
          required="This field is required."
          label="Note"
          placeholder="Enter Note"
          control={control}
          name="Note"
          LabelProps={{ fontWeight: 'bold' }}
        />
      </Grid>
    </Grid>
  )
}
