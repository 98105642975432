import { AppTypography, ProfileInfoCardWithLinks, profilelink, TextWithToolTip } from 'components'
import { ApiQueryKeys, ProfileModules, SideBarType } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { ProfileImagePayload, SessionModel, UserApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { AuthSliceSelector, setSession } from 'store/slices'
import { setOpenedProfileModule } from 'store/slices/profile-module.slice'
const data: profilelink[] = [
  {
    id: ProfileModules.Profile,
    name: ProfileModules.Profile,
    icon: 'iconamoon:profile-circle-duotone',
    allowedToPaidUsers: false,
    className: 'profile-tour-step1',
  },
  {
    id: ProfileModules.Billing,
    name: 'Billing & Plans',
    icon: 'fluent-mdl2:tab-center',
    hiddenFromSubAccounts: true,
    className: 'profile-tour-step2',
  },
  {
    id: ProfileModules.ChangePassword,
    name: ProfileModules.ChangePassword,
    icon: 'mdi:lock-open-outline',
    className: 'profile-tour-step3',
  },
  {
    id: ProfileModules.OutsideTeam,
    name: ProfileModules.OutsideTeam,
    icon: 'bi:people',
    hiddenFromSubAccounts: true,
    className: 'profile-tour-step4',
  },
  {
    id: ProfileModules.ZapierIntegration,
    name: ProfileModules.ZapierIntegration,
    icon: 'tabler:brand-zapier',
    hiddenFromSubAccounts: true,
    className: 'profile-tour-step5',
  },
  {
    id: ProfileModules.AccountRecovery,
    name: ProfileModules.AccountRecovery,
    icon: 'material-symbols:account-box',
    className: 'profile-tour-step6',
  },
]
export const ProfileSideBar = () => {
  const userApi = new UserApi()
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const onLinkClick = (key: profilelink) => {
    dispatch(setOpenedProfileModule(key.id))
  }

  const handleImageSave = (src: string) => {
    const copy: SessionModel = JSON.parse(JSON.stringify(CurrentUser))
    if (src) {
      copy.ProfileImage = src ?? ''
      dispatch(setSession(copy))
    }
    enqueueSnackbar('Profile image updated successfully', { variant: 'success' })
  }
  const handleRemoveImage = () => {
    const copy: SessionModel = JSON.parse(JSON.stringify(CurrentUser))
    copy.ProfileImage = ''
    dispatch(setSession(copy))
    enqueueSnackbar('Profile image removed successfully', { variant: 'success' })
  }

  return (
    <ProfileInfoCardWithLinks
      links={data}
      sideBarType={SideBarType.Profile}
      onLinkClick={onLinkClick}
      principalId={CurrentUser?.UserId!}
      imageSrc={CurrentUser?.ProfileImage}
      defaultImage="/images/profile-image.png"
      onImageSave={handleImageSave}
      uploadImageFn={(data: ProfileImagePayload) => userApi.updateUserProfileImage(data)}
      uploadImageFnKey={ApiQueryKeys.user.updateUserProfileImage}
      removeImageFn={() => userApi.removeUserProfileImage()}
      removeImageFnKey={ApiQueryKeys.user.removeUserProfileImage}
      onRemoveImage={handleRemoveImage}
      contentInfo={
        <>
          <AppTypography fontWeight={700} fontSize={18}>
            {CurrentUser?.FirstName + ' ' + CurrentUser?.LastName}
          </AppTypography>
          <TextWithToolTip
            children={
              <AppTypography fontWeight={500} fontSize={14} color={'grey.800'}>
                User ID: <b>{CurrentUser?.AccountId}</b>
              </AppTypography>
            }
            tooltipText={
              <>
                Your <b>User ID</b> is a unique to your account and is required to provided during support calls.
              </>
            }
          />
        </>
      }
    />
  )
}
