import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TeamViewModel } from 'sdk'
import { IAppStore } from 'store/app.store'

export interface TeamState {
  UserTeams: TeamViewModel[]
  OwnTeam: TeamViewModel | null
  SelectedTeam: TeamViewModel | null
  isChangingTeam: boolean
}
const initialState: TeamState = {
  UserTeams: [],
  OwnTeam: null,
  SelectedTeam: null,
  isChangingTeam: false,
}

export const TeamSlice = createSlice({
  name: 'Team',
  initialState: initialState,
  reducers: {
    setUserTeams: (state: TeamState, action: PayloadAction<TeamViewModel[]>) => {
      state.UserTeams = action.payload
    },
    setOwnTeam: (state: TeamState, action: PayloadAction<TeamViewModel>) => {
      state.OwnTeam = action.payload
    },
    setSelectedTeam: (state: TeamState, action: PayloadAction<TeamViewModel>) => {
      state.SelectedTeam = action.payload
    },
    setIsChangingTeam: (state: TeamState, action: PayloadAction<boolean>) => {
      state.isChangingTeam = action.payload
    },
    clearTeamData: (state: TeamState) => {
      state.OwnTeam = initialState.OwnTeam
      state.SelectedTeam = initialState.SelectedTeam
      state.UserTeams = initialState.UserTeams
      state.isChangingTeam = initialState.isChangingTeam
    },
  },
})

export const { setUserTeams, setOwnTeam, setSelectedTeam, setIsChangingTeam, clearTeamData } = TeamSlice.actions
export const TeamSliceSelector = (appState: IAppStore): TeamState => appState.Team
