import { apiUrls } from '../../../api-urls'
import { ParkListTab, UserLabelledParkPayloadModel, UserParkLabelViewModel } from '../../../models'
import { UserParkLabelPayloadModel } from '../../../models/ParkSites/user-parksites/park-label/user-park-label-payload'
import { BaseApi } from '../../base.api'

const baseApi = new BaseApi()
export class ParkLabelApi {
  public AddParkLabel(parkLabel: UserParkLabelPayloadModel) {
    return baseApi.POST_Request<UserParkLabelViewModel>(apiUrls.parksites.parkLabels.addParkLabel(), parkLabel)
  }
  public UpdateParkLabel(parkId: string, parkLabel: UserParkLabelPayloadModel) {
    return baseApi.PUT_Request<UserParkLabelViewModel>(apiUrls.parksites.parkLabels.editParkLabel(parkId), parkLabel)
  }
  public DeleteParkLabel(labelId: string) {
    return baseApi.DELETE_Request<string>(apiUrls.parksites.parkLabels.deleteParkLabel(labelId))
  }
  public GetUserParkLabels() {
    return baseApi.GET_Request<Array<UserParkLabelViewModel>>(apiUrls.parksites.parkLabels.getUserParkLabels())
  }
  public AddLabelledPark(newLabelledPark: UserLabelledParkPayloadModel) {
    return baseApi.POST_Request<UserParkLabelViewModel>(
      apiUrls.parksites.parkLabels.addUserLabelledPark(),
      newLabelledPark
    )
  }
  public GetUserLabelledParks(query: string) {
    return baseApi.GET_Request<Array<ParkListTab>>(apiUrls.parksites.parkLabels.getUserLabelledParks(query))
  }
  public GetLabelledParksByLabelLabelId(id: string) {
    return baseApi.GET_Request<Array<ParkListTab>>(apiUrls.parksites.parkLabels.GetLabelledParksByLabelLabelId(id))
  }
  public RemoveUserLabelledPark(id: string) {
    return baseApi.DELETE_Request<string>(apiUrls.parksites.parkLabels.removeUserLabelledPark(id))
  }
  public RemoveUserLabelledParkByParkId(parkId: string) {
    return baseApi.DELETE_Request<string>(apiUrls.parksites.parkLabels.removeUserLabelledParkByParkId(parkId))
  }
  public RemoveAllParksFromList() {
    return baseApi.DELETE_Request<boolean>(apiUrls.parksites.parkLabels.removeAllParksFromList())
  }
}
