import { Grid } from '@mui/material'
import { ProfileModules } from 'globals/constants'
import { useSelector } from 'react-redux'
import { ProfileModulesliceSelector } from 'store/slices/profile-module.slice'
import { AccountRecoveryModule } from './profile-modules/AccountRecoveryModule'
import { ChangePasswordModule } from './profile-modules/ChangePasswordModule'
import { PersonalInfoModule } from './profile-modules/PersonalInfoModule'
import { ZapierIntegrationModule } from './profile-modules/ZapierInegrationModule'
import { BillingAndPlansModule } from './profile-modules/billing-and-plan/BillingAndPlansModule'
import { OutsideTeamModule } from './profile-modules/outside-team/OutsideTeamModule'

const ProfileBarsMapping: { [key: string]: { name: string; component: any; column?: number } } = {
  [ProfileModules.Profile]: {
    name: ProfileModules.Profile,
    component: <PersonalInfoModule />,
    column: 10,
  },
  [ProfileModules.Billing]: {
    name: ProfileModules.Billing,
    component: <BillingAndPlansModule />,
  },
  [ProfileModules.ChangePassword]: {
    name: 'Change password',
    component: <ChangePasswordModule />,
    column: 4,
  },
  [ProfileModules.OutsideTeam]: {
    name: 'Outside Team',
    component: <OutsideTeamModule />,
  },
  [ProfileModules.ZapierIntegration]: {
    name: 'Zapier Integration',
    component: <ZapierIntegrationModule />,
  },
  [ProfileModules.AccountRecovery]: {
    name: 'Account Recovery',
    component: <AccountRecoveryModule />,
    column: 4,
  },
}
export const ProfileContent = () => {
  const { openedProfileModule } = useSelector(ProfileModulesliceSelector)

  return (
    <>
      {openedProfileModule && (
        <Grid container>
          <Grid item xs={ProfileBarsMapping[openedProfileModule].column ?? 12}>
            {ProfileBarsMapping[openedProfileModule].component}
          </Grid>
        </Grid>
      )}
    </>
  )
}
