import { Box, BoxProps, Grid, styled } from '@mui/material'
import { AppBox, AppTextField, AppTypography } from 'components'
import { Controller, UseFormWatch } from 'react-hook-form'
import { UserParkLabelPayloadModel } from 'sdk'

interface SwatchProps extends BoxProps {
  color: string
  isactive: boolean
}
const Swatch = styled(Box)(({ ...props }: SwatchProps) => {
  return {
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    backgroundColor: props.color,
    scale: props.isactive ? '0.8' : '1',
    '&:hover': {
      scale: !props.isactive ? '1.1' : '0.8',
    },
  }
})

interface Props {
  control: any
  watch: UseFormWatch<UserParkLabelPayloadModel>
  isEdit: boolean
}
export const AddEditParkPinForm = ({ control, watch, ...props }: Props) => {
  // const [hex, setHex] = useState('#fff')

  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2} padding={2}>
      <AppTypography fontSize={14} color={'grey.500'}>
        Select a unique color and add a label to start applying it to specific parks.
      </AppTypography>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography fontSize={14} fontWeight={'bold'} color={'grey.900'}>
          Select Pin Color
        </AppTypography>
        <AppTypography component={'a'} fontWeight={'500'}>
          More Colors
        </AppTypography>
      </AppBox>
      <Controller
        control={control}
        rules={{ required: 'This field is required.' }}
        name="Color"
        render={({ fieldState: { error }, field }) => {
          return (
            <Grid container spacing={0}>
              {[
                '#000000',
                '#FFFFFF',
                '#EAECED',
                '#D1D3D3',
                '#F6F6F6',
                '#007BFF',
                '#409CFF',
                '#80BDFF',
                '#BFDEFF',
                '#E6F2FF',
                '#09C269',
                '#28A745',
                '#20C997',
                '#58D7B1',
                '#1ABC9C',
                '#765431',
                '#D35400',
                '#FD7E14',
                '#FFD145',
                '#FFC107',
                '#DC3545',
                '#A52834',
                '#E74C3C',
                '#8E44AD',
                '#9B59B6',
              ].map((color) => (
                <Grid xs={2.4} item key={color}>
                  <Swatch
                    color={color}
                    isactive={watch().Color === color}
                    onClick={() => {
                      field.onChange(color)
                    }}
                    {...field}
                  />
                </Grid>
              ))}
              {error?.message && <AppTypography color={'red'}>{error?.message}</AppTypography>}
            </Grid>
          )
        }}
      />
      <AppBox marginTop={2}>
        <AppTextField
          required="This field is required."
          label="Pin Label"
          placeholder="Enter Pin Label"
          control={control}
          name="Label"
          LabelProps={{ fontWeight: 'bold' }}
        />
      </AppBox>
    </AppBox>
  )
}
