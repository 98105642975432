import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components/base'
import { FC } from 'react'

interface Props {}

export const ParkHistoryLoader: FC<Props> = () => {
  return (
    <>
      {Array.from(new Array(5)).map((x, index) => (
        <Grid container paddingX={1} marginTop={2} gap={1} key={index}>
          <Grid item xs={12}>
            <AppBox display={'flex'} flexDirection={'column'} gap={1}>
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={15} />
              <Skeleton variant="rounded" height={15} />
            </AppBox>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
