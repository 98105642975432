import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTextField, AppTypography } from 'components'
import { ApiQueryKeys, FormFiledRulesKeys } from 'globals/constants'
import { centsToDollar, getFormattedDate } from 'globals/functions'
import { useMembershipHelper } from 'hooks'
import { useSnackbar } from 'notistack'
import { Dispatch, FC, SetStateAction } from 'react'
import { Form, useForm } from 'react-hook-form'
import { AccountApi, SubAccountAddedModel } from 'sdk'
import { AddSubAccountStepTwoAddedUsersTable } from './AddSubAccountStepTwoAddedUsersTable'

interface Props {
  tempAddedUsers: Array<SubAccountAddedModel>
  setTempAddedUsers: Dispatch<SetStateAction<SubAccountAddedModel[]>>
}
export const AddSubAccountStepTwoAddUsers: FC<Props> = ({ tempAddedUsers, setTempAddedUsers }) => {
  const accountApi = new AccountApi()
  const { ActiveMembership } = useMembershipHelper()
  const { enqueueSnackbar } = useSnackbar()

  const { control, watch, setError, reset } = useForm<{ Email: string }>()
  const {
    data: oldUser,
    mutate: getUserByEmail,
    isPending: validatingEmail,
  } = useMutation({
    mutationKey: [ApiQueryKeys.account.getUserByEmail],
    mutationFn: (email: string) => accountApi.getUserByEmail(email),
    onSuccess: (data): void => {
      if (!data) {
        setTempAddedUsers((prev) => [
          ...prev,
          {
            Email: watch().Email,
            DateAdded: getFormattedDate(new Date()),
            Type: 'SubAccount',
            BasePrice: centsToDollar(ActiveMembership()?.MembershipPrice ?? 0),
          },
        ])
        reset()
      } else {
        enqueueSnackbar('Email already exists', { variant: 'error' })
        setError('Email', {
          type: FormFiledRulesKeys.EmailExist,
          message: 'Email already exist',
        })
      }
    },
  })

  const handleFormSubmit = (data: { Email: string }) => {
    getUserByEmail(data.Email)
  }
  const removeUser = (Email: string) => {
    const user = tempAddedUsers.find((u) => u.Email === Email)
    if (user) {
      setTempAddedUsers((prev) => prev.filter((u) => u.Email !== Email))
    }
  }
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <AppTypography color={'grey.500'} fontWeight={500} fontSize={14}>
          Please provide us with the email address of the user(s) you wish to add to your plan. This email address will
          be used for the user's initial login. Once they log in using the password we generate, we will guide them
          through the process of completing their profile.
        </AppTypography>
        <Form control={control} onSubmit={({ data }) => handleFormSubmit(data)}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={9} md={5}>
              <AppTextField
                name="Email"
                label="User Email"
                control={control}
                required="This field is required"
                placeholder="Enter Email Address"
                hideDefaultErrorText={false}
                customRules={{
                  Email: true,
                  EmailExist: { optionValue: oldUser?.Email === watch().Email },
                }}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <AppButton
                isLoading={validatingEmail}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                textProps={{ paddingX: 3 }}
              >
                Add
              </AppButton>
            </Grid>
          </Grid>
        </Form>
        <AppBox borderBottom={1} borderColor={'grey.200'} paddingBottom={2}>
          <AppTypography fontWeight={700} fontSize={16}>
            User Being Added
          </AppTypography>
        </AppBox>
        <AppBox>
          <AddSubAccountStepTwoAddedUsersTable data={tempAddedUsers ?? []} removeUser={removeUser} isLoading={false} />
          //need to come back
        </AppBox>
      </AppBox>
    </>
  )
}
