import { AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { ParkDetailViewModel } from 'sdk'

interface Props {
  parkDetail: ParkDetailViewModel
}

export const ParkDetailMHLots: FC<Props> = ({ parkDetail }) => {
  return (
    <AppBox
      backgroundColor="grey.100"
      padding={2}
      borderRadius={3}
      display={'flex'}
      flexDirection={'column'}
      border={1}
      borderColor={'grey.200'}
      gap={1}
      width={'100%'}
    >
      <AppTypography color={'grey.900'} fontWeight={700} fontSize={16}>
        Mobile Home Lots
      </AppTypography>
      <AppBox display={'flex'} alignItems={'center'} gap={1}>
        <AppTypography color={'grey.600'}>Total MH Lots:</AppTypography>
        <AppTypography color={'primary.200'} fontWeight={700} fontSize={14}>
          {parkDetail.ParkFieldsDataObj?.TotalMHLots?.toLocaleString()}
        </AppTypography>
      </AppBox>
      <AppTypography color={'grey.900'} alignSelf={'center'} fontWeight={600}>
        Lot Break Down
      </AppTypography>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography color={'grey.600'}>Park Owned Home Lots:</AppTypography>
        <AppTypography color={'grey.900'} fontWeight={700}>
          {!!parkDetail.ParkFieldsDataObj?.TOHsMHLots && parkDetail.ParkFieldsDataObj?.TOHsMHLots > 0
            ? parkDetail.ParkFieldsDataObj?.TOHsMHLots?.toLocaleString()
            : '-'}
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography color={'grey.600'}>Tenant Owned Home Lots:</AppTypography>
        <AppTypography color={'grey.900'} fontWeight={700}>
          {!!parkDetail.ParkFieldsDataObj?.POHsMHLots && parkDetail.ParkFieldsDataObj?.POHsMHLots > 0
            ? parkDetail.ParkFieldsDataObj?.POHsMHLots?.toLocaleString()
            : '-'}
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography color={'grey.600'}>Vacant Lots:</AppTypography>
        <AppTypography color={'grey.900'} fontWeight={700}>
          {!!parkDetail.ParkFieldsDataObj?.DevelopedMHLots && parkDetail.ParkFieldsDataObj?.DevelopedMHLots > 0
            ? parkDetail.ParkFieldsDataObj?.DevelopedMHLots?.toLocaleString()
            : '-'}
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography color={'grey.600'}>Underdeveloped Lots:</AppTypography>
        <AppTypography color={'grey.900'} fontWeight={700}>
          {!!parkDetail.ParkFieldsDataObj?.UnDevelopedMHLots && parkDetail.ParkFieldsDataObj?.UnDevelopedMHLots > 0
            ? parkDetail.ParkFieldsDataObj?.UnDevelopedMHLots?.toLocaleString()
            : '-'}
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
