import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, ParkSitesTools } from 'globals/constants'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParksApi, ParkLabelApi, ShowAlert, UserTaggedParkApi } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

export const useRemoveAllParksFromList = (refetch: () => void) => {
  const parkLabelApi = new ParkLabelApi()
  const taggedParksApi = new UserTaggedParkApi()
  const contactLinkedParksApi = new CrmContactLinkedParksApi()

  const { openedTool } = useSelector(ParkSiteToolSliceSelector)
  const getQueryFn = () => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return parkLabelApi.RemoveAllParksFromList()
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return taggedParksApi.RemoveAllTaggedParks()
    } else if (openedTool === ParkSitesTools.CRM) {
      return contactLinkedParksApi.UnlinkAllContactsFromAllPark()
    }
    return parkLabelApi.RemoveAllParksFromList()
  }
  const getQueryKey = () => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return [ApiQueryKeys.parksites.labelledParks.RemoveAllLabelledParksFromList]
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return [ApiQueryKeys.parksites.taggedParks.RemoveAllTaggedParks]
    }
    return ['']
  }

  const { isPending, mutate } = useMutation({
    mutationKey: getQueryKey(),
    onSuccess: () => refetch(),
    mutationFn: () => getQueryFn(),
  })

  const handleRemoveAllParksFromList = (count: number) => {
    let message = ''
    if (openedTool === ParkSitesTools.PinnedParks) {
      message = `Are you sure you wish to reset all ${count} park pin colors?`
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      message = `Are you sure you want to remove all ${count} tagged parks?`
    } else if (openedTool === ParkSitesTools.CRM) {
      message = `Are you sure you wish to detach all ${count} Parks from your CRM records?`
    }
    ShowAlert('question', message).then((result) => {
      if (result.isConfirmed) {
        mutate()
      }
    })
  }

  return {
    handleRemoveAllParks: handleRemoveAllParksFromList,
    isRemovingAllParksFromList: isPending,
  }
}
