import { AppBox } from 'components'
import { FC } from 'react'

interface Props {}

export const ManageAccountSecurityIPInfoCard: FC<Props> = () => {
  return (
    <AppBox backgroundColor="grey.100" border={1} borderColor={'grey.200'} borderRadius={6} padding={2}>
      Security IP Info
    </AppBox>
  )
}
