import { Grid } from '@mui/material'
import { AppDialog, AppTextField, StateField, ZipField } from 'components'
import { useChangeParkLocation } from 'hooks'
import { forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { GoogleMapSliceSelector } from 'store/slices'

export type ChangeLocationDialogRef = {
  onDragEndCallback?: (marker: any, position: google.maps.LatLng) => void
}

type Props = {
  onDragEndCallback?: (marker: any, position: google.maps.LatLng) => void
}

export const ParkSitesGoogleMapChangeLocationDialog = forwardRef<ChangeLocationDialogRef, Props>(
  ({ onDragEndCallback }: Props, ref) => {
    const { SelectedParkIdToMove } = useSelector(GoogleMapSliceSelector)
    const { handleSave, handleSubmit, setValue, watch, reset, control, isChangingLocation, dialogRef } =
      useChangeParkLocation()
    useImperativeHandle(ref, () => ({
      onDragEndCallback: (marker, position) => {
        reset()
        setValue('Lat', position.lat())
        setValue('Lng', position.lng())
        setValue('ParkId', SelectedParkIdToMove!)
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ location: position }, (results, status) => {
          if (status === 'OK' && results && results[0]) {
            if (status === 'OK' && results && results[0]) {
              const myadd = results[0].address_components
              myadd.filter((x) => {
                x.types.find((y) => {
                  switch (y) {
                    case 'street_number':
                      setValue('Address', x.long_name)
                      break
                    case 'route':
                      setValue('Address', `${watch().Address} ${x.long_name}`)
                      break
                    case 'country':
                      setValue('Country', x.long_name)
                      break
                    case 'locality':
                      setValue('City', x.long_name)
                      break
                    case 'administrative_area_level_2':
                      setValue('County', x.long_name)
                      break
                    case 'administrative_area_level_1':
                      setValue('State', x.long_name)
                      break
                    case 'postal_code':
                      setValue('Zip', x.long_name)
                      break
                  }
                })
              })
            }
          }
        })
        dialogRef.current?.openDialog!()
      },
    }))

    return (
      <AppDialog
        ref={dialogRef}
        maxWidth="md"
        title="Admin - Change Park Location"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(handleSave),
        }}
        submitButtonProps={{ isLoading: isChangingLocation, children: 'Submit' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppTextField control={control} name="Lat" disabled label="Latitude" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} name="Lng" disabled label="Longitude" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} required name="Address" label="Address" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} required name="City" label="City" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} required name="County" label="County" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} required name="State" label="State" />
          </Grid>
          <Grid item xs={6}>
            <ZipField control={control} required name="Zip" label="Zip" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} required name="Country" label="Country" />
          </Grid>
        </Grid>
      </AppDialog>
    )
  }
)
