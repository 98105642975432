import { apiUrls } from '../../../api-urls'
import { ParkNotePayload, ParkNoteViewModel } from '../../../models'
import { BaseApi } from '../../base.api'

const baseApi = new BaseApi()
export class ParkNoteApi {
  public AddParkNote(parkNote: ParkNotePayload) {
    return baseApi.POST_Request<ParkNoteViewModel>(apiUrls.parksites.parkNotes.addParkNote(), parkNote)
  }
  public EditParkNote(parkNote: ParkNotePayload) {
    return baseApi.PUT_Request<ParkNoteViewModel>(apiUrls.parksites.parkNotes.editParkNote(parkNote.Id!), parkNote)
  }
  public DeleteParkNoteById(parkNoteId: string) {
    return baseApi.DELETE_Request(apiUrls.parksites.parkNotes.deleteParkNote(parkNoteId))
  }
  public GetUserParkNotes(parkId: string, query: string) {
    return baseApi.GET_Request<Array<ParkNoteViewModel>>(apiUrls.parksites.parkNotes.getParkNotes(parkId, query))
  }
}
