import { IconButton } from '@mui/material'
import {
  AppBox,
  AppButton,
  AppDialog,
  AppIcon,
  AppMenu,
  AppTypography,
  BaseComponent,
  CrmContactCompaniesHelperLoader,
  InfoBox,
  PhoneText,
} from 'components'
import { useCrmCompanyContacts, useCrmContacts } from 'hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TeamSliceSelector } from 'store/slices'
import { AddUpdateCrmContactForm } from 'views'
import { CrmContactLinkedParkSites } from 'views/account/crm/content/contacts/contact-details/linked-parksites/CrmContactLinkedParksites'

interface Props {
  companyId: string
}

export const CrmCompanyContactsHelperModule = (props: Props) => {
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const {
    control,
    handleSubmit,
    setError,
    watch,
    dialogRef,
    isAddingContact,
    isEditing,
    handleFormSubmit,
    contactToUpdate,
    handleEditContact,
  } = useCrmContacts({})

  const { getCompanyLinkedContacts, isGettingCompanyContacts, companyLinkedContacts } = useCrmCompanyContacts()
  useEffect(() => {
    getCompanyLinkedContacts(props.companyId)
  }, [getCompanyLinkedContacts, SelectedTeam?.Id, props.companyId])
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <InfoBox>
          When you associate one or more companies to a contact the list of companies will begin to display here for
          that contact. Dismiss
        </InfoBox>
        <BaseComponent
          isLoading={isGettingCompanyContacts}
          isData={!!companyLinkedContacts?.Data}
          loaderComponent={
            <>
              <CrmContactCompaniesHelperLoader />
            </>
          }
        >
          <>
            <AppTypography fontSize={14} fontWeight={500}>
              Companies({companyLinkedContacts?.TotalCount})
            </AppTypography>

            {companyLinkedContacts?.Data?.map((contact) => (
              <AppBox
                backgroundColor="grey.50"
                borderColor={'grey.200'}
                border="1px"
                padding={2}
                borderRadius={3}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <AppBox>
                  <AppTypography color={'grey.900'} fontSize={14} fontWeight={600}>
                    {contact.ContactFirstName + ' ' + contact.ContactLastName}
                  </AppTypography>
                  <AppTypography fontWeight={500} color={'grey.500'}>
                    {contact.ContactAddress}
                  </AppTypography>
                  <AppBox display={'flex'} gap={2}>
                    <PhoneText phone={contact.ContactPhone ?? ''} />
                    <AppBox
                      width={8}
                      height={8}
                      backgroundColor={'secondary.600'}
                      borderRadius={1}
                      alignSelf={'center'}
                    />
                    <PhoneText phone={contact.ContactPhone ?? ''} />
                  </AppBox>
                  <AppTypography color={'grey.500'}>Updated By: </AppTypography>
                  <AppBox display={'flex'} justifyContent={'space-between'}>
                    <AppDialog
                      maxWidth="md"
                      renderElement={(openDialog) => (
                        <AppButton
                          color="primary"
                          size="large"
                          prefixIcon="gis:home"
                          prefixIconProps={{ size: 'extra-small' }}
                          onClick={openDialog}
                        >
                          <AppTypography fontSize={12}>View Parks</AppTypography>
                        </AppButton>
                      )}
                      renderChild={(openDialog) => (
                        <AppBox width={600}>
                          <CrmContactLinkedParkSites contactId={contact.Id!} isLinkedOnly={true} />
                        </AppBox>
                      )}
                    ></AppDialog>
                    <AppDialog
                      maxWidth="md"
                      renderElement={() => (
                        <AppButton
                          color="primary"
                          size="large"
                          prefixIcon="ph:note-pencil-bold"
                          prefixIconProps={{ size: 'extra-small' }}
                          onClick={() => handleEditContact(contact)}
                        >
                          <AppTypography fontSize={14}>Edit Contact</AppTypography>
                        </AppButton>
                      )}
                    ></AppDialog>
                  </AppBox>
                </AppBox>
                <AppBox>
                  <AppMenu
                    renderElement={(handleClick) => (
                      <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                        <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                      </IconButton>
                    )}
                    MenuItems={[{ text: 'Remove' }]}
                  />
                </AppBox>
              </AppBox>
            ))}
          </>
        </BaseComponent>
      </AppBox>
      <AppDialog
        ref={dialogRef}
        title={!!contactToUpdate ? 'Edit Contact' : 'Add New Contact'}
        maxWidth="md"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(handleFormSubmit),
        }}
        submitButtonProps={{ isLoading: isAddingContact || isEditing }}
      >
        <AddUpdateCrmContactForm control={control} setError={setError} watch={watch} originalData={contactToUpdate} />
      </AppDialog>
    </>
  )
}
