import { Autocomplete, Grid } from '@mui/material'
import { AppBox, AppTextField, AppTypography, SimpleField } from 'components'
import { FC, useEffect, useState } from 'react'
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { ParkUpdatePayload, ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ParkUpdatePayload> | Control<ReportParkPayload>
  getValue: UseFormGetValues<ParkUpdatePayload> | UseFormGetValues<ReportParkPayload>
  setValue: UseFormSetValue<ParkUpdatePayload> | UseFormSetValue<ReportParkPayload>
  watch: UseFormWatch<ParkUpdatePayload> | UseFormWatch<ReportParkPayload>
}

export const UpdateParkRVLotCard: FC<Props> = ({ control, setValue, getValue, watch }) => {
  const [selectedSurfaceTypes, setSelectedSurfaceTypes] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    if (watch().ParkFieldsDataObj.RVLotSurfaceTypeGrass) {
      setSelectedSurfaceTypes((prev) => [...prev, { label: 'Grass', value: 'True' }])
    }
    if (watch().ParkFieldsDataObj.RVLotSurfaceTypeGravel) {
      setSelectedSurfaceTypes((prev) => [...prev, { label: 'Gravel', value: 'True' }])
    }
    if (watch().ParkFieldsDataObj.RVLotSurfaceTypeConcrete) {
      setSelectedSurfaceTypes((prev) => [...prev, { label: 'Concrete', value: 'True' }])
    }
    if (watch().ParkFieldsDataObj.RVLotSurfaceTypeAsphalt) {
      setSelectedSurfaceTypes((prev) => [...prev, { label: 'Asphalt', value: 'True' }])
    }
  }, [])

  return (
    <>
      <Grid
        item
        xs={3.86}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        borderRadius={3}
        border={1}
        borderColor={'grey.200'}
        paddingX={2}
        paddingY={1}
        sx={{ backgroundColor: 'grey.50' }}
      >
        <AppTypography fontWeight={'bold'} fontSize={16}>
          RV Lots
        </AppTypography>
        <AppBox display={'flex'} alignItems={'center'}>
          <AppTypography component={'span'} fontWeight={400}>
            Total RV Lots{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              required
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              fullWidth={false}
              name="ParkFieldsDataObj.TotalRVLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox textAlign={'center'}>
          <AppTypography fontWeight={'bold'}>Lot Break Down</AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            In Use:
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.UnDevelopedRVLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Vacant Lots:
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.DevelopedRVLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppTypography component={'span'} fontWeight={400}>
          Lot Surface Type:
        </AppTypography>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={['Gravel', 'Concrete', 'Asphalt', 'Grass'].map((x) => {
              return { label: x, value: 'True' }
            })}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            value={selectedSurfaceTypes}
            onChange={(event, value) => {
              setSelectedSurfaceTypes(value)
              ;['Gravel', 'Concrete', 'Asphalt', 'Grass'].forEach((x) => {
                if (value.find((y) => y.label === x)) {
                  console.log('ParkFieldsDataObj.RVLotSurfaceType$', x)
                  ;(setValue as any)(`ParkFieldsDataObj.RVLotSurfaceType${x}`, 'True')
                } else {
                  ;(setValue as any)(`ParkFieldsDataObj.RVLotSurfaceType${x}`, 'False')
                }
              })
            }}
            renderInput={(params) => (
              <SimpleField
                {...params}
                style={{ marginTop: 0 }}
                required
                hideDefaultErrorText={false}
                ref={params.InputProps.ref}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}
