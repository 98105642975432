import { AppTextField, AppTextFieldProps, SimpleField } from 'components/base'
import { FC } from 'react'

interface Props extends Omit<AppTextFieldProps, 'control' | 'name'> {
  control?: any
  name?: string
}

export const CurrencyField: FC<Props> = ({ control, onChange, name, ...rest }) => {
  return (
    <>
      {control ? (
        <AppTextField control={control} name={name!} {...rest} prefixIcon="uil:dollar-sign-alt" />
      ) : (
        <SimpleField
          {...rest}
          prefixIcon="uil:dollar-sign-alt"
          InputProps={{
            ...rest.InputProps,
          }}
          commaSeparated={true}
          onChange={onChange}
        />
      )}
    </>
  )
}
