import { useMutation, useQuery } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { PaginationFilterModel, PeopleInsightApi, PeopleInsightPayload, PeopleInsightViewModel } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

export const usePeopleInsight = () => {
  const peopleInsightApi = new PeopleInsightApi()

  const { enqueueSnackbar } = useSnackbar()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  const addDialogFormRef = useRef<AppDialogRefType>(null)
  const editDialogFormRef = useRef<AppDialogRefType>(null)
  const {
    control,
    handleSubmit,
    getValues: addGetValue,
    reset,
  } = useForm<PeopleInsightPayload>({
    defaultValues: { ParkId: selectedParkId },
  })
  const {
    control: editControl,
    handleSubmit: editHandleSubmit,
    watch,
    reset: editReset,
  } = useForm<PeopleInsightPayload>({
    defaultValues: { ParkId: selectedParkId },
  })

  const { mutate: AddPeopleInsight, isPending: isAdding } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.AddPeopleInsight],
    mutationFn: (data: PeopleInsightPayload) => peopleInsightApi.AddPeopleInsight(data),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      reset({ ParkId: selectedParkId })
      GetPeopleInsights(selectedParkId)
      enqueueSnackbar('People Insight added successfully', { variant: 'success' })
      addDialogFormRef.current?.closeDialog!()
    },
  })
  const { mutate: LinkPeopleInsightToPark, isPending: isLinking } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.LinkParkPeopleInsight],
    mutationFn: (peopleInsightId: string) => peopleInsightApi.LinkPeopleInsightToPark(peopleInsightId, selectedParkId),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      reset({ ParkId: selectedParkId })
      GetPeopleInsights(selectedParkId)
      enqueueSnackbar('People Insight added successfully', { variant: 'success' })
      addDialogFormRef.current?.closeDialog!()
    },
  })

  const {
    data: possibleInsights,
    mutate: GetPossibleInsights,
    isPending: isGettingPossibleInsights,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.GetPossiblePeopleInsights],
    mutationFn: (data: { queryKey: string; value: string }) =>
      peopleInsightApi.GetPossiblePeopleInsights(selectedParkId, data.queryKey, data.value),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: (data) => {
      if (data.length === 0) {
        enqueueSnackbar('No Insights found', { variant: 'info' })
      }
    },
  })
  const { mutate: DetachPeopleInsight, isPending: isDetaching } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.DetachParkPeopleInsight],
    mutationFn: (peopleId: string) => peopleInsightApi.DetachParkPeopleInsight(selectedParkId, peopleId),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('People Insight detached successfully', { variant: 'success' })
      GetPeopleInsights(selectedParkId)
    },
  })
  const { mutate: EditPeopleInsight, isPending: isEditing } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.AddPeopleInsight],
    mutationFn: (data: PeopleInsightPayload) => peopleInsightApi.EditPeopleInsight(data),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      editReset({ ParkId: selectedParkId })
      enqueueSnackbar('People Insight edited successfully', { variant: 'success' })
      editDialogFormRef.current?.closeDialog!()
      GetPeopleInsights(selectedParkId)
    },
  })
  const {
    data: PeopleInsights,
    isPending: isGetting,
    mutate: GetPeopleInsights,
  } = useMutation({
    mutationFn: (parkId: string) => peopleInsightApi.GetPeopleInsights(parkId, filter),
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.GetParkPeopleInsights],
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  const handleGetPossibleMatches = (queryKey: string) => {
    const key = queryKey.replaceAll(' ', '')
    if (!!!addGetValue(key as any)) {
      enqueueSnackbar(`Please Fill in the ${queryKey} field first`, { variant: 'error' })
      return
    }
    GetPossibleInsights({ queryKey: key, value: addGetValue(key as any) })
  }
  const handleUseClick = (data: PeopleInsightViewModel) => {
    LinkPeopleInsightToPark(data.Id)
  }
  useEffect(() => GetPeopleInsights(selectedParkId), [filter])

  return {
    filter,
    setFilter,
    addDialogFormRef,
    editDialogFormRef,
    control,
    addDialogReset: reset,
    editControl,
    editReset,
    editHandleSubmit,
    AddPeopleInsight,
    isAdding,
    isLinking,
    handleSubmit,
    GetPeopleInsights,
    PeopleInsights,
    isGetting,
    watch,
    DetachPeopleInsight,
    isDetaching,
    EditPeopleInsight,
    isEditing,
    GetPossibleInsights,
    isGettingPossibleInsights,
    possibleInsights,
    handleGetPossibleMatches,
    handleUseClick,
    selectedParkId,
  }
}
