import { CustomRoute } from 'globals/typings'
import flattenDeep from 'lodash/flattenDeep'
import { Navigate, Routes as ReactRoutes, Route, Routes } from 'react-router-dom'
import { routes } from '.'
import { GuardedRoute } from './Guards'
import React from 'react'
import { HelmetWrapper } from './helmet/Helmet'

const generateFlattenRoutes = (subRoutes: Array<CustomRoute>): Array<CustomRoute> => {
  if (!subRoutes) return []
  return flattenDeep(subRoutes.map(({ routes, ...rest }) => [rest, generateFlattenRoutes(routes ?? [])]))
}

export const RenderRoutes = () => {
  const layouts = routes.map((route, index) => {
    const subRoutes = generateFlattenRoutes(route.routes ?? [])
    return (
      <React.Fragment key={index}>
        <Route path="/" element={<Navigate replace to="/login" />} key={index} />

        <Route
          key={route.key}
          path={route.path}
          element={
            <GuardedRoute
              isAuthRoutes={route.isAuthRoute}
              allowedToPaidUsers={route.allowedToPaidUsers ?? false}
              allowedToPaidUsersAndAdmin={route.allowedToPaidUsersAndAdmin ?? false}
            >
              <HelmetWrapper helmet={route.helmet}>
                <route.component />
              </HelmetWrapper>
            </GuardedRoute>
          }
        >
          {subRoutes.map(
            ({ component: Component, path, key, allowedToPaidUsers, allowedToPaidUsersAndAdmin, helmet }) => {
              return (
                Component &&
                path && (
                  <Route
                    key={key}
                    element={
                      <GuardedRoute
                        allowedToPaidUsers={allowedToPaidUsers ?? false}
                        allowedToPaidUsersAndAdmin={allowedToPaidUsersAndAdmin ?? false}
                      >
                        <HelmetWrapper helmet={helmet}>
                          <Component />
                        </HelmetWrapper>
                      </GuardedRoute>
                    }
                    path={path}
                  />
                )
              )
            }
          )}
        </Route>
      </React.Fragment>
    )
  })
  return <ReactRoutes>{layouts}</ReactRoutes>
}
