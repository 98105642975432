import { Skeleton } from '@mui/material'
import { AppBox } from 'components/base'
import { FC } from 'react'

interface Props {}

export const MemberShipLoader: FC<Props> = () => {
  return (
    <AppBox display={'flex'} gap={3}>
      <Skeleton variant="rounded" width={200} height={300} />
      <Skeleton variant="rounded" width={200} height={300} />
    </AppBox>
  )
}
