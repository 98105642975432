import { Grid } from '@mui/material'
import {
  AppBox,
  AppButton,
  AppDialog,
  AppDialogRefType,
  AppMenu,
  FilterBuiler,
  FilterButton,
  SimpleField,
} from 'components'
import { FC } from 'react'
import { Control, UseFormHandleSubmit, UseFormSetError, UseFormWatch } from 'react-hook-form'
import { CrmCompanyPayload, PaginationFilterModel } from 'sdk'
import { AddUpdateCrmCompanyForm } from '../content/companies/add-edit-company/AddUpdateCrmCompanyForm'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useSelector } from 'react-redux'
import { FilterSliceSelector } from 'store/slices'

interface Props {
  control: Control<CrmCompanyPayload>
  isAddingCompany: boolean
  isAllCompaniesSelected: boolean
  selectedCompaniesIds: string[]
  dialogRef: React.MutableRefObject<AppDialogRefType>
  paginationFilter: PaginationFilterModel
  watch: UseFormWatch<CrmCompanyPayload>
  setIsApplyingFilter: (val: boolean) => void
  setError: UseFormSetError<CrmCompanyPayload>
  handleSubmit: UseFormHandleSubmit<CrmCompanyPayload, undefined>
  handleFormSubmit: (data: CrmCompanyPayload) => void
  handleQuery: (query: string) => void
  handleTypeChange: (event: string) => void
  handleDeleteCompanies: (ids: string[]) => void
  handleAddClicked: () => void
  handleDeleteAllCompanies: () => void
}
export const CompanyActions: FC<Props> = ({
  control,
  isAddingCompany,
  dialogRef,
  isAllCompaniesSelected,
  selectedCompaniesIds,
  paginationFilter,
  setIsApplyingFilter,
  setError,
  watch,
  handleSubmit,
  handleFormSubmit,
  handleQuery,
  handleAddClicked,
  handleDeleteCompanies,
  handleDeleteAllCompanies,
}) => {
  const {
    CrmCompany: { IsFilterToolOpened },
  } = useSelector(FilterSliceSelector)
  return (
    <>
      <Grid item xs={12} minHeight={'8vh'}>
        <AppBox minHeight={'100%'} backgroundColor="white" paddingX={4} paddingY={2}>
          <Grid container display={'flex'} gap={1}>
            <AppBox>
              <SimpleField
                prefixIcon="iconamoon:search-bold"
                placeholder="Search Companies"
                onChange={(event) => handleQuery(event.target.value)}
              />
            </AppBox>
            <AppMenu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              renderElement={(openMenu) => (
                <AppButton
                  color="secondary"
                  prefixIcon="lets-icons:import-fill"
                  suffixIcon="mingcute:down-line"
                  disabled={selectedCompaniesIds.length === 0}
                  onClick={openMenu}
                >
                  Actions
                </AppButton>
              )}
              MenuItems={[
                {
                  text: 'Delete',
                  prefixIcon: 'tabler:trash',
                  onClick: () => handleDeleteCompanies(selectedCompaniesIds),
                },
                {
                  text: 'Delete All',
                  prefixIcon: 'tabler:trash',
                  disabled: !isAllCompaniesSelected,
                  onClick: handleDeleteAllCompanies,
                },
              ]}
            />
            <FilterButton type={FILTER_PRINCIPAL_TYPES.COMPANY} />

            <AppDialog
              ref={dialogRef}
              maxWidth="md"
              title="Add New Company"
              appDialogWrapperProps={{
                component: 'form',
                role: 'form',
                onSubmit: handleSubmit((data) => handleFormSubmit(data)),
              }}
              submitButtonProps={{ isLoading: isAddingCompany }}
              renderElement={(openDialog) => (
                <AppButton color="secondary" size="large" prefixIcon="icon-park-solid:add" onClick={handleAddClicked}>
                  Add New
                </AppButton>
              )}
            >
              <AddUpdateCrmCompanyForm control={control} setError={setError} watch={watch} />
            </AppDialog>
            {!!IsFilterToolOpened && (
              <Grid item xs={12} minHeight={'2vh'} borderTop={1} borderColor={'grey.300'} maxHeight={''}>
                <AppBox paddingX={3} paddingY={1} height={'100%'} backgroundColor="white" alignItems={'center'}>
                  <FilterBuiler
                    type={FILTER_PRINCIPAL_TYPES.COMPANY}
                    setIsApplyingFilter={setIsApplyingFilter}
                    paginationFilter={paginationFilter}
                  />
                </AppBox>
              </Grid>
            )}
          </Grid>
        </AppBox>
      </Grid>
    </>
  )
}
