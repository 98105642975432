import { AppTextField, AppTextFieldProps } from 'components/base'
import { FC } from 'react'

interface Props extends Omit<AppTextFieldProps, 'control' | 'name'> {
  control?: any
  name?: string
  webSiteValue: string | undefined
}
export const WebsiteField: FC<Props> = ({ control, name, webSiteValue, ...rest }) => {
  return (
    <AppTextField
      control={control}
      name={name!}
      {...rest}
      suffixIcon={webSiteValue ? 'streamline:browser-website-1' : ''}
      suffixIconProps={{
        onClick: () => {
          window.open(webSiteValue, '_blank')
        },
      }}
    />
  )
}
