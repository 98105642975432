import { UserStatuses } from 'globals/constants'
import { useSelector } from 'react-redux'
import { AuthSliceSelector } from 'store/slices'
import { DashBoardGettingStartedDialog } from './getting-started/DashBoardGettingStartedDialog'
import { useMembershipHelper, useRolesHelper } from 'hooks'
import { OldCancelledMessage } from './old-cancelled-message/OldCancelledMessage'

export const DashBoard = () => {
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { IsPaidMember } = useMembershipHelper()
  const { IsTrialUser } = useRolesHelper()
  const renderComp = () => {
    if (CurrentUser?.Status === UserStatuses.SUBSCRIPTION_CANCELLED) {
      return <OldCancelledMessage />
    } else if (CurrentUser?.Status !== UserStatuses.COMPLETED) {
      return <DashBoardGettingStartedDialog />
    } else if (!IsPaidMember() && !IsTrialUser()) {
    }
    return <>Dashboard</>
  }
  return <>{renderComp()}</>
}
