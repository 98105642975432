import { AbsoluteBox, AppBox, AppIcon, AppTypography, TextWithToolTip } from 'components'
import { useRolesHelper } from 'hooks'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, closeActivity, setOpenedActivityAdminViewOpen } from 'store/slices'
import { ParkActivitiesMapping } from './ParkActivitiesMapping'

export const ParkActivity = () => {
  const { selectedParkId, openedActivity, activityWidth, isOpenedActivityAdminViewOpen } =
    useSelector(ParkSiteToolSliceSelector)
  const { IsAdmin } = useRolesHelper()
  const disptach = useAppDispatch()
  return selectedParkId && openedActivity ? (
    <AbsoluteBox
      maxWidth={activityWidth ?? '100%'}
      minWidth={(openedActivity && ParkActivitiesMapping[openedActivity].width) ?? '100%'}
      borderTop={1}
      borderColor={'grey.200'}
      overflow={'auto'}
    >
      <AppBox
        borderBottom={1}
        marginX={4}
        borderColor={'grey.200'}
        paddingY={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        minHeight={'2vh'}
      >
        <AppBox display={'flex'} alignItems={'center'}>
          <TextWithToolTip tooltipText={ParkActivitiesMapping[openedActivity].toolTipText}>
            <AppTypography
              color={'grey.900'}
              fontSize={16}
              fontWeight={700}
              component={IsAdmin() ? 'a' : 'span'}
              onClick={() => IsAdmin() && disptach(setOpenedActivityAdminViewOpen(!isOpenedActivityAdminViewOpen))}
            >
              {IsAdmin() && isOpenedActivityAdminViewOpen && ParkActivitiesMapping[openedActivity].AdminName
                ? ParkActivitiesMapping[openedActivity].AdminName
                : ParkActivitiesMapping[openedActivity].name}
            </AppTypography>
          </TextWithToolTip>
        </AppBox>
        <AppIcon
          size="large"
          icon="solar:square-double-alt-arrow-left-linear"
          color="#F44336"
          onClick={() => disptach(closeActivity())}
        />
      </AppBox>

      <AppBox height={'72vh'} width={'100%'} paddingY={2}>
        {ParkActivitiesMapping[openedActivity].component}
      </AppBox>
    </AbsoluteBox>
  ) : null
}
