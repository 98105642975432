import { useMutation } from '@tanstack/react-query'
import { TagTypes, ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CrmContactTagApi, TagApi, CrmcontactLinkedTagPayload, ShowAlert } from 'sdk'
import { TeamSliceSelector } from 'store/slices'

export const useCrmContactGroupTags = (contactId: string) => {
  const crmContactTagApi = new CrmContactTagApi()
  const tagApi = new TagApi()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const { control, reset, clearErrors, setError } = useForm<CrmcontactLinkedTagPayload>({
    defaultValues: { CrmContactsTableId: contactId, Type: TagTypes.GROUP_TAG },
  })
  const [tempTag, setTempTag] = useState<string>('')

  //    Getting All Tags
  const {
    data: allTags,
    isPending: isTagsLoading,
    mutate: getAllTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.tags.FetchTag],
    mutationFn: () => tagApi.GetTags(TagTypes.GROUP_TAG),
  })
  useEffect(() => getAllTags(), [SelectedTeam?.Id])
  //
  //    Getting All Linked Tags

  const {
    data: contactTags,
    isPending: isContactTagsLoading,
    mutate: GetAllLinkedTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.FetchTag],
    mutationFn: () => crmContactTagApi.GetTags(contactId!, TagTypes.GROUP_TAG),
  })
  useEffect(() => GetAllLinkedTags(), [SelectedTeam?.Id])

  //

  // Adding New Tag

  const { mutate: addTag, isPending: isAddingTag } = useMutation({
    mutationFn: crmContactTagApi.AddTag,
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.AddTag],
    onSuccess: () => handleFormSubmitSuccess(),
  })

  //

  // Deleting Linked Tags
  const { mutate: deleteTag } = useMutation({
    mutationFn: crmContactTagApi.RemoveTag,
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.RemoveTag],
    onSuccess: () => handleDeleteTagSuccess(),
  })

  const handleDeleteTagSuccess = () => {
    enqueueSnackbar('Tag has Been Removed ', { variant: 'success' })
    GetAllLinkedTags()
  }
  const handleDeleteParkTag = (id: string) => {
    ShowAlert('question', 'Are you sure you want to remove this Tag?').then((result) => {
      if (result.isConfirmed) {
        const linkId = contactTags?.find((tag) => tag.TagsTableId === id)?.Id
        if (!linkId) {
          return enqueueSnackbar('Tag not found', { variant: 'error' })
        }
        deleteTag(linkId)
      }
    })
  }
  //
  const unlinkedTags = useMemo(() => {
    return allTags
      ?.filter((tag) => !contactTags?.find((linkedTag) => linkedTag.TagsTableId === tag.Id))
      .map((tag) => {
        return { label: tag.Tag, value: tag.Id }
      })
  }, [contactTags, allTags])
  const linkedTags = useMemo(() => {
    return allTags?.filter((tag) => contactTags?.find((linkedTag) => linkedTag.TagsTableId === tag.Id))
  }, [contactTags, allTags])

  const addLinkedTag = (data: CrmcontactLinkedTagPayload) => {
    addTag(data)
  }
  const handleFormSubmitSuccess = () => {
    enqueueSnackbar('Tag has Been Added ', { variant: 'success' })
    GetAllLinkedTags()
  }
  return {
    control,
    isTagsLoading,
    isContactTagsLoading,
    isAddingTag,
    linkedTags,
    unlinkedTags,
    addLinkedTag,
    handleDeleteParkTag,
    reset,
    tempTag,
    setTempTag,
    clearErrors,
    setError,
    getAllTags,
  }
}
