import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactLinkedCompaniesApi, ShowAlert } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { CrmLinkedToolsHelperSelector, TeamSliceSelector } from 'store/slices'
interface Props {
  contactId: string
}
const linkedCompaniesApi = new CrmContactLinkedCompaniesApi()
export const useCrmContactCompanies = ({ contactId }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const [isNewCompanyAdded, setIsNewCompanyAdded] = useState<boolean>(false)
  const { isParkActivityCrmContactCompanyUpdated } = useSelector(CrmLinkedToolsHelperSelector)

  // Fetching Linked Companies
  const {
    data: linkedCompanis,
    isPending: isLoadingLinkedCompanies,
    mutate: getLinkedCompanies,
  } = useMutation({
    mutationKey: ['CrmContactLinkedCompanies', contactId],
    mutationFn: () => linkedCompaniesApi.getContactLinkedCompanies(contactId),
  })
  useEffect(() => getLinkedCompanies(), [isParkActivityCrmContactCompanyUpdated])

  //
  // Fetching Unlinked Companies

  const {
    data: unLinkedCompanies,
    isPending: isLoadingUnLinkedLinkedCompanies,
    mutate: getUnlinkedCompanies,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedCompanies.FetchUnlinkedCompanies],
    mutationFn: () => linkedCompaniesApi.getContactUnlinkedCompanies(contactId),
  })
  useEffect(() => {
    getUnlinkedCompanies()
  }, [isNewCompanyAdded, contactId, SelectedTeam?.Id])

  //

  // Adding Company

  const { isPending: isAddingLinkedCompany, mutateAsync: linkCompany } = useMutation({
    mutationFn: linkedCompaniesApi.linkCompany,
    mutationKey: [ApiQueryKeys.crm.contactLinkedCompanies.AddLinkedCompany],
    onSuccess: () => handleSuccess(),
  })

  const handleSuccess = () => {
    enqueueSnackbar('Company has been added', { variant: 'success' })
    getUnlinkedCompanies()
    getLinkedCompanies()
  }
  //
  // Deleting Linked Company
  const { mutate: UnlinkCompany } = useMutation({
    mutationFn: (data: { contactId: string; companyId: string }) =>
      linkedCompaniesApi.unlinkContactLinkedCompany(data.contactId, data.companyId),
    mutationKey: [ApiQueryKeys.crm.contactLinkedCompanies.RemoveLinkedCompany],
    onSuccess: () => {
      enqueueSnackbar('Company has been removed', { variant: 'success' })
      getLinkedCompanies()
      getUnlinkedCompanies!()
    },
  })

  const handleUnlinkClick = (companyId: string) => {
    ShowAlert(
      'question',
      `You are about to break the link between this contact and this company. You can add them back anytime while this contact remains in your CRM. <br /><br />Do you wish to continue?`
    ).then((result) => {
      if (result.isConfirmed) {
        UnlinkCompany({ contactId: contactId!, companyId })
      }
    })
  }

  return {
    linkCompany,
    handleUnlinkClick,
    linkedCompanis,
    unLinkedCompanies,
    isLoadingLinkedCompanies,
    isLoadingUnLinkedLinkedCompanies,
    isAddingLinkedCompany,
    setIsNewCompanyAdded,
  }
}
