import { AppBox, AppDialog, AppDialogRefType } from 'components'
import { useCounties, useStates } from 'hooks'
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  CountyPayload,
  CountyWithStateViewModel,
  PaginationFilterModel,
  ShowAlert,
  UpdateCountyStatusModel,
  UpdateCountyWebsitePayload,
} from 'sdk'
import { EditStateWebsiteFrom } from '../../states/add-edit-state-form/EditStateWebsiteForm'
import { AddeditCountyForm } from '../add-edit-county-form/AddEditCountyForm'
import { CountiesTable } from './CountiesTable'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
  isAddedNew: boolean
}
export const CountiesListing: FC<Props> = ({ filter, setFilter, isAddedNew }) => {
  const {
    AllCounties,
    getAllCounties,
    EditCounty,
    isEditingCounty,
    UpdateCountyStatus,
    isUpdatingStatus,
    UpdateCountyWebsite,
    isGettingAllCounties,
    isUpdatingCountyWebsite,
  } = useCounties({})
  const EditCountyDialogRef = useRef<AppDialogRefType>(null)
  const EditCountyWebsiteDialogRef = useRef<AppDialogRefType>(null)
  const { States } = useStates()

  const { control, handleSubmit, getValues, setValue, reset } = useForm<CountyPayload>()
  const {
    control: UpdateWebsiteControl,
    handleSubmit: UpdateWebsiteHandleSubmit,
    getValues: UpdateWebsiteGetValues,
    setValue: UpdateWebsiteSetValue,
    reset: UpdateWebsiteReset,
  } = useForm<UpdateCountyWebsitePayload>()

  useEffect(() => {
    getAllCounties()
  }, [isAddedNew])

  const handleEditClick = (county: CountyWithStateViewModel) => {
    const stateId = States.find((x) => x.Code === county.StateCode)?.Id
    setValue('Id', county.Id)
    setValue('StateId', stateId ?? '')
    setValue('County', county.County)
    EditCountyDialogRef.current?.openDialog!()
  }
  const handleWebsiteUpdateClick = (county: CountyWithStateViewModel) => {
    UpdateWebsiteSetValue('Id', county.Id)
    UpdateWebsiteSetValue('Website', county.Website)
    EditCountyWebsiteDialogRef.current?.openDialog!()
  }
  const handleUpdateStatusClick = (county: CountyWithStateViewModel) => {
    ShowAlert(
      'question',
      `Are you sure you want to ${county.ActiveStatus === 'TRUE' ? 'Deactivate' : 'Activate'} this county?`
    ).then((result) => {
      if (result.isConfirmed) {
        const payload: UpdateCountyStatusModel = {
          Id: county.Id!,
          Status: county.ActiveStatus === 'TRUE' ? 'FALSE' : 'TRUE',
        }
        UpdateCountyStatus(payload)
      }
    })
  }

  const paginatedCounties = useMemo(() => {
    if (!!filter) {
      return AllCounties?.filter(
        (x) =>
          x.County?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.StateName?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.StateCode?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '')
      ).slice(filter.PageSize! * (filter.PageNo! - 1), filter.PageSize! * filter.PageNo!)
    }
    return AllCounties
  }, [filter, AllCounties])
  const totalPaginatedStates = useMemo(() => {
    if (!!filter) {
      return AllCounties?.filter(
        (x) =>
          x.County?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.StateName?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.StateCode?.toLowerCase().includes(filter.Query?.toLowerCase() ?? '')
      ).length
    }
    return AllCounties?.length
  }, [paginatedCounties])
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <CountiesTable
          data={paginatedCounties ?? []}
          totalRecords={totalPaginatedStates ?? 0}
          isLoading={isGettingAllCounties}
          onEditClicked={handleEditClick}
          onUpdateWebSiteClick={handleWebsiteUpdateClick}
          onStatusUpdateClick={handleUpdateStatusClick}
          paginationFilter={filter}
          onPageSizeChange={(pageSize) => {
            setFilter((prev) => ({ ...prev, PageNo: 1, PageSize: pageSize }))
          }}
          onCurrentPageChange={(page) => {
            setFilter((prev) => ({ ...prev, PageNo: page }))
          }}
        />
      </AppBox>
      {/* // Dialoges for Editing state and state website */}
      <AppDialog
        maxWidth="sm"
        ref={EditCountyDialogRef}
        title="Edit County"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(() => {
            EditCounty(getValues())
              .then((x) => {
                EditCountyDialogRef.current?.closeDialog!()
                reset()
              })
              .catch((err) => {})
          }),
        }}
        submitButtonProps={{ isLoading: isEditingCounty, children: 'Update' }}
        renderChild={(openDialog) => <AddeditCountyForm control={control} />}
      />
      <AppDialog
        fullWidth
        ref={EditCountyWebsiteDialogRef}
        title="Edit County Website"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(() => {
            UpdateCountyWebsite(UpdateWebsiteGetValues())
              .then((x) => {
                EditCountyWebsiteDialogRef.current?.closeDialog!()
                UpdateWebsiteReset()
              })
              .catch((err) => {})
          }),
        }}
        submitButtonProps={{ isLoading: isUpdatingCountyWebsite, children: 'Update' }}
        renderChild={(openDialog) => <EditStateWebsiteFrom control={UpdateWebsiteControl} />}
      />
    </>
  )
}
