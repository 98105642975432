import { ProfileInfoCardWithLinks, profilelink } from 'components'
import { SideBarType } from 'globals/constants'
import { FC } from 'react'
import { CrmCompanyViewModel } from 'sdk'
interface Props {
  company: CrmCompanyViewModel | undefined
}
export const CrmCompanyDetailSideBar: FC<Props> = ({ company }) => {
  const links: profilelink[] = [
    {
      id: 'Company',
      name: 'Company',
      icon: 'carbon:user-avatar',
    },
    {
      name: 'Parks',
      icon: 'bi:people',
      id: 'Parks',
    },
    {
      name: 'Notes',
      icon: 'ph:question',
      id: 'Notes',
    },
  ]

  // return <ProfileInfoCardWithLinks links={links} sideBarType={SideBarType.CRMContact} contentInfo={<></>} />
  return <></>
}
