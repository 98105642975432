import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps } from 'components'
import { FC } from 'react'
import { FeedbackViewModel } from 'sdk'

interface Props extends AppTableProps<FeedbackViewModel> {}
export const ReviewsTable: FC<Props> = ({ ...props }) => {
  const columns: ColumnDef<FeedbackViewModel>[] = [
    {
      header: 'Date',
      accessorKey: 'Created',
    },
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Rating',
      accessorKey: 'Rating',
    },
    {
      header: 'Comments',
      accessorKey: 'Comment',
    },
  ]
  return (
    <>
      <AppTable columns={columns} {...props} />
    </>
  )
}
