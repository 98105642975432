import { AppBox, AppCard, AppIcon, AppTypography, ProfileImage, ProfileImageSelectorProps } from 'components'
import { SideBarType } from 'globals/constants'
import { useAuthorizeHelper } from 'hooks'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AuthorizeProps } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { ProfileModulesliceSelector, setOpenedProfileModule } from 'store/slices'
import { Authorize } from '../authorize/Authorize'

export interface profilelink extends AuthorizeProps {
  id: string
  name: string
  icon: string
  className?: string
}
interface Props extends ProfileImageSelectorProps {
  links: profilelink[]
  sideBarType: SideBarType
  onLinkClick?: (link: profilelink) => void
  contentInfo: ReactElement
}
export const ProfileInfoCardWithLinks = ({
  links: sideBarBtns,
  sideBarType,
  onLinkClick,
  contentInfo,
  ...props
}: Props) => {
  const [hoveredBtn, setHoveredBtn] = useState<string>('')
  const [showAbleBtns, setShowAbleBtns] = useState<profilelink[]>([])
  const { openedProfileModule } = useSelector(ProfileModulesliceSelector)
  const { isAuthorized } = useAuthorizeHelper()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (sideBarBtns.length > 0) {
      const showable: profilelink[] = [...sideBarBtns]
      if (sideBarType === SideBarType.Profile) {
        sideBarBtns.forEach((item) => {
          if (!isAuthorized(item)) {
            showable.splice(showable.indexOf(item), 1)
          }
        })
        if (openedProfileModule) {
          const opened = showable.find((item) => item.id === openedProfileModule)
          if (opened) {
            dispatch(setOpenedProfileModule(opened.id))
          } else {
            dispatch(setOpenedProfileModule(showable[0].id))
          }
        } else {
          dispatch(setOpenedProfileModule(showable[0].id))
        }
      }
      setShowAbleBtns(showable)
    }
  }, [sideBarBtns, dispatch])

  return (
    <AppBox
      border={1}
      paddingY={4}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      alignSelf={'flex-start'}
    >
      <AppBox display={'flex'} flexDirection={'column'}>
        <AppBox
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
          gap={1}
          width={1}
        >
          <ProfileImage {...props} />
          {contentInfo}
        </AppBox>
        <AppBox display={'flex'} flexDirection={'column'} gap={1} padding={2}>
          {showAbleBtns.map((item, index) => {
            return (
              <Authorize hiddenFromSubAccounts={item.hiddenFromSubAccounts}>
                <AppCard
                  key={index}
                  onMouseEnter={() => setHoveredBtn(item.id)}
                  onMouseLeave={() => setHoveredBtn('')}
                  onClick={() => {
                    if (onLinkClick) {
                      onLinkClick(item)
                    }
                  }}
                  elevation={[openedProfileModule, hoveredBtn].includes(item.id) ? 5 : 0}
                >
                  <AppBox
                    display={'flex'}
                    gap={1}
                    alignItems={'center'}
                    cursor="pointer"
                    sx={{
                      backgroundColor: 'lightgrey',
                      transition: 'background-color 0.2s',
                      '&:hover': {
                        backgroundColor: 'darkgrey',
                      },
                    }}
                    className={item.className}
                  >
                    <AppBox
                      padding={0.7}
                      margin={1}
                      backgroundColor={[openedProfileModule, hoveredBtn].includes(item.id) ? 'primary.200' : 'grey.100'}
                      borderRadius={2}
                    >
                      <AppIcon
                        icon={item.icon}
                        color={[openedProfileModule, hoveredBtn].includes(item.id) ? 'white' : 'grey'}
                        size="large"
                      />
                    </AppBox>
                    <AppTypography fontSize={14} fontWeight={'bold'}>
                      {item.name}
                    </AppTypography>
                  </AppBox>
                </AppCard>
              </Authorize>
            )
          })}
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
