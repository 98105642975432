import { useMutation } from '@tanstack/react-query'
import { appRoutes } from 'globals/constants'
import { jwtDecode } from 'jwt-decode'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { AuthApi, DecodedTokenViewModel, SessionModel, UserMembershipViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { setTrialExpiry, setMemberships, setOwnerId, setRoles, setSession, setUserId } from 'store/slices'

export const useLogin = () => {
  const authApi = new AuthApi()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleLoginSuccess = (data: SessionModel) => {
    dispatch(setSession(data))
    dispatch(setUserId(data.UserId))
    const decoded: DecodedTokenViewModel = jwtDecode(data.JwtToken!)
    const parsedMemberships: UserMembershipViewModel[] = JSON.parse(decoded.Memberships)
    dispatch(setMemberships(parsedMemberships))
    dispatch(setTrialExpiry(decoded.TrialExpiry))
    dispatch(setOwnerId(decoded.AccountOwnerId))
    dispatch(setRoles(decoded.role))
    navigate(appRoutes.account.path)
  }

  const { mutate: appLogin, isPending } = useMutation({
    mutationFn: authApi.appLogin,
    onSuccess: handleLoginSuccess,
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  return {
    appLogin,
    isPending,
  }
}
