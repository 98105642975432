import { ParksList } from 'components'
import { NoTaggedParks } from 'components/shared/no-data-components'
import { useGetTaggedParks } from 'hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TeamSliceSelector } from 'store/slices'
import { ParkTagsliceSelector } from 'store/slices/park-tags.slice'

export const TaggedParksTable = () => {
  const { TaggedParks, isTaggedParksLoading } = useSelector(ParkTagsliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { getTaggedParks } = useGetTaggedParks()

  useEffect(() => {
    getTaggedParks()
  }, [SelectedTeam?.Id])

  return (
    <ParksList
      linkedParks={TaggedParks ?? []}
      isLoading={isTaggedParksLoading}
      refetch={getTaggedParks}
      noParksComponent={
        <>
          <NoTaggedParks />
        </>
      }
    />
  )
}
