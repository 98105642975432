export enum ParkSitesTools {
  PinnedParks = 'PinnedParks',
  TaggedParks = 'TaggedParks',
  CRM = 'CRM',
}
export enum CrmTools {
  Contacts = 'Contacts',
  Companies = 'Companies',
  Import = 'Import',
}
export enum AdminSettingsTools {
  Users = 'Users',
  News_Alerts = 'News Alerts',
  States = 'States',
  Counties = 'Counties',
  City_Data = 'City Data',
  Reviews = 'Reviews',
}
export enum ProfileModules {
  Profile = 'Profile',
  Billing = 'Billing',
  ChangePassword = 'Change Password',
  OutsideTeam = 'Outside Team',
  ZapierIntegration = 'Zapier Integration',
  AccountRecovery = 'Account Recovery',
}
export enum CrmHelpModules {
  ContactLinkedCompanies = 'ContactLinkedCompanies',
  ContactNotes = 'ContactNotes',
  ContactParks = 'ContactParks',
  ContactTags = 'ContactTags',
  CompanyContacts = 'CompanyContacts',
  CompanyNotes = 'CompanyNotes',
  ManageTags = 'ManageTags',
}
export enum ParkSiteHelpingModules {
  ContactLinkedCompanies = 'ContactLinkedCompanies',
  ContactNotes = 'ContactNotes',
  ContactTags = 'ContactTags',
  ContactParks = 'ContactParks',
  ParkHistory = 'ParkHistory',
  AddContact = 'AddContact',
  ParkSiteHelpingModules = 'ParkSiteHelpingModules',
}
export enum ParkSitesActivities {
  Parktags = 'Parktags',
  ParkActivityCrm = 'ParkActivityCrm',
  CityDemographics = 'CityDemographics',
  ClaimPark = 'ClaimPark',
  ParkDetails = 'ParkDetails',
  ParkNotes = 'ParkNotes',
  PeopleInsight = 'PeopleInsight',
  ParcelData = 'ParcelData',
}
export enum ParkSitesExternalLinks {
  CountyWebsite = 'CountyWebsite',
  StateWebsite = 'StateWebsite',
  GooglePark = 'GooglePark',
  ViewInGoogleMaps = 'ViewInGoogleMaps',
}
export enum FormFiledRulesKeys {
  Email = 'Email',
  Phone = 'Phone',
  Password = 'Password',
  ConfirmPassword = 'ConfirmPassword',
  ConfirmEmail = 'ConfirmEmail',
  Website = 'Website',
  TextOnly = 'TextOnly',
  NumberOnly = 'NumberOnly',
  EmailExist = 'EmailExist',
  EmailNotFound = 'EmailNotFound',
  TeamNameTaken = 'TeamNameTaken',
  Mask = 'Mask',
}
export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export enum MembershipTypes {
  BasicMembership = 'basic-membership',
  PremiumMembership = 'premium-membership',
  AnnualPremiumMembership = 'annual-premium-membership',
  ProMembership = 'pro-membership',
  AnnualProMembership = 'annual-pro-membership',
  CancelledMembership = 'cancelled-membership',
}

export enum UserMembershipStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED_SOON = 'CANCELLED_SOON',
  CANCELLED = 'CANCELLED',
  FUTURE = 'FUTURE',
}

export enum TagTypes {
  ROLE_TAG = 'ROLE_TAG',
  GROUP_TAG = 'GROUP_TAG',
  PARK_TAG = 'PARK_TAG',
}
export enum TeamStatus {
  InActive,
  Activated,
  Deactivated,
}

export enum UserPaymentMethod {
  Default = 'DEFAULT_PAYMENT_METHOD',
}
export enum SettingsTypes {
  DEFAULT_PAYMENT_METHOD = 'DEFAULT_PAYMENT_METHOD',
  INFO_BOX_SETTINGS = 'INFO_BOX_SETTINGS',
  TOUR_SETTINGS = 'TOUR_SETTINGS',
}
export enum InfoBoxSettingTypes {
  ParcelDataDismissed = 'ParcelDataDismissed',
  CityDemographicsDismissed = 'CityDemographicsDismissed',
  ContactLinkedCompaniesDismissed = 'ContactLinkedCompaniesDismissed',
  ContactLinkedParksDismissed = 'ContactLinkedParksDismissed',
  ParkActivityCrmDismissed = 'ParkActivityCrmDismissed',
  ParkNotesDismissed = 'ParkNotesDismissed',
  ParkTagsDismissed = 'ParkTagsDismissed',
  PeopleInsightDismissed = 'PeopleInsightDismissed',
  AddExistingContactDismissed = 'AddExistingContactDismissed',
  ContactLinkedTagsDismissed = 'ContactLinkedTagsDismissed',
  CrmNoteHelpWindowDismissed = 'CrmNoteHelpWindowDismissed',
  CrmManageTagsDismissed = 'CrmManageTagsDismissed',
  ReportParkDismissed = 'ReportParkDismissed',
}
export enum UserStatuses {
  PASSWORD_NOT_CHANGED,
  NO_PERSONAL_INFO,
  COMPLETED,
  SUBSCRIPTION_CANCELLED,
}
export enum RoleTypes {
  Admin = 'Admin',
  User = 'User',
  SuperAdmin = 'SuperAdmin',
  BasicUser = 'BasicUser',
  SubAccount = 'SubAccount',
  TrialUser = 'TrialUser',
  ProYearlyUser = 'ProYearlyUser',
  ProMonthlyUser = 'ProMonthlyUser',
  PremiumYearlyUser = 'ProYearlyUser',
  PremiumMonthlyUser = 'PremiumMonthlyUser',
}

export enum SideBarType {
  CRMContact = 'CRMContact',
  CRMCompany = 'CRMCompany',
  Profile = 'Profile',
}
export enum SubAccountStatus {
  Pending = 'Pending',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
}

export enum OutSideTeamStatus {
  Email_Sent = 'Email_Sent',
  Accepted = 'Accepted',
  Revoked = 'Revoked',
}

export enum FILTER_PRINCIPAL_TYPES {
  PARK,
  CONTACT,
  COMPANY,
}

export const UserTypeOptions = [
  {
    value: RoleTypes.Admin,
    label: 'Admin',
  },
  {
    value: RoleTypes.User,
    label: 'User',
  },
  {
    value: RoleTypes.SuperAdmin,
    label: 'Super Admin',
  },
]
export const PeopleInsightRolesOptions: { value: string; label: string; [key: string]: string }[] = [
  {
    value: 'Owner',
    label: 'Owner',
    color: '#24D164',
  },
  {
    value: 'Manager',
    label: 'Manager',
    color: '#38BDF8',
  },
  {
    value: 'CEO',
    label: 'CEO',
    color: '#1859FB',
  },
  {
    value: 'RegAgent',
    label: 'Reg Agent',
    color: '#FF0000',
  },
  {
    value: 'Other',
    label: 'Other',
    color: '#F6A723',
  },
  {
    value: 'Unknown',
    label: 'Unknown',
    color: '#94A3B8',
  },
]
export const UserPrimaryRolesOptions = [
  {
    value: 'Investor',
    label: 'Investor',
  },
  {
    value: 'Broker',
    label: 'Broker',
  },
  {
    value: 'WholeSaler',
    label: 'WholeSaler',
  },
  {
    value: 'Owner',
    label: 'Owner',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'Lender',
    label: 'Lender',
  },
  {
    value: 'Manager',
    label: 'Manager',
  },
  {
    value: 'Coach',
    label: 'Coach',
  },
  {
    value: 'Dealer',
    label: 'Dealer',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]
export const FloodPlainTypeOptions = [
  {
    value: 'NoFloodPlain',
    label: 'No Flood Plain',
  },

  {
    value: 'FloodPlain',
    label: 'Flood Plain',
  },

  {
    value: 'FloodWay',
    label: 'Floodway',
  },

  {
    value: 'Mixed',
    label: 'Mixed',
  },

  {
    value: 'Unknown',
    label: 'Unknown',
  },
]
export const TrashServiceTypes = [
  { value: 'ParkProvided', label: 'Park Provided' },
  { value: 'TenantProvided', label: 'Tenant Provided' },
  { value: 'Unknown', label: 'Unknown' },
]
export const HeatingFuelTypes = [
  {
    value: 'NaturalGas',
    label: 'Natural Gas',
  },

  {
    value: 'Propane',
    label: 'Propane',
  },

  {
    value: 'Electric',
    label: 'Electric',
  },

  {
    value: 'Unknown',
    label: 'Unknown',
  },
]
export const SewerTypes = [
  {
    value: 'MunicipalSewer',
    label: 'Municipal Sewer',
  },

  {
    value: 'SepticSystem',
    label: 'Septic System',
  },

  {
    value: 'LagoonSystem',
    label: 'Lagoon System',
  },

  {
    value: 'TreatmentPlant',
    label: 'Treatment Plant',
  },

  {
    value: 'Unknown',
    label: 'Unknown',
  },
]
export const WaterSourceTypes = [
  { value: 'MunicipalWater', label: 'Municipal Water' },
  { value: 'PrivateWell', label: 'Private Well' },
  { value: 'Unknown', label: 'Unknown' },
]
export const StreetOwnedByTypes = ['Park', 'Municipality', 'Unknown'].map((x) => {
  return { value: x, label: x }
})
export const StreetTypes = ['Asphalt', 'Concrete', 'Gravel', 'Unknown'].map((x) => {
  return { value: x, label: x }
})
export const ParkActivityTypes = [
  { type: 'Park Created', text: 'Created', color: '#F6A723' },
  { type: 'Park Updated', color: '#6366F1', text: 'Updated' },
  { type: 'Park Location Updated', color: '#6366F1', text: 'Updated' },
]

export enum ParkSystemStatuses {
  Active = 'Active',
  Closed = 'Closed',
  NoParkData = 'NoParkData',
}

export enum FeedbackTypes {
  Application,
  ParkDetails,
  ParkPeopleInsight,
  ParkParcelData,
  ParkCityDemographics,
}
export const ParkSystemStatusOptions = [
  { label: ParkSystemStatuses.Active, value: ParkSystemStatuses.Active },
  { label: ParkSystemStatuses.Closed, value: ParkSystemStatuses.Closed },
  { label: 'No Data', value: ParkSystemStatuses.NoParkData },
]

export enum AppTourKeys {
  ParkSitesDashboardTour = 'ParkSitesDashboardTour',
  ParkSitesActivityCenterTour = 'ParkSitesActivityCenterTour',
  ProfileTour = 'ProfileTour',
}
export enum AppGoogleMapUniqueKeys {
  ParkSitesMap = 'ParkSitesMap',
  RequestNewParkSiteMap = 'RequestNewParkSiteMap',
}
