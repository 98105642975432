import { TextFieldProps, Typography } from '@mui/material'
import { LocalizationProvider, DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppBox } from '../box/AppBox'
import { FieldError } from 'react-hook-form'
import React from 'react'
import { SimpleField } from './simple-field/SimpleField'
import { AppIcon, AppIconProps, AppTypography } from 'components'

interface Props extends Omit<DatePickerProps<any>, 'slots' | 'error'> {
  label?: string
  required?: boolean
  labelIcon?: string
  labelIconProps?: Omit<AppIconProps, 'icon'>
  LabelProps?: any
  error?: FieldError
  fieldProps?: TextFieldProps
}
export const SimpleDatePicker = ({
  label,
  required,
  labelIcon,
  labelIconProps,
  LabelProps,
  error,
  fieldProps,
  ...props
}: Props) => {
  const TextField = React.forwardRef((props: TextFieldProps, ref: React.Ref<HTMLDivElement>) => (
    <SimpleField {...props} ref={ref} size="small" label="" />
  ))
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppBox display="flex" flexDirection="row" justifyContent="space-between">
          {label && (
            <AppTypography component={'span'} fontWeight={'bold'} {...LabelProps}>
              {label}
              {required && (
                <AppTypography component={'span'} color={'red'} paddingLeft={1}>
                  *
                </AppTypography>
              )}
            </AppTypography>
          )}
          {labelIcon && <AppIcon icon={labelIcon!} {...labelIconProps} />}
        </AppBox>
        <DatePicker {...props} slots={{ textField: TextField as any }} />
        {!!error && <AppTypography>{error?.message?.toString() ?? ''}</AppTypography>}
      </LocalizationProvider>
    </>
  )
}
