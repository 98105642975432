import { Divider } from '@mui/material'
import { AppBox, AppButton, AppTypography } from 'components'
import { useCrmCompanyContacts } from 'hooks'
import { CrmCompanyLinkedContactsTable } from './listing/CrmCompanyLinkedContactsTable'

interface Props {
  companyId: string
}
export const CrmCompanyLinkedContacts = ({ companyId }: Props) => {
  const { isAddClicked, setIsAddClicked } = useCrmCompanyContacts()
  return (
    <AppBox
      border={1}
      paddingX={4}
      paddingY={1}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Companies"
    >
      <AppBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={2}>
        <AppTypography fontWeight={700} fontSize={16}>
          Contacts
        </AppTypography>
        <AppBox If={!isAddClicked}>
          <AppButton variant="contained" prefixIcon="tabler:plus" size="large" onClick={() => setIsAddClicked(true)}>
            Add
          </AppButton>
        </AppBox>
        <AppBox If={isAddClicked}>
          {!isAddClicked ? (
            <AppButton variant="contained" prefixIcon="tabler:plus" size="large" onClick={() => setIsAddClicked(true)}>
              Add
            </AppButton>
          ) : (
            <></>
            // <Form control={control} onSubmit={({ data }) => addLinkedTag(data)}>
            //   <Grid container spacing={2} alignItems={'center'}>
            //     <Grid item xs={9}>
            //       <AppAutoComplete
            //         options={unlinkedTags ?? []}
            //         fullWidth
            //         placeholder="Search Tags"
            //         control={control}
            //         name={'TagsTableId'}
            //         required
            //       />
            //     </Grid>
            //     <Grid item xs={3}>
            //       <AppButton isLoading={isAddingTag} sidePaddings={3} variant="contained" size="large" type="submit">
            //         Save
            //       </AppButton>
            //     </Grid>
            //   </Grid>
            // </Form>
          )}
        </AppBox>
      </AppBox>
      <Divider />
      <AppBox paddingY={4}>
        <CrmCompanyLinkedContactsTable data={[]} isLoading={false} />
      </AppBox>
    </AppBox>
  )
}
