import { Popover, PopoverProps, Typography, TypographyProps } from '@mui/material'
import { AppBox, AppIcon, AppIconProps, AppTypography } from 'components'
import React, { ReactNode } from 'react'
interface MenuItem {
  text: string
  textProps?: TypographyProps
  prefixIcon?: string
  prefixIconProps?: Omit<AppIconProps, 'icon'>
  suffixIcon?: string
  suffixIconProps?: Omit<AppIconProps, 'icon'>
  disabled?: boolean
  onClick?: () => void
}
interface AppMenuProps extends Omit<PopoverProps, 'open'> {
  renderElement: (handleClick: (event: React.MouseEvent<HTMLElement>) => void) => React.ReactNode
  MenuItems?: MenuItem[]
  MenuComponent?: (handleClose: () => void) => ReactNode
}

export const AppMenu = ({ renderElement, MenuItems, MenuComponent, ...rest }: AppMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      {renderElement(handleClick)}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        slotProps={{ paper: { style: { borderRadius: 12 } } }}
        {...rest}
      >
        <AppBox padding={1} borderRadius={6}>
          {MenuComponent && <>{MenuComponent(handleClose)}</>}
          {MenuItems &&
            MenuItems.map((item, index) => (
              <AppBox
                key={index}
                paddingX={2}
                cursor={item.disabled ? 'default' : 'pointer'}
                paddingTop={1}
                sx={{ opacity: item.disabled ? 0.5 : 1 }}
                onClick={() => {
                  if (item.disabled) {
                    return
                  }
                  handleClose()
                  if (item.onClick) {
                    item.onClick()
                  }
                }}
              >
                <AppBox display={'flex'} alignItems={'center'} gap={1} cursor="pointer">
                  {item.prefixIcon && (
                    <AppIcon
                      icon={item.prefixIcon}
                      color={item.disabled ? 'grey' : item.prefixIconProps?.color}
                      {...item.prefixIconProps}
                    />
                  )}
                  <AppTypography color={item.disabled ? 'grey.400' : 'grey.600'} fontWeight={600} {...item.textProps}>
                    {item.text}
                  </AppTypography>
                  {item.suffixIcon && <AppIcon icon={item.suffixIcon} {...item.suffixIconProps} />}
                </AppBox>
              </AppBox>
            ))}
        </AppBox>
      </Popover>
    </>
  )
}
