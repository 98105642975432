import { Grid } from '@mui/material'
import { AppBox, AppIcon, AppMenu, AppTypography } from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { FC } from 'react'
import { FilterConditionSaveModel } from 'sdk'
import { AddFilterMenu } from '../add-filter-menu/AddFilterMenu'

interface Props {
  filter: FilterConditionSaveModel
  type: FILTER_PRINCIPAL_TYPES
  addedfilters: FilterConditionSaveModel[]
  getFilterValue: (filter: FilterConditionSaveModel) => string | string[]
  addNewFilter: (newFilter: FilterConditionSaveModel) => void
  handleRemoveFilter: () => void
  handleUpdateFilter: (updatedFilter: FilterConditionSaveModel, closeMenu: () => void) => void
}

export const AddedFilterButton: FC<Props> = ({
  filter,
  type,
  addedfilters,
  getFilterValue,
  addNewFilter,
  handleRemoveFilter,
  handleUpdateFilter,
}) => {
  const { fieldByCoumnAndTableName, conditionSigns } = useFilterBuilder({ type, preventFiltersUpdate: true })
  return (
    <Grid item>
      <AppMenu
        MenuComponent={(closeMenu) => (
          <AddFilterMenu
            categories={[]}
            type={type}
            addedFilters={addedfilters}
            closeMenu={closeMenu}
            onSaveNewFilter={(updatedFilter) => handleUpdateFilter(updatedFilter, closeMenu)}
            filterToUpdate={filter}
            updateFilterStep="value"
          />
        )}
        renderElement={(openMenu) => (
          <AppBox display={'flex'} alignItems={'center'} gap={0.5}>
            <AppBox
              padding={1}
              borderRadius={1}
              color={'primary.800'}
              backgroundColor="primary.50"
              display={'flex'}
              gap={1}
            >
              <AppIcon icon={'eva:close-outline'} size="medium" color="red" onClick={() => handleRemoveFilter()} />

              <AppTypography
                fontSize={14}
                fontWeight={600}
                onClick={(e) => {
                  openMenu(e)
                }}
              >
                {fieldByCoumnAndTableName(filter.ColumnName!, filter.TableName!)?.Label}
              </AppTypography>
              <AppMenu
                renderElement={(openChildMenu) => (
                  <AppBox
                    cursor="pointer"
                    onClick={(event) => {
                      event.stopPropagation()
                      openChildMenu(event)
                    }}
                  >
                    <AppTypography fontSize={14} fontWeight={600}>
                      {conditionSigns[filter.Condition as keyof typeof conditionSigns]}
                    </AppTypography>
                  </AppBox>
                )}
                MenuComponent={(closeMenu) => (
                  <AddFilterMenu
                    categories={[]}
                    type={type}
                    addedFilters={addedfilters}
                    closeMenu={closeMenu}
                    onSaveNewFilter={(updatedFilter) => handleUpdateFilter(updatedFilter, closeMenu)}
                    filterToUpdate={filter}
                    updateFilterStep="condition"
                  />
                )}
              />
              <AppBox
                onClick={(e) => {
                  openMenu(e)
                }}
                display={'flex'}
                alignItems={'center'}
                gap={0.5}
                cursor="pointer"
              >
                <AppTypography fontSize={14} fontWeight={600}>
                  {getFilterValue(filter)}
                </AppTypography>
                <AppIcon icon={'mdi:chevron-down'} size="medium" color={'grey.800'} />
              </AppBox>
            </AppBox>
          </AppBox>
        )}
      />
    </Grid>
  )
}
