import { CircularProgress, Divider } from '@mui/material'
import { AppMenu, AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { FilterViewModel, ShowAlert } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  FilterSliceSelector,
  ParkSiteToolSliceSelector,
  setAppliedCrmCompanyFilter,
  setAppliedCrmContactFilter,
  setAppliedParkFilter,
  setIsCrmCompanyFilterToolOpened,
  setIsCrmContactFilterToolOpened,
  setIsFilterToolOpened,
} from 'store/slices'

interface Props {
  type: FILTER_PRINCIPAL_TYPES
  setIsApplyingFilter?: (val: boolean) => void
}

export const FilterButton: FC<Props> = ({ type, setIsApplyingFilter }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispath = useAppDispatch()
  const { isFilterToolOpened } = useSelector(ParkSiteToolSliceSelector)

  const { getFilters, isGettingFilters, DeleteFilter, isDeletingFilter } = useFilterBuilder({
    type,
    setIsApplyingFilter: setIsApplyingFilter,
    preventFiltersUpdate: true,
  })
  const { Park, CrmCompany, CrmContact } = useSelector(FilterSliceSelector)

  const filters = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.Filters
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.Filters
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.Filters
    }
  }
  const isCurrentFilterToolOpened = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return isFilterToolOpened
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.IsFilterToolOpened
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.IsFilterToolOpened
    }
  }
  const isFilterApplied = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.IsUnSavedFilterApplied
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.IsUnSavedFilterApplied
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.IsUnSavedFilterApplied
    }
    return false
  }

  const handleDeleteFilter = (filterId: string) => {
    ShowAlert('question', 'Are you sure you want to delete this filter?').then((result) => {
      if (result.isConfirmed) {
        DeleteFilter(filterId)
          .then(() => {
            enqueueSnackbar('Filter deleted successfully', { variant: 'success' })
            getFilters()
          })
          .catch(() => {})
      }
    })
  }
  const handleOpenedFilterTool = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      dispath(setIsFilterToolOpened(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      dispath(setIsCrmCompanyFilterToolOpened(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      dispath(setIsCrmContactFilterToolOpened(true))
    }
  }
  const handleFilterClick = (filter: FilterViewModel) => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      dispath(setAppliedParkFilter(filter))
      dispath(setIsFilterToolOpened(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      dispath(setAppliedCrmCompanyFilter(filter))
      dispath(setIsCrmCompanyFilterToolOpened(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      dispath(setAppliedCrmContactFilter(filter))
      dispath(setIsCrmContactFilterToolOpened(true))
    }
  }
  return (
    <AppMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      renderElement={(openMenu) => (
        <>
          <AppBox marginTop={0.5}>
            <AppButton
              variant="outlined"
              size="large"
              color={!!isCurrentFilterToolOpened() || isFilterApplied() ? 'primary' : 'secondary'}
              sidePaddings={2}
              onClick={(e) => {
                if (!isCurrentFilterToolOpened()) {
                  openMenu(e)
                  getFilters()
                }
              }}
              className="park-site-tour-step2"
            >
              <AppBox display={'flex'} gap={1}>
                <AppIcon icon={'mingcute:filter-fill'} size="medium" />
                <AppIcon icon={'heroicons-outline:chevron-down'} size="medium" />
              </AppBox>
            </AppButton>
          </AppBox>
        </>
      )}
      MenuComponent={(handleClose) => (
        <AppBox display={'flex'} flexDirection={'column'} paddingX={1}>
          <AppBox>
            <AppButton
              size="small"
              color={'primary'}
              prefixIcon="ic:baseline-plus"
              onClick={() => {
                handleClose()
                handleOpenedFilterTool()
              }}
            >
              New Filter
            </AppButton>
          </AppBox>
          <Divider />
          {isGettingFilters || isDeletingFilter ? (
            <CircularProgress variant="indeterminate" size="1.5rem" color={'primary'} />
          ) : (
            <AppBox paddingY={1}>
              <AppTypography fontSize={12} color={'grey.600'} paddingBottom={0.5}>
                Saved Filters
              </AppTypography>
              {filters()!.map((filter, index) => (
                <AppBox
                  key={index}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  cursor="pointer"
                  gap={1}
                  onClick={() => {
                    handleClose()
                    handleFilterClick(filter)
                  }}
                >
                  <AppTypography fontSize={14} color={'primary.200'}>
                    {filter.Name}
                  </AppTypography>
                  <AppBox>
                    <AppIcon icon="tabler-trash" color="red" onClick={() => handleDeleteFilter(filter.Id)} />
                  </AppBox>
                </AppBox>
              ))}
            </AppBox>
          )}
        </AppBox>
      )}
    ></AppMenu>
  )
}
