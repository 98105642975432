import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography, SimpleCheckBox, SimpleValueSelect } from 'components'
import { ApiQueryKeys, UserTypeOptions } from 'globals/constants'
import { getFormattedDate } from 'globals/functions'
import { useSnackbar } from 'notistack'
import { FC, useEffect } from 'react'
import { UserApi } from 'sdk'

interface Props {
  userId: string
}

export const ManageAccountAccountOwnerCard: FC<Props> = ({ userId }) => {
  const userApi = new UserApi()
  const { enqueueSnackbar } = useSnackbar()
  const { mutate, data } = useMutation({
    mutationFn: () => userApi.getUserPersonalInfo(userId),
    mutationKey: [ApiQueryKeys.user.getAllUsersWithSubAccounts],
    onError: (error) => enqueueSnackbar(error?.message, { variant: 'error' }),
  })
  useEffect(() => {
    mutate()
  }, [])
  return (
    <AppBox backgroundColor="grey.100" border={1} borderColor={'grey.200'} borderRadius={6} padding={2}>
      <AppTypography fontWeight={700} fontSize={16}>
        Account Owner
      </AppTypography>
      <Grid container spacing={2} paddingY={2}>
        <Grid item xs={6}>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>User Type</AppTypography>
            <SimpleValueSelect
              size="small"
              value={data?.Active ? 'active' : 'inactive'}
              options={UserTypeOptions}
              onValueChange={() => {}}
            />
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Account ID:</AppTypography>
            <AppTypography fontWeight={700}>{data?.AccountId}</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Company:</AppTypography>
            <AppTypography fontWeight={700}>{data?.Company}</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Name:</AppTypography>
            <AppTypography fontWeight={700}>{data?.FirstName + ' ' + data?.LastName}</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Address:</AppTypography>
            <AppTypography fontWeight={700}>{data?.Address}</AppTypography>
          </AppBox>
        </Grid>
        <Grid item xs={6}>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Account Access</AppTypography>
            <SimpleValueSelect
              size="small"
              value={data?.Active ? 'active' : 'inactive'}
              options={[
                { label: 'Active', value: 'active' },
                { label: 'InActive', value: 'inactive' },
              ]}
              onValueChange={() => {}}
            />
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Sign Up Date:</AppTypography>
            <AppTypography fontWeight={700}>
              {data?.Created ? getFormattedDate(new Date(data?.Created)) : 'N/A'}
            </AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Last Login Date:</AppTypography>
            <AppTypography fontWeight={700}>
              {data?.LastLogin ? getFormattedDate(new Date(data?.LastLogin)) : 'N/A'}
            </AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Phone:</AppTypography>
            <AppTypography fontWeight={700}>{data?.PhoneNumber}</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Email:</AppTypography>
            <AppTypography fontWeight={700}>{data?.Email}</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1}>
            <AppTypography color={'grey.700'}>Role:</AppTypography>
            <AppTypography fontWeight={700}>{data?.Role}</AppTypography>
          </AppBox>
        </Grid>
      </Grid>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <SimpleCheckBox label="Disable User's Account Access Switch" />
        <AppButton variant="contained" sidePaddings={5}>
          Update
        </AppButton>
      </AppBox>
    </AppBox>
  )
}
