import { Divider, Step, StepLabel, StepLabelProps, StepProps, Stepper } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { ApiQueryKeys, MembershipTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AttachPaymentMethodPayloadModel,
  ChangeMembershipPayload,
  ChangeMembershipResponse,
  MembershipApi,
  MembershipPlanViewModel,
} from 'sdk'
import { AuthSliceSelector, setMemberships } from 'store/slices'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'
import { ChangePlanConfimation } from './confirmation/ChangePlanConfirmation'
import { ChangeMembershipPaymentMethod } from './payment-methods/ChangeMembershipPaymentMethods'
import { ChangeMembershipPurchase } from './purchase/ChangeMembershipPurchase'
import { ChangeMembershipSelectPlans } from './select-plans/ChangeMembershipSelectPlans'
import { usePaymentMethods } from 'hooks'
import { useForm } from 'react-hook-form'

interface Props {
  closeDialog: () => void
}
export const ChangeMemberShipStepperDialog: FC<Props> = ({ closeDialog }) => {
  const membershipApi = new MembershipApi()

  const { enqueueSnackbar } = useSnackbar()
  const { UserId } = useSelector(AuthSliceSelector)
  const { DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)

  const dispatch = useDispatch()

  const steps = ['Select', 'Payment Method', 'Purchase', 'Confirmation']
  const [activeStep, setActiveStep] = useState(0)
  const [payload, setPayload] = useState<ChangeMembershipPayload | null>(null)
  const [selectedMembership, setSelectedMembership] = useState<MembershipPlanViewModel | null>(null)
  const {
    control: addPaymentControl,
    reset: addPaymentReset,
    handleSubmit: handleAddPaymentSubmit,
  } = useForm<AttachPaymentMethodPayloadModel>({ defaultValues: { UserId } })
  const { attachPaymentMethod, isAddingPaymentMethod } = usePaymentMethods()

  const {
    data: membershipPlans,
    isPending: loadingMembershipPlans,
    mutate: getMembershipPlans,
  } = useMutation({
    mutationKey: [ApiQueryKeys.memberships.GetMembershipPlans],
    mutationFn: () => membershipApi.GetMembershipPlans(),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      closeDialog()
    },
  })

  const { mutate: ChangeMembership, isPending: isChangingMembersip } = useMutation({
    mutationKey: [ApiQueryKeys.memberships.ChangeMembership],
    mutationFn: () => membershipApi.ChangeMembership(payload!),
    onSuccess: (data: ChangeMembershipResponse) => {
      if (data) {
        dispatch(setMemberships(data.Memberships))
        // if (data.IsTrialCancelled && TrialExpiry) {
        //   dispatch(setTrialExpiry(false))
        // }
        enqueueSnackbar('Membership changed successfully', { variant: 'success' })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
  })

  const renderStepsContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ChangeMembershipSelectPlans
            handlePlanSelected={handleMembershipPlanSelected}
            loadingMemberships={loadingMembershipPlans}
            membershipPlans={membershipPlans ?? []}
          />
        )
      case 1:
        return (
          <ChangeMembershipPaymentMethod
            control={addPaymentControl}
            reset={addPaymentReset}
            moveNext={() => setActiveStep((prev) => prev + 1)}
          />
        )
      case 2:
        return <ChangeMembershipPurchase selectedMembership={selectedMembership} />
      case 3:
        return <ChangePlanConfimation />
      default:
        return null
    }
  }

  const handleMembershipPlanSelected = (membershipSlug: MembershipTypes) => {
    if (membershipPlans) {
      const found = membershipPlans.find((x) => x.Slug === membershipSlug)
      if (found) {
        setPayload((prev) => ({ ...prev, MembershipId: found.Id }) as ChangeMembershipPayload)
        setSelectedMembership(found)
        handleContinueClick()
      }
    }
  }
  const nextButtonLabel = () => {
    switch (activeStep) {
      case 1:
        return 'Next'
      case 2:
        return 'Purchase'
      case 3:
        return 'Close'
    }
  }

  const handleContinueClick = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + (!!payload?.PaymentMethodId ? 2 : 1))
      return
    }
    if (activeStep === 1) {
      handleAddPaymentSubmit((data) => {
        attachPaymentMethod(data, true, () => {
          addPaymentReset()
          setPayload(
            (prev) => ({ ...prev, PaymentMethodId: DefaultPaymentMethod?.PaymentMethodId }) as ChangeMembershipPayload
          )
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        })
      })()
      return
    }
    if (activeStep === 2) {
      ChangeMembership()
    } else if (activeStep === 3) {
      closeDialog()
      return
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  const handleBackClick = () => {
    if (activeStep === 0) {
      closeDialog()
      return
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }
  useEffect(() => {
    setPayload(new ChangeMembershipPayload({ UserId, PaymentMethodId: DefaultPaymentMethod?.PaymentMethodId }))
    getMembershipPlans()
  }, [])

  return (
    <>
      <AppBox>
        <AppBox paddingBottom={0}>
          <Stepper
            activeStep={activeStep}
            connector={<Divider sx={{ width: 40, marginX: 0.5, borderWidth: 1, borderColor: 'primary.600' }} />}
            sx={{ justifyContent: 'center' }}
          >
            {steps.map((step, index) => {
              const stepProps: StepProps = {}
              const labelProps: StepLabelProps = {}
              return (
                <Step key={step} {...stepProps}>
                  <StepLabel
                    icon={
                      <AppBox
                        width={25}
                        height={25}
                        backgroundColor="primary.100"
                        borderRadius={12}
                        textAlign={'center'}
                        justifySelf={'center'}
                        paddingTop={0.2}
                      >
                        {activeStep > index ? (
                          <AppIcon icon={'material-symbols:check'} size="medium" color="primary.600" />
                        ) : (
                          <AppTypography fontSize={14}>{index + 1}</AppTypography>
                        )}
                      </AppBox>
                    }
                    {...labelProps}
                  >
                    <AppTypography fontSize={16} color={'primary.600'} fontWeight={600}>
                      {step}
                    </AppTypography>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </AppBox>
        <AppBox paddingY={2}>{renderStepsContent()}</AppBox>
        <AppBox If={activeStep > 0}>
          <AppBox display={'flex'} gap={2} justifyContent={'flex-end'}>
            <AppButton
              variant="outlined"
              onClick={() => handleBackClick()}
              size="large"
              color="primary"
              sidePaddings={6}
            >
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </AppButton>
            <AppButton
              sidePaddings={6}
              variant="contained"
              size="large"
              isLoading={isChangingMembersip || isAddingPaymentMethod}
              onClick={() => handleContinueClick()}
            >
              {nextButtonLabel()}
            </AppButton>
          </AppBox>
        </AppBox>
      </AppBox>
    </>
  )
}
