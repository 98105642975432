import { Grid } from '@mui/material'
import { AppBox, AppButton, AppAutoComplete } from 'components/base'
import { Form, useForm } from 'react-hook-form'
import { CrmCompanyViewModel, CrmcontactLinkedCompanyPayload } from 'sdk'

interface Props {
  linkCompany: (data: CrmcontactLinkedCompanyPayload) => void
  isAddingLinkedCompany: boolean
  unLinkedCompanies: CrmCompanyViewModel[]
  isLoadingUnLinkedLinkedCompanies: boolean
  contactId: string
}
export const AddContactCompany = ({
  contactId,
  linkCompany: addCompany,
  isAddingLinkedCompany,
  unLinkedCompanies,
  isLoadingUnLinkedLinkedCompanies,
}: Props) => {
  const { control, reset } = useForm<CrmcontactLinkedCompanyPayload>({ defaultValues: { ContactId: contactId } })

  const handleSubmit = (data: CrmcontactLinkedCompanyPayload) => {
    reset({ ContactId: contactId })
    addCompany(data)
  }
  return (
    <Form control={control} onSubmit={({ data }) => handleSubmit(data)}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={9}>
          <AppAutoComplete
            options={unLinkedCompanies?.map((item) => ({ label: item.Name, value: item.Id })) ?? []}
            fullWidth
            isLoading={isLoadingUnLinkedLinkedCompanies}
            placeholder="Search Companies"
            control={control}
            name={'CompanyId'}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <AppButton
            isLoading={isAddingLinkedCompany}
            sidePaddings={3}
            variant="contained"
            // prefixIcon="tabler:plus"
            size="large"
            type="submit"
          >
            Add
          </AppButton>
        </Grid>
      </Grid>
    </Form>
  )
}
