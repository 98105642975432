import { AppBox, AppTypography, SimpleCheckBox } from 'components'
import { Dispatch, FC, SetStateAction } from 'react'
interface Props {
  name: string
  isAgreed: boolean
  setTermsAgreed: Dispatch<SetStateAction<boolean>>
}
export const GettingStartedTerms: FC<Props> = ({ name, isAgreed, setTermsAgreed }) => {
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2}>
      <AppTypography color={'grey.500'} fontSize={14}>
        {name}, have you ever had a tenant steal the appliances from your property? It's not much fun and costs everyone
        including the other tenants. After all, just because you let them use it doesn't mean they can take it as their
        own.
      </AppTypography>
      <AppTypography color={'grey.500'} fontSize={14} component={'span'}>
        Since you're new to the site, we just wanted to remind you that despite popular belief, website scraping{' '}
        <AppTypography fontWeight={600} fontSize={14} component={'span'}>
          IS ILLEGAL
        </AppTypography>{' '}
        when the information is proprietary to the site.
      </AppTypography>
      <AppTypography color={'grey.500'} fontSize={14}>
        Our system monitors this type of malicious behavior. Our staff also has a zero-tolerance policy for anyone
        attempting to scrape our records.
      </AppTypography>
      <AppTypography color={'grey.500'} fontSize={14}>
        We also want to remind you that by using our site you agree to follow our{' '}
        <AppTypography
          component={'a'}
          fontSize={14}
          color={'primary.200'}
          onClick={() => window.open('https://parksites.io/terms-of-use', '_blank')}
        >
          Terms of Use.
        </AppTypography>
      </AppTypography>
      <AppBox display={'flex'} alignItems={'center'}>
        <SimpleCheckBox
          defaultChecked={isAgreed}
          value={isAgreed}
          onClick={() => setTermsAgreed((prev: boolean) => !prev)}
        />
        <AppTypography fontSize={14} fontWeight={500} color={'grey.600'}>
          I have read and agree to the
          <AppTypography
            component={'a'}
            fontSize={14}
            color={'primary.200'}
            onClick={() => window.open('https://parksites.io/terms-of-use', '_blank')}
          >
            Terms of Use.
          </AppTypography>
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
