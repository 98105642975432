import { AppBox } from 'components'
import { CityDataTable } from './CityDataTable'
import { PaginationFilterModel } from 'sdk'
import { Dispatch, FC, SetStateAction } from 'react'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const CityDataListing: FC<Props> = ({ filter, setFilter }) => {
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <CityDataTable />
        {/* <AppBox paddingX={2}>
          <AppPagination />
        </AppBox> */}
      </AppBox>
    </>
  )
}
