import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'
import { FeedbackPayload, FeedbackViewModel, PaginatedApiResponseModel, PaginationFilterModel } from 'sdk/models'

export class FeedBackApi extends BaseApi {
  public AddFeedback(obj: FeedbackPayload) {
    return this.POST_Request<FeedbackViewModel>(apiUrls.feedback.addFeedback(), obj)
  }
  public GetAllFeedBacks(filter: PaginationFilterModel) {
    return this.GET_Request<PaginatedApiResponseModel<FeedbackViewModel>>(apiUrls.feedback.getAllFeedbacks(filter))
  }
}
