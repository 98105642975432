import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, TableActions } from 'components'
import { FC } from 'react'
import { CrmNoteViewModel } from 'sdk'
interface Props extends AppTableProps<CrmNoteViewModel> {
  onEditClick: (data: CrmNoteViewModel) => void
  onDeleteClick: (Id: string) => void
}
export const CrmNotesTable: FC<Props> = ({ onEditClick, onDeleteClick, ...props }) => {
  const columns: ColumnDef<CrmNoteViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Edit', onClick: () => onEditClick(row.original) },
              { text: 'Delete', onClick: () => onDeleteClick(row.original.Id!) },
            ]}
          />
        )
      },
    },
    {
      header: 'Date',
      accessorKey: 'Date',
      size: 1,
    },

    {
      header: 'Comments',
      accessorKey: 'Note',
      size: 1,
    },
    {
      header: 'Created By',
      accessorKey: 'Address',
      size: 1,
    },
  ]
  return <AppTable columns={columns} {...props} />
}
