import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ParkSiteMapPin, ParkSitesApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { GoogleMapSliceSelector, setIsLoadingMap, setParkPins } from 'store/slices'

const parkSitesApiService = new ParkSitesApi()
export const useGetParkPins = () => {
  const appDispatch = useAppDispatch()
  const { ParkPins } = useSelector(GoogleMapSliceSelector)

  const { mutateAsync: getParkPins } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchAllParkLabels],
    mutationFn: parkSitesApiService.GetAllParkMarkerPins,
    onSuccess: (data) => {
      handleParkPinsSuccess(data)
    },
  })

  const handleParkPinsSuccess = (data: ParkSiteMapPin[]) => {
    if (data) {
      appDispatch(setParkPins(data))
    }
    appDispatch(setIsLoadingMap(false))
  }

  const fetchParkPins = useCallback(() => {
    if (ParkPins.length === 0) {
      appDispatch(setIsLoadingMap(true))
    }
    return getParkPins()
  }, [])

  return {
    getParkPins: fetchParkPins,
  }
}
