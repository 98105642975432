import { FormControlLabel, Typography, TypographyProps } from '@mui/material'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { AppTypography } from 'components'
export interface SimpleCheckBoxProps
  extends Omit<CheckboxProps, 'label' | 'name' | 'required' | 'error' | 'helperText'> {
  label?: string
  labelTextProps?: TypographyProps
}

export const SimpleCheckBox = ({ label, labelTextProps, value, checked, ...props }: SimpleCheckBoxProps) => {
  return (
    <>
      <FormControlLabel
        checked={checked}
        control={<Checkbox color="primary" value={!!value} {...props} checked={checked} />}
        label={<AppTypography {...labelTextProps}>{label ?? ''}</AppTypography>}
      />
    </>
  )
}
