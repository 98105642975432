import { Grid } from '@mui/material'
import { AdminSettingToolBar } from './toolbar/AdminSettingToolBar'
import { AdminSettingContent } from './content/AdminSettingContent'

export const AdminSettings = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AdminSettingToolBar />
        </Grid>
        <Grid item xs={12} position={'relative'} padding={2}>
          <AdminSettingContent />
        </Grid>
      </Grid>
    </>
  )
}
