import { Grid } from '@mui/material'
import {
  AppAutoComplete,
  AppBox,
  AppButton,
  AppMenu,
  AppTextField,
  AppTypography,
  InfoBox,
  PhoneField,
  StateField,
  ZipField,
} from 'components'
import { PeopleInsightRolesOptions } from 'globals/constants'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { PeopleInsightPayload, PeopleInsightViewModel } from 'sdk'
import { PeopleInsightAdminAddPossibleMatches } from './possible-matches/PeopleInsightAdminAddPossibleMatches'

interface Props {
  control: Control<PeopleInsightPayload>
  possibleInsights: PeopleInsightViewModel[]
  onGetPossibleMatches: (field: string) => void
  handleUseClick: (data: PeopleInsightViewModel) => void
}

export const PeopleInsightAdminAddNewForm: FC<Props> = ({
  control,
  onGetPossibleMatches,
  handleUseClick,
  possibleInsights,
}) => {
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2}>
      <InfoBox>
        Our goal is to provide the most accurate details about people related to each park. Enter the person’s
        information and then search to ensure our records don’t already reference this person in our system.
      </InfoBox>
      <AppBox backgroundColor="grey.50" border={1} borderColor={'grey.200'} borderRadius={6} padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <AppAutoComplete
              isSmallForm={true}
              required
              control={control}
              name="Role"
              label="Role"
              placeholder="Select Role"
              options={PeopleInsightRolesOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              isSmallForm={true}
              control={control}
              name="CompanyName"
              label="Company"
              placeholder="Enter Company"
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <AppTextField
              isSmallForm={true}
              required
              control={control}
              name="FirstName"
              label="First Name"
              placeholder="Enter First Name"
            />
          </Grid>
          <Grid item xs={3}>
            <AppTextField
              required
              isSmallForm={true}
              control={control}
              name="LastName"
              label="Last Name"
              placeholder="Enter Last Name"
            />
          </Grid>
          <Grid item xs={3}>
            <AppTextField isSmallForm={true} control={control} name="Email" label="Email" placeholder="Enter Email" />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <AppTextField
              required
              isSmallForm={true}
              control={control}
              name="Address"
              label="Address"
              placeholder="Enter Address"
            />
          </Grid>
          <Grid item xs={3}>
            <AppTextField
              required
              isSmallForm={true}
              control={control}
              name="City"
              label="City"
              placeholder="Enter City"
            />
          </Grid>
          <Grid item xs={3}>
            <StateField
              required
              isSmallForm={true}
              control={control}
              name="StateId"
              label="State"
              placeholder="Select State"
            />
          </Grid>
          <Grid item xs={3}>
            <ZipField required isSmallForm={true} control={control} name="Zip" label="Zip" placeholder="Enter Zip" />
          </Grid>
          <Grid item xs={3}>
            <PhoneField
              required
              isSmallForm={true}
              control={control}
              name="Phone"
              label="Phone"
              placeholder="Enter Phone"
            />
          </Grid>
          <Grid item xs={3}>
            <PhoneField
              isSmallForm={true}
              control={control}
              name="AltPhone"
              label="Alt Phone"
              placeholder="Enter Alt Phone"
            />
          </Grid>
        </Grid>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2}>
        <AppTypography fontWeight={'bold'} fontSize={14} component={'span'}>
          Possible Matches
        </AppTypography>
        <AppMenu
          MenuItems={['Company Name', 'First Name', 'Last Name', 'Address', 'Email', 'Phone', 'Alt Phone'].map((x) => ({
            text: x,
            onClick: () => {
              onGetPossibleMatches(x)
            },
          }))}
          renderElement={(open) => (
            <AppBox
              onClick={(e) => {
                open(e)
              }}
            >
              <AppButton
                variant="contained"
                prefixIcon="iconamoon:search-bold"
                size="small"
                suffixIcon="mdi:chevron-down"
              >
                Search
              </AppButton>
            </AppBox>
          )}
        />
      </AppBox>
      <PeopleInsightAdminAddPossibleMatches
        subRowsKey="RelatedParks"
        data={possibleInsights}
        onUseClick={handleUseClick}
      />
    </AppBox>
  )
}
