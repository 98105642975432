import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, TeamStatus } from 'globals/constants'
import { jwtDecode } from 'jwt-decode'
import { useSelector } from 'react-redux'
import { AuthApi, DecodedTokenViewModel, SessionModel, TeamApi, TeamViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  AuthSliceSelector,
  TeamSliceSelector,
  setIsChangingTeam,
  setOwnTeam,
  setSelectedTeam,
  setSession,
  setUserTeams,
} from 'store/slices'

const teamApi = new TeamApi()
const authApi = new AuthApi()

export const useGetTeam = () => {
  const dispatch = useAppDispatch()
  const { SelectedTeam, UserTeams } = useSelector(TeamSliceSelector)
  const { CurrentUser } = useSelector(AuthSliceSelector)

  const { mutate: getTeams } = useMutation({
    mutationKey: [ApiQueryKeys.team.FetchUserTeams],
    mutationFn: () => teamApi.getUserTeams(),
    onSuccess: (teams) => handleGetTeamsSuccess(teams),
  })

  const { mutate: changeTeam } = useMutation({
    mutationKey: [ApiQueryKeys.auth.getTeamAuthToken],
    mutationFn: (data: string) => authApi.getTeamAuthToken(data),
    onSuccess: (data) => handleTeamChangeSuccess(data),
    onError: () => dispatch(setIsChangingTeam(false)),
  })
  const handleGetTeamsSuccess = (teams: TeamViewModel[]) => {
    if (teams && teams.length > 0) {
      dispatch(setUserTeams(teams))
      const ownTeam = teams.find((team) => team.IsDefault)
      dispatch(setOwnTeam(ownTeam!))
      const isSelected = teams.find((x) => x.Id === SelectedTeam?.Id)
      if (!isSelected && ownTeam?.Status === TeamStatus.Activated) {
        dispatch(setSelectedTeam(ownTeam!))
      }
      // if (ownTeam?.Status === TeamStatus.Activated) {
      //   dispatch(setSelectedTeam(ownTeam))
      // } else if (teams.length > 1) {
      //   dispatch(setSelectedTeam(teams.find((x) => !x.IsDefault)!))
      // }
    }
  }
  const handleTeamChangeSuccess = (token: string) => {
    dispatch(setIsChangingTeam(false))
    const copy: SessionModel = JSON.parse(JSON.stringify(CurrentUser))
    copy.JwtToken = token
    const decoded: DecodedTokenViewModel = jwtDecode(copy.JwtToken!)
    const newSelectedTeam = UserTeams?.find((x) => x.Id === decoded.TeamId)
    if (newSelectedTeam) {
      dispatch(setSelectedTeam(newSelectedTeam!))
      dispatch(setSession(copy))
    }
  }
  const handleSwitchTeam = (teamId: string) => {
    dispatch(setIsChangingTeam(true))
    changeTeam(teamId)
  }
  return {
    getTeams,
    handleSwitchTeam,
  }
}
