import { Grid } from '@mui/material'
import {
  AddEditNoteForm,
  AppBox,
  AppButton,
  AppDialog,
  AppTypography,
  BaseComponent,
  InfoBox,
  NoteListView,
} from 'components'
import { NoteListViewLoader } from 'components/shared/loaders/NoteListViewLoader'
import { InfoBoxSettingTypes } from 'globals/constants'
import { useCrmNotesCRUD } from 'hooks'

interface Props {
  type: 'Contact' | 'Company'
  contactId?: string | undefined
  companyId?: string | undefined
}
export const CrmNotesHelpModule = (props: Props) => {
  const {
    notes,
    isAddingCrmNote,
    isEditingNote,
    idToBeEdited,
    isLoadingNotes,
    isRemovingNote,
    dialogRef,
    handleAddClicked,
    handleDeleteClicked,
    handleEditClicked,
    handleFormSubmit,
    control,
    handleSubmit,
  } = useCrmNotesCRUD({ type: props.type, contactId: props.contactId, companyId: props.companyId })
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.CrmNoteHelpWindowDismissed}>
          Add Notes for this {!!props.contactId ? 'contact' : 'company'} to keep track of important information you may
          want to remember
        </InfoBox>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={9}>
            <AppTypography>
              Add new notes to this contact by clicking the <b>Add</b> button
            </AppTypography>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} justifyContent={'flex-end'}>
              <AppButton variant="contained" size="large" onClick={handleAddClicked} sidePaddings={6}>
                Add
              </AppButton>
            </AppBox>
          </Grid>
        </Grid>
        <BaseComponent
          isLoading={isLoadingNotes || isRemovingNote}
          isData={!!notes}
          loaderComponent={
            <>
              <NoteListViewLoader />
            </>
          }
        >
          <AppTypography fontSize={14} fontWeight={500}>
            Notes ({notes?.length})
          </AppTypography>
          {notes?.map((note) => (
            <NoteListView note={note} handleEditClicked={handleEditClicked} handleDeleteClicked={handleDeleteClicked} />
          ))}
        </BaseComponent>
      </AppBox>
      <AppDialog
        title={idToBeEdited ? 'Edit Note' : 'Add Note'}
        ref={dialogRef}
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(handleFormSubmit),
        }}
        submitButtonProps={{
          isLoading: isAddingCrmNote || isEditingNote,
        }}
      >
        <AddEditNoteForm control={control} />
      </AppDialog>
    </>
  )
}
