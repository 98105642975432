export class ParkSiteImagesPayload {
  public ParkId?: string | null = null
  public DeletedImageIds: Array<string> | null = null
  public OldImagesIds: Array<{ Id: string; Sequence: number }> | null = null
  public NewImages: Array<{ file: File; UniqueKey: string; Sequence: number }> | null = null

  constructor(data?: Partial<ParkSiteImagesPayload>) {
    Object.assign(this, data)
  }
}

export class GetParkSiteImagesModel {
  public Id: string | null = null
  public Url: string | null = null
  public Sequence: number | null = null

  constructor(data?: Partial<GetParkSiteImagesModel>) {
    Object.assign(this, data)
  }
}

export interface ParkImageInternal {
  id: string | null
  sequence: number
  url: string
  uniqueId: string
  file?: File
}
