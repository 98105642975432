import { AppBar, Toolbar } from '@mui/material'
import { AppBox, AppButton, AppMenu, AppTypography, ViewsSelectBtn } from 'components'
import { CrmHelpModules, CrmTools } from 'globals/constants'
import { useCrmContacts } from 'hooks'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { CrmToolSliceSelector, setCrmListingHelpModule, setCrmTool } from 'store/slices'
const links = [
  { title: 'Contacts', icon: 'material-symbols:contacts-product', key: CrmTools.Contacts },
  { title: 'Companies', icon: 'mdi:company', key: CrmTools.Companies },
  { title: 'Import', icon: 'lets-icons:import-fill', key: CrmTools.Import },
]
export const CrmToolBar = () => {
  const { openedCrmTool, openedCrmListingHelpModule } = useSelector(CrmToolSliceSelector)

  const dispatch = useAppDispatch()
  const { handleExportCrmContacts } = useCrmContacts({})

  const handleButtons = (key: string) => {
    dispatch(setCrmTool(key))
  }

  return (
    <>
      <AppBox flexGrow={1} borderBottom={1} borderColor={'grey.100'} backgroundColor="white" minHeight={'inherit'}>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar>
            <AppBox flex={1}>
              <AppTypography fontSize={24} fontWeight={700}>
                CRM / {openedCrmTool}
              </AppTypography>
            </AppBox>
            <AppBox>
              <AppBox display={'flex'} gap={2}>
                {links.map((link, index) => (
                  <AppButton
                    key={index}
                    color={openedCrmTool === link.key ? 'primary' : 'secondary'}
                    prefixIcon={link.icon}
                    onClick={() => handleButtons(link.key)}
                  >
                    {link.title}
                  </AppButton>
                ))}
                {[CrmTools.Companies, CrmTools.Contacts].includes(openedCrmTool) && (
                  <AppMenu
                    MenuItems={[
                      {
                        text: 'All Leads',
                        prefixIcon: 'lets-icons:export-fill',
                        onClick: () => handleExportCrmContacts({ ContactType: 'Lead' }),
                      },
                      {
                        text: 'All Contacts',
                        prefixIcon: 'lets-icons:export-fill',
                        onClick: () => handleExportCrmContacts({ ContactType: 'Contact' }),
                      },
                      {
                        text: 'All Records',
                        prefixIcon: 'lets-icons:export-fill',
                        onClick: () => handleExportCrmContacts({ Ids: [], ContactType: '' }),
                      },
                    ]}
                    renderElement={(open) => (
                      <AppButton
                        color="secondary"
                        prefixIcon={'ph:export-fill'}
                        suffixIcon="mingcute:down-line"
                        onClick={open}
                      >
                        Export
                      </AppButton>
                    )}
                  />
                )}
                <AppButton
                  color={openedCrmListingHelpModule?.key === CrmHelpModules.ManageTags ? 'primary' : 'secondary'}
                  prefixIcon={'mdi:tags'}
                  onClick={() =>
                    dispatch(
                      setCrmListingHelpModule({
                        key: CrmHelpModules.ManageTags,
                        options: { Title: 'Manage Tags' },
                      })
                    )
                  }
                >
                  Manage Tags
                </AppButton>
                <ViewsSelectBtn />
              </AppBox>
            </AppBox>
          </Toolbar>
        </AppBar>
      </AppBox>
    </>
  )
}
