import {
  CircularProgress,
  FilledInputProps,
  InputAdornment,
  InputProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Message, ValidationRule } from 'react-hook-form'
import { AppBox } from 'components/base/box/AppBox'
import { forwardRef, ReactNode } from 'react'
import InputMask from 'react-input-mask'
import { AppIcon, AppIconProps, AppToolTip, AppToolTipProps, AppTypography } from 'components'
import { NumericFormat as NumberFormat } from 'react-number-format'

export interface AppSimpleFieldProps
  extends Omit<TextFieldProps, 'label' | 'name' | 'required' | 'error' | 'helperText' | 'InputProps'> {
  prefixIcon?: string
  suffixIcon?: string
  label?: string
  LabelProps?: TypographyProps
  prefixIconProps?: Omit<AppIconProps, 'icon'>
  suffixIconProps?: Omit<AppIconProps, 'icon'>
  required?: Message | ValidationRule<boolean>
  error?: boolean
  errorText?: string
  hideDefaultErrorText?: boolean
  isLoading?: boolean
  mask?: string
  labelIcon?: string
  labelIconProps?: Omit<AppIconProps, 'icon'>
  isSmallForm?: boolean
  toolTipMessage?: string | ReactNode
  tooltipProps?: AppToolTipProps
  commaSeparated?: boolean
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | undefined
}

export const SimpleField = forwardRef(
  (
    {
      prefixIcon,
      suffixIcon,
      prefixIconProps,
      suffixIconProps,
      label,
      LabelProps,
      errorText,
      hideDefaultErrorText = true,
      required,
      isLoading,
      mask,
      labelIcon,
      labelIconProps,
      onChange,
      onBlur,
      value,
      isSmallForm = false,
      toolTipMessage,
      tooltipProps,
      commaSeparated = false,
      ...props
    }: AppSimpleFieldProps,
    ref
  ) => {
    const textField = (
      <TextField
        size="small"
        variant="outlined"
        color="secondary"
        fullWidth={true}
        {...props}
        style={{ ...props.style, marginTop: 4, ...(isSmallForm ? { flex: 2 } : {}) }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        InputProps={{
          ...props.InputProps,
          ref,
          style: {
            borderRadius: isSmallForm ? '6px' : '10px',
            color: '#64748B',
            ...(isSmallForm ? { height: '22px' } : {}),
            ...props.InputProps?.style,
          },
          ...(prefixIcon
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <AppIcon icon={prefixIcon} {...prefixIconProps} />
                  </InputAdornment>
                ),
              }
            : {}),
          ...(suffixIcon || isLoading
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {suffixIcon ? (
                      <AppIcon icon={suffixIcon} {...suffixIconProps} />
                    ) : (
                      <CircularProgress color="primary" size={25} />
                    )}
                  </InputAdornment>
                ),
              }
            : {}),
          ...(!!commaSeparated
            ? {
                inputComponent: NumberFormatCustom,
              }
            : {}),
        }}
      />
    )
    return (
      <>
        <AppBox
          display="flex"
          flexDirection={isSmallForm ? 'row' : 'column'}
          width={'100%'}
          alignItems={!isSmallForm ? 'flex-start' : 'center'}
          gap={isSmallForm ? 2 : 0}
          borderRadius={'10px'}
        >
          <AppBox display="flex" flexDirection="row" justifyContent="space-between">
            {label && (
              <AppTypography
                component={'span'}
                fontWeight={'bold'}
                {...LabelProps}
                style={{ ...(isSmallForm ? { fontWeight: 'normal', flex: 3 } : {}) }}
              >
                {label}
                {required && (
                  <AppTypography component={'span'} color={'red'} paddingLeft={1}>
                    *
                  </AppTypography>
                )}
                {toolTipMessage && (
                  <AppToolTip placement="right" {...tooltipProps}>
                    {toolTipMessage}
                  </AppToolTip>
                )}
              </AppTypography>
            )}
            {labelIcon && <AppIcon icon={labelIcon!} {...labelIconProps} />}
          </AppBox>
          {mask ? (
            <InputMask mask={mask} onChange={onChange} onBlur={onBlur} value={value as any}>
              {textField}
            </InputMask>
          ) : (
            <>{textField}</>
          )}
        </AppBox>
        <AppTypography color="red">{errorText ? errorText : !hideDefaultErrorText && '\u00A0'}</AppTypography>
      </>
    )
  }
)

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }
      }}
      thousandSeparator
      isNumericString
    />
  )
}
