import { IconButton, Typography } from '@mui/material'
import { AppBox, AppButton, AppButtonProps, AppIcon, AppMenu } from 'components'

export interface TableActionsProps {
  row: any
  menuIcon?: string
  actions?: { text: string; onClick: () => void }[]
  buttonProps?: AppButtonProps
}
export const TableActions = ({ row, actions }: TableActionsProps) => {
  return (
    <AppMenu
      renderElement={(handleClick) => (
        <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
          <AppIcon icon="pepicons-pencil:dots-y" size="large" />
        </IconButton>
      )}
      MenuItems={actions ?? []}
    />
  )
}
