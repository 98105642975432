import { Grid, LinearProgress } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppDialog, AppDialogRefType, InfoBox } from 'components'
import { ApiQueryKeys, InfoBoxSettingTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, forwardRef, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ParkDetailViewModel, ParkFieldsDataViewModel, ParkSitesApi, ReportParkPayload } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'
import { UpdateParkBasicCard } from './cards/UpdateParkBasicCard'
import { UpdateParkCommentSection } from './cards/UpdateParkCommentSection'
import { UpdateParkInfoCard } from './cards/UpdateParkInfoCard'
import { UpdateParkMHLotsCard } from './cards/UpdateParkMHLotsCard'
import { UpdateParkRVLotCard } from './cards/UpdateParkRVLotCard'
import { UpdateParkUtilitiesCard } from './cards/UpdateParkUtilitiesCard'

interface Props {
  parkDetail: ParkDetailViewModel | undefined
  isLoading: boolean
}
export interface ReportParkFormRefType {
  openDialog?: () => void
}
export const ReportParkForm = forwardRef(({ parkDetail, isLoading }: Props, ref) => {
  const parkSiteApi = new ParkSitesApi()
  const { enqueueSnackbar } = useSnackbar()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const dialogRef = useRef<AppDialogRefType>(null)
  const { control, getValues, setValue, handleSubmit, reset, watch } = useForm<ReportParkPayload>({
    defaultValues: {
      Id: selectedParkId,
      City: parkDetail?.City,
      State: parkDetail?.State,
      Zip: parkDetail?.Zip,
      County: parkDetail?.County,
    },
    mode: 'onChange',
  })

  const { mutate: ReportPark, isPending: isReporting } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.ReportPark],
    mutationFn: () => parkSiteApi.sendParkReport(watch()),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Your information has been sent to our support team for review.', { variant: 'success' })
      reset({
        Id: selectedParkId,
      })
      dialogRef.current?.closeDialog!()
    },
  })

  const openDialog = () => {
    if (!!parkDetail) {
      const clone = JSON.parse(JSON.stringify(parkDetail)) as ParkDetailViewModel
      clone.ParkFieldsDataObj = new ParkFieldsDataViewModel(
        clone.ParkFieldsData.map((x) => {
          if (x.Value != null) {
            return { [`${x.Key}`]: x.Value }
          }
          return {}
        }).reduce((r, c) => Object.assign(r, c))
      )

      reset(clone)
    }
    dialogRef.current?.openDialog!()
  }

  if (ref) {
    ;(ref as any).current = { openDialog }
  }

  return (
    <>
      <AppDialog
        ref={dialogRef}
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit((data) => {
            const clone = JSON.parse(JSON.stringify(data.ParkFieldsDataObj)) as any
            Object.keys(data.ParkFieldsDataObj).forEach((parkKey) => {
              data.ParkFieldsData?.map((park) => {
                if (park.Key === parkKey) {
                  park.Value = clone![parkKey]
                }
              })
            })

            ReportPark()
          }),
        }}
        submitButtonProps={{
          isLoading: isReporting,
          children: 'Send',
        }}
        title={'Report Park'}
        maxWidth={'lg'}
        onClose={() => reset({})}
      >
        <AppBox display="flex" flexDirection="column" gap={2}>
          {isLoading && <LinearProgress />}
          <InfoBox dismissable settingUniqueKey={InfoBoxSettingTypes.ReportParkDismissed}>
            Our goal is to provide the most recent details available for each park. Correct the fields below so we know
            where we may have gone wrong in our staff’s research. Once you click submit, we’ll review our records and
            update this park accordingly.
          </InfoBox>
          <UpdateParkInfoCard control={control} watch={watch} parkDetail={parkDetail} isAdmin={false} />
          <UpdateParkBasicCard control={control} />
          <Grid container columnGap={2}>
            <UpdateParkMHLotsCard control={control} />
            <UpdateParkRVLotCard control={control} setValue={setValue} getValue={getValues} watch={watch} />
            <UpdateParkUtilitiesCard control={control} isAdmin={false} />
          </Grid>
          <UpdateParkCommentSection control={control} />
        </AppBox>
      </AppDialog>
    </>
  )
})
