import { Grid } from '@mui/material'
import { AppAutoComplete, AppBox, AppTypography } from 'components'
import {
  HeatingFuelTypes,
  SewerTypes,
  StreetOwnedByTypes,
  StreetTypes,
  TrashServiceTypes,
  WaterSourceTypes,
} from 'globals/constants'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { ParkUpdatePayload, ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ParkUpdatePayload> | Control<ReportParkPayload>
  isAdmin: boolean
}

export const UpdateParkUtilitiesCard: FC<Props> = ({ control, isAdmin }) => {
  return (
    <>
      <Grid
        item
        xs={4}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        borderRadius={3}
        border={1}
        borderColor={'grey.200'}
        paddingX={2}
        paddingY={1}
        sx={{ backgroundColor: 'grey.50' }}
      >
        <AppTypography fontWeight={'bold'} fontSize={16}>
          Utilities & Streets
        </AppTypography>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Water Source{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={WaterSourceTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.ParkUtilityWaterSource"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Sewer Type{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={SewerTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.ParkUtilitySewerType"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Heating Fuel{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={HeatingFuelTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.ParkUtilityHeatingFuel"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Trash Service{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={TrashServiceTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.ParkUtilityTrashService"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Street Type{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={StreetTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.StreetAndRoadsStreetType"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Street Owned By{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={6}>
            <AppAutoComplete
              required={isAdmin}
              options={StreetOwnedByTypes}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.StreetAndRoadsOwnedBy"
              control={control}
            />
          </Grid>
        </AppBox>
      </Grid>
    </>
  )
}
