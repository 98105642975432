import { FilterPayload, FilterViewModel } from 'sdk'
import { BaseApi } from '../base.api'
import { apiUrls } from 'sdk/api-urls'

export class FilterApi extends BaseApi {
  public AddFilter(Filter: FilterPayload) {
    return this.POST_Request<FilterViewModel>(apiUrls.filter.addFilter(), Filter)
  }
  public DeleteFilter(filterId: string) {
    return this.DELETE_Request<Array<FilterViewModel>>(apiUrls.filter.deleteFilter(filterId))
  }
  public UpdateFilterName(filterId: string, newName: string) {
    return this.PATCH_Request<Array<FilterViewModel>>(apiUrls.filter.updateFilterName(filterId, newName), {})
  }
  // public GetFilterById(filterId: string) {
  //     return this.GET_Request<Array<FilterConditionSaveModel>>(`${this.ApiUrl}/Filter/${filterId}`);
  // }

  // public GetContactFilters() {
  //     return this.GET_Request<Array<FilterViewModel>>(`${this.ApiUrl}/Filter/GetContactFilters`);
  // }
  // public GetCompanyFilters() {
  //     return this.GET_Request<Array<FilterViewModel>>(`${this.ApiUrl}/Filter/GetCompanyFilters`);
  // }
  // public ApplyParkFilter(filters: Array<FilterConditionSaveModel>) {
  //     return this.POST_Request<Array<ParksInAreaSpViewModel | CrmContactsViewModel>>(`${this.ApiUrl}/Filter/ApplyParkFilter`, filters);
  // }
  // public ApplyContactFilter(filters: Array<FilterConditionSaveModel>) {
  //     return this.POST_Request<Array<ParksInAreaSpViewModel>>(`${this.ApiUrl}/Filter/ApplyContactFilter`, filters);
  // }
  // public ApplyCompanyFilter(filters: Array<FilterConditionSaveModel>) {
  //     return this.POST_Request<Array<ParksInAreaSpViewModel>>(`${this.ApiUrl}/Filter/ApplyCompanyFilter`, filters);
  // }
}
