import { AppBox } from 'components'
import { filter } from 'lodash'
import { CityDataListing } from './listing/CityDataListing'
import { CityDataActions } from 'views'
import { useState } from 'react'
import { PaginationFilterModel } from 'sdk'

export const ManageCities = () => {
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  const [AddedNew, setAddedNew] = useState<boolean>(false)

  return (
    <>
      <AppBox minHeight={'inherit'}>
        <AppBox paddingX={2}>
          <CityDataActions filter={filter} setFilter={setFilter} />
        </AppBox>
        <AppBox paddingX={2} minHeight={'inherit'}>
          <CityDataListing filter={filter} setFilter={setFilter} />
        </AppBox>
      </AppBox>
    </>
  )
}
