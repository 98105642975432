import { Divider, Typography } from '@mui/material'
import { AppBox, AppButton, AppCard, AppDialog, AppTypography, BaseComponent, PinnedParkSkeleton } from 'components'

import { AddEditParkTagForm } from './AddEditParkTagForm'
import { ParkTagsAppTable } from './ParkTagsTable'
import { useTaggedParks } from 'hooks'
import { TagTypes } from 'globals/constants'

export const TaggedParksManage = () => {
  const {
    isGettingTags,
    data,
    dialogRef,
    watch,
    reset,
    isAddingTag,
    isEditingTag,
    isDeleting,
    control,
    handleFormSubmit,
    handleSubmit,
    handleDeleteParkTag,
    handleEditTag,
  } = useTaggedParks(TagTypes.PARK_TAG)
  return (
    <>
      <BaseComponent
        isLoading={isGettingTags}
        loaderComponent={<PinnedParkSkeleton />}
        isData={!!data}
        noDataComponent={<></>}
      >
        <AppBox paddingTop={2} paddingX={1.5} paddingBottom={2}>
          <AppCard variant="outlined" raised>
            <AppBox height={'63vh'} paddingX={1} display={'flex'} flexDirection={'column'} gap={1}>
              <AppBox
                minHeight={'5vh'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <AppTypography fontWeight={'bold'} fontSize={14}>
                  Tags
                </AppTypography>
                <AppDialog
                  ref={dialogRef}
                  title={(watch().Id ? 'Edit' : 'Add') + ' Tag'}
                  renderElement={(openDialog) => (
                    <AppButton
                      prefixIcon="ic:baseline-plus"
                      variant="contained"
                      isLoading={isAddingTag}
                      onClick={() => {
                        reset()
                        openDialog()
                      }}
                    >
                      Add New
                    </AppButton>
                  )}
                  renderChild={(openDialog) => <AddEditParkTagForm control={control} />}
                  appDialogWrapperProps={{
                    component: 'form',
                    role: 'form',
                    onSubmit: handleSubmit(handleFormSubmit),
                  }}
                  submitButtonProps={{
                    isLoading: isAddingTag || isEditingTag,
                  }}
                />
              </AppBox>
              <Divider />
              <AppBox overflow={'auto'} minHeight={'56vh'}>
                {data && (
                  <ParkTagsAppTable
                    data={data}
                    onEditClick={handleEditTag}
                    onDeleteClick={handleDeleteParkTag}
                    isLoading={isGettingTags}
                  />
                )}
              </AppBox>
            </AppBox>
          </AppCard>
        </AppBox>
      </BaseComponent>
    </>
  )
}
