import { Grid, IconButton } from '@mui/material'
import { AddContactCompany, AppBox, AppButton, AppDialog, AppIcon, AppMenu, AppTypography, InfoBox } from 'components'
import { InfoBoxSettingTypes } from 'globals/constants'
import { getMDYFormattedDate } from 'globals/functions'
import { useCrmCompanies, useCrmContactCompanies } from 'hooks'
import { ShowAlert } from 'sdk'
import { AddUpdateCrmCompanyForm } from 'views/account/crm/content/companies/add-edit-company/AddUpdateCrmCompanyForm'
import { BaseComponent } from '../../base-component/base.component'
import { CrmContactCompaniesHelperLoader } from '../../loaders'
import { PhoneText } from '../../phone-text/PhoneText'

interface Props {
  contactId: string
}
export const CrmContactLinkedCompaniesHelpModule = (props: Props) => {
  const {
    linkCompany,
    setIsNewCompanyAdded,
    handleUnlinkClick,
    linkedCompanis,
    unLinkedCompanies,
    isLoadingLinkedCompanies,
    isLoadingUnLinkedLinkedCompanies,
    isAddingLinkedCompany,
  } = useCrmContactCompanies({ contactId: props.contactId })

  const {
    isAddingCompany,
    isEditing,
    dialogRef,
    handleSubmit,
    control,
    setError,
    watch,
    handleAddClicked,
    handleDeleteClick,
    handleEditClicked,
    submit,
    isDeleting,
    idToBeEdited,
  } = useCrmCompanies({
    updateOnCompanyUpdate: true,
    handleChange: () => setIsNewCompanyAdded((prev) => !prev),
  })
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ContactLinkedCompaniesDismissed}>
          When you associate one or more companies to a contact the list of companies will begin to display here for
          that contact.
        </InfoBox>
        <AddContactCompany
          contactId={props.contactId!}
          linkCompany={linkCompany}
          isAddingLinkedCompany={isAddingLinkedCompany}
          isLoadingUnLinkedLinkedCompanies={isLoadingUnLinkedLinkedCompanies}
          unLinkedCompanies={unLinkedCompanies ?? []}
        />

        <AppBox borderTop={1} borderBottom={1} borderColor={'grey.200'} paddingY={2}>
          <Grid container>
            <Grid item xs={8}>
              <AppTypography fontWeight={500} fontSize={14} color={'grey.500'}>
                Add a new company to your CRM. After being added it will appear in the lookup.
              </AppTypography>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
              <AppBox>
                <AppButton variant="contained" size="large" onClick={handleAddClicked}>
                  Add New
                </AppButton>
              </AppBox>
            </Grid>
          </Grid>
        </AppBox>
        <BaseComponent
          isLoading={isLoadingLinkedCompanies || isDeleting}
          isData={!!linkedCompanis?.Data}
          loaderComponent={
            <>
              <CrmContactCompaniesHelperLoader />
            </>
          }
        >
          <>
            <AppTypography fontSize={14} fontWeight={500}>
              Companies({linkedCompanis?.TotalCount})
            </AppTypography>

            {linkedCompanis?.Data?.map((company) => (
              <AppBox
                backgroundColor="grey.50"
                borderColor={'grey.200'}
                border="1px"
                padding={2}
                borderRadius={3}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <AppBox>
                  <AppTypography color={'grey.900'} fontSize={14} fontWeight={600}>
                    {company.Name}
                  </AppTypography>
                  <AppTypography fontWeight={500} color={'grey.500'}>
                    {company.Address}
                  </AppTypography>
                  <AppBox display={'flex'} gap={2}>
                    <PhoneText phone={company.Phone} />
                    {company.AltPhone && (
                      <>
                        <AppBox
                          width={3}
                          height={3}
                          backgroundColor={'secondary.600'}
                          borderRadius={1}
                          alignSelf={'center'}
                        />
                        <PhoneText phone={company.AltPhone} />
                      </>
                    )}
                    {company.Website && (
                      <AppBox
                        color={'primary.600'}
                        display={'flex'}
                        alignItems={'center'}
                        cursor="pointer"
                        onClick={() => window.open(company.Website, '_blank')}
                      >
                        <AppIcon icon={'mdi:web'} />
                        <AppTypography fontSize={14}> Website</AppTypography>
                      </AppBox>
                    )}
                  </AppBox>
                  <AppTypography color={'grey.500'}>
                    Updated on {company.Modified} by: {company.UpdatedByName}
                  </AppTypography>
                </AppBox>
                <AppBox>
                  <AppMenu
                    renderElement={(handleClick) => (
                      <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                        <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                      </IconButton>
                    )}
                    MenuItems={[
                      {
                        text: 'Info',
                        onClick: () =>
                          ShowAlert(
                            'info',
                            `Originally created on ${getMDYFormattedDate(new Date(company.Created!))} by ${company.CreatedByName}
                      Last updated on ${getMDYFormattedDate(new Date(company.Modified!))} by ${company.UpdatedByName}`,
                            { showCancelButton: false }
                          ),
                      },
                      { text: 'Edit', onClick: () => handleEditClicked(company) },
                      { text: 'Detach From Contact', onClick: () => handleUnlinkClick(company.Id) },
                      { text: 'Delete', onClick: () => handleDeleteClick([company.Id]) },
                    ]}
                  />
                </AppBox>
              </AppBox>
            ))}
          </>
        </BaseComponent>
      </AppBox>
      <AppDialog
        maxWidth="sm"
        ref={dialogRef}
        title={idToBeEdited ? 'Edit Company' : 'Add New Company'}
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit((data) => submit(data)),
        }}
        submitButtonProps={{ isLoading: isAddingCompany || isEditing }}
        renderChild={(openDialog) => <AddUpdateCrmCompanyForm control={control} setError={setError} watch={watch} />}
      />
    </>
  )
}
