import { Typography, TypographyProps } from '@mui/material'
import { FC } from 'react'

interface Props extends TypographyProps {
  isColorPrimary?: boolean
}
export const AppTypography: FC<Props> = ({ isColorPrimary = false, ...props }) => {
  return (
    <Typography
      style={{ ...props.style, cursor: props.component === 'a' ? 'pointer' : '' }}
      {...props}
      color={isColorPrimary ? 'primary.600' : props.color}
    />
  )
}
