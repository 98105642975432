import { Grid } from '@mui/material'
import { AppBox, AppTextField, AppTypography } from 'components'
import { AppSelect } from 'components/base/select/AppSelect'
import { CurrencyField } from 'components/shared/form/Currency/CurrencyField'
import { FloodPlainTypeOptions } from 'globals/constants'
import { ChangeEvent, FC } from 'react'
import { Control } from 'react-hook-form'
import { ParkUpdatePayload, ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ParkUpdatePayload> | Control<ReportParkPayload>
}

export const UpdateParkBasicCard: FC<Props> = ({ control }) => {
  return (
    <AppBox borderRadius={3} backgroundColor="grey.50" border={1} borderColor={'grey.200'} paddingX={2} paddingY={1}>
      <AppTypography fontSize={16} fontWeight={'bold'}>
        Basics
      </AppTypography>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            isSmallForm
            name="ParkInfo.PermitNo"
            control={control}
            label="Permit #:"
            LabelProps={{ fontWeight: 400 }}
            placeholder="Enter Permit #"
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            isSmallForm
            name="ParkInfo.PropertyTaxYear"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Property Tax Year:"
            placeholder="Enter Property Tax Year"
            inputProps={{
              maxLength: 4,
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 4) {
                  event.target.value = event.target.value.slice(0, 4)
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            type="number"
            isSmallForm
            name="ParkInfo.TotalAcres"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Total Acres +/-:"
            placeholder="Enter Total Acres"
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            type="number"
            isSmallForm
            name="ParkFieldsDataObj.TotalSFRUnits"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Single Family Homes:"
            placeholder="Enter Single Family Homes"
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            isSmallForm
            name="ParkInfo.YearBuilt"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Year Built:"
            placeholder="Enter Year Built"
            inputProps={{
              maxLength: 4,
              onInput: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 4) {
                  event.target.value = event.target.value.slice(0, 4)
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            type="number"
            isSmallForm
            name="ParkInfo.PropertyTax"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Property Tax:"
            placeholder="Enter Property Tax"
          />
        </Grid>
        <Grid item xs={3}>
          <AppSelect
            isSmallForm
            name="ParkFieldsDataObj.FloodPlain"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Flood Plain Type:"
            placeholder="Select Flood Plain Type"
            options={FloodPlainTypeOptions}
            sx={{ backgroundColor: 'white' }}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            sx={{ backgroundColor: 'white' }}
            type="number"
            isSmallForm
            name="ParkFieldsDataObj.TotalStorageUnits"
            control={control}
            LabelProps={{ fontWeight: 400 }}
            label="Storage Units:"
            placeholder="Enter Storage Units"
          />
        </Grid>
      </Grid>
    </AppBox>
  )
}
