import { IconButton } from '@mui/material'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { AppIcon, AppTypography } from 'components'
import { FC } from 'react'
export interface AppToolTipProps extends Omit<TooltipProps, 'children' | 'title'> {
  children: React.ReactNode
}

export const AppToolTip: FC<AppToolTipProps> = ({ children, ...props }) => {
  return (
    <Tooltip
      title={<AppTypography color={'grey.500'}>{children}</AppTypography>}
      slotProps={{
        tooltip: {
          sx: {
            padding: 1,
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'grey.300',
            backgroundColor: 'white',
          },
        },
      }}
      {...props}
    >
      <IconButton size="small">
        <AppIcon icon="ant-design:question-circle-outlined" size="small" />
      </IconButton>
    </Tooltip>
  )
}
