import { useAuthorizeHelper } from 'hooks'
import { FC } from 'react'
import { AuthorizeProps } from 'sdk'

interface Props extends AuthorizeProps {
  children: React.ReactNode
}

export const Authorize: FC<Props> = ({ children, ...rest }) => {
  const { isAuthorized } = useAuthorizeHelper()

  return <>{isAuthorized(rest) && children}</>
}
