import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  ApiErrorModel,
  StateApi,
  StatePayload,
  StateViewModel,
  UpdateStateStatusPayload,
  UpdateStateWebsitePayload,
} from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { CommonSliceSelector, SetStates } from 'store/slices/common.slice'

export const useStates = (initialCall: boolean = true) => {
  const stateApi = new StateApi()
  const { States } = useSelector(CommonSliceSelector)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  //
  const { mutate, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.state.getAllStates],
    mutationFn: () => stateApi.getAllStates(),
    onSuccess: (data: StateViewModel[]) => dispatch(SetStates(data)),
  })
  //
  const { mutateAsync: AddState, isPending: isAddingState } = useMutation({
    mutationFn: (data: StatePayload) => stateApi.addState(data),
    mutationKey: [ApiQueryKeys.state.addState],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('State added successfully', { variant: 'success' })
      mutate()
    },
  })
  //
  const { mutateAsync: updateState, isPending: isUpdatingState } = useMutation({
    mutationFn: (data: StatePayload) => stateApi.update(data),
    mutationKey: [ApiQueryKeys.state.updateState],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('State updated successfully', { variant: 'success' })
      mutate()
    },
  })
  //
  const { mutateAsync: updateStateWebsite, isPending: isUpdatingStateWebsite } = useMutation({
    mutationFn: (data: UpdateStateWebsitePayload) => stateApi.updateStateWebsite(data),
    mutationKey: [ApiQueryKeys.state.updateWebsite],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('State updated successfully', { variant: 'success' })
      mutate()
    },
  })
  //
  const { mutate: updateStateStatus } = useMutation({
    mutationFn: (data: UpdateStateStatusPayload) => stateApi.updateStatus(data),
    mutationKey: [ApiQueryKeys.state.updateStateStatus],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('State status updated successfully', { variant: 'success' })
      mutate()
    },
  })

  const refetch = () => {
    if (States.length === 0) {
      mutate()
    }
  }
  useEffect(() => {
    if (initialCall) {
      refetch()
    }
  }, [States, mutate])
  return {
    isGettingState: isPending,
    States,
    refetch,
    AddState,
    isAddingState,
    updateState,
    isUpdatingState,
    updateStateStatus,
    updateStateWebsite,
    isUpdatingStateWebsite,
  }
}
