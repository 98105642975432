import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ParkLabelApi, ParkListTab, ShowAlert, UserParkLabelPayloadModel } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
import { setParkLabels } from 'store/slices/park-labels.slice'

export const usePinnedParks = () => {
  const parkLabelApi = new ParkLabelApi()
  const [idToEdit, setIdToEdit] = useState<string>('')
  const [idToRemove, setIdToRemove] = useState<string | null>(null)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const dispatch = useDispatch()
  const dialogRef = useRef<AppDialogRefType>({})
  // For Saving labels
  const { control, watch, handleSubmit, reset, setValue } = useForm<UserParkLabelPayloadModel>({ mode: 'onSubmit' })
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: AddNewLabel, isPending: isAddingLabel } = useMutation({
    mutationFn: parkLabelApi.AddParkLabel,
    mutationKey: [ApiQueryKeys.parksites.parkLabels.AddParkLabel],
    onSuccess: () => handleAddEditParkPinSuccess(),
  })
  //

  // For Fetching labels
  const {
    data: ParkLabels,
    mutate: GetParkLabels,
    isPending: isGettingLabels,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parkLabels.FetchParkLabels],
    mutationFn: parkLabelApi.GetUserParkLabels,
    onSuccess: (data) => dispatch(setParkLabels(data)),
  })
  useEffect(() => GetParkLabels(), [SelectedTeam?.Id])
  //
  // Fetching Pinned Parks By Label Id
  const { isPending: isCheckingPinnedParks, mutate: GetPinnedParksByLabelId } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.labelledParks.RemoveLabelledParkFromList],
    mutationFn: (labelId: string) => parkLabelApi.GetLabelledParksByLabelLabelId(labelId),
    onSuccess: (data) => handleGetPinnedParksByLinkIdSuccess(data),
  })
  const handleGetPinnedParksByLinkIdSuccess = (data: ParkListTab[]) => {
    ShowAlert(
      'question',
      data == null || data.length === 0
        ? 'Are you sure you want to delete this Park Pin?'
        : `This pin is linked to ${data.length} parks. Are you sure you want to remove this pin?`
    ).then((result) => {
      if (result.isConfirmed) {
        DeleteLabel(idToRemove!)
      }
    })
  }
  // For Deleting labels
  const { isPending: isDeleting, mutate: DeleteLabel } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parkLabels.DeleteLabel],
    mutationFn: (id: string) => parkLabelApi.DeleteParkLabel(id),
    onError: () => enqueueSnackbar('Error Deleting Park Label', { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Park Label has Been Deleted ', { variant: 'success' })
      GetParkLabels()
    },
  })
  const handleDeleteParkPin = (id: string) => {
    setIdToRemove(id)
    GetPinnedParksByLabelId(id)
  }
  //
  // For Editing labels
  const { mutate: EditParkLabel, isPending: isEditingLabel } = useMutation({
    mutationFn: (data: { parkId: string; payload: UserParkLabelPayloadModel }) =>
      parkLabelApi.UpdateParkLabel(data.parkId, data.payload),
    mutationKey: [ApiQueryKeys.parksites.parkLabels.EditParkLabel],
    onSuccess: () => handleAddEditParkPinSuccess(),
  })

  //
  const handleAddEditParkPinSuccess = () => {
    GetParkLabels()
    enqueueSnackbar(`Park Label has Been ${idToEdit ? 'Edited' : 'Added'} `, { variant: 'success' })
    dialogRef.current?.closeDialog!()
    reset()
  }
  const handleFormSubmit = (data: UserParkLabelPayloadModel) => {
    if (idToEdit) {
      EditParkLabel({ parkId: idToEdit, payload: data })
    } else {
      AddNewLabel(data)
    }
  }

  return {
    control,
    watch,
    handleSubmit,
    setValue,
    GetParkLabels,
    ParkLabels,
    handleDeleteParkPin,
    handleFormSubmit,
    idToEdit,
    setIdToEdit,
    isAddingLabel,
    isEditingLabel,
    isGettingLabels,
    dialogRef,
  }
}
