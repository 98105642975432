import { Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AppBox,
  AppButton,
  AppCheckBox,
  AppDialog,
  AppDialogRefType,
  AppTypography,
  EmailField,
  PasswordField,
} from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useLogin } from 'hooks'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { Form, useForm } from 'react-hook-form'
import { AuthApi, ForgotPasswordPayload, LoginPayloadModel } from 'sdk'

export const LoginForm = () => {
  const authApi = new AuthApi()
  const { enqueueSnackbar } = useSnackbar()
  const { control } = useForm<LoginPayloadModel>({
    mode: 'onChange',
  })

  // Forgot password
  const {
    control: ForgotPasswordControl,
    handleSubmit: handleForgotPasswordSubmit,
    getValues,
  } = useForm<ForgotPasswordPayload>()

  const { mutate, isPending: isForgotMailSending } = useMutation({
    mutationFn: () => authApi.forgotPassword(getValues()),
    mutationKey: [ApiQueryKeys.account.forgotPassword],
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      setIsForgotMailSent(true)
      enqueueSnackbar('Password reset link sent to your email', { variant: 'success' })
    },
  })
  const [isForgotMailSent, setIsForgotMailSent] = useState(false)

  const dialogRef = useRef<AppDialogRefType>(null)
  const { appLogin, isPending } = useLogin()
  return (
    <>
      <Form onSubmit={({ data }) => appLogin(data)} control={control}>
        <Grid container paddingBottom={1}>
          <Grid item md={12}>
            <EmailField
              hideDefaultErrorText={false}
              control={control}
              required
              name="Email"
              fullWidth
              label="Email Address"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              hideDefaultErrorText={false}
              required
              control={control}
              name="Password"
              label="Password"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <AppBox display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <AppCheckBox control={control} name="RememberMe" label="Remember me" labelTextProps={{ fontSize: 14 }} />
              <AppTypography
                fontSize={14}
                component={'a'}
                isColorPrimary
                onClick={() => dialogRef.current?.openDialog!()}
              >
                Forgot your password?
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={12}>
            <AppBox paddingY={2}>
              <AppButton type="submit" variant="contained" fullWidth isLoading={isPending} labelTextSize={16}>
                <AppTypography fontSize={16} fontWeight={'bold'} color={'white'}>
                  Sign In
                </AppTypography>
              </AppButton>
            </AppBox>
          </Grid>
        </Grid>
      </Form>

      <Divider>OR</Divider>
      <AppBox display={'flex'} gap={2} justifyContent={'center'} paddingTop={3}>
        <AppButton
          variant="outlined"
          color="secondary"
          prefixIcon="flat-color-icons:google"
          size="large"
          sidePaddings={3}
        >
          <AppTypography fontSize={16} fontWeight={700} color={'grey.900'}>
            Sign in using Google
          </AppTypography>
        </AppButton>
        <AppButton variant="outlined" color="secondary" prefixIcon="logos:microsoft-icon" size="large" sidePaddings={3}>
          <AppTypography fontSize={16} fontWeight={700} color={'grey.900'}>
            Sign in using Microsoft
          </AppTypography>
        </AppButton>
      </AppBox>
      <AppDialog
        maxWidth="sm"
        ref={dialogRef}
        title="Forgot Password"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleForgotPasswordSubmit(() => mutate()),
        }}
        hideSubmitButton={isForgotMailSent}
        submitButtonProps={{
          isLoading: isForgotMailSending,
          children: 'Submit',
          sidePaddings: 2,
        }}
      >
        <>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={500} paddingBottom={2}>
            {!isForgotMailSent
              ? 'Please enter you email address to resert your password'
              : "We've emailed you a link to change your password. Be sure to check your spam if you don't see it right away."}
          </AppTypography>
          {!isForgotMailSent && (
            <EmailField
              hideDefaultErrorText={false}
              control={ForgotPasswordControl}
              required
              name="Email"
              fullWidth
              label="Email Address"
            />
          )}
        </>
      </AppDialog>
    </>
  )
}
