import { apiUrls } from '../../api-urls'
import {
  ActivityCenterInfo,
  AddNewParkPayload,
  ChangeParkLocationPayload,
  GetParkSiteImagesModel,
  ParkDetailViewModel,
  ParkHistoryViewModel,
  ParkSiteImagesPayload,
  ParkSiteMapPin,
  ParkUpdatePayload,
  ReportParkPayload,
  RequestNewParkPayloadModel,
} from '../../models'
import { BaseApi } from '../base.api'

const baseApi = new BaseApi()
export class ParkSitesApi {
  public GetAllParkMarkerPins() {
    return baseApi.GET_Request<Array<ParkSiteMapPin>>(apiUrls.parksites.getAllParkMarkerPins(), {})
  }
  public getParkActivityCenterInfo(parkId: string) {
    return baseApi.GET_Request<ActivityCenterInfo>(apiUrls.parksites.getParkActivityCenterInfo(parkId))
  }
  public GetParkDetail(parkId: string) {
    return baseApi.GET_Request<ParkDetailViewModel>(apiUrls.parksites.getParkDetail(parkId))
  }
  public GetParkImages(ParkId: string) {
    return baseApi.GET_Request<Array<GetParkSiteImagesModel>>(apiUrls.parksites.getParkImages(ParkId))
  }
  public GetParkParcelData(parkId: string) {
    return baseApi.GET_Request<any>(apiUrls.parksites.getParkParcelData(parkId))
  }
  public RequestNewParkSite(payload: RequestNewParkPayloadModel) {
    return baseApi.POST_Request<boolean>(apiUrls.parksites.requestNewParkSite(), payload)
  }
  public getParkSiteHistory(parkId: string) {
    return baseApi.GET_Request<ParkHistoryViewModel[]>(apiUrls.parksites.getParkSiteHistory(parkId))
  }
  public updatePark(payload: ParkUpdatePayload) {
    return baseApi.PUT_Request<boolean>(apiUrls.parksites.updatePark(), payload)
  }
  public sendParkReport(payload: ReportParkPayload) {
    return baseApi.POST_Request<boolean>(apiUrls.parksites.sendParkReport(), payload)
  }
  public isUserParkDataExist(parkId: string) {
    return baseApi.GET_Request<number>(apiUrls.parksites.isUserParkDataExist(parkId))
  }
  public deleteParkSite(parkId: string) {
    return baseApi.DELETE_Request<boolean>(apiUrls.parksites.deleteParkSite(parkId))
  }
  public updateParkImages(payload: ParkSiteImagesPayload) {
    const data = new FormData()
    data.append('ParkId', payload.ParkId!)
    if (payload.NewImages) {
      for (let i = 0; i < payload.NewImages.length; i++) {
        const element = payload.NewImages[i]
        data.append(`NewImages[${i}].File`, element.file)
        data.append(`NewImages[${i}].Sequence`, element.Sequence.toString())
      }
    }
    if (payload.DeletedImageIds) {
      for (const Id of payload.DeletedImageIds) {
        data.append('DeletedImageIds', Id)
      }
    }

    if (payload.OldImagesIds) {
      for (let index = 0; index < payload.OldImagesIds.length; index++) {
        data.append(`OldImagesIds[${index}].Id`, payload.OldImagesIds[index].Id)
        data.append(`OldImagesIds[${index}].Sequence`, `${payload.OldImagesIds[index].Sequence}`)
      }
    }
    return baseApi.PATCH_FormDataRequestAsync<boolean>(apiUrls.parksites.updateParkImages(), data)
  }
  public changeParkLocation(payload: ChangeParkLocationPayload) {
    return baseApi.PATCH_Request<boolean>(apiUrls.parksites.changeParkLocation(), payload)
  }
  public addParkSite(payload: AddNewParkPayload) {
    return baseApi.POST_Request<string>(apiUrls.parksites.addParkSite(), payload)
  }
  public pinnedParksLoadMore() {
    return baseApi.POST_Request<void>(apiUrls.parksites.pinnedParksLoadMore(), {})
  }
  public taggedParksLoadMore() {
    return baseApi.POST_Request<void>(apiUrls.parksites.taggedParksLoadMore(), {})
  }
  public crmParksLoadMore() {
    return baseApi.POST_Request<void>(apiUrls.parksites.crmParksLoadMore(), {})
  }
}
