import dayjs from 'dayjs'
import { getValidationRules } from 'plugins/helper/helper'
import { Controller } from 'react-hook-form'
import { SimpleDatePicker } from '../simple-fields/DatePIcker'
import { AppIconProps } from 'components'

interface Props {
  required?: string | boolean
  control: any
  customRules?: any
  label?: string
  LabelProps?: any
  name: string
  labelIcon?: string
  labelIconProps?: Omit<AppIconProps, 'icon'>
  defaultValue?: dayjs.Dayjs | string
}

export const AppDatePicker = ({
  required,
  control,
  customRules,
  label,
  LabelProps,
  labelIcon,
  labelIconProps,
  ...props
}: Props) => {
  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue ?? ''}
      control={control}
      rules={{
        required: required ? 'This field is required' : false,
        validate: (value) => getValidationRules(value, props.name, customRules ?? {}),
      }}
      render={({ fieldState: { error }, field }) => (
        <SimpleDatePicker
          label={label}
          LabelProps={LabelProps}
          labelIconProps={labelIconProps}
          {...field}
          error={error}
          {...props}
        />
      )}
    />
  )
}
