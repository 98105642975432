import { Box, BoxProps } from '@mui/material'
import { forwardRef } from 'react'
export interface AppBoxProps extends BoxProps {
  backgroundColor?: string
  cursor?: 'pointer' | 'default'
  If?: boolean
}
export const AppBox = forwardRef(({ children, If, cursor = 'default', ...rest }: AppBoxProps, ref) => {
  return (
    <>
      {If !== false && (
        <Box {...rest} ref={ref} sx={{ cursor: cursor }}>
          {children}
        </Box>
      )}
    </>
  )
})
