import { ProfileInfoCardWithLinks } from 'components'
import { SideBarType } from 'globals/constants'
import { getFullDate } from 'globals/functions'
import { FC } from 'react'
import { CrmContactViewModel } from 'sdk'
interface Props {
  contact: CrmContactViewModel | undefined
}
export const CrmContactDetailSideBar: FC<Props> = ({ contact }) => {
  const sideBar = [
    {
      name: 'Contact',
      icon: 'carbon:user-avatar',
      id: 'Contact',
    },
    {
      name: 'Parks',
      icon: 'bi:people',
      id: 'Parks',
    },
    {
      name: 'Companies',
      icon: 'fluent-mdl2:tab-center',
      id: 'Companies',
    },
    {
      name: 'Group Tags',
      icon: 'mdi:lock-open-outline',
      id: 'GroupTags',
    },
    {
      name: 'Notes',
      icon: 'ph:question',
      id: 'Notes',
    },
  ]
  // return <ProfileInfoCardWithLinks links={sideBar} sideBarType={SideBarType.CRMContact} contentInfo={<></>} />
  return <></>
}
