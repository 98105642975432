import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ParkLabelApi, ParkListTab } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { TeamSliceSelector, setLabelledParkPins } from 'store/slices'
import { setLabelledParks, setisLoadingLabelledParks } from 'store/slices/park-labels.slice'

const labelApi = new ParkLabelApi()

export const useGetLabelledParks = () => {
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const parkPinsDispatch = useAppDispatch()
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.labelledParks.FetchLabelledParks],
    mutationFn: (query: string) => labelApi.GetUserLabelledParks(query),
    onSuccess: (data) => onSuccess(data),
  })

  const onSuccess = (data: ParkListTab[]) => {
    parkPinsDispatch(setLabelledParks(data ?? []))
    parkPinsDispatch(setLabelledParkPins(data?.map((x) => x.Position) ?? []))
    parkPinsDispatch(setisLoadingLabelledParks(false))
  }

  const fetchParkPins = useCallback(
    (query?: string) => {
      parkPinsDispatch(setisLoadingLabelledParks(true))
      return mutate(query ?? '')
    },
    [mutate, parkPinsDispatch]
  )

  return {
    getPinnedParks: fetchParkPins,
    isFetchingPinnedParks: isPending,
    isSuccess: isSuccess,
  }
}
