import {
  CrmContactViewModel,
  CrmCompanyTableModel,
  CityDemographicsViewModel,
  ParkDetailViewModel,
  ParkInformationViewModel,
  ParkFieldsDataViewModel,
} from 'sdk'
import {
  FilterCategory,
  FilterConditionLabels,
  FilterConditionTypes,
  FilterFieldType,
  FilterFieldsPricincipalType,
  FilterInputValueFrom,
  PreConditionFields,
} from '../FilterTypes'
import { nameof } from 'ts-simple-nameof'

export const ParkSiteFilterData: Array<FilterCategory> = [
  {
    Label: 'CRM Records',
    Fields: [
      {
        Label: 'Contact First Name',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactFirstName),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Contact Last Name',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactLastName),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Contact Email',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactEmail),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Contact Phone',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactPhone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
        ],
      },
      {
        Label: 'Contact Alt Phone',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactAltPhone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
        ],
      },
      {
        Label: 'Contact Type',
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactType),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
        ],
      },
      {
        Label: 'Company Name',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.Name),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Address',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.Address),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Address(2)',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.Address2),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Phone',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.Phone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Alt Phone',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.AltPhone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company City',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.City),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Zip',
        TableName: 'CrmContactCompanyLinkedParkSitesTable',
        ColumnName: nameof<CrmCompanyTableModel>((x) => x.Zip),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Zip,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Zip,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'City Demographics',
    Fields: [
      {
        TableName: 'Cities',
        Label: 'City Type',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.Type),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CityTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CityTypes,
            },
          },
        ],
      },
      {
        TableName: 'Cities',
        Label: 'City Population',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.Population),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        TableName: 'Cities',
        Label: 'City Households',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.HouseHold),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
        ],
      },
      {
        TableName: 'Cities',
        Label: 'City Median Income',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.MedianIncome),
        IsDefaultFilter: true,
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Currency,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Currency,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Currency,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Currency,
            },
          },
        ],
      },
      {
        TableName: 'Cities',
        Label: 'City Land Area(Acres)',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.LandArea),
        Conditions: [
          {
            Label: FilterConditionLabels.Is_More_Than_Area,
            Condition: FilterConditionTypes.Is_More_Than_Area,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than_Area,
            Condition: FilterConditionTypes.Is_Less_Than_Area,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        TableName: 'Cities',
        Label: 'City Water Area(Acres)',
        ColumnName: nameof<CityDemographicsViewModel>((x) => x.WaterArea),
        Conditions: [
          {
            Label: FilterConditionLabels.Is_More_Than_Area,
            Condition: FilterConditionTypes.Is_More_Than_Area,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than_Area,
            Condition: FilterConditionTypes.Is_Less_Than_Area,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Park Records',
    Fields: [
      {
        TableName: 'ParkInformation',
        Label: 'Park No',
        ColumnName: 'ParkNo',
        allowedToAdmin: true,
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Contain,
            Condition: FilterConditionTypes.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'ParkSites',
        Label: 'Park City',
        ColumnName: nameof<ParkDetailViewModel>((x) => x.City),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Contain,
            Condition: FilterConditionTypes.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'ParkSites',
        Label: 'Park County',
        ColumnName: nameof<ParkDetailViewModel>((x) => x.County),
        PreConditionFieldTableName: 'ParkSites',
        PreConditionFieldColumnName: nameof<ParkDetailViewModel>((x) => x.State),
        PreConditionField: PreConditionFields.State,
        Conditions: [
          {
            Label: FilterConditionLabels.EqualsWithPreCond,
            Condition: FilterConditionTypes.EqualsWithPreCond,
            IsDefaultCondition: true,
            InputField: {
              Type: FilterFieldType.Counties,
              IsMultiSelect: true,
            },
          },
          {
            Label: FilterConditionLabels.NotEqualsWithPreCond,
            Condition: FilterConditionTypes.NotEqualsWithPreCond,
            InputField: {
              Type: FilterFieldType.Counties,
              IsMultiSelect: true,
            },
          },
        ],
      },
      {
        TableName: 'ParkSites',
        Label: 'Park State',
        ColumnName: nameof<ParkDetailViewModel>((x) => x.State),
        IsDefaultFilter: true,
        Conditions: [
          {
            Label: FilterConditionLabels.In,
            Condition: FilterConditionTypes.In,
            IsDefaultCondition: true,
            InputField: {
              Type: FilterFieldType.States,
              IsMultiSelect: true,
            },
          },
          {
            Label: FilterConditionLabels.Not_In,
            Condition: FilterConditionTypes.Not_In,
            InputField: {
              Type: FilterFieldType.States,
              IsMultiSelect: true,
            },
          },
        ],
      },
      {
        TableName: 'ParkSites',
        Label: 'Park Zip Code',
        ColumnName: nameof<ParkDetailViewModel>((x) => x.Zip),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Zip,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Zip,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Contain,
            Condition: FilterConditionTypes.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
        ],
      },
      {
        Label: 'Park Permit No.',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.PermitNo),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Registered,
            Condition: FilterConditionTypes.Registered,
          },
          {
            Label: FilterConditionLabels.Unregistered,
            Condition: FilterConditionTypes.Unregistered,
          },
        ],
      },
      {
        Label: 'Park Year Built',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.YearBuilt),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_Before_Date,
            Condition: FilterConditionTypes.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_After_Date,
            Condition: FilterConditionTypes.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.YearUnknown,
            Condition: FilterConditionTypes.YearUnknown,
          },
          {
            Label: FilterConditionLabels.YearNotUnknown,
            Condition: FilterConditionTypes.YearNotUnknown,
          },
        ],
      },
      {
        Label: 'Park Total Acres',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.TotalAcres),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Number,
            },
          },
          {
            Label: FilterConditionLabels.YearUnknown,
            Condition: FilterConditionTypes.YearUnknown,
          },
          {
            Label: FilterConditionLabels.YearNotUnknown,
            Condition: FilterConditionTypes.YearNotUnknown,
          },
        ],
      },
      {
        Label: 'Park Name',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.ParkName),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Park Street Adress',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.StreetAddress),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Park Phone',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkInformationViewModel>((x) => x.ParkPhone),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.ParkPhoneUnknown,
            Condition: FilterConditionTypes.ParkPhoneUnknown,
          },
          {
            Label: FilterConditionLabels.ParkPhoneNotUnknown,
            Condition: FilterConditionTypes.ParkPhoneNotUnknown,
          },
        ],
      },
      {
        Label: 'Park Total MH Lots',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalMHLots),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        Label: 'Park Total RV Lots',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalRVLots),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        Label: 'Park Status',
        TableName: 'ParkSites',
        ColumnName: 'Status',
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.ParkSystemStatuses,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.ParkSystemStatuses,
            },
          },
        ],
      },
      {
        Label: 'Park Other Rental Units - Total SFR',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalSFRUnits),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        Label: 'Park Other Rental Units - total Storage Units',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalStorageUnits),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        Label: 'Park Street Type',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.StreetAndRoadsStreetType),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.StreetTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.StreetTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Street Owner',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.StreetAndRoadsOwnedBy),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.StreetOwnerTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.StreetOwnerTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Water Source',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.ParkUtilityWaterSource),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.WaterSourceTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.WaterSourceTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Sewer Source',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.ParkUtilitySewerType),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SewerSourceTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SewerSourceTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Heating Fuel',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.ParkUtilityHeatingFuel),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.HeatingFuelTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.HeatingFuelTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Trash Service',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.ParkUtilityTrashService),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TrashServiceTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TrashServiceTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Flood Plain',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.FloodPlain),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.FloodPlainTypes,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.FloodPlainTypes,
            },
          },
        ],
      },
      {
        Label: 'Park Last Updated',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.Modified),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_Before_Date,
            Condition: FilterConditionTypes.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_After_Date,
            Condition: FilterConditionTypes.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
      {
        Label: 'Park Create Date',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.Created),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_Before_Date,
            Condition: FilterConditionTypes.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_After_Date,
            Condition: FilterConditionTypes.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
    ],
  },
]
export const ParkSiteFilterGroups: Array<FilterCategory> = [
  {
    Label: 'Lot Count',
    Fields: [
      {
        Label: 'Park Total MH Lots',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalMHLots),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
      {
        Label: 'Park Total RV Lots',
        TableName: 'ParkInformation',
        ColumnName: nameof<ParkFieldsDataViewModel>((x) => x.TotalRVLots),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_More_Than,
            Condition: FilterConditionTypes.Is_More_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
          {
            Label: FilterConditionLabels.Is_Less_Than,
            Condition: FilterConditionTypes.Is_Less_Than,
            InputField: {
              Type: FilterFieldType.Comma_Separated_Number,
            },
          },
        ],
      },
    ],
  },
]
export const ParkSiteFilterPrincipalTypes: FilterFieldsPricincipalType = {
  Label: 'Park Type',
  Options: [
    {
      Id: 'MH Parks',
      Label: 'MH Lots Only',
    },
    {
      Id: 'RV Parks',
      Label: 'RV Lots Only',
    },
  ],
}
