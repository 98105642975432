import { Map, useMap } from '@vis.gl/react-google-maps'
import * as GeoJSONCreator from 'geojson'
import { useRolesHelper } from 'hooks'
import { useShowableParkPins } from 'hooks/park-sites/showable-park-pins-hook'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterSliceSelector, GoogleMapSliceSelector, ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'
import Supercluster from 'supercluster'
import { DraggableMarkerProps, useClusterWithMarkers } from './ClusterWithMarkers/ClusterWithMarkers'
import { GoogleMapsPinTypesLegendControl } from './MapControls/GoogleMapsPinTypesLegendControl'
import { GoogleMapsZoomBackControl } from './MapControls/GoogleMapsZoomBackControl'
import { AppGoogleMapUniqueKeys } from 'globals/constants'

interface Props extends DraggableMarkerProps {
  onMarkerClick: (parkId: string, marker: google.maps.Marker) => void
  onMarkerRightClick?: (parkId: string, marker: google.maps.Marker) => void
}
export const GoogleMap = (props: Props) => {
  const {
    SelectedPlace,
    ParkPins,
    SelectedParkIdToMove,
    LabelledParkPins,
    TaggedParkPins,
    CrmContactLinkedParkPins,
    CrmContactLinkedParksState,
    LinkedCrmContactsParkPins,
  } = useSelector(GoogleMapSliceSelector)
  const {
    Park: { FilteredData, IsUnSavedFilterApplied, AppliedFilter },
  } = useSelector(FilterSliceSelector)
  const { openedTool, selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)
  const [superCluster] = useState<Supercluster | null>(new Supercluster({ radius: 128, maxZoom: 13 }))
  const [mapZoom, setMapZoom] = useState<number | null>(null)
  const [mapCenter, setMapCenter] = useState<[number, number] | null>(null)
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false)
  const { showClusterData } = useClusterWithMarkers({
    superCluster,
    ...props,
  })
  const { currentMarkers } = useShowableParkPins()
  const { IsAdmin } = useRolesHelper()
  //
  /* First Time Load and on pins change */
  //

  useEffect(() => {
    if (!map || !isMapLoaded) {
      return
    }

    // const debounceId = setTimeout(() => {
    if (superCluster) {
      const geoJsonData = (GeoJSONCreator as any).parse(currentMarkers(), {
        Point: ['L', 'N'],
        include: ['I', 'S'],
      })
      superCluster.load(geoJsonData.features)
      showClusterData(props.onMarkerClick, props.onMarkerRightClick)
    }
    // }, 200)
    // return () => {
    //   clearTimeout(debounceId)
    // }
  }, [
    isMapLoaded,
    ParkPins,
    LabelledParkPins,
    TaggedParkPins,
    openedTool,
    CrmContactLinkedParksState,
    CrmContactLinkedParkPins,
    SelectedTeam,
    FilteredData,
    AppliedFilter,
    IsUnSavedFilterApplied,
    LinkedCrmContactsParkPins,
    SelectedParkIdToMove,
    selectedParkId,
    mapZoom,
    mapCenter,
  ])

  useEffect(() => {
    const pin = currentMarkers().find((x) => x.I === selectedParkId)
    if (pin) {
      const center = new google.maps.LatLng(pin.L!, pin.N!)
      setMapCenter([pin.L!, pin.N!])
      map?.setCenter(center)
    }
    if (selectedParkId) {
      setMapZoom(18)
      map?.setZoom(18)
    } else {
      if (!IsAdmin()) {
        setMapZoom(13)
        map?.setZoom(13)
      }
    }

    // this.map?.setCenter(center)
  }, [selectedParkId])

  //
  /* on searching map */
  //

  useEffect(() => {
    if (!map || !SelectedPlace) {
      return
    }

    if (!SelectedPlace.geometry || !SelectedPlace.geometry.location) {
      console.log("No details available for input: '" + SelectedPlace?.name + "'")
      return
    }
    if (SelectedPlace.geometry.viewport) {
      map.fitBounds(SelectedPlace.geometry.viewport)
    } else {
      map.setCenter(SelectedPlace.geometry.location!)
    }
  }, [SelectedPlace])

  return (
    <>
      <Map
        id={AppGoogleMapUniqueKeys.ParkSitesMap}
        mapId={AppGoogleMapUniqueKeys.ParkSitesMap}
        defaultCenter={{ lat: 41.4831344, lng: -101.924517 }}
        defaultZoom={4}
        gestureHandling={'greedy'}
        mapTypeId={'hybrid'}
        disableDefaultUI={true}
        mapTypeControl={false}
        isFractionalZoomEnabled={false}
        zoomControl={true}
        minZoom={4}
        onBoundsChanged={(x) => setMapZoom(x.map.getZoom()!)}
        onZoomChanged={(x) => setMapZoom(x.map.getZoom()!)}
        onTilesLoaded={() => setIsMapLoaded(true)}
      >
        <GoogleMapsPinTypesLegendControl />
        <GoogleMapsZoomBackControl />
      </Map>
    </>
  )
}
