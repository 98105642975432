import { FilterConditionSaveModel, FilteredParkSitesModel, FilterViewModel, ParkSiteMapPin } from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../../base.api'

export class ParkFilterApi extends BaseApi {
  public GetParkFilters() {
    return this.GET_Request<Array<FilterViewModel>>(apiUrls.parksites.filter.getParkFilters())
  }
  public ApplyParkFilter(filters: Array<FilterConditionSaveModel>) {
    return this.POST_Request<FilteredParkSitesModel>(apiUrls.parksites.filter.applyParkFilter(), filters)
  }
}
