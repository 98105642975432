import { apiUrls } from '../../api-urls'
import { CityDemographicsViewModel } from '../../models/ParkSites/city-demographics/city-demographics-view.model'
import { BaseApi } from '../base.api'

const baseApi = new BaseApi()
export class CityDemographicsApi {
  public GetParkCityDemographics(parkId: string) {
    return baseApi.GET_Request<CityDemographicsViewModel>(apiUrls.parksites.getParkCityDemographics(parkId))
  }
}
