import { SimpleRadioGroup } from 'components/base'
import { FilterCondition } from 'globals'
import { FC } from 'react'

interface Props {
  conditions: Array<FilterCondition>
  selectedCondition: string
  onConditionChange: (conditionId: string) => void
}

export const SelectFilterCondition: FC<Props> = ({ conditions, selectedCondition, onConditionChange }) => {
  return (
    <SimpleRadioGroup
      defaultValue={selectedCondition}
      onChange={(e) => onConditionChange(e)}
      options={conditions.map((x) => {
        return { Id: x.Condition, Label: x.Label }
      })}
    />
  )
}
