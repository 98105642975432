import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, TableActions } from 'components'
import { TagViewModel } from 'sdk'

interface Props extends AppTableProps<TagViewModel> {
  onEditClick: (data: TagViewModel) => void
  onDeleteClick: (Id: string) => void
}
export const ParkTagsAppTable = ({ onDeleteClick, onEditClick, ...props }: Props) => {
  const columns: ColumnDef<TagViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Edit', onClick: () => onEditClick(row.original) },
              { text: 'Delete', onClick: () => onDeleteClick(row.original.Id) },
            ]}
          />
        )
      },
    },
    {
      header: 'Tag',
      accessorKey: 'Tag',
      size: 1,
    },
  ]
  return <AppTable columns={columns} {...props} />
}
