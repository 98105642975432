import {
  AttachPaymentMethodPayloadModel,
  PaginatedApiResponseModel,
  PaymentInformationViewModel,
  StripeCustomerChargeModel,
} from 'sdk'
import { BaseApi } from '../base.api'
import { apiUrls } from 'sdk/api-urls'

export class UserPaymentsApi extends BaseApi {
  public PaymentMethods(id: string) {
    return this.GET_Request<Array<PaymentInformationViewModel>>(`${this.ApiUrl}/UserPayment/PaymentMethods/${id}`)
  }

  public AttachPaymentMethodtoCustomer(data: AttachPaymentMethodPayloadModel) {
    return this.POST_Request<AttachPaymentMethodPayloadModel>(apiUrls.payment.attachPaymentMethodToCustomer(), data)
  }

  public DetachPaymentMethod(paymentMethodId: string) {
    return this.POST_Request<void>(`${this.ApiUrl}/UserPayment/DetachPaymentMethod/${paymentMethodId}`, {})
  }

  public GetUserChargeHistory(id: string) {
    return this.GET_Request<PaginatedApiResponseModel<StripeCustomerChargeModel>>(
      `${this.ApiUrl}/UserPayment/GetUserChargeHistory/${id}`
    )
  }

  public GetUserPlanHistory(id: string) {
    return this.GET_Request<PaginatedApiResponseModel<StripeCustomerChargeModel>>(
      `${this.ApiUrl}/UserPayment/GetUserPlanHistory/${id}`
    )
  }
}
