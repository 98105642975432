import { Divider, Grid, LinearProgress } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AppAutoComplete,
  AppBox,
  AppButton,
  AppTextField,
  AppTypography,
  EmailField,
  PhoneField,
  StateField,
  TextWithToolTip,
  ZipField,
} from 'components'
import { ApiQueryKeys, AppTourKeys, FormFiledRulesKeys, UserPrimaryRolesOptions } from 'globals/constants'
import { useLogout } from 'hooks'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ApiErrorModel, SessionModel, UserApi, UserPersonalInfoViewModel } from 'sdk'

import { AuthSliceSelector, setSession } from 'store/slices'
import { setCurrentTourKey } from 'store/slices/common.slice'

export const PersonalInfoModule = () => {
  const userApi = new UserApi()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { logout } = useLogout()
  const { control, setError, getValues, reset, watch } = useForm<UserPersonalInfoViewModel>({
    mode: 'onChange',
  })
  const {
    mutate: getPersonalInfo,
    data: personalInfo,
    isPending: isGettingPersonalInfo,
  } = useMutation({
    mutationFn: () => userApi.getUserPersonalInfo(CurrentUser?.UserId!),
    mutationKey: [ApiQueryKeys.user.updateUserProfile],
    onError: (error) => enqueueSnackbar(error?.message, { variant: 'error' }),
    onSuccess: () => dispatch(setCurrentTourKey(AppTourKeys.ProfileTour)),
  })
  const { mutate: updateProfile, isPending: isUpdaing } = useMutation({
    mutationFn: () => userApi.UpdateUserProfile(getValues()),
    mutationKey: [ApiQueryKeys.user.updateUserProfile],
    onError: (error: ApiErrorModel) => {
      if (error?.status === 403) {
        enqueueSnackbar(error.message, { variant: 'error' })
        setError('Email', {
          type: FormFiledRulesKeys.EmailExist,
          message: 'Email already taken',
        })
      } else {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    },
    onSuccess: (data: string) => {
      if (data === 'EMAIL_CHANGED') {
        enqueueSnackbar('Verification Email has been sent your new email address, please verify your email address', {
          variant: 'success',
        })
        logout()
      } else {
        enqueueSnackbar('Profile updated successfully', { variant: 'success' })
        dispatch(
          setSession(
            new SessionModel({
              ...CurrentUser,
              FirstName: getValues().FirstName,
              LastName: getValues().LastName,
              Email: getValues().Email,
              PhoneNumber: getValues().PhoneNumber,
            })
          )
        )
        getPersonalInfo()
      }
    },
  })
  useEffect(() => {
    getPersonalInfo()
  }, [])

  useEffect(() => {
    if (personalInfo) {
      reset(new UserPersonalInfoViewModel({ ...personalInfo }))
    }
  }, [personalInfo])

  return (
    <>
      <AppBox border={1} backgroundColor="white" borderColor={'grey.300'} borderRadius={6} padding={2}>
        <TextWithToolTip tooltipText="Manage your account’s profile information when something personally changes for you.">
          <AppTypography fontWeight={700} fontSize={16}>
            Profile
          </AppTypography>
        </TextWithToolTip>
        {isGettingPersonalInfo && <LinearProgress />}
        <Divider sx={{ marginBlock: 1 }} />
        <Form control={control} onSubmit={({}) => updateProfile()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppTextField
                label={'Company Name'}
                name={'Company'}
                control={control}
                placeholder={'Enter Company Name'}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                label={'First Name'}
                name={'FirstName'}
                control={control}
                placeholder={'Enter First Name'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                label={'Last Name'}
                name={'LastName'}
                control={control}
                placeholder={'Enter Last Name'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <EmailField
                label={'Email'}
                name={'Email'}
                control={control}
                placeholder={'Enter Email'}
                required={true}
                fullWidth
                autoComplete="none"
              />
            </Grid>
            <Grid item xs={6}>
              <AppAutoComplete
                options={UserPrimaryRolesOptions}
                label={'Primary Role'}
                name={'Role'}
                control={control}
                placeholder={'Enter Primary Role'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                label={'Address'}
                name={'Address'}
                control={control}
                placeholder={'Enter Address'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                label={'Address 2'}
                name={'Address2'}
                control={control}
                placeholder={'Enter Address 2'}
                required={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                label={'City'}
                name={'City'}
                control={control}
                placeholder={'Enter City'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <StateField
                label={'State'}
                name={'StateId'}
                control={control}
                placeholder={'Select State'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ZipField
                label={'Zip Code'}
                name={'Zip'}
                control={control}
                placeholder={'Enter Zip Code'}
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneField
                label={'Phone'}
                name={'PhoneNumber'}
                control={control}
                placeholder={'Enter Phone'}
                required={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <AppBox display={'flex'} justifyContent={'end'}>
                <AppButton variant="contained" isLoading={isUpdaing} type="submit">
                  Update
                </AppButton>
              </AppBox>
            </Grid>
          </Grid>
        </Form>
      </AppBox>
    </>
  )
}
