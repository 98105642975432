import { Grid } from '@mui/material'
import { AppBox, AppButton, AppDialog, SimpleField, SimpleValueSelect } from 'components'
import { PeopleInsightRolesOptions } from 'globals/constants'
import { usePeopleInsight } from 'hooks'
import { FC } from 'react'
import { PeopleInsightViewModel } from 'sdk'
import { PeopleInsightAdminAddNewForm } from './add-new/PeopleInsightAdminAddNewForm'
import { PeopleInsightAdminEditForm } from './edit/PeopleInsightAdminEditForm'
import { PeopleInsightAdminViewListing } from './listing/PeopleInsightAdminListing'

interface Props {}

export const PeopleInsightAdminView: FC<Props> = () => {
  const {
    filter,
    setFilter,
    AddPeopleInsight,
    addDialogFormRef,
    addDialogReset,
    editDialogFormRef,
    editReset,
    editControl,
    editHandleSubmit,
    handleSubmit,
    isAdding,
    isLinking,
    control,
    isGetting,
    PeopleInsights,
    DetachPeopleInsight,
    isDetaching,
    EditPeopleInsight,
    isEditing,
    handleGetPossibleMatches,
    possibleInsights,
    handleUseClick,
    selectedParkId,
    watch,
  } = usePeopleInsight()

  return (
    <>
      <AppBox paddingX={4} display={'flex'} flexDirection={'column'} gap={2}>
        <Grid container spacing={1} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={4}>
                <SimpleValueSelect
                  options={[{ label: 'All', value: 'All' }, ...PeopleInsightRolesOptions]}
                  defaultValue={'All'}
                  onValueChange={(value) => setFilter((prev) => ({ ...prev, EntityType: value.toString() }))}
                />
              </Grid>
              <Grid item xs={6}>
                <SimpleField
                  prefixIcon="iconamoon:search-bold"
                  placeholder="Search"
                  onChange={(value) => setFilter((prev) => ({ ...prev, Query: value.toString() }))}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <AppButton
              prefixIcon="ic:baseline-plus"
              variant="contained"
              onClick={() => {
                addDialogReset({ ParkId: selectedParkId })
                addDialogFormRef.current?.openDialog!()
              }}
            >
              Add
            </AppButton>
          </Grid>
        </Grid>
        <PeopleInsightAdminViewListing
          data={PeopleInsights?.Data ?? []}
          totalRecords={PeopleInsights?.TotalCount ?? 0}
          paginationFilter={filter}
          isLoading={isGetting || isDetaching}
          onDetachClick={(row: PeopleInsightViewModel) => DetachPeopleInsight(row.Id)}
          onEditClick={(row: PeopleInsightViewModel) => {
            editReset(row)
            editDialogFormRef.current?.openDialog!()
          }}
          onRoleChange={(row, value) => {
            EditPeopleInsight({ ...row, Role: value })
          }}
        />
      </AppBox>
      <AppDialog
        ref={addDialogFormRef}
        maxWidth="lg"
        title="Admin - New Insight"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit((data) => AddPeopleInsight(data)),
        }}
        submitButtonProps={{
          isLoading: isAdding || isLinking,
        }}
      >
        <PeopleInsightAdminAddNewForm
          control={control}
          onGetPossibleMatches={handleGetPossibleMatches}
          possibleInsights={possibleInsights ?? []}
          handleUseClick={handleUseClick}
        />
      </AppDialog>
      <AppDialog
        ref={editDialogFormRef}
        maxWidth="sm"
        title="Edit Insight"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: editHandleSubmit((data) => EditPeopleInsight(data)),
        }}
        submitButtonProps={{
          isLoading: isEditing,
          children: 'Update',
          sidePaddings: 4,
        }}
      >
        <PeopleInsightAdminEditForm control={editControl} />
      </AppDialog>
    </>
  )
}
