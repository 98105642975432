import { Grid } from '@mui/material'
import { AppTypography, SimpleField, SimpleValueSelect } from 'components'
import { Dispatch, FC, SetStateAction } from 'react'
import { PaginationFilterModel } from 'sdk'
const options = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'interested',
    label: 'Interested',
  },
]
interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
  totalUsers: number
}
export const UsersActions: FC<Props> = ({ filter, setFilter, totalUsers }) => {
  return (
    <>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item md={2} xs={4}>
          <SimpleValueSelect options={options} value={'All'} onValueChange={() => {}} />
        </Grid>
        <Grid item md={2} xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search Users"
            onChange={(val) => setFilter((prev) => ({ ...prev, PageNo: 1, Query: val.target.value }))}
          />
        </Grid>
        <Grid item>
          <AppTypography fontSize={16}>
            Total Users:<b>{totalUsers ?? 0} </b>
          </AppTypography>
        </Grid>
      </Grid>
    </>
  )
}
