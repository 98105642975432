export class ChangeMembershipPayload {
  public MembershipId: string | null = null
  public UserId: string | null = null
  public IsNewCard: boolean | null = null
  public PaymentMethodId: string | null = null
  public CardHolderName: string | null = null
  public PromotionCodeId: string | null = null
  constructor(data?: Partial<ChangeMembershipPayload>) {
    Object.assign(this, data)
  }
}
