import { CardElement } from '@stripe/react-stripe-js'
import { AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { Controller } from 'react-hook-form'

interface Props {
  control: any
}

export const StripeCardField: FC<Props> = ({ control }) => {
  const cardElementOptions = {
    style: {
      base: {
        fontFamily: 'Inter',
        fontSmoothing: 'antialiased',
        borderRadius: '10px',
        backgroundColor: 'white',
        color: '#64748B',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  }
  return (
    <AppBox>
      <Controller
        control={control}
        rules={{
          validate: (val) => {
            if (!val || val?.empty) {
              return 'This field is required'
            }
            if (val.error) {
              return val.error.message === null || 'Invalid card details'
            }
          },
        }}
        name="PaymentMethodId"
        render={({ fieldState: { error }, field }) => {
          return (
            <>
              <AppTypography component={'span'} fontWeight={'bold'}>
                Credit or debit card
                <AppTypography component={'span'} color={'red'} paddingLeft={1}>
                  *
                </AppTypography>
              </AppTypography>
              <AppBox marginTop={0.3} padding={1.3} borderRadius={3} border={1} borderColor={'secondary.600'}>
                <CardElement options={cardElementOptions} {...field} />
              </AppBox>
              {error && error.message && <AppTypography color="error">{error.message}</AppTypography>}
            </>
          )
        }}
      />
    </AppBox>
  )
}
