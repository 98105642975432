import { AppBox, AppCard, AppIcon } from '../..'

interface DrawerControlsProps {
  onClose: () => void
}
export const DrawerControls = ({ onClose }: DrawerControlsProps) => {
  return (
    <AppCard>
      <AppBox display={'flex'} justifyContent={'end'}>
        <AppIcon size="medium" icon="radix-icons:cross-circled" color="grey.500" onClick={() => onClose()} />
      </AppBox>
    </AppCard>
  )
}
