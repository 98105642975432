import { Grid, Skeleton } from '@mui/material'

export const TagsListLoader = () => {
  return (
    <Grid container gap={2}>
      {Array.from(new Array(6)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" width={90} height={30} style={{ borderRadius: 25 }} />
      ))}
    </Grid>
  )
}
