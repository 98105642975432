import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components'

export const ParcelDataLoader = () => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingX={4}>
        <AppBox borderBottom={1} borderColor={'grey.100'} paddingY={1} width={1}>
          <Skeleton variant="rounded" width={'20%'} height={40} />
        </AppBox>
        <Skeleton variant="rounded" width={'100%'} height={80} sx={{ color: 'transparent' }}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed neque accusamus similique cupiditate tempore
          tempora amet corporis dignissimos enim distinctio quae harum magni rerum illo exercitationem, veniam
          voluptatem, earum pariatur.
        </Skeleton>
        <Grid container justifyContent={'space-between'} gap={2}>
          {Array.from(new Array(6)).map(() => (
            <>
              <Grid item xs={5}>
                <Skeleton variant="rounded" width={'100%'} height={30} />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="rounded" width={'100%'} height={30} />
              </Grid>
            </>
          ))}
        </Grid>
      </AppBox>
    </>
  )
}
