import { Divider, Grid, Step, StepLabel, StepLabelProps, StepProps, Stepper } from '@mui/material'
import { AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ImportContactWizardStepOneFileUpload } from './step-1/ImportContactWizardStepOneFileUpload'
import { ImportContactWizardStepTwoContactType } from './step-2/ImportContactWizardStepTwoContactType'
import { useImportCrmContact } from 'hooks/crm/import-crm-contact.hook'

interface Props {}

export const ImportContactWizard: FC<Props> = () => {
 const {steps, fieldMappins, activeStep, nextClick, backClick, nextButtonText, isNextDisabled, setFile, setFieldMappings} = useImportCrmContact();
 const renderComponent = () => {
  if (activeStep === 0) {
    return <ImportContactWizardStepOneFileUpload onFileSelected={(file) => {setFile(file)}}/>
  } else if (activeStep === 1) {
    return <ImportContactWizardStepTwoContactType fieldMappings={fieldMappins} setFieldMappings={setFieldMappings} />
  }
  return <ImportContactWizardStepOneFileUpload onFileSelected={(file) => {setFile(file)}} />
}
  return (
    <Grid container minHeight={'inherit'} alignItems={'start'}>
      <Grid container>
        <Grid item xs={12}>
          <AppBox backgroundColor="white" padding={3}>
            <Stepper
              activeStep={activeStep}
              connector={<Divider sx={{ width: 200, marginX: 0.5, borderWidth: 1, borderColor: 'primary.600' }} />}
              sx={{ justifyContent: 'center' }}
            >
              {steps.map((step, index) => {
                const stepProps: StepProps = {}
                const labelProps: StepLabelProps = {}
                return (
                  <Step key={step} {...stepProps}>
                    <StepLabel
                      icon={
                        <AppBox
                          width={25}
                          height={25}
                          backgroundColor="primary.100"
                          borderRadius={12}
                          textAlign={'center'}
                          justifySelf={'center'}
                          paddingTop={0.2}
                        >
                          {activeStep > index ? (
                            <AppIcon icon={'material-symbols:check'} size="medium" color="primary" />
                          ) : (
                            <AppTypography fontSize={14}>{index + 1}</AppTypography>
                          )}
                        </AppBox>
                      }
                      {...labelProps}
                    >
                      <AppTypography fontSize={16} color={'primary.600'} fontWeight={600}>
                        {step}
                      </AppTypography>
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </AppBox>
        </Grid>
        <AppBox padding={2} width={'100%'}>
          {renderComponent()}
        </AppBox>
      </Grid>
      <Grid item xs={12} alignSelf={'end'}>
        <AppBox display={'flex'} backgroundColor="white" justifyContent={'flex-end'} padding={2} gap={1}>
          {activeStep > 0 && (
            <AppButton variant="contained" size="large" onClick={backClick}>
              Back
            </AppButton>
          )}
          {activeStep < steps.length - 1 && (
            <AppButton variant="contained" size="large" onClick={nextClick} disabled={isNextDisabled()}>
              {nextButtonText()}
            </AppButton>
          )}
        </AppBox>
      </Grid>
    </Grid>
  )
}
