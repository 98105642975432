import { RoleTypes } from 'globals/constants'
import { useSelector } from 'react-redux'
import { AuthSliceSelector } from 'store/slices'

export const useRolesHelper = () => {
  const { Roles } = useSelector(AuthSliceSelector)
  const IsAdmin = () => {
    if (!Roles) return false
    return Roles.includes(RoleTypes.Admin)
  }
  const IsSubAccount = () => {
    return Roles.includes(RoleTypes.SubAccount)
  }
  const IsTrialUser = () => {
    return Roles.includes(RoleTypes.TrialUser)
  }
  return { IsAdmin, IsSubAccount, IsTrialUser }
}
