import { AppBox, AppIcon, AppTypography } from 'components'

export const NoPinnedParks = () => {
  return (
    <AppBox backgroundColor="grey.100" height={'71vh'} alignContent={'space-evenly'} position={'relative'}>
      <AppBox textAlign={'center'} marginTop={-6}>
        <AppIcon icon="line-md:map-marker-filled" height={'30%'} color="#F6A723" />
        <AppTypography fontWeight={'bold'} fontSize={22}>
          No Parks Pinned Yet?
        </AppTypography>
        <AppTypography fontSize={16} color={'grey.500'} fontWeight={400}>
          Pinning a park allows you to quickly identify parks using custom map pin colors and labels. After pinning a
          park within its <b>Activity Center</b>, it will begin displaying here.
        </AppTypography>
        <AppTypography fontSize={16} color={'grey.500'} fontWeight={400} paddingTop={4}>
          Use the <b>Manage</b> option above to customize the colors and labels that should be used to pin a park.{' '}
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
