import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps'
import { AppBox, AppIcon } from 'components/base'
import { AppGoogleMapUniqueKeys } from 'globals/constants'
import { FC } from 'react'

interface Props {}
export const GoogleMapsZoomBackControl: FC<Props> = () => {
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)
  return (
    <MapControl position={ControlPosition.TOP_RIGHT}>
      <AppBox
        margin={3}
        backgroundColor="white"
        padding={1}
        alignItems={'center'}
        cursor="pointer"
        onClick={() => map?.setZoom(4)}
      >
        <AppIcon
          icon="flowbite:expand-outline"
          color="secondary"
          size="large"
          cursor={'pointer'}
          style={{ fontSize: '25px' }}
          onClick={() => map?.setZoom(4)}
        />
      </AppBox>
    </MapControl>
  )
}
