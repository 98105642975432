import { AppBox } from 'components'
import { CrmTools } from 'globals/constants'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { CrmToolSliceSelector } from 'store/slices'
import { CrmCompanies } from './companies/CrmCompanies'
import { CrmContacts } from './contacts/CrmContacts'
import { ImportContactWizard } from './import-contact-wizard/ImportContactWizard'

export const CrmContent = () => {
  const mapping: { [key: string]: ReactNode } = {
    [CrmTools.Contacts]: <CrmContacts />,
    [CrmTools.Companies]: <CrmCompanies />,
    [CrmTools.Import]: <ImportContactWizard />,
  }
  const { openedCrmTool } = useSelector(CrmToolSliceSelector)

  return <AppBox minHeight={'inherit'}>{mapping[openedCrmTool]}</AppBox>
}
