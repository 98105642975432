import { Grid } from '@mui/material'
import { AppBox, AppTextField } from 'components/base'
import { FC } from 'react'
import { StripeCardField } from '../stipe-card-field/StripeCardField'
import { AttachPaymentMethodPayloadModel } from 'sdk'
import { Control } from 'react-hook-form'
import { StateField } from 'components/shared/form/States/StatesField'

interface Props {
  control: Control<AttachPaymentMethodPayloadModel>
}

export const AddPaymentMethodForm: FC<Props> = ({ control }) => {
  return (
    <AppBox border={1} borderColor={'grey.300'} paddingX={4} paddingY={2} borderRadius={4}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AppTextField
            name="CardHolderName"
            control={control}
            label="Name on Card"
            placeholder="Enter Name on Card"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <StripeCardField control={control} />
        </Grid>
        <Grid item xs={12}>
          <AppTextField
            name="Address"
            label="Street Address"
            control={control}
            placeholder="Enter Street Address on Card"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <AppTextField name="City" label="City" control={control} placeholder="Enter City on Card" required />
        </Grid>
        <Grid item xs={4}>
          <StateField
            name="State"
            label="State"
            control={control}
            placeholder="Select State"
            required
            BindName={true}
          />
        </Grid>
        <Grid item xs={4}>
          <AppTextField name="Zip" label="Zip" control={control} placeholder="Zip Code" required />
        </Grid>
      </Grid>
      <AppBox
        display={'flex'}
        justifyContent={'space-between'}
        borderTop={1}
        borderColor={'grey.200'}
        marginTop={3}
        paddingTop={3}
      >
        <AppBox display={'flex'} gap={1}>
          <img src="/images/stripe/AmericanExpress.png" alt="visa" />
          <img src="/images/stripe/Visa.png" alt="visa" />
          <img src="/images/stripe/Master.png" alt="visa" />
          <img src="/images/stripe/Discover.png" alt="visa" />
        </AppBox>
        <AppBox>
          <img src="/images/stripe/PoweredByStripe.png" alt="visa" />
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
