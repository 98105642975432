import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, AppTypography, EmailText, TableActions } from 'components'
import { SubAccountStatus } from 'globals/constants'
import { getFullDate } from 'globals/functions'
import { FC } from 'react'
import { SubAccountViewModel } from 'sdk'

interface Props extends AppTableProps<SubAccountViewModel> {
  onManageClick: () => void
  onResendInvitationClick: () => void
}

export const SubAccountsTable: FC<Props> = ({ onManageClick, onResendInvitationClick, ...props }) => {
  const columns: ColumnDef<SubAccountViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        const actions = [{ text: 'Manage', onClick: onManageClick }]
        if (row.original.Status === SubAccountStatus.Pending) {
          actions.push({ text: 'Resend Invitation', onClick: onResendInvitationClick })
        }
        return <TableActions row={row} actions={actions} />
      },
    },
    {
      header: 'Status',
      cell: ({ row }) => <AppTypography color={'grey.500'}>{row.original.Status}</AppTypography>,
    },
    {
      header: 'Name',
      accessorKey: 'Name',
    },

    {
      header: 'Email',
      cell: ({ row }) => <EmailText email={row.original.Email} />,
    },
    {
      header: 'Data Added',
      cell: ({ row }) => <AppTypography color={'grey.500'}>{getFullDate(row.original.DateAdded)}</AppTypography>,
    },
    { header: 'Account Type', cell: () => <AppTypography color={'grey.500'}>Sub-Account</AppTypography> },
    { header: 'Next Billing', cell: () => <AppTypography color={'grey.500'}>4/30/2024</AppTypography> },
  ]
  return <AppTable columns={columns} {...props} />
}
