export class PaginationFilterModel {
  public PageNo: number | null = null
  public PageSize: number | null = null
  public Query: string | null = null
  public SortBy: string | null = null
  public SortDesc: boolean | null = null
  public EntityType: string | null = null
  constructor(data?: PaginationFilterModel) {
    this.PageNo = 1
    this.PageSize = 10
    Object.assign(this, data)
  }
}
