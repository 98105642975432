import { Grid, Typography } from '@mui/material'
import { AppBox, AppDropZone, AppTypography } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'

interface Props {
  onFileSelected:(file: File) => void
}

export const ImportContactWizardStepOneFileUpload: FC<Props> = ({onFileSelected} ) => {
  return (
    <Grid container spacing={2} alignItems={'stretch'}>
      <Grid item xs={12}>
        <AppBox backgroundColor="white" padding={2} borderRadius={3} border={1} borderColor={'grey.200'}>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
            Please provide the CSV or TXT file you wish to import. Click Upload to proceed with mapping the fields
            within your file to the appropriate CRM fields.
          </AppTypography>
        </AppBox>
      </Grid>
      <Grid item xs={6}>
        <AppBox
          backgroundColor="white"
          padding={2}
          borderRadius={3}
          border={1}
          borderColor={'grey.200'}
          height={'100%'}
        >
          <AppTypography fontWeight={'bold'} fontSize={14}>
            Important
          </AppTypography>
          <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingTop={1}>
            <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
              <b>Duplicate Email Addresses -</b> To ensure data integrity, we automatically clean duplicate instances of
              email addresses from the import.
            </AppTypography>
            <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
              Duplicate Company Names - To ensure data integrity, we automatically group company information by Company
              Name, and then link the related contacts to their corresponding company. Having multiple variations of a
              company name will result in multiple companies being imported to your Companies Tab records.
            </AppTypography>
            <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
              State Abbreviations - For consistency with our system all state abbreviations will be automatically
              converted to the state's full name.
            </AppTypography>
          </AppBox>
        </AppBox>
      </Grid>
      <Grid item xs={6}>
        <AppBox
          backgroundColor="white"
          padding={2}
          borderRadius={3}
          border={1}
          borderColor={'grey.200'}
          height={'100%'}
        >
          <Grid item xs={7}>
            <AppTypography fontSize={14} fontWeight={'bold'}>
              Upload File
            </AppTypography>
          </Grid>
          <Grid item xs={5}>
            <AppDropZone onFileSelect={(files) => onFileSelected(files[0])} acceptedFileTypes={{'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ': [], 'text/csv': []}}/>
          </Grid>
        </AppBox>
      </Grid>
    </Grid>
  )
}
