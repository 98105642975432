import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useGetLabelledParks } from 'hooks'
import { useCallback } from 'react'
import { ParkLabelApi, UserLabelledParkPayloadModel } from 'sdk'

const parkLabelApi = new ParkLabelApi()
export const useAddLabelledPark = () => {
  const { getPinnedParks } = useGetLabelledParks()

  const { mutate, data, isPending } = useMutation({
    mutationFn: parkLabelApi.AddLabelledPark,
    onSuccess: () => handleAfterLabelledParkAdded(),
  })

  const handleAfterLabelledParkAdded = () => {
    getPinnedParks()
  }

  const AddLabelledPark = useCallback((payload: UserLabelledParkPayloadModel) => {
    return mutate(payload)
  }, [])

  return {
    AddLabelledPark,
    addedLabelledPark: data,
    isAddingLabelledPark: isPending,
  }
}
