import { AppBox } from 'components'
import { useCrmCompanies } from 'hooks'
import { CompanyActions, CrmCompaniesListing } from 'views'

export const CrmCompanies = () => {
  const {
    control,
    showableCompanies,
    showableCompaniesCount,
    setError,
    isAddingCompany,
    dialogRef,
    handleSubmit,
    handleAddClicked,
    submit,
    handleDeleteClick,
    handleDeleteAllClikc,
    isDeleting,
    isGetting,
    isEditing,
    selectedCompaniesIds,
    setSelectedCompaniesIds,
    isAllCompaniesSelected,
    setIsAllCompaniesSelected,
    filter,
    setFilter,
    setIsApplyingFilter,
    watch,
    isApplyingFilter,
  } = useCrmCompanies({ fetchCompaniesOnFilterChange: true, fetchCompaniesOnFormSubmit: true })
  return (
    <AppBox minHeight={'inherit'}>
      <CompanyActions
        control={control}
        setError={setError}
        isAddingCompany={isAddingCompany}
        dialogRef={dialogRef}
        selectedCompaniesIds={selectedCompaniesIds}
        isAllCompaniesSelected={isAllCompaniesSelected}
        watch={watch}
        handleAddClicked={handleAddClicked}
        handleSubmit={handleSubmit}
        handleFormSubmit={submit}
        handleQuery={(val) => setFilter({ ...filter, Query: val })}
        handleTypeChange={(val) => setFilter({ ...filter, EntityType: val })}
        handleDeleteCompanies={handleDeleteClick}
        handleDeleteAllCompanies={handleDeleteAllClikc}
        paginationFilter={filter}
        setIsApplyingFilter={setIsApplyingFilter}
      />
      <AppBox minHeight={'72vh'}>
        <CrmCompaniesListing
          companies={showableCompanies() ?? []}
          isLoading={isDeleting || isGetting || isApplyingFilter}
          paginationFilter={filter}
          totalRecords={showableCompaniesCount() ?? 0}
          onCurrentPageChange={(val) => setFilter({ ...filter, PageNo: val })}
          onPageSizeChange={(val) => setFilter({ ...filter, PageSize: val })}
          onRowSelectionChange={(val) => setSelectedCompaniesIds(val)}
          onSelectAllRowsChange={(val) => setIsAllCompaniesSelected(val)}
        />
      </AppBox>
    </AppBox>
  )
}
