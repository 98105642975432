import { ParkSitesTools } from 'globals/constants'
import { useRolesHelper } from 'hooks'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FilterSliceSelector, GoogleMapSliceSelector, ParkSiteToolSliceSelector } from 'store/slices'

export const useShowableParkPins = () => {
  const { openedTool } = useSelector(ParkSiteToolSliceSelector)
  const {
    ParkPins,
    SelectedParkIdToMove,
    LabelledParkPins,
    TaggedParkPins,
    CrmContactLinkedParksState,
    CrmContactLinkedParkPins,
    LinkedCrmContactsParkPins,
  } = useSelector(GoogleMapSliceSelector)
  const {
    Park: { IsUnSavedFilterApplied: IsFilterApplied, AppliedFilter, FilteredData },
  } = useSelector(FilterSliceSelector)
  const { IsAdmin } = useRolesHelper()
  const location = useLocation()

  const currentMarkers = () => {
    if (!!SelectedParkIdToMove && IsAdmin()) {
      return []
    } else if (!!openedTool) {
      if (openedTool === ParkSitesTools.PinnedParks) {
        return LabelledParkPins
      } else if (openedTool === ParkSitesTools.TaggedParks) {
        return TaggedParkPins
      } else if (openedTool === ParkSitesTools.CRM) {
        return LinkedCrmContactsParkPins
      }
    } else if (IsFilterApplied || AppliedFilter) {
      return FilteredData
    } else if (location.pathname.includes('crm/contacts/')) {
      return CrmContactLinkedParksState?.isShowing ? CrmContactLinkedParkPins : ParkPins ?? []
    } else if (location.pathname.includes('crm/companies')) {
      return []
    }
    return ParkPins
  }
  return {
    currentMarkers,
  }
}
