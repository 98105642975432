import { AppDialog, AppDialogRefType } from 'components'
import { useFeedBack } from 'hooks'
import { forwardRef } from 'react'
import { useForm } from 'react-hook-form'
import { FeedbackPayload } from 'sdk'
import { FeedBackForm } from './FeedBackForm'
import { FeedbackTypes } from 'globals/constants'

interface Props {
  handleSubmitSuccess: (success: boolean) => void
}
export const FeedBackDialog = forwardRef(
  ({ handleSubmitSuccess }: Props, ref: React.ForwardedRef<AppDialogRefType>) => {
    const { control, handleSubmit, getValues } = useForm<FeedbackPayload>({
      defaultValues: {
        Type: FeedbackTypes.Application,
      },
    })
    const { SaveFeedBack, IsSaving } = useFeedBack()

    return (
      <AppDialog
        title="Rate ParkSitesIO"
        ref={ref}
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(() =>
            SaveFeedBack(getValues())
              .then(() => handleSubmitSuccess(true))
              .catch(() => {})
          ),
        }}
        submitButtonProps={{ isLoading: IsSaving, children: 'Submit' }}
        renderChild={(closeDialog) => <FeedBackForm control={control} />}
      />
    )
  }
)
