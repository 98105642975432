import { createSlice } from '@reduxjs/toolkit'
import { IAppStore } from 'store/app.store'

interface CrmLinkedToolsHelperType {
  isParkActivityCrmUpdated: number
  isParkActivityCrmContactCompanyUpdated: number
}

const CrmLinkedToolsHelperInitialState: CrmLinkedToolsHelperType = {
  isParkActivityCrmUpdated: 0,
  isParkActivityCrmContactCompanyUpdated: 0,
}

export const CrmLinkedToolsHelperSlice = createSlice({
  name: 'CrmLinkedToolsHelper',
  initialState: CrmLinkedToolsHelperInitialState,
  reducers: {
    SetIsParkActivityCrmUpdated: (state) => {
      state.isParkActivityCrmUpdated += 1
    },
    SetIsParkActivityCrmContactCompanyUpdate: (state) => {
      state.isParkActivityCrmContactCompanyUpdated += 1
    },
  },
})
export const { SetIsParkActivityCrmUpdated, SetIsParkActivityCrmContactCompanyUpdate } =
  CrmLinkedToolsHelperSlice.actions
export const CrmLinkedToolsHelperSelector = (appState: IAppStore): CrmLinkedToolsHelperType =>
  appState.CrmLinkedToolsHelper
