import { AppTextField, AppTextFieldProps, SimpleField } from 'components/base'
import { FC } from 'react'

interface Props extends Omit<AppTextFieldProps, 'control' | 'name'> {
  control?: any
  name?: string
}

export const PhoneField: FC<Props> = ({ control, name, ...rest }) => {
  const Tmask = `(999) 999-9999`
  return (
    <>
      {control ? (
        <AppTextField
          control={control}
          name={name!}
          {...rest}
          mask={Tmask}
          suffixIcon="ph:phone-fill"
          customRules={{ ...rest.customRules, Mask: { optionValue: Tmask, isRequired: !!rest.required } }}
        />
      ) : (
        <SimpleField {...rest} mask={Tmask} suffixIcon="ph:phone-fill" />
      )}
    </>
  )
}
