import { Grid } from '@mui/material'
import { AppButton, AppDialog, AppDialogRefType, SimpleField } from 'components'
import { useStates } from 'hooks'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PaginationFilterModel, StatePayload } from 'sdk'
import { AddEditStateForm } from '../content/states/add-edit-state-form/AddEditStateForm'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}

export const StateLinksActions: FC<Props> = ({ filter, setFilter }) => {
  const { control, handleSubmit, getValues, reset } = useForm<StatePayload>()
  const dialogRef = useRef<AppDialogRefType>(null)
  const { AddState, isAddingState } = useStates()

  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item md={2} xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search Contacts"
            value={filter.Query ?? ''}
            onChange={(event) => {
              setFilter((prev) => ({ ...prev, Query: event.target.value }))
            }}
          />
        </Grid>
        <Grid item>
          <AppDialog
            maxWidth="sm"
            ref={dialogRef}
            title="Add State"
            appDialogWrapperProps={{
              component: 'form',
              role: 'form',
              onSubmit: handleSubmit(() => {
                AddState(getValues())
                  .then((x) => {
                    dialogRef.current?.closeDialog!()
                    reset()
                  })
                  .catch((err) => {})
              }),
            }}
            submitButtonProps={{ isLoading: isAddingState }}
            renderElement={(openDialog) => (
              <AppButton prefixIcon="icon-park-solid:add" color="secondary" onClick={openDialog}>
                Add New
              </AppButton>
            )}
            renderChild={(openDialog) => <AddEditStateForm control={control} />}
          />
        </Grid>
      </Grid>
    </>
  )
}
