import { Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppTypography, TextWithToolTip } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TeamApi, TeamMemberViewModel } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
import { OutSideTeamMembersTable } from './team-members-listing/OutSideTeamMembersTable'

interface Props {
  teamMembers: TeamMemberViewModel[]
  isGettingTeamMembers: boolean
  getTeamMembers: (teamId: string) => void
}
export const OutSideTeamMembersCard: FC<Props> = ({ teamMembers, isGettingTeamMembers, getTeamMembers }) => {
  const teamApi = new TeamApi()
  const { OwnTeam } = useSelector(TeamSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: RevokeAccess, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.team.RevokeAccess],
    mutationFn: (data: { teamId: string; UserId: string }) => teamApi.revokeTeamMember(data.teamId, data.UserId),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar('Access revoked successfully', { variant: 'success' })
      getTeamMembers(OwnTeam!.Id)
    },
  })
  const { mutate: resendInvite, isPending: sendingInvite } = useMutation({
    mutationFn: (data: { teamId: string; UserId: string }) => teamApi.resendInvite(data.teamId, data.UserId),
    mutationKey: [ApiQueryKeys.team.ReSendInvitation],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Invitation resent successfully', { variant: 'success' })
      getTeamMembers(OwnTeam!.Id)
    },
  })

  useEffect(() => {
    if (OwnTeam) {
      getTeamMembers(OwnTeam.Id)
    }
  }, [getTeamMembers, OwnTeam])

  return (
    <AppBox border={1} padding={3} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
      <TextWithToolTip tooltipText="Manage the people you’ve invited to work within your space using these options.">
        <AppTypography fontWeight={700} fontSize={16}>
          Invitees
        </AppTypography>
      </TextWithToolTip>
      <Divider sx={{ marginBlock: 1 }} />
      <Grid container spacing={2} alignItems={'center'} paddingBottom={2}>
        <Grid item sm={1.5}>
          {/* <AppSelect options={options} fullWidth defaultValue={'all'} /> */}
        </Grid>
        <Grid item>{/* <SearchField name="" control={control} placeholder="Search" /> */}</Grid>
      </Grid>
      <OutSideTeamMembersTable
        data={teamMembers ?? []}
        isLoading={isPending || sendingInvite || isGettingTeamMembers}
        onRevokeAccessClick={(memeber) => RevokeAccess({ teamId: OwnTeam!.Id, UserId: memeber.UserId })}
        onResendInviteClick={(memeber) => resendInvite({ teamId: OwnTeam!.Id, UserId: memeber.UserId })}
      />
    </AppBox>
  )
}
