import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components/base'

export const CrmContactCompaniesHelperLoader = () => {
  return (
    <>
      {Array.from(new Array(2)).map(() => (
        <>
          <AppBox
            backgroundColor="grey.50"
            borderColor={'white'}
            border="1px"
            padding={2}
            borderRadius={3}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
          >
            <Skeleton variant="text" width={'30%'} height={30} />

            <Skeleton variant="text" width={'40%'} height={30} />

            <AppBox display={'flex'} gap={2}>
              <Skeleton variant="text" width={'20%'} height={30} />
              <AppBox width={8} height={8} backgroundColor={'secondary.600'} borderRadius={1} alignSelf={'center'} />
              <Skeleton variant="text" width={'20%'} height={30} />
              <Skeleton variant="text" width={'20%'} height={30} />
            </AppBox>
            <Skeleton variant="text" width={'30%'} height={30} />
          </AppBox>
        </>
      ))}
    </>
  )
}
