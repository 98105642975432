import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppAutoComplete, AppBox, AppTextField, EmailField, PhoneField, StateField, ZipField } from 'components'
import { ApiQueryKeys, FormFiledRulesKeys } from 'globals/constants'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { UseFormSetError, UseFormWatch } from 'react-hook-form'
import { CrmContactPayload, CrmContactsApi, CrmContactViewModel } from 'sdk'

interface Props {
  control: any
  setError: UseFormSetError<CrmContactPayload>
  originalData?: CrmContactViewModel | null
  watch: UseFormWatch<CrmContactPayload>
}
const crmContactApi = new CrmContactsApi()
export const AddUpdateCrmContactForm = ({ control, originalData, watch, ...props }: Props) => {
  const {
    data: isEmailExist,
    mutate: isUserContctExist,
    isPending: isCheckingEmail,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.IsContactExist],
    mutationFn: (data: { email: string; contactId?: string }) =>
      crmContactApi.isUserContctExist(data.email, data.contactId),
  })
  const debouncedChangeHandler = debounce((value) => {
    const isValid = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}$/.test(value)
    if (isValid && value !== originalData?.ContactEmail) {
      isUserContctExist({ email: value })
    }
  }, 500)

  useEffect(() => {
    if (isEmailExist) {
      props.setError('ContactEmail', {
        type: FormFiledRulesKeys.EmailExist,
        message: 'Email already exist',
      })
    }
  }, [isEmailExist])

  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingX={1}>
      <AppBox padding={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <AppAutoComplete
              required
              label="Contact Type"
              placeholder="Select Contact Type"
              control={control}
              name="ContactType"
              options={[
                { label: 'Lead', value: 'lead' },
                { label: 'Contact', value: 'contact' },
              ]}
            />
          </Grid>
          <Grid item md={6}>
            <EmailField
              required
              label="Email"
              control={control}
              name="ContactEmail"
              placeholder="Enter Email"
              isLoading={isCheckingEmail}
              customRules={{
                EmailExist: { optionValue: !!isEmailExist && watch().ContactEmail !== originalData?.ContactEmail },
              }}
              onFieldChange={(e) => debouncedChangeHandler(e.target.value)}
            />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="First Name" control={control} name="ContactFirstName" placeholder="Enter First Name" />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="Last Name" control={control} name="ContactLastName" placeholder="Enter Last Name" />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="Address" control={control} name="ContactAddress" placeholder="Enter Address" />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="Address (2)" control={control} name="ContactAddress2" placeholder="Enter Address2" />
          </Grid>
          <Grid item md={5}>
            <AppTextField label="City" control={control} name="ContactCity" placeholder="Enter City" />
          </Grid>
          <Grid item md={4}>
            <StateField label="State" control={control} name="ContactStateId" placeholder="Enter State" />
          </Grid>
          <Grid item md={3}>
            <ZipField label="Zip" control={control} name="ContactZip" placeholder="Enter Zip" />
          </Grid>
          <Grid item md={6}>
            <PhoneField label="Phone" control={control} name="ContactPhone" placeholder="Enter Phone" />
          </Grid>
          <Grid item md={6}>
            <PhoneField label="Alt Phone" control={control} name="ContactAltPhone" placeholder="Enter Alt Phone" />
          </Grid>
        </Grid>
      </AppBox>
    </AppBox>
  )
}
