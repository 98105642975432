import { AppBar, Toolbar } from '@mui/material'
import { AppBox, AppButton, AppTypography, ViewsSelectBtn } from 'components'
import { AdminSettingsTools } from 'globals/constants'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { AdminSettingToolsSelector, setAdminSettingTool } from 'store/slices'

const links = [
  { title: AdminSettingsTools.Users, icon: 'material-symbols:contacts-product', key: AdminSettingsTools.Users },
  {
    title: AdminSettingsTools.News_Alerts,
    icon: 'material-symbols:contacts-product',
    key: AdminSettingsTools.News_Alerts,
  },
  { title: AdminSettingsTools.States, icon: 'material-symbols:contacts-product', key: AdminSettingsTools.States },
  { title: AdminSettingsTools.Counties, icon: 'material-symbols:contacts-product', key: AdminSettingsTools.Counties },
  { title: AdminSettingsTools.City_Data, icon: 'material-symbols:contacts-product', key: AdminSettingsTools.City_Data },
  { title: AdminSettingsTools.Reviews, icon: 'material-symbols:contacts-product', key: AdminSettingsTools.Reviews },
]
export const AdminSettingToolBar = () => {
  const { openedAdminSettingTool } = useSelector(AdminSettingToolsSelector)
  const dispatch = useAppDispatch()
  const handleButtons = (key: string) => {
    dispatch(setAdminSettingTool(key))
  }
  return (
    <>
      <AppBox flexGrow={1} borderBottom={1} borderColor={'grey.100'} backgroundColor="white">
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar>
            <AppBox flex={1}>
              <AppTypography fontSize={24} fontWeight={700}>
                Admin Settings / {openedAdminSettingTool}
              </AppTypography>
            </AppBox>
            <AppBox>
              <AppBox display={'flex'} gap={2}>
                {links.map((link, index) => (
                  <AppButton
                    key={index}
                    color={openedAdminSettingTool === link.key ? 'primary' : 'secondary'}
                    prefixIcon={link.icon}
                    onClick={() => handleButtons(link.key)}
                  >
                    {link.title}
                  </AppButton>
                ))}
                <ViewsSelectBtn />
              </AppBox>
            </AppBox>
          </Toolbar>
        </AppBar>
      </AppBox>
    </>
  )
}
