import { BaseInputProps } from 'globals/typings'
import { getValidationRules } from 'plugins/helper/helper'
import { Controller } from 'react-hook-form'
import { SimpleCheckBox, SimpleCheckBoxProps } from '../simple-fields/SimpleCheckBox'
interface props extends BaseInputProps, SimpleCheckBoxProps {
  onFieldChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const AppCheckBox = ({
  label,
  control,
  required,
  labelTextProps,
  customRules,
  onFieldChange,
  ...props
}: props) => {
  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={props.defaultValue ?? false}
      rules={{
        required: required,
        validate: (value) => getValidationRules(value, props.name!, customRules ?? {}),
      }}
      render={({ field }) => {
        return (
          <SimpleCheckBox
            {...field}
            {...props}
            label={label}
            value={field.value}
            labelTextProps={labelTextProps}
            onChange={(e) => {
              if (onFieldChange) {
                onFieldChange(e)
              }
              field.onChange(e.target.checked)
            }}
          />
        )
      }}
    />
  )
}
