import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParksApi, ParkListTab } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { TeamSliceSelector, setIsLoadingMap, setLinkedCrmContactsParkPins } from 'store/slices'

export const useGetParksWithCrm = () => {
  const linkedParksApi = new CrmContactLinkedParksApi()
  const dispatch = useAppDispatch()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { mutate, data, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parksWithCrm.FetchParksWithCrm],
    mutationFn: (query: string) => linkedParksApi.getParksWithCrm(query),
    onSuccess: (data) => onSuccess(data),
  })

  const onSuccess = (data: ParkListTab[]) => {
    dispatch(setIsLoadingMap(false))
    dispatch(setLinkedCrmContactsParkPins(data?.map((x) => x.Position) ?? []))
  }

  useEffect(() => {
    dispatch(setIsLoadingMap(true))
    mutate('')
  }, [SelectedTeam?.Id])
  return {
    parksWithCrm: data,
    isFetchingParksWithCrm: isPending,
    refetch: mutate,
  }
}
