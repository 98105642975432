import { Divider } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography, ChangePasswordForm, TextWithToolTip } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useLogout } from 'hooks'
import { useSnackbar } from 'notistack'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AuthApi, ChangePasswordPayloadModel } from 'sdk'
import { AuthSliceSelector } from 'store/slices'
export const ChangePasswordModule = () => {
  const authApi = new AuthApi()
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const { control, reset } = useForm<ChangePasswordPayloadModel>({
    mode: 'onChange',
    defaultValues: {
      Id: CurrentUser?.UserId!,
    },
  })
  const { logout } = useLogout()

  const { mutate, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.account.changePassword],
    mutationFn: (data: ChangePasswordPayloadModel) => authApi.changePassword(data),
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: 'error' })
    },
    onSuccess: () => {
      reset()
      enqueueSnackbar('Password updated successfully, enter your new password to login again', { variant: 'success' })
      logout()
    },
  })
  return (
    <AppBox border={1} padding={2} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
      <TextWithToolTip tooltipText="Manage the password you use to log into your account. Changes will require you to login again">
        <AppTypography fontWeight={'bold'} fontSize={16}>
          Change Password
        </AppTypography>
      </TextWithToolTip>
      <Divider sx={{ marginBlock: 1 }} />
      <Form control={control} onSubmit={({ data }) => mutate(data)}>
        <AppBox paddingTop={2}>
          <ChangePasswordForm control={control} />
        </AppBox>
        <AppBox paddingTop={2} display={'flex'} justifyContent={'end'}>
          <AppButton variant="contained" isLoading={isPending} type="submit">
            Update
          </AppButton>
        </AppBox>
      </Form>
    </AppBox>
  )
}
