import { Grid } from '@mui/material'
import { AppBox, AppTextField, PhoneField, StateField, WebsiteField, ZipField } from 'components'
import { Control, UseFormSetError, UseFormWatch } from 'react-hook-form'
import { CrmCompanyPayload } from 'sdk'

interface Props {
  control: Control<CrmCompanyPayload>
  setError: UseFormSetError<CrmCompanyPayload>
  watch: UseFormWatch<CrmCompanyPayload>
}
export const AddUpdateCrmCompanyForm = ({ control, watch, ...props }: Props) => {
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2}>
      <AppBox padding={2}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <AppTextField label="Company Name" control={control} name="Name" placeholder="Enter First Name" required />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="Address" control={control} name="Address" placeholder="Enter Address" />
          </Grid>
          <Grid item md={6}>
            <AppTextField label="Address (2)" control={control} name="Address2" placeholder="Enter Address2" />
          </Grid>
          <Grid item md={5}>
            <AppTextField label="City" control={control} name="City" placeholder="Enter City" />
          </Grid>
          <Grid item md={4}>
            <StateField label="State" control={control} name="State" placeholder="Enter State" />
          </Grid>
          <Grid item md={3}>
            <ZipField label="Zip" control={control} name="Zip" placeholder="Enter Zip" />
          </Grid>
          <Grid item md={6}>
            <PhoneField label="Phone" control={control} name="Phone" placeholder="Enter Phone" />
          </Grid>
          <Grid item md={6}>
            <PhoneField label="Alt Phone" control={control} name="AltPhone" placeholder="Enter Alt Phone" />
          </Grid>
          <Grid item md={6}>
            <WebsiteField
              webSiteValue={watch().Website}
              label="Website"
              control={control}
              name="Website"
              placeholder="Enter Website"
            />
          </Grid>
        </Grid>
      </AppBox>
    </AppBox>
  )
}
