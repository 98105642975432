import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components'

export const ParkActivityCenterLoader = () => {
  return (
    <AppBox padding={2} display={'flex'} flexDirection={'column'} gap={2}>
      <AppBox display={'flex'} alignItems={'center'} gap={1}>
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      </AppBox>
      <AppBox
        display={'flex'}
        justifyContent={'space-between'}
        borderTop={1}
        borderBottom={1}
        borderColor={'grey.200'}
        paddingY={2}
      >
        <Grid container wrap="nowrap" gap={1}>
          <Grid item xs={8}>
            <Skeleton variant="rounded" width={'100%'} height={90} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rounded" width={'100%'} height={90} />
          </Grid>
        </Grid>
      </AppBox>
      <Grid container spacing={3} justifyContent={'center'} paddingTop={2}>
        {Array.from(new Array(12)).map(() => (
          <Grid item xs={6}>
            <Skeleton variant="rounded" width={'100%'} height={30} />
          </Grid>
        ))}
      </Grid>
    </AppBox>
  )
}
