import { Divider } from '@mui/material'
import { AppBox, AppButton, AppDialog, AppTypography } from 'components'
import { AddEditNoteForm } from 'components/shared/add-edit-note-form/AddEditNoteForm'
import { useCrmNotesCRUD } from 'hooks'
import { CrmNotesTable } from './listing/CrmNotesTable'

interface Props {
  type: 'Contact' | 'Company'
  contactId?: string
  companyId?: string
}
export const CrmNotes = (props: Props) => {
  const {
    notes,
    isAddingCrmNote,
    isEditingNote,
    isLoadingNotes,
    idToBeEdited,
    dialogRef,
    handleAddClicked,
    handleDeleteClicked,
    handleEditClicked,
    handleFormSubmit,
    control,
    handleSubmit,
  } = useCrmNotesCRUD({ type: props.type, contactId: props.contactId, companyId: props.companyId })

  return (
    <AppBox
      border={1}
      paddingX={4}
      paddingY={2}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Companies"
    >
      <AppBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={2}>
        <AppTypography fontWeight={700} fontSize={16}>
          Notes
        </AppTypography>
        <AppBox>
          <AppDialog
            title={idToBeEdited ? 'Edit Note' : 'Add Note'}
            ref={dialogRef}
            appDialogWrapperProps={{
              component: 'form',
              role: 'form',
              onSubmit: handleSubmit(handleFormSubmit),
            }}
            submitButtonProps={{
              isLoading: isAddingCrmNote || isEditingNote,
            }}
            renderElement={(openDialog) => (
              <AppButton variant="contained" size="large" prefixIcon="ic:baseline-plus" onClick={handleAddClicked}>
                <AppTypography fontWeight={'bold'} fontSize={14}>
                  Add
                </AppTypography>
              </AppButton>
            )}
            renderChild={(openDialog) => <AddEditNoteForm control={control} />}
          />
        </AppBox>
      </AppBox>
      <Divider />
      <AppBox paddingY={4}>
        <CrmNotesTable
          data={notes ?? []}
          isLoading={isLoadingNotes}
          onDeleteClick={handleDeleteClicked}
          onEditClick={handleEditClicked}
        />
      </AppBox>
    </AppBox>
  )
}
