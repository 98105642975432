import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ParkSiteMapPin } from 'sdk'
import { IAppStore } from 'store/app.store'

export interface GoogleMapsState {
  SelectedPlace: google.maps.places.PlaceResult | null
  SelectedParkIdToMove: string | undefined
  isLoadingMap: boolean
  ParkPins: Array<ParkSiteMapPin>
  LabelledParkPins: Array<ParkSiteMapPin>
  TaggedParkPins: Array<ParkSiteMapPin>
  LinkedCrmContactsParkPins: Array<ParkSiteMapPin>
  CrmContactLinkedParkPins: Array<ParkSiteMapPin>
  CrmContactLinkedParksState: { isAdding: boolean; isShowing: boolean }
}

const initialState: GoogleMapsState = {
  SelectedPlace: null,
  SelectedParkIdToMove: undefined,
  isLoadingMap: true,
  ParkPins: [],
  LabelledParkPins: [],
  TaggedParkPins: [],
  LinkedCrmContactsParkPins: [],
  CrmContactLinkedParkPins: [],
  CrmContactLinkedParksState: { isAdding: false, isShowing: false },
}

export const GoogleMapsSlice = createSlice({
  name: 'GoogleMaps',
  initialState: initialState,
  reducers: {
    setSelectedPlace: (state: GoogleMapsState, action) => {
      state.SelectedPlace = action.payload
    },
    setSelectedParkIdToMove: (state: GoogleMapsState, action: PayloadAction<string | undefined>) => {
      state.SelectedParkIdToMove = action.payload
    },
    setIsLoadingMap: (state: GoogleMapsState, action) => {
      state.isLoadingMap = action.payload
    },
    setParkPins: (state: GoogleMapsState, action) => {
      state.ParkPins = action.payload
    },
    setLabelledParkPins: (state: GoogleMapsState, action) => {
      state.LabelledParkPins = action.payload
    },
    setTaggedParkPins: (state: GoogleMapsState, action) => {
      state.TaggedParkPins = action.payload
    },
    setLinkedCrmContactsParkPins: (state: GoogleMapsState, action) => {
      state.LinkedCrmContactsParkPins = action.payload
    },
    setCrmContactLinkedParkPins: (state: GoogleMapsState, action) => {
      state.CrmContactLinkedParkPins = action.payload
    },
    setCrmContactLinkedParksState: (
      state: GoogleMapsState,
      action: PayloadAction<{ isAdding: boolean; isShowing: boolean }>
    ) => {
      state.CrmContactLinkedParksState = action.payload
    },
    clearGoogleMapsData: (state: GoogleMapsState) => {
      state.SelectedPlace = initialState.SelectedPlace
      state.SelectedParkIdToMove = initialState.SelectedParkIdToMove
      state.isLoadingMap = initialState.isLoadingMap
      state.ParkPins = initialState.ParkPins
      state.LabelledParkPins = initialState.LabelledParkPins
      state.TaggedParkPins = initialState.TaggedParkPins
      state.LinkedCrmContactsParkPins = initialState.LinkedCrmContactsParkPins
      state.CrmContactLinkedParkPins = initialState.CrmContactLinkedParkPins
      state.CrmContactLinkedParksState = initialState.CrmContactLinkedParksState
    },
  },
})

export const {
  setSelectedPlace,
  setSelectedParkIdToMove,
  setIsLoadingMap,
  setParkPins,
  setLabelledParkPins,
  setTaggedParkPins,
  setLinkedCrmContactsParkPins,
  setCrmContactLinkedParkPins,
  setCrmContactLinkedParksState,
  clearGoogleMapsData,
} = GoogleMapsSlice.actions
export const GoogleMapSliceSelector = (appState: IAppStore): GoogleMapsState => appState.GoogleMaps
