import { Grid } from '@mui/material'
import { SimpleField } from 'components'
import { filter } from 'lodash'
import { Dispatch, FC, SetStateAction } from 'react'
import { PaginationFilterModel } from 'sdk'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const ReviewsActions: FC<Props> = ({ filter, setFilter }) => {
  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item md={2} xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search Contacts"
            value={filter.Query ?? ''}
            onChange={(event) => {
              setFilter((prev) => ({ ...prev, PageNo: 1, Query: event.target.value }))
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
