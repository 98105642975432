import { ColumnDef } from '@tanstack/react-table'
import { AppTable } from 'components'

export const CityDataTable = () => {
  const columns: ColumnDef<any>[] = [
    {
      header: 'ID',
      accessorKey: 'ID',
    },
    {
      header: 'State',
      accessorKey: 'State',
    },
    {
      header: 'State Code',
      accessorKey: 'StateCode',
    },
    {
      header: 'City Name',
      accessorKey: 'CityName',
    },
    {
      header: 'Zip Code',
      accessorKey: 'ZipCode',
    },
    {
      header: 'City Type',
      accessorKey: 'CityType',
    },
    {
      header: 'Upload Date',
      accessorKey: 'UploadDate',
    },
    {
      header: 'Updated By',
      accessorKey: 'UpdatedBy',
    },
  ]
  return (
    <>
      <AppTable
        columns={columns}
        isLoading={false}
        data={[
          {
            ID: '1',
            State: 'California',
            StateCode: 'CA',
            CityName: 'Los Angeles',
            ZipCode: '90001',
            CityType: 'CDP',
            UploadDate: '2022-01-01',
            UpdatedBy: 'Gil Fernandez',
          },
        ]}
      />
    </>
  )
}
