export class SdkConfig {
  private static _ApiBaseUrl = process.env.REACT_APP_API_BASE_URL
  private static buildNumber = `2.0.6`

  static get ApiPath() {
    return this._ApiBaseUrl + '/api'
  }
  static get ApiBaseUrl() {
    return this._ApiBaseUrl ?? ''
  }
  static set ApiBaseUrl(path: string) {
    this._ApiBaseUrl = path
  }
  static get BuildNumber() {
    return this.buildNumber
  }
  static set BuildNumber(buildNumber: string) {
    this.buildNumber = buildNumber
  }
}
