import { Typography } from '@mui/material'
import { AppTypography } from 'components'

interface Prop {
  email: string
}
export const EmailText: React.FC<Prop> = ({ email }) => {
  return (
    <AppTypography
      component={'a'}
      isColorPrimary
      onClick={() => {
        window.location.href = 'mailto:' + email
      }}
    >
      {email}
    </AppTypography>
  )
}
