import { AppBox, FilterBuiler } from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoadingMap } from 'store/slices'

interface Props {}

export const ParkSiteFilterBuilder: FC<Props> = () => {
  const dispatch = useDispatch()
  return (
    <AppBox paddingX={3} paddingY={1} height={'100%'} backgroundColor="white" alignItems={'center'}>
      <FilterBuiler
        type={FILTER_PRINCIPAL_TYPES.PARK}
        setIsApplyingFilter={useCallback((val) => dispatch(setIsLoadingMap(val)), [])}
      />
    </AppBox>
  )
}
