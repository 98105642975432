import { Dialog } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography } from 'components'
import { ApiQueryKeys, UserStatuses, appRoutes } from 'globals/constants'
import { useRolesHelper } from 'hooks'
import { useSnackbar } from 'notistack'
import { FC, useMemo, useState } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AuthApi, ChangePasswordPayloadModel, NewUserGettingStartedPayload, SessionModel, UserApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { AuthSliceSelector, setSession } from 'store/slices'
import { GettingStartedAboutUs } from './about-us/GettingStartedAboutUs'
import { GettingStartedChangePassword } from './change-password/GettingStartedChangePassword'
import { GettingStatrtedPersonalInfo } from './personal-info/GettingStartedPersonalInfo'
import { GettingStartedRole } from './role/GettingStartedRole'
import { GettingStartedTerms } from './terms/GettingStartedTerms'

interface Props {}

export const DashBoardGettingStartedDialog: FC<Props> = () => {
  const userApi = new UserApi()
  const authApi = new AuthApi()
  const { CurrentUser, UserId } = useSelector(AuthSliceSelector)
  const { IsSubAccount } = useRolesHelper()
  const [activeStep, setactiveStep] = useState(0)
  const [isTermsAgreed, setisTermsAgreed] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { control, getValues, watch } = useForm<NewUserGettingStartedPayload>()
  const { control: changePasswordControl, getValues: changePasswordGetValues } = useForm<ChangePasswordPayloadModel>({
    defaultValues: { Id: UserId },
  })

  const { mutate: update, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.user.updateUserOnGettingStarted],
    mutationFn: (data: NewUserGettingStartedPayload) => userApi.updateUserOnGettingStarted(data),
    onError: () => {
      enqueueSnackbar('Error updating user', { variant: 'error' })
    },
    onSuccess: (data: boolean) => {
      if (data) {
        enqueueSnackbar('Profile Successfully Created', { variant: 'success' })
        var copy: SessionModel = JSON.parse(JSON.stringify(CurrentUser))
        copy.Status = UserStatuses.COMPLETED
        copy.FirstName = getValues().FirstName
        copy.LastName = getValues().LastName
        dispatch(setSession(copy))
        navigate(appRoutes.parksites.path)
      }
    },
  })

  const { mutate: changePasswordAndUpdateUser, isPending: changingPassword } = useMutation({
    mutationKey: [ApiQueryKeys.account.changePassword],
    mutationFn: (data: ChangePasswordPayloadModel) => authApi.changePassword(data),
    onError: () => {
      enqueueSnackbar('Error updating user', { variant: 'error' })
    },
    onSuccess: (data: boolean) => {
      if (data) {
        update(getValues())
      }
    },
  })
  const isAgreedToTerms = useMemo(() => isTermsAgreed, [isTermsAgreed])
  const steps = () => {
    if (IsSubAccount()) {
      return ['Personal Info', 'Terms', 'Change Password']
    }
    return ['Terms', 'Personal Info', 'Terms', 'Completed']
  }

  const renderComp = () => {
    switch (activeStep) {
      case 0: {
        if (IsSubAccount()) {
          return <GettingStatrtedPersonalInfo control={control} />
        } else {
          return <GettingStartedRole control={control} />
        }
      }
      case 1: {
        if (IsSubAccount()) {
          return (
            <GettingStartedTerms
              name={watch().FirstName}
              isAgreed={isAgreedToTerms}
              setTermsAgreed={setisTermsAgreed}
            />
          )
        } else {
          return <GettingStatrtedPersonalInfo control={control} />
        }
      }
      case 2:
        if (IsSubAccount()) {
          return <GettingStartedChangePassword control={changePasswordControl} />
        } else {
          return (
            <GettingStartedTerms name={watch().FirstName} isAgreed={isTermsAgreed} setTermsAgreed={setisTermsAgreed} />
          )
        }
      case 3:
        return <GettingStartedAboutUs control={control} name={watch().FirstName} />
    }
  }
  const handleNextClick = () => {
    if (activeStep === steps().length - 1) {
      if (IsSubAccount()) {
        changePasswordAndUpdateUser(changePasswordGetValues())
      } else {
        update(getValues())
      }
    } else {
      setactiveStep((prev) => prev + 1)
    }
  }
  return (
    <Dialog open={true} maxWidth={'md'} PaperProps={{ style: { borderRadius: '20px', minWidth: '900px' } }}>
      <Form control={control} onSubmit={() => handleNextClick()}>
        <AppBox paddingX={4} paddingY={2} backgroundColor="white">
          <AppBox display={'flex'} justifyContent={'space-between'} paddingBottom={2}>
            <AppTypography fontSize={16} fontWeight={700}>
              Getting Started ({steps()[activeStep]})
            </AppTypography>
            <AppBox display={'flex'} gap={1} alignItems={'center'}>
              {steps().map((step, index) => (
                <AppBox
                  key={index}
                  backgroundColor={activeStep >= index ? 'primary.200' : 'grey.500'}
                  width={12}
                  height={12}
                  borderRadius={50}
                />
              ))}
            </AppBox>
          </AppBox>
          <AppBox paddingY={2} borderBottom={1} borderTop={1} borderColor={'grey.200'}>
            {renderComp()}
          </AppBox>
          <AppBox display={'flex'} justifyContent={'flex-end'} paddingTop={2} gap={2}>
            {activeStep > 0 && (
              <AppButton
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => setactiveStep((prev) => prev - 1)}
              >
                Back
              </AppButton>
            )}
            <AppButton
              variant="contained"
              size="large"
              disabled={activeStep === (IsSubAccount() ? 1 : 2) && !isTermsAgreed}
              type="submit"
              isLoading={isPending || changingPassword}
            >
              {activeStep === steps().length - 1 ? 'Close' : 'Next'}
            </AppButton>
          </AppBox>
        </AppBox>
      </Form>
    </Dialog>
  )
}
