import { Grid } from '@mui/material'
import { AppBox, AppTypography, InfoBox, PhoneText, SimpleField, SimpleValueSelect } from 'components'
import { PeopleInsightRolesOptions } from 'globals/constants'
import React, { FC, useMemo } from 'react'
import { PeopleInsightRelatedPark, PeopleInsightViewModel } from 'sdk'
import { PeopleInsightUserViewRelatedParksTable } from './PeopleInsightUserViewRelatedParksTable'

interface Props {
  insight: PeopleInsightViewModel
}

export const PeopleInsightRelatedParksInsight: FC<Props> = ({ insight }) => {
  const [query, setQuery] = React.useState('')
  const [role, setRole] = React.useState('All')
  const filteredParks = useMemo(() => {
    let temp = [...insight.RelatedParks] as PeopleInsightRelatedPark[]
    if (query) {
      temp = temp.filter(
        (park) =>
          park.ParkName.toLowerCase().includes(query.toLowerCase()) ||
          park.ParkRole.toLowerCase().includes(query.toLowerCase()) ||
          park.Address.toLowerCase().includes(query.toLowerCase()) ||
          park.ParkPhone.toLowerCase().includes(query.toLowerCase())
      )
    }
    if (role !== 'All') {
      temp = temp.filter((park) => park.ParkRole === role)
    }
    return temp
  }, [query, role, insight])
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={1}>
      <InfoBox dismissable>
        These are all of the parks that our research has currently identified as being associated with this person.
      </InfoBox>
      <AppBox
        paddingX={2}
        paddingY={1}
        border={1}
        borderColor={'grey.200'}
        borderRadius={3}
        backgroundColor="grey.50"
        display={'flex'}
        flexDirection={'column'}
      >
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          <AppTypography color={'grey.700'} flex={1}>
            Name:
          </AppTypography>
          <AppTypography fontWeight={'bold'} flex={11}>
            {insight.FirstName + ' ' + insight.LastName}
          </AppTypography>
        </AppBox>
        {insight.CompanyName && (
          <>
            <AppBox display={'flex'} alignItems={'center'} gap={1}>
              <AppTypography color={'grey.700'} flex={1}>
                Company:
              </AppTypography>
              <AppTypography fontWeight={'bold'} flex={11}>
                {insight.CompanyName}
              </AppTypography>
            </AppBox>
          </>
        )}
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          <AppTypography color={'grey.700'} flex={1}>
            Address:
          </AppTypography>
          <AppTypography fontWeight={'bold'} flex={11}>
            {insight.Address}, {insight.City}, {insight.StateName} {insight.Zip}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          <AppTypography color={'grey.700'} flex={1}>
            Phone:
          </AppTypography>
          <AppBox flex={11} display={'flex'} alignItems={'center'} gap={1}>
            <PhoneText phone={insight.Phone} component={'span'} />
            {insight.AltPhone && (
              <>
                <AppBox backgroundColor="grey.200" height={5} width={5} borderRadius={50} alignSelf={'center'} />
                <AppTypography color={'grey.700'}>Alt Phone:</AppTypography>
                <PhoneText phone={insight.AltPhone} component={'span'} />
              </>
            )}
          </AppBox>
        </AppBox>
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          <AppTypography color={'grey.700'} flex={1}>
            Email:
          </AppTypography>
          <AppTypography fontWeight={'bold'} flex={11}>
            {insight.Email}
          </AppTypography>
        </AppBox>
      </AppBox>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SimpleValueSelect
            options={[{ label: 'All', value: 'All' }, ...PeopleInsightRolesOptions]}
            defaultValue={'All'}
            onValueChange={(value) => setRole(value.toString())}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search"
            onChange={(event) => setQuery(event.target.value)}
          />
        </Grid>
      </Grid>
      <AppBox height={'40vh'} style={{ overflowY: 'auto' }}>
        <PeopleInsightUserViewRelatedParksTable data={filteredParks ?? []} />
      </AppBox>
    </AppBox>
  )
}
