import { AppBox, AppCard, AppIcon, AppTabs, AppToolTip, AppTypography, TabType } from 'components'
import { ParkSitesTools } from 'globals/constants'
import { useShowableParkPins } from 'hooks'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, closeTool, setToolOpenedTab } from 'store/slices'
import { SideToolCrm } from './crm/SideToolCrm'
import { PinnedParksListing } from './pinned-parks/PinnedParksListing'
import { PinnedParksManage } from './pinned-parks/manage/PinnedParksManage'
import { TaggedParksTable } from './tagged-parks/TaggedParksTable'
import { TaggedParksManage } from './tagged-parks/manage/TaggedParksManage'

const toolBarMapping: {
  [key: string]: { name: string; toolTipText: ReactNode; tabs?: Array<TabType>; components: Array<any> }
} = {
  [ParkSitesTools.PinnedParks]: {
    name: 'Pinned Parks',
    toolTipText: (
      <AppTypography>
        <b>Pinned Parks</b> allows you to manage your custom pin colors and view the list of parks they’ve been applied
        to.
      </AppTypography>
    ),
    tabs: [
      { icon: 'ph:map-pin-fill', label: 'Pinned' },
      { icon: 'fa6-solid:gear', label: 'Manage' },
    ],
    components: [<PinnedParksListing />, <PinnedParksManage />],
  },
  [ParkSitesTools.TaggedParks]: {
    name: 'Tagged Parks',
    toolTipText: (
      <AppTypography>
        <b>Tagged Parks</b> allows you to manage your tags and view the list of parks they’ve been applied to.{' '}
      </AppTypography>
    ),
    tabs: [
      { icon: 'bi:tags-fill', label: 'Tagged Parks' },
      { icon: 'fa6-solid:gear', label: 'Manage' },
    ],
    components: [<TaggedParksTable />, <TaggedParksManage />],
  },
  [ParkSitesTools.CRM]: {
    name: 'CRM Parks',
    toolTipText: (
      <AppTypography>
        <b>CRM Parks</b> displays a list of parks that you’ve added personal contacts to from within its{' '}
        <b>Activity Center</b>.
      </AppTypography>
    ),
    components: [<SideToolCrm />],
  },
}
export const ParkSitesSideTool = () => {
  const { currentMarkers } = useShowableParkPins()
  const { openedTool, toolOpenedTab } = useSelector(ParkSiteToolSliceSelector)
  const disptach = useAppDispatch()
  const [tab, setTab] = useState<number>(0)

  useEffect(() => {
    setTab(toolOpenedTab === 'list' ? 0 : 1)
  }, [openedTool])

  return (
    <>
      <AppBox borderBottom={1} borderTop={1} borderColor="grey.200">
        <AppCard className="rounded-none">
          <AppBox display={'flex'} justifyContent="space-between" paddingY={1} alignItems={'center'}>
            <AppTabs
              handleChange={(event: React.SyntheticEvent, val: number) => {
                setTab(val)
                disptach(setToolOpenedTab(val === 0 ? 'list' : 'manage'))
              }}
              value={tab}
              tabs={toolBarMapping[openedTool as string].tabs ?? []}
            />
            <AppBox display={'flex'} alignItems="center" pr={2}>
              <AppToolTip>
                {<AppTypography>{toolBarMapping[openedTool as string].toolTipText}</AppTypography>}
              </AppToolTip>
              <AppTypography fontWeight="bold" fontSize={14}>
                {toolBarMapping[openedTool as string].name}
              </AppTypography>

              <AppTypography paddingLeft={0.5} paddingRight={0.6} color="grey.500" fontSize={14}>
                ({currentMarkers()?.length ?? 0})
              </AppTypography>
              <AppIcon
                size="medium"
                icon="heroicons-outline:arrow-narrow-right"
                color="grey.500"
                onClick={() => disptach(closeTool())}
              />
            </AppBox>
          </AppBox>
        </AppCard>
      </AppBox>
      <AppBox paddingTop={1}>{openedTool && toolBarMapping[openedTool].components[tab]}</AppBox>
    </>
  )
}
