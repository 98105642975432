import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppIcon, AppTypography, CityDemoGraphicsLoader, InfoBox } from 'components'
import { ApiQueryKeys, FeedbackTypes, InfoBoxSettingTypes } from 'globals/constants'
import { landArea } from 'globals/functions'
import { useFeedBack } from 'hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CityDemographicsApi, FeedbackPayload } from 'sdk'
import { ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'

const cityDemoApi = new CityDemographicsApi()

export const CityDemographics = () => {
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const { data, isPending, mutate } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchParkCityDemographics],
    mutationFn: () => cityDemoApi.GetParkCityDemographics(selectedParkId),
  })
  useEffect(() => mutate(), [selectedParkId, SelectedTeam?.Id])

  const { SaveFeedBack, IsSaving } = useFeedBack()
  const [savedFeedBack, setSavedFeedBack] = useState<string | null>(null)
  const mapping = [
    { text: 'Name', key: 'Name' },
    { text: 'Type', key: 'Type' },
    { text: 'Population', key: 'Population' },
    { text: 'Households', key: 'HouseHold' },
    { text: 'Median Income', key: 'MedianIncome' },
    { text: 'Time Zone', key: 'TimeZone' },
    { text: 'Land Area', key: 'LandArea' },
    { text: 'Water Area', key: 'WaterArea' },
  ]
  const handleFeedBack = (feedback: 'good' | 'bad') => {
    if (!savedFeedBack) {
      setSavedFeedBack(feedback)
      const data: FeedbackPayload = {
        Type: FeedbackTypes.ParkCityDemographics,
        Value: feedback,
        PrincipalType: 'ParkSite',
        PrincipalId: selectedParkId,
      }
      SaveFeedBack(data)
    }
  }
  const value = (itemKey: string) => {
    const _val = (data as any)[itemKey]
    if (['LandArea', 'WaterArea'].includes(itemKey)) {
      return landArea(_val)
    } else if ('MedianIncome' === itemKey) {
      return '$' + (!isNaN(Number(_val)) ? Number(_val).toLocaleString() : _val)
    }
    return !isNaN(Number(_val)) ? Number(_val).toLocaleString() : _val
  }

  return (
    <>
      {isPending ? (
        <CityDemoGraphicsLoader />
      ) : !data ? (
        <AppBox display={'flex'} flexDirection={'column'} gap={2}>
          <AppBox backgroundColor="red" color={'white'} padding={2}>
            <AppTypography fontSize={14} fontWeight={600}>
              Oops...looks like we have a slight problem with this park. We’ve automatically generated a report and sent
              it to our staff. They’ll have this fixed up for you shortly.
            </AppTypography>
          </AppBox>
          <AppBox textAlign={'center'}>
            <img src="/images/pax/error.png" alt="error" />
          </AppBox>
        </AppBox>
      ) : (
        <AppBox display={'flex'} flexDirection={'column'} height={'100%'} paddingX={4} justifyContent={'space-between'}>
          <AppBox>
            <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.CityDemographicsDismissed}>
              Our city demographics are generated by a 3rd party that tracks U.S. Census statistics.
            </InfoBox>
            <AppBox borderBottom={1} borderColor={'grey.100'} paddingY={2}>
              <AppTypography color={'grey.900'} fontSize={14} fontWeight={600}>
                {data.Name}, {data.State} {data.ZipCode}
              </AppTypography>
            </AppBox>
            <AppBox paddingY={1}>
              <Grid container spacing={2}>
                {mapping.map((item) => (
                  <>
                    <Grid item xs={6}>
                      <AppTypography color={'grey.500'}>{item.text}</AppTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <AppTypography color={'grey.900'} textAlign={'end'} fontWeight={'bold'}>
                        {value(item.key)}
                      </AppTypography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </AppBox>
          </AppBox>
          <AppBox
            borderRadius={2}
            border={1}
            padding={1}
            borderColor={'grey.200'}
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <AppTypography color={'grey.500'} fontSize={14}>
              How would you rate this information?
            </AppTypography>
            <AppBox gap={2} display={'flex'} paddingRight={1}>
              <AppIcon
                color={savedFeedBack === 'good' ? 'blue' : 'grey'}
                icon={'octicon:thumbsup-16'}
                size="large"
                onClick={() => handleFeedBack('good')}
              />
              <AppIcon
                color={savedFeedBack === 'bad' ? 'blue' : 'grey'}
                icon={'octicon:thumbsdown-16'}
                size="large"
                onClick={() => handleFeedBack('bad')}
              />
            </AppBox>
          </AppBox>
        </AppBox>
      )}
    </>
  )
}
