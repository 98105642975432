import { ParkSystemStatuses } from 'globals/constants'
import { MonthNames } from 'globals/constants/common'
import { AnyObject } from 'globals/typings'
import queryString from 'query-string'

export const getFullDate = (date: Date | string | null) => {
  return `${MonthNames[new Date(date ?? '').getMonth() ?? 0]} ${new Date(date ?? '').getDate()}, ${new Date(date ?? '').getFullYear()}`
}
export const objToQuery = (obj: AnyObject) => {
  return queryString.stringify(obj)
}

export const getFormattedDate = (date: Date) => {
  return new Date(date.valueOf() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10)
}
export const getMDYFormattedDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getFullYear()

  return `${month}/${day}/${year}`
}
export const timestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')
  return `${month}/${day}/${year}`
}
export const centsToDollar = (cents: number) => {
  return `$${(cents > 0 ? cents / 100 : cents).toFixed(2)}`
}

export const deslugify = (slug: string) => {
  return slug
    .split('-')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const landArea = (area: number) => {
  return (Number(area) * 0.000247105).toFixed(2)
}
export const getParkStatusColor = (status: string) => {
  if (status === ParkSystemStatuses.Active) {
    return '#23d164'
  } else if (status === ParkSystemStatuses.Closed) {
    return 'red'
  } else if (status === ParkSystemStatuses.NoParkData) {
    return 'black'
  }
  return 'black'
}

export function imageToBase64(image: File) {
  return new Promise((resolve: (base64: string) => void, reject) => {
    if (image) {
      const reader = new FileReader()
      reader.onload = (e) => {
        resolve((e.target as any).result)
      }
      reader.readAsDataURL(image)
    } else {
      resolve('')
    }
  })
}
export const generateRandomId = () => {
  return Math.floor(10000000 + Math.random() * 90000000).toString()
}
export const imageToDataURL = (imageUrl: string, callback: (dataUrl: string) => void) => {
  const img = new Image()
  img.crossOrigin = 'Anonymous' // Handle CORS if the image is from a different origin
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    ctx?.drawImage(img, 0, 0)
    const dataURL = canvas.toDataURL('image/png') // You can specify the desired format (e.g., 'image/jpeg')
    return callback(dataURL)
  }
  img.src = imageUrl
}

export const dataURLToFile = (dataURL: string) => {
  const [header, data] = dataURL.split(',')
  const match = header.match(/:(.*?);/)
  const mimeType = match ? match[1] : ''
  const byteString = atob(data)
  const arrayBuffer = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([arrayBuffer], { type: mimeType })
  return new File([blob], 'new-file.png', { type: mimeType })
}
