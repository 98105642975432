import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { FC, useEffect, useRef, useState } from 'react'
import { SimpleField } from '../../../base'

interface Props {
  onPlaceChange: (place: google.maps.places.PlaceResult) => void
}
export const AutoCompleteInput: FC<Props> = ({ onPlaceChange }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const places = useMapsLibrary('places')

  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if (!places || !inputRef.current) {
      return
    }
    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: false,
    }

    const autocomplete = new places.Autocomplete(inputRef.current, options)
    setPlaceAutocomplete(autocomplete)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        onPlaceChange(place)
      }
    })
  }, [places, onPlaceChange])

  // useEffect(() => {
  //   if (!placeAutocomplete) {
  //     return
  //   }

  //   placeAutocomplete.addListener('place_changed', () => {
  //     onPlaceChange(placeAutocomplete.getPlace())
  //   })
  // }, [placeAutocomplete])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)

    // Check if the input looks like coordinates
    const coordinateRegex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/
    const geocoder = new window.google.maps.Geocoder()

    if (geocoder && coordinateRegex.test(value)) {
      const [lat, lng] = value.split(',').map(Number)
      const latLng = { lat, lng }

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results) {
          onPlaceChange(results[0]) // Return the first matching result
        } else {
          console.error('Geocoder failed due to:', status)
        }
      })
    }
  }

  return (
    <SimpleField
      prefixIcon="iconamoon:search-bold"
      placeholder="Search Map Location"
      inputProps={{
        ref: inputRef,
        style: { width: 330, borderRadius: 12 },
        onChange: handleInputChange,
      }}
      suffixIcon={!!inputValue ? 'mingcute:close-fill' : ''}
      suffixIconProps={{
        size: 'medium',
        onClick: () => {
          setInputValue('')
          inputRef.current!.value = ''
        },
      }}
    />
  )
}
