import { Grid } from '@mui/material'
import { AppTextField } from 'components/base'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { ChangePasswordPayloadModel } from 'sdk'
import { PasswordField } from '../form/password/PasswordField'

interface Props {
  control: Control<ChangePasswordPayloadModel>
}

export const ChangePasswordForm: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PasswordField
          label={'Old Password'}
          name={'OldPassword'}
          control={control}
          placeholder={'Enter Old Password'}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordField
          label={'New Password'}
          name={'NewPassword'}
          control={control}
          placeholder={'Enter New Password'}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordField
          label={'Confirm Password'}
          name={'ConfirmPassword'}
          control={control}
          placeholder={'Enter Confirm Password'}
          required
        />
      </Grid>
    </Grid>
  )
}
