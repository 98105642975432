import { AppTextField, AppTextFieldProps } from '../../../base'
interface EmailFieldProps extends AppTextFieldProps {
  isConfirmEmail?: boolean
  emailValue?: string
}
export const EmailField = ({ customRules, isConfirmEmail, emailValue, ...props }: EmailFieldProps) => {
  return (
    <AppTextField
      {...props}
      customRules={{
        Email: true,
        ...customRules,
        ...(isConfirmEmail && {
          ConfirmEmail: { optionValue: emailValue },
        }),
      }}
      prefixIcon="ant-design:mail-outlined"
      prefixIconProps={{ size: 'medium' }}
    />
  )
}
