import { Divider, Typography } from '@mui/material'
import { AppBox, AppTypography, GoogleMap, MapWrapper } from 'components'

export const CrmCompanyDetailLocation = () => {
  return (
    <AppBox
      border={1}
      padding={2}
      paddingY={4}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Company"
    >
      <MapWrapper>
        <AppBox width={'100%'}>
          <AppTypography fontWeight={700} fontSize={'1rem'}>
            Location
          </AppTypography>
          <Divider sx={{ marginY: 2.5 }} />
          {/* {isGettingCompany && <LinearProgress color="primary" />} */}
          <AppBox width={1} height={'450px'} backgroundColor="black">
            <GoogleMap onMarkerClick={(parkId, marker) => {}} />
          </AppBox>
        </AppBox>
      </MapWrapper>
    </AppBox>
  )
}
