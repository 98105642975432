import { Tab, Tabs, TabsProps, Typography } from '@mui/material'

import React from 'react'
import { AppIcon, AppTypography } from 'components'

export interface TabType {
  icon: string
  label: string
}
interface AppTabsProps extends TabsProps {
  tabs: Array<TabType>
  value: number
  handleChange: (event: React.SyntheticEvent, val: number) => void
}
export const AppTabs = ({ tabs, handleChange, ...props }: AppTabsProps) => {
  return (
    <Tabs
      {...props}
      value={props.value}
      onChange={handleChange}
      sx={{
        minHeight: 10,
      }}
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
    >
      {tabs &&
        tabs.length > 0 &&
        tabs.map((t: TabType, index: number) => (
          <Tab
            key={index}
            icon={<AppIcon size="medium" icon={t.icon} />}
            iconPosition="start"
            label={
              <AppTypography fontSize={14} fontWeight={500} textTransform={'none'}>
                {t.label}
              </AppTypography>
            }
            sx={{
              minHeight: 10,
              height: 10,
              paddingX: 1,
              margin: 0,
              '&.Mui-selected': {
                color: 'primary',
              },
            }}
          />
        ))}
    </Tabs>
  )
}
