import { useMutation } from '@tanstack/react-query'
import { AppBox, AppDialogRefType, AppTypography, TextWithToolTip } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { AccountApi } from 'sdk'
import { ManageSubAccountDialog } from '../manage-sub-account-dialog/ManageSubAccountDialog'
import { SubAccountsTable } from '../sub-accounts-table/SubAccountsTable'

export const ManageUserCard = () => {
  const accountApi = new AccountApi()
  const {
    data: subAccounts,
    isPending,
    mutate,
  } = useMutation({
    mutationKey: [ApiQueryKeys.account.getUserSubAccounts],
    mutationFn: () => accountApi.getUserSubAccounts(),
  })
  useEffect(() => mutate(), [])
  const dialogRef = useRef<AppDialogRefType>({})
  const { control } = useForm<any>()
  const openManageDialog = () => {
    dialogRef.current.openDialog!()
  }
  return (
    <>
      <AppBox border={2} borderColor={'grey.200'} borderRadius={6} padding={2} backgroundColor="white">
        <AppBox display={'flex'} flexDirection={'column'} gap={2}>
          <AppBox paddingBottom={1} borderBottom={1} borderColor={'grey.100'}>
            <TextWithToolTip tooltipText="Disable or remove each of the users that you have added to your plan’s subscription.">
              <AppTypography color={'grey.900'} fontSize={16} fontWeight={700}>
                Manage Users
              </AppTypography>
            </TextWithToolTip>
          </AppBox>
          <AppBox paddingTop={2}>
            <SubAccountsTable
              data={subAccounts ?? []}
              isLoading={isPending}
              onManageClick={openManageDialog}
              onResendInvitationClick={() => {}}
            />
          </AppBox>
        </AppBox>
      </AppBox>
      <ManageSubAccountDialog control={control} ref={dialogRef} />
    </>
  )
}
