import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useCallback } from 'react'
import { ParkListTab, UserTaggedParkApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { setTaggedParkPins } from 'store/slices'
import { setTaggedParks, setisLoadingTaggedParks } from 'store/slices/park-tags.slice'

const taggedParkApi = new UserTaggedParkApi()

export const useGetTaggedParks = () => {
  const dispatch = useAppDispatch()
  const { mutate } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.taggedParks.FetchTaggedParks],
    mutationFn: (query: string) => taggedParkApi.GetUserTaggedParks(query),
    onSuccess: (data) => onSuccess(data),
  })

  const onSuccess = (data: ParkListTab[]) => {
    dispatch(setTaggedParks(data ?? []))
    dispatch(setTaggedParkPins(data?.map((x) => x.Position) ?? []))
    dispatch(setisLoadingTaggedParks(false))
  }

  const fetchParkPins = useCallback(
    (query?: string) => {
      dispatch(setisLoadingTaggedParks(true))
      return mutate(query ?? '')
    },
    [mutate, dispatch]
  )

  return {
    getTaggedParks: fetchParkPins,
  }
}
