import { apiUrls } from '../../api-urls'
import { TagPayloadModel, TagViewModel } from '../../models'

import { BaseApi } from '../base.api'

const baseApi = new BaseApi()
export class TagApi {
  public AddTag(tag: TagPayloadModel) {
    return baseApi.POST_Request<string>(apiUrls.parksites.tags.addTag(), tag)
  }
  public EditTag(tag: TagPayloadModel) {
    return baseApi.PUT_Request<TagViewModel>(apiUrls.parksites.tags.editTag(), tag)
  }
  public GetTags(tagType: string) {
    return baseApi.GET_Request<Array<TagViewModel>>(apiUrls.parksites.tags.getTags(tagType))
  }
  public RemoveTag(tagId: string) {
    return baseApi.DELETE_Request<boolean>(apiUrls.parksites.tags.removeTag(tagId))
  }
}
