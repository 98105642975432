import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys, TagTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ParkListTab, ShowAlert, TagApi, TagPayloadModel, TagViewModel, UserTaggedParkApi } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
import Swal from 'sweetalert2'

export const useTaggedParks = (type: TagTypes) => {
  const tagApi = new TagApi()
  const taggedParksApi = new UserTaggedParkApi()
  const [idToRemove, setIdToRemove] = useState<string>('')

  const { enqueueSnackbar } = useSnackbar()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { control, handleSubmit, reset, setValue, watch } = useForm<TagPayloadModel>({
    mode: 'onSubmit',
    defaultValues: { TagType: type },
  })

  //    Getting All Tags

  const {
    data,
    isPending: isGettingTags,
    mutate: GetAllTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.tags.FetchTag],
    mutationFn: () => tagApi.GetTags(type),
  })
  useEffect(() => GetAllTags(), [SelectedTeam?.Id])
  //
  // Getting by tagId
  const { isPending: isCheckingTaggedParks, mutate: GetTaggedParksByTagId } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.taggedParks.FetchTaggedParksByTagId],
    mutationFn: (tagId: string) => taggedParksApi.GetTaggedParksByLabelId(tagId),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: (data) => handleGetTaggedParksByLinkIdSuccess(data),
  })
  const handleGetTaggedParksByLinkIdSuccess = (data: ParkListTab[]) => {
    ShowAlert(
      'question',
      data.length === 0
        ? 'Are you sure you want to delete this Park Tag?'
        : `This tag is linked to ${data.length} parks. Are you sure you want to delete this Tag?`
    ).then((result) => {
      if (result.isConfirmed) {
        DeleteTag(idToRemove!)
      }
    })
  }

  // Adding New Tag

  const { mutateAsync: addTag, isPending: isAddingTag } = useMutation({
    mutationFn: (data: TagPayloadModel) => tagApi.AddTag(data),
    mutationKey: [ApiQueryKeys.tags.AddTag],
    onSuccess: () => {
      GetAllTags()
      enqueueSnackbar('Tag has been added successfully', { variant: 'success' })
      dialogRef.current?.closeDialog!()
      reset()
    },
  })

  //

  // Editing Park Tags

  const { mutate: EditTag, isPending: isEditingTag } = useMutation({
    mutationFn: tagApi.EditTag,
    mutationKey: [ApiQueryKeys.tags.EditTag],
    onSuccess: () => {
      GetAllTags()
      enqueueSnackbar('Tag has been edited successfully', { variant: 'success' })
      dialogRef.current?.closeDialog!()
      reset()
    },
  })

  const handleEditTag = (data: TagViewModel) => {
    setValue('Id', data.Id)
    setValue('Tag', data.Tag)
    dialogRef.current?.openDialog!()
  }

  //

  // Deleting Park Tags
  const { isPending: isDeleting, mutate: DeleteTag } = useMutation({
    mutationKey: [ApiQueryKeys.tags.RemoveTag],
    mutationFn: (id: string) => tagApi.RemoveTag(id),
    onError: () => enqueueSnackbar('Error Deleting Park Label', { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Park Label has Been Deleted ', { variant: 'success' })
      GetAllTags()
    },
  })
  const handleDeleteParkTag = (id: string) => {
    setIdToRemove(id)
    GetTaggedParksByTagId(id)
  }
  //

  const dialogRef = useRef<AppDialogRefType>({})
  const handleFormSubmit = (data: TagPayloadModel) => {
    if (data.Id) {
      EditTag(data)
    } else {
      addTag(data)
    }
  }
  return {
    control,
    dialogRef,
    handleSubmit,
    reset,
    watch,
    data,
    GetAllTags,
    isGettingTags,
    addTag,
    isAddingTag,
    isEditingTag,
    isDeleting,
    handleDeleteParkTag,
    handleEditTag,
    handleFormSubmit,
  }
}
