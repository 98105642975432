import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaginationFilterModel, ParkListTab, TagViewModel } from 'sdk'
import { IAppStore } from 'store/app.store'

export interface ParkTagState {
  ParkTags: Array<TagViewModel>
  Filter: PaginationFilterModel
  TaggedParks: Array<ParkListTab>
  isTaggedParksLoading: boolean
}

const initialState: ParkTagState = {
  ParkTags: [],
  Filter: new PaginationFilterModel(),
  TaggedParks: [],
  isTaggedParksLoading: false,
}

export const ParkTagSlice = createSlice({
  name: 'ParkTag',
  initialState: initialState,
  reducers: {
    setParkTags: (state, action: PayloadAction<Array<TagViewModel>>) => {
      state.ParkTags = action.payload
    },
    setTaggedParks: (state, action: PayloadAction<Array<ParkListTab>>) => {
      state.TaggedParks = action.payload
    },
    setisLoadingTaggedParks: (state, action: PayloadAction<boolean>) => {
      state.isTaggedParksLoading = action.payload
    },
  },
})

export const { setParkTags, setTaggedParks, setisLoadingTaggedParks } = ParkTagSlice.actions

export const ParkTagsliceSelector = (appState: IAppStore): ParkTagState => appState.ParkTag
