import { Grid } from '@mui/material'
import { AppBox, AppIcon, AppMenu, AppTypography } from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { FilterField } from 'globals/Filter/FilterTypes'
import { FC } from 'react'
import { FilterConditionSaveModel } from 'sdk'
import { AddFilterMenu } from '../add-filter-menu/AddFilterMenu'

interface Props {
  type: FILTER_PRINCIPAL_TYPES
  field: FilterField
  addedFilters: FilterConditionSaveModel[]
  addNewFilter: (filter: FilterConditionSaveModel, origin?: typeof FilterConditionSaveModel.prototype.Origin) => void
}

export const UnSelectedDefaultFilterButton: FC<Props> = ({ type, addedFilters, field, addNewFilter }) => {
  return (
    <Grid item>
      <AppMenu
        renderElement={(openMenu) => (
          <AppBox
            padding={1}
            paddingX={2}
            borderRadius={1}
            color={'grey.800'}
            backgroundColor="grey.200"
            alignItems={'center'}
            display={'flex'}
            gap={1}
            onClick={openMenu}
          >
            <AppTypography fontSize={14} fontWeight={600}>
              {field.Label}
            </AppTypography>
            <AppIcon icon={'mdi:chevron-down'} size="medium" color={'grey.800'} />
          </AppBox>
        )}
        MenuComponent={(closeMenu) => (
          <AddFilterMenu
            categories={[]}
            type={type}
            addedFilters={addedFilters}
            closeMenu={closeMenu}
            onSaveNewFilter={(filter) => addNewFilter(filter, 'default')}
            fieldToAdd={field}
          />
        )}
      ></AppMenu>
    </Grid>
  )
}
