import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, AppTypography, ImageCarousal, ParkDetailsLoader } from 'components'
import { ApiQueryKeys, FeedbackTypes } from 'globals/constants'
import { useSelector } from 'react-redux'

import { useFeedBack } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { FeedbackPayload, ParkDetailViewModel, ParkFieldsDataViewModel, ParkSitesApi, SdkConfig } from 'sdk'
import { ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'
import { ParkDetailHeader } from './cards/ParkDetailHeader'
import { ReportParkForm, ReportParkFormRefType } from './dialog/ReportParkForm'
import { UpdateParkForm, UpdateParkFormRefType } from './dialog/UpdateParkForm'

const parkSitesApiService = new ParkSitesApi()
export const ParkDetails = () => {
  const handlePrint = useReactToPrint({
    documentTitle: 'Print This Document',
    // onBeforePrint: () => console.log('before printing...'),
    // onAfterPrint: () => console.log('after printing...'),
    // removeAfterPrint: true,
  })
  const [isImagesDialogOpen, setIsImagesDialogOpen] = useState(false)
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { isOpenedActivityAdminViewOpen } = useSelector(ParkSiteToolSliceSelector)
  const [originalParkData, setOriginalParkData] = useState<ParkDetailViewModel>()
  const parkUpdateDialogRef = useRef<UpdateParkFormRefType>()
  const parkReportDialogRef = useRef<ReportParkFormRefType>()

  //
  const {
    data,
    isPending,
    mutate: refetch,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchParkDetail],
    mutationFn: () => parkSitesApiService.GetParkDetail(selectedParkId),
    onSuccess: (data) => {
      setOriginalParkData(JSON.parse(JSON.stringify(data)))
      if (data && data.ParkFieldsData?.length) {
        data.ParkFieldsData = data?.ParkFieldsData.map((x) => {
          if (x.FieldType === 'Dropdown') {
            x.Value = x.Items?.find((key) => {
              return x.Value === key.Key
            })?.Value as any
          }
          return x
        })

        data.ParkFieldsDataObj = new ParkFieldsDataViewModel(
          data.ParkFieldsData.map((x) => {
            return { [`${x.Key}`]: x.Value }
          }).reduce((r, c) => Object.assign(r, c))
        )
      }
      return data
    },
  })
  useEffect(() => {
    refetch()
  }, [selectedParkId, SelectedTeam?.Id])
  //

  const { SaveFeedBack, IsSaving } = useFeedBack()
  const [savedFeedBack, setSavedFeedBack] = useState<string | null>(null)

  const handleFeedBack = (feedback: 'good' | 'bad') => {
    if (!savedFeedBack) {
      setSavedFeedBack(feedback)
      const data: FeedbackPayload = {
        Type: FeedbackTypes.ParkCityDemographics,
        Value: feedback,
        PrincipalType: 'ParkSite',
        PrincipalId: selectedParkId,
      }
      SaveFeedBack(data)
    }
  }
  useEffect(() => {
    if (isOpenedActivityAdminViewOpen) {
      parkUpdateDialogRef.current?.openDialog!()
    }
  }, [selectedParkId, isOpenedActivityAdminViewOpen])

  return (
    <>
      {isPending ? (
        <ParkDetailsLoader />
      ) : (
        data && (
          <AppBox
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            height={'100%'}
            justifyContent={'space-between'}
            paddingX={4}
          >
            <AppBox>
              <AppBox backgroundColor="primary.100" padding={2} borderRadius={3}>
                <AppBox display={'flex'} gap={3} justifyContent={'space-between'} alignItems={'center'}>
                  <AppTypography fontSize={14} color={'grey.500'}>
                    Our goal is to provide the most recent details available for each park. When we get it wrong, send
                    us a report with the correct information. Once we confirm the information you provide, we’ll update
                    the park’s record.
                  </AppTypography>
                  <AppButton
                    variant="contained"
                    size="large"
                    sidePaddings={2}
                    onClick={() => parkReportDialogRef.current?.openDialog!()}
                  >
                    Report
                  </AppButton>
                </AppBox>
              </AppBox>
              <ParkDetailHeader data={data} openImagesDialog={() => setIsImagesDialogOpen(true)} />
            </AppBox>
            <AppBox
              borderRadius={2}
              border={1}
              padding={1}
              borderColor={'grey.200'}
              width={'100%'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <AppTypography color={'grey.500'} fontSize={14}>
                How would you rate this information?
              </AppTypography>
              <AppBox gap={2} display={'flex'} paddingRight={1}>
                <AppIcon
                  color={savedFeedBack === 'good' ? 'blue' : 'grey'}
                  icon={'octicon:thumbsup-16'}
                  size="large"
                  onClick={() => handleFeedBack('good')}
                />
                <AppIcon
                  color={savedFeedBack === 'bad' ? 'blue' : 'grey'}
                  icon={'octicon:thumbsdown-16'}
                  size="large"
                  onClick={() => handleFeedBack('bad')}
                />
              </AppBox>
            </AppBox>
          </AppBox>
        )
      )}
      <UpdateParkForm
        parkDetail={originalParkData}
        isLoading={isPending}
        ref={parkUpdateDialogRef}
        refetchDetails={refetch}
      />
      <ReportParkForm parkDetail={originalParkData} isLoading={isPending} ref={parkReportDialogRef} />
      <ImageCarousal
        open={isImagesDialogOpen}
        onClose={() => {
          setIsImagesDialogOpen(false)
        }}
        images={data?.ImageUrls?.map((x) => `${SdkConfig.ApiBaseUrl + '/' + x}`) ?? []}
      />
    </>
  )
}
