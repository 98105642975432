import { createSlice } from '@reduxjs/toolkit'
import { ProfileModules } from 'globals/constants'
import { IAppStore } from 'store/app.store'

export interface ProfileModulesTypes {
  openedProfileModule: ProfileModules | null
}

const initialState: ProfileModulesTypes = {
  openedProfileModule: null,
}

export const ProfileModulesSlice = createSlice({
  name: 'ProfileModules',
  initialState: initialState,
  reducers: {
    setOpenedProfileModule: (state: ProfileModulesTypes, action) => {
      state.openedProfileModule = action.payload
    },
  },
})

export const { setOpenedProfileModule } = ProfileModulesSlice.actions
export const ProfileModulesliceSelector = (appState: IAppStore): ProfileModulesTypes => appState.ProfileModules
