import { Grid } from '@mui/material'
import { AppBox, AppTypography } from 'components'
import { FC } from 'react'

interface Props {}

export const OldCancelledMessage: FC<Props> = () => {
  return (
    <AppBox height={'91vh'} alignContent={'center'}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={4}>
          <AppBox backgroundColor="white" borderRadius={6} border={1} borderColor={'grey.200'} padding={2}>
            <AppBox borderBottom={1} borderColor={'grey.200'} paddingBottom={1}>
              <AppTypography fontSize={32} fontWeight={'bold'}>
                Your plan was cancelled
              </AppTypography>
            </AppBox>
            <AppTypography fontSize={16} fontWeight={400} color={'grey.500'} paddingTop={2}>
              We see you previously canceled your plan on our old platform. We would love to give you the opportunity to
              experience the power of our new system with a free 7-day trial. Simply click the <b>Restart Trial</b>{' '}
              button and complete the profile wizard. There’s no credit card required.{' '}
            </AppTypography>
          </AppBox>
        </Grid>
        <Grid item xs={2}>
          <img
            src="/images/dashboard/old-subscription-cancelled/old-subscription-cancelled.png"
            alt="old-cancelled-message"
          />
        </Grid>
      </Grid>
    </AppBox>
  )
}
