import { ColumnDef } from '@tanstack/react-table'
import { AppIcon, AppTable, AppTableProps } from 'components'
import { FC } from 'react'
import { NewsAlertViewModel } from 'sdk'

interface Props extends AppTableProps<NewsAlertViewModel> {}
export const NewAlertsTable: FC<Props> = ({ ...props }) => {
  const columns: ColumnDef<NewsAlertViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <>
            <AppIcon icon={'material-symbols:more-horiz'} />
          </>
        )
      },
    },
    {
      header: 'Start Date',
      accessorKey: 'StartDate',
    },
    {
      header: 'Article Date',
      accessorKey: 'ArticleDate',
    },
    {
      header: 'Title',
      accessorKey: 'Title',
    },
    {
      header: 'URL',
      accessorKey: 'ArticleUrl',
    },
    {
      header: 'Created By',
      accessorKey: 'CreatedBy',
    },
  ]
  return (
    <>
      <AppTable columns={columns} {...props} />
    </>
  )
}
