import { Grid } from '@mui/material'
import {
  AppBox,
  AppDialog,
  AppDialogRefType,
  AppIcon,
  AppTypography,
  EmailText,
  InfoBox,
  PepoleInsightLoader,
  PhoneText,
  TextWithToolTip,
} from 'components'
import { FeedbackTypes, InfoBoxSettingTypes, PeopleInsightRolesOptions } from 'globals/constants'
import { getFullDate } from 'globals/functions'
import { useFeedBack, usePeopleInsight } from 'hooks'
import { FC, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FeedbackPayload, PeopleInsightToCrmPayload, PeopleInsightViewModel } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'
import { AddPeopleToCrmForm } from './forms/AddPeopleInsightToCrmForm'
import { PeopleInsightRelatedParksInsight } from './forms/PeopleInsightRelatedParksInsights'
import { useForm } from 'react-hook-form'

interface Props {}

export const PeopleInsightUserView: FC<Props> = () => {
  const { isGetting, PeopleInsights } = usePeopleInsight()
  const [selectedInsight, setSelectedInsight] = useState<PeopleInsightViewModel>()
  const relatedParksDialog = useRef<AppDialogRefType>()
  const addToCRMDialogRef = useRef<AppDialogRefType>()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { control, setValue, reset, handleSubmit, watch } = useForm<PeopleInsightToCrmPayload>()

  const openRelatedParksDialog = (item: PeopleInsightViewModel) => {
    setSelectedInsight(item)
    relatedParksDialog.current?.openDialog!()
  }
  const { SaveFeedBack, IsSaving } = useFeedBack()
  const [savedFeedBack, setSavedFeedBack] = useState<{ feedback: 'good' | 'bad'; insightId: string } | null>(null)

  const handleFeedBack = (feedback: 'good' | 'bad', insightId: string) => {
    if (!savedFeedBack) {
      setSavedFeedBack({ feedback, insightId })
      const data: FeedbackPayload = {
        Type: FeedbackTypes.ParkCityDemographics,
        Value: feedback,
        PrincipalType: 'ParkSite',
        PrincipalId: selectedParkId,
        DataMemberId: insightId,
      }
      SaveFeedBack(data)
    }
  }
  return (
    <>
      {isGetting ? (
        <PepoleInsightLoader />
      ) : (
        <AppBox display={'flex'} flexDirection={'column'} gap={2} height={'100%'} paddingX={4}>
          <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.PeopleInsightDismissed}>
            These are the people we believe are associated with this park. Whenever one of these people are associated
            with more than one park, simply click the displayed park icon to see their list of other parks.
          </InfoBox>
          <AppBox>
            <Grid container spacing={2}>
              {PeopleInsights && PeopleInsights?.Data && PeopleInsights?.TotalCount! > 0 ? (
                PeopleInsights?.Data?.map((item, index) => (
                  <>
                    <Grid xs={3} item display={'inline-flex'}>
                      <AppBox
                        key={index}
                        display={'flex'}
                        flexDirection={'column'}
                        padding={2}
                        backgroundColor="grey.100"
                        border={1}
                        borderColor={'grey.200'}
                        borderRadius={3}
                        color={'grey.500'}
                        justifyContent={'space-between'}
                      >
                        <AppBox>
                          <AppBox display={'flex'} gap={2} justifyContent={'space-between'} paddingBottom={2}>
                            <AppBox
                              borderRadius={30}
                              paddingTop={0.5}
                              paddingBottom={0.3}
                              paddingX={2}
                              backgroundColor={
                                !!item.Role && PeopleInsightRolesOptions.map((x) => x.value).includes(item.Role)
                                  ? PeopleInsightRolesOptions.find((x) => x.value === item.Role)!['color']
                                  : '#38bdf8'
                              }
                            >
                              <AppTypography color={'white'} fontWeight={600}>
                                {PeopleInsightRolesOptions.map((x) => x.value).includes(item.Role)
                                  ? PeopleInsightRolesOptions.find((x) => x.value === item.Role)![
                                      'label'
                                    ].toLocaleUpperCase()
                                  : '#38bdf8'}
                              </AppTypography>
                            </AppBox>
                            <AppBox display={'flex'} gap={1} alignItems={'center'}>
                              {item.RelatedParks?.length > 0 && (
                                <AppIcon
                                  icon={'gis:home'}
                                  color="#1859FB"
                                  onClick={() => {
                                    openRelatedParksDialog(item)
                                  }}
                                />
                              )}
                              <AppIcon
                                icon={'gridicons:user-add'}
                                color="#1859FB"
                                size="medium"
                                onClick={() => {
                                  setSelectedInsight(item)
                                  reset({
                                    ContactType: 'lead',
                                    ContactFirstName: item.FirstName,
                                    ContactLastName: item.LastName,
                                    ContactEmail: item.Email,
                                    ContactPhone: item.Phone,
                                    ContactAltPhone: item.AltPhone,
                                    ContactAddress: item.Address,
                                    ContactCity: item.City,
                                    ContactStateId: item.StateId,
                                    ContactZip: item.Zip,
                                    Company: item.CompanyName,
                                    LinkToParkId: selectedParkId,
                                  })
                                  addToCRMDialogRef.current?.openDialog!()
                                }}
                              />
                            </AppBox>
                          </AppBox>
                          {item.Updated && (
                            <>
                              <AppTypography fontWeight={500}>Updated on {getFullDate(item.Updated)}</AppTypography>
                              <AppTypography fontWeight={'bold'} fontSize={14}>
                                {item.FirstName} {item.LastName}
                              </AppTypography>
                              <AppTypography fontWeight={500}>{item.CompanyName}</AppTypography>

                              <AppTypography fontWeight={500}>{item.Address}</AppTypography>
                              <AppTypography fontWeight={500}>
                                {item.City}, {item.StateName} {item.Zip}
                              </AppTypography>
                            </>
                          )}
                          <AppBox display={'flex'} gap={1} alignItems={'center'}>
                            <PhoneText phone={item.Phone} />
                            {item.AltPhone && (
                              <>
                                <AppIcon color="grey.100" icon={'ion:ellipse'} style={{ fontSize: 6 }} />
                                <PhoneText phone={item.AltPhone} />
                              </>
                            )}
                          </AppBox>
                          <EmailText email={item.Email} />
                        </AppBox>
                        <AppBox
                          display={'flex'}
                          gap={2}
                          paddingTop={2}
                          alignContent={'center'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <AppTypography fontSize={14}>Was this information helpful?</AppTypography>
                          <AppBox gap={1} display={'flex'} alignItems={'center'}>
                            <AppIcon
                              color={
                                savedFeedBack?.insightId === item.Id && savedFeedBack.feedback === 'good'
                                  ? 'blue'
                                  : 'grey.500'
                              }
                              icon={'octicon:thumbsup-16'}
                              size="medium"
                              onClick={() => handleFeedBack('good', item.Id)}
                            />

                            <AppIcon
                              color={
                                savedFeedBack?.insightId === item.Id && savedFeedBack.feedback === 'bad'
                                  ? 'blue'
                                  : 'grey.500'
                              }
                              icon={'octicon:thumbsdown-16'}
                              size="medium"
                              onClick={() => handleFeedBack('bad', item.Id)}
                            />
                          </AppBox>
                        </AppBox>
                      </AppBox>
                    </Grid>
                  </>
                ))
              ) : (
                <AppBox paddingTop={4} textAlign={'center'} width={'100%'}>
                  <AppTypography fontWeight={700} fontSize={16} color={'grey.400'}>
                    Sorry, we're still working to identify this park's associated people
                  </AppTypography>
                </AppBox>
              )}
            </Grid>
          </AppBox>
        </AppBox>
      )}
      <AppDialog
        ref={relatedParksDialog}
        title={
          <TextWithToolTip tooltipText="These are the parks our records indicate are related to this person">
            Related Park Insights
          </TextWithToolTip>
        }
        maxWidth={'xl'}
      >
        <>
          <PeopleInsightRelatedParksInsight insight={selectedInsight!} />
        </>
      </AppDialog>
      <AddPeopleToCrmForm
        dialogRef={addToCRMDialogRef}
        peopleInsight={selectedInsight!}
        control={control}
        reset={reset}
        watch={watch}
        setValue={setValue}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
