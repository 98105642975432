import { Divider, Grid, Skeleton } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, AppTypography, SimpleField, TextWithToolTip } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ZapierApi, ZapierViewModel } from 'sdk'
import { AuthSliceSelector } from 'store/slices'

export const ZapierIntegrationModule = () => {
  const zapierApi = new ZapierApi()
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const [userZapier, setUserZapier] = useState<ZapierViewModel>()

  const { mutate: generateApiKey, isPending: isGenerating } = useMutation({
    mutationKey: [ApiQueryKeys.zapier.generateApiKey],
    mutationFn: () => zapierApi.generateApiKey(CurrentUser?.UserId!),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      getUserZapier()
      enqueueSnackbar('API Key generated successfully', { variant: 'success' })
    },
  })
  const { mutate: getUserZapier, isPending: isGetting } = useMutation({
    mutationKey: [ApiQueryKeys.zapier.GetZapierInfo],
    mutationFn: () => zapierApi.GetZapierInfo(CurrentUser?.UserId!),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: (data) => {
      setUserZapier(data)
    },
  })
  useEffect(() => {
    getUserZapier()
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBox backgroundColor="red" padding={2} borderRadius={3}>
            <AppTypography display={'flex'} alignItems={'center'} fontSize={14} color={'white'}>
              <AppIcon icon={'line-md:alert-loop'} size="medium" color="white" style={{ paddingRight: 1 }} />
              <b> Alert: </b>
              This integration is currently in the final review process by Zapier. Once approval, ParksitesIO will
              become available from within the Zapier application.
            </AppTypography>
          </AppBox>
        </Grid>
        <Grid item xs={12}>
          <AppBox border={1} padding={2} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
            <TextWithToolTip tooltipText="Use our Zapier integration to connect your CRM records to other apps and services.">
              <AppTypography fontWeight={700} fontSize={16}>
                Zapier Integration
              </AppTypography>
            </TextWithToolTip>
            <Divider sx={{ marginBlock: 1 }} />
            <img src="/images/zapier/zapier-logo.png" alt="Zapier Logo" />
            <AppBox paddingTop={2}>
              <AppTypography fontWeight={500} fontSize={14} color={'grey.500'} component={'span'}>
                While not affiliated with ParkSitesIO, Zapier is an online automation tool that connects your apps and
                services. Using a zapier account you can connect two or more apps to automate repetitive tasks without
                coding or relying on developer to build the integration. To learn more about Zapier please visit the{' '}
                <AppTypography
                  component={'a'}
                  fontSize={14}
                  color={'primary'}
                  onClick={() => window.open('https://help.zapier.com/hc/en-us', '_blank')}
                >
                  Zapier Help Center
                </AppTypography>
              </AppTypography>
            </AppBox>
          </AppBox>
        </Grid>
        <Grid item xs={12}>
          <AppBox border={1} padding={3} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
            <TextWithToolTip
              tooltipText={
                <AppTypography>
                  {' '}
                  Generate and provide your private <b>API Key</b> to Zapier to complete the integration to ParkSitesIO.
                </AppTypography>
              }
            >
              <AppTypography fontWeight={700} fontSize={16}>
                API Key
              </AppTypography>
            </TextWithToolTip>
            <Divider sx={{ marginBlock: 1 }} />
            <AppTypography fontSize={14} fontWeight={500} color={'grey.500'} component={'span'}>
              Utilize your API Key to connect your Zapier account with your ParkSitesIO account. This API Key should be
              kept private to only you. If you feel someone has obtained your private API Key you can regenerate a new
              API Key at anytime.{' '}
              <AppTypography
                component={'a'}
                fontSize={14}
                color={'primary'}
                onClick={() => window.open('https://support.parksites.io/portal/en/kb/zapier-integration', '_blank')}
              >
                Learn more
              </AppTypography>
            </AppTypography>
            {isGetting ? (
              <Grid container spacing={2} alignItems={'center'} paddingTop={2}>
                <Grid item sm={3}>
                  <Skeleton variant="text" height={40} />
                </Grid>
                <Grid item sm={1}>
                  <Skeleton variant="text" height={40} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems={'center'} paddingTop={2}>
                <Grid item sm={3}>
                  <SimpleField
                    placeholder={'Click Generate Key'}
                    fullWidth
                    value={userZapier?.API_Key}
                    disabled={true}
                    suffixIcon="ph:copy-bold"
                    suffixIconProps={{
                      color: '#2563EB',
                      onClick: () => {
                        navigator.clipboard.writeText(userZapier?.API_Key!)
                        enqueueSnackbar('API Key copied to clipboard', { variant: 'success' })
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <AppButton
                    variant="contained"
                    disabled={!!userZapier?.API_Key || true}
                    onClick={() => generateApiKey()}
                    isLoading={isGenerating}
                  >
                    Generate Key
                  </AppButton>
                </Grid>
              </Grid>
            )}
          </AppBox>
        </Grid>
      </Grid>
    </>
  )
}
