import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppImage, AppTypography, PasswordField } from 'components'
import { ApiQueryKeys, appRoutes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthApi, SetPasswordPayload } from 'sdk'

interface Props {}

export const AccountSetPassword: FC<Props> = () => {
  const authApi = new AuthApi()
  const { enqueueSnackbar } = useSnackbar()
  const { control, setValue, watch } = useForm<SetPasswordPayload>({ mode: 'onChange' })

  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const Id = queryParams.get('Id')
  const token = queryParams.get('token')
  useEffect(() => {
    setValue('UserId', Id!)
    setValue('Token', token!)
  }, [Id, token])

  const { mutate, isPending } = useMutation({
    mutationFn: (data: SetPasswordPayload) => authApi.setPasswordAfterForgot(data),
    mutationKey: [ApiQueryKeys.account.setPasswordAfterForgot],
    onError: () => enqueueSnackbar('Error updating password', { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Password updated successfully', { variant: 'success' })
      navigate(appRoutes.login.path)
    },
  })
  return (
    <>
      <AppBox backgroundColor="grey.100" height={'100vh'} alignContent={'space-evenly'}>
        <AppBox textAlign={'center'}>
          <AppImage src="/images/logo/logo-black.png" alt="nature" height={40} />
        </AppBox>
        <Grid container justifyContent={'center'} padding={2}>
          <AppBox
            border={1}
            borderColor={'grey.200'}
            borderRadius={6}
            padding={2}
            backgroundColor="white"
            width={'xs'}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
          >
            <Form
              control={control}
              onSubmit={({ data }) => {
                mutate(data)
              }}
            >
              <AppTypography
                fontWeight={700}
                fontSize={14}
                color={'grey.900'}
                borderBottom={1}
                borderColor={'grey.200'}
              >
                Change Password
              </AppTypography>
              <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingTop={1}>
                <AppTypography fontSize={16} color={'grey.600'} fontWeight={500}>
                  Please enter your new password to sign into ParkSitesIO.
                </AppTypography>
                <PasswordField
                  name="Password"
                  control={control}
                  label="Password"
                  placeholder="Enter new password"
                  required
                />
                <PasswordField
                  name="ConfirmPassword"
                  control={control}
                  label="Confirm Password"
                  placeholder="Re-enter new password"
                  isConfirmPassword
                  passwordValue={watch().Password?.toString() ?? ''}
                  required
                />
              </AppBox>

              <AppBox paddingTop={2} display={'flex'} justifyContent={'flex-end'}>
                <AppButton variant="contained" type="submit" isLoading={isPending}>
                  Change Password
                </AppButton>
              </AppBox>
            </Form>
          </AppBox>
        </Grid>
      </AppBox>
    </>
  )
}
