import { StepType } from '@reactour/tour'
import { FC } from 'react'
import { AppTour } from './AppTour'
import { AppTourKeys } from 'globals/constants'
import { AppBox, AppTypography } from 'components/base'

interface Props {}

export const ParkSiteDashboardTours: FC<Props> = () => {
  //   const setting: SettingPayload = {
  //     Status: true,
  //     UniqueKey: SettingsTypes.INFO_BOX_SETTINGS,
  //     Value: settingUniqueKey?.toString(),
  //     AllowDuplicate: true,
  //   }
  const ParkSiteTourSteps: StepType[] = [
    {
      selector: '.park-sites-dashboard-tour-step0',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Welcome
          </AppTypography>
          <AppTypography fontSize={14}>
            Welcome to your <b>Dashboard!</b> Whenever you access something new we'll offer to give you a quick tour.
            This way you can harness the full power of our app. Would you like to see a tour of your <b>Dashboard's</b>{' '}
            highlights
          </AppTypography>
        </AppBox>
      ),
      position: 'center',
    },
    {
      selector: '.park-sites-dashboard-tour-step1',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Clicking on your profile image opens your <b>Resource Center</b>. From within it, you can access your{' '}
            <b>Account Settings</b> and other resources such as our <b>Help Center</b>.
          </AppTypography>
        </AppBox>
      ),
      position: 'bottom',
    },
    {
      selector: '.park-sites-dashboard-tour-step2',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Target specific parks that should be display on your map with the filter. Once you have the results you’re
            looking for, use the filter’s <b>Save</b> option to quickly access them later or to segment parks for other
            team members.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step3',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            By pinning a park from within its <b>Activity Center</b>, you can control the color of its map pin and view
            it in a list using the <b>Pinned Parks</b> option. Define your customized pin colors using the <b>Manage</b>{' '}
            option within <b>Pinned Parks.</b>
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step4',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Applying tags to a park allows you to organize parks into searchable groups. Use the <b>Tagged Parks</b>{' '}
            option to manage your predefined tags or view the list of the parks you’ve applied them to.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step5',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            When you add your private contacts to a park either using the park’s <b>Activity Center</b> or while
            directly viewing your <b>CRM</b> records, we’ll display the associated parks in a searchable list when you
            click the <b>CRM Parks</b> option.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step6',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            When our records don’t include a park that you want to work with, simply click the <b>New Park</b> option to
            request it be added. After that, our staff will research your request and add the appropriate information.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step7',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Use the options within the <b>Views</b> menu to switch between working with the <b>Park Site</b> map view or
            directly within your <b>CRM</b> records.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-dashboard-tour-step8',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Dashboard Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            You can access each park's <b>Activity Center</b> by clicking it map pin. The legend helps you remember
            which parks our staff have deemed operationally active, permamnently closed, or just have data on yet.
          </AppTypography>
        </AppBox>
      ),
      position: 'right',
    },
  ]

  return <AppTour uniqueKey={AppTourKeys.ParkSitesDashboardTour} steps={ParkSiteTourSteps} />
}
