import { Grid, Skeleton } from '@mui/material'

export const PinnedParkSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" width={'100%'} height={40} />
      <Grid container gap={2}>
        {Array.from(new Array(3)).map((x, _index) => (
          <Grid item xs={12} key={_index}>
            <Grid container gap={1} padding={2}>
              <Grid item xs={2}>
                <Skeleton variant="rounded" width={'100%'} height={100} />
              </Grid>
              <Grid item xs={7.6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Skeleton variant="rounded" width={'50%'} height={30} />
                <Skeleton variant="rounded" width={'20%'} height={20} />
                <Skeleton variant="rounded" width={'25%'} height={20} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
