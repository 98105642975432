import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaginationFilterModel, ParkListTab, UserParkLabelViewModel } from 'sdk'
import { IAppStore } from 'store/app.store'

export interface ParkLabelState {
  ParkLabels: Array<UserParkLabelViewModel>
  Filter: PaginationFilterModel
  LabelledParks: Array<ParkListTab>
  isLabelledParksLoading: boolean
}

const initialState: ParkLabelState = {
  ParkLabels: [],
  Filter: new PaginationFilterModel(),
  LabelledParks: [],
  isLabelledParksLoading: false,
}

export const ParkLabelSlice = createSlice({
  name: 'ParkLabel',
  initialState: initialState,
  reducers: {
    setParkLabels: (state, action: PayloadAction<Array<UserParkLabelViewModel>>) => {
      state.ParkLabels = action.payload
    },
    setLabelledParks: (state, action: PayloadAction<Array<ParkListTab>>) => {
      state.LabelledParks = action.payload
    },
    setisLoadingLabelledParks: (state, action: PayloadAction<boolean>) => {
      state.isLabelledParksLoading = action.payload
    },
  },
})

export const { setParkLabels, setLabelledParks, setisLoadingLabelledParks } = ParkLabelSlice.actions

export const ParkLabelsliceSelector = (appState: IAppStore): ParkLabelState => appState.ParkLabel
