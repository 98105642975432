import { ChangeMembershipPayload, ChangeMembershipResponse, MembershipPlanViewModel } from 'sdk'
import { BaseApi } from '../base.api'
import { apiUrls } from 'sdk/api-urls'

export class MembershipApi extends BaseApi {
  public GetMembershipPlans() {
    return this.GET_Request<Array<MembershipPlanViewModel>>(apiUrls.memberships.GetMembershipPlans())
  }
  public ChangeMembership(data: ChangeMembershipPayload) {
    return this.POST_Request<ChangeMembershipResponse>(apiUrls.memberships.changeMembership(), data)
  }
  public CancelMembership() {
    return this.GET_Request(apiUrls.memberships.cancelMembership())
  }
}
