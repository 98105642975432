import { Grid } from '@mui/material'
import { AppAutoComplete, AppTypography } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { NewUserGettingStartedPayload } from 'sdk'

interface Props {
  control: Control<NewUserGettingStartedPayload>
  name: string
}

export const GettingStartedAboutUs: FC<Props> = ({ control, name }) => {
  return (
    <Grid container>
      <Grid item xs={9}>
        <AppTypography fontSize={15} fontWeight={700}>
          You're all set!
        </AppTypography>
        <AppTypography fontSize={14} color={'grey.500'} paddingBottom={2}>
          {name}, your profile is all set up!
        </AppTypography>
        <AppTypography fontSize={14} color={'grey.500'} paddingBottom={2}>
          Before we let you get to work, please tell us how you heard about our site.
        </AppTypography>
        <Grid item xs={8} md={5}>
          <AppAutoComplete
            required
            control={control}
            name="Channel"
            label="Channels"
            options={[
              { label: 'Google Search', value: 'Google' },
              { label: 'Yahoo Search', value: 'Yahoo' },
              { label: 'Bing Search', value: 'Bing' },
              { label: 'Social Media', value: 'Social' },
              { label: 'Podcast', value: 'Podcast' },
              { label: 'Word of the mouth', value: 'WordOfMouth' },
              { label: 'My Employer', value: 'Employer' },
              { label: 'Other', value: 'Other' },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} textAlign={'center'} alignContent={'center'}>
        <img
          src="/images/dashboard/getting-started/getting-started-step-four-success.png"
          alt="Getting Started Step Four"
        />
      </Grid>
      <Grid container borderTop={1} borderColor={'grey.200'} paddingTop={1} marginTop={4} paddingBottom={4}>
        <Grid item xs={9}>
          <AppTypography fontSize={15} fontWeight={700}>
            Feedback
          </AppTypography>
          <AppTypography fontSize={14} component={'span'}>
            We know you’re just getting started with us, but we have a favor to ask once your trial is over. Our
            platform relies on people just like you to keep things running. As a favor to us, if you like what we’ve
            built, please let us know by going to the{' '}
            <AppTypography fontSize={14} component={'span'}>
              Provide Feedback
            </AppTypography>{' '}
            section of your profile menu.
          </AppTypography>
        </Grid>
        <Grid item xs={3} textAlign={'center'} alignContent={'flex-end'}>
          <img
            src="/images/dashboard/getting-started/getting-started-step-four-feedback.png"
            alt="Getting Started Step Four"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
