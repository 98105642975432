import { Typography } from '@mui/material'
import { AppBox, AppTypography, SimpleField } from 'components'
import { FilterCategory, FilterField } from 'globals'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useRolesHelper } from 'hooks'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { FC, useEffect, useMemo, useState } from 'react'
import { FilterConditionSaveModel } from 'sdk'

interface Props {
  filterCategories: FilterCategory[]
  addedFilters: FilterConditionSaveModel[]
  onFieldSelect: (columnName: string, tableName: string) => void
  selectedFilter: { columnName: string; tableName: string }
  type: FILTER_PRINCIPAL_TYPES
}

export const SelectFilterField: FC<Props> = ({
  filterCategories,
  selectedFilter,
  addedFilters,
  type,
  onFieldSelect,
}) => {
  const { fieldByCoumnAndTableName } = useFilterBuilder({ type, preventFiltersUpdate: true })
  const { IsAdmin } = useRolesHelper()

  const isFieldVisible = (field: FilterField) => {
    if (field.allowedToAdmin) {
      return IsAdmin()
    }
    return true
  }

  useEffect(() => {
    if (selectedFilter) {
      const field = fieldByCoumnAndTableName(selectedFilter.columnName, selectedFilter.tableName)
      if (field) {
        setSelectedLabel(field.Label)
      }
    }
  }, [selectedFilter])

  const [selectedLabel, setSelectedLabel] = useState<string>()
  const [query, setQuery] = useState('')

  const visibleCategoryFields = (fields: Array<FilterField>) => {
    const filtered = fields.filter(
      (field) => !addedFilters.find((x) => x.ColumnName === field.ColumnName && x.TableName === field.TableName)
    )
    if (query !== '') {
      return filtered.filter((x) => x.Label.toLowerCase().includes(query.toLowerCase()))
    }
    return filtered
  }
  const searchedFields = useMemo(() => {
    return filterCategories.map((category: FilterCategory) => (
      <AppBox key={category.Label}>
        {category.Label && (
          <AppTypography
            fontSize="15px"
            fontWeight="bold"
            color="primary.200"
            marginBottom={1}
            borderBottom={1}
            borderColor={'primary.200'}
          >
            {category.Label}
          </AppTypography>
        )}
        {visibleCategoryFields(category.Fields).map((field: FilterField) => (
          <AppBox
            backgroundColor={selectedLabel === field.Label ? 'primary.200' : 'transparent'}
            color={selectedLabel === field.Label ? 'white' : 'black'}
            key={field.ColumnName}
            cursor="pointer"
            onClick={() => {
              setSelectedLabel(field.Label)
              onFieldSelect(field.ColumnName, field.TableName)
            }}
            If={isFieldVisible(field)}
          >
            <AppTypography>{field.Label}</AppTypography>
          </AppBox>
        ))}
      </AppBox>
    ))
  }, [query, selectedLabel])
  return (
    <AppBox>
      <SimpleField prefixIcon="iconamoon:search-bold" placeholder="Search" onChange={(e) => setQuery(e.target.value)} />
      <AppBox
        paddingTop={2}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        maxHeight={'25vh'}
        borderTop={1}
        marginTop={1}
        borderColor={'grey.300'}
        style={{ overflowY: 'scroll' }}
      >
        {searchedFields}
      </AppBox>
    </AppBox>
  )
}
