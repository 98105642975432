import { useSelector } from 'react-redux'

import { AppBox, AppButton, AppTypography, FilterButton } from 'components'
import { FILTER_PRINCIPAL_TYPES, ParkSitesTools } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from 'store/app.store'
import {
  closeActivity,
  GoogleMapSliceSelector,
  ParkSiteToolSliceSelector,
  setSelectedPlace,
  setTool,
} from 'store/slices'
import { FilterSliceSelector } from 'store/slices/filter.slice'
import { NewParkDialogBtn } from 'views'
import { AutoCompleteInput } from '../GoogleMap/AutoCompleteInput/AutoCompleteInput'
import { ViewsSelectBtn } from '../views-select-btn/ViewsSelectBtn'
interface ParkSiteToolType {
  title: string
  icon: string
  key: ParkSitesTools
}
export const ParkSitesToolBar = () => {
  const { openedTool } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedParkIdToMove } = useSelector(GoogleMapSliceSelector)
  const {
    Park: { IsUnSavedFilterApplied: IsFilterApplied },
  } = useSelector(FilterSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  const dispath = useAppDispatch()

  const handleToolClick = (key: ParkSitesTools) => {
    if (IsFilterApplied) {
      enqueueSnackbar('We’re temporarily hiding your filter while you view this information', {
        variant: 'info',
      })
    }
    dispath(closeActivity())
    dispath(setTool(key))
  }

  const links: Array<ParkSiteToolType> = [
    {
      title: 'Pinned Parks',
      key: ParkSitesTools.PinnedParks,
      icon: 'ph:map-pin-fill',
    },
    {
      title: 'Tagged Parks',
      key: ParkSitesTools.TaggedParks,
      icon: 'bi:tags-fill',
    },
    {
      title: 'CRM Parks',
      key: ParkSitesTools.CRM,
      icon: 'ri:contacts-fill',
    },
  ]

  return (
    <>
      <AppBox
        backgroundColor="white"
        height={'100%'}
        alignContent={'center'}
        display={'flex'}
        justifyContent={'space-between'}
        borderBottom={1}
        borderColor={'grey.300'}
        paddingX={3}
      >
        <AppBox alignContent={'center'}>
          <AppBox display={'flex'} alignItems={'center'} gap={1}>
            <AppBox>
              <AutoCompleteInput onPlaceChange={(value) => dispath(setSelectedPlace(value))} />
            </AppBox>
            {!SelectedParkIdToMove && (
              <AppBox className="park-sites-dashboard-tour-step2">
                <FilterButton type={FILTER_PRINCIPAL_TYPES.PARK} />
              </AppBox>
            )}
          </AppBox>
        </AppBox>
        <AppBox alignContent={'center'}>
          <AppBox display={'flex'} alignItems={'center'} gap={2}>
            {!SelectedParkIdToMove &&
              links.map((link, index) => (
                <AppButton
                  key={index}
                  color={openedTool === link.key ? 'primary' : 'secondary'}
                  onClick={() => handleToolClick(link.key)}
                  prefixIcon={link.icon}
                  className={`park-sites-dashboard-tour-step${index + 3}`}
                >
                  <AppTypography fontSize={14} fontWeight={500}>
                    {link.title}
                  </AppTypography>
                </AppButton>
              ))}
            {!SelectedParkIdToMove && (
              <AppBox className="park-sites-dashboard-tour-step6">
                <NewParkDialogBtn />
              </AppBox>
            )}
            <AppBox className="park-sites-dashboard-tour-step7">
              <ViewsSelectBtn />
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
    </>
  )
}
