import {
  CrmCompanyViewModel,
  CrmContactLinkedCompaniesViewModel,
  CrmContactViewModel,
  CrmcontactLinkedCompanyPayload,
  PaginatedApiResponseModel,
} from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from 'sdk/api-services/base.api'

const baseApi = new BaseApi()
export class CrmContactLinkedCompaniesApi {
  public getContactLinkedCompanies(contactId: string) {
    return baseApi.GET_Request<PaginatedApiResponseModel<CrmContactLinkedCompaniesViewModel>>(
      apiUrls.crm.companyLinkedContacts.getContactLinkedCompanies(contactId)
    )
  }
  public getCompanyLinkedContacts(companyId: string) {
    return baseApi.GET_Request<PaginatedApiResponseModel<CrmContactViewModel>>(
      apiUrls.crm.companyLinkedContacts.getCompanyLinkedContacts(companyId)
    )
  }
  public getContactUnlinkedCompanies(contactId: string) {
    return baseApi.GET_Request<CrmContactLinkedCompaniesViewModel[]>(
      apiUrls.crm.companyLinkedContacts.getContactUnlinkedCompanies(contactId)
    )
  }
  public linkCompany(payload: CrmcontactLinkedCompanyPayload) {
    return baseApi.POST_Request(apiUrls.crm.companyLinkedContacts.contactLinkCompany(), payload)
  }

  public unlinkContactLinkedCompany(contactId: string, companyId: string) {
    return baseApi.DELETE_Request(apiUrls.crm.companyLinkedContacts.unlinkContactLinkedCompany(contactId, companyId))
  }
}
