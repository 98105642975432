import { Grid } from '@mui/material'
import { AppBox, AppTypography } from 'components'

export const AddSubAccountStepFourConfirmation = () => {
  return (
    <>
      <AppBox textAlign={'center'} display={'ruby'}>
        <AppTypography fontWeight={700} fontSize={32} textAlign={'center'} width={'80%'}>
          Your user has been added to your subscription plan
        </AppTypography>
      </AppBox>
      <Grid container justifyContent={'center'} paddingTop={4}>
        <Grid item xs={6}>
          <AppTypography fontSize={16} fontWeight={700}>
            What happens next
          </AppTypography>
          <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
            We’re sending out your new user’s invitation to log into their account for the first time using the email
            address you’ve specified and a password we’ve automatically generated.
          </AppTypography>
          <AppTypography fontSize={14} color={'grey.500'} fontWeight={500} paddingY={3}>
            After they accept the invitation their status within your
            <AppTypography fontWeight={600} component={'span'}>
              {' '}
              Manage Users{' '}
            </AppTypography>{' '}
            will change to
            <AppTypography fontWeight={600} component={'span'}>
              {' '}
              Enabled.{' '}
            </AppTypography>{' '}
            Until then, we’ll display{' '}
            <AppTypography fontWeight={600} component={'span'}>
              Pending.{' '}
            </AppTypography>
            You can use the user’s action menu to resend the invitation again if they didn’t receive ours.
          </AppTypography>
        </Grid>
        <Grid item xs={4} textAlign={'right'}>
          <img src="/images/pax/AddSubAccountConfirmation.png" alt="img" />
        </Grid>
      </Grid>
    </>
  )
}
