import { Skeleton } from '@mui/material'
import { AppBox, AppTypography } from 'components'

export const NoteListViewLoader = () => {
  return (
    <>
      {Array.from(new Array(2)).map(() => (
        <AppBox backgroundColor="grey.50" borderColor={'grey.200'} border="1px" padding={2} borderRadius={3}>
          <AppBox
            display={'flex'}
            justifyContent={'space-between'}
            borderBottom={1}
            borderColor={'grey.200'}
            paddingBottom={1}
          >
            <Skeleton variant="text" width={'30%'} height={30} />
          </AppBox>
          <AppBox paddingTop={1}>
            <AppTypography color={'grey.700'} fontSize={12}>
              <Skeleton variant="text" width={'100%'} height={30} />
              <Skeleton variant="text" width={'100%'} height={30} />
            </AppTypography>
          </AppBox>
        </AppBox>
      ))}
    </>
  )
}
