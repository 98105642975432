import { FormControl, FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'
import { FC, ReactElement } from 'react'

interface Props extends Omit<RadioGroupProps, 'onChange'> {
  options: Array<{ Id: string; Component?: ReactElement; Label?: string }>
  onChange: (value: string, checked: boolean) => void
}

export const SimpleRadioGroup: FC<Props> = ({ options, onChange, ...props }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={props.defaultValue ?? ''}
        name="radio-buttons-group"
        onChange={() => {}}
        {...props}
      >
        {options?.map((x) => (
          <FormControlLabel
            key={x.Id}
            control={<Radio value={x.Id} onChange={(event, checked) => onChange(event.target.value, checked)} />}
            label={x.Label ?? x.Component}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
