import { AppBox, AppDialog, AppDialogRefType } from 'components'
import { useManageUsers } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { UsersActions } from 'views'
import { TotalUsersListing } from './listing/TotalUsersListing'
import { ManageAccountDialog } from './manage-account-dialog/ManageAccountDialog'

export const ManageUsers = () => {
  const { filter, setFilter, allUsers, getAllUsers, isGettingAllUsers } = useManageUsers()
  const manageAccountDialogRef = useRef<AppDialogRefType>(null)
  const [userIdSelected, setUserIdSelected] = useState<string>('')

  useEffect(() => {
    getAllUsers(filter)
  }, [filter])

  return (
    <>
      <AppBox minHeight={'inherit'}>
        <AppBox paddingX={2}>
          <UsersActions filter={filter} setFilter={setFilter} totalUsers={allUsers?.TotalCount ?? 0} />
        </AppBox>
        <AppBox paddingX={2} minHeight={'inherit'}>
          <TotalUsersListing
            filter={filter}
            setFilter={setFilter}
            paginatedUsers={allUsers?.Data ?? []}
            isGettingUsers={isGettingAllUsers}
            totalPaginatedUsers={allUsers?.TotalCount ?? 0}
            handleManageAccountClick={(user) => {
              setUserIdSelected(user.Id)
              manageAccountDialogRef.current?.openDialog!()
            }}
          />
        </AppBox>
      </AppBox>
      <AppDialog
        maxWidth="xl"
        ref={manageAccountDialogRef}
        title="Manage Account"
        renderChild={() => <ManageAccountDialog userId={userIdSelected} />}
      />
    </>
  )
}
