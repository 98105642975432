import { AuthorizeProps } from 'sdk'

export interface FilterCategory {
  Label: string
  Fields: Array<FilterField>
}
export interface FilterField extends AuthorizeProps {
  TableName: string
  ColumnName: string
  Label: string
  IsDefaultFilter?: boolean
  PreConditionFieldTableName?: null | string
  PreConditionFieldColumnName?: null | string
  Conditions: Array<FilterCondition>
  PreConditionField?: PreConditionFields
}
export interface FilterCondition {
  Condition: FilterConditionTypes
  Label: FilterConditionLabels
  IsDefaultCondition?: boolean
  InputField?: FilterInputFields
}
export interface FilterInputFields {
  Type: FilterFieldType
  ValueFrom?: FilterInputValueFrom
  InputField?: FilterInputFields
  IsMultiSelect?: boolean
}

export interface FilterFieldsPricincipalType {
  Label: string
  Options: Array<{ Label: string; Id: string }>
}

export enum FilterInputValueFrom {
  States = '$[States]',
  SimpleStates = '$[SimpleStates]',
  RoleTypes = '$[RoleTypes]',
  LegendType = '$[LegendType]',
  CityTypes = '$[CityTypes]',
  TimeZoneTypes = '$[TimeZoneTypes]',
  StreetTypes = '$[StreetTypes]',
  StreetOwnerTypes = '$[StreetOwnerTypes]',
  WaterSourceTypes = '$[WaterSourceTypes]',
  SewerSourceTypes = '$[SewerSourceTypes]',
  HeatingFuelTypes = '$[HeatingFuelTypes]',
  TrashServiceTypes = '$[TrashServiceTypes]',
  FloodPlainTypes = '$[FloodPlainTypes]',
  SurfaceTypes = '$[SurfaceTypes]',
  ParkActivityTypes = '$[ParkActivityTypes]',
  Counties = '$[Counties]',
  Misc_AmenititesTypes = '$[Misc_Amenitites]',
  CrmContactTypes = '$[CrmContactTypes]',
  PrimaryRoles = '$[PrimaryRoles]',
  ParkSystemStatuses = '$[ParkSystemStatuses]',
}

export enum FilterFieldType {
  Text = 'Text',
  Number = 'Number',
  Currency = 'Currency',
  Date = 'Date',
  DropDown = 'DropDown',
  Year = 'Year',
  Phone = 'Phone',
  SimpleNumber = 'SimpleNumber',
  States = 'States',
  Counties = 'Counties',
  Zip = 'Zip',
  Comma_Separated_Number = 'Comma_Separated_Number',
}

export enum FilterConditionLabels {
  Equals = 'Equals',
  Does_Not_Equal = 'Does Not Equal',
  Contains = 'Contains',
  Does_Not_Contain = 'Does Not Contain',
  Is_More_Than = 'Is More Than',
  Is_Less_Than = 'Is Less Than',
  Is_Null = 'Is Null',
  Is_Not_Null = 'Is Not Null',
  Equals_Date = 'Equals',
  Does_Not_Equal_Date = 'Does Not Equal',
  Is_Before_Date = 'Is Before',
  Is_After_Date = 'Is After',
  Is_More_Than_Area = 'Is More Than',
  Is_Less_Than_Area = 'Is Less Than',
  EqualsWithPreCond = 'EqualsWithPreCond',
  NotEqualsWithPreCond = 'Does Not Equal',
  Unregistered = 'Unregistered',
  Registered = 'Registered',
  Unknown = 'Unknown',
  NotUnknown = 'Not Unknown',
  YearUnknown = 'Is Unknown',
  YearNotUnknown = 'Is Not Unknown',
  AcresUnknown = 'Is Unknown',
  AcresNotUnknown = 'Is Not Unknown',
  ParkPhoneUnknown = 'Is Unknown',
  ParkPhoneNotUnknown = 'Is Not Unknown',
  In = 'In',
  Not_In = 'Not In',
}

export enum FilterConditionTypes {
  Equals = 'Equals',
  Does_Not_Equal = 'Does_Not_Equal',
  Contains = 'Contains',
  Does_Not_Contain = 'Does_Not_Contain',
  Is_More_Than = 'Is_More_Than',
  Is_Less_Than = 'Is_Less_Than',
  Is_Null = 'Is_Null',
  Is_Not_Null = 'Is_Not_Null',
  Equals_Date = 'Equals_Date',
  Does_Not_Equal_Date = 'Does_Not_Equal_Date',
  Is_Before_Date = 'Is_Before_Date',
  Is_After_Date = 'Is_After_Date',
  Is_More_Than_Area = 'Is_More_Than_Area',
  Is_Less_Than_Area = 'Is_Less_Than_Area',
  EqualsWithPreCond = 'EqualsWithPreCond',
  NotEqualsWithPreCond = 'NotEqualsWithPreCond',
  Unregistered = 'Unregistered',
  Registered = 'Registered',
  Unknown = 'Unknown',
  NotUnknown = 'NotUnknown',
  YearUnknown = 'YearUnknown',
  YearNotUnknown = 'YearNotUnknown',
  AcresUnknown = 'AcresUnknown',
  AcresNotUnknown = 'AcresNotUnknown',
  ParkPhoneUnknown = 'ParkPhoneUnknown',
  ParkPhoneNotUnknown = 'ParkPhoneNotUnknown',
  In = 'In',
  Not_In = 'Not_In',
}

export enum PreConditionFields {
  State = 'State',
}
