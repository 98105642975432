import { AppBox, AppTypography, AppIcon } from 'components'
import { InfoBoxSettingTypes, SettingsTypes } from 'globals/constants'
import { useSettings } from 'hooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { SettingPayload } from 'sdk'
import { CommonSliceSelector } from 'store/slices/common.slice'

interface Props {
  dismissable?: boolean
  children: React.ReactNode | string
  settingUniqueKey?: InfoBoxSettingTypes
}
export const InfoBox = ({ dismissable = false, children, settingUniqueKey }: Props) => {
  const { UpdateSettings, loadDefaultSettings } = useSettings()
  const { Settings } = useSelector(CommonSliceSelector)
  const [isDismissed, setIsDismissed] = useState(false)

  const setting = () =>
    Settings.find((x) => x.UniqueKey === SettingsTypes.INFO_BOX_SETTINGS && x.Value === settingUniqueKey)

  const isVisible = () => {
    return settingUniqueKey ? !setting() : dismissable ? !isDismissed : true
  }

  const handleDismiss = () => {
    setIsDismissed(true)

    // Update the setting
    if (!!settingUniqueKey) {
      const setting: SettingPayload = {
        Status: true,
        UniqueKey: SettingsTypes.INFO_BOX_SETTINGS,
        Value: settingUniqueKey?.toString(),
        AllowDuplicate: true,
      }
      UpdateSettings([setting])
        .then(() => loadDefaultSettings())
        .catch(() => {})
    }
  }

  return (
    <AppBox backgroundColor="primary.100" padding={2} borderRadius={3} If={isVisible()}>
      <AppTypography fontSize={14} color={'grey.500'} fontWeight={500} component={'span'}>
        <AppIcon icon={'mage:light-bulb-fill'} /> <b>Hint:</b> {children}
        <AppBox If={dismissable} component={'span'} color={'primary.600'} cursor="pointer" onClick={handleDismiss}>
          {' '}
          Dismiss
        </AppBox>
      </AppTypography>
    </AppBox>
  )
}
