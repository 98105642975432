import { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  children: ReactNode
  helmet?: string
}

export const HelmetWrapper: FC<Props> = ({ children, helmet }) => {
  return (
    <>
      {helmet && (
        <Helmet>
          <title>{helmet}</title>
        </Helmet>
      )}
      {children}
    </>
  )
}
