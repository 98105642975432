import { useMutation } from '@tanstack/react-query'
import { AppButton, AppDialog, AppDialogRefType, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useGetParkPins, usePinnedParks, useRolesHelper } from 'hooks'
import { enqueueSnackbar } from 'notistack'
import { FC, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { AddNewParkPayload, ParkSitesApi, RequestNewParkPayloadModel, ShowAlert } from 'sdk'
import { RequestNewParkDialogForm } from './forms/RequestNewParkDialogForm'
import { AddNewParkDialogForm } from './forms/AddNewParkDialogForm'
import { useAppDispatch } from 'store/app.store'
import { setSelectedParkId } from 'store/slices'

interface Props {}

export const NewParkDialogBtn: FC<Props> = () => {
  const parkSitesApi = new ParkSitesApi()
  const dialogRef = useRef<AppDialogRefType>({})
  const dispatch = useAppDispatch()

  const { IsAdmin } = useRolesHelper()
  const { control, setValue, watch, handleSubmit, reset } = useForm<RequestNewParkPayloadModel>()
  const {
    control: addParkControl,
    setValue: addParkSetValue,
    watch: addParkWatch,
    handleSubmit: addParkHandleSubmit,
    reset: addParkReset,
  } = useForm<AddNewParkPayload>()
  const { getParkPins } = useGetParkPins()

  const { mutate: requestNewPark, isPending: isRequesting } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchAllParkLabels],
    mutationFn: () => parkSitesApi.RequestNewParkSite(watch()),
    onSuccess: () => {
      // Do something
      dialogRef.current.closeDialog!()
      enqueueSnackbar('Park request submitted successfully', { variant: 'success' })
      reset()
    },
    onError: () => {
      // Do something
    },
  })
  const { mutate: addParkSite, isPending: isAdding } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchAllParkLabels],
    mutationFn: () => parkSitesApi.addParkSite(addParkWatch()),
    onSuccess: (parkId: string) => {
      // Do something
      addParkReset()
      enqueueSnackbar('New Park Added!', { variant: 'success' })
      getParkPins()
        .then(() => {
          dialogRef.current.closeDialog!()
          ShowAlert('question', `Park successfully created. Would you like to update the park's information?`, {
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
          }).then((x) => {
            if (x.isConfirmed) {
              dialogRef.current.closeDialog!()
              dispatch(setSelectedParkId(parkId))
            } else {
              dialogRef.current.closeDialog!()
            }
          })
        })
        .catch(() => {})
    },
    onError: () => {
      // Do something
    },
  })
  const submit = () => {
    if (IsAdmin()) {
      addParkSite()
    } else {
      requestNewPark()
    }
  }
  return (
    <AppDialog
      title={IsAdmin() ? 'Admin - Add New Park' : 'New Park Request'}
      maxWidth="md"
      ref={dialogRef}
      appDialogWrapperProps={{
        component: 'form',
        role: 'form',
        onSubmit: IsAdmin() ? addParkHandleSubmit(submit) : handleSubmit(submit),
      }}
      submitButtonProps={{ isLoading: isRequesting || isAdding, children: IsAdmin() ? 'Save' : 'Send' }}
      renderElement={(openDialog) => (
        <AppButton
          color="secondary"
          onClick={() => {
            reset()
            addParkReset()
            openDialog()
          }}
          prefixIcon="icon-park-solid:add"
        >
          <AppTypography fontSize={14} fontWeight={500}>
            New Park
          </AppTypography>
        </AppButton>
      )}
    >
      {IsAdmin() ? (
        <AddNewParkDialogForm control={addParkControl} setValue={addParkSetValue} watch={addParkWatch} />
      ) : (
        <RequestNewParkDialogForm control={control} setValue={setValue} watch={watch} />
      )}
    </AppDialog>
  )
}
