import { Outlet } from 'react-router-dom'
import { AppBox } from 'components'

export const Auth = () => {
  return (
    <AppBox minHeight={'100vh'} padding={0} margin={0}>
      <Outlet />
    </AppBox>
  )
}
