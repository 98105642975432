import { Typography, Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppIcon, AppTextField, AppButton, TextWithToolTip, AppTypography } from 'components'
import { TeamStatus, ApiQueryKeys, FormFiledRulesKeys } from 'globals/constants'
import { useGetTeam } from 'hooks'
import { useSnackbar } from 'notistack'
import { useMemo, useEffect } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { TeamApi, TeamNameUpdatePayload } from 'sdk'
import { TeamSliceSelector } from 'store/slices'

const teamApi = new TeamApi()
export const OutSideTeamNameCard = () => {
  const { OwnTeam } = useSelector(TeamSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const { getTeams } = useGetTeam()
  const { control, setValue, setError } = useForm<TeamNameUpdatePayload>({
    mode: 'onChange',
  })

  const isTeamActivated = useMemo(() => OwnTeam?.Status === TeamStatus.Activated, [OwnTeam])

  const {
    data: isTeamNameTakenResp,
    mutate: isTeamTaken,
    isPending: isVerifyingTeamName,
  } = useMutation({
    mutationKey: [ApiQueryKeys.team.IsTeamNameExist],
    mutationFn: teamApi.isTeamNameTaken,
  })

  const { mutate: updateTeamName, isPending: isUpdatingTeamName } = useMutation({
    mutationKey: [ApiQueryKeys.team.UpdateTeamName],
    mutationFn: teamApi.updateTeamName,
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => handleUpdateTeamNameSuccess(),
  })
  const handleUpdateTeamNameSuccess = () => {
    getTeams()
    enqueueSnackbar('Team name updated successfully', { variant: 'success' })
  }
  useEffect(() => {
    if (isTeamNameTakenResp) {
      setError('Name', {
        type: FormFiledRulesKeys.TeamNameTaken,
        message: 'Team name already taken',
      })
    }
  }, [isTeamNameTakenResp, setError])

  useEffect(() => {
    if (isTeamActivated) {
      setValue('Name', OwnTeam!.Name)
    }
    if (OwnTeam) {
      setValue('Id', OwnTeam?.Id)
    }
  }, [OwnTeam, setValue, isTeamActivated])
  return (
    <AppBox
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      border={1}
      padding={3}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
    >
      <TextWithToolTip tooltipText="Your team’s name must be unique to our system. After creating your team you can starting sending invites.">
        <AppTypography fontWeight={700} fontSize={16}>
          Team Name
        </AppTypography>
      </TextWithToolTip>
      <Divider />
      <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
        Provide a unique team name to start building your team. Your team name can not contain empty spaces. After
        you've saved your team name you'll be able to start inviting other outside account users.
      </AppTypography>
      <Form onSubmit={({ data }) => updateTeamName(data)} control={control}>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={9}>
            <AppTextField
              label={'Team Name'}
              name="Name"
              hideDefaultErrorText={false}
              control={control}
              required
              suffixIcon={!isTeamNameTakenResp && !isVerifyingTeamName ? 'icon-park-solid:check-one' : undefined}
              suffixIconProps={{ color: 'green', size: 'medium' }}
              isLoading={isVerifyingTeamName}
              onFieldChange={(e) => isTeamTaken({ Name: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <AppButton type="submit" variant="contained" fullWidth isLoading={isUpdatingTeamName}>
              Save
            </AppButton>
          </Grid>
        </Grid>
      </Form>
    </AppBox>
  )
}
