import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, TableActions } from 'components'
import { FC } from 'react'
import { UserWithSubAccounts } from 'sdk'

interface Props extends AppTableProps<UserWithSubAccounts> {
  onManageClick: (user: UserWithSubAccounts) => void
}
export const ManageUsersTable: FC<Props> = ({ onManageClick, ...props }) => {
  const columns: ColumnDef<UserWithSubAccounts>[] = [
    {
      id: 'popover',
      header: () => <></>,
      cell: ({ row }) => {
        return <TableActions row={row} actions={[{ text: 'Manage', onClick: () => onManageClick(row.original) }]} />
      },
    },
    {
      header: 'Account ID',
      accessorKey: 'AccountId',
    },
    {
      header: 'Name',
      cell: ({ row }) => row.original.FirstName + ' ' + row.original.LastName,
    },
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Phone',
      accessorKey: 'Phone',
    },
    {
      header: 'Account Type',
      accessorKey: 'AccountType',
    },
    {
      header: 'User Type',
      accessorKey: 'UserType',
    },
    {
      header: 'Status',
      cell: ({ row }) => (row.original.Active ? 'Active' : 'Inactive'),
    },
  ]
  return (
    <>
      <AppTable columns={columns} {...props} />
    </>
  )
}
