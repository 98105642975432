import { Grid } from '@mui/material'
import { AppBox, AppButton, AppDialog, AppTypography, PaymentMethods, TextWithToolTip } from 'components'
import { AddPayemntMethodDialog } from '../add-payment-method-dialog/AddPaymentMethodDialog'
import { usePaymentMethods } from 'hooks'

export const PaymentMethodsCard = () => {
  const {
    setDefaultPayment,
    getPaymentMehtods,
    handleDeletePaymentMethod,
    attachPaymentMethod,
    isAddingPaymentMethod,
    isGettingPaymentMethods,
    isDetaching,
    isUpdaingDefaultPayment,
    isGettingDefaultPayments,
  } = usePaymentMethods()

  return (
    <AppBox border={2} borderColor={'grey.200'} borderRadius={6} padding={2} backgroundColor="white">
      <AppBox paddingBottom={1} borderBottom={1} borderColor={'grey.100'}>
        <TextWithToolTip tooltipText="Manage the payment method you use to pay for subscription when something changes.">
          <AppTypography color={'grey.900'} fontSize={16} fontWeight={700}>
            Payment Methods
          </AppTypography>
        </TextWithToolTip>
      </AppBox>
      <Grid container paddingTop={2}>
        <Grid item xs={12} md={8}>
          <PaymentMethods
            setDefaultPayment={setDefaultPayment}
            getPaymentMehtods={getPaymentMehtods}
            handleDeletePaymentMethod={handleDeletePaymentMethod}
            isGettingPaymentMethods={isGettingPaymentMethods}
            isDetaching={isDetaching}
            isUpdaingDefaultPayment={isUpdaingDefaultPayment}
            isGettingDefaultPayments={isGettingDefaultPayments}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppBox display={'flex'} gap={2}>
            <AppDialog
              hideFooterCloseBtn={true}
              hideCancelButton={true}
              hideTitleBottomBorder={true}
              title="Add Payment Method to ParkSitesIO Subscription Plan"
              maxWidth="md"
              titleProps={{ fontSize: 20, fontWeight: 400, textAlign: 'center' }}
              renderChild={(closeDialog) => (
                <AddPayemntMethodDialog
                  closeDialog={closeDialog}
                  attachPaymentMethod={attachPaymentMethod}
                  isAddingPaymentMethod={isAddingPaymentMethod}
                />
              )}
              renderElement={(openDialog) => (
                <>
                  <AppButton size="large" variant="contained" onClick={() => openDialog()}>
                    Add New
                  </AppButton>
                </>
              )}
            />
            <AppButton size="large" variant="outlined">
              Past Invoices
            </AppButton>
          </AppBox>
        </Grid>
      </Grid>
    </AppBox>
  )
}
