import { ChangePasswordForm } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { ChangePasswordPayloadModel } from 'sdk'

interface Props {
  control: Control<ChangePasswordPayloadModel>
}

export const GettingStartedChangePassword: FC<Props> = ({ control }) => {
  return <ChangePasswordForm control={control} />
}
