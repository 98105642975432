import { AnyObject } from 'globals/typings'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CrmContactImportModel } from 'sdk'
import { SetStates } from 'store/slices/common.slice';

type fieldsMapping = Array<{ field: { Id: string; Title: string; isRequired: boolean }; column: string }>
type processedContact = CrmContactImportModel & { ActualRecord?: AnyObject }
export type fieldMapping = {
  contacts: fieldsMapping
  companies: fieldsMapping
  contactTypeColumn: string
  contactType: string
  groupTagColumn: string
}
export const useImportCrmContact = () => {
  const initialMappings: fieldMapping = {
    contacts: [],
    companies: [],
    contactTypeColumn: 'a',
    contactType: '',
    groupTagColumn: '',
  }
  const [fieldMappins, setFieldMappings] = useState<fieldMapping>(initialMappings)
  const [file, setFile] = useState<File>();
  const rawImportedContacts = useState<Array<any>>([]);
  const [isContactNotIncluded, setIsContactNotIncluded] = useState<boolean>(false);
  const [isCompanyNotIncluded, setIsCompanyNotIncluded] = useState<boolean>(false);
  const [goodContacts, setGoodContacts] = useState<Array<CrmContactImportModel>> ();
  const [badContacts, setBadContacts] = useState<Array<CrmContactImportModel>> ();

  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Upload File', 'Contact Type', 'Mapping', 'Summary']
 

  const contactKeys = [
    { Id: 'ContactEmail', isRequired: true, Title: 'Email' },
    { Id: 'ContactFirstName', isRequired: false, Title: 'First Name' },
    { Id: 'ContactLastName', isRequired: false, Title: 'Last Name' },
    { Id: 'ContactPhone', isRequired: false, Title: 'Phone' },
    { Id: 'ContactAltPhone', isRequired: false, Title: 'Alt Phone' },
    { Id: 'ContactAddress', isRequired: false, Title: 'Address' },
    { Id: 'ContactAddress2', isRequired: false, Title: 'Address2' },
    { Id: 'ContactCity', isRequired: false, Title: 'City' },
    { Id: 'ContactStateId', isRequired: false, Title: 'State' },
    { Id: 'ContactZip', isRequired: false, Title: 'Zip' },
    { Id: 'ContactTimeZone', isRequired: false, Title: 'Time Zone' },
    { Id: 'ContactDescription', isRequired: false, Title: 'Description' },
    { Id: 'ContactYoutubeLink', isRequired: false, Title: 'Youtube Link' },
    { Id: 'ContactFacebookLink', isRequired: false, Title: 'Facebook Link' },
    { Id: 'ContactTwitterLink', isRequired: false, Title: 'Twitter Link' },
    { Id: 'ContactLinkedInLink', isRequired: false, Title: 'LinkedIn Link' },
  ]

  const companyKeys = [
    { Id: 'Name', Title: 'Company Name', isRequired: true },
    { Id: 'Address', Title: 'Address', isRequired: false },
    { Id: 'Address2', Title: 'Address2', isRequired: false },
    { Id: 'City', Title: 'City', isRequired: false },
    { Id: 'State', Title: 'State', isRequired: false },
    { Id: 'Zip', Title: 'Zip', isRequired: false },
    { Id: 'PhoneNumber', Title: 'Phone Number', isRequired: false },
    { Id: 'AltPhone', Title: 'Alt Phone', isRequired: false },
    { Id: 'Website', Title: 'Website', isRequired: false },
    { Id: 'TimeZone', Title: 'Time Zone', isRequired: false },
  ]

  const contactColumns = () => {
    const keys: any = []
    // for (const record of this.rawImportedContacts) {
    //     for (const key of Object.keys(record)) {
    //         if (!keys.includes(key)) {
    //             keys.push(key);
    //         }
    //     }
    // }
    // return keys;
  }
  const init = () => {
    setFieldMappings({ contacts: [], companies: [], contactType: '', contactTypeColumn: 'a', groupTagColumn: '' })
    for (const key of companyKeys) {
      setFieldMappings((prev) => ({ ...prev, companies: [...prev.companies, { field: key, column: '' }] }))
    }
    for (const key of contactKeys) {
      setFieldMappings((prev) => ({ ...prev, contacts: [...prev.contacts, { field: key, column: '' }] }))
    }
    SetStates(null)
    // this.isFileUploaded = false
    // this.isCompanyNotIncluded = false
    // this.isContactNotIncluded = false
  }

 

  const isContactTypeSelected = useCallback(() => {
      return !!fieldMappins.contactTypeColumn || !!fieldMappins.contactType;
  },[fieldMappins])

  const nextButtonText = () => {
    switch (activeStep) {
      case 0:
        return 'Upload File'
      case 1:
        return 'Contact Type'
      case 2:
        return 'Mapping'
      case 3:
        return 'Summary'
    }
  }
  const stepOneCompleted =()=> {
    return !!file;
}
const stepTwoCompleted =()=> {
    return isContactTypeSelected();
}
const stepThreeCompleted =()=> {
    return (
        [
            ...fieldMappins.contacts.filter(x => !!x.field.isRequired && !!!x.column),
            ...(!isCompanyNotIncluded
                ? fieldMappins.companies.filter(x => !!x.field.isRequired && !!!x.column)
                : []),
        ].length === 0
    );
}

const isNextDisabled =()=> {
    if (activeStep === 0) {
        return !stepOneCompleted();
    } else if (activeStep === 1) {
        return !stepTwoCompleted();
    } else if (activeStep === 2) {
        return !stepThreeCompleted();
    }
}
const nextClick =() => {
    if (activeStep === 3) {
        // processImportedContacts();
    }
    setActiveStep(activeStep + 1)
}
const backClick = () => {
  setActiveStep(activeStep - 1)
}

  useEffect(() => init(),[])
  return {
    steps,
    activeStep,
    fieldMappins,
    nextButtonText,
    isNextDisabled,
    nextClick,
    backClick,
    setFile,
    setFieldMappings,
  }
}
