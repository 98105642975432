import Swal, { SweetAlertOptions } from 'sweetalert2'

export const ShowAlert = (
  type: 'success' | 'warning' | 'error' | 'info' | 'question',
  message: string,
  options?: SweetAlertOptions
) => {
  return Swal.fire({
    icon: type,
    html: message,
    showCancelButton: true,
    ...options,
  })
}
