import { AppBox, ParkSiteActivityCenterTours, ParkSiteDashboardTours, TopBar } from 'components'
import { useSettings } from 'hooks'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const Account = () => {
  const { loadDefaultSettings } = useSettings()
  useEffect(() => {
    loadDefaultSettings()
  }, [])

  return (
    <AppBox minHeight={'100vh'}>
      <AppBox minHeight={'9vh'}>
        <TopBar />
      </AppBox>
      <AppBox backgroundColor="grey.100">
        <Outlet />
      </AppBox>
    </AppBox>
  )
}
