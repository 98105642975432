import { Icon } from '@iconify/react'
import { Chip, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AppAutoComplete,
  AppBox,
  AppButton,
  AppIcon,
  AppTypography,
  BaseComponent,
  InfoBox,
  ParkTagsSkeletons,
  TagsListLoader,
} from 'components'
import { ApiQueryKeys, InfoBoxSettingTypes, TagTypes } from 'globals/constants'
import { useGetTaggedParks, useTaggedParks } from 'hooks'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { TagApi, TagPayloadModel, UserTaggedParkApi, UserTaggedParkPayloadModel } from 'sdk'
import { ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'

const tagApi = new TagApi()
const taggedParkApi = new UserTaggedParkApi()
export const Parktags = () => {
  const { getTaggedParks } = useGetTaggedParks()
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const { enqueueSnackbar } = useSnackbar()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { data, GetAllTags } = useTaggedParks(TagTypes.PARK_TAG)

  const tags = useMemo(() => {
    if (data) {
      return data?.map((tag) => ({ label: tag.Tag, value: tag.Id }))
    }
  }, [data])
  //
  const { mutateAsync: addTag, isPending: isAddingTag } = useMutation({
    mutationFn: (data: TagPayloadModel) => tagApi.AddTag(data),
    mutationKey: [ApiQueryKeys.tags.AddTag],
    onSuccess: (id: string) => {
      GetAllTags()
      AddParkTag({ TagId: id, ParkId: selectedParkId, UserId: null })
    },
  })
  // Getting Park Tags
  const {
    data: parkTags,
    isPending: isParkTagsLoading,
    mutate: GetParkTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.taggedParks.FetchParkTags],
    mutationFn: () => taggedParkApi.GetParkTags(selectedParkId),
  })

  useEffect(() => {
    GetParkTags()
  }, [SelectedTeam?.Id, selectedParkId])
  //

  // Saving Park Tag
  const handleAddTaggedParkSuccess = () => {
    enqueueSnackbar('Park Tag has Been Added ', { variant: 'success' })
    reset({ ParkId: selectedParkId })
    getTaggedParks()
    GetParkTags()
  }
  const { control, reset, clearErrors, watch } = useForm<UserTaggedParkPayloadModel>({
    mode: 'onChange',
    defaultValues: { ParkId: selectedParkId },
  })
  const { mutate: AddParkTag, isPending: isAddingTaggedPark } = useMutation({
    mutationFn: taggedParkApi.addParkTag,
    mutationKey: [ApiQueryKeys.parksites.taggedParks.AddTaggedPark],
    onSuccess: handleAddTaggedParkSuccess,
    onError: (err) => enqueueSnackbar(err.message ?? 'Error Removing Park Tag', { persist: false, variant: 'error' }),
  })
  //
  // Removing Park Tag
  const { mutate: RemoveParkTag, isPending: isRemovingParkTag } = useMutation({
    mutationFn: (data: { parkId: string; tagId: string }) => taggedParkApi.removeParkTag(data.parkId, data.tagId),
    mutationKey: [ApiQueryKeys.parksites.taggedParks.RemoveParkTag],
    onSuccess: () => handleRemoveTaggedParkSuccess(),
    onError: (err) => enqueueSnackbar(err.message ?? 'Error Removing Park Tag', { variant: 'error' }),
  })
  const handleRemoveTaggedParkSuccess = () => {
    enqueueSnackbar('Park Tag has Been Removed ', { variant: 'success' })
    getTaggedParks()
    GetParkTags()
  }
  //
  const visibleTags = useMemo(() => {
    return tags
      ?.filter((tag) => !parkTags?.some((parkTag) => parkTag.Tag === tag.label))
      .map((x) => {
        return { label: x.label, value: x.value }
      })
  }, [tags, parkTags])

  const [tempTag, setTempTag] = useState<string>('')

  return (
    <BaseComponent isData={true} loaderComponent={<ParkTagsSkeletons />} isLoading={false}>
      <AppBox display={'flex'} flexDirection={'column'} height={'100%'} paddingX={4} justifyContent={'space-between'}>
        <AppBox>
          <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ParkTagsDismissed}>
            Adding tags helps you quickly identify groups of parks by the tags you’ve applied. You can manage all of
            your tags from within your Tagged Parks.
          </InfoBox>
          {!isParkTagsLoading && (
            <Form onSubmit={({ data }) => AddParkTag(data)} control={control}>
              <Grid container paddingY={1} gap={1} marginTop={4} alignItems={'center'}>
                <Grid item md={9}>
                  <AppAutoComplete
                    required={'This field is required'}
                    control={control}
                    label="Select Tag"
                    placeholder="Select Tag"
                    name="TagId"
                    fullWidth
                    hideDefaultErrorText={false}
                    hideNoOptionText={true}
                    options={visibleTags ?? []}
                    isLoading={isAddingTag || isParkTagsLoading}
                    toolTipMessage={
                      'Press enter or click the Add button to add new tag values to your global list of park tags.'
                    }
                    onChange={(value) => {
                      setTempTag(value.target.value.toString())
                      clearErrors()
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        reset({ ParkId: selectedParkId })
                        if (!!tempTag && !tags?.find((x) => x.value === tempTag)) {
                          addTag(new TagPayloadModel({ Tag: tempTag, TagType: TagTypes.PARK_TAG }))
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item md={2} alignContent={'flex-end'}>
                  <AppButton variant="contained" size="large" type="submit" isLoading={isAddingTaggedPark}>
                    <AppTypography fontWeight={'bold'} fontSize={14}>
                      Add
                    </AppTypography>
                  </AppButton>
                </Grid>
              </Grid>
            </Form>
          )}
          <AppBox borderTop={1} borderColor={'grey.200'} paddingBottom={1} paddingY={2}>
            <AppTypography color={'grey.900'} fontSize={14} fontWeight={500}>
              Applied Park Tags
            </AppTypography>
            <AppBox
              marginTop={2}
              backgroundColor="grey.50"
              width={'100%'}
              minHeight={'150px'}
              borderRadius={5}
              border={1}
              borderColor={'grey.200'}
            >
              <AppBox padding={4}>
                {isParkTagsLoading || isRemovingParkTag ? (
                  <TagsListLoader />
                ) : (
                  parkTags?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.Tag}
                      variant="outlined"
                      size="medium"
                      style={{
                        backgroundColor: 'white',
                        color: '#64748B',
                        margin: '4px',
                        fontWeight: 500,
                      }}
                      className="icon"
                      deleteIcon={<Icon fontSize={'8'} icon={'octicon:x-16'} cursor={'pointer'} />}
                      onDelete={() => RemoveParkTag({ tagId: tag.Id, parkId: selectedParkId })}
                    />
                  ))
                )}
              </AppBox>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
    </BaseComponent>
  )
}
