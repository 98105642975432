import { ParksList } from 'components'
import { NoPinnedParks } from 'components/shared/no-data-components'
import { useGetLabelledParks } from 'hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TeamSliceSelector } from 'store/slices'
import { ParkLabelsliceSelector } from 'store/slices/park-labels.slice'

export const PinnedParksListing = () => {
  const { getPinnedParks } = useGetLabelledParks()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { LabelledParks, isLabelledParksLoading } = useSelector(ParkLabelsliceSelector)

  useEffect(() => {
    getPinnedParks()
  }, [SelectedTeam?.Id])

  return (
    <ParksList
      linkedParks={LabelledParks ?? []}
      isLoading={isLabelledParksLoading}
      refetch={getPinnedParks}
      noParksComponent={
        <>
          <NoPinnedParks />
        </>
      }
    />
  )
}
