import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { getFormattedDate, getMDYFormattedDate } from 'globals/functions'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  CrmContactPayload,
  CrmContactsApi,
  CrmContactViewModel,
  ExportCrmContactPayload,
  PaginationFilterModel,
  ShowAlert,
} from 'sdk'
import { FilterSliceSelector, TeamSliceSelector } from 'store/slices'
import * as XLSX from 'xlsx'

interface Props {
  fetchContactsonFilterChange?: boolean
  fetchContactsOnFormSubmit?: boolean
  contactId?: string
}
export const useCrmContacts = ({
  contactId,
  fetchContactsOnFormSubmit = false,
  fetchContactsonFilterChange = false,
}: Props) => {
  const crmContactApi = new CrmContactsApi()

  const dialogRef = useRef<AppDialogRefType>({})
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const {
    CrmContact: { IsUnSavedFilterApplied, AppliedFilter, FilteredData, FilterDataCount },
  } = useSelector(FilterSliceSelector)

  const { enqueueSnackbar } = useSnackbar()

  const [contactAdded, setContactAdded] = useState(false)
  const [contactToUpdate, setContactToUpdate] = useState<CrmContactViewModel | null>(null)
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  const [SelectedContactIds, setSelectedContactIds] = useState<string[]>([])
  const [isAllContactsSelected, setIsAllContactsSelected] = useState(false)
  const [isApplyingFilter, setIsApplyingFilter] = useState(false)

  // Get Contacts
  const {
    data: Contacts,
    isPending: isGetting,
    mutate: GetContacts,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.FetchContacts],
    mutationFn: () => crmContactApi.getContacts(filter),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  useEffect(() => {
    if (fetchContactsonFilterChange && !AppliedFilter && !IsUnSavedFilterApplied) {
      GetContacts()
    }
  }, [filter, SelectedTeam?.Id, AppliedFilter, IsUnSavedFilterApplied])

  const {
    data: contactById,
    isPending: isGettingContactById,
    mutate: getContactById,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.FetchContactById, contactId],
    mutationFn: (_contactId: string) => crmContactApi.GetContactById(_contactId),
    onSuccess: (data) => {
      reset({
        Id: data.Id,
        ContactType: data.ContactType,
        ContactEmail: data.ContactEmail,
        ContactFirstName: data.ContactFirstName,
        ContactLastName: data.ContactLastName,
        ContactAddress: data.ContactAddress,
        ContactAddress2: data.ContactAddress2,
        ContactCity: data.ContactCity,
        ContactStateId: data.ContactStateId,
        ContactZip: data.ContactZip,
        ContactPhone: data.ContactPhone,
        ContactAltPhone: data.ContactAltPhone,
      })
    },
  })
  //
  //Add Contact
  const { control, handleSubmit, reset, setError, setValue, watch } = useForm<CrmContactPayload>({
    mode: 'onChange',
    defaultValues: { ContactType: 'lead', ContactStateId: null },
  })

  const { mutateAsync: AddContact, isPending: isAddingContact } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.AddContact],
    mutationFn: crmContactApi.addContact,
    onSuccess: () => {
      if (fetchContactsOnFormSubmit) {
        GetContacts()
      } else {
        setContactAdded((prev) => !prev)
      }
      dialogRef.current.closeDialog!()
      reset()
      enqueueSnackbar('Contact Added Successfully', { variant: 'success' })
    },
  })
  //

  //   Edit Contact
  const { mutateAsync: EditContact, isPending: isEditing } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.EditContact],
    mutationFn: (data: CrmContactPayload) => crmContactApi.EditContact(data),
    onSuccess: () => {
      if (fetchContactsOnFormSubmit) {
        GetContacts()
      } else {
        setContactAdded((prev) => !prev)
      }
      if (dialogRef.current.closeDialog) {
        dialogRef.current.closeDialog!()
      }
      reset()
      enqueueSnackbar('Contact Edited Successfully', { variant: 'success' })
    },
  })
  //
  //   Delete Contact
  const { mutateAsync: DeleteContactById, isPending: isDeletingContactById } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.DeleteContactById],
    mutationFn: (id: string) => crmContactApi.DeleteContactById(id),
    onSuccess: () => {
      if (fetchContactsOnFormSubmit) {
        GetContacts()
      } else {
        setContactAdded((prev) => !prev)
      }
      enqueueSnackbar('Contact Deleted Successfully', { variant: 'success' })
    },
  })
  const { mutateAsync: DeleteContacts, isPending: isDeletingContacts } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.DeleteContacts],
    mutationFn: (ids: string[]) => crmContactApi.deleteContacts(ids),
    onSuccess: () => {
      if (fetchContactsOnFormSubmit) {
        GetContacts()
      } else {
        setContactAdded((prev) => !prev)
      }
      enqueueSnackbar('Contact Deleted Successfully', { variant: 'success' })
    },
  })
  const { mutateAsync: DeleteAllContacts, isPending: isDeletingAllContacts } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.DeleteContacts],
    mutationFn: () => crmContactApi.deleteAllContacts(),
    onSuccess: () => {
      if (fetchContactsOnFormSubmit) {
        GetContacts()
      } else {
        setContactAdded((prev) => !prev)
      }
      enqueueSnackbar('All Contact Deleted Successfully', { variant: 'success' })
    },
  })
  //
  // Export Contact
  const { mutateAsync: ExportCrmContacts, isPending: isExporting } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.ExportContacts],
    mutationFn: (payload: ExportCrmContactPayload) => crmContactApi.exportContacts(payload),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })
  //

  const handleAddNewContactClick = (linkToParkId?: string) => {
    setContactToUpdate(null)
    reset({
      LinkToParkId: linkToParkId,
    })
    dialogRef.current.openDialog!()
  }

  const handleEditContact = (contact: CrmContactViewModel) => {
    setContactToUpdate(contact)
    reset({
      Id: contact.Id,
      ContactType: contact.ContactType,
      ContactEmail: contact.ContactEmail,
      ContactFirstName: contact.ContactFirstName,
      ContactLastName: contact.ContactLastName,
      ContactAddress: contact.ContactAddress,
      ContactAddress2: contact.ContactAddress2,
      ContactCity: contact.ContactCity,
      ContactStateId: contact.ContactStateId,
      ContactZip: contact.ContactZip,
      ContactPhone: contact.ContactPhone,
      ContactAltPhone: contact.ContactAltPhone,
    })
    dialogRef.current.openDialog!()
  }

  const handleFormSubmit = (data: CrmContactPayload) => {
    if (contactToUpdate !== null) {
      EditContact(data)
    } else {
      AddContact(data)
    }
  }

  const handleShowInfoClick = (contact: CrmContactViewModel) => {
    ShowAlert(
      'info',
      `Originally created on ${getMDYFormattedDate(new Date(contact.Created!))} by ${contact.CreatedByName}

    Last updated on ${getMDYFormattedDate(new Date(contact.Updated!))} by ${contact.UpdatedByName}`,
      { showCancelButton: false }
    )
  }
  const handleExportCrmContacts = (payload: ExportCrmContactPayload) => {
    ExportCrmContacts(payload).then((x) => {
      if (!!x && x.length) {
        let fileName = 'CRM List.xlsx'
        if (payload.ContactType === 'Lead') {
          fileName = 'Contacts-CRM-All-Leads.xlsx'
        } else if (payload.ContactType === 'Contact') {
          fileName = 'Contacts-CRM-All-Contacts.xlsx'
        }
        const data = XLSX.utils.json_to_sheet(x)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        XLSX.writeFile(wb, `${fileName}.xlsx`)
        enqueueSnackbar('Exported Successfully', { variant: 'success' })
      } else {
        enqueueSnackbar('No records found', { variant: 'info' })
      }
    })
  }
  const handleDeleteContactById = (id: string) => {
    ShowAlert('question', 'Are you sure you want to delete this contact?').then((result) => {
      if (result.isConfirmed) {
        DeleteContactById(id)
      }
    })
  }
  const handleDeleteContacts = () => {
    ShowAlert('question', 'Are you sure you want to delete these contacts?').then((result) => {
      if (result.isConfirmed) {
        DeleteContacts(SelectedContactIds)
      }
    })
  }
  const handleDeleteAllContacts = () => {
    ShowAlert('question', 'Are you sure you want to delete all contacts?').then((result) => {
      if (result.isConfirmed) {
        DeleteAllContacts()
      }
    })
  }
  const updatePaginationFilter = (filter: PaginationFilterModel) => {
    setFilter(filter)
  }

  const showableContacts = () => {
    if (IsUnSavedFilterApplied || AppliedFilter) {
      return FilteredData
    }
    return Contacts?.Data ?? []
  }
  const showableContactsCount = () => {
    if (IsUnSavedFilterApplied || AppliedFilter) {
      return FilterDataCount ?? 0
    }
    return Contacts?.TotalCount ?? 0
  }

  return {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    dialogRef,
    isAddingContact,
    handleAddNewContactClick,
    handleEditContact,
    handleShowInfoClick,
    handleFormSubmit,
    contactAdded,
    setContactAdded,
    handleDeleteContactById,
    handleDeleteContacts,
    handleDeleteAllContacts,
    contactToUpdate,
    isGetting,
    isEditing,
    filter,
    updatePaginationFilter,
    SelectedContactIds,
    setSelectedContactIds,
    isAllContactsSelected,
    setIsAllContactsSelected,
    isDeletingContactById,
    isExporting,
    handleExportCrmContacts,
    isApplyingFilter,
    setIsApplyingFilter,
    showableContacts,
    showableContactsCount,
    isDeletingAllContacts,
    isDeletingContacts,
    reset,
    EditContact,
    getContactById,
    isGettingContactById,
    contactById,
  }
}
