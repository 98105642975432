import { AppBox, AppTypography, SimpleCheckBox } from 'components'
import { Dispatch, FC, SetStateAction } from 'react'
interface Props {
  setAgreementAccepted: Dispatch<SetStateAction<boolean>>
}
export const AddSubAccountStepOneTerms: FC<Props> = ({ setAgreementAccepted }) => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={1}>
        <AppTypography color={'grey.500'} fontSize={14}>
          Please read and agree to the following terms regarding adding additional users to your current subscription
          plan.
        </AppTypography>
        <AppBox backgroundColor="grey.100" border={1} borderColor={'grey.300'} borderRadius={6} padding={2}>
          <AppTypography fontWeight={500} fontSize={14} component={'span'}>
            New User Terms -{' '}
            <AppTypography color={'grey.500'} component={'span'} fontSize={14} fontWeight={500}>
              Please see the following terms for adding the new user(s) as a sub-account to your plan:
            </AppTypography>
          </AppTypography>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
            <li>
              As the account owner you are assuming responsible for any and all actions of all your user(s) as it
              applies to ParkSitesIQ.
            </li>
            <li>
              In the event that your user(s) attempt to perform any action we consider malicious within ParkSitesIQ, we
              reserve the right to permanently disable your full account without warning. Furthermore, no refund will be
              provided for any unused portion of your subscription plan.
            </li>
            <li>
              Changes made to your subscription plan will be globally applied to all user(s) within your subscription
              plan.
            </li>
            <li>
              You control how this user fits into your plan from within the Manage Users section of your Billing &
              Plans.
            </li>
            <li>
              As a sub-account to your plan, your user(s) will not be allowed to be invited into other Outside Teams.
            </li>
            <li>
              As a sub-account to your plan, your user(s) will have limited access to the options within their account
              settings.
            </li>
            <li>
              Charges for this user(s) will be pro-rated to synchronize with your current subscription plan’s billing
              cycle.{' '}
            </li>
            <li>
              Any available volume pricing discount this user(s) allow you to qualify for will occur on your first full
              billing cycle.{' '}
            </li>
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} alignItems={'center'}>
          <SimpleCheckBox onClick={(value) => setAgreementAccepted((prev) => !prev)} />
          <AppTypography color={'grey.500'}>
            I acknowledge the terms of adding user(s) to my subscription plan.
          </AppTypography>
        </AppBox>
      </AppBox>
    </>
  )
}
