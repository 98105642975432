import { Drawer } from '@mui/material'
import {
  AppBox,
  AppIcon,
  AppTypography,
  CrmContactLinkedCompaniesHelpModule,
  CrmContactParksHelpModule,
  CrmNotesHelpModule,
} from 'components'
import { CrmContactLinkedtagsHelpModule } from 'components/shared/help-window-components/contacts/CrmContactLinkedTagsHelpModule'
import { CrmHelpModules } from 'globals/constants'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { CrmToolSliceSelector, closeCrmListingHelpModule } from 'store/slices'
import { CrmCompanyContactsHelperModule } from './help-modules/company/CrmCompanyContactsHelperModule'
import { CrmManageTagsHelpModule } from './help-modules/manage-tags/CrmManageTagsHelpModule'

export const CrmListingHelpWindow = () => {
  const { openedCrmListingHelpModule } = useSelector(CrmToolSliceSelector)
  const dispatch = useAppDispatch()

  const mapping: { [key: string]: { componet: ReactNode } } = {
    [CrmHelpModules.ManageTags]: {
      componet: <CrmManageTagsHelpModule />,
    },
    [CrmHelpModules.ContactLinkedCompanies]: {
      componet: <CrmContactLinkedCompaniesHelpModule contactId={openedCrmListingHelpModule?.options.Id!} />,
    },
    [CrmHelpModules.ContactParks]: {
      componet: <CrmContactParksHelpModule contactId={openedCrmListingHelpModule?.options.Id!} />,
    },
    [CrmHelpModules.ContactNotes]: {
      componet: <CrmNotesHelpModule type={'Contact'} contactId={openedCrmListingHelpModule?.options.Id!} />,
    },
    [CrmHelpModules.ContactTags]: {
      componet: <CrmContactLinkedtagsHelpModule contactId={openedCrmListingHelpModule?.options.Id!} />,
    },
    [CrmHelpModules.CompanyContacts]: {
      componet: <CrmCompanyContactsHelperModule companyId={openedCrmListingHelpModule?.options.Id!} />,
    },
    [CrmHelpModules.CompanyNotes]: {
      componet: <CrmNotesHelpModule type={'Company'} companyId={openedCrmListingHelpModule?.options.Id!} />,
    },
  }
  return (
    <>
      {openedCrmListingHelpModule && (
        <Drawer
          anchor="right"
          PaperProps={{ style: { position: 'absolute', width: '25%' } }}
          ModalProps={{
            container: document.getElementById('crm-listing-help-window-Container'),
            style: { position: 'absolute' },
          }}
          variant="persistent"
          open={openedCrmListingHelpModule !== null}
        >
          <AppBox
            backgroundColor="white"
            height={'inherit'}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            paddingX={3}
          >
            <AppBox
              display={'flex'}
              justifyContent={'space-between'}
              borderBottom={1}
              borderColor={'grey.200'}
              paddingY={1}
              paddingTop={1.5}
            >
              <AppTypography fontSize={16} fontWeight={700}>
                {openedCrmListingHelpModule.options.Title}
              </AppTypography>
              <AppIcon
                size="large"
                icon="solar:square-double-alt-arrow-right-linear"
                color="#F44336"
                onClick={() => dispatch(closeCrmListingHelpModule())}
              />
            </AppBox>
            <AppBox overflow={'auto'} paddingBottom={2}>
              {mapping[openedCrmListingHelpModule.key].componet}
            </AppBox>
          </AppBox>
        </Drawer>
      )}
    </>
  )
}
