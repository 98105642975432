import { Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography, EmailField, TextWithToolTip } from 'components'
import { ApiQueryKeys, FormFiledRulesKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ApiErrorModel, RequestUserToJoinTeamPayload, ShowAlert, TeamApi } from 'sdk'
import { TeamSliceSelector } from 'store/slices'

interface Props {
  handleNewInvitationSent: () => void
}
export const OutSideTeamInviteUserCard: FC<Props> = ({ handleNewInvitationSent }) => {
  const teamApi = new TeamApi()
  const { OwnTeam } = useSelector(TeamSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const { control, watch, setError } = useForm<RequestUserToJoinTeamPayload>({
    mode: 'onChange',
  })

  const { mutate: verifyEmail, isPending: verifyingEmail } = useMutation({
    mutationKey: [ApiQueryKeys.account.verifyEmail],
    mutationFn: teamApi.getPaidUserTeamMembersCountByEmail,
    onError: (error: ApiErrorModel) => {
      setError('Email', {
        type: FormFiledRulesKeys.EmailNotFound,
        message: error.message ?? 'Email not found',
      })
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onSuccess: (data: number) => handleVerifyEmailSuccess(data),
  })

  const { mutate: requestUser, isPending: isRequesting } = useMutation({
    mutationKey: [ApiQueryKeys.team.GetUserteamMemersCount],
    mutationFn: teamApi.requestUserToJoinTeam,
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      handleNewInvitationSent()
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' })
    },
  })

  const handleVerifyEmailSuccess = (teamcount: number) => {
    if (teamcount > 0) {
      ShowAlert(
        'question',
        'This user has an existing Outside Team containing additional members. Do you want to send an invite to their entire team or only the user?',
        {
          cancelButtonText: 'Invite this User Only',
          confirmButtonText: 'Invite User and His Team Members',
        }
      ).then((result) => {
        requestUser({ Email: watch().Email, TeamId: OwnTeam?.Id!, AddSubAccounts: result.isConfirmed })
      })
    } else {
      requestUser({ Email: watch().Email, TeamId: OwnTeam?.Id! })
    }
  }
  return (
    <AppBox border={1} padding={3} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
      <TextWithToolTip tooltipText="Invite others that are not related to your account to work in your space by providing their email.">
        <AppTypography fontSize={16} fontWeight={700}>
          Send Invite
        </AppTypography>
      </TextWithToolTip>
      <Divider sx={{ marginBlock: 1 }} />
      <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
        To send an invitation to another person simply enter their email address and click{' '}
        <AppTypography component={'span'} fontWeight={'bold'}>
          send.
        </AppTypography>
      </AppTypography>
      <Form
        control={control}
        onSubmit={({ data }) => {
          verifyEmail(data.Email)
        }}
      >
        <Grid container spacing={2} paddingTop={2} alignItems={'center'}>
          <Grid item xs={9}>
            <EmailField
              control={control}
              label={'Invitee Email'}
              name={'Email'}
              hideDefaultErrorText={false}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <AppButton type="submit" variant="contained" fullWidth isLoading={verifyingEmail || isRequesting}>
              Send
            </AppButton>
          </Grid>
        </Grid>
      </Form>
    </AppBox>
  )
}
