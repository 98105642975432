import { ParkDetailViewModel } from 'sdk'
import {
  FilterCategory,
  FilterConditionLabels,
  FilterConditionTypes,
  FilterFieldType,
  FilterInputValueFrom,
  PreConditionFields,
} from '../FilterTypes'
import { nameof } from 'ts-simple-nameof'

export const CrmContactFilterData: Array<FilterCategory> = [
  {
    Label: 'Personal Information',
    Fields: [
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactType',
        IsDefaultFilter: true,
        Label: 'Contact Type',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            IsDefaultCondition: true,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactFirstName',
        Label: 'First Name',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactLastName',
        Label: 'Last Name',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactEmail',
        Label: 'Email',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactPrimaryRole',
        Label: 'Primary Role',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.PrimaryRoles,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.PrimaryRoles,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactPhone',
        Label: 'Phone Number',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactAltPhone',
        Label: 'Alt Phone Number',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactAddress',
        Label: 'Address',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactAddress2',
        Label: 'Address 2',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactCity',
        Label: 'City',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactStateId',
        Label: 'State',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactZip',
        Label: 'Zip code',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactTimeZone',
        Label: 'Time Zone',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TimeZoneTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TimeZoneTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmContactsTable',
        ColumnName: 'ContactDescription',
        Label: 'Description',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
    ],
  },
  {
    Label: 'Company Information',
    Fields: [
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Name',
        Label: 'Company Name',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Phone',
        Label: 'Phone Number',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'AltPhone',
        Label: 'Alt Phone Number',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Address',
        Label: 'Office Address',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Address2',
        Label: 'Office Address 2',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'City',
        Label: 'City',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'State',
        Label: 'State',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Zip',
        Label: 'Zip code',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'TimeZone',
        Label: 'Time Zone',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TimeZoneTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.TimeZoneTypes,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        ColumnName: 'Website',
        Label: 'Website',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Industry Roles',
    Fields: [
      {
        TableName: 'CrmContactLinkedRoleTagsTable',
        ColumnName: 'Tag',
        Label: 'Tag',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Group Tags',
    Fields: [
      {
        TableName: 'CrmContactLinkedGroupTagsTable',
        ColumnName: 'Tag',
        Label: 'Tag',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Contact Notes',
    Fields: [
      {
        TableName: 'CrmNotes',
        ColumnName: 'Date',
        Label: 'Date',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals_Date,
            Label: FilterConditionLabels.Equals_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal_Date,
            Label: FilterConditionLabels.Does_Not_Equal_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Before_Date,
            Label: FilterConditionLabels.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_After_Date,
            Label: FilterConditionLabels.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
      {
        TableName: 'CrmNotes',
        ColumnName: 'Note',
        Label: 'Note',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Link Ads',
    Fields: [
      {
        TableName: 'CrmContactLinkedAdsTable',
        ColumnName: 'Title',
        Label: 'Ad Title',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedAdsTable',
        ColumnName: 'SecondaryTitle',
        Label: 'Ad Secondary Title',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedAdsTable',
        ColumnName: 'PublishedDate',
        Label: 'Ad Published Date',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals_Date,
            Label: FilterConditionLabels.Equals_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal_Date,
            Label: FilterConditionLabels.Does_Not_Equal_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Before_Date,
            Label: FilterConditionLabels.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_After_Date,
            Label: FilterConditionLabels.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedAdsTable',
        ColumnName: 'StateId',
        Label: 'State',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Linked Park Sites',
    Fields: [
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'City',
        Label: 'City',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Contain,
            Label: FilterConditionLabels.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'County',
        Label: 'County',
        PreConditionFieldTableName: 'ParkSites',
        PreConditionFieldColumnName: nameof<ParkDetailViewModel>((x) => x.State),
        PreConditionField: PreConditionFields.State,
        Conditions: [
          {
            Label: FilterConditionLabels.EqualsWithPreCond,
            Condition: FilterConditionTypes.EqualsWithPreCond,
            IsDefaultCondition: true,
            InputField: {
              Type: FilterFieldType.Counties,
              IsMultiSelect: true,
            },
          },
          {
            Label: FilterConditionLabels.NotEqualsWithPreCond,
            Condition: FilterConditionTypes.NotEqualsWithPreCond,
            InputField: {
              Type: FilterFieldType.Counties,
              IsMultiSelect: true,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'State',
        Label: 'State',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.SimpleStates,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'Zip',
        Label: 'Zip Code',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Contain,
            Label: FilterConditionLabels.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'ParkNo',
        Label: 'Park Id',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.SimpleNumber,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.SimpleNumber,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.SimpleNumber,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Contain,
            Label: FilterConditionLabels.Does_Not_Contain,
            InputField: {
              Type: FilterFieldType.SimpleNumber,
            },
          },
        ],
      },
      {
        TableName: 'CrmContactLinkedParkSitesTable',
        ColumnName: 'Modified',
        Label: 'Last Date Edited',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals_Date,
            Label: FilterConditionLabels.Equals_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal_Date,
            Label: FilterConditionLabels.Does_Not_Equal_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Before_Date,
            Label: FilterConditionLabels.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Condition: FilterConditionTypes.Is_After_Date,
            Label: FilterConditionLabels.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
    ],
  },
]
