import { AppBox, AppIcon, AppTypography } from 'components'

export const SignUpPlans = () => {
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={1} backgroundColor="grey.100" height={'100%'} paddingX={4}>
      <AppTypography fontSize={32} fontWeight={700} marginTop={20}>
        Try us risk free for 7-days
      </AppTypography>
      <AppTypography fontSize={16}>
        Try our system risk free for the next 7 days. After that, simply choose a plan that’s right for you.
      </AppTypography>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Access 30,000 mobile home park records
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Filter parks by size, median income, and more
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Manage your park related contacts
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Track the mobile home parks you own
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          View parcel data for every park
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Invite others to work in your account
        </AppTypography>
      </AppBox>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingY={1}>
        <AppIcon icon={'lets-icons:check-fill'} size="medium"></AppIcon>
        <AppTypography fontSize={16} fontWeight={600}>
          Push your contacts to other apps with Zapier
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
