import { CircularProgress } from '@mui/material'
import { PhoneField } from 'components'
import { AppBox, SimpleField, SimpleRadioGroup } from 'components/base'
import { SimpleDatePicker } from 'components/base/simple-fields/DatePIcker'
import { SimpleCheckBoxGroup } from 'components/base/simple-fields/SimpleCheckBoxGroup'
import { CurrencyField } from 'components/shared/form/Currency/CurrencyField'
import { FilterCondition, FilterFieldType } from 'globals/Filter/FilterTypes'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useCounties, useStates } from 'hooks'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { FC, useEffect, useMemo, useState } from 'react'
import { FilterConditionSaveModel } from 'sdk'

export interface FilterInputFieldsProps {
  onChange: (value: string | string[]) => void
  condition?: FilterCondition | undefined
  filter?: FilterConditionSaveModel
  type: FILTER_PRINCIPAL_TYPES
  PlaceHolder?: string
  preConditionValue?: string
  singleValueOnly?: boolean
}
export const RenderFilterField: FC<FilterInputFieldsProps> = ({
  condition,
  onChange,
  type,
  filter,
  singleValueOnly = false,
  preConditionValue,
  PlaceHolder = 'Enter Value',
}) => {
  const { dropDownItems } = useFilterBuilder({ type, preventFiltersUpdate: true })
  const { isGettingState, States, refetch: fetchStates } = useStates(false)
  const { isPending: loadingCounties, StateCounties, refetch: fetchCounties } = useCounties({})
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (condition?.InputField?.Type === FilterFieldType.States) {
      fetchStates()
    } else if (condition?.InputField?.Type === FilterFieldType.Counties) {
      if (preConditionValue) {
        const stateId = States.find((x) => x.Name === preConditionValue)?.Id
        fetchCounties(stateId!)
      }
    }
  }, [condition, preConditionValue])
  useEffect(() => {
    setSearch('')
  }, [condition?.InputField?.Type])
  const dropDownItemsForCondition = useMemo(() => {
    if (condition?.InputField?.Type === FilterFieldType.States) {
      return States.filter((x) => x.Name.toLowerCase().includes(search.toLowerCase())).map((x) => {
        return { Id: x.Name!, Label: x.Name! }
      })
    } else if (condition?.InputField?.Type === FilterFieldType.Counties) {
      return (
        StateCounties?.filter((x) => x.County.toLowerCase().includes(search.toLowerCase())).map((x) => {
          return { Id: x.County!, Label: x.County! }
        }) ?? []
      )
    } else {
      return dropDownItems(condition!).map((x) => {
        return { Id: x.value!, Label: x.label! }
      })
    }
  }, [States, StateCounties, condition, search])
  useEffect(() => {
    if (filter?.Value) {
      onChange(filter?.Value)
    }
  }, [])
  return (
    <>
      {loadingCounties || isGettingState ? (
        <CircularProgress />
      ) : (
        <AppBox padding={2} maxHeight={'25vh'} style={{ overflowY: 'scroll' }}>
          {condition?.InputField?.Type === FilterFieldType.Text ? (
            <SimpleField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : condition?.InputField?.Type === FilterFieldType.Comma_Separated_Number ? (
            <SimpleField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
              commaSeparated={true}
            />
          ) : condition?.InputField?.Type === FilterFieldType.Phone ? (
            <PhoneField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : condition?.InputField?.Type === FilterFieldType.Number ? (
            <SimpleField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              type="number"
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : condition?.InputField?.Type === FilterFieldType.Zip ? (
            <SimpleField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              mask="99999"
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : condition?.InputField?.Type === FilterFieldType.SimpleNumber ? (
            <SimpleField
              variant="standard"
              label={condition.Label}
              placeholder={PlaceHolder}
              defaultValue={filter?.Value}
              type="number"
              onChange={(e) => onChange(e.target.value)}
            />
          ) : [FilterFieldType.States, FilterFieldType.Counties, FilterFieldType.DropDown].includes(
              condition?.InputField?.Type as FilterFieldType
            ) ? (
            <>
              {[FilterFieldType.States, FilterFieldType.Counties].includes(
                condition?.InputField?.Type as FilterFieldType
              ) && (
                <>
                  <SimpleField
                    variant="standard"
                    label={'Search'}
                    placeholder={PlaceHolder}
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </>
              )}
              {condition?.InputField?.IsMultiSelect && !singleValueOnly ? (
                <SimpleCheckBoxGroup
                  BindLabel={condition.InputField.Type === FilterFieldType.States}
                  orientation="column"
                  values={filter?.Value as string[]}
                  onChange={(value) => {
                    onChange(value)
                  }}
                  options={dropDownItemsForCondition ?? []}
                />
              ) : (
                <SimpleRadioGroup
                  defaultValue={filter?.Value}
                  onChange={(e) => onChange(e)}
                  options={dropDownItemsForCondition ?? []}
                />
              )}
            </>
          ) : condition?.InputField?.Type === FilterFieldType.Date ? (
            <SimpleDatePicker
              label={condition.Label}
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : condition?.InputField?.Type === FilterFieldType.Currency ? (
            <CurrencyField
              label={condition.Label}
              placeholder={PlaceHolder}
              defaultValue={filter?.Value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : null}
        </AppBox>
      )}
    </>
  )
}
