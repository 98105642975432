import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ApiErrorModel,
  CountyApi,
  CountyPayload,
  CountyWithStateViewModel,
  UpdateCountyStatusModel,
  UpdateCountyWebsitePayload,
} from 'sdk'
import { CommonSliceSelector } from 'store/slices/common.slice'
interface Props {
  IsStateNameBind?: boolean
}
export const useCounties = ({ IsStateNameBind }: Props) => {
  const countyApi = new CountyApi()
  const [StateCounties, setStateCounties] = useState<Array<CountyWithStateViewModel>>()
  const { States } = useSelector(CommonSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: getCountyByState, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.county.getCountyByState],
    mutationFn: (data: string) => countyApi.getCountyByState(data),
    onSuccess: (data) => {
      if (data !== StateCounties) {
        setStateCounties(data)
      }
    },
  })

  const {
    mutate: getAllCounties,
    isPending: isGettingAllCounties,
    data: AllCounties,
  } = useMutation({
    mutationKey: [ApiQueryKeys.county.getAllCounties],
    mutationFn: () => countyApi.getCountyByState(),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  const { mutateAsync: AddCounty, isPending: isAddingCounty } = useMutation({
    mutationFn: (data: CountyPayload) => countyApi.addCounty(data),
    mutationKey: [ApiQueryKeys.county.addCounty],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('County added successfully', { variant: 'success' })
    },
  })
  const { mutateAsync: EditCounty, isPending: isEditingCounty } = useMutation({
    mutationFn: (data: CountyPayload) => countyApi.update(data),
    mutationKey: [ApiQueryKeys.county.updateCounty],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('County edited successfully', { variant: 'success' })
      getAllCounties()
    },
  })
  const { mutateAsync: UpdateCountyStatus, isPending: isUpdatingStatus } = useMutation({
    mutationFn: (data: UpdateCountyStatusModel) => countyApi.updateStatus(data),
    mutationKey: [ApiQueryKeys.county.updateCounty],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('County Status Updated successfully', { variant: 'success' })
      getAllCounties()
    },
  })
  const { mutateAsync: UpdateCountyWebsite, isPending: isUpdatingCountyWebsite } = useMutation({
    mutationFn: (data: UpdateCountyWebsitePayload) => countyApi.updateCountyWebsite(data),
    mutationKey: [ApiQueryKeys.county.updateContyWebsite],
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('County WebSite Updated successfully', { variant: 'success' })
      getAllCounties()
    },
  })

  const refetch = (_stateValue: string) => {
    if (!!_stateValue) {
      let stateId = _stateValue
      if (IsStateNameBind) {
        stateId = States.find((x) => x.Name === _stateValue)?.Id!
      }
      getCountyByState(stateId)
    }
  }

  return {
    isPending,
    StateCounties,
    refetch,
    getAllCounties,
    isGettingAllCounties,
    AddCounty,
    isAddingCounty,
    AllCounties,
    EditCounty,
    isEditingCounty,
    UpdateCountyStatus,
    isUpdatingStatus,
    UpdateCountyWebsite,
    isUpdatingCountyWebsite,
  }
}
