import { Grid } from '@mui/material'
import {
  AppBox,
  AppButton,
  AppDialog,
  AppDialogRefType,
  AppMenu,
  FilterBuiler,
  FilterButton,
  SimpleField,
  SimpleValueSelect,
} from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { FC } from 'react'
import { Control, UseFormHandleSubmit, UseFormReset, UseFormSetError, UseFormWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CrmContactPayload, ExportCrmContactPayload, PaginationFilterModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { FilterSliceSelector, setIsCrmContactFilterToolOpened } from 'store/slices'
import { AddUpdateCrmContactForm } from '../content/contacts/add-edit-contact/AddUpdateCrmContactForm'

interface Props {
  control: Control<CrmContactPayload>
  isAddingContact: boolean
  isAllContactsSelected: boolean
  selectedContactIds: string[]
  dialogRef: React.MutableRefObject<AppDialogRefType>
  paginationFilter: PaginationFilterModel
  setIsApplyingFilter: (val: boolean) => void
  setError: UseFormSetError<CrmContactPayload>
  watch: UseFormWatch<CrmContactPayload>
  handleSubmit: UseFormHandleSubmit<CrmContactPayload, undefined>
  handleFormSubmit: (data: CrmContactPayload) => void
  handleQuery: (query: string) => void
  handleTypeChange: (event: string) => void
  handleDeleteAllContacts: () => void
  handleDeleteContacts: (ids: string[]) => void
  handleExportCrmContacts: (payload: ExportCrmContactPayload) => void
  reset: UseFormReset<CrmContactPayload>
}

export const CrmContactActionBar: FC<Props> = ({
  control,
  isAddingContact,
  dialogRef,
  isAllContactsSelected,
  selectedContactIds,
  paginationFilter,
  setIsApplyingFilter,
  setError,
  watch,
  reset,
  handleSubmit,
  handleFormSubmit,
  handleQuery,
  handleTypeChange,
  handleDeleteAllContacts,
  handleDeleteContacts,
  handleExportCrmContacts,
}) => {
  const {
    CrmContact: { IsFilterToolOpened },
  } = useSelector(FilterSliceSelector)
  return (
    <>
      <Grid item xs={12} minHeight={'8vh'}>
        <AppBox minHeight={'100%'} backgroundColor="white" paddingX={4} paddingY={2}>
          <Grid container display={'flex'} gap={1}>
            <AppBox width={150}>
              <SimpleValueSelect
                options={[
                  {
                    value: 'Contact',
                    label: 'Contact',
                  },
                  {
                    value: 'Lead',
                    label: 'Lead',
                  },
                  {
                    value: 'All',
                    label: 'All',
                  },
                ]}
                defaultValue={'All'}
                onChange={(event) => handleTypeChange(event.target.value)}
              />
            </AppBox>
            <AppBox>
              <SimpleField
                prefixIcon="iconamoon:search-bold"
                placeholder="Search Contacts"
                onChange={(event) => handleQuery(event.target.value)}
              />
            </AppBox>

            <AppMenu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              renderElement={(openMenu) => (
                <AppButton
                  color="secondary"
                  prefixIcon="lets-icons:import-fill"
                  suffixIcon="mingcute:down-line"
                  disabled={selectedContactIds.length === 0}
                  onClick={openMenu}
                >
                  Actions
                </AppButton>
              )}
              MenuItems={[
                {
                  text: 'Export',
                  prefixIcon: 'lets-icons:export-fill',
                  onClick: () => handleExportCrmContacts({ Ids: selectedContactIds }),
                },
                {
                  text: 'Export All',
                  prefixIcon: 'lets-icons:export-fill',
                  disabled: !isAllContactsSelected,
                  onClick: () => handleExportCrmContacts({ ContactType: '', Ids: [] }),
                },
                { text: 'Delete', prefixIcon: 'tabler:trash', onClick: () => handleDeleteContacts(selectedContactIds) },
                {
                  text: 'Delete All',
                  prefixIcon: 'tabler:trash',
                  disabled: !isAllContactsSelected,
                  onClick: handleDeleteAllContacts,
                },
              ]}
            />
            <FilterButton type={FILTER_PRINCIPAL_TYPES.CONTACT} setIsApplyingFilter={setIsApplyingFilter} />
            <AppDialog
              ref={dialogRef}
              maxWidth="md"
              title="Add New Contact"
              appDialogWrapperProps={{
                component: 'form',
                role: 'form',
                onSubmit: handleSubmit((data) => handleFormSubmit(data)),
              }}
              submitButtonProps={{ isLoading: isAddingContact }}
              renderElement={(openDialog) => (
                <AppButton
                  color="secondary"
                  size="large"
                  prefixIcon="icon-park-solid:add"
                  onClick={() => {
                    reset({ ContactType: 'lead', ContactStateId: null })
                    openDialog()
                  }}
                >
                  Add New
                </AppButton>
              )}
            >
              <AddUpdateCrmContactForm control={control} setError={setError} watch={watch} />
            </AppDialog>
            {!!IsFilterToolOpened && (
              <Grid item xs={12} minHeight={'2vh'} borderTop={1} borderColor={'grey.300'} maxHeight={''}>
                <AppBox paddingX={3} paddingY={1} height={'100%'} backgroundColor="white" alignItems={'center'}>
                  <FilterBuiler
                    type={FILTER_PRINCIPAL_TYPES.CONTACT}
                    setIsApplyingFilter={setIsApplyingFilter}
                    paginationFilter={paginationFilter}
                  />
                </AppBox>
              </Grid>
            )}
          </Grid>
        </AppBox>
      </Grid>
    </>
  )
}
