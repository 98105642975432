import { Grid } from '@mui/material'
import { AppBox, AppIcon, AppTypography } from 'components'
import { useCrmCompanies } from 'hooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CrmCompanyPayload } from 'sdk'
import { CrmNotes } from '../../crm-notes/CrmNotes'
import { CrmCompanyDetailInfo } from './company-info/CrmCompanyDetailInfo'
import { CrmCompanyLinkedContacts } from './linked-contacts/CrmCompanyLinkedContacts'
import { CrmCompanyDetailLocation } from './location/CrmCompanyLocation'
import { CrmCompanyDetailSideBar } from './side-bar/CrmCompanyDetailSideBar'

export const CrmCompanyDetails = () => {
  const navigate = useNavigate()
  const { companyId } = useParams()

  const { control, reset, watch, setError, companyById, GetCompanyById, isGettingCompanyById, isEditing, EditCompany } =
    useCrmCompanies({
      companyId: companyId!,
    })
  const handleEditCompany = (data: { id: string; data: CrmCompanyPayload }) => {
    EditCompany(data)
      .then(() => GetCompanyById(companyId!))
      .catch(() => {})
  }
  useEffect(() => GetCompanyById(companyId!), [])
  useEffect(() => {
    if (companyById) {
      reset({
        Name: companyById.Name,
        Address: companyById.Address,
        Address2: companyById.Address,
        City: companyById.City,
        State: companyById.State,
        Zip: companyById.Zip,
        Phone: companyById.Phone,
        AltPhone: companyById.AltPhone,
        Website: companyById.Website,
      })
    }
  }, [companyById])

  return (
    <>
      <AppBox display={'flex'} alignItems={'center'} gap={1} backgroundColor="white" padding={2}>
        <AppIcon icon={'mingcute:arrow-left-line'} size="medium" onClick={() => navigate(-1)} />
        <AppTypography fontWeight={700} fontSize={24} color={'grey.900'}>
          {companyById?.Name}
        </AppTypography>
      </AppBox>
      <Grid container spacing={2} padding={3} paddingBottom={8}>
        <Grid item xs={3}>
          <CrmCompanyDetailSideBar company={companyById} />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} rowSpacing={3}>
            <Grid item xs={6}>
              <CrmCompanyDetailInfo
                companyId={companyId!}
                control={control}
                watch={watch}
                setError={setError}
                editCompany={handleEditCompany}
                isEditing={isEditing}
                isGettingCompany={isGettingCompanyById}
              />
            </Grid>
            <Grid item xs={6}>
              <CrmCompanyDetailLocation />
            </Grid>
            <Grid item xs={12}>
              <CrmCompanyLinkedContacts companyId={companyId!} />
            </Grid>
            <Grid item xs={12}>
              <CrmNotes type="Company" companyId={companyId!} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
