import {
  PaginatedApiResponseModel,
  PaginationFilterModel,
  PeopleInsightPayload,
  PeopleInsightToCrmPayload,
  PeopleInsightViewModel,
} from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'

export class PeopleInsightApi extends BaseApi {
  public GetPeopleInsights(parkId: string, filter: PaginationFilterModel) {
    return this.GET_Request<PaginatedApiResponseModel<PeopleInsightViewModel>>(
      apiUrls.peopleInsight.getParkPeopleInsights(parkId, filter)
    )
  }
  public AddPeopleInsight(data: PeopleInsightPayload) {
    return this.POST_Request<PeopleInsightViewModel>(apiUrls.peopleInsight.addParkPeopleInsight(), data)
  }
  public LinkPeopleInsightToPark(peopleInsightId: string, parkId: string) {
    return this.POST_Request(apiUrls.peopleInsight.linkPeopleInsightToPark(peopleInsightId, parkId), {})
  }
  public GetPossiblePeopleInsights(parkId: string, queryKey: string, value: string) {
    return this.GET_Request<Array<PeopleInsightViewModel>>(
      apiUrls.peopleInsight.getPossiblePeopleInsightsWithPark(parkId, queryKey, value)
    )
  }
  public DetachParkPeopleInsight(parkId: string, peopleId: string) {
    return this.DELETE_Request(apiUrls.peopleInsight.detachParkPeopleInsight(parkId, peopleId))
  }
  public EditPeopleInsight(data: PeopleInsightPayload) {
    return this.PUT_Request<PeopleInsightViewModel>(apiUrls.peopleInsight.editParkPeopleInsight(), data)
  }
  public AddPeopleInsightToCrm(data: PeopleInsightToCrmPayload) {
    return this.POST_Request(apiUrls.peopleInsight.addPeopleInsightToCrm(), data)
  }
}
