import { Button, ButtonProps, CircularProgress, TypographyProps } from '@mui/material'
import { AppIcon, AppIconProps } from 'components'

export interface AppButtonProps extends ButtonProps {
  textProps?: TypographyProps
  isLoading?: boolean
  prefixIcon?: string
  suffixIcon?: string
  prefixIconSize?: 'small' | 'medium' | 'large'
  suffixIconSize?: 'small' | 'medium' | 'large'
  prefixIconProps?: Omit<AppIconProps, 'icon'>
  suffixIconProps?: Omit<AppIconProps, 'icon'>
  sidePaddings?: number
  labelTextSize?: number
}

export const AppButton = ({
  isLoading = false,
  textProps,
  prefixIcon,
  suffixIcon,
  prefixIconSize,
  suffixIconSize,
  prefixIconProps,
  suffixIconProps,
  sidePaddings,
  labelTextSize,
  ...props
}: AppButtonProps) => {
  const getLoaderColor = () => {
    if (props.variant === 'contained') {
      return 'inherit'
    }
    if (props.variant === 'outlined') {
      return 'primary'
    }
    return 'inherit'
  }
  return (
    <Button
      {...props}
      onClick={(e) => {
        if (!isLoading && props.onClick) {
          props.onClick(e)
        }
      }}
      startIcon={prefixIcon ? <AppIcon icon={prefixIcon} style={{ cursor: 'pointer' }} {...prefixIconProps} /> : null}
      endIcon={
        suffixIcon ? (
          <AppIcon icon={suffixIcon} size="extra-small" style={{ cursor: 'pointer' }} {...suffixIconProps} />
        ) : null
      }
      sx={{ paddingX: sidePaddings, fontSize: labelTextSize }}
    >
      {isLoading ? (
        <CircularProgress variant="indeterminate" size="1.5rem" color={getLoaderColor()} />
      ) : (
        <>{props.children}</>
      )}
    </Button>
  )
}
