import { useRolesHelper } from 'hooks'
import { useSelector } from 'react-redux'
import { ParkSiteToolSliceSelector } from 'store/slices'
import { PeopleInsightAdminView } from './admin-view/PeopleInsightAdminView'
import { PeopleInsightUserView } from './user-view/PeopleInsightUserView'

export const PeopleInsight = () => {
  const { IsAdmin } = useRolesHelper()
  const { isOpenedActivityAdminViewOpen } = useSelector(ParkSiteToolSliceSelector)
  return <>{IsAdmin() && isOpenedActivityAdminViewOpen ? <PeopleInsightAdminView /> : <PeopleInsightUserView />}</>
}
