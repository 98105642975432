import { StepType } from '@reactour/tour'
import { FC } from 'react'
import { AppTour } from './AppTour'
import { AppTourKeys } from 'globals/constants'
import { AppBox, AppTypography } from 'components/base'
interface Props {}

export const ParkSiteActivityCenterTours: FC<Props> = () => {
  const ParkSiteTourSteps: StepType[] = [
    {
      selector: '.park-sites-activity-tour-step0',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Welcome
          </AppTypography>
          <AppTypography fontSize={14}>
            Welcome to the <b>Activity Center</b>! We’ve designed the <b>Activity Center</b> to be the central hub of
            information for each park. Take our quick tour to learn some key points that also relate to your main
            <b> Dashboard</b> options.
          </AppTypography>
        </AppBox>
      ),
      position: 'center',
    },
    {
      selector: '.park-sites-activity-tour-step1',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Activity Center Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Utilize the <b>Pin Control</b> to customize the park’s map pin color. You can manage the colors and labels
            that display within the drop-down by using your Dashboard’s <b>Pinned Parks</b> option.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-activity-tour-step2',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Activity Center Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            We display the park’s basics here for quick reference. Any time a phone number or email address is displayed
            within our system, we make it clickable so you can use your device’s soft phone and email client.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-activity-tour-step3',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Activity Center Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            When you click any of these buttons within your <b>Activity Center</b>, we display the relative information
            that our staff has uncovered about the park.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-activity-tour-step4',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Activity Center Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            These buttons allow you to manage your personal information about the park. You can quickly reference the
            parks you’ve added information to by utilizing the associated options in your <b>Dashboard</b>.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.park-sites-activity-tour-step5',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Activity Center Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            These buttons allow you to quickly deep dive into outside resources that are directly related to the park.
            This enables you to verify its county records or even inspect the park using <b>Street View</b> in{' '}
            <b>Google Maps</b>.
          </AppTypography>
        </AppBox>
      ),
    },
  ]

  return <AppTour uniqueKey={AppTourKeys.ParkSitesActivityCenterTour} steps={ParkSiteTourSteps} />
}
