import { TypographyProps } from '@mui/material'
import { AppTypography } from 'components'

interface Prop extends TypographyProps {
  phone: string
}
export const PhoneText: React.FC<Prop> = ({ phone, ...rest }) => {
  return (
    <a href={'tel:' + phone} style={{ textDecoration: 'auto' }}>
      <AppTypography {...rest} component={'span'} color={'primary.600'} fontWeight={500}>
        {phone}
      </AppTypography>
    </a>
  )
}
