import { Pagination, Typography } from '@mui/material'
import { FC } from 'react'
import { PaginationFilterModel } from 'sdk'
import { AppBox } from '../box/AppBox'
import { SimpleValueSelect } from '../simple-fields/SimpleValueSelect'
import { AppTypography } from 'components'

export interface AppTablePaginationProps {
  totalRecords?: number
  paginationFilter?: PaginationFilterModel
  onPageSizeChange?: (value: number) => void
  onCurrentPageChange?: (value: number) => void
}
interface Props extends AppTablePaginationProps {
  totalPages: number
}
export const AppPagination: FC<Props> = ({ paginationFilter, onPageSizeChange, onCurrentPageChange, totalPages }) => {
  return (
    <AppBox display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <AppBox display={'flex'} alignItems={'center'} gap={2} paddingTop={1}>
        <AppTypography fontSize={16} fontWeight={500}>
          Show More
        </AppTypography>
        <AppBox minWidth={60}>
          <SimpleValueSelect
            value={paginationFilter!.PageSize}
            options={[
              { value: 10, label: ' 10 Items' },
              { value: 20, label: ' 20 Items' },
              { value: 30, label: ' 30 Items' },
              { value: 40, label: ' 40 Items' },
              { value: 50, label: ' 50 Items' },
            ]}
            onValueChange={(val) => onPageSizeChange!(+val)}
          />
        </AppBox>
      </AppBox>
      <Pagination
        sx={{ float: 'right' }}
        count={totalPages}
        color="primary"
        defaultPage={1}
        size="medium"
        variant="outlined"
        shape="rounded"
        boundaryCount={1}
        siblingCount={0}
        page={paginationFilter!.PageNo!}
        onChange={(value, page) => onCurrentPageChange!(page)}
      />
    </AppBox>
  )
}
