import { Autocomplete, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, InfoBox, SimpleField } from 'components'
import { ApiQueryKeys, InfoBoxSettingTypes, SettingsTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParkPayload, CrmContactLinkedParksApi, CrmContactViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, SetIsParkActivityCrmUpdated, TeamSliceSelector } from 'store/slices'

interface Props {}

export const ParkSiteCrmContactLinkHelpModule: FC<Props> = ({}) => {
  const contactLinkedParksApi = new CrmContactLinkedParksApi()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const [contactIds, setContactIds] = useState<string[]>([])

  const [error, setError] = useState<string>('')
  // Getting All Contacts
  const { mutate, data, isPending } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.FetchUnlinkedParks],
    mutationFn: () => contactLinkedParksApi.GetUnlinkedCrmContactsByParkId(selectedParkId),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })

  // Linking Contacts to Parks
  const { mutate: LinkContacts, isPending: isAdding } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.LinkContactsToPark],
    mutationFn: (data: CrmContactLinkedParkPayload) => contactLinkedParksApi.linkParkToContact(data),
    onSuccess: () => {
      enqueueSnackbar('Contacts linked to park successfully', { variant: 'success' })
      dispatch(SetIsParkActivityCrmUpdated())
      mutate()
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })

  //
  const options = useMemo(() => {
    if (!data) {
      return []
    }
    return data?.map((contact) => ({
      value: contact.Id!,
      label: { ...contact },
    }))
  }, [data])

  const handleSubmit = () => {
    if (contactIds.length === 0) {
      setError('Please select at least one contact')
      return
    }
    setError('')
    LinkContacts({ ParkSiteId: selectedParkId, CrmContactIds: contactIds })
    setContactIds([])
    // Do something with the selected contacts
  }

  useEffect(() => {
    mutate()
  }, [SelectedTeam?.Id])

  const singleOption = (contact: CrmContactViewModel) => (
    <AppBox fontSize={12}>
      {contact.ContactFirstName && <b>{contact.ContactFirstName + ' ' + contact.ContactLastName} - </b>}
      <b>{contact.ContactType}</b>
      <br />
      {contact.ContactPhone && (
        <>
          {contact.ContactPhone} <br />
        </>
      )}
      {contact.ContactAddress && (
        <>
          {contact.ContactAddress} <br />
        </>
      )}
      {contact.ContactEmail}
    </AppBox>
  )
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2}>
      <InfoBox dismissable={true} key={InfoBoxSettingTypes.AddExistingContactDismissed}>
        Add notes for this contact to keep track of important information you may want to remember.
      </InfoBox>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item xs={8}>
          {isAdding ? (
            <SimpleField
              label="Contacts"
              required={true}
              disabled={true}
              suffixIcon="mdi:chevron-down"
              suffixIconProps={{ size: 'large' }}
              hideDefaultErrorText={false}
            />
          ) : (
            <Autocomplete
              style={{ fontSize: 8 }}
              multiple={true}
              popupIcon={<AppIcon size="large" icon="mdi:chevron-down" />}
              getOptionLabel={(option) =>
                option.label.ContactFirstName
                  ? option.label.ContactFirstName +
                    ' ' +
                    option.label.ContactLastName +
                    ' - ' +
                    option.label.ContactType
                  : option.label.ContactEmail
                    ? option.label.ContactEmail + ' - ' + option.label.ContactType
                    : option.label.ContactType!
              }
              size="small"
              options={options ?? []}
              renderOption={(props, option) => <li {...props}>{singleOption(option.label)}</li>}
              onChange={(event, value) => {
                setError('')
                setContactIds(value.map((v) => v.label.Id!))
              }}
              renderInput={(params) => (
                <SimpleField
                  {...params}
                  isLoading={isPending}
                  style={{ marginTop: 0 }}
                  placeholder="Select Contacts"
                  label="Contacts"
                  required
                  hideDefaultErrorText={false}
                  errorText={error}
                  ref={params.InputProps.ref}
                />
              )}
            />
          )}
        </Grid>
        <Grid item>
          <AppButton variant="contained" sidePaddings={4} onClick={handleSubmit} isLoading={isAdding}>
            Save
          </AppButton>
        </Grid>
      </Grid>
    </AppBox>
  )
}
