import { Grid } from '@mui/material'
import { AppButton, AppDialog, AppDialogRefType, SimpleField } from 'components'
import { useNewsAlert } from 'hooks'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { NewsAlertPayload, PaginationFilterModel } from 'sdk'
import { AddEditNewsAlertForm } from '../content/news_alerts/add-edit-news-alert-form/AddEditNewsAlertForm'
interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const NewAlertsActions: FC<Props> = ({ filter, setFilter }) => {
  const { control, handleSubmit, getValues, reset } = useForm<NewsAlertPayload>({
    defaultValues: { StartDate: new Date(), ArticleDate: new Date() },
  })
  const dialogRef = useRef<AppDialogRefType>(null)
  const { AddNewsAlert, isAdding } = useNewsAlert()
  return (
    <>
      <Grid container spacing={4} alignItems={'center'}>
        <Grid item md={2} xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search News"
            value={filter.Query ?? ''}
            onChange={(event) => {
              setFilter((prev) => ({ ...prev, PageNo: 1, Query: event.target.value }))
            }}
          />
        </Grid>
        <Grid item>
          <Grid item>
            <AppDialog
              maxWidth="sm"
              ref={dialogRef}
              title="Add News Alert"
              appDialogWrapperProps={{
                component: 'form',
                role: 'form',
                onSubmit: handleSubmit(() => {
                  AddNewsAlert(getValues())
                    .then((x) => {
                      dialogRef.current?.closeDialog!()
                      reset()
                    })
                    .catch((err) => {})
                }),
              }}
              submitButtonProps={{ isLoading: isAdding }}
              renderElement={(openDialog) => (
                <AppButton prefixIcon="icon-park-solid:add" color="secondary" onClick={openDialog}>
                  Add New asd
                </AppButton>
              )}
              renderChild={(openDialog) => <AddEditNewsAlertForm control={control} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
