import { ColumnDef } from '@tanstack/react-table'
import { AppBox, AppIcon, AppTable, AppTableProps, TableActions } from 'components'
import { FC } from 'react'
import { PeopleInsightViewModel } from 'sdk'

interface Props extends AppTableProps<PeopleInsightViewModel> {
  onUseClick: (row: PeopleInsightViewModel) => void
}

export const PeopleInsightAdminAddPossibleMatches: FC<Props> = ({ onUseClick, ...props }) => {
  const columns: ColumnDef<PeopleInsightViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return <TableActions row={row} actions={[{ text: 'Use Instead', onClick: () => onUseClick(row.original) }]} />
      },
    },

    {
      header: 'Company',
      cell: ({ row }) => (
        <AppBox display={'flex'} alignItems={'center'}>
          {row.original.RelatedParks.length > 0 && (
            <AppIcon
              color="blue"
              onClick={() => row.toggleExpanded()}
              icon={row.getIsExpanded() ? 'ic:twotone-minus' : 'ic:twotone-plus'}
            />
          )}
          {row.original.CompanyName}{' '}
        </AppBox>
      ),
      accessorKey: 'CompanyName',
    },
    {
      header: 'Name',
      cell: ({ row }) => row.original.FirstName + ' ' + row.original.LastName,
    },
    {
      header: 'Address',
      accessorKey: 'Address',
    },
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Phone',
      accessorKey: 'Phone',
    },
    {
      header: 'Alt Phone',
      accessorKey: 'AltPhone',
    },
  ]
  return <AppTable columns={columns} {...props} />
}
