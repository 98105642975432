import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CrmHelpModules, ParkSitesTools } from 'globals/constants'
import { IAppStore } from 'store/app.store'

export interface ParkSiteTools {
  openedTool: ParkSitesTools | undefined
  toolOpenedTab: 'list' | 'manage'
  isFilterToolOpened: boolean
  selectedParkId: string
  openedActivity: string
  isOpenedActivityAdminViewOpen: boolean
  activityWidth: string | null
  openedParkSiteHelpModule: { key: CrmHelpModules; options: { Title: string; Id?: string } } | null
  parkGetActivityCenterInfoCount: number
}

const initialState: ParkSiteTools = {
  openedTool: undefined,
  toolOpenedTab: 'list',
  isFilterToolOpened: false,
  selectedParkId: '',
  openedActivity: '',
  isOpenedActivityAdminViewOpen: false,
  activityWidth: null,
  openedParkSiteHelpModule: null,
  parkGetActivityCenterInfoCount: 0,
}

export const ParkSiteToolsSlice = createSlice({
  name: 'ParkSiteTools',
  initialState: initialState,
  reducers: {
    closeTool: (state: ParkSiteTools) => {
      state.openedTool = undefined
      state.toolOpenedTab = 'list'
    },
    setTool: (state: ParkSiteTools, action) => {
      state.openedTool = action.payload
      state.selectedParkId = ''
    },
    setToolOpenedTab: (state: ParkSiteTools, action: PayloadAction<'list' | 'manage'>) => {
      state.toolOpenedTab = action.payload
    },
    setIsFilterToolOpened: (state: ParkSiteTools, action) => {
      state.isFilterToolOpened = action.payload
    },
    unSelectedParkId: (state: ParkSiteTools) => {
      state.selectedParkId = ''
    },
    setSelectedParkId: (state: ParkSiteTools, action) => {
      state.selectedParkId = action.payload
      // state.openedTool = ''
    },
    setOpenedActivity: (state: ParkSiteTools, action) => {
      state.openedActivity = action.payload
    },
    closeActivity: (state: ParkSiteTools) => {
      state.openedActivity = ''
      state.isOpenedActivityAdminViewOpen = false
    },
    setOpenedActivityAdminViewOpen: (state: ParkSiteTools, action) => {
      state.isOpenedActivityAdminViewOpen = action.payload
    },
    setOpenedActivityWidth: (state: ParkSiteTools, action) => {
      state.activityWidth = action.payload
    },
    closeParkSiteHelpModule: (state: ParkSiteTools) => {
      state.openedParkSiteHelpModule = null
    },
    setOpenedParkSiteHelpModule: (state: ParkSiteTools, action) => {
      state.openedParkSiteHelpModule = action.payload
    },
    setParkGetActivityCenterInfoCount: (state: ParkSiteTools) => {
      state.parkGetActivityCenterInfoCount += 1
    },
  },
})

export const {
  closeTool,
  setTool,
  setToolOpenedTab,
  unSelectedParkId,
  setSelectedParkId,
  setIsFilterToolOpened,
  setOpenedActivity,
  setOpenedActivityAdminViewOpen,
  closeActivity,
  setOpenedActivityWidth,
  closeParkSiteHelpModule,
  setOpenedParkSiteHelpModule,
  setParkGetActivityCenterInfoCount,
} = ParkSiteToolsSlice.actions
export const ParkSiteToolSliceSelector = (appState: IAppStore): ParkSiteTools => appState.ParkSiteTools
