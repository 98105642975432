import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, SettingsTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { SettingApi, SettingPayload } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { SetInfoBoxSettings, SetIsLoadingDefaultSettings } from 'store/slices/common.slice'

export const useSettings = () => {
  const settingsApi = new SettingApi()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const { mutateAsync: getSettings, isPending: isGetting } = useMutation({
    mutationKey: [ApiQueryKeys.settings.FetchSettings],
    mutationFn: (keys: SettingsTypes[]) => settingsApi.getAll(keys),
    onError: () => enqueueSnackbar('Error Fetching Settings', { variant: 'error' }),
    // onSuccess: () => enqueueSnackbar('Settings Fetched Successfully', { variant: 'success' }),
  })
  const { mutateAsync: UpdateSettings, isPending: isUpdating } = useMutation({
    mutationKey: [ApiQueryKeys.settings.UpdateSettings],
    mutationFn: (settings: SettingPayload[]) => settingsApi.update(settings),
    onError: () => enqueueSnackbar('Error Updating Settings', { variant: 'error' }),
    // onSuccess: () => enqueueSnackbar('Settings Updated Successfully', { variant: 'success' }),
  })
  const { mutate: ResetHints, isPending: isResetingHints } = useMutation({
    mutationKey: ['reset-hints'],
    mutationFn: () => settingsApi.resetHints(),
    onError: () => {
      enqueueSnackbar('Error updating settings', { variant: 'error' })
    },
    onSuccess: () => {
      loadDefaultSettings()
      enqueueSnackbar('Hints reset successfully', { variant: 'success' })
    },
  })
  const { mutateAsync: ResetTours, isPending: isResetingTours } = useMutation({
    mutationKey: ['reset-tours'],
    mutationFn: () => settingsApi.resetTours(),
    onError: () => {
      enqueueSnackbar('Error updating settings', { variant: 'error' })
    },
    onSuccess: () => {
      loadDefaultSettings()
      enqueueSnackbar('Tours reset successfully', { variant: 'success' })
    },
  })
  const loadDefaultSettings = () => {
    dispatch(SetIsLoadingDefaultSettings(true))
    getSettings([SettingsTypes.INFO_BOX_SETTINGS, SettingsTypes.TOUR_SETTINGS])
      .then((res) => {
        dispatch(SetInfoBoxSettings(res))
        dispatch(SetIsLoadingDefaultSettings(false))
      })
      .catch(() => {
        dispatch(SetIsLoadingDefaultSettings(false))
      })
  }
  return {
    isGetting,
    isUpdating,
    isResetingHints,
    getSettings,
    UpdateSettings,
    ResetHints,
    loadDefaultSettings,
    ResetTours,
    isResetingTours,
  }
}
