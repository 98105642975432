import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppTourKeys } from 'globals/constants'
import { SettingViewModel, StateViewModel } from 'sdk'
import { IAppStore } from 'store/app.store'

interface CommonSliceType {
  States: StateViewModel[]
  Settings: SettingViewModel[]
  isLoadingDefaultSettings: boolean
  currentTourKey?: AppTourKeys
}

const CommonSliceInitialState: CommonSliceType = {
  States: [],
  Settings: [],
  isLoadingDefaultSettings: true,
  currentTourKey: undefined,
}

export const CommonSlice = createSlice({
  name: 'Common',
  initialState: CommonSliceInitialState,
  reducers: {
    SetStates: (state, action) => {
      state.States = action.payload
    },
    SetInfoBoxSettings: (state, action: PayloadAction<SettingViewModel[]>) => {
      state.Settings = action.payload
    },
    SetTourSettings: (state, action: PayloadAction<SettingViewModel[]>) => {
      state.Settings = action.payload
    },
    SetIsLoadingDefaultSettings: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDefaultSettings = action.payload
    },
    setCurrentTourKey: (state, action: PayloadAction<AppTourKeys | undefined>) => {
      state.currentTourKey = action.payload
    },
  },
})
export const { SetStates, SetInfoBoxSettings, SetTourSettings, SetIsLoadingDefaultSettings, setCurrentTourKey } =
  CommonSlice.actions
export const CommonSliceSelector = (appState: IAppStore): CommonSliceType => appState.Common
