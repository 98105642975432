import { appRoutes } from 'globals/constants'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearSession, clearTeamData, clearGoogleMapsData } from 'store/slices'

export const useLogout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = () => {
    dispatch(clearSession())
    dispatch(clearTeamData())
    dispatch(clearGoogleMapsData())
    navigate(appRoutes.login.path)
  }
  return { logout }
}
