import { Grid, IconButton } from '@mui/material'
import {
  AppBox,
  AppButton,
  AppDialog,
  AppIcon,
  AppMenu,
  AppTypography,
  InfoBox,
  ParkNotesLoader,
  SimpleField,
} from 'components'
import { AddEditNoteForm } from 'components/shared/add-edit-note-form/AddEditNoteForm'
import { InfoBoxSettingTypes } from 'globals/constants'
import { getFormattedDate, getMDYFormattedDate } from 'globals/functions'
import { useParkParkNotes } from 'hooks'
import { ParkNoteViewModel, ShowAlert } from 'sdk'
export const ParkNotes = () => {
  const {
    control,
    watch,
    handleSubmit,
    isPending,
    isDeleting,
    isGettinNotes,
    parkNotes,
    dialogRef,
    handleEdit,
    handleDelete,
    submitForm,
    reset,
    handleSearch,
  } = useParkParkNotes()
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2} height={'100%'} paddingX={4}>
        <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ParkNotesDismissed}>
          The notes you or your team keep here are specific to this park and also searchable using the Park Filter
        </InfoBox>
        <Grid container justifyContent={'space-between'}>
          <Grid item md={3}>
            <SimpleField fullWidth prefixIcon="iconamoon:search-bold" placeholder="Search" onChange={handleSearch} />
          </Grid>
          <Grid item md={1}>
            <AppDialog
              ref={dialogRef}
              title={watch().Id ? 'Edit Park Note' : 'Add Park Note'}
              appDialogWrapperProps={{
                component: 'form',
                role: 'form',
                onSubmit: handleSubmit(submitForm),
              }}
              submitButtonProps={{
                isLoading: isPending,
              }}
              renderElement={(openDialog) => (
                <AppButton
                  variant="contained"
                  size="large"
                  prefixIcon="ic:baseline-plus"
                  onClick={() => {
                    reset()
                    openDialog()
                  }}
                >
                  <AppTypography fontWeight={'bold'} fontSize={14}>
                    Add
                  </AppTypography>
                </AppButton>
              )}
              renderChild={(openDialog) => <AddEditNoteForm control={control} />}
            />
          </Grid>
        </Grid>

        {isGettinNotes || isDeleting ? (
          <ParkNotesLoader />
        ) : (
          <AppBox>
            {!!parkNotes && parkNotes?.length > 0 ? (
              <>
                <AppBox display={'flex'} flexDirection={'column'} gap={2}>
                  {parkNotes?.map((note: ParkNoteViewModel) => (
                    <AppBox
                      key={note.Id}
                      paddingY={2}
                      borderBottom={1}
                      borderColor={'grey.200'}
                      backgroundColor="grey.50"
                      borderRadius={3}
                    >
                      <AppBox paddingX={2} paddingY={1}>
                        <AppBox
                          display={'flex'}
                          justifyContent={'space-between'}
                          borderBottom={1}
                          borderColor={'grey.200'}
                          paddingY={1}
                        >
                          <AppTypography fontSize={14} fontWeight={600}>
                            {note.CreatedByName} {getFormattedDate(new Date(note.Date!))}
                          </AppTypography>
                          <AppMenu
                            renderElement={(handleClick) => (
                              <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                                <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                              </IconButton>
                            )}
                            MenuItems={[
                              {
                                text: 'Info',
                                onClick: () =>
                                  ShowAlert(
                                    'info',
                                    `Originally created on ${getMDYFormattedDate(new Date(note.Created!))} by ${note.CreatedByName}
                          Last updated on ${getMDYFormattedDate(new Date(note.Updated!))} by ${note.UpdatedByName}`,
                                    { showCancelButton: false }
                                  ),
                              },
                              { text: 'Edit', onClick: () => handleEdit(note) },
                              { text: 'Delete', onClick: () => handleDelete(note.Id!) },
                            ]}
                          />
                        </AppBox>
                        <AppTypography paddingTop={1}>{note.Note}</AppTypography>
                      </AppBox>
                    </AppBox>
                  ))}
                </AppBox>
              </>
            ) : (
              <AppBox width={'100%'} textAlign={'center'} paddingTop={2}>
                <AppTypography color={'grey.500'} fontSize={20} fontWeight={500}>
                  Click <b>+Add</b> to create a note specific to this park record
                </AppTypography>
              </AppBox>
            )}
          </AppBox>
        )}
      </AppBox>
    </>
  )
}
