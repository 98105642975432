import { BaseInputProps } from 'globals/typings/baseComponentTypes/BaseComponentTypes'
import { getValidationRules } from 'plugins/helper/helper'
import { Controller } from 'react-hook-form'
import { AppBox } from '../box/AppBox'
import { AppSimpleTextAreaProps, SimpleTextArea } from '../simple-fields/SimpleTextArea'

export interface AppTextAreaProps extends BaseInputProps, AppSimpleTextAreaProps {}

export const AppTextArea = ({ required, control, customRules, label, LabelProps, ...props }: AppTextAreaProps) => {
  return (
    <>
      <Controller
        name={props.name}
        defaultValue={props.defaultValue ?? ''}
        control={control}
        rules={{
          required: required,
          validate: (value) => getValidationRules(value, props.name, customRules ?? {}),
        }}
        render={({ fieldState: { error }, field }) => (
          <AppBox display="flex" flexDirection="column">
            <SimpleTextArea
              label={label}
              required={required}
              LabelProps={LabelProps}
              size="small"
              error={!!error}
              errorText={error?.message?.toString() ?? ''}
              {...props}
              {...field}
            />
          </AppBox>
        )}
      />
    </>
  )
}
