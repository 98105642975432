import { Typography } from '@mui/material'
import { ColumnDef, Row } from '@tanstack/react-table'
import { AppBox, AppIcon, AppTable, AppTableProps, AppTypography, EmailText, PhoneText, TableActions } from 'components'
import { ParkSiteHelpingModules } from 'globals/constants'
import { CrmContactViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { setOpenedParkSiteHelpModule } from 'store/slices'

interface Props extends AppTableProps<CrmContactViewModel> {
  onInfoClick: (contact: CrmContactViewModel) => void
  onDetachFromParkClick: (contact: CrmContactViewModel) => void
  onDeleteClick: (id: string) => void
  onEditClick: (contact: CrmContactViewModel) => void
}

export const ParkActivityCrmTable = ({
  onInfoClick,
  onDetachFromParkClick,
  onDeleteClick,
  onEditClick,
  ...props
}: Props) => {
  const dispatch = useAppDispatch()

  const columns: ColumnDef<CrmContactViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Info', onClick: () => onInfoClick(row.original) },
              { text: 'Detach From Park', onClick: () => onDetachFromParkClick(row.original) },
              { text: 'Delete', onClick: () => onDeleteClick(row.original.Id!) },
            ]}
          />
        )
      },
    },
    {
      header: 'Type',
      accessorKey: 'ContactType',
    },
    {
      header: 'Name',
      cell: ({ row }) => {
        return (
          <AppTypography
            component={'a'}
            onClick={() => onEditClick(row.original)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'blue',
              },
            }}
          >
            {row.original.ContactFirstName + ' ' + row.original.ContactLastName}
          </AppTypography>
        )
      },
    },
    {
      header: 'Email',
      accessorKey: 'ContactEmail',
      cell: ({ row }) => {
        return <EmailText email={row.original.ContactEmail ?? ''} />
      },
    },
    {
      header: 'Phone',
      accessorKey: 'Phone',
      cell: ({ row }) => {
        return <PhoneText phone={row.original.ContactPhone ?? ''} />
      },
    },
    {
      header: 'Alt Phone',
      accessorKey: 'ContactAltPhone',
      cell: ({ row }) => {
        return <PhoneText phone={row.original.ContactAltPhone ?? ''} />
      },
    },

    ...[
      { header: 'Parks', icon: 'gis:home', helpModule: ParkSiteHelpingModules.ContactParks },
      { header: 'Companies', icon: 'mdi:company', helpModule: ParkSiteHelpingModules.ContactLinkedCompanies },
      { header: 'Notes', icon: 'icon-park-solid:notes', helpModule: ParkSiteHelpingModules.ContactNotes },
      { header: 'Tags', icon: 'bi:tags-fill', helpModule: ParkSiteHelpingModules.ContactTags },
    ].map((x) => {
      return {
        header: x.header,
        cell: ({ row }: { row: Row<CrmContactViewModel> }) => {
          return (
            <>
              <AppBox color={'primary.200'} justifyContent={'center'}>
                <AppIcon
                  icon={x.icon}
                  size="medium"
                  onClick={() =>
                    dispatch(
                      setOpenedParkSiteHelpModule({
                        key: x.helpModule,
                        options: {
                          Title: row.original.ContactFirstName + ' ' + row.original.ContactLastName,
                          Id: row.original.Id!,
                        },
                      })
                    )
                  }
                />
              </AppBox>
            </>
          )
        },
      }
    }),
  ]
  return <AppTable columns={columns} alignColumns="center" {...props} />
}
