import { Divider, Grid, Typography } from '@mui/material'
import { AddContactCompany, AppBox, AppButton, AppTypography } from 'components'
import { useCrmContactCompanies } from 'hooks'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CrmContactLinkedCompaniesListing } from './listing/CrmContactLinkedCompaniesListing'

export const CrmContactLinkedCompanies = () => {
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)
  const { contactId } = useParams()
  const {
    linkCompany,
    handleUnlinkClick,
    linkedCompanis,
    unLinkedCompanies,
    isLoadingLinkedCompanies,
    isLoadingUnLinkedLinkedCompanies,
    isAddingLinkedCompany,
  } = useCrmContactCompanies({ contactId: contactId! })

  return (
    <AppBox
      border={1}
      paddingX={4}
      paddingY={2}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Companies"
    >
      <Grid container alignItems={'center'} paddingBottom={2}>
        <Grid item md={9}>
          <AppTypography fontWeight={700} fontSize={16}>
            Companies
          </AppTypography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          {!isAddClicked ? (
            <AppButton variant="contained" prefixIcon="tabler:plus" size="large" onClick={() => setIsAddClicked(true)}>
              Add
            </AppButton>
          ) : (
            <AddContactCompany
              contactId={contactId!}
              linkCompany={(data) =>
                linkCompany(data)
                  .then((x) => {
                    setIsAddClicked(false)
                  })
                  .catch(() => {})
              }
              isAddingLinkedCompany={isAddingLinkedCompany}
              isLoadingUnLinkedLinkedCompanies={isLoadingUnLinkedLinkedCompanies}
              unLinkedCompanies={unLinkedCompanies ?? []}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <AppBox paddingY={4}>
        <CrmContactLinkedCompaniesListing
          data={linkedCompanis?.Data ?? []}
          isLoading={isLoadingLinkedCompanies}
          onDeleteClick={handleUnlinkClick}
        />
      </AppBox>
    </AppBox>
  )
}
