import { LinearProgress } from '@mui/material'
import { useMap } from '@vis.gl/react-google-maps'
import { AppBox, GoogleMap } from 'components'
import { useRolesHelper } from 'hooks'
import { useRef } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { closeActivity, closeTool, GoogleMapSliceSelector, setSelectedParkId, unSelectedParkId } from 'store/slices'
import { ParkLabelsliceSelector } from 'store/slices/park-labels.slice'
import { ParkTagsliceSelector } from 'store/slices/park-tags.slice'
import {
  ChangeLocationDialogRef,
  ParkSitesGoogleMapChangeLocationDialog,
} from './dialogs/ParkSitesGoogleMapChangeLocationDialog'
import { ParkSiteGoogleMapsAdminDraggableMarkerInfoxBox } from './info-box/ParkSiteGoogleMapAdminDraggableMarkerInfoBox'
import { ParkSitesGoogleMapAdminInfoBox } from './info-box/ParkSitesGoogleMapAdminInfoBox'
import { createRoot } from 'react-dom/client'
import { AppGoogleMapUniqueKeys } from 'globals/constants'

export const ParkSitesGoogleMaps = () => {
  const { isLoadingMap } = useSelector(GoogleMapSliceSelector)
  const { isLabelledParksLoading } = useSelector(ParkLabelsliceSelector)
  const { isTaggedParksLoading } = useSelector(ParkTagsliceSelector)
  const { IsAdmin } = useRolesHelper()
  const dispatch = useAppDispatch()
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)

  const changeLocationDialogRef = useRef<ChangeLocationDialogRef | null>(null)

  const handleMarkerClick = (parkId: string) => dispatch(setSelectedParkId(parkId))

  const handleMarkerRightClick = (parkId: string, marker: google.maps.Marker) => {
    if (IsAdmin()) {
      const newPosition = marker.getPosition()
      const container = document.createElement('div')

      const root = createRoot(container)
      root.render(<ParkSitesGoogleMapAdminInfoBox parkId={parkId} marker={marker} />)

      const infoWindow = new google.maps.InfoWindow({
        content: container,
        position: newPosition,
      })
      dispatch(closeActivity(undefined))
      dispatch(closeTool())
      dispatch(unSelectedParkId())
      infoWindow.open(map, marker)
    }
  }
  const handleDraggableMarkerRightClick = (marker: google.maps.Marker) => {
    const newPosition = marker.getPosition()
    const container = document.createElement('div')
    const root = createRoot(container)
    root.render(<ParkSiteGoogleMapsAdminDraggableMarkerInfoxBox marker={marker} />)

    const infoWindow = new google.maps.InfoWindow({
      content: container,
      position: newPosition,
    })
    infoWindow.open(map, marker)
  }

  return (
    <>
      {(isLoadingMap || isLabelledParksLoading || isTaggedParksLoading) && <LinearProgress color="primary" />}
      <GoogleMap
        onMarkerClick={handleMarkerClick}
        onMarkerRightClick={handleMarkerRightClick}
        onDraggableMarkerRightClick={handleDraggableMarkerRightClick}
        onDraggableMarkerDragEnd={(marker, position) =>
          changeLocationDialogRef.current?.onDragEndCallback!(marker, position)
        }
      />
      <ParkSitesGoogleMapChangeLocationDialog ref={changeLocationDialogRef} />
    </>
  )
}
