import { Grid } from '@mui/material'
import { AppBox, AppButton, AppTypography } from 'components'
import { ProfileModules } from 'globals/constants'
import { FC } from 'react'
import { useAppDispatch } from 'store/app.store'
import { setOpenedProfileModule } from 'store/slices'
interface Props {
  closeDialog: () => void
  setPromoShown: (value: boolean) => void
}
export const SuBAccountBundlePromotion: FC<Props> = ({ closeDialog, setPromoShown }) => {
  const dispatch = useAppDispatch()
  const handleOutsideTeam = () => {
    closeDialog()
    dispatch(setOpenedProfileModule(ProfileModules.OutsideTeam))
  }
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <AppTypography fontSize={14} color={'grey.600'}>
          Add users to your plan for one bill by clicking <b>Continue</b>. Or, create an <b>Outside Team</b> and invite
          other account owners to your space for no extra cost.
        </AppTypography>
        <AppBox>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img src="/images/pax/AddSubAccountPricing.png" alt="user" />
            </Grid>
            <Grid item xs={8}>
              <AppBox display={'flex'} gap={2}>
                <AppBox>
                  <AppBox borderRadius={6} border={2} borderColor={'grey.300'} paddingX={3} paddingY={1}>
                    <AppTypography fontWeight={700} fontSize={32} color={'primary.800'} textAlign={'center'}>
                      5% Volume Discount
                    </AppTypography>
                    <AppTypography fontSize={14} color={'grey.600'}>
                      Receive a 5% discount when your plan includes 5 or more users
                    </AppTypography>
                    <AppTypography fontSize={14} color={'grey.600'}>
                      <li>Total includes account owner</li>
                      <li>No Plan Type Restriction</li>
                      <li>Discount will begin applying at the first fulll seat billing</li>
                    </AppTypography>
                  </AppBox>
                </AppBox>
                <AppBox>
                  <AppBox borderRadius={6} border={2} borderColor={'grey.300'} paddingX={3} paddingY={1}>
                    <AppTypography fontWeight={700} fontSize={32} color={'primary.800'} textAlign={'center'}>
                      10% Volume Discount
                    </AppTypography>
                    <AppTypography fontSize={14} color={'grey.600'}>
                      Receive a 10% discount when your plan includes 10 or more users
                    </AppTypography>
                    <AppTypography fontSize={14} color={'grey.600'}>
                      <li>Total includes account owner</li>
                      <li>No Plan Type Restriction</li>
                      <li>Discount will begin applying at the first fulll seat billing</li>
                    </AppTypography>
                  </AppBox>
                </AppBox>
              </AppBox>
              <AppBox width={'100%'} textAlign={'right'} paddingTop={2}>
                <AppTypography onClick={handleOutsideTeam} fontSize={14} color={'primary.200'} fontWeight={500}>
                  Manage Outside Team
                </AppTypography>
              </AppBox>
            </Grid>
          </Grid>
        </AppBox>
        <AppBox paddingTop={4}>
          <AppBox display={'flex'} gap={2} justifyContent={'flex-end'}>
            <AppButton color="primary" onClick={closeDialog} size="large">
              Cancel
            </AppButton>
            <AppButton variant="contained" size="large" onClick={() => setPromoShown(true)}>
              Continue
            </AppButton>
          </AppBox>
        </AppBox>
      </AppBox>
    </>
  )
}
