import { AppBox, AppButton } from 'components'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { setSelectedParkIdToMove } from 'store/slices'
import { AppStore } from 'store/app.store'

interface Props {
  parkId: string
  marker: google.maps.Marker
}
export const ParkSitesGoogleMapAdminInfoBox: FC<Props> = ({ parkId, marker }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyLatLng = () => {
    enqueueSnackbar('Copied to clipboard', { variant: 'default' })
    navigator.clipboard.writeText(` ${marker.getPosition()?.lat()}, ${marker.getPosition()?.lng()}`)
  }
  const handleDragClick = () => {
    AppStore.dispatch(setSelectedParkIdToMove(parkId))
    enqueueSnackbar('Copied to clipboard', { variant: 'error' })
  }
  return (
    <AppBox display={'flex'} flexDirection={'column'}>
      <AppButton onClick={() => handleCopyLatLng()} suffixIcon="mynaui:copy">
        {marker.getPosition()?.lat()}, {marker.getPosition()?.lng()}
      </AppButton>
      <AppButton onClick={() => handleDragClick()} suffixIcon="hugeicons:drag-04">
        Drag
      </AppButton>
    </AppBox>
  )
}
