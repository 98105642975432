import { AppBox, AppButton, AppTypography, BaseComponent, MemberShipLoader } from 'components'
import { MembershipTypes } from 'globals/constants'
import { centsToDollar } from 'globals/functions'
import { useMembershipHelper } from 'hooks'
import { FC, useMemo } from 'react'
import { MembershipPlanViewModel } from 'sdk'

interface Props {
  handlePlanSelected: (slug: MembershipTypes) => void
  loadingMemberships: boolean
  membershipPlans: MembershipPlanViewModel[]
}

export const ChangeMembershipSelectPlans: FC<Props> = ({ handlePlanSelected, loadingMemberships, membershipPlans }) => {
  const { ActiveMembership } = useMembershipHelper()
  const annualProMembership = useMemo(
    () => membershipPlans.find((x) => x.Slug === MembershipTypes.AnnualProMembership),
    [membershipPlans]
  )
  const monthlyProMembership = useMemo(
    () => membershipPlans.find((x) => x.Slug === MembershipTypes.ProMembership),
    [membershipPlans]
  )
  return (
    <AppBox>
      <AppTypography fontSize={32} fontWeight={700} textAlign={'center'}>
        Access all of our features with the plan that fits
      </AppTypography>
      <AppBox display={'flex'} gap={2} paddingX={2} paddingTop={2}>
        <img src={'/images/pax/ChangeMembershipPlans.png'} alt={'plan1'} />
        <BaseComponent
          isLoading={loadingMemberships}
          loaderComponent={
            <>
              <MemberShipLoader />
            </>
          }
          isData={membershipPlans.length > 0}
          children={
            <>
              <AppBox
                position={'relative'}
                borderRadius={6}
                border={2}
                borderColor={'grey.300'}
                paddingX={3}
                paddingY={1}
                paddingTop={8}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <AppBox position="absolute" top={-10}>
                  <img src={'/images/plan-banners/Most_Popular.png'} alt={'most-popular'} />
                </AppBox>
                <AppTypography fontWeight={700} fontSize={32} textAlign={'center'}>
                  Monthly Plan
                </AppTypography>
                <AppTypography
                  fontWeight={700}
                  fontSize={32}
                  color={'primary.800'}
                  textAlign={'center'}
                  component={'span'}
                >
                  {centsToDollar(monthlyProMembership?.Price ?? 0)}
                  <AppTypography component={'span'} color={'grey.900'} fontSize={16} fontWeight={700}>
                    /user/mon
                  </AppTypography>
                </AppTypography>
                <AppTypography fontSize={14} fontWeight={400}>
                  <li>Pay month-to-month</li>
                  <li>Full access to all features</li>
                  <li>Business hour email support</li>
                </AppTypography>
                <AppBox paddingTop={6} alignSelf={'center'}>
                  <AppButton
                    variant="outlined"
                    size="large"
                    onClick={() => handlePlanSelected(MembershipTypes.ProMembership)}
                    sidePaddings={6}
                    disabled={ActiveMembership()?.MembershipSlug === MembershipTypes.ProMembership}
                  >
                    Select
                  </AppButton>
                </AppBox>
              </AppBox>
              <AppBox
                position={'relative'}
                borderRadius={6}
                border={2}
                borderColor={'grey.300'}
                paddingX={3}
                paddingY={1}
                paddingTop={8}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
              >
                <AppBox position="absolute" top={-10}>
                  <img src={'/images/plan-banners/CurrentlyPaused.png'} alt={'most-popular'} />
                </AppBox>

                <AppTypography fontWeight={700} fontSize={32} textAlign={'center'}>
                  Annual Plan
                </AppTypography>
                <AppTypography
                  fontWeight={700}
                  fontSize={32}
                  color={'primary.800'}
                  textAlign={'center'}
                  component={'span'}
                >
                  {centsToDollar(annualProMembership?.Price ?? 0)}
                  <AppTypography component={'span'} color={'grey.900'} fontSize={16} fontWeight={700}>
                    /user/year
                  </AppTypography>
                </AppTypography>
                <AppTypography fontSize={14} fontWeight={400}>
                  <li>Pay annually to save 20%</li>
                  <li>Full access to all features</li>
                  <li>Business hour email support</li>
                </AppTypography>
                <AppBox paddingTop={6} alignSelf={'center'}>
                  <AppButton
                    variant="outlined"
                    size="large"
                    onClick={() => handlePlanSelected(MembershipTypes.AnnualProMembership)}
                    sidePaddings={6}
                    disabled={ActiveMembership()?.MembershipSlug === MembershipTypes.AnnualProMembership}
                  >
                    Select
                  </AppButton>
                </AppBox>
              </AppBox>
            </>
          }
        />
      </AppBox>
    </AppBox>
  )
}
