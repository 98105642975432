import { Grid } from '@mui/material'
import { AppBox, AppIcon, AppTypography } from 'components'
import { useCrmContacts } from 'hooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CrmNotes } from '../../crm-notes/CrmNotes'
import { CrmContactLinkedCompanies } from './linked-companies/CrmContactLinkedCompanies'
import { CrmContactLinkedParkSites } from './linked-parksites/CrmContactLinkedParksites'
import { CrmContactLinkedGroupTags } from './linked-tags/CrmContactLinkedGroupTags'
import { CrmContactPersonalInfo } from './personal-info/CrmContactPersonalInfo'
import { CrmContactDetailSideBar } from './side-bar/CrmContactDetailSideBar'

export const ContactDetails = () => {
  const navigate = useNavigate()
  const { contactId } = useParams()

  const { EditContact, isEditing, control, reset, watch, contactById, getContactById, isGettingContactById } =
    useCrmContacts({ contactId: contactId! })

  useEffect(() => getContactById(contactId!), [])
  useEffect(() => {
    if (contactById) {
      reset({
        Id: contactById.Id,
        ContactType: 'contact',
        ContactEmail: contactById.ContactEmail,
        ContactFirstName: contactById.ContactFirstName,
        ContactLastName: contactById.ContactLastName,
        ContactAddress: contactById.ContactAddress,
        ContactAddress2: contactById.ContactAddress2,
        ContactCity: contactById.ContactCity,
        ContactStateId: contactById.ContactStateId,
        ContactZip: contactById.ContactZip,
        ContactPhone: contactById.ContactPhone,
        ContactAltPhone: contactById.ContactAltPhone,
      })
    }
  }, [contactById])
  return (
    <>
      <AppBox display={'flex'} alignItems={'center'} gap={1} backgroundColor="white" padding={2}>
        <AppIcon icon={'mingcute:arrow-left-line'} size="medium" onClick={() => navigate(-1)} />
        <AppTypography fontWeight={700} fontSize={24} color={'grey.900'}>
          {contactById?.ContactEmail}
        </AppTypography>
      </AppBox>
      <Grid container spacing={2} padding={3} paddingBottom={8}>
        <Grid item xs={3}>
          <CrmContactDetailSideBar contact={contactById} />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} rowSpacing={3}>
            <Grid item xs={6}>
              <CrmContactPersonalInfo
                control={control}
                watch={watch}
                isLoading={isGettingContactById}
                editContact={EditContact}
                isEditing={isEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <CrmContactLinkedParkSites contactId={contactId!} />
            </Grid>
            <Grid item xs={12}>
              <CrmContactLinkedCompanies />
            </Grid>
            <Grid item xs={12}>
              <CrmContactLinkedGroupTags contactId={contactId!} />
            </Grid>
            <Grid item xs={12}>
              <CrmNotes type="Contact" contactId={contactId!} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
