import { StepType } from '@reactour/tour'
import { AppBox, AppTypography } from 'components/base'
import { AppTourKeys } from 'globals/constants'
import { FC } from 'react'
import { AppTour } from './AppTour'

interface Props {}
export const ProfileTours: FC<Props> = () => {
  const ParkSiteTourSteps: StepType[] = [
    {
      selector: '.profile-tour-step0',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Welcome
          </AppTypography>
          <AppTypography fontSize={14}>
            Welcome to your <b>Account Settings</b>! This is where you'll manage everything related to your account
            including your <b>Billing & Plans</b>, <b>Outside Team</b>, and <b>Zapier Integration</b>. Would you like a
            quick tour?
          </AppTypography>
        </AppBox>
      ),
      position: 'center',
    },
    {
      selector: '.profile-tour-step1',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            The <b>Profile</b> tab allows you to control your personal information such as name, email address, and
            phone number. Whenever you change your email address here, the new value should be used to log into our
            system.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.profile-tour-step2',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            You can manage everything about your subscription plan using the <b>Billing & Plans</b> tab. This includes
            managing your credit card information and adding additional users that should be included in your billing
            cycle.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.profile-tour-step3',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Use the <b>Change Password</b> tab to manage the password that should be used to sign into your account.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.profile-tour-step4',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            The <b>Outside Team</b> tab allows you to provide other account owners access to your Dashboard. Invitees
            can utilize the Resource Center to switch from their Dashboard to yours.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.profile-tour-step5',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            <b>Zapier</b> is a 3rd party app that helps automate the transfer of your <b>CRM</b> records in and out of
            your account, without the use of code. Use the <b>Zapier Integration</b> tab to generate your private API
            Key.
          </AppTypography>
        </AppBox>
      ),
    },
    {
      selector: '.profile-tour-step6',
      content: (
        <AppBox>
          <AppTypography fontSize={16} fontWeight={500}>
            Profile Tour
          </AppTypography>
          <AppTypography fontSize={14}>
            Use the <b>Account Recovery</b> tab to add an alternative email address that can be used in the event you
            need to reset your password but have lost access to your Profile’s email address.
          </AppTypography>
        </AppBox>
      ),
    },
  ]

  return <AppTour uniqueKey={AppTourKeys.ProfileTour} steps={ParkSiteTourSteps} />
}
