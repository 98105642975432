import { Icon } from '@iconify/react'
import { Chip, Divider, Grid, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppAutoComplete, AppBox, AppButton, AppTypography, TagsListLoader } from 'components'
import { ApiQueryKeys, TagTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useMemo, useState } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CrmContactTagApi, CrmcontactLinkedTagPayload, ShowAlert, TagApi } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
import theme from 'theme/theme'

const crmContactTagApi = new CrmContactTagApi()
const tagApi = new TagApi()
interface Props {
  contactId: string
}
export const CrmContactLinkedGroupTags: FC<Props> = ({ contactId }) => {
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { control } = useForm<CrmcontactLinkedTagPayload>({
    defaultValues: { CrmContactsTableId: contactId, Type: TagTypes.GROUP_TAG },
  })

  //    Getting All Tags
  const {
    data: allTags,
    isPending: isTagsLoading,
    mutate: getAllTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.tags.FetchTag],
    mutationFn: () => tagApi.GetTags(TagTypes.GROUP_TAG),
  })
  useEffect(() => getAllTags(), [SelectedTeam?.Id])
  //
  //    Getting All Linked Tags

  const {
    data: contactTags,
    isPending: isContactTagsLoading,
    mutate: GetAllLinkedTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.FetchTag],
    mutationFn: () => crmContactTagApi.GetTags(contactId!, TagTypes.GROUP_TAG),
  })
  useEffect(() => GetAllLinkedTags(), [SelectedTeam?.Id])

  //

  // Adding New Tag

  const { mutate: addTag, isPending: isAddingTag } = useMutation({
    mutationFn: crmContactTagApi.AddTag,
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.AddTag],
    onSuccess: () => handleFormSubmitSuccess(),
  })

  //

  // Deleting Linked Tags
  const { mutate: deleteTag } = useMutation({
    mutationFn: crmContactTagApi.RemoveTag,
    mutationKey: [ApiQueryKeys.crm.contactLinkedTags.RemoveTag],
    onSuccess: () => handleDeleteTagSuccess(),
  })

  const handleDeleteTagSuccess = () => {
    enqueueSnackbar('Tag has Been Removed ', { variant: 'success' })
    GetAllLinkedTags()
  }
  const handleDeleteParkTag = (id: string) => {
    ShowAlert('question', 'Are you sure you want to remove this Tag?').then((result) => {
      if (result.isConfirmed) {
        const linkId = contactTags?.find((tag) => tag.TagsTableId === id)?.Id
        if (!linkId) {
          return enqueueSnackbar('Tag not found', { variant: 'error' })
        }
        deleteTag(linkId)
      }
    })
  }
  //
  const unlinkedTags = useMemo(() => {
    return allTags
      ?.filter((tag) => !contactTags?.find((linkedTag) => linkedTag.TagsTableId === tag.Id))
      .map((tag) => {
        return { label: tag.Tag, value: tag.Id }
      })
  }, [contactTags, allTags])
  const linkedTags = useMemo(() => {
    return allTags?.filter((tag) => contactTags?.find((linkedTag) => linkedTag.TagsTableId === tag.Id))
  }, [contactTags, allTags])

  const addLinkedTag = (data: CrmcontactLinkedTagPayload) => {
    addTag(data)
  }
  const handleFormSubmitSuccess = () => {
    enqueueSnackbar('Tag has Been Added ', { variant: 'success' })
    GetAllLinkedTags()
  }
  return (
    <AppBox
      border={1}
      paddingX={4}
      paddingY={2}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Companies"
    >
      <Grid container alignItems={'center'} paddingBottom={2}>
        <Grid item xs={9}>
          <AppTypography fontWeight={700} fontSize={16}>
            Group Tags
          </AppTypography>
        </Grid>
        <Grid item xs={3} textAlign={'end'}>
          {!isAddClicked ? (
            <AppButton variant="contained" prefixIcon="tabler:plus" size="large" onClick={() => setIsAddClicked(true)}>
              Add
            </AppButton>
          ) : (
            <Form control={control} onSubmit={({ data }) => addLinkedTag(data)}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={9}>
                  <AppAutoComplete
                    options={unlinkedTags ?? []}
                    fullWidth
                    placeholder="Search Tags"
                    control={control}
                    name={'TagsTableId'}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <AppButton isLoading={isAddingTag} sidePaddings={3} variant="contained" size="large" type="submit">
                    Save
                  </AppButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Grid>
      </Grid>
      <Divider />
      <AppBox
        marginTop={2}
        backgroundColor="grey.50"
        width={'100%'}
        minHeight={'150px'}
        borderRadius={5}
        border={1}
        borderColor={'grey.200'}
      >
        <AppBox padding={4}>
          {isContactTagsLoading || isTagsLoading ? (
            <TagsListLoader />
          ) : (
            linkedTags?.map((tag, _index) => {
              return (
                <Chip
                  key={_index}
                  label={tag.Tag}
                  variant="outlined"
                  style={{ backgroundColor: theme.palette.primary.light, color: 'white', margin: '4px' }}
                  deleteIcon={<Icon icon={'octicon:x-16'} color="white" />}
                  onDelete={() => {
                    handleDeleteParkTag(tag.Id)
                  }}
                />
              )
            })
          )}
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
