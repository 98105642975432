import { CrmCompanyViewModel, CrmContactViewModel, CrmNoteViewModel } from 'sdk'
import {
  FilterCategory,
  FilterConditionLabels,
  FilterConditionTypes,
  FilterFieldType,
  FilterInputValueFrom,
} from '../FilterTypes'
import { nameof } from 'ts-simple-nameof'

export const CrmCompanyFilterOptions: Array<FilterCategory> = [
  {
    Label: 'Company Information',
    Fields: [
      {
        TableName: 'CrmCompanies',
        ColumnName: nameof<CrmCompanyViewModel>((x) => x.Name),
        Label: 'Company Name',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        Label: 'Company Address',
        TableName: 'CrmCompanies',
        ColumnName: nameof<CrmCompanyViewModel>((x) => x.Address),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        Label: 'Company Address 2',
        TableName: 'CrmCompanies',
        ColumnName: 'Address2',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company City',
        ColumnName: 'City',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Is_Null,
            Label: FilterConditionLabels.Is_Null,
          },
          {
            Condition: FilterConditionTypes.Is_Not_Null,
            Label: FilterConditionLabels.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company State',
        ColumnName: 'State',
        IsDefaultFilter: true,
        Conditions: [
          {
            Label: FilterConditionLabels.In,
            Condition: FilterConditionTypes.In,
            IsDefaultCondition: true,
            InputField: {
              Type: FilterFieldType.States,
              IsMultiSelect: true,
            },
          },
          {
            Label: FilterConditionLabels.Not_In,
            Condition: FilterConditionTypes.Not_In,
            InputField: {
              Type: FilterFieldType.States,
              IsMultiSelect: true,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company Zip',
        ColumnName: 'Zip',
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Is_Null,
            Condition: FilterConditionTypes.Is_Null,
          },
          {
            Label: FilterConditionLabels.Is_Not_Null,
            Condition: FilterConditionTypes.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company Phone',
        ColumnName: nameof<CrmCompanyViewModel>((x) => x.Phone),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Is_Null,
            Condition: FilterConditionTypes.Is_Null,
          },
          {
            Label: FilterConditionLabels.Is_Not_Null,
            Condition: FilterConditionTypes.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company Phone',
        ColumnName: 'AltPhone',
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Label: FilterConditionLabels.Is_Null,
            Condition: FilterConditionTypes.Is_Null,
          },
          {
            Label: FilterConditionLabels.Is_Not_Null,
            Condition: FilterConditionTypes.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company Website',
        ColumnName: nameof<CrmCompanyViewModel>((x) => x.Website),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals,
            Condition: FilterConditionTypes.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal,
            Condition: FilterConditionTypes.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Contains,
            Condition: FilterConditionTypes.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Label: FilterConditionLabels.Is_Null,
            Condition: FilterConditionTypes.Is_Null,
          },
          {
            Label: FilterConditionLabels.Is_Not_Null,
            Condition: FilterConditionTypes.Is_Not_Null,
          },
        ],
      },
      {
        TableName: 'CrmCompanies',
        Label: 'Company Modified',
        ColumnName: nameof<CrmCompanyViewModel>((x) => x.Modified),
        Conditions: [
          {
            Label: FilterConditionLabels.Equals_Date,
            Condition: FilterConditionTypes.Equals_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Does_Not_Equal_Date,
            Condition: FilterConditionTypes.Does_Not_Equal_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_Before_Date,
            Condition: FilterConditionTypes.Is_Before_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
          {
            Label: FilterConditionLabels.Is_After_Date,
            Condition: FilterConditionTypes.Is_After_Date,
            InputField: {
              Type: FilterFieldType.Date,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Linked Contacts',
    Fields: [
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Type',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactType),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.DropDown,
              ValueFrom: FilterInputValueFrom.CrmContactTypes,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact First Name',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactFirstName),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Last Name',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactLastName),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Email',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactEmail),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Phone',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactPhone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Alt Phone',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactAltPhone),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Phone,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Address',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactAddress),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmCompanyLinkedContactsTable',
        Label: 'Contact Address2',
        ColumnName: nameof<CrmContactViewModel>((x) => x.ContactAddress2),
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
  {
    Label: 'Company Notes',
    Fields: [
      {
        TableName: 'CrmNotes',
        ColumnName: nameof<CrmNoteViewModel>((x) => x.Note),
        Label: 'Note',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
      {
        TableName: 'CrmNotes',
        ColumnName: nameof<CrmNoteViewModel>((x) => x.Date),
        Label: 'Note Date',
        Conditions: [
          {
            Condition: FilterConditionTypes.Equals,
            Label: FilterConditionLabels.Equals,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Does_Not_Equal,
            Label: FilterConditionLabels.Does_Not_Equal,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
          {
            Condition: FilterConditionTypes.Contains,
            Label: FilterConditionLabels.Contains,
            InputField: {
              Type: FilterFieldType.Text,
            },
          },
        ],
      },
    ],
  },
]
