import { Grid } from '@mui/material'
import { AppTextField, StateField } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { CountyPayload } from 'sdk'

interface Props {
  control: Control<CountyPayload>
}

export const AddeditCountyForm: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StateField control={control} name="StateId" label="State" />
      </Grid>
      <Grid item xs={12}>
        <AppTextField control={control} name="County" label="County" />
      </Grid>
    </Grid>
  )
}
