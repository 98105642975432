import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components'

export const ParkDetailsLoader = () => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2} height={'100%'} paddingX={4}>
        <AppBox borderBottom={1} borderColor={'grey.100'} paddingY={1} width={1}>
          <Skeleton variant="rounded" width={'20%'} height={40} />
        </AppBox>
        <Skeleton variant="rounded" width={'100%'} height={80} sx={{ color: 'transparent' }}>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officia doloribus illo asperiores totam eum
          perspiciatis voluptatum, repellat aspernatur, temporibus pariatur nulla, laudantium aperiam esse quod quis
          corrupti reprehenderit quae sunt.
        </Skeleton>
        <AppBox
          paddingBottom={2}
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          borderBottom={1}
          borderColor={'grey.100'}
        >
          <AppBox display={'flex'} justifyContent={'space-between'}>
            <Skeleton variant="rounded" width={'16%'} height={40} />
            <Skeleton variant="rounded" width={'40%'} height={40} />
          </AppBox>
          <Skeleton variant="rounded" width={'52%'} height={40} />
          <Skeleton variant="rounded" width={'20%'} height={40} />
        </AppBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rounded" width={'10%'} height={40} />
            <Grid container spacing={2} paddingTop={1}>
              {Array.from(new Array(8)).map(() => (
                <Grid item xs={3}>
                  <Skeleton variant="rounded" width={'80%'} height={40} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {Array.from(new Array(3)).map(() => (
            <Grid item xs={4}>
              <AppBox display={'flex'} flexDirection={'column'} gap={1}>
                <Skeleton variant="rounded" width={'40%'} height={40} />
                <Skeleton variant="rounded" width={'30%'} height={20} />
                <Skeleton variant="rounded" width={'30%'} height={20} sx={{ alignSelf: 'center' }} />
                {Array.from(new Array(3)).map(() => (
                  <AppBox display={'flex'} justifyContent={'space-between'}>
                    <Skeleton variant="rounded" width={'30%'} height={20} />
                    <Skeleton variant="rounded" width={'10%'} height={20} />
                  </AppBox>
                ))}
              </AppBox>
            </Grid>
          ))}
        </Grid>
        <AppBox
          borderRadius={2}
          border={1}
          padding={1}
          borderColor={'grey.200'}
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Skeleton variant="rounded" width={'25%'} height={40} />
          <Skeleton variant="rounded" width={'10%'} height={40} />
        </AppBox>
      </AppBox>
    </>
  )
}
