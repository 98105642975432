import { Grid } from '@mui/material'
import { AppBox, AppTextField, AppTypography } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { ParkUpdatePayload, ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ParkUpdatePayload> | Control<ReportParkPayload>
}

export const UpdateParkMHLotsCard: FC<Props> = ({ control }) => {
  return (
    <>
      <Grid
        item
        xs={3.8}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        borderRadius={3}
        border={1}
        borderColor={'grey.200'}
        paddingX={2}
        paddingY={1}
        sx={{ backgroundColor: 'grey.50' }}
      >
        <AppTypography fontWeight={'bold'} fontSize={16}>
          Mobile Home Lots
        </AppTypography>
        <AppBox display={'flex'} alignItems={'center'}>
          <AppTypography component={'span'} fontWeight={400}>
            Total MH Lots{' '}
            <AppTypography color={'red'} component={'span'}>
              *
            </AppTypography>
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              required
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.TotalMHLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox textAlign={'center'}>
          <AppTypography fontWeight={'bold'}>Lot Break Down</AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Park Owned Home Lots
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.POHsMHLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Tenant Owned Home Lots
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.TOHsMHLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Vacant Lots
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.DevelopedMHLots"
              control={control}
            />
          </Grid>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography component={'span'} fontWeight={400}>
            Undeveloped Lots
          </AppTypography>
          <Grid item xs={4}>
            <AppTextField
              type="number"
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="ParkFieldsDataObj.UnDevelopedMHLots"
              control={control}
            />
          </Grid>
        </AppBox>
      </Grid>
    </>
  )
}
