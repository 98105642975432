import { Grid } from '@mui/material'
import { AppBox } from 'components'
import { CrmContent } from './content/CrmContent'
import { CrmListingHelpWindow } from './help-window/CrmHelpWindow'
import { CrmToolBar } from './toolbar/CrmToolBar'

export const Crm = () => {
  return (
    <Grid container>
      <Grid item xs={12} minHeight={'8vh'}>
        <CrmToolBar />
      </Grid>
      <Grid item xs={12} minHeight={'83vh'} position={'relative'} id="crm-listing-help-window-Container">
        <AppBox minHeight={'83vh'} position={'relative'}>
          <CrmListingHelpWindow />
          <CrmContent />
        </AppBox>
      </Grid>
    </Grid>
  )
}
