import { IconButton } from '@mui/material'
import { AppBox, AppIcon, AppMenu, AppTypography } from 'components'
import { CrmNoteViewModel, ParkNoteViewModel } from 'sdk'

interface Props {
  note: CrmNoteViewModel | ParkNoteViewModel
  handleEditClicked: (note: CrmNoteViewModel | ParkNoteViewModel) => void
  handleDeleteClicked: (id: string) => void
}
export const NoteListView = (props: Props) => {
  return (
    <>
      <AppBox backgroundColor="grey.50" borderColor={'grey.200'} border="1px" padding={2} borderRadius={3}>
        <AppBox
          display={'flex'}
          justifyContent={'space-between'}
          borderBottom={1}
          borderColor={'grey.200'}
          paddingBottom={1}
        >
          <AppTypography color={'grey.900'} fontSize={14} fontWeight={600}>
            {props.note.CreatedByName} {props.note.Date}
          </AppTypography>
          <AppBox>
            <AppMenu
              renderElement={(handleClick) => (
                <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                  <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                </IconButton>
              )}
              MenuItems={[
                { text: 'Edit', onClick: () => props.handleEditClicked(props.note) },
                { text: 'Delete', onClick: () => props.handleDeleteClicked(props.note.Id!) },
              ]}
            />
          </AppBox>
        </AppBox>
        <AppBox paddingTop={1}>
          <AppTypography color={'grey.700'} fontSize={12}>
            {props.note.Note}
          </AppTypography>
        </AppBox>
      </AppBox>
    </>
  )
}
