import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import { AuthSlice } from './slices/auth.slice'
import { GoogleMapsSlice } from './slices/googleMaps.slice'
import { ParkLabelSlice } from './slices/park-labels.slice'
import { ParkSiteToolsSlice } from './slices/park-site-tools.slice'
// import { ProfileModulesSlice } from './slices/profile-module.slice'
import { CrmLinkedToolsHelperSlice, TeamSlice } from './slices'
import { AdminSettingsToolsSlice } from './slices/admin-settings.slice'
import { CommonSlice } from './slices/common.slice'
import { CrmToolsSlice } from './slices/crm-tools.slice'
import { FilterSlice } from './slices/filter.slice'
import { ParkTagSlice } from './slices/park-tags.slice'
import { PaymentMethodSlice } from './slices/payment-methods.slice'
import { ProfileModulesSlice } from './slices/profile-module.slice'

export const AppStore = configureStore({
  reducer: persistReducer(
    {
      key: 'root',
      storage,
      whitelist: ['Auth', 'GoogleMaps', 'Team'],
    },
    combineReducers({
      Auth: AuthSlice.reducer,
      ParkLabel: ParkLabelSlice.reducer,
      ParkTag: ParkTagSlice.reducer,
      GoogleMaps: GoogleMapsSlice.reducer,
      ParkSiteTools: ParkSiteToolsSlice.reducer,
      CrmTool: CrmToolsSlice.reducer,
      AdminSettingsTool: AdminSettingsToolsSlice.reducer,
      ProfileModules: ProfileModulesSlice.reducer,
      Team: TeamSlice.reducer,
      // Membership: MembershipSlice.reducer,
      PaymentMethod: PaymentMethodSlice.reducer,
      Common: CommonSlice.reducer,
      Filter: FilterSlice.reducer,
      CrmLinkedToolsHelper: CrmLinkedToolsHelperSlice.reducer,
    })
  ),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
})

export type AppDispatch = typeof AppStore.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export type IAppStore = ReturnType<typeof AppStore.getState>
export const persistor = persistStore(AppStore)
