import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, TableActions } from 'components'
import { FC } from 'react'
import { CrmContactLinkedCompaniesViewModel } from 'sdk'

interface Props extends AppTableProps<CrmContactLinkedCompaniesViewModel> {
  onDeleteClick: (Id: string) => void
}
export const CrmContactLinkedCompaniesListing: FC<Props> = ({ onDeleteClick, ...props }) => {
  const columns: ColumnDef<CrmContactLinkedCompaniesViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return <TableActions row={row} actions={[{ text: 'Delete', onClick: () => onDeleteClick(row.original.Id) }]} />
      },
    },
    {
      header: 'Company Name',
      accessorKey: 'Name',
      size: 1,
    },

    {
      header: 'Phone',
      accessorKey: 'Phone',
      size: 1,
    },
    {
      header: 'Address',
      accessorKey: 'Address',
      size: 1,
    },
  ]
  return <AppTable columns={columns} {...props} />
}
