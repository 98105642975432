import { AppBox, AppTextField, AppTypography } from 'components'

interface Props {
  control: any
}
export const AddEditParkTagForm = ({ control }: Props) => {
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingX={1}>
      <AppTypography fontSize={14} color={'grey.500'}>
        Enter a unique Tag Value that will help you search for specific parks after it’s been applied.
      </AppTypography>
      <AppBox marginTop={2}>
        <AppTextField
          required="This field is required."
          label="Tag Value"
          placeholder="Enter Pin Label"
          control={control}
          name="Tag"
          LabelProps={{ fontWeight: 'bold' }}
        />
      </AppBox>
    </AppBox>
  )
}
