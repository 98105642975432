import { AppBox, AppDialog, AppDialogRefType } from 'components'
import { useStates } from 'hooks'
import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  PaginationFilterModel,
  ShowAlert,
  StatePayload,
  StateViewModel,
  UpdateStateStatusPayload,
  UpdateStateWebsitePayload,
} from 'sdk'
import { AddEditStateForm } from '../add-edit-state-form/AddEditStateForm'
import { EditStateWebsiteFrom } from '../add-edit-state-form/EditStateWebsiteForm'
import { StatesTable } from './StatesTable'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const StatesListing: FC<Props> = ({ filter, setFilter }) => {
  const {
    States,
    updateState,
    isGettingState,
    isUpdatingState,
    updateStateStatus,
    updateStateWebsite,
    isUpdatingStateWebsite,
  } = useStates()
  const EditStateDialogRef = useRef<AppDialogRefType>(null)
  const EditStateWebsiteDialogRef = useRef<AppDialogRefType>(null)

  const { control, handleSubmit, getValues, setValue, reset } = useForm<StatePayload>()
  const {
    control: UpdateWebsiteControl,
    handleSubmit: UpdateWebsiteHandleSubmit,
    getValues: UpdateWebsiteGetValues,
    setValue: UpdateWebsiteSetValue,
    reset: UpdateWebsiteReset,
  } = useForm<UpdateStateWebsitePayload>()

  const handleEditClick = (state: StateViewModel) => {
    setValue('Id', state.Id)
    setValue('Name', state.Name)
    setValue('Code', state.Code)
    EditStateDialogRef.current?.openDialog!()
  }
  const handleWebsiteUpdateClick = (id: string) => {
    UpdateWebsiteSetValue('Id', id)

    EditStateWebsiteDialogRef.current?.openDialog!()
  }
  const handleUpdateStatusClick = (state: StateViewModel) => {
    ShowAlert(
      'question',
      `Are you sure you want to ${state.ActiveStatus === 'TRUE' ? 'Deactivate' : 'Activate'} this park?`
    ).then((result) => {
      if (result.isConfirmed) {
        const payload: UpdateStateStatusPayload = {
          Id: state.Id!,
          Status: state.ActiveStatus === 'TRUE' ? 'FALSE' : 'TRUE',
        }
        updateStateStatus(payload)
      }
    })
  }

  const paginatedStates = useMemo(() => {
    if (!!filter) {
      return States?.filter(
        (x) =>
          x.Name.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.Code.toLowerCase().includes(filter.Query?.toLowerCase() ?? '')
      ).slice(filter.PageSize! * (filter.PageNo! - 1), filter.PageSize! * filter.PageNo!)
    }
    return States
  }, [filter, States])
  const totalPaginatedStates = useMemo(() => {
    if (!!filter) {
      return States?.filter(
        (x) =>
          x.Name.toLowerCase().includes(filter.Query?.toLowerCase() ?? '') ||
          x.Code.toLowerCase().includes(filter.Query?.toLowerCase() ?? '')
      ).length
    }
    return States?.length
  }, [paginatedStates])
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <StatesTable
          data={paginatedStates ?? []}
          isLoading={isGettingState}
          totalRecords={totalPaginatedStates ?? 0}
          onEditClicked={handleEditClick}
          onUpdateWebSiteClick={handleWebsiteUpdateClick}
          onStatusUpdateClick={handleUpdateStatusClick}
          paginationFilter={filter}
          onPageSizeChange={(pageSize) => {
            setFilter((prev) => ({ ...prev, PageNo: 1, PageSize: pageSize }))
          }}
          onCurrentPageChange={(page) => {
            setFilter((prev) => ({ ...prev, PageNo: page }))
          }}
        />
      </AppBox>
      {/* // Dialoges for Editing state and state website */}
      <AppDialog
        maxWidth="sm"
        ref={EditStateDialogRef}
        title="Edit State"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(() => {
            updateState(getValues())
              .then((x) => {
                EditStateDialogRef.current?.closeDialog!()
                reset()
              })
              .catch((err) => {})
          }),
        }}
        submitButtonProps={{ isLoading: isUpdatingState, children: 'Update' }}
        renderChild={(openDialog) => <AddEditStateForm control={control} />}
      />
      <AppDialog
        maxWidth="sm"
        ref={EditStateWebsiteDialogRef}
        title="Edit State Website"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: UpdateWebsiteHandleSubmit(() => {
            updateStateWebsite(UpdateWebsiteGetValues())
              .then((x) => {
                EditStateWebsiteDialogRef.current?.closeDialog!()
                UpdateWebsiteReset()
              })
              .catch((err) => {})
          }),
        }}
        submitButtonProps={{ isLoading: isUpdatingStateWebsite, children: 'Update' }}
        renderChild={(openDialog) => <EditStateWebsiteFrom control={UpdateWebsiteControl} />}
      />
    </>
  )
}
