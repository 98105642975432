import { Grid } from '@mui/material'
import { FC } from 'react'
import { ParkDetailViewModel } from 'sdk'
import { ParkDetailBasics } from './cards/ParkDetailBasics'
import { ParkDetailMHLots } from './cards/ParkDetailMHLots'
import { ParkDetailRVLots } from './cards/ParkDetailRVLots'
import { ParkDetailUtilities } from './cards/ParkDetailUtilities'

interface Props {
  parkDetail: ParkDetailViewModel
}
export const ParkDetailsCards: FC<Props> = ({ parkDetail }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ParkDetailBasics parkDetail={parkDetail} />
      </Grid>
      <Grid item xs={4} display={'inline-flex'}>
        <ParkDetailMHLots parkDetail={parkDetail} />
      </Grid>
      <Grid item xs={4} display={'inline-flex'}>
        <ParkDetailRVLots parkDetail={parkDetail} />
      </Grid>
      <Grid item xs={4} display={'inline-flex'}>
        <ParkDetailUtilities parkDetail={parkDetail} />
      </Grid>
    </Grid>
  )
}
