import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, ParkSitesTools } from 'globals/constants'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParksApi, ParkLabelApi, ShowAlert, UserTaggedParkApi } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

const parkLabelApi = new ParkLabelApi()
const taggedParksApi = new UserTaggedParkApi()
const crmcontactsLinkedParksApi = new CrmContactLinkedParksApi()

export const useRemoveParkFromList = (refetch: () => void) => {
  const { openedTool } = useSelector(ParkSiteToolSliceSelector)
  const getQueryFn = (id: string) => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return parkLabelApi.RemoveUserLabelledPark(id)
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return taggedParksApi.removeAllTagsFromPark(id)
    } else if (openedTool === ParkSitesTools.CRM) {
      return crmcontactsLinkedParksApi.UnlinkAllContactsFromParkByLinkId(id)
    }
    return parkLabelApi.RemoveUserLabelledPark(id)
  }
  const getQueryKey = () => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return [ApiQueryKeys.parksites.labelledParks.RemoveLabelledParkFromList]
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return [ApiQueryKeys.parksites.taggedParks.RemoveTaggedPark]
    }
    return ['']
  }

  const { isPending: isCheckingPinnedParks, mutate: GetPinnedParksByLabelId } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.labelledParks.RemoveLabelledParkFromList],
    mutationFn: (labelId: string) => getQueryFn(labelId),
    onSuccess: () => refetch(),
  })

  const { isPending, mutate } = useMutation({
    mutationKey: getQueryKey(),
    onSuccess: () => refetch(),
    mutationFn: (id: string) => getQueryFn(id),
  })

  const handleRemovePark = (linkId: string) => {
    let message = ''
    if (openedTool === ParkSitesTools.PinnedParks) {
      message = `Are you sure you wish to reset this park's pin color?`
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      message = 'Are you sure you want to remove all the tags from this park?'
    } else if (openedTool === ParkSitesTools.CRM) {
      message = `Are you sure you wish to detach all of this park's contacts?`
    }
    ShowAlert('question', message).then((result) => {
      if (result.isConfirmed) {
        mutate(linkId)
      }
    })
  }

  return {
    handleRemovePark,
    isRemovingParkFromList: isPending,
  }
}
