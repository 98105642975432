import { TagTypes } from 'globals/constants'
import { SdkConfig } from '../sdk.config'
import { PaginationFilterModel } from 'sdk/models'
import { objToQuery } from 'globals/functions'

export const apiUrls = {
  auth: {
    AppSignup: () => {
      return `${SdkConfig.ApiPath}/Account/AppSignup`
    },
    AppLogIn: () => {
      return `${SdkConfig.ApiPath}/Account/AppLogIn`
    },
    getTeamAuthToken: (teamId: string) => {
      return `${SdkConfig.ApiPath}/Account/GetTeamAuthToken/${teamId}`
    },
  },
  account: {
    getUserByEmail(email: string) {
      return `${SdkConfig.ApiPath}/Account/GetUserByEmail/${encodeURIComponent(email)}`
    },
    addSubAccounts() {
      return `${SdkConfig.ApiPath}/Account/AddSubAccounts`
    },
    changePassword() {
      return `${SdkConfig.ApiPath}/Account/ChangePassword`
    },
    getUserSubAccounts(userId?: string) {
      return `${SdkConfig.ApiPath}/Account/GetUserSubAccounts${userId ? '?userId=' + userId : ''}`
    },
    forgotPassword() {
      return `${SdkConfig.ApiPath}/Account/ResetPassword`
    },
    setPasswordAfterForgot() {
      return `${SdkConfig.ApiPath}/Account/SetPasswordAfterForgot`
    },
    addRecoveryEmail() {
      return `${SdkConfig.ApiPath}/Account/AddRecoveryEmail`
    },
  },
  feedback: {
    addFeedback() {
      return `${SdkConfig.ApiPath}/Feedback`
    },
    getAllFeedbacks(filter: PaginationFilterModel) {
      return `${SdkConfig.ApiPath}/Feedback?${objToQuery(filter)}`
    },
  },
  newsAlerts: {
    getNewsAlerts(filter: PaginationFilterModel) {
      return `${SdkConfig.ApiPath}/NewsAlerts?${objToQuery(filter)}`
    },
    addNewsAlert() {
      return `${SdkConfig.ApiPath}/NewsAlerts`
    },
    updateNewsAlert() {
      return `${SdkConfig.ApiPath}/NewsAlerts`
    },
    deleteNewsAlert(newsAlertId: string) {
      return `${SdkConfig.ApiPath}/NewsAlerts/${newsAlertId}`
    },
  },
  user: {
    updateUserOnGettingStarted() {
      return `${SdkConfig.ApiPath}/User/UpdateUserOnGettingStarted`
    },
    getUsersWithSubAccounts(filter: PaginationFilterModel) {
      return `${SdkConfig.ApiPath}/User/GetAllUsersWithSubAccounts?${objToQuery(filter)}`
    },
    getUserPersonalInfo(userId: string) {
      return `${SdkConfig.ApiPath}/User/GetUserPersonalInfo/${userId}`
    },
    getUserSubscriptionInfo(userId: string) {
      return `${SdkConfig.ApiPath}/User/GetUserSubscriptionInfo/${userId}`
    },
    updateUserProfile() {
      return `${SdkConfig.ApiPath}/User/UpdateProfile`
    },
    updateUserProfileImage() {
      return `${SdkConfig.ApiPath}/User/UpdateProfilePicture`
    },
    removeUserProfileImage() {
      return `${SdkConfig.ApiPath}/User/RemoveProfilePicture`
    },
  },
  state: {
    getAllStates() {
      return `${SdkConfig.ApiPath}/State`
    },
    getById(id: string) {
      return `${SdkConfig.ApiPath}/State/${id}`
    },
    update() {
      return `${SdkConfig.ApiPath}/State`
    },
    updateWebsite() {
      return `${SdkConfig.ApiPath}/State/UpdateWebsite`
    },
    updateStatus() {
      return `${SdkConfig.ApiPath}/State/changestatus`
    },
    addState() {
      return `${SdkConfig.ApiPath}/State`
    },
  },
  county: {
    getCountyByState(stateId?: string) {
      return `${SdkConfig.ApiPath}/County${stateId ? `?stateId=${stateId}` : ''}`
    },
    getById(id: string) {
      return `${SdkConfig.ApiPath}/County/${id}`
    },
    update() {
      return `${SdkConfig.ApiPath}/County`
    },
    updateStatus() {
      return `${SdkConfig.ApiPath}/County/changestatus`
    },
    updateCountyWebsite() {
      return `${SdkConfig.ApiPath}/County/UpdateWebsite`
    },
    addCounty() {
      return `${SdkConfig.ApiPath}/County`
    },
  },
  filter: {
    getFilters() {
      return `${SdkConfig.ApiPath}/Filter`
    },
    addFilter() {
      return `${SdkConfig.ApiPath}/Filter/AddFilter`
    },
    updateFilter(filterId: string) {
      return `${SdkConfig.ApiPath}/Filter/${filterId}`
    },
    deleteFilter(filterId: string) {
      return `${SdkConfig.ApiPath}/Filter/${filterId}`
    },
    updateFilterName(filterId: string, newName: string) {
      return `${SdkConfig.ApiPath}/Filter/UpdateFilterName/${filterId}/${newName}`
    },
  },
  payment: {
    fetchCouponById(couponId: string) {
      return `${SdkConfig.ApiPath}/Coupon/GetCouponById/${couponId}`
    },
    attachPaymentMethodToCustomer() {
      return `${SdkConfig.ApiPath}/UserPayment/AttachPaymentMethod`
    },
  },
  memberships: {
    GetMembershipPlans() {
      return `${SdkConfig.ApiPath}/Membership/GetMembershipPlans`
    },
    changeMembership() {
      return `${SdkConfig.ApiPath}/Membership/ChangeMembership`
    },
    cancelMembership() {
      return `${SdkConfig.ApiPath}/Account/CancelMembership`
    },
  },
  team: {
    getUserTeams() {
      return `${SdkConfig.ApiPath}/Team/GetUserTeams`
    },
    isTeamNameTaken() {
      return `${SdkConfig.ApiPath}/Team/IsTeamNameTaken`
    },
    updateTeamName() {
      return `${SdkConfig.ApiPath}/Team/UpdateTeamName`
    },
    getPaidUserTeamsCountByEmail(email: string) {
      return `${SdkConfig.ApiPath}/Team/PaidUserTeamMembersCountByEmail?email=${encodeURIComponent(email)}`
    },
    requestUserToJoinTeam() {
      return `${SdkConfig.ApiPath}/Team/RequestUserToJoinTeam`
    },
    getTeamMembers(teamId: string) {
      return `${SdkConfig.ApiPath}/Team/GetTeamMembers/${teamId}`
    },
    revokeTeamMember(teamId: string, memberId: string) {
      return `${SdkConfig.ApiPath}/Team/RevokeTeamMemberAccess/${teamId}/${memberId}`
    },
    ResendInvite(teamId: string, memberId: string) {
      return `${SdkConfig.ApiPath}/Team/ResendTeamInvitation/${teamId}/${memberId}`
    },
  },
  parksites: {
    getAllParkMarkerPins: () => {
      return `${SdkConfig.ApiPath}/ParkSites/GetAllParkPins`
    },
    getParkActivityCenterInfo: (parkId: string) => {
      return `${SdkConfig.ApiPath}/ParkSites/GetParkActivityCenterInfo/${parkId}`
    },
    getParkDetail(parkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/GetParkDetail/${parkId}`
    },
    getParkImages(ParkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/GetParkImages/${ParkId}`
    },
    getParkCityDemographics(parkId: string) {
      return `${SdkConfig.ApiPath}/CityDemographics/GetParkCityDemographics/${parkId}`
    },
    getParkParcelData(parkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/GetParkParcelData/${parkId}`
    },
    requestNewParkSite() {
      return `${SdkConfig.ApiPath}/ParkSites/RequestNewParkSite`
    },
    getParkSiteHistory(parkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/GetParkActivityHistory/${parkId}`
    },
    updatePark() {
      return `${SdkConfig.ApiPath}/ParkSites/UpdatePark`
    },
    sendParkReport() {
      return `${SdkConfig.ApiPath}/ParkSites/SendParkReport`
    },
    isUserParkDataExist(parkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/IsUserParkDataExist/${parkId}`
    },
    deleteParkSite(parkId: string) {
      return `${SdkConfig.ApiPath}/ParkSites/DeleteParkSite/${parkId}`
    },
    updateParkImages() {
      return `${SdkConfig.ApiPath}/ParkSites/UpdateParkImages`
    },
    changeParkLocation() {
      return `${SdkConfig.ApiPath}/ParkSites/ChangeParkLocation`
    },
    addParkSite() {
      return `${SdkConfig.ApiPath}/ParkSites`
    },
    pinnedParksLoadMore() {
      return `${SdkConfig.ApiPath}/ParkSites/PinnedParksLoadMore`
    },
    taggedParksLoadMore() {
      return `${SdkConfig.ApiPath}/ParkSites/TaggedParksLoadMore`
    },
    crmParksLoadMore() {
      return `${SdkConfig.ApiPath}/ParkSites/CrmParksLoadMore`
    },
    filter: {
      applyParkFilter() {
        return `${SdkConfig.ApiPath}/Filter/ApplyParkFilter`
      },
      getParkFilters() {
        return `${SdkConfig.ApiPath}/Filter/GetParkFilters`
      },
      getContactFilters() {
        return `${SdkConfig.ApiPath}/Filter/GetContactFilters`
      },
      getCompanyFiltes() {
        return `${SdkConfig.ApiPath}/Filter/GetCompanyFilters`
      },
    },
    parkNotes: {
      addParkNote() {
        return `${SdkConfig.ApiPath}/UserParkNote`
      },
      editParkNote(noteId: string) {
        return `${SdkConfig.ApiPath}/UserParkNote/${noteId}`
      },
      deleteParkNote(noteId: string) {
        return `${SdkConfig.ApiPath}/UserParkNote/${noteId}`
      },
      getParkNotes(parkId: string, query: string) {
        return `${SdkConfig.ApiPath}/UserParkNote/${parkId}?query=${query}`
      },
    },
    parkLabels: {
      addParkLabel() {
        return `${SdkConfig.ApiPath}/UserParkLabel`
      },
      editParkLabel(parkId: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/${parkId}`
      },
      deleteParkLabel(labelId: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/${labelId}`
      },
      getUserParkLabels() {
        return `${SdkConfig.ApiPath}/UserParkLabel`
      },
      addUserLabelledPark() {
        return `${SdkConfig.ApiPath}/UserParkLabel/AddLabelledPark`
      },
      getUserLabelledParks(query: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/GetUserLabelledParks${!!query ? '?query=' + query : ''}`
      },
      GetLabelledParksByLabelLabelId(labelId: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/GetUserLabelledParksByLabelId/${labelId}`
      },
      removeUserLabelledPark(id: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/DeleteLabeledPark/${id}`
      },
      removeAllParksFromList() {
        return `${SdkConfig.ApiPath}/UserParkLabel/DeleteAllLabeledParks`
      },
      removeUserLabelledParkByParkId(parkId: string) {
        return `${SdkConfig.ApiPath}/UserParkLabel/DeleteLabeledParkByParkId/${parkId}`
      },
    },
    tags: {
      addTag() {
        return `${SdkConfig.ApiPath}/Tag`
      },
      editTag() {
        return `${SdkConfig.ApiPath}/Tag`
      },
      getTags(tagType: string) {
        return `${SdkConfig.ApiPath}/Tag/GetUserTags/${tagType}`
      },
      removeTag(tagId: string) {
        return `${SdkConfig.ApiPath}/Tag/${tagId}`
      },
    },
    taggedParks: {
      getUserTaggedParks(query?: string) {
        return `${SdkConfig.ApiPath}/UserTaggedParks${!!query ? '?query=' + query : ''}`
      },
      GetTaggedParksByTagId(tagId: string) {
        return `${SdkConfig.ApiPath}/UserTaggedParks/UserTaggedParksByTagId/${tagId}`
      },
      removeAllTaggedPark() {
        return `${SdkConfig.ApiPath}/UserTaggedParks/RemoveAllTaggedParks`
      },
      removeAllTagsFromPark(parkId: string) {
        return `${SdkConfig.ApiPath}/UserTaggedParks/RemoveAllTagsFromPark/${parkId}`
      },
      addParkTag() {
        return `${SdkConfig.ApiPath}/UserTaggedParks/AddParkTag`
      },
      getParkTags(parkId: string) {
        return `${SdkConfig.ApiPath}/UserTaggedParks/ParkTags/${parkId}`
      },
      removeParktag(parkId: string, tagId: string) {
        return `${SdkConfig.ApiPath}/UserTaggedParks/RemoveTagFromPark/${parkId}/${tagId}`
      },
    },
  },
  crm: {
    contacts: {
      addCrmContact() {
        return `${SdkConfig.ApiPath}/CrmContacts`
      },
      getCrmContacts(filter: PaginationFilterModel) {
        return `${SdkConfig.ApiPath}/CrmContacts/GetUserContacts?${objToQuery(filter)}`
      },
      getCrmContactById(contactId: string) {
        return `${SdkConfig.ApiPath}/CrmContacts/${contactId}`
      },
      isContactExist(email: string, id?: string) {
        return `${SdkConfig.ApiPath}/CrmContacts/IsUserContactExist?email=${encodeURIComponent(email)}&id=${id ?? ''}`
      },
      editCrmContact() {
        return `${SdkConfig.ApiPath}/CrmContacts`
      },
      deleteCrmContactById(contactId: string) {
        return `${SdkConfig.ApiPath}/CrmContacts/DeleteContact/${contactId}`
      },
      deleteCrmContacts() {
        return `${SdkConfig.ApiPath}/CrmContacts/DeleteContacts`
      },
      deleteAllCrmContacts() {
        return `${SdkConfig.ApiPath}/CrmContacts/DeleteAllContacts`
      },
      exportCrmContacts() {
        return `${SdkConfig.ApiPath}/CrmContacts/ExportCrmContacts`
      },
      applyContactFilter() {
        return `${SdkConfig.ApiPath}/Filter/ApplyContactFilter`
      },
    },
    companies: {
      getCrmCompanyById(companyId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanies/${companyId}`
      },
      getCrmCompanies(filter: PaginationFilterModel) {
        return `${SdkConfig.ApiPath}/CrmCompanies?${objToQuery(filter)}`
      },
      getCrmCompanyDetails(companyId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanies/${companyId}`
      },
      addCrmCompany() {
        return `${SdkConfig.ApiPath}/CrmCompanies`
      },
      editCrmCompany(companyId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanies/${companyId}`
      },
      isCompanyExist(CompanyName: string) {
        return `${SdkConfig.ApiPath}/CrmCompanies/IsUserCompanyExist?companyName=${CompanyName}`
      },
      deleteCompanies() {
        return `${SdkConfig.ApiPath}/CrmCompanies/DeleteCompanies`
      },
      deleteAllCompanies() {
        return `${SdkConfig.ApiPath}/CrmCompanies/DeleteAllCompanies`
      },
    },
    notes: {
      getNotes(type: 'Contact' | 'Company', typeId: string) {
        return `${SdkConfig.ApiPath}/CrmNotes/${type}/${typeId}`
      },
      addNote(type: 'Contact' | 'Company', typeId: string) {
        return `${SdkConfig.ApiPath}/CrmNotes/${type}/${typeId}`
      },
      updateNote(noteId: string) {
        return `${SdkConfig.ApiPath}/CrmNotes/${noteId}`
      },
      deleteNote(noteId: string) {
        return `${SdkConfig.ApiPath}/CrmNotes/${noteId}`
      },
    },
    companyLinkedContacts: {
      getContactLinkedCompanies(contactId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanyLinkedContacts/GetContactLinkedCompanies/${contactId}`
      },
      getCompanyLinkedContacts(companyId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanyLinkedContacts/GetCompanyLinkedContacts/${companyId}`
      },
      getContactUnlinkedCompanies(contactId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanyLinkedContacts/GetContactUnlinkedCompanies/${contactId}`
      },
      contactLinkCompany() {
        return `${SdkConfig.ApiPath}/CrmCompanyLinkedContacts`
      },
      unlinkContactLinkedCompany(contactId: string, companyId: string) {
        return `${SdkConfig.ApiPath}/CrmCompanyLinkedContacts/UnlinkCompanyContact/Company/${companyId}/Contact/${contactId}`
      },
    },
    contactLinkedTags: {
      getCrmContactTags(contactId: string, type: TagTypes) {
        return `${SdkConfig.ApiPath}/CrmContactTags/${contactId}/${type}`
      },
      linkContactTag() {
        return `${SdkConfig.ApiPath}/CrmContactTags`
      },
      unlinkContactTag(linkId: string) {
        return `${SdkConfig.ApiPath}/CrmContactTags/${linkId}`
      },
    },
    contactLinkedParks: {
      LinkParkSiteToContact() {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/LinkParkSiteToContact`
      },
      unlinkContactLinkedPark(contactId: string, parkId: string) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/Unlink/${contactId}/ParkSite/${parkId}`
      },
      getContactLinkedParks(contactId: string) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/GetContactLinkedParkSites/${contactId}`
      },
      getParkLinkedContacts(parkId: string, filter: PaginationFilterModel) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/GetParkSiteLinkedContacts/${parkId}?${objToQuery(filter)}`
      },
      getParksWithCrm(searchQuery: string) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/GetParksWithCrm?search=${searchQuery}`
      },
      UnlinkAllContactsFromParkByLinkId(linkId: string) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/UnlinkAllContactsFromParkByLinkId/${linkId}`
      },
      UnlinkAllContactsFromAllPark() {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/UnlinkAllContactsFromAllPark`
      },
      GetUnlinkedCrmContactsByParkId(parkId: string) {
        return `${SdkConfig.ApiPath}/CrmContactLinkedParkSites/GetUnlinkedCrmContactsByParkId/${parkId}`
      },
    },
  },
  peopleInsight: {
    getParkPeopleInsights(parkId: string, filter: PaginationFilterModel) {
      return `${SdkConfig.ApiPath}/PeopleInsight/${parkId}?${objToQuery(filter)}`
    },
    addParkPeopleInsight() {
      return `${SdkConfig.ApiPath}/PeopleInsight`
    },
    linkPeopleInsightToPark(peopleInsightId: string, parkId: string) {
      return `${SdkConfig.ApiPath}/PeopleInsight/LinkPeopleInsightToParkSite/${peopleInsightId}/${parkId}`
    },
    getPossiblePeopleInsightsWithPark(parkId: string, queryKey: string, value: string) {
      return `${SdkConfig.ApiPath}/PeopleInsight/GetPossiblePeopleInsights/${parkId}?queryKey=${queryKey}&value=${value}`
    },
    detachParkPeopleInsight(parkId: string, peopleId: string) {
      return `${SdkConfig.ApiPath}/PeopleInsight/DetachPeopleInsightFromPark/${parkId}/${peopleId}`
    },
    editParkPeopleInsight() {
      return `${SdkConfig.ApiPath}/PeopleInsight`
    },
    addPeopleInsightToCrm() {
      return `${SdkConfig.ApiPath}/PeopleInsight/AddPeopleInsightToCrm`
    },
  },
  zapier: {
    generateApiKey(userId: string) {
      return `${SdkConfig.ApiPath}/Zapier/GenerateApiKey/${userId}`
    },
    GetZapierInfo(userId: string) {
      return `${SdkConfig.ApiPath}/Zapier/GetZapierInfo/${userId}`
    },
  },
}
