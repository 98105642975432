import { TagTypes } from 'globals/constants'
import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'
import { CrmContactLinkedTagViewModel, CrmcontactLinkedTagPayload } from 'sdk/models'

const baseApi = new BaseApi()
export class CrmContactTagApi {
  public AddTag(tag: CrmcontactLinkedTagPayload) {
    return baseApi.POST_Request<CrmContactLinkedTagViewModel>(apiUrls.crm.contactLinkedTags.linkContactTag(), tag)
  }
  public GetTags(contactId: string, type: TagTypes) {
    return baseApi.GET_Request<Array<CrmContactLinkedTagViewModel>>(
      apiUrls.crm.contactLinkedTags.getCrmContactTags(contactId, type)
    )
  }
  public RemoveTag(linkId: string) {
    return baseApi.DELETE_Request<boolean>(apiUrls.crm.contactLinkedTags.unlinkContactTag(linkId))
  }
}
