import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CrmCompanyViewModel, CrmContactViewModel, FilterViewModel, ParkSiteMapPin } from 'sdk'
import { IAppStore } from 'store/app.store'

interface FilterModel<T> {
  Filters: Array<FilterViewModel>
  AppliedFilter?: FilterViewModel | null
  IsGettingFilters: boolean
  FilteredData: T[]
  FilterDataCount: number
  IsUnSavedFilterApplied: boolean
  IsRetractedView: boolean
  IsFilterToolOpened?: boolean
}
const FilterInitialState = {
  Filters: [],
  AppliedFilter: null,
  IsUnSavedFilterApplied: false,
  IsGettingFilters: false,
  FilterDataCount: 0,
  FilteredData: [],
  IsRetractedView: false,
}
export interface FilterSliceTypes {
  Park: FilterModel<ParkSiteMapPin> & { CrmContactIds: string[] }
  CrmContact: FilterModel<CrmContactViewModel>
  CrmCompany: FilterModel<CrmCompanyViewModel>
}
const initialState: FilterSliceTypes = {
  Park: { ...FilterInitialState, CrmContactIds: [] },
  CrmContact: FilterInitialState,
  CrmCompany: FilterInitialState,
}

export const FilterSlice = createSlice({
  name: 'Filter',
  initialState,
  reducers: {
    setParkFilters: (state: FilterSliceTypes, action) => {
      state.Park.Filters = action.payload
    },
    setIsGettingParkFilters: (state: FilterSliceTypes, action) => {
      state.Park.IsGettingFilters = action.payload
    },
    setAppliedParkFilter: (state: FilterSliceTypes, action: PayloadAction<FilterViewModel | null>) => {
      state.Park.AppliedFilter = action.payload
    },
    setParkFilteredData: (state: FilterSliceTypes, action) => {
      state.Park.FilteredData = action.payload
    },
    setParkIsRetractedView: (state: FilterSliceTypes, action) => {
      state.Park.IsRetractedView = action.payload
    },
    setIsUnSavedParkFilterApplied: (state: FilterSliceTypes, action: PayloadAction<boolean>) => {
      state.Park.IsUnSavedFilterApplied = action.payload
    },
    setParkFilterCrmContactIds: (state: FilterSliceTypes, action: PayloadAction<string[]>) => {
      state.Park.CrmContactIds = action.payload
    },

    setCrmContactFilters(state: FilterSliceTypes, action) {
      state.CrmContact.Filters = action.payload
    },
    setIsGettingCrmContactFilters(state: FilterSliceTypes, action) {
      state.CrmContact.IsGettingFilters = action.payload
    },
    setAppliedCrmContactFilter(state: FilterSliceTypes, action) {
      state.CrmContact.AppliedFilter = action.payload
    },
    setCrmContactsIsRetractedView(state: FilterSliceTypes, action) {
      state.CrmContact.IsRetractedView = action.payload
    },
    setCrmContactsFilteredData(state: FilterSliceTypes, action) {
      state.CrmContact.FilteredData = action.payload
    },
    setCrmContactFilteredDataCount(state: FilterSliceTypes, action) {
      state.CrmContact.FilterDataCount = action.payload
    },
    setIsUnSavedCrmContactFilterApplied(state: FilterSliceTypes, action) {
      state.CrmContact.IsUnSavedFilterApplied = action.payload
    },
    setIsCrmContactFilterToolOpened(state: FilterSliceTypes, action) {
      state.CrmContact.IsFilterToolOpened = action.payload
    },

    setCrmCompanyFilters(state: FilterSliceTypes, action) {
      state.CrmCompany.Filters = action.payload
    },
    setIsGettingCrmCompanyFilters(state: FilterSliceTypes, action) {
      state.CrmCompany.IsGettingFilters = action.payload
    },
    setAppliedCrmCompanyFilter(state: FilterSliceTypes, action) {
      state.CrmCompany.AppliedFilter = action.payload
    },
    setCrmCompanyIsRetractedView(state: FilterSliceTypes, action) {
      state.CrmCompany.IsRetractedView = action.payload
    },
    setCrmCompanyFilteredData(state: FilterSliceTypes, action) {
      state.CrmCompany.FilteredData = action.payload
    },
    setCrmCompanyFilteredDataCount(state: FilterSliceTypes, action) {
      state.CrmCompany.FilterDataCount = action.payload
    },
    setIsUnSavedCrmCompanyFilterApplied(state: FilterSliceTypes, action) {
      state.CrmCompany.IsUnSavedFilterApplied = action.payload
    },
    setIsCrmCompanyFilterToolOpened(state: FilterSliceTypes, action) {
      state.CrmCompany.IsFilterToolOpened = action.payload
    },
  },
})
export const {
  setParkFilters,
  setAppliedParkFilter,
  setIsGettingParkFilters,
  setParkIsRetractedView,
  setParkFilteredData,
  setIsUnSavedParkFilterApplied,
  setParkFilterCrmContactIds,

  setCrmContactFilters,
  setIsGettingCrmContactFilters,
  setAppliedCrmContactFilter,
  setCrmContactsIsRetractedView,
  setCrmContactsFilteredData,
  setCrmContactFilteredDataCount,
  setIsUnSavedCrmContactFilterApplied,
  setIsCrmContactFilterToolOpened,

  setCrmCompanyFilters,
  setIsGettingCrmCompanyFilters,
  setAppliedCrmCompanyFilter,
  setCrmCompanyIsRetractedView,
  setCrmCompanyFilteredData,
  setCrmCompanyFilteredDataCount,
  setIsUnSavedCrmCompanyFilterApplied,
  setIsCrmCompanyFilterToolOpened,
} = FilterSlice.actions
export const FilterSliceSelector = (appState: IAppStore): FilterSliceTypes => appState.Filter
