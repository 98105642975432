import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'
import {
  RequestUserToJoinTeamPayload,
  TeamMemberViewModel,
  TeamNameUpdatePayload,
  TeamNameVerificationPayload,
  TeamViewModel,
} from 'sdk'

const baseApi = new BaseApi()
export class TeamApi {
  public getUserTeams() {
    return baseApi.GET_Request<Array<TeamViewModel>>(apiUrls.team.getUserTeams())
  }
  public isTeamNameTaken(payload: TeamNameVerificationPayload) {
    return baseApi.POST_Request<boolean>(apiUrls.team.isTeamNameTaken(), payload)
  }
  public updateTeamName(payload: TeamNameUpdatePayload) {
    return baseApi.PATCH_Request<boolean>(apiUrls.team.updateTeamName(), payload)
  }
  public getPaidUserTeamMembersCountByEmail(email: string) {
    return baseApi.GET_Request<number>(apiUrls.team.getPaidUserTeamsCountByEmail(email))
  }
  public requestUserToJoinTeam(payload: RequestUserToJoinTeamPayload) {
    return baseApi.POST_Request<boolean>(apiUrls.team.requestUserToJoinTeam(), payload)
  }
  public getTeamMebers(teamId: string) {
    return baseApi.GET_Request<Array<TeamMemberViewModel>>(apiUrls.team.getTeamMembers(teamId))
  }
  public revokeTeamMember(teamId: string, memberId: string) {
    return baseApi.POST_Request<boolean>(apiUrls.team.revokeTeamMember(teamId, memberId), {})
  }
  public resendInvite(teamId: string, memberId: string) {
    return baseApi.POST_Request<boolean>(apiUrls.team.ResendInvite(teamId, memberId), {})
  }
}
