import { appRoutes } from 'globals/constants'
import { CustomRoute } from 'globals/typings'
import {
  Account,
  AdminSettings,
  Auth,
  ContactDetails,
  Crm,
  CrmCompanyDetails,
  DashBoard,
  Login,
  ParkSites,
  Profile,
  SignUp,
  UnAuthorized,
} from 'views'
import { AccountSetPassword } from 'views/auth/set-password/AccountSetPassword'
export const routes: Array<CustomRoute> = [
  {
    key: appRoutes.auth.name,
    component: Auth,
    path: '/',
    redirect: appRoutes.auth.name,
    routes: [
      {
        key: appRoutes.login.name,
        component: Login,
        title: 'Login',
        path: appRoutes.login.path,
        isAuthRoute: true,
        helmet: 'Sign in to your ParkSitesIO account',
      },
      {
        key: appRoutes.signup.name,
        component: SignUp,
        title: 'SignUp',
        path: appRoutes.signup.path,
        isAuthRoute: true,
        helmet: 'Sign up for a ParkSitesIO account',
      },
      {
        key: appRoutes.changePassword.name,
        component: AccountSetPassword,
        title: 'changePassword',
        path: appRoutes.changePassword.path,
        isAuthRoute: true,
        helmet: 'Sign up for a ParkSitesIO account',
      },
    ],
  },
  {
    key: appRoutes.account.name,
    title: 'Account',
    path: appRoutes.account.path,
    component: Account,
    helmet: 'ParkSitesIO App',
    routes: [
      {
        key: appRoutes.profile.name,
        component: Profile,
        title: 'Profile',
        path: appRoutes.profile.path,
      },
      {
        key: appRoutes.dashboard.name,
        component: DashBoard,
        title: 'Dashboard',
        path: appRoutes.dashboard.path,
      },
      {
        key: appRoutes.unauthorized.name,
        title: 'Unauthorized',
        component: UnAuthorized,
        path: appRoutes.unauthorized.path,
      },
      {
        key: appRoutes.parksites.name,
        component: ParkSites,
        title: 'ParkSites',
        path: appRoutes.parksites.path,
        allowedToPaidUsersAndAdmin: true,
      },
      {
        key: appRoutes.crm.name,
        component: Crm,
        title: 'Crm',
        path: appRoutes.crm.path,
        allowedToPaidUsersAndAdmin: true,
        routes: [
          {
            path: appRoutes.crmContactDetail.path,
            key: appRoutes.crmContactDetail.name,
            component: ContactDetails,
            title: 'Contacts',
          },
          {
            path: appRoutes.crmCompanyDetail.path,
            key: appRoutes.crmCompanyDetail.name,
            component: CrmCompanyDetails,
            title: 'Companies',
          },
        ],
      },
      {
        key: appRoutes.adminSetting.name,
        component: AdminSettings,
        path: appRoutes.adminSetting.path,
        title: 'AdminSetting',
      },
    ],
  },
]
