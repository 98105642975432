import { AppBox } from 'components'
import { useManageUsers } from 'hooks'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { PaginationFilterModel, UserWithSubAccounts } from 'sdk'
import { ManageUsersTable } from './ManageUsersTable'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
  paginatedUsers: UserWithSubAccounts[]
  isGettingUsers: boolean
  totalPaginatedUsers: number
  handleManageAccountClick: (user: UserWithSubAccounts) => void
}
export const TotalUsersListing: FC<Props> = ({
  filter,
  setFilter,
  paginatedUsers,
  isGettingUsers,
  totalPaginatedUsers,
  handleManageAccountClick,
}) => {
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <ManageUsersTable
          data={paginatedUsers ?? []}
          isLoading={isGettingUsers}
          totalRecords={totalPaginatedUsers ?? 0}
          onManageClick={handleManageAccountClick}
          paginationFilter={filter}
          onPageSizeChange={(pageSize) => {
            setFilter((prev) => ({ ...prev, PageNo: 1, PageSize: pageSize }))
          }}
          onCurrentPageChange={(page) => {
            setFilter((prev) => ({ ...prev, PageNo: page }))
          }}
        />
      </AppBox>
    </>
  )
}
