import { Divider, Grid, IconButton, LinearProgress } from '@mui/material'
import { ParkListLoader } from 'components'
import { ParkSitesActivities, ParkSitesTools } from 'globals/constants'
import { getParkStatusColor } from 'globals/functions'
import { useParksListLoadMore, useRemoveParkFromList } from 'hooks'
import { useRemoveAllParksFromList } from 'hooks/park-sites/remove-all-parks-from-list-hook'
import { debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ParkListTab, SdkConfig } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, setOpenedActivity, setSelectedParkId } from 'store/slices'
import { AppBox, AppButton, AppCard, AppIcon, AppMenu, AppTypography, SimpleField } from '../../base'
import { BaseComponent } from '../base-component/base.component'
import { PhoneText } from '../phone-text/PhoneText'

interface ParksListProps {
  linkedParks: ParkListTab[]
  isLoading: boolean
  noParksComponent: JSX.Element
  refetch: (query?: string) => void
}
export const ParksList = ({ linkedParks, isLoading, noParksComponent, refetch }: ParksListProps) => {
  const parkDispatch = useAppDispatch()
  const { handleRemovePark, isRemovingParkFromList } = useRemoveParkFromList(refetch)
  const { handleRemoveAllParks, isRemovingAllParksFromList } = useRemoveAllParksFromList(refetch)
  const { LoadMore } = useParksListLoadMore()
  const { openedTool, selectedParkId } = useSelector(ParkSiteToolSliceSelector)

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [query, setQuery] = useState<string>('')
  const [pageNo, setPageNo] = useState<number>(1)

  const removeBtnText = () => {
    switch (openedTool) {
      case ParkSitesTools.PinnedParks:
        return 'Clear All Pins'
      case ParkSitesTools.TaggedParks:
        return 'Clear All Tags'
      default:
        return 'Clear All Parks'
    }
  }

  const handleViewPark = (parkId: string) => {
    parkDispatch(setSelectedParkId(parkId))
  }
  const redirectToCrmContacts = (parkId: string) => {
    parkDispatch(setSelectedParkId(parkId))
    parkDispatch(setOpenedActivity(ParkSitesActivities.ParkActivityCrm))
  }

  const handleSearchPark = debounce((search: string) => {
    // setQuery(search)
    refetch(search)
  }, 500)
  const showableParks = useMemo(() => {
    return linkedParks.slice(0, pageNo * 15)
  }, [linkedParks, pageNo])

  const handleLoadMore = () => {
    setPageNo(pageNo + 1)
    LoadMore()
  }
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} height={'inherit'} paddingX={2}>
        {(!!query || !!showableParks.length) && (
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={7}>
              <SimpleField
                style={{ backgroundColor: 'white', borderRadius: '10px' }}
                prefixIcon="iconamoon:search-bold"
                placeholder="Search"
                suffixIcon={query ? 'carbon:close-filled' : ''}
                value={query}
                suffixIconProps={{
                  onClick: () => {
                    setQuery('')
                    handleSearchPark('')
                  },
                }}
                onChange={(event) => {
                  setQuery(event.target.value)
                  handleSearchPark(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <AppButton
                color="secondary"
                className="mr-4"
                onClick={() => handleRemoveAllParks(linkedParks.length)}
                prefixIcon="tabler:trash"
              >
                {removeBtnText()}
              </AppButton>
            </Grid>
          </Grid>
        )}
        <BaseComponent
          isLoading={isLoading}
          isData={!!linkedParks && linkedParks.length > 0}
          loaderComponent={<>{<ParkListLoader />}</>}
          noDataComponent={
            <>
              {!query ? (
                <> {noParksComponent}</>
              ) : (
                <>
                  <AppBox width={'100%'} paddingTop={8} textAlign={'center'}>
                    <AppTypography fontSize={14} fontWeight={500} color={'grey.600'}>
                      No Matching Records
                    </AppTypography>
                  </AppBox>
                </>
              )}
            </>
          }
        >
          <AppBox maxHeight={'68vh'} marginBottom={0} style={{ overflowY: 'auto' }}>
            <AppBox width="100%">
              {(isRemovingParkFromList || isRemovingAllParksFromList) && <LinearProgress color="primary" />}
            </AppBox>
            <>
              {showableParks.map((linkedPark, parkIndex) => (
                <>
                  <AppCard
                    key={parkIndex}
                    onMouseEnter={() => {
                      setSelectedIndex(parkIndex)
                    }}
                    elevation={selectedIndex === parkIndex || selectedParkId === linkedPark.ParkId ? 5 : 0}
                    className="my-4 p-2"
                    style={selectedParkId === linkedPark.ParkId ? { borderLeft: '6px solid #0067D0' } : {}}
                  >
                    <AppBox display="flex" alignContent="center" gap={2}>
                      <AppBox style={{ width: '130px', height: '80px' }}>
                        <AppBox
                          style={{
                            width: '130px',
                            height: '80px',
                            backgroundImage: `url(${linkedPark.ThumbnailUrl ? SdkConfig.ApiBaseUrl + '/' + linkedPark.ThumbnailUrl : '/images/no-park-image.png'})`,
                            backgroundSize: 'cover', // Cover the entire area of the box
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            borderRadius: 10,
                          }}
                        />
                      </AppBox>
                      <AppBox width={'100%'}>
                        <AppBox display={'flex'} justifyContent={'space-between'}>
                          <AppBox display={'flex'} gap={1}>
                            <AppTypography component={'span'} fontWeight={600} lineHeight={'22px'} fontSize={'14px'}>
                              {linkedPark.Title}
                            </AppTypography>
                            <AppIcon icon="carbon:view" onClick={() => handleViewPark(linkedPark.ParkId)} />
                          </AppBox>
                          <AppMenu
                            renderElement={(handleClick) => (
                              <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                                <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                              </IconButton>
                            )}
                            MenuItems={[
                              {
                                text: 'Remove',
                                prefixIcon: 'material-symbols:delete-outline',
                                prefixIconProps: { color: 'red' },
                                onClick: () => handleRemovePark(linkedPark.Id),
                              },
                            ]}
                          />
                        </AppBox>
                        <AppTypography>{linkedPark.Address}</AppTypography>
                        <AppTypography>
                          {linkedPark.City}, {linkedPark.State} {linkedPark.Zip}
                        </AppTypography>

                        <AppBox display={'flex'} gap={2}>
                          <AppBox If={!!linkedPark.ParkPhone}>
                            <AppTypography>
                              Park Phone: <PhoneText phone={linkedPark.ParkPhone} />
                            </AppTypography>
                          </AppBox>
                          {linkedPark.CrmContactCount && (
                            <AppTypography>
                              CRM Records:{' '}
                              <AppTypography
                                component={'a'}
                                color={'primary'}
                                onClick={() => {
                                  redirectToCrmContacts(linkedPark.ParkId)
                                }}
                              >
                                {linkedPark.CrmContactCount}
                              </AppTypography>
                            </AppTypography>
                          )}
                        </AppBox>
                        <AppBox display={'flex'} alignItems={'center'} gap={1}>
                          <AppTypography color={'grey'}>Status:</AppTypography>
                          <AppBox
                            backgroundColor={getParkStatusColor(linkedPark.ParkStatus)}
                            height={10}
                            width={10}
                            borderRadius={50}
                          />
                          <AppTypography>{linkedPark.ParkStatus}</AppTypography>
                          {linkedPark.Color && (
                            <>
                              <Divider orientation="vertical" flexItem />
                              <AppTypography color={'grey'}>Pinned:</AppTypography>
                              <AppBox backgroundColor={linkedPark.Color} height={10} width={10} borderRadius={50} />
                              <AppTypography>{linkedPark.Label ?? 'Pinned'}</AppTypography>
                            </>
                          )}
                        </AppBox>
                      </AppBox>
                    </AppBox>
                  </AppCard>
                </>
              ))}
              {showableParks.length !== linkedParks.length && (
                <AppBox paddingBottom={2}>
                  <AppButton variant="contained" fullWidth onClick={handleLoadMore}>
                    Show More
                  </AppButton>
                </AppBox>
              )}
            </>
          </AppBox>
        </BaseComponent>
      </AppBox>
    </>
  )
}
