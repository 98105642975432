import { Controller } from 'react-hook-form'
import { BaseInputProps } from 'globals/typings/baseComponentTypes/BaseComponentTypes'
import { getValidationRules } from 'plugins/helper/helper'
import { AppSimpleFieldProps, SimpleField } from '../simple-fields/simple-field/SimpleField'

export interface AppTextFieldProps extends BaseInputProps, AppSimpleFieldProps {
  onFieldChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const AppTextField = ({ required, control, customRules, onFieldChange, ...props }: AppTextFieldProps) => {
  return (
    <>
      <Controller
        name={props.name!}
        defaultValue={props.defaultValue ?? ''}
        control={control}
        rules={{
          required: required,
          validate: (value) => getValidationRules(value, props.name!, customRules ?? {}),
        }}
        render={({ fieldState: { error }, field: { onChange, ...fieldRest } }) => (
          <SimpleField
            size="small"
            error={!!error}
            required={required}
            errorText={error?.message?.toString() ?? ''}
            {...props}
            {...fieldRest}
            onChange={(e) => {
              onChange(e)
              if (onFieldChange) {
                onFieldChange(e)
              }
            }}
          />
        )}
      />
    </>
  )
}
