import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, AppTypography, InfoBox, ParcelDataLoader } from 'components'
import { ApiQueryKeys, FeedbackTypes, InfoBoxSettingTypes } from 'globals/constants'
import { useFeedBack } from 'hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FeedbackPayload, ParkSitesApi } from 'sdk'
import { ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'

const parkSitesApi = new ParkSitesApi()

export const ParcelData = () => {
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [ApiQueryKeys.parksites.FetchParkParcelData],
    queryFn: () => parkSitesApi.GetParkParcelData(selectedParkId),
    select: (data) => {
      if (data) {
        return JSON.parse(data)[0]
      }
    },
    enabled: false,
  })
  useEffect(() => {
    refetch()
  }, [selectedParkId, SelectedTeam?.Id])

  const [isViewMoreClicked, setIsViewMoreClicked] = useState<boolean>(false)
  const defaultParcelKeys = [
    'parcel_id',
    'state_abbr',
    'census_zip',
    'owner',
    'mail_address1',
    'mail_address3',
    'mkt_val_land',
    'mkt_val_tot',
    'muni_id',
    'school_dist_id',
    'acreage',
    'acreage_calc',
    'elevation',
    'last_updated',
  ]
  const keysToHide = [
    'rausa_id',
    'geom_as_wkt',
    'land_cover',
    'crop_cover',
    'alt_id_1',
    'robust_id',
    'latitude',
    'longitude',
  ]
  const showableDefaultKeys = () => {
    return defaultParcelKeys.filter((x) => Object.keys(data).includes(x))
  }
  const showRestKeys = () => {
    return Object.keys(data).filter((x) => !showableDefaultKeys().includes(x) && !keysToHide.includes(x))
  }
  const getKeyNames = (key: string) => {
    let keySplit = key.split('_')
    if (key === 'acreage_adjacent_with_sameowner') {
      keySplit = keySplit.slice(0, 2)
    }
    return keySplit
      .reduce((val, curr) => {
        return val + curr[0].toUpperCase() + curr.slice(1, curr.length) + '_'
      }, '')
      .slice(0, -1)
  }
  const showableKeys = () => {
    const valueToReturn = [...showableDefaultKeys(), ...(isViewMoreClicked ? showRestKeys() : [])]
    return valueToReturn.map((x) => {
      return {
        id: x,
        name: getKeyNames(x),
      }
    })
  }
  const { SaveFeedBack, IsSaving } = useFeedBack()
  const [savedFeedBack, setSavedFeedBack] = useState<string | null>(null)

  const handleFeedBack = (feedback: 'good' | 'bad') => {
    if (!savedFeedBack) {
      setSavedFeedBack(feedback)
      const data: FeedbackPayload = {
        Type: FeedbackTypes.ParkCityDemographics,
        Value: feedback,
        PrincipalType: 'ParkSite',
        PrincipalId: selectedParkId,
      }
      SaveFeedBack(data)
    }
  }
  return (
    <AppBox display={'flex'} flexDirection={'column'} height={'100%'} paddingX={4} justifyContent={'space-between'}>
      {isLoading ? (
        <ParcelDataLoader />
      ) : !data ? (
        <AppBox display={'flex'} flexDirection={'column'} gap={2}>
          <AppBox backgroundColor="red" color={'white'} padding={2}>
            <AppTypography fontSize={14} fontWeight={600}>
              Oops...looks like we have a slight problem with this park. We’ve automatically generated a report and sent
              it to our staff. They’ll have this fixed up for you shortly.
            </AppTypography>
          </AppBox>
          <AppBox textAlign={'center'}>
            <img src="/images/pax/error.png" alt="error" />
          </AppBox>
        </AppBox>
      ) : (
        <>
          <AppBox>
            <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ParcelDataDismissed}>
              Parks can be comprised of one or more parcels while still only having one owner. We display the most
              relevant parcel. View the Acrage_Calc field to see the park’s accumulated total acres.
            </InfoBox>
            <AppBox padding={1} overflow={'auto'}>
              <AppTypography fontSize={14} color={'grey.900'} fontWeight={500} padding={0} marginY={2}>
                Parcel Details
              </AppTypography>
              <Grid container spacing={1}>
                {showableKeys().map((item) => (
                  <>
                    <Grid item xs={6}>
                      <AppTypography color={'grey.500'}> {item.name}</AppTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <AppTypography color={'grey.900'} textAlign={'end'} fontWeight={'bold'}>
                        {data[item.id as keyof typeof defaultParcelKeys]}
                      </AppTypography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </AppBox>
            <AppButton onClick={() => setIsViewMoreClicked(!isViewMoreClicked)}>
              View {isViewMoreClicked ? 'Less' : 'More'}
            </AppButton>
          </AppBox>
          <AppBox
            borderRadius={2}
            border={1}
            padding={1}
            borderColor={'grey.200'}
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <AppTypography color={'grey.500'} fontSize={14}>
              How would you rate this information?
            </AppTypography>
            <AppBox gap={2} display={'flex'} paddingRight={1}>
              <AppIcon
                color={savedFeedBack === 'good' ? 'blue' : 'grey'}
                icon={'octicon:thumbsup-16'}
                size="large"
                onClick={() => handleFeedBack('good')}
              />
              <AppIcon
                color={savedFeedBack === 'bad' ? 'blue' : 'grey'}
                icon={'octicon:thumbsdown-16'}
                size="large"
                onClick={() => handleFeedBack('bad')}
              />
            </AppBox>
          </AppBox>
        </>
      )}
    </AppBox>
  )
}
