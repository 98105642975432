import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ApiErrorModel, ChangeParkLocationPayload, ParkSiteMapPin, ParkSitesApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  GoogleMapSliceSelector,
  setIsLoadingMap,
  setParkPins,
  setSelectedParkId,
  setSelectedParkIdToMove,
} from 'store/slices'

export const useChangeParkLocation = () => {
  const parkSiteApi = new ParkSitesApi()
  const { enqueueSnackbar } = useSnackbar()
  const { SelectedParkIdToMove, ParkPins } = useSelector(GoogleMapSliceSelector)
  const { control, handleSubmit, reset, setValue, watch } = useForm<ChangeParkLocationPayload>()
  const dispatch = useAppDispatch()
  const dialogRef = useRef<AppDialogRefType>(null)

  const { mutate: changeParkLocation, isPending: isChangingLocation } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.ChangeParkLocation],
    mutationFn: (data: ChangeParkLocationPayload) => parkSiteApi.changeParkLocation(data),
    onError: (err: ApiErrorModel) => {
      enqueueSnackbar(err.message, { variant: 'error' })
      dispatch(setSelectedParkIdToMove(undefined))
    },
    onSuccess: () => {
      dialogRef.current?.closeDialog!()
      dispatch(setIsLoadingMap(true))

      const clone = JSON.parse(JSON.stringify(ParkPins)) as ParkSiteMapPin[]
      const updatedPark = clone.find((x) => x.I === SelectedParkIdToMove)
      if (updatedPark) {
        updatedPark.L = watch().Lat
        updatedPark.N = watch().Lng
      }
      dispatch(setParkPins(clone))
      dispatch(setIsLoadingMap(false))
      dispatch(setSelectedParkId(SelectedParkIdToMove!))
      dispatch(setSelectedParkIdToMove(undefined))
    },
  })

  const handleSave = () => {
    changeParkLocation(watch())
  }
  return { handleSave, isChangingLocation, control, reset, handleSubmit, setValue, watch, dialogRef }
}
