import { AppBox, SimpleField } from 'components'
import { Dispatch, FC, SetStateAction } from 'react'
import { PaginationFilterModel } from 'sdk'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const CityDataActions: FC<Props> = ({ filter, setFilter }) => {
  return (
    <>
      <AppBox display={'flex'} alignItems={'center'} gap={1}>
        <SimpleField prefixIcon="iconamoon:search-bold" placeholder="Search Contacts" />
      </AppBox>
    </>
  )
}
