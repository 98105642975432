import { Grid, IconButton, Tooltip } from '@mui/material'
import { AppBox, AppButton, AppIcon, AppMenu, AppTypography, SimpleField, SimpleRadioGroup } from 'components'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { FC, useEffect } from 'react'
import { FilterConditionSaveModel, PaginationFilterModel } from 'sdk'
import { AddFilterMenu } from './add-filter-menu/AddFilterMenu'
import { AddedFilterButton } from './filter-buttons/AddedFilterButton'
import { UnSelectedDefaultFilterButton } from './filter-buttons/UnSelectedDefaultFilterButton'

interface Props {
  type: FILTER_PRINCIPAL_TYPES
  setIsApplyingFilter: (value: boolean) => void
  paginationFilter?: PaginationFilterModel
}
export const FilterBuiler: FC<Props> = ({ type, setIsApplyingFilter, paginationFilter }) => {
  const {
    filterData,
    filterGroupsData,
    filterPrincipalTypes,
    appliedSavedFilter,
    isFilterApplied,
    defaultFields,
    fieldByCoumnAndTableName,
    dropDownItems,
    saveFilter,
    isSavingNewFilter,
    applyFilter,
    handleEditName,
    closeAndClearFilter,
    exportParkCrmContacts,
    handleClear,
    handleSaveClick,
    getFilterValue,
    getCategories,
    handleRemoveFilter,
    handleUpdateFilter,
    visibleCategoryFields,
    isParkFilterHasCrmContacts,
    ParkFilteredCrmContactIds,
    isExporting,
    selectedDefaultFilter,
    addNewFilter,
    filters,
    filterResult,
    filteredAddedFilters,
    addedPrincipalType,
    addedGroupType,
    onFilterPrincipalTypeChange,
    isRetracted,
    handleExpand,
    handleRetract,
    setFilterNameToBeEdited,
    filterNameToBeEdited,
    setTempNewFilterName,
    tempNewFilterName,
    recordLabel,
    conditionSigns,
  } = useFilterBuilder({ type, setIsApplyingFilter, paginationFilter })

  return (
    <>
      <AppBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={1}>
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          {isRetracted() ? (
            <Tooltip title="Expand Filter" placement="right">
              <IconButton size="small">
                <AppIcon
                  size="large"
                  icon={'solar:square-double-alt-arrow-down-linear'}
                  color="blue"
                  onClick={() => handleExpand()}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Minimise Filter" placement="right">
              <IconButton size="small">
                <AppIcon
                  size="large"
                  icon="solar:square-double-alt-arrow-up-linear"
                  color="blue"
                  onClick={() => handleRetract()}
                />
              </IconButton>
            </Tooltip>
          )}
          <AppTypography fontSize={16}>{appliedSavedFilter() ? 'Filter Name : ' : 'Filter Options'} </AppTypography>
          {appliedSavedFilter() && (
            <>
              {filterNameToBeEdited ? (
                <AppBox display={'flex'} alignItems={'center'} gap={1} borderColor={'grey.300'} paddingBottom={1}>
                  <SimpleField
                    variant="standard"
                    defaultValue={appliedSavedFilter()?.Name!}
                    onChange={(event) => setTempNewFilterName(event.target.value)}
                  />
                  {!!tempNewFilterName && tempNewFilterName !== appliedSavedFilter()?.Name && (
                    <AppIcon
                      icon={'icon-park-solid:check-one'}
                      size="large"
                      color="blue"
                      onClick={() => handleEditName(tempNewFilterName, setFilterNameToBeEdited)}
                    />
                  )}
                  <AppIcon
                    icon={'mdi:cross-circle'}
                    size="large"
                    color="red"
                    onClick={() => setFilterNameToBeEdited(false)}
                  />
                </AppBox>
              ) : (
                <AppBox cursor="pointer" onClick={() => setFilterNameToBeEdited(true)}>
                  <AppTypography fontSize={16} fontWeight={400} color={'blue'} style={{ textDecoration: 'underline' }}>
                    {appliedSavedFilter()!.Name}
                  </AppTypography>
                </AppBox>
              )}
            </>
          )}
        </AppBox>
        <AppBox display={'flex'} alignItems={'center'} gap={2}>
          {isFilterApplied() && (
            <>
              <AppTypography
                fontSize={14}
              >{`${recordLabel()}: ${(filterResult()?.length ?? 0).toLocaleString()}`}</AppTypography>
              <AppButton
                variant="contained"
                color="primary"
                onClick={exportParkCrmContacts}
                prefixIcon="pajamas:import"
                isLoading={isExporting}
                prefixIconProps={{ style: { marginBottom: 2 } }}
                disabled={!isParkFilterHasCrmContacts()}
              >
                CRM List ({ParkFilteredCrmContactIds().length})
              </AppButton>
            </>
          )}
          <AppButton
            variant="contained"
            color="error"
            onClick={closeAndClearFilter}
            prefixIcon="radix-icons:cross-circled"
          >
            Close
          </AppButton>
        </AppBox>
      </AppBox>
      {!isRetracted() && (
        <Grid
          container
          display={'flex'}
          alignItems={'flex-end'}
          gap={1}
          paddingY={1}
          borderTop={1}
          borderColor={'grey.200'}
        >
          <>
            {!!filterPrincipalTypes() && (
              <Grid item>
                <AppMenu
                  renderElement={(openMenu) => (
                    <AppBox
                      padding={1}
                      paddingX={2}
                      borderRadius={1}
                      color={!!addedPrincipalType() ? 'primary.800' : 'grey.800'}
                      backgroundColor={addedPrincipalType() ? 'primary.50' : 'grey.200'}
                      alignItems={'center'}
                      display={'flex'}
                      gap={1}
                      onClick={openMenu}
                    >
                      {!!addedPrincipalType() && (
                        <AppIcon
                          icon={'eva:close-outline'}
                          size="medium"
                          color="red"
                          onClick={() => {
                            const index = filters.findIndex((x) => x.Origin === 'principalType')
                            handleRemoveFilter(index)
                          }}
                        />
                      )}

                      {addedPrincipalType() ? (
                        <AppTypography fontSize={14} fontWeight={600}>
                          {addedPrincipalType()?.ColumnName}
                        </AppTypography>
                      ) : (
                        <AppTypography fontSize={14} fontWeight={600}>
                          {filterPrincipalTypes()?.Label}
                        </AppTypography>
                      )}
                      <AppIcon icon={'mdi:chevron-down'} size="medium" color={'grey.800'} />
                    </AppBox>
                  )}
                  MenuComponent={(closeMenu) => (
                    <SimpleRadioGroup
                      options={filterPrincipalTypes()?.Options ?? []}
                      value={addedPrincipalType()?.ColumnName}
                      onChange={(value) => {
                        closeMenu()
                        onFilterPrincipalTypeChange(value)
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {filterGroupsData().map((group, index) => {
              return (
                <Grid item key={index}>
                  <AppMenu
                    renderElement={(openMenu) => (
                      <AppBox
                        padding={1}
                        paddingX={2}
                        borderRadius={1}
                        color={addedGroupType().length > 0 ? 'primary.800' : 'grey.800'}
                        backgroundColor={addedGroupType().length > 0 ? 'primary.50' : 'grey.200'}
                        alignItems={'center'}
                        display={'flex'}
                        gap={1}
                      >
                        {addedGroupType().length > 0 ? (
                          addedGroupType().map((p) => (
                            <>
                              <AppMenu
                                MenuComponent={(closeMenu) => (
                                  <AddFilterMenu
                                    categories={[]}
                                    type={type}
                                    addedFilters={filters}
                                    closeMenu={closeMenu}
                                    onSaveNewFilter={(updatedFilter) => {
                                      closeMenu()
                                      handleUpdateFilter(updatedFilter)
                                    }}
                                    filterToUpdate={p}
                                    updateFilterStep="value"
                                  />
                                )}
                                renderElement={(openValueMenu) => (
                                  <AppBox display={'flex'} alignItems={'center'} gap={0.5}>
                                    <AppBox
                                      borderRadius={1}
                                      color={'primary.800'}
                                      backgroundColor="primary.50"
                                      display={'flex'}
                                      gap={1}
                                    >
                                      <AppIcon
                                        icon={'eva:close-outline'}
                                        size="medium"
                                        color="red"
                                        onClick={() => {
                                          const index = filters.findIndex(
                                            (x) =>
                                              x.Origin === 'group' &&
                                              x.TableName === p.TableName &&
                                              x.ColumnName === p.ColumnName
                                          )
                                          handleRemoveFilter(index)
                                        }}
                                      />

                                      <AppTypography
                                        fontSize={14}
                                        fontWeight={600}
                                        onClick={(e) => {
                                          openMenu(e)
                                        }}
                                      >
                                        {fieldByCoumnAndTableName(p.ColumnName!, p.TableName!)?.Label}
                                      </AppTypography>
                                      <AppMenu
                                        renderElement={(openChildMenu) => (
                                          <AppBox
                                            cursor="pointer"
                                            onClick={(event) => {
                                              event.stopPropagation()
                                              openChildMenu(event)
                                            }}
                                          >
                                            <AppTypography fontSize={14} fontWeight={600}>
                                              {conditionSigns[p.Condition as keyof typeof conditionSigns]}
                                            </AppTypography>
                                          </AppBox>
                                        )}
                                        MenuComponent={(closeMenu) => (
                                          <AddFilterMenu
                                            categories={[]}
                                            type={type}
                                            addedFilters={filters}
                                            closeMenu={closeMenu}
                                            onSaveNewFilter={(updatedFilter) => {
                                              closeMenu()
                                              handleUpdateFilter(updatedFilter)
                                            }}
                                            filterToUpdate={p}
                                            updateFilterStep="condition"
                                          />
                                        )}
                                      />
                                      <AppBox
                                        onClick={(e) => {
                                          openValueMenu(e)
                                        }}
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={0.5}
                                      >
                                        <AppTypography fontSize={14} fontWeight={600}>
                                          {getFilterValue(p)}
                                        </AppTypography>
                                      </AppBox>
                                    </AppBox>
                                  </AppBox>
                                )}
                              />
                            </>
                          ))
                        ) : (
                          <AppBox onClick={openMenu} display={'flex'} gap={0.5}>
                            <AppTypography fontSize={14} fontWeight={600}>
                              {group.Label}
                            </AppTypography>
                            <AppIcon icon={'mdi:chevron-down'} size="medium" color={'grey.800'} />
                          </AppBox>
                        )}
                      </AppBox>
                    )}
                    MenuComponent={(closeMenu) => (
                      <AddFilterMenu
                        categories={getCategories('group', group.Label)}
                        type={type}
                        addedFilters={filters}
                        closeMenu={closeMenu}
                        onSaveNewFilter={(filter) => addNewFilter(filter, 'group')}
                      />
                    )}
                  ></AppMenu>
                </Grid>
              )
            })}
            {defaultFields().map((field, index) => {
              {
                return !!selectedDefaultFilter(field) ? (
                  <AddedFilterButton
                    key={index}
                    type={type}
                    filter={selectedDefaultFilter(field)!}
                    addedfilters={filters}
                    addNewFilter={addNewFilter}
                    getFilterValue={(filter: FilterConditionSaveModel) => getFilterValue(filter) || ''}
                    handleRemoveFilter={() => {
                      const selected = selectedDefaultFilter(field)
                      const i = filters.findIndex(
                        (x) => x.ColumnName === selected!.ColumnName && x.TableName === selected!.TableName
                      )
                      handleRemoveFilter(i)
                    }}
                    handleUpdateFilter={(updatedFilter, closeMenu) => {
                      closeMenu()
                      handleUpdateFilter(updatedFilter)
                    }}
                  />
                ) : (
                  <UnSelectedDefaultFilterButton
                    key={index}
                    type={type}
                    field={field}
                    addedFilters={filters}
                    addNewFilter={addNewFilter}
                  />
                )
              }
            })}
            {filteredAddedFilters.map((filter, index) => (
              <AddedFilterButton
                key={index}
                type={type}
                filter={filter}
                addedfilters={filters}
                addNewFilter={addNewFilter}
                getFilterValue={(filter: FilterConditionSaveModel) => getFilterValue(filter) || ''}
                handleRemoveFilter={() => {
                  const i = filters.findIndex(
                    (x) => x.ColumnName === filter.ColumnName && x.TableName === filter.TableName
                  )
                  handleRemoveFilter(i)
                }}
                handleUpdateFilter={(updatedFilter, closeMenu) => {
                  closeMenu()
                  handleUpdateFilter(updatedFilter)
                }}
              />
            ))}
          </>
          <AppMenu
            renderElement={(openMenu) => (
              <AppBox
                padding={1}
                borderRadius={1}
                backgroundColor="grey.200"
                display={'flex'}
                gap={1}
                alignItems={'center'}
                cursor="pointer"
                onClick={openMenu}
              >
                <AppTypography color={'grey.800'} fontSize={15} fontWeight={600}>
                  More
                </AppTypography>
                <AppIcon icon="pepicons-pop:plus" color={'grey.800'} />
              </AppBox>
            )}
            MenuComponent={(closeMenu) => (
              <AddFilterMenu
                categories={getCategories('all')}
                type={type}
                addedFilters={filters}
                closeMenu={() => closeMenu()}
                onSaveNewFilter={addNewFilter}
              />
            )}
          ></AppMenu>
          {filters.length > 0 && (
            <>
              {!appliedSavedFilter() && (
                <AppButton variant="text" onClick={handleSaveClick} isLoading={isSavingNewFilter}>
                  Save Filter
                </AppButton>
              )}
              <AppButton variant="text" color="error" onClick={handleClear}>
                Clear All
              </AppButton>
            </>
          )}
        </Grid>
      )}
    </>
  )
}
