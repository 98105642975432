import { MembershipTypes, UserMembershipStatus } from 'globals/constants'
import { useSelector } from 'react-redux'
import { AuthSliceSelector } from 'store/slices'

export const useMembershipHelper = () => {
  const { Memberships, TrialExpiry } = useSelector(AuthSliceSelector)

  const ActiveMembership = () => {
    return Memberships.find((membership) => membership.Status === UserMembershipStatus.ACTIVE)
  }
  const FutureMembership = () => {
    return Memberships.find((membership) => membership.Status === UserMembershipStatus.FUTURE)
  }
  const SoonToBeCancelledMembership = () => {
    return Memberships.find((membership) => membership.Status === UserMembershipStatus.CANCELLED_SOON)
  }
  const userMembershipSlugs = () => Memberships.map((membership) => membership.MembershipSlug)
  const paidMembershipSlugs = () => [
    MembershipTypes.AnnualPremiumMembership,
    MembershipTypes.PremiumMembership,
    MembershipTypes.ProMembership,
    MembershipTypes.AnnualProMembership,
  ]
  const IsPaidMember = () => {
    return userMembershipSlugs().find((slug) => paidMembershipSlugs().includes(slug as MembershipTypes))
  }
  return {
    ActiveMembership,
    FutureMembership,
    SoonToBeCancelledMembership,
    userMembershipSlugs,
    paidMembershipSlugs,
    IsPaidMember,
  }
}
