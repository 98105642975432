import { AppBox, AppTableProps } from 'components'
import { FC, useRef } from 'react'
import { CrmCompanyTable } from './CrmCompanyTable'
import { CrmCompanyViewModel } from 'sdk'

interface Props extends Omit<AppTableProps<CrmCompanyViewModel>, 'data'> {
  companies: CrmCompanyViewModel[]
}
export const CrmCompaniesListing: FC<Props> = ({ companies, ...props }) => {
  const appBoxRef = useRef<HTMLDivElement | null>(null)

  return (
    <AppBox ref={appBoxRef} minHeight={'inherit'} padding={2}>
      <AppBox
        backgroundColor="white"
        padding={3}
        minHeight={appBoxRef.current?.offsetHeight! - 180}
        borderRadius={5}
        flexGrow={1}
      >
        <CrmCompanyTable data={companies ?? []} {...props} />
      </AppBox>
    </AppBox>
  )
}
