import { Divider, Grid } from '@mui/material'
import { AppBox, AppTypography, TextWithToolTip } from 'components'
import { ApiQueryKeys, TeamStatus } from 'globals/constants'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TeamSliceSelector } from 'store/slices'
import { OutSideTeamInviteUserCard } from './cards/OutSideTeamInviteUserCard'
import { OutSideTeamMembersCard } from './cards/OutSideTeamMembersCard'
import { OutSideTeamNameCard } from './cards/TeamNamCard'
import { useMutation } from '@tanstack/react-query'
import { TeamApi } from 'sdk'
// import AppTable1 from '../base/AppTable1'
// import { columns, data } from '../data/profileOutsideTeamDataTable'
const options = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'interested',
    label: 'Interested',
  },
  {
    value: 'interested',
    label: 'Interested',
  },
  {
    value: 'interested',
    label: 'Interested',
  },
  {
    value: 'interested',
    label: 'Interested',
  },
]
export const OutsideTeamModule = () => {
  const teamApi = new TeamApi()

  const { OwnTeam } = useSelector(TeamSliceSelector)
  const isTeamActivated = useMemo(() => OwnTeam?.Status === TeamStatus.Activated, [OwnTeam])

  const {
    data: teamMembers,
    mutate: getTeamMembers,
    isPending: isGettingTeamMembers,
  } = useMutation({
    mutationKey: [ApiQueryKeys.team.FetchTeamMembers],
    mutationFn: (teamId: string) => teamApi.getTeamMebers(teamId),
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBox border={1} padding={3} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
            <TextWithToolTip tooltipText="Create a team and invite other accounts that are not related to your subscription to work in your space.">
              <AppTypography fontWeight={700} fontSize={16}>
                Outside Team
              </AppTypography>
            </TextWithToolTip>
            <Divider sx={{ marginBlock: 1 }} />
            <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
              Create a team and invite others outside your organization to work within your space. Once they accept your
              emailed invitation they’ll be able to switch to your workspace by selecting your team within their profile
              menu.
            </AppTypography>
          </AppBox>
        </Grid>
        <Grid item xs={6} display={'inline-flex'}>
          <OutSideTeamNameCard />
        </Grid>
        {isTeamActivated && (
          <>
            <Grid item xs={6} display={'inline-flex'}>
              <OutSideTeamInviteUserCard handleNewInvitationSent={() => getTeamMembers(OwnTeam?.Id!)} />
            </Grid>
            <Grid item xs={12}>
              <OutSideTeamMembersCard
                teamMembers={teamMembers ?? []}
                getTeamMembers={getTeamMembers}
                isGettingTeamMembers={isGettingTeamMembers}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
