import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import dayjs from 'dayjs'
import { ApiQueryKeys } from 'globals/constants'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm, UseFormReset, UseFormSetValue } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CrmNotePayload, CrmNoteViewModel, CrmNotesApi, ParkNoteViewModel, ShowAlert } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
interface Props {
  contactId: string | undefined
  companyId: string | undefined
  type: 'Contact' | 'Company'
}
const crmNotesApi = new CrmNotesApi()
export const useCrmNotesCRUD = (props: Props) => {
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const { control, handleSubmit, reset, setValue } = useForm<CrmNotePayload>({
    defaultValues: { ContactId: props.contactId, CompanyId: props.companyId },
  })

  const id = props.type === 'Contact' ? props.contactId : props.companyId
  const [idToBeEdited, setIdToBeEdited] = useState<string>('')
  const dialogRef = useRef<AppDialogRefType>({})

  const handleFormSubmit = (data: CrmNotePayload) => {
    const payload: CrmNotePayload = {
      Date: data.Date,
      Note: data.Note,
      ContactId: props.contactId,
      CompanyId: props.companyId,
    }
    if (idToBeEdited) {
      editNote({ noteId: idToBeEdited, payload })
    } else {
      addCrmNote({ type: props.type, typeId: id!, payload: payload })
    }
    reset()
  }
  const handleDeleteClicked = (noteId: string) => {
    ShowAlert('question', 'Are you sure you want to delete this note?').then((result) => {
      if (result.isConfirmed) {
        deleteNote(noteId)
      }
    })
  }
  const handleEditClicked = (data: CrmNoteViewModel | ParkNoteViewModel) => {
    setIdToBeEdited(data.Id!)
    setValue('Note', data.Note ?? '')
    setValue('Date', dayjs(new Date(data.Date!)) as any)
    dialogRef.current?.openDialog!()
  }
  const handleAddClicked = () => {
    setIdToBeEdited('')
    reset()
    dialogRef.current?.openDialog!()
  }
  // Fetching Notes
  const {
    data: notes,
    isPending: isLoadingNotes,
    mutate: refetchNotes,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactNotes.FetchContactNotes],
    mutationFn: () => crmNotesApi.getCrmNotes(props.type, id!),
  })
  useEffect(() => refetchNotes(), [props.contactId, props.companyId, SelectedTeam?.Id])

  //
  // Adding Notes

  const { isPending: isAddingCrmNote, mutate: addCrmNote } = useMutation({
    mutationFn: (data: { type: 'Contact' | 'Company'; typeId: string; payload: CrmNotePayload }) =>
      crmNotesApi.addCrmNote(data.type, data.typeId, data.payload),
    mutationKey: [ApiQueryKeys.crm.contactNotes.AddContactNote],
    onSuccess: () => handleFormSubmitSuccess(),
  })

  //

  // Deleting Note
  const { mutate: deleteNote, isPending: isRemovingNote } = useMutation({
    mutationFn: (noteId: string) => crmNotesApi.deleteCrmNote(noteId),
    mutationKey: [ApiQueryKeys.crm.contactNotes.DeleteContactNote],
    onSuccess: () => handleDeleteNoteSuccess(),
  })

  const handleDeleteNoteSuccess = () => {
    refetchNotes()
    enqueueSnackbar('Note has been deleted', { variant: 'success' })
  }
  //

  // Editing Notes
  const { mutate: editNote, isPending: isEditingNote } = useMutation({
    mutationFn: (data: { noteId: string; payload: CrmNotePayload }) =>
      crmNotesApi.updateCrmNote(data.noteId, data.payload),
    mutationKey: [ApiQueryKeys.crm.contactNotes.EditContactNote],
    onSuccess: () => handleFormSubmitSuccess(),
  })
  const handleFormSubmitSuccess = () => {
    refetchNotes()
    reset({ ContactId: props.contactId, CompanyId: props.companyId })
    dialogRef.current.closeDialog!()
    enqueueSnackbar('Note has been added', { variant: 'success' })
  }
  return {
    notes,
    isLoadingNotes,
    isAddingCrmNote,
    isRemovingNote,
    isEditingNote,
    dialogRef,
    handleAddClicked,
    handleDeleteClicked,
    handleEditClicked,
    handleFormSubmit,
    idToBeEdited,
    control,
    handleSubmit,
    reset,
  }
}
