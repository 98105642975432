import React, { useState } from 'react'
import { CountryLinksActions } from 'views'
import { AppBox } from 'components'
import { PaginationFilterModel } from 'sdk'
import { CountiesListing } from './listing/CountiesListing'

export const ManageCounties = () => {
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  const [AddedNew, setAddedNew] = useState<boolean>(false)

  return (
    <>
      <AppBox minHeight={'inherit'}>
        <AppBox paddingX={2}>
          <CountryLinksActions filter={filter} setFilter={setFilter} onNewCounty={() => setAddedNew(true)} />
        </AppBox>
        <AppBox paddingX={2} minHeight={'inherit'}>
          <CountiesListing filter={filter} setFilter={setFilter} isAddedNew={AddedNew} />
        </AppBox>
      </AppBox>
    </>
  )
}
