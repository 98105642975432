import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useDropzone } from 'react-dropzone'

interface Props {
  onFileSelect: (files: File[]) => void
  acceptedFileTypes: { [key: string]: string[] }
}

export const AppDropZone: FC<Props> = ({ onFileSelect, acceptedFileTypes }) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);  // Local state to hold accepted files

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      accept: acceptedFileTypes,  // Dynamically accept file types
      onDrop: (acceptedFiles) => {
        setAcceptedFiles(acceptedFiles);  // Update local state with accepted files
        onFileSelect(acceptedFiles);      // Call parent function
      },
    })

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {isDragAccept && <p>All files will be accepted</p>}
      {isDragReject && <p>Some files will be rejected</p>}
      {!isDragActive && <p>Drop some files here ...</p>}
     {fileRejections.map(({errors}) => 
     <>
      {errors.map((e) => (
          <Typography key={e.code}> {e.message}</Typography>
        ))}
     </>
     )}
    </section>
  )
}
