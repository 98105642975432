import { ColumnDef } from '@tanstack/react-table'
import { AppIcon, AppTable, AppTableProps } from 'components'
import { FC } from 'react'
import { SubAccountAddedModel } from 'sdk'
interface Props extends AppTableProps<SubAccountAddedModel> {
  removeUser: (Email: string) => void
}
export const AddSubAccountStepTwoAddedUsersTable: FC<Props> = ({ removeUser, ...props }) => {
  const columns: ColumnDef<SubAccountAddedModel>[] = [
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Date Added',
      accessorKey: 'DateAdded',
    },
    {
      header: 'Type',
      accessorKey: 'Type',
    },
    {
      header: 'Base Price',
      accessorKey: 'BasePrice',
    },
    {
      id: 'delete',
      cell: ({ row }) => {
        return <AppIcon icon={'tabler:trash'} color="red" onClick={() => removeUser(row.original.Email)} />
      },
    },
  ]
  return <AppTable columns={columns} {...props} />
}
