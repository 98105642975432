import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FeedBackApi, FeedbackPayload } from 'sdk'

export const useFeedBack = () => {
  const feebackApi = new FeedBackApi()
  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: SaveFeedBack, isPending: IsSaving } = useMutation({
    mutationFn: (data: FeedbackPayload) => feebackApi.AddFeedback(data),
    mutationKey: [ApiQueryKeys.feedback.addFeedback],
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Feedback submitted successfully', { variant: 'success' })
    },
  })
  return {
    SaveFeedBack,
    IsSaving,
  }
}
