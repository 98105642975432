import { AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { ParkDetailViewModel } from 'sdk'

interface Props {
  parkDetail: ParkDetailViewModel
}

export const ParkDetailUtilities: FC<Props> = ({ parkDetail }) => {
  return (
    <AppBox backgroundColor="grey.100" padding={2} borderRadius={3} border={1} borderColor={'grey.200'} width={'100%'}>
      <AppBox
        backgroundColor="grey.100"
        paddingX={2}
        borderRadius={3}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
      >
        <AppTypography color={'grey.900'} fontWeight={700} fontSize={16}>
          Utilities & Streets
        </AppTypography>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Water Source:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.ParkUtilityWaterSource}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Sewer Type:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.ParkUtilitySewerType}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Heating Fuel:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.ParkUtilityHeatingFuel}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Trash Service:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.ParkUtilityTrashService}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Street Type:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.StreetAndRoadsStreetType}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Street Owned By:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {parkDetail.ParkFieldsDataObj?.StreetAndRoadsOwnedBy}
          </AppTypography>
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
