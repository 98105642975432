import { useMembershipHelper } from 'hooks/membership/membership-helper.hook'
import { useRolesHelper } from 'hooks/roles/roles.helper'
import { AuthorizeProps } from 'sdk'

export const useAuthorizeHelper = () => {
  const { IsPaidMember } = useMembershipHelper()
  const { IsAdmin, IsSubAccount, IsTrialUser } = useRolesHelper()

  const isAuthorized = (arg: AuthorizeProps) => {
    if (arg.customCondition != null && !arg.customCondition) return false
    if (arg.allowedToAdmin && !IsAdmin()) return false
    if (arg.hiddenFromSubAccounts && IsSubAccount()) return false
    if (arg.allowedToPaidUsers && !IsPaidMember() && !IsTrialUser()) return false
    if (arg.hiddenFromTrialUsers && IsTrialUser()) return false
    if (arg.hiddenFromAdmin && IsAdmin()) return false
    if (arg.allowedToPaidUsersAndAdmin) {
      return IsPaidMember() || IsAdmin()
    }
    return true
  }

  return { isAuthorized }
}
