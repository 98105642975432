import { AppBox, AppTableProps } from 'components'
import { FC, useRef } from 'react'
import { CrmContactViewModel } from 'sdk'
import { CrmContactTable } from './CrmContactTable'

interface Props extends AppTableProps<CrmContactViewModel> {}
export const CrmContactsListing: FC<Props> = ({ ...props }) => {
  const appBoxRef = useRef<HTMLDivElement | null>(null)

  return (
    <AppBox ref={appBoxRef} minHeight={'inherit'} padding={2}>
      <AppBox
        backgroundColor="white"
        padding={3}
        minHeight={appBoxRef.current?.offsetHeight! - 180}
        borderRadius={5}
        flexGrow={1}
      >
        <CrmContactTable {...props} />
      </AppBox>
    </AppBox>
  )
}
