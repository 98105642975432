import { Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography, EmailField } from 'components'
import { PasswordField } from 'components/shared/form/password/PasswordField'
import { useSnackbar } from 'notistack'
import { Form, useForm } from 'react-hook-form'
import { ApiErrorModel, SignUpPayloadModel } from 'sdk'
import { AuthApi } from 'sdk/api-services/auth/auth.api'

const authApi = new AuthApi()
export const SignUpForm = () => {
  const { control, watch, reset } = useForm<SignUpPayloadModel>({
    mode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()
  const { mutate: signUp, isPending } = useMutation({
    mutationFn: authApi.appSignUp,
    onSuccess: () => {
      reset()
      enqueueSnackbar('Account created successfully, Chech your email to verify your account', { variant: 'success' })
    },
    onError: (err: ApiErrorModel) => {
      enqueueSnackbar(err.message, { variant: 'error' })
    },
  })

  const handleSingUpClick = (payload: SignUpPayloadModel) => {
    signUp(payload)
  }
  const handleTermsUseClick = () => {
    window.open('https://parksites.io/terms-of-use')
  }
  return (
    <>
      <Form onSubmit={({ data }) => handleSingUpClick(data)} control={control}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <EmailField hideDefaultErrorText={false} control={control} required name="Email" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <PasswordField required hideDefaultErrorText={false} control={control} name="Password" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <PasswordField
              required
              hideDefaultErrorText={false}
              control={control}
              name="ConfirmPassword"
              isConfirmPassword
              passwordValue={watch().Password?.toString() ?? ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <AppTypography fontSize={14} color="grey.600">
          By signing up you agree to our{' '}
          <AppTypography component={'a'} fontSize={14} isColorPrimary onClick={() => handleTermsUseClick()}>
            Terms of Use
          </AppTypography>{' '}
          and to receiving our promotional information.
        </AppTypography>
        <AppBox paddingY={2}>
          <AppButton type="submit" variant="contained" fullWidth isLoading={isPending}>
            Create Account
          </AppButton>
        </AppBox>
      </Form>

      <Divider>OR</Divider>
      <AppBox display={'flex'} gap={2} justifyContent={'center'} paddingTop={1}>
        <AppButton variant="outlined" color="secondary" prefixIcon="flat-color-icons:google">
          Sign up with Google
        </AppButton>
        <AppButton variant="outlined" color="secondary" prefixIcon="logos:microsoft-icon">
          Sign up with Microsoft
        </AppButton>
      </AppBox>
    </>
  )
}
