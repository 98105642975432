import { NewsAlertPayload, NewsAlertViewModel, PaginatedApiResponseModel, PaginationFilterModel } from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'

export class NewsAlertApi extends BaseApi {
  public getNewsAlerts(filter: PaginationFilterModel) {
    return this.GET_Request<PaginatedApiResponseModel<NewsAlertViewModel>>(apiUrls.newsAlerts.getNewsAlerts(filter))
  }
  public addNewsAlerts(newsAlert: NewsAlertPayload) {
    return this.POST_Request<NewsAlertViewModel>(apiUrls.newsAlerts.addNewsAlert(), newsAlert)
  }
  public updateNewsAlert(newsAlert: NewsAlertPayload) {
    return this.PUT_Request<NewsAlertViewModel>(apiUrls.newsAlerts.updateNewsAlert(), newsAlert)
  }
  public deleteNewsAlert(id: string) {
    return this.DELETE_Request<boolean>(apiUrls.newsAlerts.deleteNewsAlert(id))
  }
}
