import { Box, Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { AppIcon } from '../icon/AppIcon'

interface ImageCarousalProps {
  images: string[]
  open: boolean
  onClose: () => void
}

export const ImageCarousal: React.FC<ImageCarousalProps> = ({ images, open, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        }}
      >
        {/* Image Display */}
        <Box
          component="img"
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />

        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'white',
          }}
        >
          <AppIcon icon={'eva:close-outline'} size="medium" color="white" style={{ fontSize: 30 }} />
        </IconButton>

        {/* Previous Button */}
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: 16,
            color: 'white',
          }}
        >
          <AppIcon icon="ion:arrow-back-circle-outline" style={{ fontSize: 50 }} />
        </IconButton>

        {/* Next Button */}
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: 16,
            color: 'white',
          }}
        >
          <AppIcon icon="ion:arrow-forward-circle-outline" style={{ fontSize: 50 }} />
        </IconButton>
      </Box>
    </Dialog>
  )
}
