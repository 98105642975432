import { Skeleton } from '@mui/material'
import { AppBox, AppIcon, AppTypography, SimpleRadioGroup } from 'components'
import { usePaymentMethods } from 'hooks'
import { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'

interface Props {
  setDefaultPayment: (id: string) => void
  getPaymentMehtods: () => void
  handleDeletePaymentMethod: (id: string) => void
  isGettingPaymentMethods: boolean
  isDetaching: boolean
  isUpdaingDefaultPayment: boolean
  isGettingDefaultPayments: boolean
}

export const PaymentMethods: FC<Props> = ({
  setDefaultPayment,
  getPaymentMehtods,
  handleDeletePaymentMethod,
  isGettingPaymentMethods,
  isDetaching,
  isUpdaingDefaultPayment,
  isGettingDefaultPayments,
}) => {
  const { PaymentMethods, DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)

  useEffect(() => {
    getPaymentMehtods()
  }, [getPaymentMehtods])

  return (
    <>
      {isDetaching || isGettingPaymentMethods || isUpdaingDefaultPayment || isGettingDefaultPayments ? (
        <>
          <Skeleton variant="text" height={40} width={300} />
          <Skeleton variant="text" height={40} width={300} />
        </>
      ) : (
        <>
          {PaymentMethods.length > 0 && DefaultPaymentMethod && (
            <SimpleRadioGroup
              value={DefaultPaymentMethod.PaymentMethodId}
              onChange={(e) => {
                setDefaultPayment(e)
              }}
              options={PaymentMethods?.map((paymentMethod) => ({
                Id: paymentMethod.PaymentMethodId,
                Component: (
                  <AppBox display={'flex'} gap={2} alignItems={'center'}>
                    <AppIcon icon="tabler:credit-card-pay" size="large" color="secondary" />
                    <AppTypography color={'grey.900'} fontSize={14} fontWeight={500}>
                      *************{paymentMethod.LastDigits}
                    </AppTypography>
                    <AppIcon
                      icon="tabler:trash"
                      color="red"
                      size="medium"
                      onClick={() => handleDeletePaymentMethod(paymentMethod.PaymentMethodId)}
                    />
                  </AppBox>
                ),
              }))}
            />
          )}
        </>
      )}
    </>
  )
}
