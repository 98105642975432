import { CircularProgress, Divider, Grid } from '@mui/material'
import { ProfileModules, appRoutes } from 'globals/constants'
import { useGetTeam, useLogout, useMembershipHelper, useRolesHelper, useSettings } from 'hooks'
import { useSnackbar } from 'notistack'
import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SdkConfig, TeamViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { AuthSliceSelector, TeamSliceSelector, setOpenedProfileModule } from 'store/slices'
import { AppBox, AppButton, AppDialogRefType, AppIcon, AppMenu, AppTypography } from '../../base'
import { Authorize } from '../authorize/Authorize'
import { FeedBackDialog } from '../feedback/FeedBackDialog'

type link = {
  title: string
  divider?: boolean
  buttons: {
    name: string
    icon: string
    link?: string
    dialogKey?: string
    isResetTour?: boolean
    isResetInfoBoxSetting?: boolean
  }[]
}
const data: link[] = [
  {
    title: 'get social with us',
    buttons: [
      { link: 'https://www.linkedin.com/company/parksitesio', name: 'LinkedIn', icon: 'mdi:linkedin' },
      { link: 'https://www.facebook.com/parksitesio', name: 'Facebook', icon: 'ic:baseline-facebook' },
      { link: 'https://www.youtube.com/@ParkSitesIO', name: 'Youtube', icon: 'mdi:youtube' },
      { link: 'https://parksites.io/articles', name: 'Articles', icon: 'fluent:news-16-filled' },
    ],
    divider: true,
  },
  {
    title: 'provide feedback',
    buttons: [
      { dialogKey: 'Feedback', name: 'Rate our site', icon: 'material-symbols:star' },
      { dialogKey: 'Testimonials', link: 'https://parksites.io/contact-us', name: 'Testimonial', icon: 'mdi:heart' },
    ],
    divider: true,
  },
  {
    title: 'need help',
    buttons: [
      { link: 'https://support.parksites.io', name: 'Help Center', icon: 'solar:help-bold' },
      {
        link: 'https://support.parksites.io/portal/en/community/parksitesio',
        name: 'Community',
        icon: 'fluent:people-community-32-filled',
      },
      { link: 'https://support.parksites.io/portal/en/newticket', name: 'Submit ticket', icon: 'formkit:submit' },
      { link: 'https://parksites.io/contact-us', name: 'Write to us', icon: 'mdi:file-edit' },
      { isResetTour: true, name: 'Reset Tours', icon: 'ic:sharp-tour' },
      { isResetInfoBoxSetting: true, name: 'Reset Hints', icon: 'el:idea' },
    ],
  },
]
export const ProfileDrawer = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { ResetHints, isResetingHints, ResetTours, isResetingTours } = useSettings()
  const { UserTeams, SelectedTeam, isChangingTeam } = useSelector(TeamSliceSelector)
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { handleSwitchTeam } = useGetTeam()
  const { ActiveMembership } = useMembershipHelper()
  const { IsTrialUser } = useRolesHelper()
  const feedbackDialogRef = useRef<AppDialogRefType>(null)
  const { logout } = useLogout()

  const profileImageSrc = useMemo(() => {
    if (CurrentUser?.ProfileImage) {
      return CurrentUser.ProfileImage
    }
    return '/images/profile-image.png'
  }, [CurrentUser?.ProfileImage])

  const handleAccountClicked = () => {
    if (closeDrawer) {
      closeDrawer()
    }
    dispatch(setOpenedProfileModule(ProfileModules.Profile))
    navigate(appRoutes.profile.path)
  }
  const handleChangeMembershipClick = () => {
    if (closeDrawer) {
      closeDrawer()
    }
    dispatch(setOpenedProfileModule(ProfileModules.Billing))
    navigate(appRoutes.profile.path)
  }
  const handleTeamClick = (team: TeamViewModel) => {
    closeDrawer && closeDrawer()
    // dispatch(setSelectedTeam({ ...team }))
    handleSwitchTeam(team.Id)
  }
  const handleButtonClick = (data: {
    link?: string
    dialogKey?: string
    isResetTour?: boolean
    isResetHints?: boolean
  }) => {
    if (data.link) {
      window.open(data.link, '_blank')
    }
    if (data.dialogKey) {
      if (data.dialogKey === 'Feedback') {
        feedbackDialogRef.current?.openDialog!()
      } else if (data.dialogKey === 'Testimonials') {
        window.open(data.link, '_blank')
      }
    }
    if (data.isResetHints) {
      ResetHints()
    } else if (data.isResetTour) {
      ResetTours()
        .then(() => {
          if (closeDrawer) {
            closeDrawer()
          }
        })
        .catch(() => {})
    }
  }
  return (
    <>
      <AppBox
        minHeight={'inherit'}
        gap={1}
        backgroundColor="white"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <AppBox>
          <AppBox backgroundColor="grey.100" paddingY={2}>
            <AppBox display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={1}>
              <img src={profileImageSrc} alt="profile_image" height={120} width={120} style={{ borderRadius: 100 }} />
              {CurrentUser?.FirstName && (
                <AppTypography fontSize={16} fontWeight={'bold'}>
                  {CurrentUser.FirstName + ' ' + CurrentUser.LastName}{' '}
                </AppTypography>
              )}
              <AppTypography color={'grey.700'} fontSize={16} fontWeight={600}>
                User ID: {CurrentUser?.AccountId}
              </AppTypography>
              <Authorize hiddenFromSubAccounts={true} allowedToPaidUsers={true} customCondition={!!SelectedTeam}>
                <AppMenu
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  renderElement={(handleClick) => (
                    <AppButton suffixIcon="mingcute:down-line" color="secondary" onClick={handleClick}>
                      <AppTypography fontSize={16} fontWeight={600}>
                        Team: {isChangingTeam ? <CircularProgress size={25} color="secondary" /> : SelectedTeam?.Name}
                      </AppTypography>
                    </AppButton>
                  )}
                  MenuItems={UserTeams.map((team, index) => {
                    return {
                      text: team.Name,
                      onClick: () => {
                        handleTeamClick(team)
                      },
                      suffixIcon: 'mdi:star',
                      suffixIconProps: { color: 'gold', size: 'medium' },
                    }
                  })}
                />
              </Authorize>

              <AppBox display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} gap={1}>
                <AppButton
                  color="primary"
                  variant="outlined"
                  sx={{ textTransform: 'uppercase' }}
                  onClick={() => handleAccountClicked()}
                >
                  MY ACCOUNT
                </AppButton>
                <AppButton color="primary" variant="outlined" sx={{ textTransform: 'uppercase' }} onClick={logout}>
                  SIGN OUT
                </AppButton>
              </AppBox>
            </AppBox>
          </AppBox>

          <AppBox paddingX={3}>
            <Authorize hiddenFromSubAccounts>
              <AppBox paddingBottom={4}>
                <AppTypography fontWeight={700} fontSize={16} sx={{ textTransform: 'uppercase' }} paddingY={2}>
                  subscription
                </AppTypography>
                <AppTypography fontWeight={600} color={'grey.600'} fontSize={16}>
                  {IsTrialUser() ? 'Free 7 day trial' : ActiveMembership()?.MembershipTitle} -
                  <AppBox
                    component={'a'}
                    fontWeight={600}
                    fontSize={16}
                    color={'primary.200'}
                    cursor="pointer"
                    onClick={() => handleChangeMembershipClick()}
                  >
                    CHANGE
                  </AppBox>
                </AppTypography>
              </AppBox>
            </Authorize>
            <Divider />
            {data.map((item, index) => {
              return (
                <>
                  <AppBox key={index} paddingBottom={4} paddingTop={1}>
                    <AppTypography fontWeight={'bold'} fontSize={16} sx={{ textTransform: 'uppercase' }}>
                      {item.title}
                    </AppTypography>
                    <Grid container>
                      {item.buttons.map((btn, index) => {
                        return (
                          <Grid item xs={6} key={index}>
                            <AppBox display={'flex'} gap={1} alignItems={'center'} padding={1} cursor="pointer">
                              {(!!btn.isResetInfoBoxSetting && isResetingHints) ||
                              (!!btn.isResetTour && isResetingTours) ? (
                                <CircularProgress size={30} sx={{ textAlign: 'center' }} color="secondary" />
                              ) : (
                                <>
                                  <AppIcon icon={btn.icon} size="medium" color="#718096" />
                                  <AppTypography
                                    fontSize={16}
                                    fontWeight={500}
                                    color={'#718096'}
                                    component={'a'}
                                    onClick={() =>
                                      handleButtonClick({
                                        link: btn.link,
                                        dialogKey: btn.dialogKey,
                                        isResetHints: btn.isResetInfoBoxSetting,
                                        isResetTour: btn.isResetTour,
                                      })
                                    }
                                    sx={{ textDecoration: 'none' }}
                                  >
                                    {btn.name}
                                  </AppTypography>
                                </>
                              )}
                            </AppBox>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </AppBox>
                  {item.divider && <Divider />}
                </>
              )
            })}
          </AppBox>
        </AppBox>
        <AppBox paddingBottom={2}>
          <AppTypography fontWeight={400} color={'grey.500'} textAlign={'center'}>
            Build {SdkConfig.BuildNumber}
          </AppTypography>
        </AppBox>
      </AppBox>
      <FeedBackDialog ref={feedbackDialogRef} handleSubmitSuccess={() => feedbackDialogRef.current?.closeDialog!()} />
    </>
  )
}
