import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AnyObject } from 'globals/typings'
import { ShowAlert } from 'sdk/alert-service/alert-service'
import { ApiErrorModel } from 'sdk/models'
import { AppStore } from 'store/app.store'
import { clearSession } from 'store/slices'
import { SdkConfig } from '../sdk.config'
var CryptoJS = require('crypto-js')

export class BaseApi {
  protected ApiUrl = SdkConfig.ApiPath
  private controllers: AbortController[] = []

  public static cancelAllApis() {
    axios.CancelToken.source().cancel('Operation canceled by the user.')
  }
  private async requestAsync<T>(
    method: AxiosRequestConfig['method'],
    url: string,
    postBody: AnyObject = {},
    cancel: boolean = true
  ): Promise<void | AxiosResponse> {
    if (cancel) {
      const controller = new AbortController()
      this.controllers.push(controller)
    }
    const headers: AxiosRequestConfig['headers'] = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: this.authorizeRequest(),
    }
    const options: AxiosRequestConfig = {
      method,
      url,
      headers,
      data: postBody,
    }

    // const a =  axios.create({})
    // a.interceptors.request.use((config) => {
    //   // singal to cancel request
    // })
    // a.interceptors.response.use((response) => {
    //   //
    // })
    return axios<T>(options).catch((err) => {
      const newErr: ApiErrorModel = { status: err.response!.status, message: err.response?.data.Message }
      return this.handleErrorAsync(newErr)
    })
  }

  protected fileRequestAsync(method: AxiosRequestConfig['method'], url: string, data: AnyObject = {}) {
    // Headers to be sent
    const headers: AxiosRequestConfig['headers'] = {
      'Content-Type': 'multipart/form-data',
      Authorization: this.authorizeRequest(),
    }
    const options: AxiosRequestConfig = {
      method,
      url,
      headers,
      data,
    }
    return axios(options).catch((err) => {
      const newErr: ApiErrorModel = { status: err.response!.status, message: err.response?.data.Message }
      return this.handleErrorAsync(err)
    })
  }

  protected downloadRequest(Url: string): Promise<void | AxiosResponse> {
    const Options: AxiosRequestConfig = {
      method: 'GET',
      url: Url,
      responseType: 'blob',
    }
    return axios(Options).catch((err) => {
      return this.handleErrorAsync(err)
    })
  }

  private authorizeRequest() {
    const SessionValue = AppStore.getState().Auth.CurrentUser
    return `Bearer ${SessionValue ? SessionValue.JwtToken : ''}`
  }

  private async handleErrorAsync(error: ApiErrorModel) {
    if (error.status === 451) {
      // new UserSession().clear()!
      AppStore.dispatch(clearSession())
      ShowAlert('error', "We can't proceed with your request. You have signed in with another device.").then(
        (result) => {
          if (result.isConfirmed) {
            if (window.location.href !== window.location.origin) {
              window.location.href = window.location.origin + '?redirect_reason=UNAUTHORIZIED'
            }
          }
        }
      )
    }
    if (error.status === 429) {
      //   if (router.currentRoute.name !== 'limitexceeded') {
      //     router.push({ name: 'limitexceeded' })
      //   }
    }
    if (error.status === 401) {
      window.location.href = window.location.origin + '/login?redirect_reason=UNAUTHORIZIED'
      // new AlertService().show('info', 'You session is expired. Please login again to continue.').then(() => {});
      //   if (router.currentRoute.name !== 'Home') {
      //     // window.location.href = window.location.origin + '?redirect_reason=UNAUTHORIZIED';
      //     router.push({ name: 'Home', query: { redirect_reason: 'UNAUTHORIZIED' } })
      //   }
    }
    if (error.status === 410) {
      // new UserSession().clear()!
      AppStore.dispatch(clearSession())
      ShowAlert('error', error.message!).then((result) => {
        if (result.isConfirmed) {
          if (window.location.href !== window.location.origin) {
            window.location.href = window.location.origin + '?redirect_reason=UNAUTHORIZIED'
          }
        }
      })
    }
    return Promise.reject(error ?? new ApiErrorModel({ message: 'Internal Server Error' }))
  }

  // Async Requests
  public GET_Request<T>(url: string, filter: AnyObject = {}, isEncrypted: boolean = false, cancel = true): Promise<T> {
    return this.requestAsync('GET', url, {}, cancel).then((res) => {
      if (isEncrypted && res?.data.Data) {
        try {
          // Decrypt
          var data = CryptoJS.enc.Base64.parse(res?.data.Data)
          var wordArraySalt = CryptoJS.lib.WordArray.create(data.words.slice(0, 4)) // Fix: Array -> WordArray conversion
          var wordArrayIV = CryptoJS.lib.WordArray.create(data.words.slice(4, 8)) // Fix: Array -> WordArray conversion
          var wordArrayCt = CryptoJS.lib.WordArray.create(data.words.slice(8)) // Fix: Consider ciphertext
          var encryptedKey = CryptoJS.AES.encrypt('abcdefghijklmnop')
          var keyPBKDF2 = CryptoJS.PBKDF2(encryptedKey, wordArraySalt, { keySize: 256 / 32, iterations: 100 })
          var decrypted = CryptoJS.AES.decrypt({ ciphertext: wordArrayCt }, keyPBKDF2, { iv: wordArrayIV }) // Fix: Pass ciphertext as CipherParams object
          return decrypted.toString(CryptoJS.enc.Utf8) // Fix: UTF-8 decode
        } catch (e) {
          console.log('Error:', e)
        }
      }
      return res?.data.Data
    })
  }
  public POST_Request<T>(url: string, postBody: AnyObject, cancel = true): Promise<T> {
    return this.requestAsync('POST', url, postBody, cancel).then((res) => res?.data.Data)
  }
  public PATCH_Request<T>(url: string, postBody: AnyObject): Promise<T> {
    return this.requestAsync('PATCH', url, postBody).then((res) => res?.data.Data)
  }
  public PUT_Request<T>(url: string, postBody: AnyObject): Promise<T> {
    return this.requestAsync('PUT', url, postBody).then((res) => res?.data.Data)
  }
  public DELETE_Request<T>(url: string, postBody?: AnyObject): Promise<T> {
    return this.requestAsync('DELETE', url, postBody).then((res) => res?.data.Data)
  }

  public PUT_FormDataRequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
    return this.fileRequestAsync('PUT', url, postBody).then((res) => res?.data.Data)
  }
  public PATCH_FormDataRequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
    return this.fileRequestAsync('PATCH', url, postBody).then((res) => res?.data.Data)
  }
}
