import { MonthNames, validationRulesMapping } from 'globals/constants'
import { FormFieldCustomRules, FormFiledRuleOptions } from 'globals/typings'

export const getClassNames = (props: any) => {
  const {
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    className,
  } = props

  const classNames = [
    padding && `p-${padding}`,
    paddingTop && `pt-${paddingTop}`,
    paddingBottom && `pb-${paddingBottom}`,
    paddingLeft && `pl-${paddingLeft}`,
    paddingRight && `pr-${paddingRight}`,
    margin && `m-${margin}`,
    marginTop && `mt-${marginTop}`,
    marginBottom && `mb-${marginBottom}`,
    marginLeft && `ml-${marginLeft}`,
    marginRight && `mr-${marginRight}`,
    className,
  ]

  return classNames.filter(Boolean).join(' ')
}

export const getValidationRules = (value: string, fieldName: string, customRules: FormFieldCustomRules) => {
  const mappgings = validationRulesMapping.filter((x) => Object.keys(customRules).includes(x.key))
  for (const mapping of mappgings) {
    if (
      !mapping.validate(value, { optionValue: (customRules![mapping.key] as FormFiledRuleOptions).optionValue ?? '' })
    ) {
      return mapping.message({ optionValue: (customRules![mapping.key] as FormFiledRuleOptions).optionValue ?? '' })
    }
  }
}
export const getFullDate = (date: Date | string | null) => {
  return `${MonthNames[new Date(date ?? '').getMonth() ?? 0]} ${new Date(date ?? '').getDate()}, ${new Date(date ?? '').getFullYear()}`
}
