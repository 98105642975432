//theme.js
import { createTheme } from '@mui/material/styles'
import { palette } from './colorPallete'
import { components } from './components'

const theme = createTheme({
  palette: { ...palette },
  components: { ...components },
})

export default theme
