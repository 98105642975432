import { AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { ParkDetailViewModel } from 'sdk'

interface Props {
  parkDetail: ParkDetailViewModel
}

export const ParkDetailRVLots: FC<Props> = ({ parkDetail }) => {
  return (
    <AppBox backgroundColor="grey.100" padding={2} borderRadius={3} border={1} borderColor={'grey.200'} width={'100%'}>
      <AppBox
        backgroundColor="grey.100"
        paddingX={2}
        borderRadius={3}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
      >
        <AppTypography color={'grey.900'} fontWeight={700} fontSize={16}>
          RV Lots
        </AppTypography>
        <AppBox display={'flex'} alignItems={'center'} gap={1}>
          <AppTypography color={'grey.600'}>Total RV Lots:</AppTypography>
          <AppTypography color={'primary.200'} fontWeight={700} fontSize={14}>
            {!!parkDetail.ParkFieldsDataObj?.TotalRVLots && parkDetail.ParkFieldsDataObj?.TotalRVLots > 0
              ? parkDetail.ParkFieldsDataObj?.TotalRVLots?.toLocaleString()
              : '-'}
          </AppTypography>
        </AppBox>
        <AppTypography color={'grey.900'} alignSelf={'center'} fontWeight={600}>
          Lot Break Down
        </AppTypography>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>In Use:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {!!parkDetail.ParkFieldsDataObj?.UnDevelopedRVLots && parkDetail.ParkFieldsDataObj?.UnDevelopedRVLots > 0
              ? parkDetail.ParkFieldsDataObj?.UnDevelopedRVLots?.toLocaleString()
              : '-'}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} justifyContent={'space-between'}>
          <AppTypography color={'grey.600'}>Vacant Lots:</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={700}>
            {!!parkDetail.ParkFieldsDataObj?.DevelopedRVLots && parkDetail.ParkFieldsDataObj?.DevelopedRVLots > 0
              ? parkDetail.ParkFieldsDataObj?.DevelopedRVLots?.toLocaleString()
              : '-'}
          </AppTypography>
        </AppBox>
        <AppBox display={'flex'} flexDirection={'column'}>
          <AppTypography color={'grey.600'}>Lot Types:</AppTypography>
          <AppBox display={'flex'} gap={1}>
            <AppBox If={parkDetail.ParkFieldsDataObj?.RVLotSurfaceTypeAsphalt === 'True'}>
              <AppTypography color={'grey.900'} fontWeight={700}>
                Asphalt
              </AppTypography>
            </AppBox>
            <AppBox If={parkDetail.ParkFieldsDataObj?.RVLotSurfaceTypeConcrete === 'True'}>
              <AppTypography color={'grey.900'} fontWeight={700}>
                Concrete
              </AppTypography>
            </AppBox>
            <AppBox If={parkDetail.ParkFieldsDataObj?.RVLotSurfaceTypeGrass === 'True'}>
              <AppTypography color={'grey.900'} fontWeight={700}>
                Grass
              </AppTypography>
            </AppBox>
            <AppBox If={parkDetail.ParkFieldsDataObj?.RVLotSurfaceTypeGravel === 'True'}>
              <AppTypography color={'grey.900'} fontWeight={700}>
                Gravel
              </AppTypography>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
