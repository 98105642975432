import { useState } from 'react'
import { AppTextField, AppTextFieldProps } from '../../../base'

interface PasswordFieldProps extends AppTextFieldProps {
  isConfirmPassword?: boolean
  passwordValue?: string
}
export const PasswordField = ({ customRules, isConfirmPassword, passwordValue, ...props }: PasswordFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  return (
    <AppTextField
      type={isPasswordVisible ? 'text' : 'password'}
      {...props}
      customRules={{
        ...customRules,
        Password: true,
        ...(isConfirmPassword && {
          ConfirmPassword: { optionValue: passwordValue },
        }),
      }}
      prefixIcon="ant-design:lock-outlined"
      prefixIconProps={{ size: 'medium' }}
      suffixIcon={isPasswordVisible ? 'tabler:eye-off' : 'akar-icons:eye'}
      suffixIconProps={{ onClick: () => setIsPasswordVisible(!isPasswordVisible), size: 'medium' }}
    />
  )
}
