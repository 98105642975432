import { NewAlertsActions } from 'views'
import { NewAlertsListing } from './listing/NewAlertsListing'
import { PaginationFilterModel } from 'sdk'
import { useState } from 'react'
import { AppBox } from 'components'

export const AddNewsAlerts = () => {
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())

  return (
    <AppBox minHeight={'inherit'}>
      <AppBox paddingX={2}>
        <NewAlertsActions filter={filter} setFilter={setFilter} />
      </AppBox>
      <AppBox paddingX={2} minHeight={'inherit'}>
        <NewAlertsListing filter={filter} setFilter={setFilter} />
      </AppBox>
    </AppBox>
  )
}
