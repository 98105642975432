import { Divider, Grid } from '@mui/material'
import { AppAutoComplete, AppBox, AppTypography, SimpleCheckBox, SimpleRadioGroup, SimpleValueSelect } from 'components'
import { fieldMapping } from 'hooks/crm/import-crm-contact.hook'
import { Dispatch, FC, SetStateAction } from 'react'
import { Control } from 'react-hook-form'

interface Props {
  fieldMappings: fieldMapping
  setFieldMappings: Dispatch<SetStateAction<fieldMapping>>
}

export const ImportContactWizardStepTwoContactType: FC<Props> = ({fieldMappings,setFieldMappings}) => {
  return (
    <Grid container spacing={2} alignItems={'stretch'}>
      <Grid item xs={12}>
        <AppBox backgroundColor="white" padding={2} borderRadius={3} border={1} borderColor={'grey.300'}>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={400}>
            To help keep your contacts organized, each contact within your CRM is required to be assigned a{' '}
            <b>Contact Type</b> value of either <b>Lead</b> or <b>Contact</b>. You can choose to either automatically
            apply one of these values to all of your imported contacts. Alternatively, if your file already contains
            these values for each contact, you can choose to simply map the appropriate field to our <b>Contact Type</b>{' '}
            field.
          </AppTypography>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={500} paddingTop={1}>
            <b>Important-</b> If you choose to map our <b>Contact Type</b> field to one of your fields and it contains
            values other than <b>Lead</b> or <b>Contact</b> the record will fail to be imported.
          </AppTypography>
        </AppBox>
      </Grid>
      <Grid item xs={12}>
        <AppBox
          backgroundColor="white"
          padding={2}
          borderRadius={3}
          border={1}
          borderColor={'grey.300'}
          paddingBottom={4}
        >
          <AppBox display={'flex'}>
            <AppBox display={'flex'} flexDirection={'column'} gap={2} padding={1} flex={1}>
              <AppBox display={'flex'} gap={2}>
                <SimpleCheckBox label="Apply to All" labelTextProps={{ fontWeight: 500, fontSize: 14 }} />
              </AppBox>
              <AppTypography fontSize={14} color={'grey.500'}>
                Use this option if your file DOES NOT contain a field defining if the contact is categorized as a Lead
                or Contact.
              </AppTypography>
              <AppBox alignSelf={'center'}>
                <SimpleRadioGroup
                  row
                  onChange={(val) => setFieldMappings((prev) => ({...prev, contactType: val, contactTypeColumn: ''}))}
                  value={fieldMappings.contactType}
                  options={[
                    { Id: 'lead', Label: 'Lead' },
                    { Id: 'contact', Label: 'Contact' },
                  ]}
                />
              </AppBox>
            </AppBox>
            <Divider orientation="vertical" flexItem />
            <AppBox display={'flex'} flexDirection={'column'} gap={2} padding={1} paddingLeft={2} flex={1}>
              <AppBox display={'flex'} gap={2}>
                <SimpleCheckBox label="Map Contact Types" labelTextProps={{ fontWeight: 500, fontSize: 14 }} />
              </AppBox>
              <AppTypography fontSize={14} color={'grey.500'}>
                Use this option if your file contains a field defining if the contact is categorized as only a Lead or
                Contact.
              </AppTypography>
              <Grid container alignItems={'center'}>
                <Grid item xs={3}>
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                    Contact Type
                  </AppTypography>
                </Grid>
                <Grid item xs={3}>
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                    maps to
                  </AppTypography>
                </Grid>
                <Grid item xs={6}>
                  <SimpleValueSelect options={[{label: 'a', value: 'a'},{label:'b', value:'b'}]} value={fieldMappings.contactTypeColumn} 
                  onValueChange={(val) => setFieldMappings((prev) => 
                     ({ ...prev, contactTypeColumn: val.toString(), contactType: '' })
                  )}
                  />
                  </Grid>
              </Grid>
            </AppBox>
          </AppBox>
        </AppBox>
      </Grid>
    </Grid>
  )
}
