import { Typography } from '@mui/material'
import { ParkSitesActivities } from 'globals/constants'
import { ReactNode } from 'react'
import { ActivityCenterInfo } from 'sdk'
import { CityDemographics } from './city-demographics/CityDemographics'
import { ParkActivityCrm } from './crm/ParkActivityCrm'
import { ParcelData } from './parcel-data/ParcelData'
import { ParkDetails } from './park-details/ParkDetails'
import { ParkNotes } from './park-notes/ParkNotes'
import { Parktags } from './park-tags/Parktags'
import { PeopleInsight } from './people-insight/PeopleInsight'
import { AppTypography } from 'components'

export const ParkActivitiesMapping: {
  [key: string]: {
    name: string
    AdminName?: string
    component: ReactNode
    icon: string
    width?: string
    toolTipText: string | ReactNode
    hasAdminView?: true
  }
} = {
  [ParkSitesActivities.ParkDetails]: {
    name: 'Park Details',
    icon: 'carbon:folder-details',
    toolTipText: 'View the details about this park that our staff has uncovered.',
    component: <ParkDetails />,
    hasAdminView: true,
  },
  [ParkSitesActivities.PeopleInsight]: {
    name: 'People Insights',
    AdminName: 'Admin - Manage People Insights',
    icon: 'ri:user-shared-line',
    toolTipText: 'View people our staff has uncovered as being associated with this park.',
    component: <PeopleInsight />,
    hasAdminView: true,
  },
  [ParkSitesActivities.CityDemographics]: {
    name: 'City Demographics',
    icon: 'mdi:home-city-outline',
    component: <CityDemographics />,
    toolTipText: 'These are demographics our staff has uncovered about the location the park resides in.',
    width: '40%',
  },
  [ParkSitesActivities.ParcelData]: {
    name: 'Parcel Data',
    icon: 'icon-park-outline:database-point',
    component: <ParcelData />,
    toolTipText: 'View the county’s information that our staff have uncovered about the park’s most relevant parcel.',
    width: '40%',
  },
  [ParkSitesActivities.ParkActivityCrm]: {
    name: 'CRM',
    icon: 'lucide:circle-user-round',
    toolTipText: (
      <AppTypography>
        Manage your personal contacts for this park. Use the <b>CRM Parks</b> option above to display the full list of
        parks you’ve added contacts.
      </AppTypography>
    ),
    component: <ParkActivityCrm />,
  },
  [ParkSitesActivities.ParkNotes]: {
    name: 'Park Notes',
    icon: 'mingcute:notebook-line',
    toolTipText: 'Manage the notes you and your team keep about this park.',
    component: <ParkNotes />,
  },
  [ParkSitesActivities.Parktags]: {
    name: 'Park Tags',
    icon: 'bi:tags-fill',
    toolTipText: (
      <AppTypography>
        Apply tags to this park to group it with other specific parks. Use the <b>Tagged Parks</b> option above to
        manage the tags you apply.
      </AppTypography>
    ),
    component: <Parktags />,
    width: '40%',
  },
}

export const ExternalLinksMapping = [
  {
    name: 'County Website',
    icon: 'streamline:browser-website-1',
    isVisible: (park?: ActivityCenterInfo) => !!park?.CountyWebsite,
    onClick: (park?: ActivityCenterInfo) => window.open(park?.CountyWebsite!, '_blank'),
  },
  {
    name: 'State Website',
    icon: 'mdi:web',
    isVisible: (park?: ActivityCenterInfo) => !!park?.StateWebsite,
    onClick: (park?: ActivityCenterInfo) => window.open(park?.StateWebsite!, '_blank'),
  },
  {
    name: "FEMA's Flood Map",
    icon: 'f7:map-fill',
    isVisible: (park: ActivityCenterInfo) => true,
    onClick: (park: ActivityCenterInfo) =>
      window.open(
        `https://hazards-fema.maps.arcgis.com/apps/webappviewer/index.html?id=8b0adb51996444d4879338b5529aa9cd&find=${park.Lat}%2C%20${park.Long}`,
        '_blank'
      ),
  },
  {
    name: 'View in Google Maps',
    icon: 'simple-icons:googlemaps',
    isVisible: (park?: ActivityCenterInfo) => !!park?.ParkName,
    onClick: (park?: ActivityCenterInfo) =>
      window.open(`https://www.google.com/maps/search/?api=1&query=${park?.Lat}%2C${park?.Long}`, '_blank'),
  },
  {
    name: 'Google this Park',
    icon: 'ant-design:google-outlined',
    isVisible: (park?: ActivityCenterInfo) => !!park?.ParkName,
    onClick: (park?: ActivityCenterInfo) =>
      window.open(`https://www.google.com/search?q=${park?.ParkName + ' ' + (park?.Address ?? '')}`, '_blank'),
  },
]
