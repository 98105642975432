import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js'
import { PaymentMethodResult } from '@stripe/stripe-js'
import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, SettingsTypes } from 'globals/constants'
import { useSettings } from 'hooks/settings/settings.hook'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { AttachPaymentMethodPayloadModel, SettingViewModel, ShowAlert, UserPaymentsApi } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { AuthSliceSelector } from 'store/slices'
import {
  PaymentMethodSliceSelector,
  setDefaultPaymentMethod,
  setPaymentMethods,
} from 'store/slices/payment-methods.slice'

export const usePaymentMethods = () => {
  const { PaymentMethods } = useSelector(PaymentMethodSliceSelector)
  const paymentApi = new UserPaymentsApi()
  const elements = useElements()
  const stripe = useStripe()

  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { UserId } = useSelector(AuthSliceSelector)

  const {
    UpdateSettings,
    getSettings,
    isUpdating: isUpdaingDefaultPayment,
    isGetting: isGettingDefaultPayments,
  } = useSettings()

  const [isAddingStripePaymentMethod, setIsAddingStripePaymentMethod] = useState<boolean>(false)

  const { mutateAsync: addPaymentMethod, isPending: isAddingPaymentMethod } = useMutation({
    mutationKey: [ApiQueryKeys.payment.AttachPayumentMethod],
    mutationFn: (data: AttachPaymentMethodPayloadModel) => paymentApi.AttachPaymentMethodtoCustomer(data),
    onSuccess: () => {
      enqueueSnackbar('Payment method added successfully', { variant: 'success' })
      getPaymentMehtods()
    },
  })

  const { mutateAsync: getPaymentMehtods, isPending: isGettingPaymentMethods } = useMutation({
    mutationKey: [ApiQueryKeys.payment.FetchPaymentMethods],
    mutationFn: () => paymentApi.PaymentMethods(UserId),
    onSuccess: (data) => {
      dispatch(setPaymentMethods(data ?? []))
      if (data?.length > 0) {
        getSettings([SettingsTypes.DEFAULT_PAYMENT_METHOD])
          .then((_settings) => {
            if (!!_settings && _settings?.length > 0) {
              const paymentMethod = data?.find((x) => x.PaymentMethodId === _settings[0].Value)
              dispatch(setDefaultPaymentMethod(paymentMethod!))
            } else {
              if (data?.length > 0) {
                setDefaultPayment(data[0].PaymentMethodId)
              }
            }
          })
          .catch(() => {})
      } else {
        dispatch(setDefaultPaymentMethod(null))
      }
    },
  })

  const setDefaultPayment = (id: string) => {
    const defaultPayment: SettingViewModel = {
      Status: true,
      Value: id,
      UniqueKey: SettingsTypes.DEFAULT_PAYMENT_METHOD,
    }
    UpdateSettings([defaultPayment])
      .then(() => {
        const paymentMethod = PaymentMethods?.find((x) => x.PaymentMethodId === id)
        dispatch(setDefaultPaymentMethod(paymentMethod!))
      })
      .catch(() => {})
  }

  const { mutate: detachPaymentMethod, isPending: isDetaching } = useMutation({
    mutationKey: [ApiQueryKeys.payment.DetachPaymentMethod],
    mutationFn: (id: string) => paymentApi.DetachPaymentMethod(id),
    onSuccess: () => {
      enqueueSnackbar('Payment Method Deleted successfully', { variant: 'success' })
      getPaymentMehtods()
    },
  })
  const handleDeletePaymentMethod = (id: string) => {
    ShowAlert('question', 'Are you sure you want to detach this payment method?').then((result) => {
      if (result.isConfirmed) {
        detachPaymentMethod(id)
      }
    })
  }

  const attachPaymentMethod = (data: AttachPaymentMethodPayloadModel, makeDefault: boolean, callback: () => void) => {
    if (!stripe || !elements) {
      return
    }
    const cardElement = elements.getElement(CardElement)
    setIsAddingStripePaymentMethod(true)
    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          metadata: {
            cardHolderName: data.CardHolderName,
            address: data.Address,
            city: data.City,
            state: data.State,
            zip: data.Zip,
            userId: UserId,
          },
        })
        .then((response: PaymentMethodResult) => {
          setIsAddingStripePaymentMethod(false)
          if (response.paymentMethod) {
            data.PaymentMethodId = response.paymentMethod.id
            addPaymentMethod(data)
              .then(() => {
                getPaymentMehtods()
                cardElement.clear()
                callback()
                if (makeDefault) {
                  setDefaultPayment(response.paymentMethod.id)
                }
              })
              .catch(() => {})
          }
        })
    }
  }
  return {
    isGettingPaymentMethods,
    isDetaching,
    isUpdaingDefaultPayment,
    isAddingPaymentMethod: isAddingPaymentMethod || isAddingStripePaymentMethod,
    isGettingDefaultPayments,
    getPaymentMehtods,
    setDefaultPayment,
    handleDeletePaymentMethod,
    addPaymentMethod,
    attachPaymentMethod,
  }
}
