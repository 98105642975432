import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'
import { ParkListTab, TagViewModel, UserTaggedParkPayloadModel } from 'sdk/models'

const baseApi = new BaseApi()
export class UserTaggedParkApi {
  public addParkTag(newTaggedPark: UserTaggedParkPayloadModel) {
    return baseApi.POST_Request<ParkListTab>(apiUrls.parksites.taggedParks.addParkTag(), newTaggedPark)
  }
  public GetUserTaggedParks(query?: string) {
    return baseApi.GET_Request<Array<ParkListTab>>(apiUrls.parksites.taggedParks.getUserTaggedParks(query))
  }
  public GetTaggedParksByLabelId(tagId: string) {
    return baseApi.GET_Request<Array<ParkListTab>>(apiUrls.parksites.taggedParks.GetTaggedParksByTagId(tagId))
  }
  public GetParkTags(parkId: string) {
    return baseApi.GET_Request<Array<TagViewModel>>(apiUrls.parksites.taggedParks.getParkTags(parkId))
  }
  public RemoveAllTaggedParks() {
    return baseApi.DELETE_Request(apiUrls.parksites.taggedParks.removeAllTaggedPark())
  }
  public removeParkTag(parkId: string, tagId: string) {
    return baseApi.DELETE_Request<boolean>(apiUrls.parksites.taggedParks.removeParktag(parkId, tagId))
  }
  public removeAllTagsFromPark(parkId: string) {
    return baseApi.DELETE_Request(apiUrls.parksites.taggedParks.removeAllTagsFromPark(parkId))
  }
}
