export class UserPersonalInfoViewModel {
  public Id: string | null = null
  public FirstName: string | null = null
  public LastName: string | null = null
  public Company: string | null = null
  public PhoneNumber: string | null = null
  public Email: string | null = null
  public Role: string | null = null
  public Address: string | null = null
  public Address2: string | null = null
  public City: string | null = null
  public StateId: string | null = null
  public Zip: string | null = null
  public AccountId?: string | null = null
  public Created?: string | null = null
  public LastLogin?: string | null = null
  public UserType?: string | null = null
  public Active?: boolean | null = null
  constructor(data?: Partial<UserPersonalInfoViewModel>) {
    Object.assign(this, data)
  }
}
