import { Container } from '@mui/material'
import { AddPaymentMethodForm, AppBox, AppButton, AppTypography, SimpleCheckBox } from 'components'
import { usePaymentMethods } from 'hooks'
import { FC, useState } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AttachPaymentMethodPayloadModel } from 'sdk'
import { AuthSliceSelector } from 'store/slices'
interface Props {
  closeDialog?: () => void
  attachPaymentMethod: (
    data: AttachPaymentMethodPayloadModel,
    makeDefaultPayment: boolean,
    callback: () => void
  ) => void
  isAddingPaymentMethod: boolean
}
export const AddPayemntMethodDialog: FC<Props> = ({ closeDialog, attachPaymentMethod, isAddingPaymentMethod }) => {
  const { UserId } = useSelector(AuthSliceSelector)
  const [makeDefaultPayment, setMakeDefaultPayment] = useState<boolean>(false)
  const { control, reset } = useForm<AttachPaymentMethodPayloadModel>({ defaultValues: { UserId } })

  const handleAddPaymentSubmit = (data: AttachPaymentMethodPayloadModel) => {
    attachPaymentMethod(data, makeDefaultPayment, () => {
      reset()
      if (closeDialog) {
        closeDialog()
      }
    })
  }

  return (
    <Container>
      <Form control={control} onSubmit={({ data }) => handleAddPaymentSubmit(data)}>
        <AppBox display={'flex'} flexDirection={'column'} gap={3}>
          <AppBox textAlign={'center'}>
            <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
              Please provide us your new payment method
            </AppTypography>
          </AppBox>
          <AddPaymentMethodForm control={control} />
          <AppBox display={'flex'} alignItems={'center'}>
            <SimpleCheckBox onClick={() => setMakeDefaultPayment((prev: boolean) => !prev)} />
            <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
              Make this my subscription plan’s default payment method
            </AppTypography>
          </AppBox>
          <AppBox display={'flex'} justifyContent={'flex-end'} gap={1}>
            <AppButton variant="outlined" size="large" onClick={closeDialog}>
              Cancel
            </AppButton>
            <AppButton variant="contained" size="large" type="submit" isLoading={isAddingPaymentMethod}>
              Save
            </AppButton>
          </AppBox>
        </AppBox>
      </Form>
    </Container>
  )
}
