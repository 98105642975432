import { Card, CardContent, CardHeader, CardProps } from '@mui/material'

interface AppCardProps extends CardProps {
  title?: string
}

export const AppCard = ({ title, ...rest }: AppCardProps) => {
  return (
    <Card elevation={0} {...rest}>
      {title && <CardHeader title={title} />}
      <CardContent className="p-0 px-2 py-1">{rest.children}</CardContent>
    </Card>
  )
}
