import { MaterialDesignContent } from 'notistack'
import styled from 'styled-components'

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: 'primary',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
  },
}))
