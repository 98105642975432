import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import dayjs from 'dayjs'
import { ApiQueryKeys } from 'globals/constants'
import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ParkNoteApi, ParkNotePayload, ParkNoteViewModel, ShowAlert } from 'sdk'
import { ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'

export const useParkParkNotes = () => {
  const parkNotesAPi = new ParkNoteApi()

  const { enqueueSnackbar } = useSnackbar()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const [query, setQuery] = useState<string>('')

  const dialogRef = useRef<AppDialogRefType>({})

  // Getting Park Notes
  const {
    data,
    isPending: isGettinNotes,
    mutate: getAllNotes,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parkNotes.FetchParkNotes],
    mutationFn: () => parkNotesAPi.GetUserParkNotes(selectedParkId, query),
  })

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }, 500)

  useEffect(() => {
    getAllNotes()
  }, [query, SelectedTeam?.Id, selectedParkId])
  //

  // Saving Park Note
  const { control, handleSubmit, reset, watch } = useForm<ParkNotePayload>({
    mode: 'onSubmit',
    defaultValues: { ParkId: selectedParkId },
  })

  const { mutate: AddParkNote, isPending: isAdding } = useMutation({
    mutationFn: parkNotesAPi.AddParkNote,
    mutationKey: [ApiQueryKeys.parksites.parkNotes.AddParkNote],
    onSuccess: () => {
      handleAddingParkNoteSuccess()
    },
  })
  const handleAddingParkNoteSuccess = () => {
    enqueueSnackbar('Park Note has Been Added ', { variant: 'success' })
    dialogRef.current.closeDialog!()
    reset()
    getAllNotes()
  }
  //
  const { mutate: UpdateParkNote, isPending: isUpdating } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parkNotes.EditParkNote],
    mutationFn: (data: ParkNotePayload) => parkNotesAPi.EditParkNote(data),
    onSuccess: () => {
      enqueueSnackbar('Park Note has Been Edited ', { variant: 'success' })
      dialogRef.current.closeDialog!()
      reset()
      getAllNotes()
    },
  })
  //

  const { mutate: DeleteParkNote, isPending: isDeleting } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.parkNotes.RemoveParkNote],
    mutationFn: (id: string) => parkNotesAPi.DeleteParkNoteById(id),
    onSuccess: () => {
      enqueueSnackbar('Park Note has Been Deleted ', { variant: 'success' })
      getAllNotes()
    },
  })

  const submitForm = (data: ParkNotePayload) => {
    if (data.Id) {
      UpdateParkNote(data)
    } else {
      AddParkNote(data)
    }
  }

  const handleEdit = (note: ParkNoteViewModel) => {
    reset({
      Note: note.Note,
      Id: note.Id,
      Date: dayjs(new Date(note.Date!)) as any,
    })
    dialogRef.current.openDialog!()
  }
  const handleDelete = (id: string) => {
    ShowAlert('question', 'Are you sure you want to delete this note?').then((result) => {
      if (result.isConfirmed) {
        DeleteParkNote(id)
      }
    })
  }

  return {
    parkNotes: data,
    isGettinNotes,
    dialogRef,
    control,
    handleSubmit,
    watch,
    isPending: isAdding || isUpdating,
    isDeleting,
    submitForm,
    handleEdit,
    handleDelete,
    reset,
    handleSearch,
  }
}
