import { Divider, Grid, LinearProgress, Typography } from '@mui/material'
import {
  AppAutoComplete,
  AppBox,
  AppButton,
  AppTextField,
  AppTypography,
  EmailField,
  PhoneField,
  StateField,
  ZipField,
} from 'components'
import { useCrmContacts } from 'hooks'
import { FC } from 'react'
import { Control, UseFormWatch } from 'react-hook-form'
import { CrmContactPayload } from 'sdk/models/crm/contacts/crm-contact.payload'

interface Props {
  control: Control<CrmContactPayload>
  watch: UseFormWatch<CrmContactPayload>
  editContact: (data: CrmContactPayload) => void
  isLoading: boolean
  isEditing: boolean
}
export const CrmContactPersonalInfo: FC<Props> = ({ control, watch, isLoading, editContact, isEditing }) => {
  return (
    <AppBox
      border={1}
      padding={2}
      paddingY={4}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Contact"
    >
      <AppBox width={'100%'}>
        <AppTypography fontWeight={700} fontSize={'1rem'}>
          Contact
        </AppTypography>
        <Divider sx={{ marginY: 2.5 }} />
        {isLoading && <LinearProgress color="primary" />}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AppAutoComplete
              control={control}
              label="Contact Type"
              placeholder="Select Contact Type"
              options={[
                { label: 'Lead', value: 'lead' },
                { label: 'Contact', value: 'contact' },
              ]}
              fullWidth
              name="ContactType"
            />
          </Grid>
          <Grid item xs={6}>
            <EmailField label="Email" placeholder="Enter Email" name={'ContactEmail'} control={control} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              control={control}
              label={'First Name'}
              placeholder="Enter First Name"
              name={'ContactFirstName'}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              control={control}
              label={'Last Name'}
              name={'ContactLastName'}
              fullWidth
              placeholder="Enter Last Name"
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              control={control}
              label={'Address'}
              name={'ContactAddress'}
              fullWidth
              placeholder="Enter Address"
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              control={control}
              label={'Address(2)'}
              name={'ContactAddress2'}
              fullWidth
              placeholder="Enter Address (2)"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextField control={control} label={'City'} name={'ContactCity'} fullWidth placeholder="Enter City" />
          </Grid>
          <Grid item xs={5}>
            <StateField
              control={control}
              label={'State'}
              name={'ContactStateId'}
              fullWidth
              placeholder="Select State"
            />
          </Grid>
          <Grid item xs={3}>
            <ZipField control={control} label={'Zip'} name={'Zip'} fullWidth placeholder="Enter Zip" />
          </Grid>
          <Grid item xs={6}>
            <PhoneField control={control} label={'Phone'} name={'ContactPhone'} fullWidth placeholder="Enter Phone" />
          </Grid>
          <Grid item xs={6}>
            <PhoneField
              label={'Alt Phone'}
              name={'ContactAltPhone'}
              control={control}
              fullWidth
              placeholder="Enter Alt Phone"
            />
          </Grid>
        </Grid>
        <AppBox display={'flex'} justifyContent={'end'} gap={2} paddingTop={4}>
          <AppButton>Cancel</AppButton>
          <AppButton
            variant="contained"
            size="large"
            sidePaddings={5}
            onClick={() => editContact(watch())}
            isLoading={isEditing}
          >
            Update
          </AppButton>
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
