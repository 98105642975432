import { Grow } from '@mui/material'
import { AppBox, AppIcon, AppTypography } from 'components'
import { FC, useEffect, useState } from 'react'

interface Props {}

export const NoParkWithCrm: FC<Props> = () => {
  const [checked, setChecked] = useState(false)
  useEffect(() => setChecked(true), [])

  return (
    <AppBox backgroundColor="grey.100" height={'71vh'} alignContent={'space-evenly'}>
      <AppBox textAlign={'center'} marginTop={-6}>
        <Grow in={checked} style={{ transformOrigin: '0 0 0' }} {...(checked ? { timeout: 1000 } : {})}>
          <AppBox>
            <AppIcon icon="ri:contacts-fill" height={'30%'} color="#38BDF8" />
            <AppTypography fontWeight={'bold'} fontSize={22}>
              No Linked Contacts Yet?
            </AppTypography>
            <AppTypography fontSize={16} color={'grey.500'} fontWeight={400}>
              Connect individual contacts in your CRM to specific parks. To get started simply use the <b>CRM</b> button
              within a park’s <b>Activity Center.</b>
            </AppTypography>
            <AppTypography fontSize={16} color={'grey.500'} fontWeight={400} paddingTop={4}>
              After a contact has been added to a park, the park will begin displaying here.
            </AppTypography>
          </AppBox>
        </Grow>
      </AppBox>
    </AppBox>
  )
}
