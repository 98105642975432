import { Grid } from '@mui/material'
import { AppTextField } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { StatePayload, UpdateStateWebsitePayload } from 'sdk'

interface Props {
  control: Control<UpdateStateWebsitePayload>
}

export const EditStateWebsiteFrom: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppTextField control={control} name="Website" label="Website" />
      </Grid>
    </Grid>
  )
}
