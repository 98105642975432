import { Grid } from '@mui/material'
import {
  AppAutoComplete,
  AppBox,
  AppButton,
  AppIcon,
  AppTextField,
  AppTypography,
  PhoneField,
  WebsiteField,
} from 'components'
import { ParkSystemStatusOptions } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { Control, UseFormWatch } from 'react-hook-form'
import { ParkDetailViewModel, ParkUpdatePayload, ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ParkUpdatePayload> | Control<ReportParkPayload>
  parkDetail: ParkDetailViewModel | undefined
  isAdmin: boolean
  watch: UseFormWatch<ParkUpdatePayload> | UseFormWatch<ReportParkPayload>
  isCheckingParkUserData?: boolean
  isUserParkDataExist?: () => void
}

export const UpdateParkInfoCard: FC<Props> = ({
  control,
  watch,
  isCheckingParkUserData,
  parkDetail,
  isAdmin,
  isUserParkDataExist,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const handleCopyParkNo = () => {
    enqueueSnackbar('Copied to clipboard', { variant: 'default' })
    navigator.clipboard.writeText(parkDetail?.ParkNo!)
  }
  return (
    <>
      <AppBox>
        <Grid container>
          <Grid container spacing={1}>
            <Grid item xs={12} display={'flex'}>
              <AppTypography paddingRight={1}>Park No: {parkDetail?.ParkNo}</AppTypography>
              <AppIcon icon={'mynaui:copy'} onClick={handleCopyParkNo} color="blue" />
            </Grid>
            <Grid item xs={5}>
              <AppTextField
                sx={{ backgroundColor: 'white' }}
                required
                isSmallForm
                name="ParkInfo.ParkName"
                control={control}
                label="Park Name"
                placeholder="Enter Park Name"
              />
            </Grid>
            {isAdmin && (
              <>
                <Grid item xs={3}>
                  <AppAutoComplete
                    required
                    isSmallForm
                    name="Status"
                    control={control}
                    label="Park Status"
                    placeholder="Select Status"
                    options={ParkSystemStatusOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AppButton
                    prefixIcon="tabler-trash"
                    size="small"
                    variant="contained"
                    color="error"
                    isLoading={isCheckingParkUserData}
                    onClick={() => isUserParkDataExist!()}
                  >
                    Delete Park
                  </AppButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={5}>
            <AppTextField
              required={isAdmin}
              sx={{ backgroundColor: 'white' }}
              isSmallForm
              name="Address"
              control={control}
              label="Street Address"
              placeholder="Enter Street Address"
            />
          </Grid>
        </Grid>
        <AppBox paddingY={1} borderBottom={1} borderColor={'grey.200'}>
          <AppBox display={'flex'} alignItems={'center'} gap={1}>
            <AppTypography color={'grey.600'} fontSize={14}>
              City: <b>{parkDetail?.City}</b>
            </AppTypography>
            <AppBox width={3} height={3} backgroundColor={'secondary.600'} borderRadius={1} alignSelf={'center'} />
            <AppTypography color={'grey.600'} fontSize={14}>
              County: <b>{parkDetail?.County}</b>
            </AppTypography>
            <AppBox width={3} height={3} backgroundColor={'secondary.600'} borderRadius={1} alignSelf={'center'} />
            <AppTypography color={'grey.600'} fontSize={14}>
              State: <b>{parkDetail?.State}</b>
            </AppTypography>
            <AppBox width={3} height={3} backgroundColor={'secondary.600'} borderRadius={1} alignSelf={'center'} />
            <AppTypography color={'grey.600'} fontSize={14}>
              Zip Code: <b>{parkDetail?.Zip}</b>
            </AppTypography>
            <AppBox width={3} height={3} backgroundColor={'secondary.600'} borderRadius={1} alignSelf={'center'} />
            <Grid item>
              <PhoneField
                isSmallForm
                name="ParkInfo.ParkPhone"
                control={control}
                label="Phone"
                placeholder="Enter Phone"
              />
            </Grid>
          </AppBox>
          {isAdmin && (
            <Grid item xs={5}>
              <WebsiteField
                webSiteValue={watch().ParkInfo?.WebSite}
                isSmallForm
                name="ParkInfo.WebSite"
                control={control}
                label="Website"
                placeholder="Enter Website"
              />
            </Grid>
          )}
        </AppBox>
      </AppBox>
    </>
  )
}
