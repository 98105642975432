import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTablePaginationProps, AppTableProps, TableActions } from 'components'
import { CountyWithStateViewModel } from 'sdk'

interface Props extends AppTableProps<CountyWithStateViewModel> {
  onEditClicked: (county: CountyWithStateViewModel) => void
  onUpdateWebSiteClick: (county: CountyWithStateViewModel) => void
  onStatusUpdateClick: (county: CountyWithStateViewModel) => void
}

export const CountiesTable = ({ onEditClicked, onUpdateWebSiteClick, onStatusUpdateClick, ...props }: Props) => {
  const columns: ColumnDef<CountyWithStateViewModel>[] = [
    {
      id: 'popover',
      header: () => <></>,
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Edit County', onClick: () => onEditClicked(row.original) },
              { text: 'Edit County WebSite ', onClick: () => onUpdateWebSiteClick(row.original) },
              {
                text: row.original.ActiveStatus === 'TRUE' ? 'Deactivate' : 'Activate',
                onClick: () => {
                  onStatusUpdateClick(row.original)
                },
              },
            ]}
          />
        )
      },
    },
    {
      header: 'Status',
      cell: ({ row }) => {
        return row.original.ActiveStatus === 'TRUE' ? 'Active' : 'Inactive'
      },
    },
    {
      header: 'State',
      accessorKey: 'StateName',
    },
    {
      header: 'State Code',
      accessorKey: 'StateCode',
    },
    {
      header: 'Country',
      accessorKey: 'County',
    },
    {
      header: 'WebSite URL',
      accessorKey: 'Website',
    },
    {
      header: 'Updated',
      cell: ({ row }) => {
        return new Date(row.original.Updated).toLocaleDateString()
      },
    },
    {
      header: 'Updated By',
      accessorKey: 'UpdatedBy',
    },
  ]
  return (
    <>
      <AppTable columns={columns} {...props} />
    </>
  )
}
