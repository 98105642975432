import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'
import { CrmCompanyPayload, CrmCompanyViewModel, PaginatedApiResponseModel, PaginationFilterModel } from 'sdk/models'

const baseApi = new BaseApi()
export class CrmCompaniesApi {
  public GetCrmCompanies(filter: PaginationFilterModel) {
    return baseApi.GET_Request<PaginatedApiResponseModel<CrmCompanyViewModel>>(
      apiUrls.crm.companies.getCrmCompanies(filter)
    )
  }
  public GetCrmCompany(companyId: string) {
    return baseApi.GET_Request<CrmCompanyViewModel>(apiUrls.crm.companies.getCrmCompanyById(companyId))
  }
  public GetCrmCompanyDetails(companyId: string) {
    return baseApi.GET_Request<any>(apiUrls.crm.companies.getCrmCompanyDetails(companyId))
  }
  public AddCrmCompany(companyData: CrmCompanyPayload) {
    return baseApi.POST_Request<any>(apiUrls.crm.companies.addCrmCompany(), companyData)
  }
  public EditCrmCompany(companyId: string, companyData: CrmCompanyPayload) {
    return baseApi.PUT_Request<any>(apiUrls.crm.companies.editCrmCompany(companyId), companyData)
  }
  public IsCompanyExist(CompanyName: string) {
    return baseApi.GET_Request<boolean>(apiUrls.crm.companies.isCompanyExist(CompanyName))
  }
  public DeleteCrmCompanies(ids: string[]) {
    return baseApi.PATCH_Request<any>(apiUrls.crm.companies.deleteCompanies(), { Ids: ids })
  }
  public DeleteAllCrmCompanies() {
    return baseApi.DELETE_Request<any>(apiUrls.crm.companies.deleteAllCompanies())
  }
}
