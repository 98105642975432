import { useMutation } from '@tanstack/react-query'
import { AppBox, AppDialog, AppDialogRefType, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { FC, useEffect, useRef, useState } from 'react'
import { AccountApi } from 'sdk'
import { ManageAccountDialog } from '../ManageAccountDialog'
import { AdditionalUsersTable } from '../additional-users-table/AdditionalUsersTable'

interface Props {
  userId: string
}

export const ManageAccountAdditionalUsersCard: FC<Props> = ({ userId }) => {
  const accountApi = new AccountApi()
  const {
    data: subAccounts,
    mutate: getSubAccounts,
    isPending: isGetting,
  } = useMutation({
    mutationFn: (userId: string) => accountApi.getUserSubAccounts(userId),
    mutationKey: [ApiQueryKeys.account.getUserSubAccounts],
  })
  const [userIdSelected, setUserIdSelected] = useState<string>('')
  const dialogRef = useRef<AppDialogRefType>({})

  useEffect(() => getSubAccounts(userId), [userId])
  return (
    <>
      <AppBox border={1} borderColor={'grey.200'} borderRadius={6} padding={2}>
        <AppBox borderBottom={1} borderColor={'grey.200'} paddingBottom={2}>
          <AppTypography fontWeight={700} fontSize={16}>
            Additional Users
          </AppTypography>
        </AppBox>
        <AppBox paddingTop={2}>
          <AdditionalUsersTable
            data={subAccounts ?? []}
            isLoading={isGetting}
            onManageClick={(userId) => {
              setUserIdSelected(userId)
              dialogRef.current.openDialog!()
            }}
            onResendInvitationClick={() => {}}
          />
        </AppBox>
      </AppBox>
      <AppDialog
        maxWidth="xl"
        ref={dialogRef}
        title="Manage User"
        renderChild={() => <ManageAccountDialog userId={userIdSelected} isSubAccount={true} />}
      />
    </>
  )
}
