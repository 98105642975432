import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CrmHelpModules, CrmTools } from 'globals/constants'
import { IAppStore } from 'store/app.store'

export interface CrmToolsTypes {
  openedCrmTool: CrmTools
  openedCrmListingHelpModule: { key: CrmHelpModules; options: { Title: string; Id?: string } } | null
}

const initialState: CrmToolsTypes = {
  openedCrmTool: CrmTools.Contacts,
  openedCrmListingHelpModule: null,
}

export const CrmToolsSlice = createSlice({
  name: 'CrmTools',
  initialState: initialState,
  reducers: {
    setCrmTool: (state: CrmToolsTypes, action) => {
      state.openedCrmTool = action.payload
    },
    setCrmListingHelpModule: (
      state: CrmToolsTypes,
      action: PayloadAction<{ key: CrmHelpModules; options: { Title: string; Id?: string } }>
    ) => {
      state.openedCrmListingHelpModule = action.payload
    },
    closeCrmListingHelpModule: (state: CrmToolsTypes) => {
      state.openedCrmListingHelpModule = null
    },
  },
})

export const { setCrmTool, setCrmListingHelpModule, closeCrmListingHelpModule } = CrmToolsSlice.actions
export const CrmToolSliceSelector = (appState: IAppStore): CrmToolsTypes => appState.CrmTool
