import { ColumnDef } from '@tanstack/react-table'
import { AppBox, AppTable, AppTableProps, AppTypography, TableActions } from 'components'
import { OutSideTeamStatus } from 'globals/constants'
import { getFullDate } from 'plugins/helper/helper'
import { TeamMemberViewModel } from 'sdk'
interface Props extends AppTableProps<TeamMemberViewModel> {
  onRevokeAccessClick: (member: TeamMemberViewModel) => void
  onResendInviteClick: (member: TeamMemberViewModel) => void
}
export const OutSideTeamMembersTable = ({ data, onRevokeAccessClick, onResendInviteClick, ...props }: Props) => {
  const columns: ColumnDef<TeamMemberViewModel>[] = [
    {
      id: 'popover',
      header: () => <></>,
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[{ text: 'Revoke Access', onClick: () => onRevokeAccessClick(row.original) }]}
          />
        )
      },
    },
    {
      header: 'Name',
      accessorKey: 'Name',
    },
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Invite Date',
      cell({ row }) {
        return getFullDate(row.original.InviteDate)
      },
    },
    {
      header: 'Accepted Date',
      cell({ row }) {
        if (row.original.AcceptedDate) {
          return getFullDate(row.original.AcceptedDate)
        }
        if (row.original.Status === OutSideTeamStatus.Revoked) {
          return 'Revoked'
        }
        return 'Pending'
      },
    },
    {
      header: 'Status',
      cell({ row }) {
        return row.original.Status === OutSideTeamStatus.Email_Sent ? (
          <AppBox onClick={() => onResendInviteClick(row.original)} cursor="pointer">
            <AppTypography color={'primary.200'}>Resend</AppTypography>
          </AppBox>
        ) : (
          row.original.Status
        )
      },
    },
  ]
  return <AppTable columns={columns} data={data} {...props} />
}
