import { AppBox, AppPagination } from 'components'
import { NewAlertsTable } from './NewAlertsTable'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { NewsAlertApi, PaginationFilterModel } from 'sdk'
import { useMutation } from '@tanstack/react-query'
import { useNewsAlert } from 'hooks'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const NewAlertsListing: FC<Props> = ({ filter, setFilter }) => {
  const { GetFilteredAlerts, isGetting, filterdAlerts } = useNewsAlert()
  useEffect(() => {
    GetFilteredAlerts(filter)
  }, [])
  useEffect(() => {
    GetFilteredAlerts(filter)
  }, [filter])
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <NewAlertsTable
          data={filterdAlerts?.Data ?? []}
          totalRecords={filterdAlerts?.TotalCount ?? 0}
          isLoading={isGetting}
          paginationFilter={filter}
          onCurrentPageChange={(value) => setFilter((prev) => ({ ...prev, PageSize: value }))}
          onPageSizeChange={(value) => setFilter((prev) => ({ ...prev, PageSize: value }))}
        />
      </AppBox>
    </>
  )
}
