import { ParksList } from 'components'
import { NoParkWithCrm } from 'components/shared/no-data-components'
import { useGetParksWithCrm } from 'hooks'

export const SideToolCrm = () => {
  const { isFetchingParksWithCrm, parksWithCrm, refetch } = useGetParksWithCrm()
  return (
    <ParksList
      refetch={(query: string | undefined) => refetch(query ?? '')}
      linkedParks={parksWithCrm ?? []}
      isLoading={isFetchingParksWithCrm}
      noParksComponent={
        <>
          <NoParkWithCrm />
        </>
      }
    />
  )
}
