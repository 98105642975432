import { useMutation } from '@tanstack/react-query'
import {
  FilterCondition,
  FilterConditionTypes,
  FilterField,
  FilterFieldType,
  FilterInputValueFrom,
} from 'globals/Filter/FilterTypes'
import { CrmCompanyFilterOptions } from 'globals/Filter/options/CrmCompanyFilterOptions'
import { CrmContactFilterData } from 'globals/Filter/options/CrmContactFilterOptions'
import {
  ParkSiteFilterData,
  ParkSiteFilterGroups,
  ParkSiteFilterPrincipalTypes,
} from 'globals/Filter/options/ParkSiteFilterOptions'
import {
  ApiQueryKeys,
  FILTER_PRINCIPAL_TYPES,
  FloodPlainTypeOptions,
  HeatingFuelTypes,
  ParkSystemStatusOptions,
  SewerTypes,
  StreetOwnedByTypes,
  StreetTypes,
  TrashServiceTypes,
  WaterSourceTypes,
} from 'globals/constants'
import { useCrmContacts } from 'hooks/crm/crm-contacts.hook'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ApiErrorModel,
  CrmContactsApi,
  CrmContactViewModel,
  FilterApi,
  FilterConditionSaveModel,
  FilteredParkSitesModel,
  FilterPayload,
  PaginatedApiResponseModel,
  PaginationFilterModel,
  ParkFilterApi,
  ShowAlert,
} from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { closeTool, setIsFilterToolOpened } from 'store/slices'
import {
  FilterSliceSelector,
  setAppliedCrmCompanyFilter,
  setAppliedCrmContactFilter,
  setAppliedParkFilter,
  setCrmCompanyFilteredData,
  setCrmCompanyIsRetractedView,
  setCrmContactFilteredDataCount,
  setCrmContactFilters,
  setCrmContactsFilteredData,
  setCrmContactsIsRetractedView,
  setIsCrmCompanyFilterToolOpened,
  setIsCrmContactFilterToolOpened,
  setIsUnSavedCrmCompanyFilterApplied,
  setIsUnSavedCrmContactFilterApplied,
  setIsUnSavedParkFilterApplied,
  setParkFilterCrmContactIds,
  setParkFilteredData,
  setParkFilters,
  setParkIsRetractedView,
} from 'store/slices/filter.slice'
import Swal from 'sweetalert2'
interface Props {
  type: FILTER_PRINCIPAL_TYPES
  setIsApplyingFilter?: (val: boolean) => void
  paginationFilter?: PaginationFilterModel
  preventFiltersUpdate?: boolean
}
export const useFilterBuilder = ({ type, setIsApplyingFilter, paginationFilter, preventFiltersUpdate }: Props) => {
  const filterApi = new FilterApi()
  const parkFilterApi = new ParkFilterApi()
  const contactApi = new CrmContactsApi()

  // hooks
  const { enqueueSnackbar } = useSnackbar()
  const { handleExportCrmContacts, isExporting } = useCrmContacts({})

  const dispatch = useAppDispatch()
  const { Park, CrmCompany, CrmContact } = useSelector(FilterSliceSelector)

  // Api calls
  const { mutate: GetParkFilters, isPending: IsLoadingParkFilters } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.filter.GetParkFilters],
    mutationFn: () => parkFilterApi.GetParkFilters(),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onSuccess: (data) => {
      dispatch(setParkFilters(data))
    },
  })
  const { mutate: GetContactFilters, isPending: IsLoadingContactFilters } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactFilter.FetchContactFilters],
    mutationFn: () => contactApi.GetContactFilters(),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onSuccess: (data) => {
      dispatch(setCrmContactFilters(data))
    },
  })
  const { mutateAsync: DeleteFilter, isPending: isDeletingFilter } = useMutation({
    mutationFn: (id: string) => filterApi.DeleteFilter(id),
    mutationKey: [ApiQueryKeys.filter.deleteFilter],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  const { mutateAsync: SaveNewFilter, isPending: isSavingNewFilter } = useMutation({
    mutationKey: [ApiQueryKeys.filter.addFilter],
    mutationFn: (data: FilterPayload) => filterApi.AddFilter(data),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onSuccess: () => {
      enqueueSnackbar('Filter created successfully', { variant: 'success' })
    },
  })
  const { mutateAsync: EditFilterName, isPending: isEditingFilter } = useMutation({
    mutationKey: [ApiQueryKeys.filter.updateFilter],
    mutationFn: (data: { id: string; newName: string }) => filterApi.UpdateFilterName(data.id, data.newName),
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess: () => enqueueSnackbar('Filter name updated successfully', { variant: 'success' }),
  })

  const { mutate: ApplyParkFilter, isPending: isApplyingParkFilter } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.filter.ApplyParkFilter],
    mutationFn: (data: Array<FilterConditionSaveModel>) => parkFilterApi.ApplyParkFilter(data),
    onError: (error: ApiErrorModel) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      if (setIsApplyingFilter) {
        setIsApplyingFilter!(false)
      }
      dispatch(setParkFilteredData([]))
      dispatch(setParkFilterCrmContactIds([]))
      dispatch(setIsUnSavedParkFilterApplied(true))
    },
    onSuccess: (data: FilteredParkSitesModel) => {
      dispatch(setIsUnSavedParkFilterApplied(true))
      dispatch(setParkFilteredData(data.Pins))
      dispatch(setParkFilterCrmContactIds(data.CrmContactIds))
      if (setIsApplyingFilter) {
        setIsApplyingFilter!(false)
      }
      if (tempFilterCount > 0) {
        dispatch(setAppliedParkFilter(null))
      }
      setTempFilterCount(tempFilterCount + 1)
      enqueueSnackbar('Filter Applied successfully', { variant: 'success' })
    },
  })
  const { mutate: ApplyContactFilter, isPending: isApplyingContactFilter } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactFilter.ApplyContactFilter],
    mutationFn: (data: Array<FilterConditionSaveModel>) => contactApi.applyContactFilter(data, paginationFilter!),
    onError: (error: ApiErrorModel) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      if (setIsApplyingFilter) {
        setIsApplyingFilter!(false)
      }
      dispatch(setCrmContactsFilteredData([]))
      dispatch(setCrmContactFilteredDataCount(0))
      dispatch(setIsUnSavedCrmContactFilterApplied(false))
    },
    onSuccess: (data: PaginatedApiResponseModel<CrmContactViewModel>) => {
      dispatch(setIsUnSavedCrmContactFilterApplied(true))
      dispatch(setCrmContactsFilteredData(data.Data))
      dispatch(setCrmContactFilteredDataCount(data.TotalCount))
      if (setIsApplyingFilter) {
        setIsApplyingFilter!(false)
      }
      if (tempFilterCount > 0) {
        dispatch(setAppliedCrmContactFilter(null))
      }
      setTempFilterCount(tempFilterCount + 1)
      enqueueSnackbar('Filter Applied successfully', { variant: 'success' })
    },
  })
  const conditionSigns = {
    [FilterConditionTypes.Equals]: '=',
    [FilterConditionTypes.Does_Not_Equal]: '!=',
    [FilterConditionTypes.Is_More_Than]: '>',
    [FilterConditionTypes.Is_Less_Than]: '<',
    [FilterConditionTypes.Is_More_Than_Area]: '>',
    [FilterConditionTypes.Is_Less_Than_Area]: '<',
    [FilterConditionTypes.Unregistered]: 'Permit Unregistered',
    [FilterConditionTypes.Registered]: 'Permit Registered',
    [FilterConditionTypes.YearUnknown]: 'Year Unknown',
    [FilterConditionTypes.YearNotUnknown]: 'Year Not Unknown',
    [FilterConditionTypes.AcresUnknown]: 'Acres Unknown',
    [FilterConditionTypes.AcresNotUnknown]: 'Acres Not Unknown',
    [FilterConditionTypes.ParkPhoneUnknown]: 'Park Phone Unknown',
    [FilterConditionTypes.ParkPhoneNotUnknown]: 'Park Phone Not Unknown',
    [FilterConditionTypes.Is_Null]: 'Is Null',
    [FilterConditionTypes.Is_Not_Null]: 'Is Not Null',
    [FilterConditionTypes.Equals_Date]: '=',
    [FilterConditionTypes.Does_Not_Equal_Date]: '!=',
    [FilterConditionTypes.Is_Before_Date]: '<',
    [FilterConditionTypes.Is_After_Date]: '>',
    [FilterConditionTypes.Contains]: 'Contains',
    [FilterConditionTypes.Does_Not_Contain]: 'Not Contains',
    [FilterConditionTypes.In]: 'In',
    [FilterConditionTypes.Not_In]: 'Not In',
    [FilterConditionTypes.EqualsWithPreCond]: '=',
    [FilterConditionTypes.NotEqualsWithPreCond]: '!=',
  }
  // helper functions
  const filterData = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return ParkSiteFilterData
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompanyFilterOptions
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContactFilterData
    }
    return []
  }
  const filterGroupsData = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return ParkSiteFilterGroups
    }
    return []
  }
  const filterPrincipalTypes = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return ParkSiteFilterPrincipalTypes
    }
    return null
  }
  const allFields = () => {
    return filterData().flatMap((x) => x.Fields)
  }
  const defaultFields = () => allFields().filter((x) => x.IsDefaultFilter)

  const fieldByCoumnAndTableName = (columnName: string, tableName: string) => {
    return allFields().find((x) => x.ColumnName === columnName && x.TableName === tableName)
  }
  const conditionByColumnAndTableNameAndCondition = (
    ColumnName: string,
    tableName: string,
    newFilterCondition: FilterConditionTypes
  ) => {
    const conditions = fieldByCoumnAndTableName(ColumnName, tableName)?.Conditions
    const selectedCondition = conditions?.find((x) => x.Condition === newFilterCondition)
    return selectedCondition
  }

  const dropDownItems = (condition: FilterCondition): { value: string; label: string }[] => {
    if (condition?.InputField && condition.InputField.Type === FilterFieldType.DropDown) {
      if (condition.InputField.ValueFrom === FilterInputValueFrom.RoleTypes) {
        return ['Owner', 'Manager', 'Employee', 'Other'].map((x) => {
          return { value: x, label: x }
        })
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.LegendType) {
        return [
          {
            value: 'NoParksData',
            label: 'No Park Data',
          },
          {
            value: 'Unclaimed',
            label: 'Unclaimed',
          },
          {
            value: 'Claimed',
            label: 'Claimed',
          },
          {
            value: 'MyParks',
            label: 'My Parks',
          },
        ]
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.TimeZoneTypes) {
        return [
          'America/Moncton',
          'America/Detroit',
          'America/Los_Angeles',
          'America/Kentucky/Louisville',
          'America/Indiana/Marengo',
          'America/Regina',
          'America/North_Dakota/Beulah',
          'Pacific/Honolulu',
          'America/Indiana/Knox',
          'America/New_York',
          'America/Indiana/Vincennes',
          'America/North_Dakota/New_Salem',
          'America/Denver',
          'America/Juneau',
          'America/Phoenix',
          'America/Indiana/Indianapolis',
          'America/Chicago',
          'America/Indiana/Petersburg',
          'America/New York',
          'America/Anchorage',
          'America/Indiana/Tell_City',
          'America/Nome',
          'America/Indiana/Vevay',
          'America/Boise',
          'America/Kentucky/Monticello',
          'America/Adak',
          'America/Sitka',
          'America/Indiana/Winamac',
          'America/Menominee',
        ].map((x) => {
          return { value: x, label: x }
        })
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.CityTypes) {
        return [
          'Town',
          'Charter Township',
          'City',
          'Village',
          'Urban County',
          'CDP',
          'City and Borough',
          'Borough',
          'Township',
          'Corporation',
          'Municipality',
          'Other',
          'Consolidated Government',
          'Metropolitan Government',
          'Unified Government',
        ].map((x) => {
          return { value: x, label: x }
        })
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.StreetTypes) {
        return StreetTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.StreetOwnerTypes) {
        return StreetOwnedByTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.WaterSourceTypes) {
        return WaterSourceTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.SewerSourceTypes) {
        return SewerTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.HeatingFuelTypes) {
        return HeatingFuelTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.FloodPlainTypes) {
        return FloodPlainTypeOptions
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.TrashServiceTypes) {
        return TrashServiceTypes
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.ParkSystemStatuses) {
        return ParkSystemStatusOptions
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.SurfaceTypes) {
        return [
          { value: 'RVLotSurfaceTypeGravel', label: 'Gravel' },
          { value: 'RVLotSurfaceTypeConcrete', label: 'Concrete' },
          { value: 'RVLotSurfaceTypeAsphalt', label: 'Asphalt' },
          { value: 'RVLotSurfaceTypeGrass', label: 'Grass' },
        ]
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.Misc_AmenititesTypes) {
        return [
          { value: 'SecurityCameras', label: 'Security Cameras' },
          { value: 'CommunitySwimminngPool', label: 'Community Swimminng Pool' },
          { value: 'AgeRestricted', label: 'Age Restricted' },
          { value: 'CertifiedStormShelter', label: 'Certified Storm Shelter' },
          { value: 'CommunityActivityBuilding', label: 'Community Activity Building' },
          { value: 'NoAgeRestricted', label: 'All Ages' },
          { value: 'Laundary', label: 'Laundary' },
          { value: 'OnSiteOffice', label: 'On-Site Office' },
          { value: 'StorageUnitRentals', label: 'Storage Unit Rentals' },
          { value: 'RuralLiving', label: 'Rural Living' },
          { value: 'RVSepticDumpStation', label: 'RV Septic Dump Station' },
          { value: 'UrbanLiving', label: 'Urban Living' },
          { value: 'RVCampHost', label: 'RV Camp Host' },
          { value: 'OnSiteManagement', label: 'On Site Management' },
          { value: 'BasketballTennisCourts', label: 'Basketball/Tennis Courts' },
          { value: 'NoMobileHomeLots', label: 'No MH Lots' },
        ]
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.ParkActivityTypes) {
        return [
          'Park Created',
          'Park Updated',
          'Park Claimed',
          'Park Unclaimed',
          'Information Provided',
          'Listing Created',
          'Listing Published',
          'For Sale',
          'Sold',
          'Sold Parks',
        ].map((x) => {
          return { value: x, label: x }
        })
      } else if (condition.InputField.ValueFrom === FilterInputValueFrom.CrmContactTypes) {
        return ['Contact', 'Lead'].map((x) => {
          return { value: x, label: x }
        })
      }
      return []
    }
    return []
  }

  const parseFilterValues = (conditions: Array<FilterConditionSaveModel>) => {
    for (const condition of conditions) {
      if (Array.isArray(condition.Value)) {
        condition.Value = '(' + condition.Value.map((value) => `'${value}'`).join(', ') + ')'
      } else {
        const conditionData = fieldByCoumnAndTableName(condition.ColumnName!, condition.TableName!)?.Conditions.find(
          (x) => x.Condition === condition.Condition
        )
        if (conditionData?.InputField?.Type === FilterFieldType.Comma_Separated_Number) {
          condition.Value = condition.Value?.replaceAll(/,/g, '')
        }
      }
    }
    return conditions
  }
  const getFilters = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      GetParkFilters()
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      GetParkFilters()
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      GetContactFilters()
    }
  }
  const applyFilter = (conditions: Array<FilterConditionSaveModel>) => {
    if (conditions.length === 0) {
      enqueueSnackbar('Please add conditions to apply filter', { variant: 'error' })
    }
    const copiedConditions = JSON.parse(JSON.stringify(conditions))
    const parsedFilters = parseFilterValues(copiedConditions)
    if (setIsApplyingFilter) {
      setIsApplyingFilter!(true)
    }
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      ApplyParkFilter(parsedFilters)
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      ApplyContactFilter(parsedFilters)
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      ApplyContactFilter(parsedFilters)
    }
  }
  const appliedSavedFilter = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.AppliedFilter
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.AppliedFilter
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.AppliedFilter
    }
  }
  const isFilterApplied = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.IsUnSavedFilterApplied || !!Park.AppliedFilter
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.IsUnSavedFilterApplied || !!CrmCompany.AppliedFilter
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.IsUnSavedFilterApplied || !!CrmContact.AppliedFilter
    }
  }
  const isParkFilterHasCrmContacts = () => {
    return type === FILTER_PRINCIPAL_TYPES.PARK && Park.CrmContactIds.length > 0
  }
  const ParkFilteredCrmContactIds = () => {
    return Park.CrmContactIds
  }
  const clearFilter = () => {
    dispatch(closeTool())
  }

  const saveFilter = (filters: FilterConditionSaveModel[]) => {
    if (filters.length === 0) {
      enqueueSnackbar('Please add conditions to save filter', { variant: 'error' })
      return
    }

    const newFilter: FilterPayload = {
      Name: '',
      FilterConditions: parseFilterValues(filters),
      PrincipleType: type,
    }
    Swal.fire({
      title: 'Save Filter',
      text: 'Enter the name for this filter',
      input: 'text',
      inputPlaceholder: 'Enter the filter name',
      showCancelButton: true,
      confirmButtonText: 'Save',
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage('You need to enter a filter name')
          return
        } else {
          newFilter.Name = value
          SaveNewFilter(newFilter)
            .then((x) => {
              if (type === FILTER_PRINCIPAL_TYPES.PARK) {
                dispatch(setAppliedParkFilter(x))
              } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
                dispatch(setAppliedCrmCompanyFilter(x))
              } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
                dispatch(setAppliedCrmContactFilter(x))
              }
            })
            .catch((x) => {})
        }
      },
    })
  }
  const handleEditName = (name: string, setFilterNameToBeEdited: Dispatch<SetStateAction<boolean>>) => {
    EditFilterName({ id: appliedSavedFilter()?.Id!, newName: name! })
      .then(() => {
        setFilterNameToBeEdited(false)
        if (type === FILTER_PRINCIPAL_TYPES.PARK) {
          dispatch(setAppliedParkFilter({ ...appliedSavedFilter()!, Name: name! }))
        } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
          dispatch(setAppliedCrmCompanyFilter({ ...appliedSavedFilter()!, Name: name! }))
        } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
          dispatch(setAppliedCrmContactFilter({ ...appliedSavedFilter()!, Name: name! }))
        }
      })
      .catch((x) => {})
  }
  // States

  const [filters, setFilters] = useState<FilterConditionSaveModel[]>([])
  const [filterNameToBeEdited, setFilterNameToBeEdited] = useState<boolean>(false)
  const [tempNewFilterName, setTempNewFilterName] = useState<string>('')
  const [tempFilterCount, setTempFilterCount] = useState<number>(0)

  const addNewFilter = (
    filter: FilterConditionSaveModel,
    origin?: typeof FilterConditionSaveModel.prototype.Origin
  ) => {
    filter.PreConditionField = fieldByCoumnAndTableName(filter.ColumnName!, filter.TableName!)?.PreConditionField
    filter.Origin = origin
    setFilters([...filters, filter])
  }

  const handleUpdateFilter = (filter: FilterConditionSaveModel) => {
    const newFilters = [...filters]
    const newFilter = { ...filter }
    newFilter.PreConditionField = fieldByCoumnAndTableName(filter.ColumnName!, filter.TableName!)?.PreConditionField
    const index = filters.findIndex(
      (x) => x.ColumnName === filter.ColumnName && x.TableName === filter.TableName && x.Origin === filter.Origin
    )
    newFilters[index] = newFilter
    setFilters(newFilters)
  }

  const getCategories = (groupType: 'all' | 'group', groupLabel?: string) => {
    if (groupType === 'all') {
      return filterData()
    } else if (groupType === 'group' && groupLabel) {
      if (type === FILTER_PRINCIPAL_TYPES.PARK) {
        return ParkSiteFilterGroups.filter((x) => x.Label === groupLabel)
      }
    }
    return filterData()
  }
  const handleRemoveFilter = (index: number) => {
    const newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
  }
  const getFilterValue = (filter: FilterConditionSaveModel) => {
    const condition = fieldByCoumnAndTableName(filter.ColumnName!, filter.TableName!)?.Conditions?.find(
      (x) => x.Condition === filter.Condition
    )
    if ([FilterFieldType.States, FilterFieldType.Counties].includes(condition?.InputField?.Type!)) {
      if (Array.isArray(filter.Value)) {
        return !isNaN(Number(filter.Value.join(', ')))
          ? Number(filter.Value.join(', ')).toLocaleString()
          : filter.Value.toLocaleString()
      }
    }
    if (condition?.InputField?.Type === FilterFieldType.DropDown) {
      const found = dropDownItems(condition!).find((x) => x.value === filter.Value)
      return !isNaN(Number(found?.label)) ? Number(found?.label).toLocaleString() : found?.label
    }
    if (condition?.InputField?.Type === FilterFieldType.Zip) {
      return filter.Value
    }
    if (condition?.InputField?.Type === FilterFieldType.Currency) {
      return '$' + (!isNaN(Number(filter.Value)) ? Number(filter.Value).toLocaleString() : filter.Value)
    }
    return !isNaN(Number(filter.Value)) ? Number(filter.Value).toLocaleString() : filter.Value
  }
  const handleSaveClick = () => {
    saveFilter(filters)
  }
  const handleClear = () => {
    ShowAlert('question', 'Are you sure you want to clear all filters?', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setFilters([])
      }
    })
  }
  const handleRetract = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      dispatch(setParkIsRetractedView(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      dispatch(setCrmCompanyIsRetractedView(true))
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      dispatch(setCrmContactsIsRetractedView(true))
    }
  }

  const closeAndClearFilter = () => {
    if (filters.length > 0 && !appliedSavedFilter()) {
      ShowAlert('question', 'Do you want to save this filter before closing?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSaveClick()
        } else {
          closeFilter()
        }
      })
    } else {
      closeFilter()
    }
  }
  const exportParkCrmContacts = () => {
    handleExportCrmContacts({ Ids: ParkFilteredCrmContactIds(), ContactType: undefined })
  }
  const closeFilter = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      dispatch(setParkFilteredData([]))
      dispatch(setParkFilterCrmContactIds([]))
      dispatch(setIsUnSavedParkFilterApplied(false))
      dispatch(setParkIsRetractedView(false))
      dispatch(setAppliedParkFilter(null))
      dispatch(setIsFilterToolOpened(false))
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      dispatch(setCrmCompanyFilteredData([]))
      dispatch(setIsUnSavedCrmCompanyFilterApplied(false))
      dispatch(setCrmCompanyIsRetractedView(false))
      dispatch(setAppliedCrmCompanyFilter(null))
      dispatch(setIsCrmCompanyFilterToolOpened(false))
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      dispatch(setCrmContactsFilteredData([]))
      dispatch(setIsUnSavedCrmContactFilterApplied(false))
      dispatch(setCrmContactsIsRetractedView(false))
      dispatch(setAppliedCrmContactFilter(null))
      dispatch(setIsCrmContactFilterToolOpened(false))
    }
  }
  const isRetracted = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.IsRetractedView
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.IsRetractedView
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.IsRetractedView
    }
    return false
  }
  const filterResult = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return Park.FilteredData
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return CrmCompany.FilteredData
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return CrmContact.FilteredData
    }
  }

  const recordLabel = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      return 'Park Records'
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      return 'Crm Contacts'
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      return 'Crm Companies'
    }
  }
  const handleExpand = () => {
    if (type === FILTER_PRINCIPAL_TYPES.PARK) {
      dispatch(setParkIsRetractedView(false))
    } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY) {
      dispatch(setCrmCompanyIsRetractedView(false))
    } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT) {
      dispatch(setCrmContactsIsRetractedView(false))
    }
  }

  const onFilterPrincipalTypeChange = (value: string) => {
    const added = addedPrincipalType()
    if (!!added) {
      added.ColumnName = value
      const index = filters.findIndex((x) => x.Origin === 'principalType')
      handleUpdateFilter(added)
    } else {
      const newFilter = new FilterConditionSaveModel({
        TableName: 'ParkPrincipal',
        ColumnName: value,
        Condition: FilterConditionTypes.Equals,
      })
      addNewFilter(newFilter, 'principalType')
    }
  }
  const addedPrincipalType = () => {
    return filters.find((x: FilterConditionSaveModel) => x.Origin === 'principalType')
  }
  const addedGroupType = () => {
    return filters.filter((x: FilterConditionSaveModel) => x.Origin === 'group')
  }
  const selectedDefaultFilter = (field: FilterField) => {
    return filters.find((x) => x.ColumnName === field.ColumnName && x.TableName === field.TableName)
  }
  const visibleCategoryFields = (fields: Array<FilterField>) => {
    const filtered = fields.filter(
      (field) => !filters.find((x) => x.ColumnName === field.ColumnName && x.TableName === field.TableName)
    )
    return filtered
  }

  useEffect(() => {
    if (!preventFiltersUpdate) {
      if (filters.length > 0) {
        applyFilter(filters)
      } else {
        if (type === FILTER_PRINCIPAL_TYPES.PARK) {
          dispatch(setParkFilteredData([]))
          dispatch(setParkFilterCrmContactIds([]))
          dispatch(setIsUnSavedParkFilterApplied(false))
          if (!Park.AppliedFilter && tempFilterCount > 0) {
            dispatch(setAppliedParkFilter(null))
          }
        } else if (type === FILTER_PRINCIPAL_TYPES.COMPANY && tempFilterCount > 0) {
          dispatch(setCrmCompanyFilteredData([]))
          dispatch(setIsUnSavedCrmCompanyFilterApplied(false))
          if (!CrmCompany.AppliedFilter && tempFilterCount > 0) {
            dispatch(setAppliedCrmCompanyFilter(null))
          }
        } else if (type === FILTER_PRINCIPAL_TYPES.CONTACT && tempFilterCount > 0) {
          dispatch(setCrmContactsFilteredData([]))
          dispatch(setIsUnSavedCrmContactFilterApplied(false))
          if (!CrmContact.AppliedFilter && tempFilterCount > 0) {
            dispatch(setAppliedCrmContactFilter(null))
          }
        }
      }
    }
  }, [filters, paginationFilter])

  useEffect(() => {
    if (!!appliedSavedFilter() && appliedSavedFilter()?.FilterConditions?.length) {
      setFilters(appliedSavedFilter()?.FilterConditions!)
    }
  }, [])

  const filteredAddedFilters = useMemo(() => {
    return filters.filter((x) => !x.Origin)
  }, [filters])

  return {
    filterData,
    filterGroupsData,
    filterPrincipalTypes,
    appliedSavedFilter,
    isFilterApplied,
    isSavingNewFilter,
    allFields,
    fieldByCoumnAndTableName,
    conditionByColumnAndTableNameAndCondition,
    isParkFilterHasCrmContacts,
    exportParkCrmContacts,
    isExporting,
    ParkFilteredCrmContactIds,
    defaultFields,
    applyFilter,
    getFilters,
    isGettingFilters: IsLoadingParkFilters || IsLoadingContactFilters,
    clearFilter,
    saveFilter,
    isLoading: isApplyingParkFilter || isApplyingContactFilter,
    dropDownItems,
    conditionSigns,
    handleEditName,
    DeleteFilter,
    isDeletingFilter,
    closeAndClearFilter,
    handleClear,
    handleSaveClick,
    getFilterValue,
    getCategories,
    handleRemoveFilter,
    handleUpdateFilter,
    visibleCategoryFields,
    selectedDefaultFilter,
    addNewFilter,
    filters,
    filterResult,
    filteredAddedFilters,
    addedPrincipalType,
    addedGroupType,
    onFilterPrincipalTypeChange,
    isRetracted,
    handleExpand,
    handleRetract,
    setFilterNameToBeEdited,
    filterNameToBeEdited,
    setTempNewFilterName,
    tempNewFilterName,
    recordLabel,
  }
}
