import { ForgotPasswordPayload, SessionModel, SetPasswordPayload } from 'sdk'
import { ChangePasswordPayloadModel, LoginPayloadModel, SignUpPayloadModel } from '../..'
import { apiUrls } from '../../api-urls'
import { BaseApi } from '../base.api'
const baseApi = new BaseApi()
export class AuthApi {
  public appSignUp(signUpPayload: SignUpPayloadModel) {
    return baseApi.POST_Request<SessionModel>(apiUrls.auth.AppSignup(), signUpPayload)
  }

  public appLogin(loginPayloadModel: LoginPayloadModel) {
    return baseApi.POST_Request<SessionModel>(apiUrls.auth.AppLogIn(), loginPayloadModel)
  }

  public getTeamAuthToken(teamId: string) {
    return baseApi.POST_Request<string>(apiUrls.auth.getTeamAuthToken(teamId), {}, false)
  }
  public changePassword(payload: ChangePasswordPayloadModel) {
    return baseApi.POST_Request<boolean>(apiUrls.account.changePassword(), payload)
  }

  //   async signOutAsync(): Promise<void | AxiosResponse> {
  //     return this.requestAsync('POST', '/auth/sign-out')
  //   }

  public forgotPassword(payload: ForgotPasswordPayload) {
    return baseApi.POST_Request<boolean>(apiUrls.account.forgotPassword(), payload)
  }
  public setPasswordAfterForgot(data: SetPasswordPayload) {
    return baseApi.POST_Request<boolean>(apiUrls.account.setPasswordAfterForgot(), data)
  }

  //   async resetPasswordAsync(resetPasswordRequest: ResetPasswordRequest): Promise<void | AxiosResponse> {
  //     return this.requestAsync('POST', '/auth/reset-password', resetPasswordRequest)
  //   }
}
