import { CircularProgress, LinearProgress } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useGetTeam, useRolesHelper } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TeamSliceSelector } from 'store/slices'
import { AuthSliceSelector } from 'store/slices/auth.slice'
import { AppBox, AppButton, AppDrawer, AppIcon, AppTypography } from '../../base'
import { Authorize } from '../authorize/Authorize'
import { ProfileDrawer } from '../ProfileDrawer/ProfileDrawer'

export const TopBar = () => {
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { SelectedTeam, isChangingTeam } = useSelector(TeamSliceSelector)
  const { getTeams } = useGetTeam()
  const { IsSubAccount } = useRolesHelper()
  const profileImageSrc = useMemo(() => {
    if (CurrentUser?.ProfileImage) {
      return CurrentUser.ProfileImage
    }
    return '/images/profile-image.png'
  }, [CurrentUser?.ProfileImage])
  useEffect(() => {
    if (!IsSubAccount()) {
      getTeams()
    }
  }, [getTeams])
  return (
    <AppBox flexGrow={1} alignItems={'center'} borderBottom={1} borderColor={'grey.300'} paddingBottom={1}>
      <AppBar position="static" color="inherit" elevation={0}>
        {isChangingTeam && <LinearProgress />}
        <Toolbar>
          <AppBox flex={1}>
            <img src="/images/logo/ParkSitesIO-Logo-Image.png" alt="ParkSites.io" />
          </AppBox>
          <AppBox display={'flex'} alignItems={'center'} gap={2}>
            {/* {SelectedTeam && (
              <>
                <AppIcon icon="bi:people" color={'grey'} size="medium" />
                <AppTypography fontSize={18} fontWeight={600} color={'grey.600'}>
                  {deslugify(SelectedTeam.Name + '-Dashboard')}
                </AppTypography>
              </>
            )} */}

            <AppDrawer
              anchor="right"
              renderChild={(closeDrawer) => <ProfileDrawer closeDrawer={closeDrawer} />}
              renderElement={(openDrawer) => (
                <AppButton color="secondary" onClick={() => openDrawer()} className="park-sites-dashboard-tour-step1">
                  <img src={profileImageSrc} alt="profileimage" height={45} width={45} style={{ borderRadius: 100 }} />
                  <AppBox flexDirection={'column'} marginX={3}>
                    <AppTypography color="grey.900" fontWeight={'bold'}>
                      {CurrentUser?.FirstName} {CurrentUser?.LastName}
                    </AppTypography>
                    <Authorize hiddenFromSubAccounts={true} allowedToPaidUsers={true}>
                      {SelectedTeam && (
                        <AppBox display={'flex'} alignItems={'center'} gap={1}>
                          <AppIcon icon={'bi:people-fill'} color="#2563EB" size="small" />
                          <AppTypography color="grey.500" fontWeight={'bold'} fontSize={14}>
                            {isChangingTeam ? <CircularProgress size={20} color="secondary" /> : SelectedTeam?.Name}
                          </AppTypography>
                        </AppBox>
                      )}
                    </Authorize>
                  </AppBox>
                  <AppIcon icon="dashicons:arrow-down-alt2" color="grey.500" />
                </AppButton>
              )}
            ></AppDrawer>
          </AppBox>
        </Toolbar>
      </AppBar>
    </AppBox>
  )
}
