import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components'

export const PepoleInsightLoader = () => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingX={4}>
        <AppBox borderBottom={1} borderColor={'grey.100'} paddingY={1} width={1}>
          <Skeleton variant="rounded" width={'20%'} height={40} />
        </AppBox>
        <Skeleton variant="rounded" width={'100%'} height={80} sx={{ color: 'transparent' }}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sed neque accusamus similique cupiditate tempore
          tempora amet corporis dignissimos enim distinctio quae harum magni rerum illo exercitationem, veniam
          voluptatem, earum pariatur.
        </Skeleton>

        <Grid container spacing={2} paddingTop={1}>
          {Array.from(new Array(4)).map(() => (
            <Grid item xs={4}>
              <AppBox display={'flex'} gap={2} justifyContent={'space-between'} paddingBottom={2}>
                <Skeleton variant="rounded" width={'30%'} height={20} />
                <Skeleton variant="circular" width={'10%'} height={20} />
              </AppBox>
              <Skeleton variant="rounded" width={'40%'} height={20} />
              <Skeleton variant="rounded" width={'20%'} height={20} sx={{ my: 1 }} />
              <Skeleton variant="rounded" width={'60%'} height={20} />
              <AppBox display={'flex'} gap={2} paddingY={2}>
                <Skeleton variant="rounded" width={'30%'} height={20} />
                <Skeleton variant="rounded" width={'30%'} height={20} />
              </AppBox>
              <Skeleton variant="rounded" width={'40%'} height={20} />
              <AppBox display={'flex'} gap={2} paddingY={2} justifyContent={'space-between'}>
                <Skeleton variant="rounded" width={'30%'} height={20} />
                <Skeleton variant="rounded" width={'30%'} height={20} />
              </AppBox>
            </Grid>
          ))}
        </Grid>
      </AppBox>
    </>
  )
}
