import { IconButton } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AppBox,
  AppIcon,
  AppMenu,
  AppTypography,
  BaseComponent,
  CrmContactParksHelperLoader,
  InfoBox,
  PhoneText,
} from 'components'
import { ApiQueryKeys, InfoBoxSettingTypes } from 'globals/constants'
import { getMDYFormattedDate } from 'globals/functions'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParksApi } from 'sdk'
import { TeamSliceSelector } from 'store/slices'

interface Props {
  contactId: string
}

const linkedParksApi = new CrmContactLinkedParksApi()
export const CrmContactParksHelpModule = (props: Props) => {
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: parks,
    isPending: isParksLoading,
    mutate: GetLinkedParks,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.FetchLinkedParks],
    mutationFn: () => linkedParksApi.getContactLinkedParks(props.contactId),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  const { mutate: unlinkParkFromContact, isPending: isUnlinking } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.RemoveLinkedPark],
    mutationFn: (parkId: string) => linkedParksApi.unlinkParkFromContact(props.contactId, parkId),
    onSuccess: () => {
      enqueueSnackbar('Park has been removed', { variant: 'success' })
      GetLinkedParks()
    },
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  useEffect(() => {
    GetLinkedParks()
  }, [SelectedTeam?.Id])

  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2}>
      <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ContactLinkedParksDismissed}>
        When you associate one or more parks to a contact the list of parks will begin to display here for that contact.
      </InfoBox>
      <BaseComponent
        isLoading={isParksLoading || isUnlinking}
        isData={!!parks}
        loaderComponent={
          <>
            <CrmContactParksHelperLoader />
          </>
        }
      >
        <>
          <AppTypography fontSize={14} fontWeight={500}>
            Parks({parks?.length})
          </AppTypography>

          {parks?.map((park) => (
            <AppBox
              backgroundColor="grey.50"
              borderColor={'grey.200'}
              border="1px"
              padding={2}
              borderRadius={3}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <AppBox>
                <AppTypography color={'grey.900'} fontSize={14} fontWeight={600}>
                  {park.Title}
                </AppTypography>
                <AppTypography color={'grey.500'} paddingY={0.4}>
                  {park.Address}, {park.City}, {park.State}, {park.Zip}
                </AppTypography>
                {park.ParkPhone && (
                  <AppTypography fontWeight={500} color={'grey.500'}>
                    Park Phone <PhoneText phone={park.ParkPhone} />
                  </AppTypography>
                )}
                <AppBox display={'flex'} gap={1} color={'grey.500'}>
                  {park.MHLots && (
                    <>
                      <AppTypography>MH Lots:</AppTypography>
                      <AppTypography fontWeight={'bold'} color={'grey.900'}>
                        {park.MHLots}
                      </AppTypography>
                    </>
                  )}
                  {park.RVLots && (
                    <>
                      <AppTypography>RV Lots:</AppTypography>
                      <AppTypography fontWeight={'bold'} color={'grey.900'}>
                        {park.RVLots}
                      </AppTypography>
                    </>
                  )}
                </AppBox>
                <AppTypography color={'grey.500'}>
                  {park.Updated
                    ? 'Updated on ' + getMDYFormattedDate(new Date(park.Updated))
                    : 'Saved on ' + getMDYFormattedDate(new Date(park.Created ?? new Date()))}
                </AppTypography>
              </AppBox>
              <AppBox>
                <AppMenu
                  renderElement={(handleClick) => (
                    <IconButton aria-haspopup="true" onClick={handleClick} className="p-0 m-0">
                      <AppIcon icon="pepicons-pencil:dots-y" size="large" />
                    </IconButton>
                  )}
                  MenuItems={[{ text: 'Remove', onClick: () => unlinkParkFromContact(park.ParkId) }]}
                />
              </AppBox>
            </AppBox>
          ))}
        </>
      </BaseComponent>
    </AppBox>
  )
}
