import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { NewsAlertApi, NewsAlertPayload, PaginationFilterModel } from 'sdk'

export const useNewsAlert = () => {
  const newsAlertApi = new NewsAlertApi()
  const { enqueueSnackbar } = useSnackbar()
  const {
    mutate: GetFilteredAlerts,
    isPending: isGetting,
    data: filterdAlerts,
  } = useMutation({
    mutationFn: (filter: PaginationFilterModel) => newsAlertApi.getNewsAlerts(filter),
    mutationKey: [ApiQueryKeys.newsAlert.getNewsAlerts],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  const { mutateAsync: AddNewsAlert, isPending: isAdding } = useMutation({
    mutationFn: (obj: NewsAlertPayload) => newsAlertApi.addNewsAlerts(obj),
    mutationKey: [ApiQueryKeys.newsAlert.addNewsAlert],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  const { mutate: EditNewsAlert, isPending: isEditing } = useMutation({
    mutationFn: (obj: NewsAlertPayload) => newsAlertApi.updateNewsAlert(obj),
    mutationKey: [ApiQueryKeys.newsAlert.updateNewsAlert],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  const { mutate: DeleteNewsAlert, isPending: isDeleting } = useMutation({
    mutationFn: (id: string) => newsAlertApi.deleteNewsAlert(id),
    mutationKey: [ApiQueryKeys.newsAlert.deleteNewsAlert],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })

  return {
    GetFilteredAlerts,
    filterdAlerts,
    isGetting,
    AddNewsAlert,
    isAdding,
    EditNewsAlert,
    isEditing,
    DeleteNewsAlert,
    isDeleting,
  }
}
