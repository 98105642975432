import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaymentInformationViewModel } from 'sdk'

export interface PaymentMethodState {
  PaymentMethods: Array<PaymentInformationViewModel>
  DefaultPaymentMethod: PaymentInformationViewModel | null
}

export const initialState: PaymentMethodState = {
  PaymentMethods: [],
  DefaultPaymentMethod: null,
}
export const PaymentMethodSlice = createSlice({
  name: 'PaymentMethod',
  initialState,
  reducers: {
    setPaymentMethods: (state, action) => {
      state.PaymentMethods = action.payload
    },
    setDefaultPaymentMethod: (state, action: PayloadAction<PaymentInformationViewModel | null>) => {
      state.DefaultPaymentMethod = action.payload
    },
  },
})
export const { setPaymentMethods, setDefaultPaymentMethod } = PaymentMethodSlice.actions
export const PaymentMethodSliceSelector = (appState: any): PaymentMethodState => appState.PaymentMethod
