import { Skeleton } from '@mui/material'
import { AppBox } from 'components'

export const CityDemoGraphicsLoader = () => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingX={4}>
        <AppBox borderBottom={1} borderColor={'grey.100'} paddingY={1} width={1}>
          <Skeleton variant="rounded" width={'30%'} height={40} />
        </AppBox>
        <Skeleton variant="rounded" width={'100%'} height={80} sx={{ color: 'transparent' }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe at laboriosam maiores, sequi nisi ipsum dicta
          possimus. Neque rerum magnam minima veniam! Accusantium itaque odit laboriosam cumque quod illo deleniti?
        </Skeleton>
        <Skeleton variant="rounded" width={'45%'} height={40} />
      </AppBox>
    </>
  )
}
