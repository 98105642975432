import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps, TableActions } from 'components'
import { getFullDate } from 'plugins/helper/helper'
import { FC } from 'react'
import { StateViewModel } from 'sdk'

interface Props extends AppTableProps<StateViewModel> {
  onEditClicked: (state: StateViewModel) => void
  onUpdateWebSiteClick: (id: string) => void
  onStatusUpdateClick: (id: StateViewModel) => void
}
export const StatesTable: FC<Props> = ({ onEditClicked, onUpdateWebSiteClick, onStatusUpdateClick, ...props }) => {
  const columns: ColumnDef<StateViewModel>[] = [
    {
      id: 'popover',
      header: () => <></>,
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Edit State', onClick: () => onEditClicked(row.original) },
              { text: 'Edit State WebSite ', onClick: () => onUpdateWebSiteClick(row.original.Id!) },
              {
                text: row.original.ActiveStatus === 'TRUE' ? 'Deactivate' : 'Activate',
                onClick: () => {
                  onStatusUpdateClick(row.original)
                },
              },
            ]}
          />
        )
      },
    },
    {
      header: 'Status',
      cell: ({ row }) => {
        return row.original.ActiveStatus === 'TRUE' ? 'Active' : 'Inactive'
      },
    },
    {
      header: 'Name',
      accessorKey: 'Name',
    },
    {
      header: 'State Code',
      accessorKey: 'Code',
    },
    {
      header: 'WebSite URL',
      accessorKey: 'Website',
    },
    {
      header: 'Updated',
      cell: ({ row }) => {
        return getFullDate(row.original.Modified)
      },
    },
    {
      header: 'Updated By',
      accessorKey: 'UpdatedBy',
    },
  ]
  return (
    <>
      <AppTable columns={columns} {...props} />
    </>
  )
}
