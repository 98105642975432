import {
  NewUserGettingStartedPayload,
  PaginatedApiResponseModel,
  PaginationFilterModel,
  ProfileImagePayload,
  UserPersonalInfoViewModel,
  UserSubscriptionInfoViewModel,
  UserWithSubAccounts,
} from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'

export class UserApi extends BaseApi {
  public updateUserOnGettingStarted(data: NewUserGettingStartedPayload) {
    return this.PATCH_Request<boolean>(apiUrls.user.updateUserOnGettingStarted(), data)
  }
  public getUserWithSubAccounts(filter: PaginationFilterModel) {
    return this.GET_Request<PaginatedApiResponseModel<UserWithSubAccounts>>(
      apiUrls.user.getUsersWithSubAccounts(filter)
    )
  }
  public getUserPersonalInfo(userId: string) {
    return this.GET_Request<UserPersonalInfoViewModel>(apiUrls.user.getUserPersonalInfo(userId))
  }
  public getUserSubscriptionInfo(userId: string) {
    return this.GET_Request<UserSubscriptionInfoViewModel>(apiUrls.user.getUserSubscriptionInfo(userId))
  }
  public UpdateUserProfile(userProfile: UserPersonalInfoViewModel) {
    return this.PUT_Request<string>(apiUrls.user.updateUserProfile(), userProfile)
  }
  public updateUserProfileImage(data: ProfileImagePayload) {
    const formData = new FormData()
    formData.append('Id', data.Id)
    formData.append('Image', data.Image)
    return this.PUT_FormDataRequestAsync<string>(apiUrls.user.updateUserProfileImage(), formData)
  }
  public removeUserProfileImage() {
    return this.DELETE_Request<void>(apiUrls.user.removeUserProfileImage())
  }
}
