import { Grid } from '@mui/material'
import { FC, useId } from 'react'
import { ManageAccountAccountOwnerCard } from './cards/ManageAccountAccountOwnerCard'
import { ManageAccountSubscriptionPlanCard } from './cards/ManageAccountSubscriptionPlanCard'
import { ManageAccountSecurityIPInfoCard } from './cards/ManageAccountSecurityIPInfo'
import { ManageAccountAdditionalUsersCard } from './cards/ManageAccountAdditionalUsersCard'

interface Props {
  userId: string
  isSubAccount?: boolean
}

export const ManageAccountDialog: FC<Props> = ({ userId, isSubAccount = false }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ManageAccountAccountOwnerCard userId={userId} />
      </Grid>
      <Grid item xs={6}>
        <ManageAccountSubscriptionPlanCard userId={userId} isSubAccount={isSubAccount} />
      </Grid>
      <Grid item xs={12}>
        <ManageAccountSecurityIPInfoCard />
      </Grid>
      {!isSubAccount && (
        <Grid item xs={12}>
          <ManageAccountAdditionalUsersCard userId={userId} />
        </Grid>
      )}
    </Grid>
  )
}
