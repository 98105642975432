import { Icon } from '@iconify/react'
import { Zoom } from '@mui/material'
import { AppBox, AppTypography } from 'components'
import { FC, useEffect, useState } from 'react'

interface Props {}

export const NoTaggedParks: FC<Props> = () => {
  const [checked, setChecked] = useState(false)
  useEffect(() => setChecked(true), [])
  return (
    <AppBox backgroundColor="grey.100" height={'71vh'} alignContent={'space-evenly'}>
      <AppBox textAlign={'center'} marginTop={-6}>
        <Zoom in={checked} mountOnEnter unmountOnExit>
          <AppBox>
            <Icon icon="bi:tags-fill" height={'30%'} color="#2563EB" />
          </AppBox>
        </Zoom>
        <AppTypography fontWeight={'bold'} fontSize={22}>
          No Tagged Parks Yet?
        </AppTypography>
        <AppTypography fontSize={16} color={'grey.500'} fontWeight={400}>
          Tags help to organize your parks into searchable groups. After applying one or more tags to a park, it will
          start displaying here.
        </AppTypography>
        <AppTypography fontSize={16} color={'grey.500'} fontWeight={400} paddingTop={4}>
          Use the <b>Manage</b> option above to customize the tag values that can be used in a park’s{' '}
          <b>Activity Center</b>.
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
