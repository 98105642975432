import { Icon, IconProps } from '@iconify/react'

export interface AppIconProps extends IconProps {
  onClick?: () => void
  size?: 'extra-small' | 'small' | 'medium' | 'large'
}
export const AppIcon = (props: AppIconProps) => {
  const getIconSize = () => {
    switch (props.size) {
      case 'extra-small':
        return '10'
      case 'small':
        return '15'
      case 'medium':
        return '20'
      case 'large':
        return '25'
      default:
        return '15'
    }
  }
  const cursor = props.onClick ? 'pointer' : 'default'
  return <Icon fontSize={getIconSize()} {...props} cursor={cursor} />
}
