import { Divider, Grid, LinearProgress } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useMap } from '@vis.gl/react-google-maps'
import { AppBox, AppButton, AppTypography, GoogleMap, MapWrapper } from 'components'
import { AutoCompleteInput } from 'components/shared/GoogleMap/AutoCompleteInput/AutoCompleteInput'
import { ApiQueryKeys, AppGoogleMapUniqueKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CrmContactLinkedParkPayload, CrmContactLinkedParksApi, ShowAlert } from 'sdk'
import {
  GoogleMapSliceSelector,
  TeamSliceSelector,
  setCrmContactLinkedParkPins,
  setCrmContactLinkedParksState,
  setSelectedPlace,
} from 'store/slices'

const linkedParksApi = new CrmContactLinkedParksApi()
interface Props {
  contactId: string
  isLinkedOnly?: boolean
}
export const CrmContactLinkedParkSites = ({ contactId, isLinkedOnly }: Props) => {
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { CrmContactLinkedParksState } = useSelector(GoogleMapSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  // Getting linked parks
  const {
    data,
    isPending: isParksLoading,
    mutate: getLinkedParks,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.FetchLinkedParks],
    mutationFn: () => linkedParksApi.getContactLinkedParks(contactId),
  })
  useEffect(() => getLinkedParks(), [SelectedTeam?.Id])
  useEffect(() => {
    if (data) {
      dispatch(setCrmContactLinkedParkPins(data.map((x) => x.Position)))
    }
  }, [data, dispatch])

  // Adding Park to linked parks
  const { mutate: AddPark, isPending: isAdding } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.AddLinkedPark],
    mutationFn: (data: CrmContactLinkedParkPayload) => linkedParksApi.linkParkToContact(data),
    onSuccess: () => handleSuccess(),
  })
  //

  // Removing Park
  const { mutate: RemovePark, isPending: isRemoving } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.RemoveLinkedPark],
    mutationFn: (data: { CrmContactId: string; ParkSiteId: string }) =>
      linkedParksApi.unlinkParkFromContact(data.CrmContactId, data.ParkSiteId),
    onSuccess: () => handleSuccess(),
  })
  const handleRemovePark = (parkId: string) => {
    ShowAlert('question', 'Are you sure you want to remove this park?').then((result) => {
      if (result.isConfirmed) {
        RemovePark({ CrmContactId: contactId!, ParkSiteId: parkId })
      }
    })
  }
  //
  useEffect(() => {
    dispatch(setCrmContactLinkedParksState({ isAdding: false, isShowing: true }))
  }, [dispatch, isLinkedOnly])

  const handleSuccess = () => {
    dispatch(setCrmContactLinkedParksState({ isAdding: false, isShowing: true }))
    enqueueSnackbar('Park added successfully', { variant: 'success' })
    getLinkedParks()
  }
  return (
    <AppBox
      border={1}
      padding={2}
      paddingY={3}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Parks"
    >
      <MapWrapper>
        {!isLinkedOnly && (
          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={2}>
              <AppTypography fontWeight={700} fontSize={'1rem'}>
                Parks
              </AppTypography>
            </Grid>
            <Grid item>
              {CrmContactLinkedParksState?.isAdding ? (
                <AutoCompleteInput onPlaceChange={(value) => dispatch(setSelectedPlace(value))} />
              ) : (
                <AppButton
                  variant="contained"
                  prefixIcon="tabler:plus"
                  onClick={() => dispatch(setCrmContactLinkedParksState({ isAdding: true, isShowing: false }))}
                >
                  Add
                </AppButton>
              )}
            </Grid>
          </Grid>
        )}

        <Divider sx={{ marginY: 2 }} />
        <AppBox width={1} height={'500px'} backgroundColor="black">
          {(isParksLoading || isAdding || isRemoving) && <LinearProgress color="primary" />}
          <GoogleMap
            onMarkerClick={(parkId, marker) => {
              // const content = `<div><button onClick>Add</button></div>`
              // var infowindow = new google.maps.InfoWindow({
              //   content: content,
              // })
              // infowindow.open(map, marker)
              if (CrmContactLinkedParksState.isAdding) {
                AddPark({ CrmContactIds: [contactId!], ParkSiteId: parkId })
              } else {
                handleRemovePark(parkId)
              }
            }}
          />
        </AppBox>
      </MapWrapper>
    </AppBox>
  )
}
