import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { PaginationFilterModel, UserApi } from 'sdk'

export const useManageUsers = () => {
  const userApi = new UserApi()
  const { enqueueSnackbar } = useSnackbar()

  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())

  const {
    mutate: getAllUsers,
    isPending: isGettingAllUsers,
    data: allUsers,
  } = useMutation({
    mutationFn: (filter: PaginationFilterModel) => userApi.getUserWithSubAccounts(filter),
    mutationKey: [ApiQueryKeys.user.getAllUsersWithSubAccounts],
    onError: (error) => enqueueSnackbar(error.message, { variant: 'error' }),
  })
  return {
    getAllUsers,
    isGettingAllUsers,
    allUsers,
    filter,
    setFilter,
  }
}
