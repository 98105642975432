import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'
import { AppBox, AppTypography } from 'components'
import { appRoutes } from 'globals/constants'
import { useShowableParkPins } from 'hooks'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {}

export const GoogleMapsPinTypesLegendControl: FC<Props> = () => {
  const { currentMarkers } = useShowableParkPins()
  const location = useLocation()

  return (
    <>
      {location.pathname === appRoutes.parksites.path && (
        <MapControl position={ControlPosition.TOP_LEFT}>
          <AppBox
            className="control-panel park-sites-dashboard-tour-step8"
            backgroundColor="white"
            display={'flex'}
            flexDirection={'column'}
            padding={2}
            borderRadius={3}
            gap={0.5}
            margin={3}
          >
            <AppBox display={'flex'} paddingRight={4} gap={2} alignItems={'center'}>
              <AppBox backgroundColor="#23d164" borderRadius={50} height={12} width={12} />
              <AppTypography fontSize={14}>
                Active (
                {currentMarkers()
                  .filter((x) => x.S === 'A')
                  .length.toLocaleString() ?? 0}
                )
              </AppTypography>
            </AppBox>
            <AppBox display={'flex'} paddingRight={4} gap={2} alignItems={'center'}>
              <AppBox backgroundColor="red" borderRadius={50} height={12} width={12} />
              <AppTypography fontSize={14}>
                Closed (
                {currentMarkers()
                  .filter((x) => x.S === 'C')
                  .length.toLocaleString() ?? 0}
                )
              </AppTypography>
            </AppBox>
            <AppBox display={'flex'} paddingRight={4} gap={2} alignItems={'center'}>
              <AppBox backgroundColor="black" borderRadius={50} height={12} width={12} />
              <AppTypography fontSize={14}>
                No Data (
                {currentMarkers()
                  .filter((x) => x.S === 'N')
                  .length.toLocaleString() ?? 0}
                )
              </AppTypography>
            </AppBox>
          </AppBox>
        </MapControl>
      )}
    </>
  )
}
