import {
  CrmContactLinkedParkPayload,
  CrmContactLinkedParkViewModel,
  CrmContactViewModel,
  PaginatedApiResponseModel,
  PaginationFilterModel,
  ParkListTab,
} from 'sdk'
import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'

const baseApi = new BaseApi()
export class CrmContactLinkedParksApi {
  public getContactLinkedParks(contactId: string) {
    return baseApi.GET_Request<CrmContactLinkedParkViewModel[]>(
      apiUrls.crm.contactLinkedParks.getContactLinkedParks(contactId)
    )
  }
  public linkParkToContact(payload: CrmContactLinkedParkPayload) {
    return baseApi.POST_Request(apiUrls.crm.contactLinkedParks.LinkParkSiteToContact(), payload)
  }
  public unlinkParkFromContact(contactId: string, parkId: string) {
    return baseApi.DELETE_Request(apiUrls.crm.contactLinkedParks.unlinkContactLinkedPark(contactId, parkId))
  }
  public getParkLinkedContacts(parkId: string, filter: PaginationFilterModel) {
    return baseApi.GET_Request<PaginatedApiResponseModel<CrmContactViewModel>>(
      apiUrls.crm.contactLinkedParks.getParkLinkedContacts(parkId, filter)
    )
  }
  public getParksWithCrm(searchQuery: string) {
    return baseApi.GET_Request<ParkListTab[]>(apiUrls.crm.contactLinkedParks.getParksWithCrm(searchQuery))
  }
  public UnlinkAllContactsFromParkByLinkId(linkId: string) {
    return baseApi.DELETE_Request(apiUrls.crm.contactLinkedParks.UnlinkAllContactsFromParkByLinkId(linkId))
  }
  public UnlinkAllContactsFromAllPark() {
    return baseApi.DELETE_Request(apiUrls.crm.contactLinkedParks.UnlinkAllContactsFromAllPark())
  }
  public GetUnlinkedCrmContactsByParkId(parkId: string) {
    return baseApi.GET_Request<CrmContactViewModel[]>(
      apiUrls.crm.contactLinkedParks.GetUnlinkedCrmContactsByParkId(parkId)
    )
  }
}
