import { Container, Skeleton } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { AppBox, AppTable, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { centsToDollar } from 'globals/functions'
import { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AccountApi, CouponApi, MembershipPlanViewModel } from 'sdk'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'

interface Props {
  selectedMembership: MembershipPlanViewModel | null
}

export const ChangeMembershipPurchase: FC<Props> = ({ selectedMembership }) => {
  const FIVE_PERSON_DISCOUNT_CODE = 'sub-account-5'
  const TEN_PERSON_DISCOUNT_CODE = 'sub-account-10'
  const couponApi = new CouponApi()
  const accountApi = new AccountApi()
  const { DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)
  const {
    mutate: ApplyCoupon,
    data: coupon,
    isPending: isApplyingCoupon,
  } = useMutation({
    mutationKey: [ApiQueryKeys.payment.FetchCouponById],
    mutationFn: (code: string) => couponApi.ValidateCoupon(code),
  })

  const {
    data: subAccounts,
    isPending: isGettingSubAccounts,
    mutate: getSubAccount,
  } = useMutation({
    mutationKey: [ApiQueryKeys.account.getUserSubAccounts],
    mutationFn: () => accountApi.getUserSubAccounts(),
  })
  const columns: ColumnDef<{ Item: string; No: string; Total: string }>[] = [
    {
      header: 'Item',
      accessorKey: 'Item',
    },
    {
      header: 'No.',
      accessorKey: 'No',
    },
    {
      header: 'Total',
      accessorKey: 'Total',
    },
  ]
  const totalSeats = useMemo(() => {
    return (subAccounts?.length ?? 0) + 1
  }, [subAccounts])

  const ammountwithOutDiscount = useMemo(() => {
    return totalSeats * (selectedMembership?.Price ?? 0)
  }, [totalSeats, selectedMembership])

  const discountApplied = useMemo(() => {
    if (coupon && subAccounts && subAccounts?.length > 5) {
      if (coupon.AmountOff) {
        return coupon.AmountOff ?? 0
      } else if (coupon.PercentOff) {
        return (coupon.PercentOff / 100) * ammountwithOutDiscount
      }
    }
    return 0
  }, [coupon, ammountwithOutDiscount, subAccounts])
  const finalAmount = useMemo(() => {
    return ammountwithOutDiscount - discountApplied
  }, [ammountwithOutDiscount, discountApplied])
  const data = useMemo(() => {
    return {
      Item: `${selectedMembership?.Billed} - ${centsToDollar(selectedMembership?.Price ?? 0)} ${selectedMembership?.Title}`,
      No: `${totalSeats} User Seat(s)`,
      Total: centsToDollar(ammountwithOutDiscount),
    }
  }, [totalSeats, selectedMembership, ammountwithOutDiscount])

  useEffect(() => {
    getSubAccount()
  }, [])

  useEffect(() => {
    if (totalSeats) {
      if (totalSeats >= 5 && totalSeats < 10) {
        ApplyCoupon(FIVE_PERSON_DISCOUNT_CODE)
      } else if (totalSeats >= 10) {
        ApplyCoupon(TEN_PERSON_DISCOUNT_CODE)
      }
    }
  }, [totalSeats])

  return (
    <>
      <Container>
        <AppBox display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={4}>
          <AppTypography fontSize={14} fontWeight={500} color={'grey.500'} component={'span'}>
            Please verify your order. Upon clicking the{' '}
            <AppTypography fontWeight={600} component={'span'}>
              Make Payment
            </AppTypography>{' '}
            button we’ll attempt to charge your payment method to complete the transaction.
          </AppTypography>
          <AppBox border={1} borderColor={'grey.300'} padding={4} borderRadius={6}>
            <AppTable data={[data ?? {}]} columns={columns} />
          </AppBox>
          <AppBox border={1} borderColor={'grey.300'} padding={4} borderRadius={6}>
            <AppBox display={'flex'} justifyContent={'space-between'}>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                Payment Method
              </AppTypography>
              <AppBox display={'flex'} alignItems={'center'} gap={1}>
                <AppTypography fontSize={14} color={'primary.200'} fontWeight={600}>
                  {DefaultPaymentMethod?.CardType.toLocaleUpperCase() + ' '}
                  XXXX {DefaultPaymentMethod?.LastDigits}
                </AppTypography>
              </AppBox>
            </AppBox>
            <AppBox paddingTop={1}>
              <AppBox display={'flex'} justifyContent={'space-between'}>
                <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                  Total Amount
                </AppTypography>
                <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                  {isGettingSubAccounts || isApplyingCoupon ? (
                    <Skeleton width={'100px'} variant="text" />
                  ) : (
                    centsToDollar(ammountwithOutDiscount)
                  )}
                </AppTypography>
              </AppBox>
              <AppBox display={'flex'} justifyContent={'space-between'}>
                <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                  Discount Applied
                </AppTypography>
                <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                  {isGettingSubAccounts || isApplyingCoupon ? (
                    <Skeleton width={'100px'} variant="text" />
                  ) : (
                    centsToDollar(discountApplied)
                  )}
                </AppTypography>
              </AppBox>
            </AppBox>
            <AppBox
              display={'flex'}
              justifyContent={'space-between'}
              borderTop={1}
              borderColor={'grey.300'}
              marginTop={2}
            >
              <AppTypography fontSize={14} fontWeight={'bold'}>
                Amount to be paid now
              </AppTypography>
              <AppTypography fontSize={14} fontWeight={'bold'}>
                {isGettingSubAccounts || isApplyingCoupon ? (
                  <Skeleton width={'100px'} variant="text" />
                ) : (
                  centsToDollar(finalAmount)
                )}
              </AppTypography>
            </AppBox>
          </AppBox>
        </AppBox>
      </Container>
    </>
  )
}
