import { Drawer, Typography } from '@mui/material'
import {
  AppBox,
  AppIcon,
  AppTypography,
  CrmContactLinkedCompaniesHelpModule,
  CrmContactParksHelpModule,
  CrmNotesHelpModule,
} from 'components'
import { ParkSiteHelpingModules } from 'globals/constants'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, closeParkSiteHelpModule } from 'store/slices'
import { ParkSiteCrmContactLinkHelpModule } from './help-modules/ParkSiteCrmContactLinkHelpModule'
import { ParkSiteHistoryHelpModule } from './help-modules/ParkSiteHistoryHelpModule'
import { CrmContactLinkedtagsHelpModule } from 'components/shared/help-window-components/contacts/CrmContactLinkedTagsHelpModule'
export const ParkSiteHelpWindow = () => {
  const { openedParkSiteHelpModule } = useSelector(ParkSiteToolSliceSelector)
  const dispatch = useAppDispatch()
  const mapping: { [key: string]: { componet: ReactNode } } = {
    [ParkSiteHelpingModules.ParkHistory]: {
      componet: <ParkSiteHistoryHelpModule parkId={openedParkSiteHelpModule?.options.Id!} />,
    },
    [ParkSiteHelpingModules.ContactLinkedCompanies]: {
      componet: <CrmContactLinkedCompaniesHelpModule contactId={openedParkSiteHelpModule?.options.Id!} />,
    },
    [ParkSiteHelpingModules.ContactTags]: {
      componet: <CrmContactLinkedtagsHelpModule contactId={openedParkSiteHelpModule?.options.Id!} />,
    },
    [ParkSiteHelpingModules.ContactNotes]: {
      componet: <CrmNotesHelpModule type="Contact" contactId={openedParkSiteHelpModule?.options.Id!} />,
    },
    [ParkSiteHelpingModules.ContactParks]: {
      componet: <CrmContactParksHelpModule contactId={openedParkSiteHelpModule?.options.Id!} />,
    },
    [ParkSiteHelpingModules.AddContact]: {
      componet: <ParkSiteCrmContactLinkHelpModule />,
    },
  }
  return (
    <>
      {openedParkSiteHelpModule && (
        <Drawer
          anchor="right"
          PaperProps={{ style: { position: 'absolute', width: '35%' } }}
          ModalProps={{
            container: document.getElementById('park-activity-center-help-window-Container'),
            style: { position: 'absolute' },
          }}
          variant="persistent"
          open={openedParkSiteHelpModule !== null}
        >
          <AppBox
            backgroundColor="white"
            height={'inherit'}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            paddingX={3}
            border={1}
            borderTop={2}
            borderColor={'grey.200'}
          >
            <AppBox
              display={'flex'}
              justifyContent={'space-between'}
              borderBottom={1}
              borderColor={'grey.200'}
              paddingY={1}
              paddingTop={1.5}
            >
              <AppTypography fontSize={16} fontWeight={700}>
                {openedParkSiteHelpModule.options.Title}
              </AppTypography>
              <AppIcon
                size="large"
                icon="solar:square-double-alt-arrow-right-linear"
                color="#F44336"
                onClick={() => dispatch(closeParkSiteHelpModule())}
              />
            </AppBox>
            <AppBox overflow={'auto'} paddingBottom={2}>
              {mapping[openedParkSiteHelpModule.key].componet}
            </AppBox>
          </AppBox>
        </Drawer>
      )}
    </>
  )
}
