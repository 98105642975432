import { Grid } from '@mui/material'
import { AppTextField, PhoneField, StateField, ZipField } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { PeopleInsightPayload } from 'sdk'

interface Props {
  control: Control<PeopleInsightPayload>
}

export const PeopleInsightAdminEditForm: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppTextField
          isSmallForm={true}
          control={control}
          name="CompanyName"
          label="Company"
          placeholder="Enter Company"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <AppTextField
          isSmallForm={true}
          required
          control={control}
          name="FirstName"
          label="First Name"
          placeholder="Enter First Name"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <AppTextField
          required
          isSmallForm={true}
          control={control}
          name="LastName"
          label="Last Name"
          placeholder="Enter Last Name"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <AppTextField
          required
          isSmallForm={true}
          control={control}
          name="Address"
          label="Address"
          placeholder="Enter Address"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <AppTextField
          required
          isSmallForm={true}
          control={control}
          name="City"
          label="City"
          placeholder="Enter City"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <AppTextField
          isSmallForm={true}
          control={control}
          name="Email"
          label="Email"
          placeholder="Enter Email"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <StateField
          required
          isSmallForm={true}
          control={control}
          name={'StateId'}
          label="State"
          placeholder="Select State"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <ZipField
          required
          isSmallForm={true}
          control={control}
          name="Zip"
          label="Zip"
          placeholder="Enter Zip"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneField
          required
          isSmallForm={true}
          control={control}
          name="Phone"
          label="Phone"
          placeholder="Enter Phone"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <PhoneField
          isSmallForm={true}
          control={control}
          name="AltPhone"
          label="Alt Phone"
          placeholder="Enter Alt Phone"
          InputProps={{ style: { backgroundColor: 'white' } }}
        />
      </Grid>
    </Grid>
  )
}
