import { Grid, Rating } from '@mui/material'
import { AppTextArea, AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FeedbackPayload } from 'sdk'
import { AuthSliceSelector } from 'store/slices'

interface Props {
  control: Control<FeedbackPayload>
}

export const FeedBackForm: FC<Props> = ({ control }) => {
  const { CurrentUser } = useSelector(AuthSliceSelector)

  return (
    <Grid container>
      <Grid item xs={8} spacing={2}>
        <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
          Hey there {CurrentUser?.FirstName},
        </AppTypography>
        <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
          Your feedback drives our site's continued development.
        </AppTypography>
        <AppTypography paddingTop={2} color={'grey.500'} fontSize={14} fontWeight={500}>
          Throw us 5 stars if you love what we've built for the mobile home park industry.
        </AppTypography>
      </Grid>
      <Grid item xs={4} textAlign={'right'}>
        <img src="/images/feedback/feedback-pax.png" alt="profileimage" />
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        <Controller
          name="Value"
          control={control}
          rules={{ required: 'Rating is required' }}
          render={({ field, fieldState: { error } }) => (
            <>
              <AppBox padding={1} border={error ? 1 : 0} borderColor={'red'} borderRadius={3}>
                <Rating sx={{ fontSize: 60 }} {...field} value={Number(field.value)} />
              </AppBox>
              {error && <AppTypography color={'red'}>{error.message}</AppTypography>}
            </>
          )}
        />
        <AppTextArea control={control} name="Comment" placeholder="Type comments" required />
      </Grid>
    </Grid>
  )
}
