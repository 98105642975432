import { Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { getFormattedDate } from 'globals/functions'
import { useSnackbar } from 'notistack'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { UserApi } from 'sdk'
import { AuthSliceSelector } from 'store/slices'

interface Props {
  userId: string
  isSubAccount?: boolean
}

export const ManageAccountSubscriptionPlanCard: FC<Props> = ({ userId, isSubAccount }) => {
  const userApi = new UserApi()
  const { enqueueSnackbar } = useSnackbar()
  const { OwnerId } = useSelector(AuthSliceSelector)

  const { mutate, data } = useMutation({
    mutationFn: (userId: string) => userApi.getUserSubscriptionInfo(userId),
    mutationKey: [ApiQueryKeys.user.getUserSubscriptionInfo],
    onError: (error) => enqueueSnackbar(error?.message, { variant: 'error' }),
  })
  useEffect(() => {
    mutate(isSubAccount && OwnerId ? OwnerId : userId)
  }, [])
  return (
    <AppBox backgroundColor="grey.100" border={1} borderColor={'grey.200'} borderRadius={6} padding={2}>
      <AppTypography fontWeight={700} fontSize={16}>
        Subscription Plan
      </AppTypography>
      <AppBox paddingY={2}>
        <AppBox display={'flex'} gap={1}>
          <AppTypography color={'grey.700'}>Plan Owner:</AppTypography>
          <AppTypography fontWeight={700}>{data?.PlanOwner}</AppTypography>
        </AppBox>
        <AppBox display={'flex'} gap={1}>
          <AppTypography color={'grey.700'}>Current Plan:</AppTypography>
          <AppTypography fontWeight={700}>{data?.CurrentPlan}</AppTypography>
        </AppBox>
        <Grid container>
          <Grid item xs={6}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Renewal Date:</AppTypography>
              <AppTypography fontWeight={700}>
                {data?.RenewalDate && getFormattedDate(new Date(data?.RenewalDate))}
              </AppTypography>
            </AppBox>

            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Total Seats</AppTypography>
              <AppTypography fontWeight={700}>{data?.TotalSeats}</AppTypography>
            </AppBox>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Total Discount:</AppTypography>
              <AppTypography fontWeight={700}>{data?.TotalDiscount}</AppTypography>
            </AppBox>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Charge Date:</AppTypography>
              {data?.ChargeDate && getFormattedDate(new Date(data?.ChargeDate))}
            </AppBox>
          </Grid>
          <Grid item xs={6}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Renewal Plan:</AppTypography>
              <AppTypography fontWeight={700}>{data?.RenewalPlan}</AppTypography>
            </AppBox>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Price Per Seat:</AppTypography>
              <AppTypography fontWeight={700}>{data?.PricePerSeat}</AppTypography>
            </AppBox>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Total Charges:</AppTypography>
              <AppTypography fontWeight={700}>{data?.TotalCharges}</AppTypography>
            </AppBox>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.700'}>Payment Status:</AppTypography>
              <AppTypography fontWeight={700}>{data?.PaymentStatus}</AppTypography>
            </AppBox>
          </Grid>
        </Grid>
      </AppBox>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppButton variant="contained" color="error" sidePaddings={5}>
          Force Plan Cancellation
        </AppButton>
        <AppButton variant="contained" color="primary" sidePaddings={5}>
          Invoice
        </AppButton>
      </AppBox>
    </AppBox>
  )
}
