import { createSlice } from '@reduxjs/toolkit'
import { AdminSettingsTools } from 'globals/constants'
import { IAppStore } from 'store/app.store'

export interface AdminSettingsToolsTypes {
  openedAdminSettingTool: AdminSettingsTools
}
const initialState: AdminSettingsToolsTypes = {
  openedAdminSettingTool: AdminSettingsTools.Users,
}

export const AdminSettingsToolsSlice = createSlice({
  name: 'AdminSettingsTools',
  initialState,
  reducers: {
    setAdminSettingTool: (state: AdminSettingsToolsTypes, action) => {
      state.openedAdminSettingTool = action.payload
    },
  },
})
export const { setAdminSettingTool } = AdminSettingsToolsSlice.actions
export const AdminSettingToolsSelector = (appState: IAppStore): AdminSettingsToolsTypes => appState?.AdminSettingsTool
