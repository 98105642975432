import { StateLinksActions } from 'views/account/admin-settings/actionbar/StateLinksActions'
import { StatesListing } from './listing/StatesListing'
import { AppBox } from 'components'
import { useState } from 'react'
import { PaginationFilterModel } from 'sdk'

export const ManageStates = () => {
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  return (
    <AppBox minHeight={'inherit'}>
      <AppBox paddingX={2}>
        <StateLinksActions filter={filter} setFilter={setFilter} />
      </AppBox>
      <AppBox paddingX={2} minHeight={'inherit'}>
        <StatesListing filter={filter} setFilter={setFilter} />
      </AppBox>
    </AppBox>
  )
}
