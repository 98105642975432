import { AnyObject } from 'globals/typings'
import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'
import {
  CrmContactPayload,
  ExportCrmContactPayload,
  FilterConditionSaveModel,
  FilterViewModel,
  PaginatedApiResponseModel,
  PaginationFilterModel,
} from 'sdk/models'

import { CrmContactViewModel } from 'sdk/models/crm/contacts/crm-contact.viewmodel'

const baseApi = new BaseApi()
export class CrmContactsApi {
  public getContacts(filter: PaginationFilterModel) {
    return baseApi.GET_Request<PaginatedApiResponseModel<CrmContactViewModel>>(
      apiUrls.crm.contacts.getCrmContacts(filter)
    )
  }
  public GetContactById(id: string) {
    return baseApi.GET_Request<CrmContactViewModel>(apiUrls.crm.contacts.getCrmContactById(id))
  }
  public addContact(payload: CrmContactPayload) {
    return baseApi.POST_Request(apiUrls.crm.contacts.addCrmContact(), payload)
  }
  public isUserContctExist(email: string, id?: string) {
    return baseApi.GET_Request<boolean>(apiUrls.crm.contacts.isContactExist(email, id))
  }
  public EditContact(payload: CrmContactPayload) {
    return baseApi.PUT_Request(apiUrls.crm.contacts.editCrmContact(), payload)
  }
  public DeleteContactById(id: string) {
    return baseApi.DELETE_Request(apiUrls.crm.contacts.deleteCrmContactById(id))
  }
  public deleteContacts(ids: string[]) {
    return baseApi.DELETE_Request(apiUrls.crm.contacts.deleteCrmContacts(), ids)
  }
  public deleteAllContacts() {
    return baseApi.DELETE_Request(apiUrls.crm.contacts.deleteAllCrmContacts(), {})
  }
  public exportContacts(payload: ExportCrmContactPayload) {
    return baseApi.POST_Request<Array<AnyObject>>(apiUrls.crm.contacts.exportCrmContacts(), payload)
  }
  public applyContactFilter(filters: Array<FilterConditionSaveModel>, filter: PaginationFilterModel) {
    return baseApi.POST_Request<PaginatedApiResponseModel<CrmContactViewModel>>(
      apiUrls.crm.contacts.applyContactFilter(),
      {
        filterConditions: filters,
        paginatedFilter: filter,
      }
    )
  }
  public GetContactFilters() {
    return baseApi.GET_Request<Array<FilterViewModel>>(apiUrls.parksites.filter.getContactFilters())
  }
}
