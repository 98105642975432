import { Grid } from '@mui/material'
import { Map, MapMouseEvent, Marker, useMap } from '@vis.gl/react-google-maps'
import { AppBox, AppIcon, AppTextField, AppTypography, ZipField } from 'components'
import { AutoCompleteInput } from 'components/shared/GoogleMap/AutoCompleteInput/AutoCompleteInput'
import { AppGoogleMapUniqueKeys } from 'globals/constants'
import React, { FC, useEffect } from 'react'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { AddNewParkPayload } from 'sdk'

interface Props {
  control: Control<AddNewParkPayload>
  watch: UseFormWatch<AddNewParkPayload>
  setValue: UseFormSetValue<AddNewParkPayload>
}

export const AddNewParkDialogForm: FC<Props> = ({ control, watch, setValue }) => {
  const map = useMap('add-new-park-map')
  const [selectedPlace, setSelectedPlace] = React.useState<google.maps.places.PlaceResult | undefined>(undefined)
  const [isAddressFieldsVisible, setIsAddressFieldsVisible] = React.useState<boolean>(false)

  useEffect(() => {
    if (!selectedPlace || !map) {
      return
    }

    if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
      return
    }
    if (selectedPlace.geometry.viewport) {
      map.fitBounds(selectedPlace.geometry.viewport)
    } else {
      map.setCenter(selectedPlace.geometry.location!)
    }
  }, [selectedPlace, map])

  const handleMapClick = (event: MapMouseEvent) => {
    setValue('Latitude', event.detail.latLng?.lat!)
    setValue('Longitude', event.detail.latLng?.lng!)
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location: event.detail.latLng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        if (status === 'OK' && results && results[0]) {
          const myadd = results[0].address_components
          myadd.filter((x) => {
            x.types.find((y) => {
              switch (y) {
                case 'street_number':
                  setValue('Address', x.long_name)
                  break
                case 'route':
                  setValue('Address', `${watch().Address} ${x.long_name}`)
                  break
                case 'country':
                  setValue('Country', x.long_name)
                  break
                case 'locality':
                  setValue('City', x.long_name)
                  break
                case 'administrative_area_level_2':
                  setValue('County', x.long_name)
                  break
                case 'administrative_area_level_1':
                  setValue('State', x.long_name)
                  break
                case 'postal_code':
                  setValue('Zip', x.long_name)
                  break
              }
            })
          })
        }
      }
    })
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
          Use the search field to identify the park's basic location. Then zoom in closely to the exact location you
          wish to pin and complete the fields
        </AppTypography>
        <AppTypography component={'span'} color={'red'} fontSize={14} fontWeight={500}>
          Note -{' '}
          <AppTypography component={'span'} fontSize={14} fontWeight={500} color={'grey.500'}>
            Be sure you do not place any pin on a public street.
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteInput onPlaceChange={(value) => setSelectedPlace(value)} />
      </Grid>
      <Grid item xs={12}>
        <AppBox
          display={'flex'}
          justifyContent={'center'}
          height={'30vh'}
          width={'100%'}
          borderRadius={3}
          overflow={'hidden'}
        >
          <Map
            id={AppGoogleMapUniqueKeys.RequestNewParkSiteMap}
            mapId={AppGoogleMapUniqueKeys.RequestNewParkSiteMap}
            defaultCenter={{ lat: 41.4831344, lng: -101.924517 }}
            defaultZoom={4}
            gestureHandling={'greedy'}
            mapTypeId={'hybrid'}
            disableDefaultUI={true}
            mapTypeControl={false}
            isFractionalZoomEnabled={false}
            zoomControl={true}
            minZoom={4}
            onClick={(e) => handleMapClick(e)}
            style={{ width: '100%', height: '100%' }}
          >
            {watch().Latitude && <Marker position={{ lat: watch().Latitude, lng: watch().Longitude }} />}
          </Map>
        </AppBox>
      </Grid>
      {!!watch().Latitude && (
        <>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Latitude" name="Latitude" disabled />
          </Grid>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Longitude" name="Longitude" disabled />
          </Grid>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Park Name" name="ParkName" placeholder="Enter Park Name" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} label="Address" name="Address" placeholder="Address" />
          </Grid>
          <AppBox border={1} borderColor={'grey.300'} margin={2} padding={2} width={'100%'} borderRadius={3}>
            <AppBox display={'flex'} alignItems={'center'} width={'100%'} gap={1}>
              <AppBox display={'flex'} gap={0.5} alignItems={'center'}>
                <AppTypography fontSize={14} color={'grey.500'}>
                  City:
                </AppTypography>
                {isAddressFieldsVisible ? (
                  <AppTextField isSmallForm control={control} name="City" />
                ) : (
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={'bold'}>
                    {watch().City}
                  </AppTypography>
                )}
              </AppBox>
              <AppBox display={'flex'} gap={0.5} alignItems={'center'}>
                <AppTypography fontSize={14} color={'grey.500'}>
                  County:
                </AppTypography>
                {isAddressFieldsVisible ? (
                  <AppTextField isSmallForm control={control} name="County" />
                ) : (
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={'bold'}>
                    {watch().County}
                  </AppTypography>
                )}
              </AppBox>
              <AppBox display={'flex'} gap={0.5} alignItems={'center'}>
                <AppTypography fontSize={14} color={'grey.500'}>
                  State:
                </AppTypography>
                {isAddressFieldsVisible ? (
                  <AppTextField isSmallForm control={control} name="State" />
                ) : (
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={'bold'}>
                    {watch().State}
                  </AppTypography>
                )}
              </AppBox>
              <AppBox display={'flex'} gap={0.5} alignItems={'center'}>
                <AppTypography fontSize={14} color={'grey.500'}>
                  Zip:
                </AppTypography>
                {isAddressFieldsVisible ? (
                  <ZipField isSmallForm control={control} name="Zip" />
                ) : (
                  <AppTypography fontSize={14} color={'grey.500'} fontWeight={'bold'}>
                    {watch().Zip}
                  </AppTypography>
                )}
              </AppBox>
              <AppIcon
                color="blue"
                icon={'icon-park-twotone:edit-two'}
                onClick={() => setIsAddressFieldsVisible(!isAddressFieldsVisible)}
              />
            </AppBox>
          </AppBox>
        </>
      )}
    </Grid>
  )
}
