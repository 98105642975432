import { Autocomplete, Popper } from '@mui/material'
import { BaseInputProps } from 'globals/typings'
import { getValidationRules } from 'plugins/helper/helper'
import { Controller } from 'react-hook-form'
import { SimpleField } from '../simple-fields/simple-field/SimpleField'
import { AppTextFieldProps } from '../text-field/AppTextField'
import { AppIcon } from 'components'

export interface AppAutoCompleteProps extends BaseInputProps, AppTextFieldProps {
  options: { label: string; value: string }[]
  handleChange?: (event: any) => void
  isLoading?: boolean
  loadingText?: string
  multiple?: boolean
  hideNoOptionText?: boolean
  disablePortal?: boolean
}

export const AppAutoComplete = ({
  control,
  name,
  required,
  customRules,
  isLoading,
  loadingText,
  handleChange,
  hideNoOptionText = false,
  multiple = false,
  disablePortal = false,
  ...props
}: AppAutoCompleteProps) => {
  const onValueChange = (value: any, fieldOnChange: (...event: any[]) => void) => {
    const newValue = multiple ? value?.map((item: any) => item?.value) : value?.value
    if (!props.options.find((x) => x.value === newValue)) {
      props.options = [...props.options, { value: newValue, label: newValue }]
    }
    fieldOnChange(newValue ?? '')
    if (handleChange) {
      handleChange(newValue)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: (value) => getValidationRules(value, name, customRules ?? {}),
      }}
      render={({ fieldState: { error }, field }) => (
        <Autocomplete
          popupIcon={<AppIcon size="large" icon="mdi:chevron-down" />}
          size="small"
          disablePortal={disablePortal}
          options={props.options}
          loading={isLoading}
          loadingText={loadingText}
          getOptionLabel={(option) => option.label ?? ''}
          multiple={multiple}
          noOptionsText={hideNoOptionText ? '' : 'No Options'}
          {...field}
          onChange={(event, value) => onValueChange(value, field.onChange)}
          value={
            multiple
              ? props.options.filter((option) => field.value?.includes(option?.value))
              : props.options.find((option) => option?.value === field?.value) ?? null
          }
          renderInput={(params) => (
            <SimpleField
              style={{ marginTop: 0 }}
              {...params}
              {...props}
              ref={params.InputProps.ref}
              error={!!error}
              errorText={error?.message?.toString() ?? ''}
              margin="dense"
              required={required}
            />
          )}
        />
      )}
    />
  )
}
