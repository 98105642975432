import { AppBox, AppTypography } from 'components'
import { FC } from 'react'

interface Props {}

export const UnAuthorized: FC<Props> = () => {
  return (
    <AppBox textAlign={'center'}>
      <AppTypography fontSize={40}> You're not authorized to access this page</AppTypography>
    </AppBox>
  )
}
