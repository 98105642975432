import { ContactsOutlined } from '@mui/icons-material'
import { Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppTypography, EmailField, TextWithToolTip } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Form, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AccountApi, AddRecoveryEmail, SessionModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { AuthSliceSelector, setSession } from 'store/slices'
export const AccountRecoveryModule = () => {
  const accountApi = new AccountApi()

  const { control, watch, reset } = useForm<AddRecoveryEmail>({
    mode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { CurrentUser } = useSelector(AuthSliceSelector)

  const { mutate: addRecoveryEmail, isPending: isAdding } = useMutation({
    mutationKey: [ApiQueryKeys.account.addRecoveryEmail],
    mutationFn: (data: AddRecoveryEmail) => accountApi.addRecoveryEmail(data),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: (data: string) => {
      if (data) {
        const copy: SessionModel = JSON.parse(JSON.stringify(CurrentUser))
        copy.RecoveryEmail = data
        dispatch(setSession(copy))
        reset()
        enqueueSnackbar('Recovery email added successfully', { variant: 'success' })
      }
    },
  })
  useEffect(() => {
    reset({ Email: CurrentUser?.RecoveryEmail ?? '', ConfirmEmail: CurrentUser?.RecoveryEmail ?? '' })
  }, [CurrentUser])
  return (
    <>
      <AppBox border={1} padding={2} backgroundColor="white" borderColor={'grey.200'} borderRadius={6}>
        <TextWithToolTip tooltipText="Provide us an alternative email address that can also be used to log into your account.">
          <AppTypography fontWeight={700} fontSize={16}>
            Account Recovery
          </AppTypography>
        </TextWithToolTip>
        <Divider sx={{ marginBlock: 1 }} />
        <Form control={control} onSubmit={({ data }) => addRecoveryEmail(data)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
                In the event your primary email address is no longer accessible, please provide an alternate email
                address that can be used to sign in with.
              </AppTypography>
            </Grid>
            <Grid item xs={12}>
              <EmailField
                label={'Alt email'}
                name={'Email'}
                control={control}
                placeholder={'Enter Alt Email'}
                required
                fullWidth
                disabled={!!CurrentUser?.RecoveryEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <EmailField
                label={'Confirm Email'}
                name={'ConfirmEmail'}
                control={control}
                placeholder={'Confirm Alt Password'}
                required
                fullWidth
                isConfirmEmail={true}
                emailValue={watch().Email}
                disabled={!!CurrentUser?.RecoveryEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <AppBox paddingTop={2} display={'flex'} justifyContent={'end'}>
                <AppButton variant="contained" type="submit" isLoading={isAdding}>
                  Update
                </AppButton>
              </AppBox>
            </Grid>
          </Grid>
        </Form>
      </AppBox>
    </>
  )
}
