import { Grid } from '@mui/material'

import { AppBox, AppButton, AppImage, AppTypography } from 'components'
import { appRoutes } from 'globals/constants'
import { useNavigate } from 'react-router-dom'
import { LoginForm } from './login-form/LoginForm'

export const Login = () => {
  const navigate = useNavigate()
  const handleSignUpClick = () => {
    navigate(appRoutes.signup.path)
  }
  return (
    <Grid container height={'100vh'} sx={{ backgroundColor: 'grey.50' }}>
      <Grid item xs={12} md={7} paddingLeft={4} paddingY={2}>
        <AppImage src="/images/logo/logo-black.png" alt="nature" height={40} />
        <Grid container justifyContent={'center'} alignItems={'center'} paddingTop={5}>
          <Grid item xs={8}>
            <AppBox display={'flex'} gap={1} flexDirection={'column'}>
              <AppTypography fontSize={40} fontWeight={700} color={'grey.900'}>
                Sign in to your ParkSitesIO account
              </AppTypography>
              <AppTypography fontSize={16}>
                Don’t have an account yet?
                <AppTypography
                  component={'a'}
                  isColorPrimary
                  fontSize={16}
                  onClick={() => handleSignUpClick()}
                  paddingX={0.5}
                >
                  Sign up
                </AppTypography>
                for our 7-day free trial.{' '}
              </AppTypography>
              <AppBox>
                <LoginForm />
              </AppBox>
            </AppBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5} maxHeight={'100vh'} overflow={'hidden'}>
        <AppBox
          height={'100%'}
          width={'100%'}
          style={{
            backgroundImage: `url('/images/loginPage/parksitesio-mobile-home-park-login-img-min.jpeg')`,
            backgroundSize: 'cover', // Cover the entire area of the box
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></AppBox>
      </Grid>
    </Grid>
  )
}
