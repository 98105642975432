import { Divider, Grid, LinearProgress, Popover } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import {
  AppBox,
  AppButton,
  AppIcon,
  AppTypography,
  BaseComponent,
  ParkActivityCenterLoader,
  PhoneText,
  SimpleRadioGroup,
  TextWithToolTip,
} from 'components'
import { AppGoogleMapUniqueKeys, AppTourKeys, ParkSitesTools } from 'globals/constants'
import { ApiQueryKeys } from 'globals/constants/query-api-keys'
import { useAddLabelledPark, useGetLabelledParks, useShowableParkPins } from 'hooks'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ApiErrorModel,
  ParkLabelApi,
  ParkSitesApi,
  SdkConfig,
  UserLabelledParkPayloadModel,
  UserParkLabelViewModel,
} from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  AuthSliceSelector,
  ParkSiteToolSliceSelector,
  TeamSliceSelector,
  closeActivity,
  closeParkSiteHelpModule,
  setOpenedActivity,
  setOpenedActivityWidth,
  setTool,
  setToolOpenedTab,
  unSelectedParkId,
} from 'store/slices'
import { setCurrentTourKey } from 'store/slices/common.slice'
import { ParkLabelsliceSelector } from 'store/slices/park-labels.slice'
import { ExternalLinksMapping, ParkActivitiesMapping } from './ParkActivitiesMapping'
import { useMap } from '@vis.gl/react-google-maps'

const parkSitesApiService = new ParkSitesApi()
const parkLabelApi = new ParkLabelApi()
export const ParkActivityCenter = () => {
  // Dispatch
  const dispatch = useAppDispatch()
  // Selectors
  const { selectedParkId, parkGetActivityCenterInfoCount } = useSelector(ParkSiteToolSliceSelector)
  const { ParkLabels } = useSelector(ParkLabelsliceSelector)
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { addedLabelledPark, isAddingLabelledPark, AddLabelledPark } = useAddLabelledPark()
  const { getPinnedParks } = useGetLabelledParks()
  const { currentMarkers } = useShowableParkPins()
  const { enqueueSnackbar } = useSnackbar()
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)

  // Queries
  const {
    data: ActivityInfo,
    isPending,
    mutate: getActivityInfo,
  } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchParkActivityInfo],
    mutationFn: () => parkSitesApiService.getParkActivityCenterInfo(selectedParkId),
    onError: (error: ApiErrorModel) => enqueueSnackbar(error.message, { variant: 'error' }),
    onSuccess(data) {
      if (data) {
        dispatch(setCurrentTourKey(AppTourKeys.ParkSitesActivityCenterTour))
      }
    },
  })
  useEffect(() => getActivityInfo(), [selectedParkId, SelectedTeam?.Id, parkGetActivityCenterInfoCount])

  const {
    mutate: RemoveLabelledParkByParkId,
    data: isRemoved,
    isPending: isRemoving,
  } = useMutation({
    mutationFn: (data: string) => parkLabelApi.RemoveUserLabelledParkByParkId(data),
    mutationKey: [ApiQueryKeys.parksites.labelledParks.RemoveLabelledParkFromListByParkId],
    onSuccess: () => getPinnedParks(),
  })

  // Handlers
  const handleLabelRadioClick = (x: string) => {
    setAnchorEl(null)
    if (x === 'default') {
      RemoveLabelledParkByParkId(selectedParkId)
    } else {
      AddLabelledPark(
        new UserLabelledParkPayloadModel({
          ParkId: selectedParkId,
          LabelId: x,
          UserId: CurrentUser?.UserId,
        })
      )
    }
  }
  const OpenLabelsToolbar = () => {
    dispatch(setTool(ParkSitesTools.PinnedParks))
    dispatch(setToolOpenedTab('manage'))
  }
  const mapCenterOnSelectedPark = () => {
    if (map) {
      map.setCenter({ lat: ActivityInfo?.Lat ?? 0, lng: ActivityInfo?.Long ?? 0 })
      map.setZoom(18)
    }
  }

  useEffect(() => {
    if (addedLabelledPark) {
      setSelectedParkLabel(addedLabelledPark)
    }
  }, [addedLabelledPark])

  useEffect(() => setSelectedParkLabel(null), [isRemoved])
  // States
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedParkLabel, setSelectedParkLabel] = useState<UserParkLabelViewModel | null>(null)
  const isPopOverOpen = Boolean(anchorEl)
  const id = isPopOverOpen ? 'simple-popover' : undefined

  useEffect(() => {
    if (ActivityInfo) {
      setSelectedParkLabel(ParkLabels.find((x) => x.Id === ActivityInfo.ParkLabelId) ?? null)
    }
  }, [ActivityInfo])

  const parkStatusLabel = useMemo(() => {
    if (selectedParkLabel?.Label) {
      return selectedParkLabel.Label
    }
    const pin = currentMarkers().find((x) => x.I === selectedParkId)
    if (pin?.S === 'A') {
      return 'Active'
    } else if (pin?.S === 'C') {
      return 'Closed'
    } else {
      return 'No Data'
    }
  }, [selectedParkId, selectedParkLabel])

  const parkStatusColor = useMemo(() => {
    if (selectedParkLabel?.Color) {
      return selectedParkLabel.Color
    }
    const pin = currentMarkers().find((x) => x.I === selectedParkId)

    if (pin?.S === 'A') {
      return '#23d164'
    } else if (pin?.S === 'C') {
      return 'red'
    } else {
      return 'black'
    }
  }, [selectedParkId, selectedParkLabel])

  return (
    <>
      <BaseComponent
        isLoading={isPending}
        loaderComponent={<ParkActivityCenterLoader />}
        isData={!!ActivityInfo}
        noDataComponent={<>No Data</>}
      >
        <AppBox padding={2} display={'flex'} flexDirection={'column'} gap={2}>
          <AppBox display={'flex'} alignItems={'center'} gap={1}>
            <AppIcon
              size="medium"
              icon="heroicons-outline:arrow-narrow-left"
              color="grey.500"
              onClick={() => {
                dispatch(unSelectedParkId())
                dispatch(closeActivity())
                dispatch(closeParkSiteHelpModule())
              }}
            />
            <TextWithToolTip
              tooltipText={
                <AppTypography>
                  Use the options within each park’s <b>Activity Center</b> to discover and manage everything related to
                  this specific park.{' '}
                </AppTypography>
              }
            >
              <AppTypography fontWeight={'bold'} fontSize={16}>
                Activity Center
              </AppTypography>
            </TextWithToolTip>
          </AppBox>
          <AppBox borderTop={1} borderBottom={1} borderColor={'grey.200'}>
            <AppBox width="100%">{(isAddingLabelledPark || isRemoving) && <LinearProgress color="primary" />}</AppBox>
            <AppBox boxShadow="1px 1px 1px 1px secondary" paddingY={1}>
              <AppBox className="park-sites-activity-tour-step1" display={'flex'} justifyContent={'space-between'}>
                <AppBox display={'flex'} gap={1} alignItems={'center'}>
                  <AppButton
                    color="secondary"
                    suffixIcon="mdi:chevron-down"
                    variant="outlined"
                    aria-describedby={id}
                    onClick={(event) => setAnchorEl(event?.currentTarget)}
                  >
                    <AppIcon size="large" icon={'ph:map-pin-fill'} color={parkStatusColor} />
                  </AppButton>
                  <Popover
                    id={id}
                    open={isPopOverOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <AppBox padding={2} display={'flex'} flexDirection={'column'} gap={0}>
                      {ParkLabels && ParkLabels.length > 0 && (
                        <>
                          <AppTypography>Select Color to Apply</AppTypography>
                          <SimpleRadioGroup
                            onChange={handleLabelRadioClick}
                            options={[
                              ...ParkLabels.map((x) => {
                                return {
                                  Id: x.Id,
                                  Component: (
                                    <AppBox display={'flex'} alignItems={'center'} gap={1}>
                                      <AppBox
                                        backgroundColor={x.Color ?? 'secondary.500'}
                                        height={15}
                                        width={15}
                                        borderRadius={50}
                                      />
                                      <AppTypography>{x.Label}</AppTypography>
                                    </AppBox>
                                  ),
                                }
                              }),
                              ...(!!selectedParkLabel && ParkLabels?.length > 0
                                ? ([
                                    {
                                      Id: 'default',
                                      Component: (
                                        <AppBox display={'flex'} alignItems={'center'} gap={1}>
                                          <AppTypography>Use Default Color</AppTypography>
                                        </AppBox>
                                      ),
                                    },
                                  ] as any)
                                : []),
                            ]}
                          />
                        </>
                      )}
                      {(!ParkLabels || ParkLabels.length === 0) && (
                        <AppButton onClick={OpenLabelsToolbar}>Manage Pin Colors</AppButton>
                      )}
                    </AppBox>
                  </Popover>

                  <AppBox alignContent={'center'}>
                    <AppBox backgroundColor={parkStatusColor} borderRadius={20}>
                      <AppTypography color="white" paddingX={3} paddingY={0.4}>
                        {parkStatusLabel}
                      </AppTypography>
                    </AppBox>
                  </AppBox>
                  <TextWithToolTip
                    tooltipText={
                      <AppTypography>
                        Customize this park’s map pin color for quick reference. Use the <b>Pinned Parks</b> option
                        within your dashboard to manage the custom colors.
                      </AppTypography>
                    }
                  >
                    <></>
                  </TextWithToolTip>
                </AppBox>
                <AppIcon
                  icon="material-symbols:my-location-outline"
                  size="medium"
                  color="grey"
                  cursor={'pointer'}
                  onClick={mapCenterOnSelectedPark}
                  style={{
                    padding: 8,
                    backgroundColor: 'white',
                    borderRadius: 12,
                    cursor: 'pointer',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = 'blue')}
                  onMouseLeave={(e) => (e.currentTarget.style.color = 'grey')}
                />
              </AppBox>
            </AppBox>
            <AppBox
              display={'flex'}
              justifyContent={'space-between'}
              paddingBottom={1}
              className="park-sites-activity-tour-step2"
            >
              <AppBox color={'grey.500'}>
                <AppTypography fontWeight={'bold'} color={'black'} fontSize={14}>
                  {ActivityInfo?.ParkName ?? 'Unknown'}
                </AppTypography>
                <AppTypography>{ActivityInfo?.Address}</AppTypography>
                <AppTypography>
                  {ActivityInfo?.City}, {ActivityInfo?.State}, {ActivityInfo?.Zip}
                </AppTypography>
                {ActivityInfo?.Phone && (
                  <AppTypography>
                    Park Phone: <PhoneText phone={ActivityInfo.Phone} />
                  </AppTypography>
                )}
                <AppBox display={'flex'} gap={2}>
                  <AppTypography component={'span'}>
                    MH Lots:
                    <AppTypography component={'span'} fontWeight={'bold'}>
                      {ActivityInfo?.MHLots}
                    </AppTypography>
                  </AppTypography>
                  <AppTypography component={'span'}>
                    RV Lots:
                    <AppTypography component={'span'} fontWeight={'bold'}>
                      {ActivityInfo?.RVLots}
                    </AppTypography>
                  </AppTypography>
                </AppBox>
              </AppBox>
              <AppBox
                borderRadius={2}
                overflow={'hidden'}
                height={'80px'}
                width={'130px'}
                style={{
                  backgroundImage: `url(${ActivityInfo?.Image ? SdkConfig.ApiBaseUrl + '/' + ActivityInfo.Image : '/images/no-park-image.png'})`,
                  backgroundSize: 'cover', // Cover the entire area of the box
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></AppBox>
            </AppBox>
          </AppBox>
          <Grid container spacing={2} className="park-sites-activity-tour-step3">
            {Object.entries(ParkActivitiesMapping)
              .slice(0, 4)
              .map((x, index) => (
                <Grid item xs={6} key={index}>
                  <AppBox
                    backgroundColor="white"
                    borderRadius={4}
                    border={1}
                    borderColor={'grey.200'}
                    cursor="pointer"
                    onClick={() => {
                      dispatch(setOpenedActivity(x[0]))
                      dispatch(setOpenedActivityWidth(x[1].width ?? '100%'))
                    }}
                  >
                    <AppBox
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      cursor="pointer"
                      paddingY={1}
                      paddingX={2}
                    >
                      <AppTypography fontWeight={'600'} color={'grey.900'}>
                        {x[1].name}
                      </AppTypography>
                      <AppIcon color="grey" icon={x[1].icon} size="medium" />
                    </AppBox>
                  </AppBox>
                </Grid>
              ))}
          </Grid>

          <Grid container spacing={2} className="park-sites-activity-tour-step4">
            {Object.entries(ParkActivitiesMapping)
              .slice(4, 7)
              .map((x, index) => (
                <Grid item xs={6} key={index}>
                  <AppBox
                    backgroundColor="white"
                    borderRadius={4}
                    border={1}
                    borderColor={'grey.200'}
                    cursor="pointer"
                    onClick={() => {
                      dispatch(setOpenedActivity(x[0]))
                      dispatch(setOpenedActivityWidth(x[1].width ?? '100%'))
                    }}
                  >
                    <AppBox
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      cursor="pointer"
                      paddingY={1}
                      paddingX={2}
                    >
                      <AppTypography fontWeight={'600'} color={'grey.900'}>
                        {x[1].name}
                      </AppTypography>
                      <AppIcon color="grey" icon={x[1].icon} size="medium" />
                    </AppBox>
                  </AppBox>
                </Grid>
              ))}
          </Grid>
          <AppBox paddingY={2}>
            <Divider textAlign="left">
              <AppTypography color={'grey.900'}>External Links</AppTypography>
            </Divider>
          </AppBox>
          <Grid container spacing={2} className="park-sites-activity-tour-step5">
            {ExternalLinksMapping.map((x, index) => (
              <>
                {x.isVisible(ActivityInfo!) && (
                  <Grid item xs={6} key={index}>
                    <AppBox
                      backgroundColor="white"
                      borderRadius={4}
                      border={1}
                      borderColor={'grey.200'}
                      cursor="pointer"
                      onClick={() => x.onClick(ActivityInfo!)}
                    >
                      <AppBox
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        cursor="pointer"
                        paddingY={1}
                        paddingX={2}
                      >
                        <AppTypography fontWeight={'600'} color={'grey.900'}>
                          {x.name}
                        </AppTypography>
                        <AppIcon color="grey" icon={x.icon} size="medium" />
                      </AppBox>
                    </AppBox>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </AppBox>
      </BaseComponent>
    </>
  )
}
