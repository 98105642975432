import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppDialog, AppDialogRefType, InfoBox } from 'components'
import { ApiQueryKeys, InfoBoxSettingTypes, TagTypes } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { ShowAlert, TagApi, TagPayloadModel, TagViewModel } from 'sdk'
import { TeamSliceSelector } from 'store/slices'
import { AddEditParkTagForm } from 'views/account/parksites/side-tool/tagged-parks/manage/AddEditParkTagForm'
import { CrmManageTagsListing } from './listing/CrmManageTagsListing'

const tagApi = new TagApi()
export const CrmManageTagsHelpModule = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { control, handleSubmit, reset, setValue } = useForm<TagPayloadModel>({
    mode: 'onSubmit',
    defaultValues: { TagType: TagTypes.GROUP_TAG },
  })

  //    Getting All Tags

  const {
    data,
    isPending,
    mutate: GetAllTags,
  } = useMutation({
    mutationKey: [ApiQueryKeys.tags.FetchTag],
    mutationFn: () => tagApi.GetTags(TagTypes.GROUP_TAG),
  })
  useEffect(() => GetAllTags(), [SelectedTeam?.Id])
  //

  // Adding New Tag

  const { mutate: addTag, isPending: isAddingTag } = useMutation({
    mutationFn: tagApi.AddTag,
    mutationKey: [ApiQueryKeys.tags.AddTag],
    onSuccess: () => handleFormSubmitSuccess(),
  })

  //

  // Editing Park Tags

  const { mutate: EditTag, isPending: isEditingTag } = useMutation({
    mutationFn: tagApi.EditTag,
    mutationKey: [ApiQueryKeys.tags.EditTag],
    onSuccess: () => handleFormSubmitSuccess(),
  })

  const handleEditTag = (data: TagViewModel) => {
    setValue('Id', data.Id)
    setValue('Tag', data.Tag)
    dialogRef.current?.openDialog!()
  }

  //

  // Deleting Park Tags
  const { mutate: DeleteTag } = useMutation({
    mutationFn: tagApi.RemoveTag,
    mutationKey: [ApiQueryKeys.tags.RemoveTag],
    onSuccess: () => handleDeleteTagSuccess(),
  })

  const handleDeleteTag = (id: string) => {
    ShowAlert('question', 'Are you sure you want to delete this Tag?').then((result) => {
      if (result.isConfirmed) {
        DeleteTag(id)
      }
    })
  }
  const handleDeleteTagSuccess = () => {
    enqueueSnackbar('Tag has been removed', { variant: 'success' })
    GetAllTags()
  }
  //

  const dialogRef = useRef<AppDialogRefType>({})
  const handleFormSubmit = (data: TagPayloadModel) => {
    if (data.Id) {
      EditTag(data)
    } else {
      addTag(data)
    }
    reset()
  }
  const handleFormSubmitSuccess = () => {
    dialogRef.current?.closeDialog!()
    enqueueSnackbar('Tag has been added successfully', { variant: 'success' })
    reset({ TagType: TagTypes.GROUP_TAG })
    GetAllTags()
  }
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={3}>
        <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.CrmManageTagsDismissed}>
          Add or Edit the the group tags that help keep your contacts and companies organized
        </InfoBox>
        <AppBox textAlign={'right'}>
          <AppDialog
            ref={dialogRef}
            title="Add Tag"
            renderElement={(openDialog) => (
              <AppButton
                prefixIcon="ic:baseline-plus"
                variant="contained"
                isLoading={isAddingTag || isEditingTag}
                onClick={() => {
                  reset()
                  openDialog()
                }}
              >
                Add New
              </AppButton>
            )}
            renderChild={(openDialog) => <AddEditParkTagForm control={control} />}
            appDialogWrapperProps={{
              component: 'form',
              role: 'form',
              onSubmit: handleSubmit(handleFormSubmit),
            }}
            submitButtonProps={{
              isLoading: isAddingTag,
            }}
          />
        </AppBox>
        <CrmManageTagsListing
          data={data ?? []}
          isLoading={isPending}
          onEditClick={handleEditTag}
          onDeleteClick={handleDeleteTag}
        />
      </AppBox>
    </>
  )
}
