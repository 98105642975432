export const appRoutes = {
  auth: { name: 'auth', path: '/auth' },
  login: { name: 'login', path: '/login' },
  signup: { name: 'signup', path: '/signup' },
  changePassword: { name: 'changePassword', path: '/change-password' },
  account: { name: 'account', path: '/account' },
  profile: { name: 'profile', path: '/account/profile' },
  unauthorized: { name: 'unauthorized', path: '/account/unauthorized' },
  dashboard: { name: 'dashboard', path: '/account/dashboard' },
  parksites: { name: 'parksites', path: '/account/parksites' },
  crm: { name: 'crm', path: '/account/crm' },
  crmContactDetail: { name: 'crmContactDetail', path: '/account/crm/contacts/:contactId' },
  crmCompanyDetail: { name: 'crmCompanyDetail', path: '/account/crm/companies/:companyId' },
  adminSetting: { name: 'adminSetting', path: '/account/adminsettings' },
}
