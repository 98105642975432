import { Grid, Typography } from '@mui/material'
import { Map, MapMouseEvent, Marker, useMap } from '@vis.gl/react-google-maps'
import { AppBox, AppTextField, AppTypography } from 'components'
import { AutoCompleteInput } from 'components/shared/GoogleMap/AutoCompleteInput/AutoCompleteInput'
import { AppGoogleMapUniqueKeys } from 'globals/constants'
import React, { FC, useEffect } from 'react'
import { Control, UseFormSetValue, UseFormWatch, useForm } from 'react-hook-form'
import { RequestNewParkPayloadModel } from 'sdk'

interface Props {
  control: Control<RequestNewParkPayloadModel>
  watch: UseFormWatch<RequestNewParkPayloadModel>
  setValue: UseFormSetValue<RequestNewParkPayloadModel>
}

export const RequestNewParkDialogForm: FC<Props> = ({ control, watch, setValue }) => {
  const map = useMap(AppGoogleMapUniqueKeys.RequestNewParkSiteMap)
  const [selectedPlace, setSelectedPlace] = React.useState<google.maps.places.PlaceResult | undefined>(undefined)

  useEffect(() => {
    if (!selectedPlace || !map) {
      return
    }

    if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
      return
    }
    if (selectedPlace.geometry.viewport) {
      map.fitBounds(selectedPlace.geometry.viewport)
    } else {
      map.setCenter(selectedPlace.geometry.location!)
    }
  }, [selectedPlace, map])

  const handleMapClick = (event: MapMouseEvent) => {
    setValue('Latitude', event.detail.latLng?.lat!)
    setValue('Longitude', event.detail.latLng?.lng!)
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location: event.detail.latLng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        setValue('GoogleAddress', results[0].formatted_address)
      }
    })
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
          We’re always looking for new parks to add. However, not every park has a physical address. Please drop a pin
          in the center of the park by clicking the map and complete the rest of the information. Our staff will only
          add mobile home and RV parks.
        </AppTypography>
      </Grid>
      <Grid item xs={6}>
        <AutoCompleteInput onPlaceChange={(value) => setSelectedPlace(value)} />
      </Grid>
      <Grid item xs={12}>
        <AppBox
          display={'flex'}
          justifyContent={'center'}
          height={'30vh'}
          width={'100%'}
          borderRadius={3}
          overflow={'hidden'}
        >
          <Map
            id={AppGoogleMapUniqueKeys.RequestNewParkSiteMap}
            mapId={AppGoogleMapUniqueKeys.RequestNewParkSiteMap}
            defaultCenter={{ lat: 41.4831344, lng: -101.924517 }}
            defaultZoom={4}
            gestureHandling={'greedy'}
            mapTypeId={'hybrid'}
            disableDefaultUI={true}
            mapTypeControl={false}
            isFractionalZoomEnabled={false}
            zoomControl={true}
            minZoom={4}
            onClick={(e) => handleMapClick(e)}
            style={{ width: '100%', height: '100%' }}
          >
            {watch().Latitude && <Marker position={{ lat: watch().Latitude, lng: watch().Longitude }} />}
          </Map>
        </AppBox>
      </Grid>
      {watch().Latitude && (
        <>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Latitude" name="Latitude" disabled />
          </Grid>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Longitude" name="Longitude" disabled />
          </Grid>
          <Grid item xs={6}>
            <AppTextField required control={control} label="Park Name" name="ParkName" placeholder="Enter Park Name" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField control={control} label="Address" name="Address" placeholder="City, State & Zip" />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              type="number"
              control={control}
              label="Total MH Lots"
              name="TotalMHLots"
              placeholder="Enter Total MHLots"
            />
          </Grid>
          <Grid item xs={6}>
            <AppTextField
              type="number"
              control={control}
              label="Total RV Lots"
              name="TotalRVLots"
              placeholder="Enter Total RVLots"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
