import createPalette from '@mui/material/styles/createPalette'

export const palette = createPalette({
  primary: {
    light: '#2563EB',
    main: '#2563EB',
    dark: '#2563EB',
    50: '#BFDBFE',
    100: '#DBEAFE',
    200: '#0067D0',
    600: '#2563EB',
    800: '#2563EB',
  },
  secondary: {
    main: '#808080',
    light: '#808080',
    dark: '#808080',
    500: '#23d164',
    600: '#D9D9D9',
  },
  grey: {
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#64748B',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#0F172A',
  },
  // pink: {
  //   500: '#ED4F9D',
  // },
})
