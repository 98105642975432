import { Dialog, DialogActions, DialogProps, TypographyProps } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import { AppBox, AppBoxProps, AppButton, AppButtonProps, AppIcon, AppIconProps, AppTypography } from '..'

interface AppDialogProps extends Omit<DialogProps, 'open' | 'ref' | 'title'> {
  title?: string | React.ReactNode
  titleProps?: TypographyProps
  onClose?: () => any
  children?: React.ReactNode
  hideCancelButton?: boolean
  cancelButtonProps?: AppButtonProps
  hideTopCloseBtn?: boolean
  hideTitleBottomBorder?: boolean
  hideFooterCloseBtn?: boolean
  stickyCloseBtn?: boolean
  appDialogWrapperProps?: AppBoxProps
  submitButtonProps?: AppButtonProps
  closeIconProps?: Omit<AppIconProps, 'icon'>
  hideSubmitButton?: boolean
  open?: boolean
  renderElement?: (openDialog: () => void) => React.ReactNode
  renderChild?: (closeDialog: () => void, isOpen?: boolean) => React.ReactNode
}
export interface AppDialogRefType {
  openDialog?: () => void
  closeDialog?: () => void
}
export const AppDialog = forwardRef(
  (
    {
      onClose,
      title,
      hideCancelButton = false,
      hideTopCloseBtn = false,
      hideTitleBottomBorder = false,
      hideFooterCloseBtn = false,
      stickyCloseBtn = false,
      submitButtonProps,
      appDialogWrapperProps,
      cancelButtonProps,
      closeIconProps,
      children,
      renderElement,
      renderChild,
      titleProps,
      hideSubmitButton = false,
      ...props
    }: AppDialogProps,
    ref
  ) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const openDialog = () => {
      setIsDialogOpen(true)
    }
    const closeDialog = () => {
      setIsDialogOpen(false)
    }

    const onDialogClose = () => {
      if (onClose) {
        onClose()
      }
      setIsDialogOpen(false)
    }
    if (ref) {
      ;(ref as any).current = { openDialog, closeDialog }
    }
    return (
      <>
        {renderElement && renderElement(openDialog)}
        <Dialog
          maxWidth="xs"
          open={isDialogOpen}
          onClose={onClose}
          PaperProps={{ sx: { borderRadius: '20px', paddingX: 2 } }}
          {...props}
        >
          <AppBox {...appDialogWrapperProps}>
            {!hideTopCloseBtn && (
              <AppBox
                display={'flex'}
                justifyContent={'space-between'}
                padding={1}
                alignItems={stickyCloseBtn ? 'top' : 'center'}
                paddingTop={2}
                borderBottom={!hideTitleBottomBorder ? 1 : 0}
                borderColor={'grey.300'}
              >
                {title && (
                  <AppBox flexGrow={1}>
                    <AppTypography fontSize={16} fontWeight={'bold'} {...titleProps}>
                      {title ?? 'No Title'}
                    </AppTypography>
                  </AppBox>
                )}
                <AppIcon size="medium" icon="material-symbols:close" onClick={onDialogClose} {...closeIconProps} />
              </AppBox>
            )}

            <AppBox padding={2} flex={1} sx={{ overflowY: 'auto' }}>
              {renderChild ? renderChild(closeDialog, isDialogOpen) : children}
            </AppBox>

            {!hideFooterCloseBtn && (
              <DialogActions
                sx={{
                  ml: '40',
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: 'white',
                  borderTop: '1px solid',
                  borderColor: 'grey.300',
                  padding: 2,
                }}
              >
                {!hideCancelButton && (
                  <AppButton
                    variant="text"
                    color="secondary"
                    onClick={onDialogClose}
                    size="large"
                    sx={{ paddingX: 5 }}
                    {...cancelButtonProps}
                  >
                    Close
                  </AppButton>
                )}
                {appDialogWrapperProps && !hideSubmitButton && (
                  <AppButton
                    type="submit"
                    variant="contained"
                    size="large"
                    children="Save"
                    sx={{ paddingX: 5 }}
                    {...submitButtonProps}
                  />
                )}
              </DialogActions>
            )}
          </AppBox>
        </Dialog>
      </>
    )
  }
)
