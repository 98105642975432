import { Container } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { AppBox, AppTable, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { centsToDollar } from 'globals/functions'
import { useMembershipHelper } from 'hooks'
import { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CouponApi, SubAccountAddedModel } from 'sdk'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'
interface Props {
  tempAddedUsers: SubAccountAddedModel[]
}
export const AddSubAccountStepThreePurchase: FC<Props> = ({ tempAddedUsers }) => {
  const couponApi = new CouponApi()
  const { ActiveMembership } = useMembershipHelper()
  const { DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)
  const { mutate: ApplyCoupon, data: coupon } = useMutation({
    mutationKey: [ApiQueryKeys.payment.FetchCouponById],
    mutationFn: () => couponApi.ValidateCoupon('sub-account-5'),
  })
  const columns: ColumnDef<{ Item: string; No: string; Total: string }>[] = [
    {
      header: 'Item',
      accessorKey: 'Item',
    },
    {
      header: 'No.',
      accessorKey: 'No',
    },
    {
      header: 'Total',
      accessorKey: 'Total',
    },
  ]
  const data = useMemo(() => {
    return {
      Item: `Billed ${ActiveMembership()?.MembershipInterval} - ${centsToDollar(ActiveMembership()?.MembershipPrice ?? 0)} ${ActiveMembership()?.MembershipTitle}`,
      No: `${tempAddedUsers.length} User Seat(s)`,
      Total: `${centsToDollar(tempAddedUsers.length * (ActiveMembership()?.MembershipPrice ?? 0))}`,
    }
  }, [tempAddedUsers])

  const amountToBePaid = useMemo(() => {
    const total = tempAddedUsers.length * (ActiveMembership()?.MembershipPrice ?? 0)
    let afterDiscount = total
    if (coupon) {
      afterDiscount = total - (total * coupon.PercentOff!) / 100
    }
    return centsToDollar(afterDiscount)
  }, [coupon])

  const discount = useMemo(() => {
    const total = tempAddedUsers.length * (ActiveMembership()?.MembershipPrice ?? 0)
    let discount = 0
    if (coupon) {
      discount = (total * coupon.PercentOff!) / 100
    }
    return centsToDollar(discount)
  }, [coupon])

  useEffect(() => {
    ApplyCoupon()
  }, [tempAddedUsers])
  return (
    <>
      <Container>
        <AppBox display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={4}>
          <AppTypography fontSize={14} fontWeight={500} color={'grey.500'} component={'span'}>
            Please verify your order. Upon clicking the{' '}
            <AppTypography fontWeight={600} component={'span'}>
              Make Payment
            </AppTypography>{' '}
            button we’ll attempt to charge your payment method to complete the transaction.
          </AppTypography>
          <AppBox border={1} borderColor={'grey.300'} padding={4} borderRadius={6}>
            <AppTable data={[data ?? {}]} columns={columns} />
          </AppBox>
          <AppBox border={1} borderColor={'grey.300'} padding={4} borderRadius={6}>
            <AppBox display={'flex'} justifyContent={'space-between'}>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                Payment Method
              </AppTypography>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                Visa XXX {DefaultPaymentMethod?.LastDigits}
              </AppTypography>
            </AppBox>
            <AppBox display={'flex'} justifyContent={'space-between'}>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                Pro Rated Amount
              </AppTypography>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                {centsToDollar(tempAddedUsers.length * (ActiveMembership()?.MembershipPrice ?? 0))}
              </AppTypography>
            </AppBox>
            <AppBox display={'flex'} justifyContent={'space-between'}>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                Discount Applied
              </AppTypography>
              <AppTypography fontSize={14} color={'grey.500'} fontWeight={500}>
                {discount ?? 0}
              </AppTypography>
            </AppBox>
            <AppBox
              display={'flex'}
              justifyContent={'space-between'}
              borderTop={1}
              borderColor={'grey.300'}
              marginTop={2}
            >
              <AppTypography fontSize={14} fontWeight={'bold'}>
                Amount to be paid now
              </AppTypography>
              <AppTypography fontSize={14} fontWeight={'bold'}>
                {amountToBePaid ?? 0}
              </AppTypography>
            </AppBox>
          </AppBox>
        </AppBox>
      </Container>
    </>
  )
}
