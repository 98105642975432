import { FormFiledRuleOptions } from '../typings'
import { FormFiledRulesKeys } from './common'

interface ValidationRuleConfig {
  key: FormFiledRulesKeys
  validate: (value: any, options?: FormFiledRuleOptions) => boolean | string
  message: (options?: FormFiledRuleOptions) => string
}

export const validationRulesMapping: Array<ValidationRuleConfig> = [
  {
    key: FormFiledRulesKeys.Email,
    validate: (value) => {
      if (!value) return true
      return /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}$/.test(value)
    },
    message: () => 'Invalid email',
  },
  {
    key: FormFiledRulesKeys.Password,
    validate: (value) => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(value),
    message: () => 'Password must contain at least 1 uppercase letter, 1 lowercase letter and 1 number',
  },
  {
    key: FormFiledRulesKeys.ConfirmPassword,
    validate: (value, options) => value === options?.optionValue,
    message: () => 'Passwords do not match',
  },
  {
    key: FormFiledRulesKeys.ConfirmEmail,
    validate: (value, options) => value === options?.optionValue,
    message: () => 'Emails do not match',
  },

  {
    key: FormFiledRulesKeys.Phone,
    validate: (value) => /^\d{10}$/.test(value),
    message: () => 'Invalid phone number',
  },
  {
    key: FormFiledRulesKeys.EmailExist,
    validate: (value, options) => {
      return options?.optionValue === false
    },
    message: () => 'Email already exist',
  },
  {
    key: FormFiledRulesKeys.TeamNameTaken,
    validate: (value, options) => {
      return options?.optionValue === false
    },
    message: () => 'Team Name already taken',
  },
  {
    key: FormFiledRulesKeys.Mask,
    validate: (value, options) => {
      if (!value) return true
      const escapedOptionValue = options?.optionValue?.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
      const regexPattern = `^${escapedOptionValue.replace(/9/g, '\\d')}$`
      const regex = new RegExp(regexPattern)
      return regex.test(value)
    },
    message: (options) => `This field must match ${options?.optionValue.replace(/#/g, '9')} format.`,
  },
]
