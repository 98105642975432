import { AppBox, SimpleCheckBox, SimpleCheckBoxProps } from 'components'
import { FC, useEffect, useState } from 'react'

interface Props extends Omit<SimpleCheckBoxProps, 'onChange'> {
  options: Array<{ Id: string; Label?: string }>
  orientation?: 'row' | 'column'
  BindLabel?: boolean
  onChange: (checked: string[]) => void
  values: string[]
}

export const SimpleCheckBoxGroup: FC<Props> = ({
  options,
  onChange,
  BindLabel = false,
  orientation = 'row',
  ...props
}) => {
  const [checked, setChecked] = useState<string[]>([])
  useEffect(() => {
    const opt = options
      .filter((x) => props.values?.includes(BindLabel ? x.Label! : x.Id!))
      .map((x) => (BindLabel ? x.Label! : x.Id!))
    setChecked([...checked, ...opt])
    console.log('opt', opt)
  }, [props.values])
  useEffect(() => {
    onChange(checked)
  }, [checked])
  return (
    <AppBox display={'flex'} flexDirection={orientation}>
      {options?.map((x) => (
        <SimpleCheckBox
          key={x.Id}
          {...props}
          checked={!!checked.find((y) => y === (BindLabel ? x.Label! : x.Id!))}
          label={x.Label}
          onChange={(e) => {
            if (e.target.checked) {
              setChecked([...checked, BindLabel ? x.Label! : x.Id!])
            } else {
              setChecked([...checked.filter((y) => y !== x.Id!)])
            }
          }}
        />
      ))}
    </AppBox>
  )
}
