import { AppBox } from 'components'
import { AccountOwnerBillingCard } from './cards/AccountOwnerBillingCards'
import { ManageUserCard } from './cards/ManageUserCard'
import { PaymentMethodsCard } from './cards/PaymentMethodsCards'
export const BillingAndPlansModule = () => {
  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} gap={2}>
        <AccountOwnerBillingCard />
        <ManageUserCard />
        <PaymentMethodsCard />
      </AppBox>
    </>
  )
}
