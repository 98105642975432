import { MenuItem } from '@mui/material'
import { FC } from 'react'
import { AppSimpleFieldProps, SimpleField } from './simple-field/SimpleField'

interface Props extends AppSimpleFieldProps {
  options: { value: string | number; label: string }[]
  onValueChange?: (value: string | number) => void
}

export const SimpleValueSelect: FC<Props> = ({ options, onValueChange, ...props }) => {
  return (
    <SimpleField {...props} select>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          sx={{ fontSize: 16, paddingX: 2, paddingY: 0.4 }}
          value={option.value}
          onClick={() => {
            if (onValueChange) {
              onValueChange(option.value)
            }
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </SimpleField>
  )
}
