import { Popover } from '@mui/material'
import { AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { appRoutes } from 'globals/constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Authorize } from '../authorize/Authorize'

export const ViewsSelectBtn = () => {
  const navigate = useNavigate()

  const navigateTo = (name: string) => {
    navigate({ pathname: name })
  }

  const [viewsAnchorEl, setViewsAnchorEl] = useState<HTMLButtonElement | null>(null)
  return (
    <>
      <AppButton
        size="large"
        aria-describedby={'views-btn'}
        prefixIcon="uis:apps"
        suffixIcon="dashicons:arrow-down-alt2"
        onClick={(event) => setViewsAnchorEl(event.currentTarget)}
        style={{ backgroundColor: '#EFF6FF' }}
      >
        Views
      </AppButton>
      <Popover
        id={'views-btn'}
        open={!!viewsAnchorEl}
        anchorEl={viewsAnchorEl}
        onClose={() => setViewsAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AppBox display={'flex'} flexDirection={'column'} padding={2} alignItems={'start'} gap={1}>
          <AppBox display={'flex'} gap={1.5} cursor="pointer" onClick={() => navigateTo(appRoutes.parksites.path)}>
            <AppIcon icon="fa6-solid:shapes" size="medium" />
            <AppTypography fontSize={13}>Park Sites</AppTypography>
          </AppBox>
          <AppBox display={'flex'} gap={1.5} cursor="pointer" onClick={() => navigateTo(appRoutes.crm.path)}>
            <AppIcon icon="material-symbols:shapes" size="medium" />
            <AppTypography fontSize={13}>CRM</AppTypography>
          </AppBox>
          <Authorize allowedToAdmin={true}>
            <AppBox display={'flex'} gap={1.5} cursor="pointer" onClick={() => navigateTo(appRoutes.adminSetting.path)}>
              <AppIcon icon="ic:round-settings" size="medium" />
              <AppTypography fontSize={13}>Settings</AppTypography>
            </AppBox>
          </Authorize>
        </AppBox>
      </Popover>
    </>
  )
}
