import { AppBox, AppTypography, AppButton, PhoneText } from 'components'
import { ParkSiteHelpingModules } from 'globals/constants'
import { getFullDate } from 'globals/functions'
import { FC } from 'react'
import { ParkSiteToolSliceSelector, setOpenedParkSiteHelpModule } from 'store/slices'
import { ParkDetailsCards } from '../ParkDetailsCards'
import { useAppDispatch } from 'store/app.store'
import { ParkDetailViewModel } from 'sdk'
import { useSelector } from 'react-redux'

interface Props {
  data: ParkDetailViewModel
  openImagesDialog: () => void
}

export const ParkDetailHeader: FC<Props> = ({ data, openImagesDialog }) => {
  const dispatch = useAppDispatch()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={2} paddingY={2}>
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography color={'grey.900'} fontSize={24} fontWeight={700}>
          {data?.ParkInfo?.ParkName ?? 'Unknown'}
        </AppTypography>
        <AppBox color={'primary.600'}>
          {!!data.ImageUrls && data?.ImageUrls?.length > 0 && (
            <AppButton prefixIcon="bi:images" onClick={openImagesDialog}>
              <AppTypography fontSize={14}>Images</AppTypography>
            </AppButton>
          )}
          <AppBox display={'inline'} If={!!data?.ParkInfo?.WebSite}>
            <AppButton
              prefixIcon="fluent-mdl2:globe-favorite"
              onClick={() => window.open(data?.ParkInfo?.WebSite!, '_blank')}
            >
              <AppTypography fontSize={14}>Website</AppTypography>
            </AppButton>
          </AppBox>
          <AppButton
            prefixIcon="basil:history-solid"
            onClick={() =>
              dispatch(
                setOpenedParkSiteHelpModule({
                  key: ParkSiteHelpingModules.ParkHistory,
                  options: {
                    Id: selectedParkId,
                    Title: 'Record History',
                  },
                })
              )
            }
          >
            <AppTypography fontSize={14}>History</AppTypography>
          </AppButton>
          {false && (
            <AppButton
              prefixIcon="majesticons:printer-line"
              onClick={() => {
                // handlePrint(null, () => contentToPrint.current)
              }}
            >
              <AppTypography fontSize={14}>Print</AppTypography>
            </AppButton>
          )}
        </AppBox>
      </AppBox>

      <AppBox>
        <AppBox display={'flex'} gap={2}>
          <AppTypography color={'grey.600'}>Address</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={600}>
            {data?.Address}
          </AppTypography>
          <AppTypography color={'grey.600'}>City</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={600}>
            {data?.City}
          </AppTypography>
          <AppTypography color={'grey.600'}>County</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={600}>
            {data?.County}
          </AppTypography>
          <AppTypography color={'grey.600'}>State</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={600}>
            {data?.State}
          </AppTypography>
          <AppTypography color={'grey.600'}>Zip Code</AppTypography>
          <AppTypography color={'grey.900'} fontWeight={600}>
            {data?.Zip}
          </AppTypography>

          <AppBox If={!!data?.ParkInfo?.ParkPhone}>
            <AppTypography color={'grey.600'}>
              Phone <PhoneText paddingLeft={1} phone={data?.ParkInfo?.ParkPhone!} fontWeight={600} />
            </AppTypography>
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox If={!!data?.LastUpdatedDate}>
        <AppTypography color={'grey.600'}>Last Updated on {getFullDate(data?.LastUpdatedDate)}</AppTypography>
      </AppBox>

      <AppBox If={!!data} paddingY={2}>
        <ParkDetailsCards parkDetail={data!} />
      </AppBox>
    </AppBox>
  )
}
