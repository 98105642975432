import { Grid } from '@mui/material'
import { AppBox, AppButton, AppDialog, AppMenu, InfoBox, SimpleField, SimpleValueSelect } from 'components'
import { InfoBoxSettingTypes, ParkSiteHelpingModules } from 'globals/constants'
import { useCrmContactParks, useCrmContacts } from 'hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { ParkSiteToolSliceSelector, setOpenedParkSiteHelpModule } from 'store/slices'
import { AddUpdateCrmContactForm } from 'views/account/crm'
import { ParkActivityCrmTable } from './listing/ParkActivityCrmTable'

export const ParkActivityCrm = () => {
  const dispatch = useAppDispatch()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const {
    handleSubmit,
    setError,
    setValue,
    watch,
    dialogRef: AddDialogRef,
    control,
    isAddingContact,
    handleFormSubmit,
    handleShowInfoClick,
    handleEditContact,
    contactToUpdate,
    handleAddNewContactClick,
    handleDeleteContactById,
    contactAdded,
    isEditing,
  } = useCrmContacts({})

  const { filter, setFilter, isGetting, contacts, handleDetachPark, GetContacts } = useCrmContactParks()

  useEffect(() => {
    GetContacts()
  }, [contactAdded])

  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'} height={'100%'} paddingX={4} gap={2}>
        <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ParkActivityCrmDismissed}>
          These are contacts within your CRM that you have attached to this park. Use the + Add button to link existing
          CRM contacts or add new contacts to your CRM that should be linked to this park
        </InfoBox>
        <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={4}>
                <SimpleValueSelect
                  fullWidth
                  options={[
                    {
                      value: 'Contact',
                      label: 'Contact',
                    },
                    {
                      value: 'Lead',
                      label: 'Lead',
                    },
                    {
                      value: 'All',
                      label: 'All',
                    },
                  ]}
                  defaultValue={'All'}
                  onValueChange={(val) => setFilter((prev) => ({ ...prev, EntityType: val.toString() }))}
                />
              </Grid>
              <Grid item>
                <SimpleField
                  prefixIcon="iconamoon:search-bold"
                  placeholder="Search"
                  onChange={(event) => {
                    setFilter((prev) => ({ ...prev, Query: event.target.value }))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <AppMenu
              MenuItems={[
                {
                  text: 'Add Existing',
                  onClick: () =>
                    dispatch(
                      setOpenedParkSiteHelpModule({
                        key: ParkSiteHelpingModules.AddContact,
                        options: {
                          Title: 'Add Existing Contact',
                        },
                      })
                    ),
                },
                { text: 'Add New', onClick: () => handleAddNewContactClick(selectedParkId) },
              ]}
              renderElement={(openMenu) => (
                <AppButton variant={'contained'} color={'primary'} prefixIcon="ic:baseline-plus" onClick={openMenu}>
                  Add
                </AppButton>
              )}
            />
          </Grid>
        </Grid>
        <ParkActivityCrmTable
          data={contacts?.Data ?? []}
          totalRecords={contacts?.TotalCount ?? 0}
          onPageSizeChange={(pageSize) => {
            setFilter((prev) => ({ ...prev, PageNo: 1, PageSize: pageSize }))
          }}
          onCurrentPageChange={(page) => {
            setFilter((prev) => ({ ...prev, PageNo: page }))
          }}
          paginationFilter={filter}
          isLoading={isGetting}
          onInfoClick={(data: CrmContactViewModel) => {
            handleShowInfoClick(data)
          }}
          onDetachFromParkClick={(data: CrmContactViewModel) => handleDetachPark(selectedParkId, data.Id!)}
          onDeleteClick={(id: string) => handleDeleteContactById(id)}
          onEditClick={(data: CrmContactViewModel) => handleEditContact(data)}
        />
      </AppBox>
      <AppDialog
        ref={AddDialogRef}
        title={!!contactToUpdate ? 'Edit Contact' : 'Add New Contact'}
        maxWidth="md"
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit(handleFormSubmit),
        }}
        submitButtonProps={{ isLoading: isAddingContact || isEditing }}
      >
        <AddUpdateCrmContactForm control={control} setError={setError} watch={watch} originalData={contactToUpdate} />
      </AppDialog>
    </>
  )
}
