import { Grid } from '@mui/material'
import { AppTextField } from 'components'
import { FC } from 'react'
import { Control, Form } from 'react-hook-form'
import { StatePayload } from 'sdk'

interface Props {
  control: Control<StatePayload>
}

export const AddEditStateForm: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppTextField control={control} name="Name" label="Name" />
      </Grid>
      <Grid item xs={12}>
        <AppTextField control={control} name="Code" label="Abbreviation" />
      </Grid>
    </Grid>
  )
}
