import { debounce, FormControlLabel, Grid } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useMutation } from '@tanstack/react-query'
import {
  AppAutoComplete,
  AppBox,
  AppDialog,
  AppDialogRefType,
  AppTextField,
  AppTypography,
  EmailField,
  PhoneField,
  StateField,
  TextWithToolTip,
  ZipField,
} from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useRef } from 'react'
import { Control, useForm, UseFormHandleSubmit, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  CrmCompaniesApi,
  CrmContactsApi,
  PeopleInsightApi,
  PeopleInsightToCrmPayload,
  PeopleInsightViewModel,
  ShowAlert,
} from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

interface Props {
  dialogRef: React.MutableRefObject<AppDialogRefType | undefined>
  peopleInsight: PeopleInsightViewModel
  control: Control<PeopleInsightToCrmPayload>
  watch: UseFormWatch<PeopleInsightToCrmPayload>
  setValue: UseFormSetValue<PeopleInsightToCrmPayload>
  handleSubmit: UseFormHandleSubmit<PeopleInsightToCrmPayload>
  reset: UseFormReset<PeopleInsightToCrmPayload>
}

export const AddPeopleToCrmForm: FC<Props> = ({
  dialogRef,
  peopleInsight,
  control,
  watch,
  setValue,
  reset,
  handleSubmit,
}) => {
  const crmContactApi = new CrmContactsApi()
  const crmCompanyApi = new CrmCompaniesApi()
  const peopleInsightApi = new PeopleInsightApi()

  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (peopleInsight) {
      isUserContctExist({ email: peopleInsight.Email })
      if (peopleInsight.CompanyName) {
        isCompanyExistCheck(peopleInsight.CompanyName)
      }
    }
  }, [peopleInsight, watch().ContactEmail, watch().Company])

  const {
    data: isEmailExist,
    mutate: isUserContctExist,
    isPending: isCheckingEmail,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.IsContactExist],
    mutationFn: (data: { email: string; contactId?: string }) =>
      crmContactApi.isUserContctExist(data.email, data.contactId),
  })
  const debouncedChangeHandler = debounce((value) => {
    const isValid = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}$/.test(value)
    if (isValid) {
      isUserContctExist({ email: value })
    }
  }, 500)

  const {
    data: isCompanyExist,
    mutate: isCompanyExistCheck,
    isPending: isCheckingCompany,
  } = useMutation({
    mutationFn: (companyName: string) => crmCompanyApi.IsCompanyExist(companyName),
    mutationKey: [ApiQueryKeys.crm.companies.IsCompanyExist],
  })
  const debouncedChangeHandlerCompany = debounce((value) => {
    isCompanyExistCheck(value)
  }, 500)

  const { mutate: AddToCrm, isPending: isAdding } = useMutation({
    mutationFn: (data: PeopleInsightToCrmPayload) => peopleInsightApi.AddPeopleInsightToCrm(data),
    mutationKey: [ApiQueryKeys.parksites.peopleInsights.AddPeopleInsightToCrm],
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('People Insight added to CRM', { variant: 'success' })
      dialogRef.current?.closeDialog!()
      reset()
    },
  })
  const handleFormSubmit = handleSubmit((data) => {
    const emailExist = !!isEmailExist && !!watch().ContactEmail
    const companyExist = !!isCompanyExist && !!watch().Company
    if (emailExist || companyExist) {
      dialogRef.current?.closeDialog!()
      let msg = ''
      if (emailExist && companyExist) {
        msg = `A contact with this email and company with this name already exists, clicking ok will update the contact's information`
      } else if (emailExist && !companyExist) {
        msg = `A contact with this email already exists, clicking ok will update the contact's information`
      } else if (!emailExist && companyExist) {
        msg = `This company already exists, clicking ok will create a link with the existing company and update the contact's information`
      }
      ShowAlert('question', msg).then((result) => {
        if (result.isConfirmed) {
          AddToCrm(data)
          dialogRef.current?.openDialog!()
        } else {
          dialogRef.current?.openDialog!()
        }
      })
    } else {
      AddToCrm(data)
    }
  })
  return (
    <AppDialog
      ref={dialogRef}
      appDialogWrapperProps={{
        component: 'form',
        role: 'form',
        onSubmit: handleFormSubmit,
      }}
      submitButtonProps={{ isLoading: isAdding }}
      title="Copy To CRM"
      maxWidth={'md'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBox display={'flex'} justifyContent={'space-between'}>
            <AppBox>
              <AppTypography component={'span'} fontWeight={'bold'}>
                Company Name
              </AppTypography>
            </AppBox>
            <AppBox If={!!isCompanyExist && !!watch().Company}>
              <AppBox fontSize={10} color={'red'}>
                (CRM Match)
              </AppBox>
            </AppBox>
          </AppBox>
          <AppTextField
            name="Company"
            control={control}
            onFieldChange={(e) => debouncedChangeHandlerCompany(e.target.value)}
            isLoading={isCheckingCompany}
          />
        </Grid>
        <Grid item md={6}>
          <AppAutoComplete
            required
            label="Contact Type"
            placeholder="Select Contact Type"
            control={control}
            name="ContactType"
            options={[
              { label: 'Lead', value: 'lead' },
              { label: 'Contact', value: 'contact' },
            ]}
          />
        </Grid>
        <Grid item md={6}>
          <AppBox display={'flex'} justifyContent={'space-between'}>
            <AppBox>
              <AppTypography component={'span'} fontWeight={'bold'}>
                Email
              </AppTypography>
            </AppBox>
            <AppBox If={!!isEmailExist && !!watch().ContactEmail}>
              <AppBox fontSize={10} color={'red'}>
                (CRM Match)
              </AppBox>
            </AppBox>
          </AppBox>
          <EmailField
            control={control}
            name="ContactEmail"
            placeholder="Enter Email"
            isLoading={isCheckingEmail}
            onFieldChange={(e) => debouncedChangeHandler(e.target.value)}
          />
        </Grid>
        <Grid item md={6}>
          <AppTextField label="First Name" control={control} name="ContactFirstName" placeholder="Enter First Name" />
        </Grid>
        <Grid item md={6}>
          <AppTextField label="Last Name" control={control} name="ContactLastName" placeholder="Enter Last Name" />
        </Grid>
        <Grid item md={6}>
          <AppTextField label="Address" control={control} name="ContactAddress" placeholder="Enter Address" />
        </Grid>
        <Grid item md={6}>
          <AppTextField label="Address2" control={control} name="ContactAddress2" placeholder="Enter Address2" />
        </Grid>
        <Grid item md={5}>
          <AppTextField label="City" control={control} name="ContactCity" placeholder="Enter City" />
        </Grid>
        <Grid item md={4}>
          <StateField label="State" control={control} name="ContactStateId" placeholder="Enter State" />
        </Grid>
        <Grid item md={3}>
          <ZipField label="Zip" control={control} name="ContactZip" placeholder="Enter Zip" />
        </Grid>
        <Grid item md={6}>
          <PhoneField label="Phone" control={control} name="ContactPhone" placeholder="Enter Phone" />
        </Grid>
        <Grid item md={6}>
          <PhoneField label="Alt Phone" control={control} name="ContactAltPhone" placeholder="Enter Alt Phone" />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!watch().LinkToParkId}
                onChange={(e) => setValue('LinkToParkId', e.target.checked ? selectedParkId : '')}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <TextWithToolTip tooltipText="Checking this option creates a link between the current park and contact being copied to your CRM">
                Include link to this park
              </TextWithToolTip>
            }
          />
        </Grid>
        {!!peopleInsight?.RelatedParks && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!watch().PeopleInsightIdToLinkWithAllParks}
                  onChange={(e) =>
                    setValue('PeopleInsightIdToLinkWithAllParks', e.target.checked ? peopleInsight.Id : '')
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <TextWithToolTip tooltipText="Checking this option creates a link between all other associated parks and contact being copied to your CRM">
                  Include link to all other parks
                </TextWithToolTip>
              }
            />
          </Grid>
        )}
      </Grid>
    </AppDialog>
  )
}
