import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CrmContactLinkedCompaniesApi, CrmcontactLinkedCompanyPayload } from 'sdk'

interface Props {
  companyId: string
}
const crmContactLinkedCompaniesApi = new CrmContactLinkedCompaniesApi()
export const useCrmCompanyContacts = () => {
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  // const { control, reset } = useForm<CrmcontactLinkedCompanyPayload>({ defaultValues: { CompanyId: companyId } })

  const {
    data: companyLinkedContacts,
    mutate: getCompanyLinkedContacts,
    isPending: isGettingCompanyContacts,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedCompanies.FetchLinkedContacts],
    mutationFn: (companyId: string) => crmContactLinkedCompaniesApi.getCompanyLinkedContacts(companyId),
  })

  return {
    isAddClicked,
    setIsAddClicked,
    companyLinkedContacts,
    getCompanyLinkedContacts,
    isGettingCompanyContacts,
  }
}
