import { TextField, Typography } from '@mui/material'
import { AppSimpleFieldProps } from './simple-field/SimpleField'
import { AppTypography } from 'components'

export interface AppSimpleTextAreaProps extends AppSimpleFieldProps {}
export const SimpleTextArea = ({ label, LabelProps, required, ...props }: AppSimpleTextAreaProps) => {
  return (
    <>
      {label && (
        <AppTypography component={'span'} {...LabelProps}>
          {label}
          {required && (
            <AppTypography component={'span'} color={'red'} paddingLeft={1}>
              *
            </AppTypography>
          )}
        </AppTypography>
      )}
      <TextField
        size="small"
        variant="outlined"
        multiline={true}
        color="secondary"
        margin="dense"
        minRows={4}
        {...props}
        InputProps={{
          style: { borderRadius: '10px', backgroundColor: 'white' },
        }}
      />
    </>
  )
}
