import { Slider } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppDialog, AppIcon, AppTypography } from 'components/base'
import { dataURLToFile, imageToDataURL } from 'globals/functions'
import React, { FC, useCallback, useRef, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import { ProfileImagePayload } from 'sdk'
import { cropImage } from './ImageCropHelper'

export interface ProfileImageSelectorProps {
  imageSrc?: string | null
  defaultImage: string
  principalId: string
  uploadImageFnKey: string
  removeImageFnKey: string
  uploadImageFn: (image: ProfileImagePayload) => Promise<string>
  removeImageFn: () => Promise<void>
  onImageSave: (imageSrc: string) => void
  onRemoveImage: () => void
  handleRemoveImage?: () => void
}
export const ProfileImage: FC<ProfileImageSelectorProps> = ({
  imageSrc,
  principalId,
  uploadImageFnKey,
  defaultImage,
  removeImageFnKey,
  removeImageFn,
  onRemoveImage,
  onImageSave,
  uploadImageFn,
}) => {
  const [imageToCrop, setImageToCrop] = useState<string>('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const fileInput = useRef(null)

  const { mutate: uploadImage, isPending: isSaving } = useMutation({
    mutationKey: [uploadImageFnKey],
    mutationFn: (data: ProfileImagePayload) => uploadImageFn(data),
    onSuccess: (data: string) => {
      closeDialog()
      onImageSave(data)
    },
  })

  const { mutate: removeImage, isPending: isRemoving } = useMutation({
    mutationKey: [removeImageFnKey],
    mutationFn: () => removeImageFn(),
    onSuccess: () => {
      closeDialog()
      onRemoveImage()
    },
  })

  const handleSelectPicture = () => {
    const file = fileInput.current
    if (file) {
      ;(file as any).click()
    }
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        setImageToCrop(reader.result as string)
        if (fileInput.current) {
          ;(fileInput.current as any).value = ''
        }
      }
      reader.readAsDataURL(file)
    }
  }
  const handleSaveImage = () => {
    if (!croppedAreaPixels) {
      return
    }
    cropImage(imageToCrop, croppedAreaPixels, (error) => {
      console.error('cropImage error:', error)
    }).then((image) => {
      if (image) {
        imageToDataURL(image, (dataUrl) => {
          const file = dataURLToFile(dataUrl)
          const payload: ProfileImagePayload = {
            Image: file,
            Id: principalId,
          }
          uploadImage(payload)
        })
      }
    })
  }
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const closeDialog = () => {
    setImageToCrop('')
    setIsDialogOpen(false)
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <AppBox position={'relative'} display={'inline-block'}>
        <img
          src={!!imageSrc ? imageSrc : defaultImage}
          alt="profile_image"
          height={120}
          width={120}
          style={{ borderRadius: 100 }}
        />
        <AppBox
          backgroundColor="white"
          borderRadius={50}
          padding={0.5}
          cursor="pointer"
          style={{ position: 'absolute', right: 15, bottom: 0 }}
          onClick={openDialog}
        >
          <AppIcon icon="majesticons:camera" color="grey" size="large" cursor="pointer" />
        </AppBox>
        <input
          type="file"
          id="imageInput"
          hidden={true}
          ref={fileInput}
          onChange={handleImageChange}
          style={{ cursor: 'pointer' }}
        />
      </AppBox>
      <AppDialog open={isDialogOpen} title="Select Personal Image" onClose={closeDialog} hideFooterCloseBtn={true}>
        <AppTypography>
          Select your desired image.Then click <b>Save</b> After cropping your image. Be sure your image fils the dashed
          circle so we can display it correctly.
        </AppTypography>
        <AppBox textAlign={'center'} backgroundColor="white">
          {!!imageToCrop ? (
            <>
              <AppBox position={'relative'} width={'100%'} height={400}>
                <Cropper
                  image={imageToCrop}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  showGrid={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </AppBox>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom as number)}
              />
            </>
          ) : (
            <AppBox>
              <img src={!!imageSrc ? imageSrc : defaultImage} alt="profile_image" height={120} width={120} />
            </AppBox>
          )}
        </AppBox>
        <AppBox
          display={'flex'}
          gap={2}
          justifyContent={'center'}
          marginTop={2}
          borderTop={1}
          borderColor={'grey.200'}
          paddingTop={2}
        >
          <AppButton
            variant="outlined"
            color="error"
            onClick={() => removeImage()}
            disabled={!imageSrc || !!imageToCrop}
            isLoading={isRemoving}
          >
            Remove
          </AppButton>
          <AppButton variant="outlined" onClick={handleSelectPicture}>
            Select Image
          </AppButton>
          <AppButton variant="contained" isLoading={isSaving} onClick={handleSaveImage} disabled={!imageToCrop}>
            Save
          </AppButton>
        </AppBox>
      </AppDialog>
    </>
  )
}
