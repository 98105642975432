import { Divider, Step, StepLabel, StepLabelProps, StepProps, Stepper } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { AccountApi, SubAccountAddedModel } from 'sdk'
import { AddSubAccountStepFourConfirmation } from './step-four-confirmation/AddSubAccountConfirmation'
import { AddSubAccountStepOneTerms } from './step-one-terms/AddSubAccountStepOneTerms'
import { AddSubAccountStepThreePurchase } from './step-three-purchase/AddSubAccountStepThreePurchase'
import { AddSubAccountStepTwoAddUsers } from './step-two-users/AddSubAccountStepTwoAddUsers'
interface Props {
  closeDialog: () => void
}
export const AddSubAccountStepper: FC<Props> = ({ closeDialog }) => {
  const accountApi = new AccountApi()
  const { enqueueSnackbar } = useSnackbar()
  const steps = ['Terms', 'Add User', 'Purchase', 'Confirmation']
  const [activeStep, setActiveStep] = useState(0)
  const [addedUsers, setAddedUsers] = useState<Array<SubAccountAddedModel>>([])
  const [agreementAccepted, setAgreementAccepted] = useState(false)

  const { mutate: addSubAccounts, isPending: isAddingAccounts } = useMutation({
    mutationKey: [ApiQueryKeys.account.addSubAccounts],
    mutationFn: () => accountApi.addSubAccount({ Emails: addedUsers.map((u) => u.Email) }),
    onSuccess: () => enqueueSnackbar('Sub account added successfully', { variant: 'success' }),
  })
  const renderStepsContent = () => {
    switch (activeStep) {
      case 0:
        return <AddSubAccountStepOneTerms setAgreementAccepted={setAgreementAccepted} />
      case 1:
        return <AddSubAccountStepTwoAddUsers tempAddedUsers={addedUsers} setTempAddedUsers={setAddedUsers} />
      case 2:
        return <AddSubAccountStepThreePurchase tempAddedUsers={addedUsers} />
      case 3:
        return <AddSubAccountStepFourConfirmation />
      default:
        return null
    }
  }
  const nextButtonLabel = () => {
    switch (activeStep) {
      case 0:
        return 'Agree'
      case 1:
        return 'Place Order'
      case 2:
        return 'Purchase'
      case 3:
        return 'Close'
    }
  }

  const handleContinueClick = () => {
    if (activeStep === 2) {
      addSubAccounts()
    } else if (activeStep === 3) {
      closeDialog()
      return
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  const handleBackClick = () => {
    if (activeStep === 0) {
      closeDialog()
      return
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const isNextBtnDisabled = () => {
    if (activeStep === 0) {
      return !agreementAccepted
    }
    if (activeStep === 1) {
      return addedUsers.length === 0
    }
    return false
  }

  return (
    <>
      <AppBox>
        <AppBox paddingBottom={0}>
          <Stepper
            activeStep={activeStep}
            connector={<Divider sx={{ width: 40, marginX: 0.5, borderWidth: 1, borderColor: 'primary.600' }} />}
            sx={{ justifyContent: 'center' }}
          >
            {steps.map((step, index) => {
              const stepProps: StepProps = {}
              const labelProps: StepLabelProps = {}
              return (
                <Step key={step} {...stepProps}>
                  <StepLabel
                    icon={
                      <AppBox
                        width={25}
                        height={25}
                        backgroundColor="primary.100"
                        borderRadius={12}
                        textAlign={'center'}
                        justifySelf={'center'}
                        paddingTop={0.2}
                      >
                        {activeStep > index ? (
                          <AppIcon icon={'material-symbols:check'} size="medium" color="primary.600" />
                        ) : (
                          <AppTypography fontSize={14}>{index + 1}</AppTypography>
                        )}
                      </AppBox>
                    }
                    {...labelProps}
                  >
                    <AppTypography fontSize={16} color={'primary.600'} fontWeight={600}>
                      {step}
                    </AppTypography>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </AppBox>
        <AppBox paddingY={2}>{renderStepsContent()}</AppBox>
        <AppBox>
          <AppBox display={'flex'} gap={2} justifyContent={'flex-end'}>
            <AppButton onClick={() => handleBackClick()} size="large" color="primary">
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </AppButton>
            <AppButton
              variant="contained"
              size="large"
              sidePaddings={6}
              disabled={isNextBtnDisabled()}
              onClick={() => handleContinueClick()}
              isLoading={isAddingAccounts}
            >
              {nextButtonLabel()}
            </AppButton>
          </AppBox>
        </AppBox>
      </AppBox>
    </>
  )
}
