import { AppBox } from 'components'
import { ReviewsActions } from 'views/account/admin-settings/actionbar/ReviewsActions'
import { ReviewsListing } from './listing/ReviewsListing'
import { PaginationFilterModel } from 'sdk'
import { useEffect, useState } from 'react'

export const ManageReviews = () => {
  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())
  return (
    <>
      <AppBox minHeight={'inherit'}>
        <AppBox paddingX={2}>
          <ReviewsActions filter={filter} setFilter={setFilter} />
        </AppBox>
        <AppBox paddingX={2} minHeight={'inherit'}>
          <ReviewsListing filter={filter} setFilter={setFilter} />
        </AppBox>
      </AppBox>
    </>
  )
}
