import { Grid } from '@mui/material'

import { AppBox, AppImage, AppTypography } from 'components'
import { appRoutes } from 'globals/constants'
import { useNavigate } from 'react-router-dom'
import { SignUpForm } from './signup-form.tsx/SignUpForm'
import { SignUpPlans } from './signup-plans/SignUpPlans'

export const SignUp = () => {
  const navigate = useNavigate()
  const handleLoginClick = () => {
    navigate(appRoutes.login.path)
  }
  return (
    <Grid container height={'100vh'} sx={{ backgroundColor: 'grey.50' }}>
      <Grid item xs={12} md={6} paddingX={4} paddingY={2}>
        <AppImage src="/images/logo/logo-black.png" alt="nature" height={40} />
        <Grid container justifyContent={'center'} alignItems={'center'} paddingTop={5}>
          <Grid item xs={8}>
            <AppBox display={'flex'} gap={1} flexDirection={'column'}>
              <AppTypography fontSize={40} fontWeight={700} color={'grey.900'}>
                Sign up to start your free trial
              </AppTypography>
              <AppTypography fontSize={16}>
                Sign up for our 7-day free trial. No credit card required. To access an existing account, simply
                <AppTypography component={'a'} fontSize={16} isColorPrimary onClick={() => handleLoginClick()}>
                  {' '}
                  sign in.
                </AppTypography>
              </AppTypography>
              <SignUpForm />
            </AppBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <SignUpPlans />
      </Grid>
    </Grid>
  )
}
