import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect } from 'react'
import { AppBox } from '../box/AppBox'
import { AppPagination, AppTablePaginationProps } from '../pagination/AppPagination'
import { AppTypography } from 'components'
export interface AppTableColumn<T> extends Column<T> {}

export interface AppTableProps<T> extends AppTablePaginationProps {
  data: T[]
  showPagination?: boolean
  totalRecords?: number
  isLoading?: boolean
  subRowsKey?: string
  onRowSelectionChange?: (Ids: string[]) => void
  onSelectAllRowsChange?: (value: boolean) => void
}

export interface Props<T> extends AppTableProps<T> {
  columns: ColumnDef<any>[]
  alignColumns?: 'center' | undefined
}

export function AppTable<T>({
  data,
  columns,
  alignColumns,
  showPagination = true,
  paginationFilter,
  onPageSizeChange,
  onCurrentPageChange,
  subRowsKey,
  totalRecords,
  isLoading,
  onSelectAllRowsChange,
  onRowSelectionChange,
  ...props
}: Props<T>) {
  const [expanded, setExpanded] = React.useState<any>({})
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    manualPagination: true,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows ?? [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualExpanding: true,
    debugTable: true,
    columnResizeDirection: 'rtl',
    columnResizeMode: 'onChange',
  })

  const totalPages = () => {
    return Math.ceil(totalRecords! / paginationFilter!.PageSize!)
  }
  useEffect(() => {
    if (onSelectAllRowsChange) {
      onSelectAllRowsChange(table.getIsAllRowsSelected())
    }
  }, [table.getIsAllRowsSelected()])
  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(table.getSelectedRowModel().rows.map((row) => row.original.Id!))
    }
  }, [table.getSelectedRowModel()])

  useEffect(() => {
    table.getToggleAllRowsSelectedHandler()({ target: { checked: false } })
    if (onRowSelectionChange) {
      onRowSelectionChange(table.getSelectedRowModel().rows.map((row) => row.original.Id!))
    }
    if (onSelectAllRowsChange) {
      onSelectAllRowsChange(table.getIsAllRowsSelected())
    }
  }, [isLoading])

  return (
    <>
      <AppBox minHeight={'inherit'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        <TableContainer>
          <Table>
            <TableHead sx={{ borderRadius: '15px !important' }}>
              {table!.getHeaderGroups().map((headergroup) => (
                <TableRow key={headergroup.id}>
                  <>
                    {headergroup.headers.map((header, headerIndex) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{
                            border: 'none',
                            backgroundColor: 'grey.100',
                            textAlign: alignColumns,
                            padding: 1,
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <AppTypography color={'grey.500'}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </AppTypography>
                          )}
                        </TableCell>
                      )
                    })}
                  </>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      {table!.getHeaderGroups().map((headergroup, groupIndex) =>
                        headergroup.headers.map((header, headerIndex) => (
                          <TableCell
                            key={`${groupIndex}-${headerIndex}`}
                            colSpan={header.colSpan}
                            sx={{
                              border: 'none',
                              backgroundColor: 'grey.100',
                              textAlign: 'center',
                              padding: 1,
                            }}
                          >
                            <Skeleton variant={'text'} width={header.getSize()} height={40} />
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  ))
                : table.getRowModel().rows.map((row, index) => {
                    return (
                      <>
                        <TableRow key={row.id}>
                          <>
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id} sx={{ textAlign: alignColumns, padding: 1 }}>
                                <AppTypography>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </AppTypography>
                              </TableCell>
                            ))}
                          </>
                        </TableRow>
                        {row.getIsExpanded() && subRowsKey
                          ? row.original[subRowsKey].map((subRow: any) => (
                              <TableRow key={subRow.Id} sx={{ height: 20, backgroundColor: 'grey.50', padding: 0 }}>
                                <TableCell />

                                {Object.keys(subRow).map((key) => (
                                  <TableCell key={key}>{subRow[key]}</TableCell>
                                ))}
                              </TableRow>
                            ))
                          : null}
                      </>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && paginationFilter && !isLoading && (
          <AppPagination
            paginationFilter={paginationFilter}
            onPageSizeChange={onPageSizeChange}
            onCurrentPageChange={onCurrentPageChange}
            totalPages={totalPages()}
          />
        )}
      </AppBox>
    </>
  )
}
