import { FilterConditionTypes } from 'globals/Filter/FilterTypes'

export class FilterConditionSaveModel {
  public TableName: string | undefined = undefined
  public ColumnName: string | undefined = undefined
  public PreCondition: string | undefined = undefined
  public PreConditionField: string | undefined = undefined
  public Condition: FilterConditionTypes | undefined = undefined
  public Value: string | string[] | undefined = undefined
  // for Client side use only
  public Origin?: 'default' | 'principalType' | 'group' | undefined = undefined
  constructor(data?: Partial<FilterConditionSaveModel>) {
    Object.assign(this, data)
  }
}
