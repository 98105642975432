import { Divider, LinearProgress } from '@mui/material'
import { AppBox, AppButton, AppTypography } from 'components'
import { FC } from 'react'
import { Control, UseFormSetError, UseFormWatch } from 'react-hook-form'
import { CrmCompanyPayload } from 'sdk'
import { AddUpdateCrmCompanyForm } from '../../add-edit-company/AddUpdateCrmCompanyForm'
interface Props {
  companyId: string
  control: Control<CrmCompanyPayload>
  isGettingCompany: boolean
  isEditing: boolean
  setError: UseFormSetError<CrmCompanyPayload>
  watch: UseFormWatch<CrmCompanyPayload>
  editCompany: (data: { id: string; data: CrmCompanyPayload }) => void
}

export const CrmCompanyDetailInfo: FC<Props> = ({
  companyId,
  control,
  setError,
  watch,
  isGettingCompany,
  editCompany,
  isEditing,
}) => {
  return (
    <AppBox
      border={1}
      padding={2}
      paddingY={4}
      backgroundColor="white"
      borderColor={'grey.200'}
      borderRadius={6}
      id="Company"
    >
      <AppBox width={'100%'}>
        <AppTypography fontWeight={700} fontSize={'1rem'}>
          Company
        </AppTypography>
        <Divider sx={{ marginY: 2.5 }} />
        {isGettingCompany && <LinearProgress color="primary" />}
        <AddUpdateCrmCompanyForm control={control} setError={setError} watch={watch} />
        <AppBox display={'flex'} justifyContent={'flex-end'} gap={2}>
          <AppButton variant="text" color="secondary" size="large">
            Close
          </AppButton>
          <AppButton
            variant="contained"
            size="large"
            sidePaddings={5}
            isLoading={isEditing}
            onClick={() => editCompany({ id: companyId, data: watch() })}
          >
            Save
          </AppButton>
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
