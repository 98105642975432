import { Grid } from '@mui/material'
import { AppBox, AppDialog, AppTextField, AppTypography, SimpleSwitch } from 'components'
import { forwardRef } from 'react'
import { Control } from 'react-hook-form'

interface Props {
  control: Control<any>
}

export const ManageSubAccountDialog: React.ForwardRefExoticComponent<Props & React.RefAttributes<unknown>> = forwardRef(
  ({ control }: Props, ref) => {
    return (
      <AppDialog ref={ref} maxWidth={'md'} title="Manage Your ParkSitesIO User" renderElement={() => <></>}>
        <AppBox>
          <AppTypography color={'grey.500'} fontSize={14} fontWeight={500}>
            This user is a sub-account of your plan. Choose to remove them from your plan at the end of your current
            billing cycle, disable their login access, or both
          </AppTypography>
          <AppBox border={1} borderColor={'grey.200'} paddingX={2} paddingY={1} marginTop={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AppTextField disabled={true} control={control} name="Email" label="Email" />
              </Grid>
              <Grid item xs={6} textAlign={'center'}>
                <SimpleSwitch value={true} size="medium" />
              </Grid>
              <Grid item xs={6}>
                <AppTextField disabled={true} control={control} name="FirstName" label="First Name" />
              </Grid>
              <Grid item xs={6}>
                <AppTextField disabled={true} control={control} name="LastName" label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <AppTextField disabled={true} control={control} name="Address" label="Street Address" />
              </Grid>
              <Grid item xs={4}>
                <AppTextField disabled={true} control={control} name="City" label="City" />
              </Grid>
              <Grid item xs={4}>
                <AppTextField disabled={true} control={control} name="State" label="State" />
              </Grid>
              <Grid item xs={4}>
                <AppTextField disabled={true} control={control} name="Zip" label="Zip" />
              </Grid>
            </Grid>
          </AppBox>
        </AppBox>
      </AppDialog>
    )
  }
)
