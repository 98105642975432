import Tour, { StepType } from '@reactour/tour'
import { AppBox, AppButton } from 'components/base'
import { AppTourKeys, SettingsTypes } from 'globals/constants'
import { useSettings } from 'hooks'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShowAlert } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { CommonSliceSelector, setCurrentTourKey } from 'store/slices/common.slice'

interface Props {
  uniqueKey: AppTourKeys
  steps: StepType[]
}

export const AppTour: FC<Props> = ({ steps, uniqueKey }) => {
  const { UpdateSettings, loadDefaultSettings } = useSettings()
  const [currentStep, setCurrentTourStep] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const { Settings, isLoadingDefaultSettings, currentTourKey } = useSelector(CommonSliceSelector)
  const dispatch = useAppDispatch()

  const setting = () => Settings.find((x) => x.UniqueKey === SettingsTypes.TOUR_SETTINGS && x.Value === uniqueKey)

  const handleNextBtn = () => {
    if (currentStep === steps.length - 1) {
      setIsOpen(false)
      UpdateSettings([
        {
          Status: false,
          UniqueKey: SettingsTypes.TOUR_SETTINGS,
          Value: uniqueKey,
          AllowDuplicate: true,
        },
      ]).then(() => loadDefaultSettings())

      dispatch(setCurrentTourKey(undefined))
      setCurrentTourStep(0)
      return
    }
    setCurrentTourStep(currentStep + 1)
  }
  const nextBtnText = () => {
    if (currentStep === 0) {
      return 'Start Tour'
    }
    if (currentStep === steps.length - 1) {
      return 'Finish'
    }
    return 'Next'
  }
  const handleBackBtn = () => {
    if (currentStep === 0) {
      setIsOpen(false)
      UpdateSettings([
        {
          Status: false,
          UniqueKey: SettingsTypes.TOUR_SETTINGS,
          Value: uniqueKey,
          AllowDuplicate: true,
        },
      ]).then(() => loadDefaultSettings())
      dispatch(setCurrentTourKey(undefined))
      setCurrentTourStep(0)
      return
    }

    setCurrentTourStep(currentStep - 1)
  }
  return (
    <>
      {currentTourKey === uniqueKey && !!!setting() && !isLoadingDefaultSettings && (
        <Tour
          steps={steps}
          isOpen={isOpen}
          setIsOpen={() => setIsOpen(isOpen)}
          setCurrentStep={(step) => setCurrentTourStep(step)}
          currentStep={currentStep}
          disabledActions={false}
          setDisabledActions={() => {}}
          showNavigation={true}
          showBadge={false}
          // showDots={currentStep !== 0}
          showDots={false}
          disableDotsNavigation={true}
          position={'right'}
          showCloseButton={false}
          nextButton={() => (
            <AppBox display={'flex'} justifyContent={'flex-end'} gap={1}>
              {currentStep !== 1 && (
                <AppButton
                  variant="contained"
                  sidePaddings={4}
                  onClick={handleBackBtn}
                  style={{ backgroundColor: '#2563EB', color: 'white', border: '1px white solid' }}
                >
                  {currentStep === 0 ? 'Skip' : 'Previos'}
                </AppButton>
              )}
              <AppButton
                variant="contained"
                sidePaddings={4}
                onClick={handleNextBtn}
                style={{ backgroundColor: 'white', color: '#2563EB' }}
              >
                {nextBtnText()}
              </AppButton>
            </AppBox>
          )} // Custom text for Next button
          prevButton={() => (
            <>
              {currentStep > 0 && (
                <AppBox display={'flex'} gap={1}>
                  {steps.slice(1, steps.length).map((step, index) => (
                    <AppBox
                      key={index}
                      backgroundColor={currentStep - 1 >= index ? 'white' : 'grey.500'}
                      width={12}
                      height={12}
                      borderRadius={50}
                    />
                  ))}
                </AppBox>
              )}
            </>
          )}
          styles={{
            popover: (base) => ({
              ...base,
              backgroundColor: '#2563EB', // Define the background color here
              color: '#fff', // Define the text color here
              borderRadius: '4px', // Optional: Define border radius
              padding: '8px 16px', // Optional: Define padding
              border: 'none', // Optional: Remove border
              cursor: 'pointer', // Optional: Change cursor to pointer
              maxWidth: '25%', // Adjust the width here
              ...(steps[currentStep].selector === '.park-sites-dashboard-tour-step1' ? { marginTop: 15 } : {}),
            }),
            controls: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: { other: 'space-between', currentStep: 'flex-end' }[
                currentStep === 0 ? 'currentStep' : 'other'
              ],
              gap: '8px', // Optional: Add space between buttons
            }),
            maskArea: (base) => ({ ...base, ...(currentStep === 0 ? { height: '0px' } : {}) }),
          }}
        />
      )}
    </>
  )
}
