import { Grid } from '@mui/material'
import { AppBox, AppTextField, AppTypography, PhoneField, StateField, ZipField } from 'components'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { NewUserGettingStartedPayload } from 'sdk'

interface Props {
  control: Control<NewUserGettingStartedPayload>
}

export const GettingStatrtedPersonalInfo: FC<Props> = ({ control }) => {
  return (
    <AppBox paddingBottom={3}>
      <AppTypography fontSize={14} color={'grey.500'} paddingBottom={2}>
        Next, please complete the following personal information about yourself.
      </AppTypography>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={6}>
          <AppTextField control={control} required name="FirstName" placeholder="Enter First Name" label="First Name" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField control={control} required name="LastName" placeholder="Enter Last Name" label="Last Name" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField control={control} required name="Address" placeholder="Enter Address" label="Address" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField control={control} name="Address2" placeholder="Enter Address2" label="Address (2)" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField control={control} required name="City" placeholder="Enter City" label="City" />
        </Grid>
        <Grid item xs={6}>
          <StateField control={control} required name="StateId" placeholder="Select State" label="State" />
        </Grid>
        <Grid item xs={6}>
          <ZipField control={control} required name="Zip" placeholder="Enter Zip" label="Zip" />
        </Grid>
        <Grid item xs={6}>
          <PhoneField control={control} name="Phone" placeholder="Enter Phone" label="Phone" />
        </Grid>
      </Grid>
    </AppBox>
  )
}
