import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'
import { ZapierViewModel } from 'sdk/models'

export class ZapierApi extends BaseApi {
  public generateApiKey(userId: string) {
    return this.GET_Request<boolean>(apiUrls.zapier.generateApiKey(userId))
  }
  public GetZapierInfo(userId: string) {
    return this.GET_Request<ZapierViewModel>(apiUrls.zapier.GetZapierInfo(userId))
  }
}
