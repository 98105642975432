import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RoleTypes } from 'globals/constants'
import { SessionModel, UserMembershipViewModel } from 'sdk'
import { IAppStore } from 'store/app.store'

export interface AuthState {
  CurrentUser: SessionModel | null
  UserId: string
  Memberships: Array<UserMembershipViewModel>
  TrialExpiry: number | null
  Roles: RoleTypes[]
  OwnerId?: string
}

const initialState: AuthState = {
  CurrentUser: null,
  UserId: '',
  Memberships: [],
  TrialExpiry: null,
  Roles: [],
  OwnerId: '',
}

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState: initialState,
  reducers: {
    setSession: (state: AuthState, action: PayloadAction<SessionModel | null>) => {
      state.CurrentUser = action.payload
    },
    setUserId: (state, action) => {
      state.UserId = action.payload
    },
    setMemberships: (state, action) => {
      state.Memberships = action.payload
    },
    setTrialExpiry: (state, action: PayloadAction<number>) => {
      state.TrialExpiry = action.payload
    },
    setRoles: (state, action: PayloadAction<RoleTypes[]>) => {
      state.Roles = action.payload
    },
    setOwnerId: (state, action: PayloadAction<string>) => {
      state.OwnerId = action.payload
    },
    clearSession: (state: AuthState) => {
      state.CurrentUser = initialState.CurrentUser
      state.UserId = initialState.UserId
      state.Memberships = initialState.Memberships
      state.TrialExpiry = initialState.TrialExpiry
      state.Roles = initialState.Roles
      state.OwnerId = initialState.OwnerId
    },
  },
})

export const { setSession, clearSession, setUserId, setMemberships, setTrialExpiry, setRoles, setOwnerId } =
  AuthSlice.actions
export const AuthSliceSelector = (appState: IAppStore): AuthState => appState.Auth
