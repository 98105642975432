import { AppBox, AppButton } from 'components'
import { FC } from 'react'
import { AppStore } from 'store/app.store'
import { setSelectedParkIdToMove } from 'store/slices'
interface Props {
  marker: google.maps.Marker
}
export const ParkSiteGoogleMapsAdminDraggableMarkerInfoxBox: FC<Props> = ({ marker }) => {
  const handleClose = () => {
    AppStore.dispatch(setSelectedParkIdToMove(undefined))
  }
  return (
    <AppBox display={'flex'} flexDirection={'column'}>
      <AppButton onClick={() => handleClose()} suffixIcon="mdi:cross-circle">
        Close
      </AppButton>
    </AppBox>
  )
}
