import { BoxProps } from '@mui/material'
import { AppBox } from '../../base'

interface AbsoluteBoxProps extends BoxProps {}
export const AbsoluteBox: React.FC<AbsoluteBoxProps> = ({ width, children, ...rest }) => {
  return (
    <AppBox position={'absolute'} backgroundColor="white" top={0} bottom={0} left={0} zIndex={999} {...rest}>
      {children}
    </AppBox>
  )
}
