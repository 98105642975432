import { Grid } from '@mui/material'
import { AppBox, AppButton, AppDialog, AppDialogRefType, SimpleField } from 'components'
import { useCounties } from 'hooks'
import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { CountyPayload, PaginationFilterModel } from 'sdk'
import { AddeditCountyForm } from '../content/counties/add-edit-county-form/AddEditCountyForm'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
  onNewCounty: () => void
}
export const CountryLinksActions: FC<Props> = ({ filter, setFilter, onNewCounty }) => {
  const { control, handleSubmit, getValues, reset } = useForm<CountyPayload>()
  const dialogRef = useRef<AppDialogRefType>(null)
  const { AddCounty, isAddingCounty } = useCounties({})

  return (
    <>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item md={2} xs={4}>
          <SimpleField
            prefixIcon="iconamoon:search-bold"
            placeholder="Search Counties"
            value={filter.Query ?? ''}
            onChange={(event) => {
              setFilter((prev) => ({ ...prev, Query: event.target.value }))
            }}
          />
        </Grid>
        <Grid item>
          <AppDialog
            maxWidth="lg"
            ref={dialogRef}
            title="Add County"
            appDialogWrapperProps={{
              component: 'form',
              role: 'form',
              onSubmit: handleSubmit(() => {
                AddCounty(getValues())
                  .then((x) => {
                    dialogRef.current?.closeDialog!()
                    onNewCounty()
                    reset()
                  })
                  .catch((err) => {})
              }),
            }}
            submitButtonProps={{ isLoading: isAddingCounty }}
            renderElement={(openDialog) => (
              <AppButton prefixIcon="icon-park-solid:add" color="secondary" onClick={openDialog}>
                Add New
              </AppButton>
            )}
            renderChild={(openDialog) => <AddeditCountyForm control={control} />}
          />
        </Grid>
      </Grid>
    </>
  )
}
