import { Components, Theme } from '@mui/material'
import { palette } from './colorPallete'

export const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '12px',
        fontWeight: 600,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
        letterSpacing: '0.2px',
        fontSize: 12,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: palette.grey[100],
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
  },
}
