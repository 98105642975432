import { ColumnDef, Row } from '@tanstack/react-table'
import {
  AppBox,
  AppIcon,
  AppTable,
  AppTableProps,
  AppTypography,
  EmailText,
  PhoneText,
  SimpleCheckBox,
} from 'components'
import { CrmHelpModules, appRoutes } from 'globals/constants'
import { useNavigate } from 'react-router-dom'
import { CrmContactViewModel } from 'sdk/models/crm/contacts/crm-contact.viewmodel'
import { useAppDispatch } from 'store/app.store'
import { setCrmListingHelpModule } from 'store/slices'

interface Props extends AppTableProps<CrmContactViewModel> {}
export const CrmContactTable = (props: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigateToDetailRoute = (id: string) => {
    navigate(appRoutes.crmContactDetail.path.replace(':contactId', id))
  }
  const columns: ColumnDef<CrmContactViewModel>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <SimpleCheckBox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.getToggleAllRowsSelectedHandler()(e)
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <SimpleCheckBox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: (e) => {
                row.getToggleSelectedHandler()(e)
              },
            }}
          />
        </div>
      ),
    },
    {
      header: 'Type',
      cell: ({ row }) => {
        return (
          <AppTypography onClick={() => navigateToDetailRoute(row.original.Id!)} component={'a'}>
            {row.original.ContactType}
          </AppTypography>
        )
      },
    },
    {
      header: 'Name',
      cell: ({ row }) => {
        return (
          <AppTypography onClick={() => navigateToDetailRoute(row.original.Id!)} component={'a'}>
            {!!row.original.ContactFirstName || !!row.original.ContactLastName
              ? (row.original.ContactFirstName ?? '') + ' ' + (row.original.ContactLastName ?? '')
              : 'N/A'}
          </AppTypography>
        )
      },
    },
    {
      header: 'Address',
      accessorKey: 'Address',
      cell: ({ row }) => {
        return (
          <AppTypography onClick={() => navigateToDetailRoute(row.original.Id!)} color={'primary.200'}>
            {row.original.ContactAddress}
          </AppTypography>
        )
      },
    },
    {
      header: 'Email',
      accessorKey: 'Email',
      cell: ({ row }) => {
        return <EmailText email={row.original.ContactEmail ?? ''} />
      },
    },
    {
      header: 'Phone',
      accessorKey: 'Phone',
      cell: ({ row }) => {
        return <PhoneText phone={row.original.ContactPhone ?? ''} />
      },
    },
    {
      header: 'Alt Phone',
      accessorKey: 'AltPhone',
      cell: ({ row }) => {
        return <PhoneText phone={row.original.ContactAltPhone ?? ''} />
      },
    },

    ...[
      { header: 'Parks', icon: 'gis:home', helpModule: CrmHelpModules.ContactParks },
      { header: 'Companies', icon: 'mdi:company', helpModule: CrmHelpModules.ContactLinkedCompanies },
      { header: 'Notes', icon: 'icon-park-solid:notes', helpModule: CrmHelpModules.ContactNotes },
      { header: 'Tags', icon: 'bi:tags-fill', helpModule: CrmHelpModules.ContactTags },
    ].map((x) => {
      return {
        header: x.header,
        cell: ({ row }: { row: Row<CrmContactViewModel> }) => {
          return (
            <>
              <AppBox color={'primary.200'}>
                <AppIcon
                  icon={x.icon}
                  size="medium"
                  onClick={() =>
                    dispatch(
                      setCrmListingHelpModule({
                        key: x.helpModule,
                        options: {
                          Title:
                            !!row.original.ContactFirstName || !!row.original.ContactLastName
                              ? (row.original.ContactFirstName ?? '') + ' ' + (row.original.ContactLastName ?? '')
                              : 'N/A',
                          Id: row.original.Id!,
                        },
                      })
                    )
                  }
                />
              </AppBox>
            </>
          )
        },
      }
    }),
  ]
  return <AppTable columns={columns} {...props} alignColumns="center" />
}
