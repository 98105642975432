import { appRoutes, UserStatuses } from 'globals/constants'
import { useMembershipHelper, useRolesHelper } from 'hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthorizeProps } from 'sdk'
import { AuthSliceSelector } from 'store/slices'

interface GuardedRouteProps extends AuthorizeProps {
  isAuthRoutes?: boolean
  children?: React.ReactNode
}

export const GuardedRoute: React.FC<GuardedRouteProps> = ({
  children,
  isAuthRoutes,
  allowedToPaidUsers,
  allowedToAdmin,
  allowedToPaidUsersAndAdmin,
  ...props
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { CurrentUser } = useSelector(AuthSliceSelector)
  const { IsPaidMember } = useMembershipHelper()
  const { IsTrialUser } = useRolesHelper()
  const { IsAdmin } = useRolesHelper()

  useEffect(() => {
    if (!!!CurrentUser) {
      if (isAuthRoutes === false) {
        navigate(appRoutes.login.path)
      }
    } else if (!!CurrentUser) {
      if (isAuthRoutes) {
        navigate(appRoutes.dashboard.path)
      } else if (CurrentUser?.Status != null && CurrentUser?.Status !== UserStatuses.COMPLETED) {
        navigate(appRoutes.dashboard.path)
      } else if (allowedToPaidUsersAndAdmin) {
        if (!IsPaidMember() && !IsTrialUser() && !IsAdmin()) {
          navigate(appRoutes.unauthorized.path)
        }
      } else if (allowedToPaidUsers) {
        if (!IsPaidMember() && !IsTrialUser()) {
          navigate(appRoutes.unauthorized.path)
        }
      } else if (allowedToAdmin) {
        if (!IsAdmin()) {
          navigate(appRoutes.unauthorized.path)
        }
      } else if (location.pathname === appRoutes.account.path) {
        navigate(appRoutes.dashboard.path)
      } else if (location.pathname === appRoutes.dashboard.path) {
        if (!!!CurrentUser?.Status || CurrentUser?.Status === UserStatuses.COMPLETED) {
          navigate(appRoutes.parksites.path)
        }
      }
    }
  }, [location.pathname])

  return <>{children}</>
}
