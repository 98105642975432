import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'
import { AddRecoveryEmail, AddSubAccountPayload, SubAccountViewModel, UserViewModel } from 'sdk/models'

export class AccountApi extends BaseApi {
  public getUserByEmail(email: string) {
    return this.GET_Request<UserViewModel>(apiUrls.account.getUserByEmail(email))
  }
  public addSubAccount(payload: AddSubAccountPayload) {
    return this.POST_Request(apiUrls.account.addSubAccounts(), payload)
  }
  public getUserSubAccounts(userId?: string) {
    return this.GET_Request<SubAccountViewModel[]>(apiUrls.account.getUserSubAccounts(userId))
  }
  public addRecoveryEmail(payload: AddRecoveryEmail) {
    return this.POST_Request<string>(apiUrls.account.addRecoveryEmail(), payload)
  }
}
