import { TagTypes } from 'globals/constants'

export class TagPayloadModel {
  public Id: string | null = null
  public Tag: string | null = null
  public TagType: TagTypes | null = null
  public constructor(init?: Partial<TagPayloadModel>) {
    Object.assign(this, init)
  }
}
