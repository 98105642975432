import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { AppGoogleMapUniqueKeys } from 'globals/constants'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { GoogleMapSliceSelector, ParkSiteToolSliceSelector } from 'store/slices'
import { ParkLabelsliceSelector } from 'store/slices/park-labels.slice'
import Supercluster from 'supercluster'

export interface DraggableMarkerProps {
  onDraggableMarkerRightClick?: (marker: google.maps.Marker) => void
  onDraggableMarkerDragEnd?: (marker: google.maps.Marker, newPosition: google.maps.LatLng) => void
}

interface Props extends DraggableMarkerProps {
  superCluster: Supercluster | null
}

export const useClusterWithMarkers = ({
  superCluster,
  onDraggableMarkerRightClick,
  onDraggableMarkerDragEnd,
}: Props) => {
  const { LabelledParks } = useSelector(ParkLabelsliceSelector)
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { ParkPins, SelectedParkIdToMove } = useSelector(GoogleMapSliceSelector)
  const map = useMap(AppGoogleMapUniqueKeys.ParkSitesMap)
  const markerLib = useMapsLibrary('marker')
  const visibleMarkers = useRef<Array<google.maps.Marker>>([])
  const draggableMarker = useRef<google.maps.Marker | null>(null)
  // const [visibleMarkers, setVisibleMarkers] = useState<Array<google.maps.Marker>>([])
  // const [draggableMarker, setDraggableMarker] = useState<google.maps.Marker | null>(null)

  const getClusterColor = (
    pin: Supercluster.PointFeature<Supercluster.AnyProps> | Supercluster.ClusterFeature<Supercluster.AnyProps>
  ) => {
    const favColor = LabelledParks.find((x) => x.ParkId === pin.properties.I)
    if (favColor) {
      return favColor.Color
    } else if (pin.properties.S === 'N') {
      return 'black'
    }
    return '#23d164'
  }

  const getStrokeColor = (
    pin: Supercluster.PointFeature<Supercluster.AnyProps> | Supercluster.ClusterFeature<Supercluster.AnyProps>
  ) => {
    const favColor = LabelledParks.find((x) => x.ParkId === pin.properties.I)
    if (favColor) {
      return favColor.Color
    } else if (pin.properties.S === 'N') {
      return 'black'
    }
    return '#23d164'
  }

  const getMarkerColor = (
    pin: Supercluster.PointFeature<Supercluster.AnyProps> | Supercluster.ClusterFeature<Supercluster.AnyProps>
  ) => {
    const favColor = LabelledParks.find((x) => x.ParkId === pin.properties.I)
    if (favColor?.Color) {
      return favColor?.Color
    } else if (pin.properties.S === 'N') {
      return 'black'
    }
    return '#24D164'
  }

  const getMarkerIcon = (
    pin: Supercluster.PointFeature<Supercluster.AnyProps> | Supercluster.ClusterFeature<Supercluster.AnyProps> | null,
    isDraggableMarker?: boolean
  ) => {
    if (pin?.properties.I === selectedParkId) {
      return (
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(
          `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M79 40C79 61.5391 61.5391 79 40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40Z" fill="#D9D9D9" fill-opacity="0.3" stroke="white" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 61.32C40 61.32 58 49.5055 58 35.7218C58 31.0217 56.1036 26.5141 52.7279 23.1906C49.3523 19.8671 44.7739 18 40 18C35.2261 18 30.6477 19.8671 27.2721 23.1906C23.8964 26.5141 22 31.0217 22 35.7218C22 49.5055 40 61.32 40 61.32ZM45.8291 35.1001C45.8291 38.2481 43.3107 40.8001 40.2041 40.8001C37.0975 40.8001 34.5791 38.2481 34.5791 35.1001C34.5791 31.952 37.0975 29.4001 40.2041 29.4001C43.3107 29.4001 45.8291 31.952 45.8291 35.1001Z" fill="${getMarkerColor(pin)}"/>
        </svg>`
        )
      )
    } else if (isDraggableMarker) {
      return (
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(
          `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M40 61.32C40 61.32 58 49.5055 58 35.7218C58 31.0217 56.1036 26.5141 52.7279 23.1906C49.3523 19.8671 44.7739 18 40 18C35.2261 18 30.6477 19.8671 27.2721 23.1906C23.8964 26.5141 22 31.0217 22 35.7218C22 49.5055 40 61.32 40 61.32ZM45.8291 35.1001C45.8291 38.2481 43.3107 40.8001 40.2041 40.8001C37.0975 40.8001 34.5791 38.2481 34.5791 35.1001C34.5791 31.952 37.0975 29.4001 40.2041 29.4001C43.3107 29.4001 45.8291 31.952 45.8291 35.1001Z" fill="purple"/>
              </svg>`
        )
      )
    }
    return (
      'data:image/svg+xml;charset=utf-8,' +
      encodeURIComponent(
        `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M40 61.32C40 61.32 58 49.5055 58 35.7218C58 31.0217 56.1036 26.5141 52.7279 23.1906C49.3523 19.8671 44.7739 18 40 18C35.2261 18 30.6477 19.8671 27.2721 23.1906C23.8964 26.5141 22 31.0217 22 35.7218C22 49.5055 40 61.32 40 61.32ZM45.8291 35.1001C45.8291 38.2481 43.3107 40.8001 40.2041 40.8001C37.0975 40.8001 34.5791 38.2481 34.5791 35.1001C34.5791 31.952 37.0975 29.4001 40.2041 29.4001C43.3107 29.4001 45.8291 31.952 45.8291 35.1001Z" fill="${getMarkerColor(pin!)}"/>
    </svg>`
      )
    )
  }

  const showClusterData = (
    onMarkerClick: (parkId: string, marker: google.maps.Marker) => void,
    onMarkerRightClick?: (parkId: string, marker: google.maps.Marker) => void
  ) => {
    if (map && markerLib && superCluster) {
      hideAllMarkers()
      if (SelectedParkIdToMove) {
        showDraggableMarker()
        return
      }
      const bounds = map.getBounds()!
      const clusters = superCluster.getClusters(
        [
          bounds.getSouthWest().lng(),
          bounds.getSouthWest().lat(),
          bounds.getNorthEast().lng(),
          bounds.getNorthEast().lat(),
        ],
        map.getZoom() ?? 8
      )
      const newMarkers: google.maps.Marker[] = []
      for (let i = 0; i < clusters?.length; i++) {
        const pin = clusters[i]
        let newMarker: google.maps.Marker | null = null

        if (pin.properties.cluster) {
          newMarker = new markerLib!.Marker({
            label: {
              text: `${pin.properties.point_count_abbreviated}`,
              color: '#fff',
              className: 'clustor-label',
            },
            position: new google.maps.LatLng(pin.geometry.coordinates[1], pin.geometry.coordinates[0]),
            icon: {
              url:
                'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(
                  `<svg viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg"><circle cx="110" cy="110" r="100" stroke="black" fill="${'#23d164'}" fill-opacity="1.0" stroke-width="1" /></svg>`
                ),
              size: new google.maps.Size(46, 46),
              scaledSize: new google.maps.Size(38, 38),
              anchor: new google.maps.Point(23, 23),
              labelOrigin: new google.maps.Point(19, 19),
            },
            map: map,
          })
          newMarker.addListener('click', (event: google.maps.MapMouseEvent) => {
            if (event.latLng?.lat() && event.latLng?.lng()) {
              map.panTo(new google.maps.LatLng(event.latLng?.lat(), event.latLng?.lng()))

              if (map.getZoom()) {
                map.setZoom(superCluster.getClusterExpansionZoom(pin.properties.cluster_id))
              }
            }
          })
        } else {
          newMarker = new markerLib!.Marker({
            icon: {
              url: getMarkerIcon(pin),
              strokeColor: getStrokeColor(pin),
              fillColor: getClusterColor(pin),
              fillOpacity: 1,
              strokeWeight: 1,
              strokeOpacity: 1,
              scale: 1,
            },
            position: new google.maps.LatLng(pin.geometry.coordinates[1]!, pin.geometry.coordinates[0]!),
            map: map,
          })

          newMarker.set('parkid', pin.properties.I)
          newMarker.addListener('click', (event: google.maps.MapMouseEvent) => {
            onMarkerClick(pin.properties.I, newMarker!)
          })
          if (onMarkerRightClick) {
            newMarker.addListener('rightclick', (event: google.maps.MapMouseEvent) => {
              onMarkerRightClick(pin.properties.I, newMarker!)
            })
          }
        }
        newMarker.set('uniqueid', pin.properties.I)
        newMarkers.push(newMarker)
      }
      // setVisibleMarkers(newMarkers)
      visibleMarkers.current = newMarkers
    }
  }

  const showDraggableMarker = () => {
    hideDraggableMarker()
    if (map && markerLib) {
      const selectedPark = ParkPins.find((x) => x.I === SelectedParkIdToMove)
      if (selectedPark) {
        const newMarker = new markerLib!.Marker({
          icon: {
            url: getMarkerIcon(null, true),
            strokeColor: 'purple',
            fillColor: 'purple',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeOpacity: 1,
            scale: 1,
          },
          position: new google.maps.LatLng(selectedPark.L!, selectedPark.N!),
          map: map,
          draggable: true,
        })

        newMarker.set('uniqueid', 'draggable-marker')
        newMarker.addListener('rightclick', () => {
          if (onDraggableMarkerRightClick) {
            onDraggableMarkerRightClick(newMarker)
          }
        })
        newMarker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
          if (onDraggableMarkerDragEnd) {
            onDraggableMarkerDragEnd(newMarker, event.latLng!)
          }
        })
        // setDraggableMarker(newMarker)
        draggableMarker.current = newMarker
      }
    }
  }

  const hideVisibleMarkers = () => {
    // if (visibleMarkers?.length > 0) {
    //   for (let i = 0; i < visibleMarkers.length; i++) {
    //     visibleMarkers[i].setMap(null)
    //   }
    // }
    // setVisibleMarkers([])
    // Clear old markers
    visibleMarkers.current.forEach((marker) => marker.setMap(null))
    visibleMarkers.current = []
  }
  const hideDraggableMarker = () => {
    if (draggableMarker && draggableMarker.current?.get('uniqueid') === 'draggable-marker') {
      // draggableMarker.setMap(null)
      // setDraggableMarker(null)
      draggableMarker.current.setMap(null)
      draggableMarker.current = null
    }
  }
  const hideAllMarkers = () => {
    hideVisibleMarkers()
    hideDraggableMarker()
  }

  return { showClusterData }
}
