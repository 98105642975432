import { AppBox, AppButton } from 'components/base'
import { FilterCategory, FilterField } from 'globals/Filter/FilterTypes'
import { FILTER_PRINCIPAL_TYPES } from 'globals/constants'
import { useFilterBuilder } from 'hooks/filter/filter-builder.hook'
import { FC, useEffect, useMemo, useState } from 'react'
import { FilterConditionSaveModel } from 'sdk'
import { RenderFilterField } from './RenderFilterValueField'
import { SelectFilterCondition } from './SelectFilterCondition'
import { SelectFilterField } from './SelectFilterFiled'

interface Props {
  type: FILTER_PRINCIPAL_TYPES
  addedFilters: FilterConditionSaveModel[]
  categories: FilterCategory[]
  closeMenu: () => void
  onSaveNewFilter: (newFilter: FilterConditionSaveModel) => void
  filterToUpdate?: FilterConditionSaveModel
  updateFilterStep?: 'condition' | 'value'
  fieldToAdd?: FilterField
}

export const AddFilterMenu: FC<Props> = ({
  type,
  addedFilters,
  categories,
  onSaveNewFilter,
  closeMenu,
  filterToUpdate,
  fieldToAdd,
  updateFilterStep,
}) => {
  const { fieldByCoumnAndTableName, conditionByColumnAndTableNameAndCondition } = useFilterBuilder({
    type,
    preventFiltersUpdate: true,
  })
  useEffect(() => {
    if (filterToUpdate) {
      setNewFilter(filterToUpdate)
      if (updateFilterStep === 'condition') {
        setStep(1)
      } else if (updateFilterStep === 'value') {
        setStep(2)
      }
    } else if (fieldToAdd) {
      if (!newFilter?.Condition) {
        const defaultCondition = fieldToAdd.Conditions?.find((x) => x.IsDefaultCondition)
        if (!!defaultCondition) {
          setNewFilter((prev: FilterConditionSaveModel | undefined) => {
            return {
              ...prev,
              Condition: defaultCondition.Condition,
              ColumnName: fieldToAdd.ColumnName,
              TableName: fieldToAdd.TableName,
            } as FilterConditionSaveModel
          })
          setStep((prev) => prev + 2)
          return
        }
      }
      setNewFilter({
        ColumnName: fieldToAdd.ColumnName,
        TableName: fieldToAdd.TableName,
      } as FilterConditionSaveModel)
      setStep(1)
    }
  }, [])
  const [newFilter, setNewFilter] = useState<FilterConditionSaveModel>()
  const [step, setStep] = useState(0)

  const backBtnLabel = () => {
    if (step === 0) {
      return 'Close'
    }
    return 'Back'
  }
  const nextBtnLabel = () => {
    if (step === 2 || step > 2) {
      return 'Apply'
    }
    return 'Select'
  }
  const handleBackClick = () => {
    if (step === 0) {
      closeMenu()
      return
    }
    setStep((prev) => prev - 1)
  }
  const handleFieldSelect = (columnName: string, tableName: string) => {
    setNewFilter((prev: FilterConditionSaveModel | undefined) => {
      return { ...prev, ColumnName: columnName, TableName: tableName } as FilterConditionSaveModel
    })
  }
  const handleValueChange = (value: string | string[]) => {
    setNewFilter((prev: FilterConditionSaveModel | undefined) => {
      return { ...prev, Value: value } as FilterConditionSaveModel
    })
  }
  const handleNextClick = () => {
    if (step === 0) {
      const fieldConditions = fieldByCoumnAndTableName(newFilter?.ColumnName!, newFilter?.TableName!)?.Conditions
      const defaultCondition = fieldConditions?.find((x) => x.IsDefaultCondition)
      if (!!defaultCondition) {
        setNewFilter((prev: FilterConditionSaveModel | undefined) => {
          return { ...prev, Condition: defaultCondition.Condition } as FilterConditionSaveModel
        })
        setStep((prev) => prev + 2)
        return
      }
    } else if (step === 1) {
      const condition = conditionByColumnAndTableNameAndCondition(
        newFilter?.ColumnName!,
        newFilter?.TableName!,
        newFilter?.Condition!
      )
      if (!condition?.InputField) {
        closeMenu()
        onSaveNewFilter(newFilter!)
        return
      }
      setStep((prev) => prev + 1)
      return
    } else if (step === 2) {
      if (!!newFilter?.PreCondition) {
        setStep((prev) => prev + 1)
        return
      }
      closeMenu()
      onSaveNewFilter(newFilter!)
      return
    }
    if (step === 3) {
      closeMenu()
      onSaveNewFilter(newFilter!)
      return
    }
    setStep((prev) => prev + 1)
  }
  const isNextDisabled = () => {
    if (step === 0) {
      return !newFilter?.ColumnName
    } else if (step === 1) {
      return !newFilter?.Condition
    } else if (step === 2) {
      const field = fieldByCoumnAndTableName(newFilter?.ColumnName!, newFilter?.TableName!)
      return !!field?.PreConditionField ? !newFilter?.PreCondition : !newFilter?.Value
    } else if (step === 3) {
      return !newFilter?.Value
    }
    return false
  }

  const renderComp = () => {
    if (step === 0) {
      return (
        <SelectFilterField
          filterCategories={categories}
          addedFilters={addedFilters}
          onFieldSelect={handleFieldSelect}
          selectedFilter={{ columnName: newFilter?.ColumnName!, tableName: newFilter?.TableName! }}
          type={type}
        />
      )
    } else if (step === 1) {
      return (
        <SelectFilterCondition
          conditions={fieldByCoumnAndTableName(newFilter?.ColumnName!, newFilter?.TableName!)?.Conditions ?? []}
          selectedCondition={newFilter?.Condition ?? ''}
          onConditionChange={(conditionId: string) => {
            setNewFilter((prev: FilterConditionSaveModel | undefined) => {
              return { ...prev, Condition: conditionId, Value: undefined } as FilterConditionSaveModel
            })
          }}
        />
      )
    } else if (step == 2) {
      const field = fieldByCoumnAndTableName(newFilter?.ColumnName!, newFilter?.TableName!)
      if (field?.PreConditionFieldColumnName) {
        const preConditionField = fieldByCoumnAndTableName(
          field?.PreConditionFieldColumnName!,
          field?.PreConditionFieldTableName!
        )
        if (preConditionField?.Conditions) {
          const preCondition = preConditionField.Conditions.find((x) => x.IsDefaultCondition)
          if (preCondition) {
            return (
              <RenderFilterField
                condition={preCondition}
                singleValueOnly={true}
                filter={filterToUpdate}
                onChange={(value) => {
                  setNewFilter((prev: FilterConditionSaveModel | undefined) => {
                    return { ...prev, PreCondition: value } as FilterConditionSaveModel
                  })
                }}
                type={type}
              />
            )
          }
        }
      }
      return (
        <RenderFilterField
          condition={conditionByColumnAndTableNameAndCondition(
            newFilter?.ColumnName!,
            newFilter?.TableName!,
            newFilter?.Condition!
          )}
          onChange={handleValueChange}
          filter={filterToUpdate}
          type={type}
        />
      )
    } else if (step === 3) {
      return (
        <RenderFilterField
          condition={conditionByColumnAndTableNameAndCondition(
            newFilter?.ColumnName!,
            newFilter?.TableName!,
            newFilter?.Condition!
          )}
          onChange={handleValueChange}
          preConditionValue={newFilter?.PreCondition}
          filter={filterToUpdate}
          type={type}
        />
      )
    }
    return <></>
  }
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={1}>
      <AppBox>{renderComp()}</AppBox>
      <AppBox display={'flex'} justifyContent={'flex-end'} gap={1} borderTop={1} borderColor={'grey.300'} padding={1}>
        {!fieldToAdd && !filterToUpdate && (
          <AppButton variant="contained" size="small" onClick={handleBackClick}>
            {backBtnLabel()}
          </AppButton>
        )}
        <AppButton variant="contained" size="small" onClick={handleNextClick} disabled={isNextDisabled()}>
          {nextBtnLabel()}
        </AppButton>
      </AppBox>
    </AppBox>
  )
}
