import { Grid } from '@mui/material'
import { AppAutoComplete, AppTextField, AppTypography } from 'components'
import { UserPrimaryRolesOptions } from 'globals/constants'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { NewUserGettingStartedPayload } from 'sdk'

interface Props {
  control: Control<NewUserGettingStartedPayload>
}

export const GettingStartedRole: FC<Props> = ({ control }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <AppTypography fontSize={16} fontWeight={400} color={'grey.500'} paddingBottom={2}>
            Thanks for creating an account and giving us a shot! We’re excited to become a part of your mobile home park
            journey.
          </AppTypography>
          <AppTypography fontSize={16} fontWeight={400} color={'grey.500'} paddingBottom={2}>
            Our goal is to make our system as simple as possible. However, anytime you need extra help, just click your
            profile image at the top left of your dashboard and explore the options within the Need Help? section
          </AppTypography>
          <AppTypography fontSize={16} fontWeight={400} color={'grey.500'} paddingBottom={2}>
            Let's get you started start by finishing your profile before you get to the good stuff. Then we’ll correct
            one common misconception circulating in the mobile home park industry. After that, we’ll let you get on your
            way
          </AppTypography>
          <AppTypography fontSize={16} fontWeight={400} color={'grey.500'} paddingBottom={2}>
            First, what is your company’s name and primary role in the mobile home park industry?
          </AppTypography>
        </Grid>
        <Grid item xs={3} textAlign={'center'}>
          <img src="/images/dashboard/getting-started/getting-started-step-one.png" alt="Getting Started Step One" />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <AppTextField control={control} name="CompanyName" placeholder="Company Name" label="Company Name" />
        </Grid>
        <Grid item xs={4}>
          <AppAutoComplete
            required
            control={control}
            placeholder="Select Role"
            name="Role"
            label="Primary Role"
            options={UserPrimaryRolesOptions}
          />
        </Grid>
      </Grid>
    </>
  )
}
