import { AppBox, AppIcon, AppTypography } from 'components'
import { ParkSitesTools } from 'globals/constants'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { ParkSiteMapPin } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

interface Props {
  currentHeight: string
  isVisible: boolean
  currentMarkers: ParkSiteMapPin[]
  onCloseButtonClick: () => void
}
export const CollapsedParkSitesSideTool: FC<Props> = ({
  currentHeight,
  onCloseButtonClick,
  isVisible,
  currentMarkers,
}) => {
  const links: { [key in ParkSitesTools]: string } = {
    [ParkSitesTools.PinnedParks]: 'Pinned Parks',
    [ParkSitesTools.TaggedParks]: 'Tagged Parks',
    [ParkSitesTools.CRM]: 'CRM Parks',
  }

  const { openedTool } = useSelector(ParkSiteToolSliceSelector)

  return (
    <AppBox
      backgroundColor="primary.200"
      minHeight={currentHeight}
      textAlign={'center'}
      cursor="pointer"
      onClick={onCloseButtonClick}
    >
      <AppIcon size="large" icon="ri:arrow-left-double-line" color="#FFFFFF" style={{ marginTop: '2vh' }} />
      <AppBox alignContent={'center'} height={'76vh'} cursor="pointer">
        <AppTypography
          color={'white'}
          component={'p'}
          fontSize={14}
          fontWeight={'bold'}
          sx={{
            cursor: 'pointer',
            transform: 'translateY(-50%) rotate(-90deg)', // Center vertically and rotate
            whiteSpace: 'nowrap', // Prevents the text from breaking into multiple lines
            textAlign: 'center', // Aligns text properly after rotation
          }}
        >
          {links[openedTool!]} ({currentMarkers.length ?? 0})
        </AppTypography>
      </AppBox>
    </AppBox>
  )
}
