import { Grid } from '@mui/material'
import { AppBox, AppTypography } from 'components'
import { FC } from 'react'
import { ParkDetailViewModel } from 'sdk'

interface Props {
  parkDetail: ParkDetailViewModel
}

export const ParkDetailBasics: FC<Props> = ({ parkDetail }) => {
  return (
    <AppBox backgroundColor="grey.100" padding={2} borderRadius={3} border={1} borderColor={'grey.200'}>
      <AppTypography color={'grey.900'} fontWeight={700} fontSize={16}>
        Basics
      </AppTypography>
      <AppBox paddingTop={1}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Permit #:
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {parkDetail.ParkInfo?.PermitNo ?? '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Property Tax Year
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {!!parkDetail.ParkInfo.PropertyTaxYear ? parkDetail.ParkInfo.PropertyTaxYear : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Total Acres +/-:
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {!!parkDetail.ParkInfo.TotalAcres ? parkDetail.ParkInfo.TotalAcres : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Single Family Homes
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {!!parkDetail.ParkFieldsDataObj.TotalSFRUnits && parkDetail.ParkFieldsDataObj.TotalSFRUnits > 0
                  ? parkDetail.ParkFieldsDataObj.TotalSFRUnits
                  : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Year Built
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {parkDetail.ParkInfo?.YearBuilt ?? '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Property Tax
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                ${!!parkDetail.ParkInfo.PropertyTax ? parkDetail.ParkInfo.PropertyTax.toLocaleString() : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Flood Plain Type
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {!!parkDetail.ParkFieldsDataObj.FloodPlain ? parkDetail.ParkFieldsDataObj.FloodPlain : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
          <Grid item xs={3}>
            <AppBox display={'flex'} gap={1}>
              <AppTypography color={'grey.600'} fontWeight={400}>
                Storage Unit
              </AppTypography>
              <AppTypography color={'grey.900'} fontWeight={700}>
                {!!parkDetail.ParkFieldsDataObj.TotalStorageUnits && parkDetail.ParkFieldsDataObj.TotalStorageUnits > 0
                  ? parkDetail.ParkFieldsDataObj.TotalStorageUnits
                  : '-'}
              </AppTypography>
            </AppBox>
          </Grid>
        </Grid>
      </AppBox>
    </AppBox>
  )
}
