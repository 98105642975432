import { FC, useEffect } from 'react'
import { ParkSiteActivityCenterTours } from './ParkSiteActivityCenterTours'
import { ParkSiteDashboardTours } from './ParkSiteDashboardTours'
import { ProfileTours } from './ProfileTours'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/app.store'
import { setCurrentTourKey } from 'store/slices/common.slice'
import { AppBox } from 'components/base'
interface Props {
  children: React.ReactNode
}
export const TourProvider: FC<Props> = ({ children }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setCurrentTourKey(undefined))
  }, [location])
  return (
    <>
      {children}
      <ParkSiteDashboardTours />
      <ParkSiteActivityCenterTours />
      <ProfileTours />
      <AppBox className="park-sites-dashboard-tour-step0 park-sites-activity-tour-step0 profile-tour-step0"></AppBox>
    </>
  )
}
