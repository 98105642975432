import { ReactNode } from 'react'

interface Props {
  isLoading: boolean
  loaderComponent: ReactNode
  isData: boolean
  children: ReactNode
  noDataComponent?: ReactNode
}
export const BaseComponent = ({ isLoading, loaderComponent, isData, children, noDataComponent }: Props) => {
  return <>{isLoading ? <>{loaderComponent}</> : <>{isData ? <>{children}</> : <>{noDataComponent ?? <></>}</>}</>}</>
}
