import { StatePayload, StateViewModel, UpdateStateStatusPayload, UpdateStateWebsitePayload } from 'sdk/models'
import { BaseApi } from '../base.api'
import { apiUrls } from 'sdk/api-urls'

export class StateApi extends BaseApi {
  public getAllStates() {
    return this.GET_Request<Array<StateViewModel>>(apiUrls.state.getAllStates())
  }

  public getById(id: string) {
    return this.GET_Request<StateViewModel>(apiUrls.state.getById(id))
  }

  public update(stateData: StatePayload) {
    return this.PUT_Request(apiUrls.state.update(), stateData)
  }
  public updateStateWebsite(updateStateWebsite: UpdateStateWebsitePayload) {
    return this.PUT_Request(apiUrls.state.updateWebsite(), updateStateWebsite)
  }
  public updateStatus(updateStatus: UpdateStateStatusPayload) {
    return this.PUT_Request(apiUrls.state.updateStatus(), updateStatus)
  }
  public addState(stateData: StatePayload) {
    return this.POST_Request(apiUrls.state.addState(), stateData)
  }
}
