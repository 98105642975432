import { AppBox } from 'components'
import { useCrmContacts } from 'hooks'
import { FC } from 'react'
import { CrmContactActionBar } from '../../actionbar/ContactActions'
import { CrmContactsListing } from './crm-contact-listing/CrmContactsListing'
import { ExportCrmContactPayload } from 'sdk'

interface Props {}

export const CrmContacts: FC<Props> = () => {
  const {
    showableContacts,
    showableContactsCount,
    isGetting,
    filter,
    updatePaginationFilter,
    watch,
    handleSubmit,
    handleFormSubmit,
    control,
    isAddingContact,
    dialogRef,
    setError,
    SelectedContactIds,
    isAllContactsSelected,
    setSelectedContactIds,
    setIsAllContactsSelected,
    handleDeleteAllContacts,
    handleDeleteContacts,
    handleExportCrmContacts,
    isApplyingFilter,
    setIsApplyingFilter,
    isDeletingAllContacts,
    isDeletingContacts,
    reset,
  } = useCrmContacts({ fetchContactsonFilterChange: true, fetchContactsOnFormSubmit: true })

  return (
    <AppBox minHeight={'inherit'}>
      <CrmContactActionBar
        control={control}
        reset={reset}
        setError={setError}
        watch={watch}
        isAddingContact={isAddingContact}
        dialogRef={dialogRef}
        selectedContactIds={SelectedContactIds}
        isAllContactsSelected={isAllContactsSelected}
        paginationFilter={filter}
        setIsApplyingFilter={setIsApplyingFilter}
        handleSubmit={handleSubmit}
        handleFormSubmit={handleFormSubmit}
        handleQuery={(val) => updatePaginationFilter({ ...filter, Query: val })}
        handleTypeChange={(val) => updatePaginationFilter({ ...filter, EntityType: val })}
        handleDeleteAllContacts={handleDeleteAllContacts}
        handleDeleteContacts={handleDeleteContacts}
        handleExportCrmContacts={(payload: ExportCrmContactPayload) => {
          handleExportCrmContacts(payload)
        }}
      />
      <AppBox minHeight={'72vh'}>
        <CrmContactsListing
          data={showableContacts() ?? []}
          isLoading={isGetting || isApplyingFilter || isDeletingAllContacts || isDeletingContacts}
          paginationFilter={filter}
          totalRecords={showableContactsCount()}
          onCurrentPageChange={(val) => updatePaginationFilter({ ...filter, PageNo: val })}
          onPageSizeChange={(val) => updatePaginationFilter({ ...filter, PageSize: val })}
          onRowSelectionChange={(val) => setSelectedContactIds(val)}
          onSelectAllRowsChange={(val) => setIsAllContactsSelected(val)}
        />
      </AppBox>
    </AppBox>
  )
}
