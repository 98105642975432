import { Grid } from '@mui/material'
import { useTour } from '@reactour/tour'
import { AppBox, MapWrapper, ParkSitesToolBar } from 'components'
import { AppTourKeys } from 'globals/constants'
import { useGetLabelledParks, useGetParkPins, usePinnedParks, useShowableParkPins } from 'hooks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store/app.store'
import { closeActivity, ParkSiteToolSliceSelector, TeamSliceSelector } from 'store/slices'
import { setCurrentTourKey } from 'store/slices/common.slice'
import { ParkActivity, ParkActivityCenter, ParkSitesSideTool } from '..'
import { ParkSitesGoogleMaps } from '../google-map/ParkSitesGoogleMap'
import { ParkSiteHelpWindow } from '../help-window/ParkSiteHelpWindow'
import { CollapsedParkSitesSideTool } from '../side-tool/collapsed-side-tool/CollapsedSideTool'
import { ParkSiteFilterBuilder } from '../side-tool/filter/ParkSiteFilterBuilder'
import { createRoot } from 'react-dom/client'

export const ParkSitesLayout = () => {
  const { openedTool, selectedParkId, openedActivity, isFilterToolOpened } = useSelector(ParkSiteToolSliceSelector)

  const { SelectedTeam } = useSelector(TeamSliceSelector)
  const { currentMarkers } = useShowableParkPins()

  const [showSideTool, setShowSideTool] = useState(false)

  const dispatch = useAppDispatch()
  const { setIsOpen } = useTour()
  useEffect(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    if (showSideTool) {
      dispatch(closeActivity())
      setShowSideTool(false)
    }
  }, [showSideTool])

  // fetching Park Labels

  const {} = usePinnedParks()
  //

  // fetching Pinned Parks

  const { getPinnedParks } = useGetLabelledParks()

  useEffect(() => {
    getPinnedParks()
  }, [getPinnedParks])
  //

  // fetching park pins

  const { getParkPins } = useGetParkPins()

  useEffect(() => {
    getParkPins()
      .then(() => {
        dispatch(setCurrentTourKey(AppTourKeys.ParkSitesDashboardTour))
      })
      .catch(() => {})
  }, [getParkPins, SelectedTeam?.Id])

  //

  const getMapColumns = () => {
    if (openedTool && !selectedParkId) {
      return 8
    }
    if (selectedParkId) {
      if (openedTool && openedActivity) {
        return 8.7
      }
      return !!openedTool ? 5 : 9
    }
    return 12
  }
  const getContentHeight = () => {
    return '82vh'
  }

  return (
    <AppBox minHeight={'100%'}>
      <MapWrapper>
        <AppBox height={'9vh'}>
          <ParkSitesToolBar />
        </AppBox>
        {!!isFilterToolOpened && (
          <AppBox
            minHeight={openedTool ? '1px' : '2vh'}
            borderTop={1}
            borderColor={'grey.300'}
            maxHeight={openedTool ? '1px' : ''}
          >
            <ParkSiteFilterBuilder />
          </AppBox>
        )}
        <Grid container>
          {!!selectedParkId && (
            <Grid item md={3} minHeight={getContentHeight()} position={'relative'}>
              <AppBox backgroundColor="grey.50" height={'100%'}>
                <ParkActivityCenter />
              </AppBox>
            </Grid>
          )}
          <Grid item md={getMapColumns()} position={'relative'} id={'park-activity-center-help-window-Container'}>
            <AppBox height={getContentHeight()} width={'100%'}>
              <ParkSiteHelpWindow />
              <ParkActivity />
              <ParkSitesGoogleMaps />
            </AppBox>
          </Grid>
          {openedTool && openedActivity && (
            <Grid md={0.3} minHeight={getContentHeight()} width={'100%'}>
              <CollapsedParkSitesSideTool
                currentHeight={getContentHeight()}
                currentMarkers={currentMarkers()}
                isVisible={showSideTool}
                onCloseButtonClick={() => setShowSideTool(!showSideTool)}
              />
            </Grid>
          )}
          {openedTool && !openedActivity && (
            <Grid item xs={4} minHeight={getContentHeight()} position={'relative'}>
              <AppBox backgroundColor="grey.100" height={'100%'}>
                <ParkSitesSideTool />
              </AppBox>
            </Grid>
          )}
        </Grid>
      </MapWrapper>
    </AppBox>
  )
}
