import { BaseInputProps } from 'globals/typings'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { AppTextFieldProps } from '../text-field/AppTextField'
import { getValidationRules } from 'plugins/helper/helper'
import { SimpleValueSelect } from '../simple-fields/SimpleValueSelect'

export interface AppSelectProps extends BaseInputProps, AppTextFieldProps {
  options: { label: string; value: string }[]
  handleChange?: (event: any) => void
  isLoading?: boolean
  loadingText?: string
  multiple?: boolean
}

export const AppSelect: FC<AppSelectProps> = ({ name, control, required, customRules, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: (value) => getValidationRules(value, name, customRules ?? {}),
      }}
      render={({ fieldState: { error }, field }) => (
        <SimpleValueSelect {...field} {...props} errorText={error?.message} />
      )}
    />
  )
}
