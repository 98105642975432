import { AdminSettingsTools } from 'globals/constants'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { AdminSettingToolsSelector } from 'store/slices'
import { ManageUsers } from './users/ManageUsers'
import { AddNewsAlerts } from './news_alerts/ManageNewsAlers'
import { ManageStates } from './states/ManageStates'
import { ManageCounties } from './counties/ManageCounties'
import { ManageCities } from './city_data/ManageCities'
import { ManageReviews } from './reviews/ManageReviews'
import { AppBox } from 'components'

export const AdminSettingContent = () => {
  const mapping: { [key: string]: ReactNode } = {
    [AdminSettingsTools.Users]: <ManageUsers />,
    [AdminSettingsTools.News_Alerts]: <AddNewsAlerts />,
    [AdminSettingsTools.States]: <ManageStates />,
    [AdminSettingsTools.Counties]: <ManageCounties />,
    [AdminSettingsTools.City_Data]: <ManageCities />,
    [AdminSettingsTools.Reviews]: <ManageReviews />,
  }
  const { openedAdminSettingTool } = useSelector(AdminSettingToolsSelector)
  return (
    <AppBox
      backgroundColor="white"
      paddingTop={1}
      minHeight={'71vh'}
      display={'flex'}
      flexDirection={'column'}
      borderRadius={5}
    >
      {mapping[openedAdminSettingTool]}
    </AppBox>
  )
}
