import { AddPaymentMethodForm, AppBox, AppTypography } from 'components'
import { FC, useEffect } from 'react'
import { Control, UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { AttachPaymentMethodPayloadModel } from 'sdk'
import { PaymentMethodSliceSelector } from 'store/slices/payment-methods.slice'

interface Props {
  control: Control<AttachPaymentMethodPayloadModel>
  reset: UseFormReset<AttachPaymentMethodPayloadModel>
  moveNext: () => void
}

export const ChangeMembershipPaymentMethod: FC<Props> = ({ control, moveNext }) => {
  const { DefaultPaymentMethod } = useSelector(PaymentMethodSliceSelector)

  useEffect(() => {
    if (!!DefaultPaymentMethod) {
      moveNext()
    }
  }, [DefaultPaymentMethod])
  return (
    <AppBox display={'flex'} flexDirection={'column'} gap={3}>
      <AppBox textAlign={'center'}>
        <AppTypography fontSize={14} fontWeight={500} color={'grey.500'}>
          Please provide us your payment method
        </AppTypography>
      </AppBox>
      <AddPaymentMethodForm control={control} />
    </AppBox>
  )
}
