import { ColumnDef } from '@tanstack/react-table'
import { AppBox, AppIcon, AppMenu, AppTable, AppTableProps, AppTypography, TableActions } from 'components'
import { PeopleInsightRolesOptions } from 'globals/constants'
import { FC } from 'react'
import { PeopleInsightViewModel } from 'sdk'

interface Props extends AppTableProps<PeopleInsightViewModel> {
  onDetachClick: (row: PeopleInsightViewModel) => void
  onRoleChange: (row: PeopleInsightViewModel, value: string) => void
  onEditClick: (row: PeopleInsightViewModel) => void
}

export const PeopleInsightAdminViewListing: FC<Props> = ({ onDetachClick, onRoleChange, onEditClick, ...props }) => {
  const columns: ColumnDef<PeopleInsightViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[{ text: 'Detach From Park', onClick: () => onDetachClick(row.original) }]}
          />
        )
      },
    },
    {
      header: 'Park Role',
      cell: ({ row }) => (
        <AppBox display={'flex'} gap={2}>
          <AppTypography>{row.original.Role}</AppTypography>
          <AppMenu
            MenuItems={PeopleInsightRolesOptions.map((x) => ({
              text: x.label,
              onClick: () => {
                onRoleChange(row.original, x.value)
                // handleRoleChange(x.value)
              },
            }))}
            renderElement={(open) => (
              <AppBox
                onClick={(e) => {
                  open(e)
                }}
              >
                <AppIcon icon={'icon-park-twotone:edit-two'} style={{ cursor: 'pointer' }} size={'small'} />
              </AppBox>
            )}
          />
        </AppBox>
      ),
    },
    {
      header: 'Company',
      accessorKey: 'CompanyName',
    },
    {
      header: 'Contact Name',
      cell: ({ row }) => (
        <AppTypography component={'a'} color={'blue'} onClick={() => onEditClick(row.original)}>
          {row.original.FirstName + ' ' + row.original.LastName}
        </AppTypography>
      ),
    },
    {
      header: 'Email',
      accessorKey: 'Email',
    },
    {
      header: 'Phone',
      accessorKey: 'Phone',
    },
    {
      header: 'Alt Phone',
      accessorKey: 'AltPhone',
    },
  ]
  return <AppTable columns={columns} {...props} />
}
