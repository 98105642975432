import { useMutation } from '@tanstack/react-query'
import { AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CrmCompaniesApi, CrmCompanyPayload, CrmCompanyViewModel, PaginationFilterModel, ShowAlert } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { FilterSliceSelector, SetIsParkActivityCrmContactCompanyUpdate, TeamSliceSelector } from 'store/slices'
interface Props {
  fetchCompaniesOnFilterChange?: boolean
  fetchCompaniesOnFormSubmit?: boolean
  companyId?: string
  updateOnCompanyUpdate?: boolean
  handleChange?: () => void
}
export const useCrmCompanies = ({
  fetchCompaniesOnFilterChange,
  fetchCompaniesOnFormSubmit,
  companyId,
  updateOnCompanyUpdate,
  handleChange,
}: Props) => {
  const companyApi = new CrmCompaniesApi()

  const {
    CrmCompany: { IsUnSavedFilterApplied, AppliedFilter, FilteredData, FilterDataCount },
  } = useSelector(FilterSliceSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const [idToBeEdited, setIdToBeEdited] = useState<string | null>(null)
  const [filter, setFilter] = useState(new PaginationFilterModel())
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([])
  const [isAllCompaniesSelected, setIsAllCompaniesSelected] = useState(false)
  const [isApplyingFilter, setIsApplyingFilter] = useState(false)

  const { control, handleSubmit, reset, setError, watch } = useForm<CrmCompanyPayload>({
    mode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const dialogRef = useRef<AppDialogRefType>({})
  // Get Companies
  const {
    data: Companies,
    mutate: GetCompanies,
    isPending: isGetting,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.companies.FetchCompanies],
    mutationFn: () => companyApi.GetCrmCompanies(filter),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })
  useEffect(() => {
    if (fetchCompaniesOnFilterChange && !AppliedFilter && !IsUnSavedFilterApplied) {
      GetCompanies()
    }
  }, [filter, SelectedTeam?.Id, AppliedFilter, IsUnSavedFilterApplied])

  const {
    data: companyById,
    isPending: isGettingCompanyById,
    mutate: GetCompanyById,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.companies.FetchCompanyById, companyId],
    mutationFn: (_companyId: string) => companyApi.GetCrmCompany(_companyId),
  })
  // Add Comapny
  const { mutateAsync: AddCompany, isPending: isAddingCompany } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contacts.AddContact],
    mutationFn: companyApi.AddCrmCompany,
    onSuccess: () => {
      if (handleChange) {
        handleChange()
      }
      if (fetchCompaniesOnFormSubmit) {
        GetCompanies()
      }
      dialogRef.current.closeDialog!()
      enqueueSnackbar('Company Added Successfully', { variant: 'success' })
      reset()
    },
  })
  //
  // Edit Company
  const { mutateAsync: EditCompany, isPending: isEditing } = useMutation({
    mutationKey: [ApiQueryKeys.crm.companies.EditCompany],
    mutationFn: (data: { id: string; data: CrmCompanyPayload }) => companyApi.EditCrmCompany(data.id, data.data),
    onSuccess: () => {
      if (handleChange) {
        handleChange()
      }
      if (updateOnCompanyUpdate) {
        dispatch(SetIsParkActivityCrmContactCompanyUpdate())
      }
      if (fetchCompaniesOnFormSubmit) {
        GetCompanies()
      }
      enqueueSnackbar('Company Updated Successfully', { variant: 'success' })
      reset()
      if (dialogRef.current.closeDialog) {
        dialogRef.current.closeDialog!()
      }
    },
  })
  //
  // Delete Company
  const { mutate: DeleteCompanies, isPending: isDeleting } = useMutation({
    mutationKey: [ApiQueryKeys.crm.companies.DeleteCompany],
    mutationFn: (ids: string[]) => companyApi.DeleteCrmCompanies(ids),
    onSuccess: () => {
      if (handleChange) {
        handleChange()
      }
      if (fetchCompaniesOnFormSubmit) {
        GetCompanies()
      }
      enqueueSnackbar('Companies Deleted Successfully', { variant: 'success' })
    },
  })
  const { mutate: DeleteAllCompanies, isPending: isDeletingAll } = useMutation({
    mutationKey: [ApiQueryKeys.crm.companies.DeleteAllCompanies],
    mutationFn: () => companyApi.DeleteAllCrmCompanies(),
    onSuccess: () => {
      if (handleChange) {
        handleChange()
      }
      if (fetchCompaniesOnFormSubmit) {
        GetCompanies()
      }
      enqueueSnackbar('All Companies Deleted Successfully', { variant: 'success' })
    },
  })
  //
  const handleAddClicked = () => {
    setIdToBeEdited(null)
    reset()
    dialogRef.current.openDialog!()
  }
  const handleEditClicked = (company: CrmCompanyViewModel) => {
    setIdToBeEdited(company.Id)
    reset(company)
    dialogRef.current.openDialog!()
  }
  const handleDeleteClick = (id: string[]) => {
    ShowAlert('info', 'Are you sure you want to delete this company?').then((result) => {
      if (result.isConfirmed) {
        DeleteCompanies(id)
      }
    })
  }
  const handleDeleteAllClikc = () => {
    ShowAlert('info', 'Are you sure you want to delete all companies?').then((result) => {
      if (result.isConfirmed) {
        DeleteAllCompanies()
      }
    })
  }
  const submit = (data: CrmCompanyPayload) => {
    if (idToBeEdited) {
      EditCompany({ id: idToBeEdited, data })
    } else {
      AddCompany(data)
    }
  }

  const showableCompanies = () => {
    if (IsUnSavedFilterApplied || AppliedFilter) {
      return FilteredData
    }
    return Companies?.Data ?? []
  }
  const showableCompaniesCount = () => {
    if (IsUnSavedFilterApplied || AppliedFilter) {
      return FilterDataCount ?? 0
    }
    return Companies?.TotalCount ?? 0
  }
  return {
    control,
    handleSubmit,
    AddCompany,
    reset,
    setError,
    dialogRef,
    isAddingCompany,
    idToBeEdited,
    isEditing,
    isDeleting,
    isDeletingAll,
    filter,
    selectedCompaniesIds,
    isAllCompaniesSelected,
    setIsAllCompaniesSelected,
    setSelectedCompaniesIds,
    setFilter,
    setIdToBeEdited,
    handleAddClicked,
    handleEditClicked,
    handleDeleteClick,
    handleDeleteAllClikc,
    submit,
    EditCompany,
    isGetting,
    setIsApplyingFilter,
    showableCompanies,
    showableCompaniesCount,
    isApplyingFilter,
    watch,
    isGettingCompanyById,
    GetCompanyById,
    companyById,
  }
}
