import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CrmContactLinkedParksApi, PaginationFilterModel, ShowAlert } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  CrmLinkedToolsHelperSelector,
  ParkSiteToolSliceSelector,
  SetIsParkActivityCrmUpdated,
  TeamSliceSelector,
} from 'store/slices'

interface Props {}
export const useCrmContactParks = () => {
  const contactLinkedParksApi = new CrmContactLinkedParksApi()

  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const { isParkActivityCrmUpdated } = useSelector(CrmLinkedToolsHelperSelector)
  const { SelectedTeam } = useSelector(TeamSliceSelector)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const [filter, setFilter] = useState<PaginationFilterModel>(new PaginationFilterModel())

  const handleDetachPark = (parkId: string, contactId: string) => {
    ShowAlert(
      'question',
      `You are about to break the link between this contact and  this park. You can add them back anytime while the contact remains in your CRM.
      <br />
      <br />
        Do you wish to continue?`
    ).then((result) => {
      if (result.isConfirmed) {
        UnlinkPark({ CrmContactId: contactId!, ParkSiteId: parkId })
        dispatch(SetIsParkActivityCrmUpdated())
      }
    })
  }
  // Removing Park
  const { mutate: UnlinkPark, isPending: isRemoving } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.RemoveLinkedPark],
    mutationFn: (data: { CrmContactId: string; ParkSiteId: string }) =>
      contactLinkedParksApi.unlinkParkFromContact(data.CrmContactId, data.ParkSiteId),
    onSuccess: () => {
      enqueueSnackbar('Park Detached', { variant: 'success' })
      GetContacts()
    },
  })

  //
  // Fetching Contacts
  const {
    data: contacts,
    isPending: isGetting,
    mutate: GetContacts,
  } = useMutation({
    mutationKey: [ApiQueryKeys.crm.contactLinkedParks.FetchParkContacts],
    mutationFn: () => contactLinkedParksApi.getParkLinkedContacts(selectedParkId, filter),
  })
  // hooks
  useEffect(() => {
    if (isParkActivityCrmUpdated === 0) {
      return
    }
    GetContacts()
  }, [isParkActivityCrmUpdated])

  useEffect(() => {
    GetContacts()
  }, [filter, SelectedTeam?.Id])
  //

  return {
    GetContacts,
    contacts,
    filter,
    setFilter,
    isGetting,
    handleDetachPark,
    isRemoving,
  }
}
