export const ApiQueryKeys = {
  auth: {
    login: 'login',
    signup: 'signup',
    getTeamAuthToken: 'get-team-auth-token',
  },
  account: {
    getUserByEmail: 'get-user-by-email',
    addSubAccounts: 'add-sub-accounts',
    getUserSubAccounts: 'get-user-sub-accounts',
    verifyEmail: 'verify-email',
    changePassword: 'change-password',
    forgotPassword: 'forgot-password',
    setPasswordAfterForgot: 'set-password-after-forgot',
    addRecoveryEmail: 'add-recovery-email',
  },
  newsAlert: {
    getNewsAlerts: 'get-news-alerts',
    addNewsAlert: 'add-news-alert',
    updateNewsAlert: 'update-news-alert',
    deleteNewsAlert: 'delete-news-alert',
  },
  state: {
    getAllStates: 'get-all-states',
    getById: 'get-state-by-id',
    addState: 'add-state',
    updateState: 'update-state',
    updateWebsite: 'update-website',
    updateStateStatus: 'update-state-status',
  },
  county: {
    getCountyByState: 'get-county-by-state',
    getAllCounties: 'get-all-counties',
    addCounty: 'add-county',
    updateCounty: 'update-county',
    updateContyWebsite: 'update-county-website',
    updateCountyStatus: 'update-county-status',
  },
  feedback: {
    addFeedback: 'add-feedback',
    getFeedbacks: 'get-feedbacks',
  },
  filter: {
    getFilters: 'get-filters',
    addFilter: 'add-filter',
    updateFilter: 'update-filter',
    deleteFilter: 'delete-filter',
  },
  user: {
    updateUserOnGettingStarted: 'update-user-on-getting-started',
    updateUserProfile: 'update-user-profile',
    getAllUsersWithSubAccounts: 'get-all-users-with-sub-accounts',
    getUserPersonalInfo: 'get-user-personal-info',
    getUserSubscriptionInfo: 'get-user-subscription-info',
    updateUserProfileImage: 'update-user-profile-image',
    removeUserProfileImage: 'remove-user-profile-image',
  },
  memberships: {
    GetMembershipPlans: 'get-membership-plans',
    ChangeMembership: 'change-membership',
    CancelMembership: 'cancel-membership',
  },
  parksites: {
    FetchAllParkLabels: 'fetch-all-park-pins',
    FetchParkDetail: 'fetch-park-detail',
    FetchParkImages: 'fetch-park-images',
    FetchParkActivityCrm: 'fetch-park-activity-crm',
    FetchParkActivityInfo: 'fetch-park-activity-info',
    FetchParkPeopleInsight: 'fetch-park-people-insight',
    FetchParkParcelData: 'fetch-park-parcel-data',
    FetchParkCityDemographics: 'fetch-park-city-demographics',
    FetchParkActivityHistory: 'fetch-park-activity-history',
    RequestNewPark: 'request-new-park',
    UpdatePark: 'update-park',
    ChangeParkLocation: 'change-park-location',
    AddParkSite: 'add-park-site',
    IsParkDataExist: 'is-park-data-exist',
    DeleteParkSite: 'delete-park-site',
    ReportPark: 'report-park',
    filter: {
      GetParkFilters: 'get-park-filters',
      ApplyParkFilter: 'apply-park-filter',
    },
    peopleInsights: {
      GetParkPeopleInsights: 'get-park-people-insights',
      AddPeopleInsight: 'add-people-insight',
      LinkParkPeopleInsight: 'link-park-people-insight',
      DetachParkPeopleInsight: 'detach-park-people-insight',
      GetPossiblePeopleInsights: 'get-possible-people-insights',
      EditPeopleInsight: 'edit-people-insight',
      AddPeopleInsightToCrm: 'add-people-insight-to-crm',
    },
    parkLabels: {
      AddParkLabel: 'add-park-label',
      FetchParkLabels: 'fetch-park-labels',
      EditParkLabel: 'edit-park-label',
      DeleteLabel: 'delete-label',
    },
    labelledParks: {
      FetchLabelledParks: 'fetch-labelled-parks',
      FethcParkWithCrm: 'fetch-park-with-crm',
      RemoveLabelledParkFromList: 'remove-labelled-park-from-list',
      RemoveAllLabelledParksFromList: 'remove-labelled-all-parks-from-list',
      FetchLabelledParksByLabelId: 'fetch-labelled-parks-by-label-id',
      RemoveLabelledParkFromListByParkId: 'remove-labelled-park-from-list-by-park-id',
      LabelledParksLoadMore: 'labelled-parks-load-more',
    },
    parksWithCrm: {
      FetchParksWithCrm: 'fetch-parks-with-crm',
      ParksWithCrmLoadMore: 'parks-with-crm-load-more',
    },
    parkNotes: {
      AddParkNote: 'add-park-note',
      RemoveParkNote: 'remove-park-note',
      FetchParkNotes: 'fetch-park-notes',
      EditParkNote: 'edit-park-note',
    },
    taggedParks: {
      FetchTaggedParks: 'fetch-tagged-parks',
      FetchTaggedParksByTagId: 'fetch-tagged-parks-by-tag-id',
      AddTaggedPark: 'add-tagged-park',
      FetchParkTags: 'fetch-park-tags',
      RemoveTaggedPark: 'remove-tagged-park',
      RemoveAllTaggedParks: 'remove-all-tagged-parks',
      RemoveParkTag: 'remove-park-tag',
      TaggedParksLoadMore: 'tagged-parks-load-more',
    },
  },
  tags: {
    AddTag: 'add-tag',
    FetchTag: 'fetch-tag',
    EditTag: 'edit-tag',
    RemoveTag: 'remove-tag',
  },
  team: {
    FetchUserTeams: 'fetch-user-teams',
    IsTeamNameExist: 'is-team-name-exist',
    UpdateTeamName: 'update-team-name',
    GetUserteamMemersCount: 'get-userteam-memers-count',
    RequestUserToJoinTeam: 'request-user-to-join-team',
    FetchTeamMembers: 'fetch-team-members',
    RevokeAccess: 'revoke-access',
    ReSendInvitation: 're-send-invitation',
  },
  crm: {
    contacts: {
      AddContact: 'add-contact',
      FetchContacts: 'fetch-contacts',
      FetchContactById: 'fetch-contact-by-id',
      EditContact: 'edit-contact',
      IsContactExist: 'is-contact-exist',
      DeleteContactById: 'delete-contact-by-id',
      DeleteContacts: 'delete-contacts',
      DeleteAllContacts: 'delete-all-contacts',
      ExportContacts: 'export-contacts',
    },
    companies: {
      AddCompany: 'add-company',
      FetchCompanies: 'fetch-companies',
      FetchCompanyById: 'fetch-company-by-id',
      EditCompany: 'edit-company',
      IsCompanyExist: 'is-company-exist',
      DeleteCompany: 'delete-company',
      DeleteAllCompanies: 'delete-all-companies',
    },
    contactLinkedCompanies: {
      FetchLinkedContacts: 'fetch-linked-contacts',
      FetchUnlinkedCompanies: 'fetch-unlinked-companies',
      AddLinkedCompany: 'add-linked-company',
      RemoveLinkedCompany: 'remove-linked-company',
    },
    contactNotes: {
      AddContactNote: 'add-contact-note',
      FetchContactNotes: 'fetch-contact-notes',
      EditContactNote: 'edit-contact-note',
      DeleteContactNote: 'delete-contact-note',
    },
    contactLinkedTags: {
      FetchTag: 'fetch-linked-tag',
      AddTag: 'add-linked-tag',
      RemoveTag: 'remove-linked-tag',
    },
    contactLinkedParks: {
      FetchLinkedParks: 'fetch-linked-parks',
      FetchParkContacts: 'fetch-park-contacts',
      AddLinkedPark: 'add-linked-park',
      RemoveLinkedPark: 'remove-linked-park',
      FetchUnlinkedParks: 'fetch-unlinked-parks',
      LinkContactsToPark: 'link-contacts-to-park',
    },
    contactFilter: {
      ApplyContactFilter: 'apply-contact-filter',
      FetchContactFilters: 'fetch-contact-filters',
      DeleteContactFilter: 'delete-contact-filter',
    },
  },
  payment: {
    FetchPaymentMethods: 'fetch-payment-methods',
    FetchCouponById: 'fetch-coupon-by-id',
    AttachPayumentMethod: 'attach-payment-method',
    DetachPaymentMethod: 'detach-payment-method',
    UpdateDefaultPaymentMethod: 'update-default-payment-method',
  },
  settings: {
    FetchSettings: 'fetch-settings',
    UpdateSettings: 'update-settings',
  },
  zapier: {
    generateApiKey: 'generate-api-key',
    GetZapierInfo: 'get-zapier-info',
  },
}
