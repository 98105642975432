import { Grid } from '@mui/material'
import { AppBox, AppIcon, AppTypography } from 'components'
import { useNavigate } from 'react-router-dom'
import { ProfileContent } from './content/ProfileContent'
import { ProfileSideBar } from './side-bar/ProfileSideBar'

export const Profile = () => {
  const navigate = useNavigate()
  return (
    <AppBox minHeight={'100%'}>
      <Grid container minHeight={'inherit%'}>
        <Grid item xs={12} minHeight={'8vh'}>
          <AppBox display={'flex'} alignItems={'center'} gap={1} backgroundColor="white" padding={2}>
            <AppIcon icon={'mingcute:arrow-left-line'} size="medium" onClick={() => navigate(-1)} />
            <AppTypography fontWeight={700} fontSize={24} color={'grey.900'}>
              Account Settings
            </AppTypography>
          </AppBox>
        </Grid>
        <Grid item xs={12} padding={2} sx={{ backgroundColor: 'grey.100' }} minHeight={'83vh'}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ProfileSideBar />
            </Grid>
            <Grid item xs={9}>
              <ProfileContent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBox>
  )
}
