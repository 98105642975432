import { Provider } from 'react-redux'
import './App.css'

import { ThemeProvider } from '@emotion/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClientProvider } from '@tanstack/react-query'
import { StyledMaterialDesignContent } from 'components/shared/snackbar/snack-bar.component'
import { client } from 'handlers/query-response-handler'
import { SnackbarProvider } from 'notistack'
import { PersistGate } from 'redux-persist/integration/react'
import { AppStore, persistor } from 'store/app.store'
import theme from 'theme/theme'
import { RenderRoutes } from './router/generate-routes'
import { TourProvider } from 'components/shared/tours/TourProvider'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY!)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={client}>
        <Elements stripe={stripePromise}>
          <Provider store={AppStore}>
            <PersistGate loading={null} persistor={persistor}>
              <TourProvider>
                <SnackbarProvider
                  maxSnack={5}
                  autoHideDuration={2500}
                  hideIconVariant
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  preventDuplicate
                  Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                  }}
                >
                  <RenderRoutes />
                </SnackbarProvider>
              </TourProvider>
            </PersistGate>
          </Provider>
        </Elements>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
