import { Column, ColumnDef, Row } from '@tanstack/react-table'
import { AppIcon, AppTable, AppTableProps, TableActions } from 'components'
import { UserParkLabelPayloadModel, UserParkLabelViewModel } from 'sdk'

interface Props extends AppTableProps<UserParkLabelViewModel> {
  onEditClick: (Id: string, row: UserParkLabelViewModel) => void // Add a question mark after 'UserParkLabelViewModel'
  onDeleteClick: (Id: string) => void
}
type ColumnDefi<T extends { Id: string }> = ColumnDef<T> & {}
export const ParkPinsTable = ({ onEditClick, onDeleteClick, ...props }: Props) => {
  const columns: ColumnDefi<UserParkLabelViewModel>[] = [
    {
      id: 'popover',
      cell: ({ row }) => {
        return (
          <TableActions
            row={row}
            actions={[
              { text: 'Edit', onClick: () => onEditClick(row.original.Id, row.original) },
              { text: 'Delete', onClick: () => onDeleteClick(row.original.Id) },
            ]}
          />
        )
      },
    },
    {
      header: 'Color',
      accessorKey: 'Color',
      cell: ({ row }) => {
        return <AppIcon size="large" icon={'ph:map-pin-fill'} color={row.original.Color ?? 'red'} />
      },
    },
    {
      header: 'Label',
      accessorKey: 'Label',
      size: 1,
    },
  ]
  return <AppTable columns={columns} {...props} />
}
