import { TooltipProps } from '@mui/material'
import { AppBox, AppToolTip, AppTypography } from 'components'
import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
  tooltipText: React.ReactNode | string
  toolTipProps?: Omit<TooltipProps, 'children' | 'title'>
}

export const TextWithToolTip: FC<Props> = ({ children, tooltipText, toolTipProps }) => {
  return (
    <AppBox display={'flex'} alignItems={'center'}>
      {children}
      <AppToolTip {...toolTipProps} placement={toolTipProps?.placement ?? 'right-start'}>
        {<AppTypography>{tooltipText}</AppTypography>}
      </AppToolTip>
    </AppBox>
  )
}
