import { AppBox } from 'components'
import { FC, useState } from 'react'
import { AddSubAccountStepper } from './add-user-stepper/AddSubAccountStepper'
import { SuBAccountBundlePromotion } from './bundle-promo/SubAccountBundlePromotion'

interface Props {
  closeDialog: () => void
}
export const AddSubUserDialog: FC<Props> = ({ closeDialog }) => {
  const [promoShown, setPromoShown] = useState(false)

  return (
    <>
      <AppBox display={'flex'} flexDirection={'column'}>
        {!promoShown && <SuBAccountBundlePromotion closeDialog={closeDialog} setPromoShown={setPromoShown} />}
        {promoShown && <AddSubAccountStepper closeDialog={closeDialog} />}
      </AppBox>
    </>
  )
}
