import { Grid, LinearProgress } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AppBox, AppDialog, AppDialogRefType } from 'components'
import { ApiQueryKeys } from 'globals/constants'
import { useGetParkPins } from 'hooks'
import { useSnackbar } from 'notistack'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  ApiErrorModel,
  ParkDetailViewModel,
  ParkFieldsDataViewModel,
  ParkImageInternal,
  ParkSiteImagesPayload,
  ParkSitesApi,
  ParkUpdatePayload,
  SdkConfig,
  ShowAlert,
} from 'sdk'
import { useAppDispatch } from 'store/app.store'
import {
  ParkSiteToolSliceSelector,
  setOpenedActivityAdminViewOpen,
  setParkGetActivityCenterInfoCount,
  setSelectedParkId,
} from 'store/slices'
import { UpdateParkBasicCard } from './cards/UpdateParkBasicCard'
import { UpdateParkInfoCard } from './cards/UpdateParkInfoCard'
import { UpdateParkMHLotsCard } from './cards/UpdateParkMHLotsCard'
import { UpdateParkParkImages } from './cards/UpdateParkParkImages'
import { UpdateParkRVLotCard } from './cards/UpdateParkRVLotCard'
import { UpdateParkUtilitiesCard } from './cards/UpdateParkUtilitiesCard'

interface Props {
  parkDetail: ParkDetailViewModel | undefined
  isLoading: boolean
  refetchDetails: () => void
}
export interface UpdateParkFormRefType {
  openDialog?: () => void
}
export const UpdateParkForm = forwardRef(({ parkDetail, isLoading, refetchDetails }: Props, ref) => {
  const parkSiteApi = new ParkSitesApi()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { selectedParkId } = useSelector(ParkSiteToolSliceSelector)
  const dialogRef = useRef<AppDialogRefType>(null)
  const { control, getValues, setValue, handleSubmit, reset, watch } = useForm<ParkUpdatePayload>({
    defaultValues: { Id: selectedParkId },
    mode: 'onChange',
  })
  const { watch: watchImages, reset: resetImages } = useForm<ParkSiteImagesPayload>({
    defaultValues: {
      ParkId: selectedParkId,
      DeletedImageIds: [],
      OldImagesIds: [],
      NewImages: [],
    },
  })
  const { getParkPins } = useGetParkPins()

  const { mutate: deleteParkSite, isPending: isDeleting } = useMutation({
    mutationFn: () => parkSiteApi.deleteParkSite(selectedParkId),
    mutationKey: [ApiQueryKeys.parksites.AddParkSite],
    onError: (err: ApiErrorModel) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Park deleted successfully', { variant: 'success' })
      getParkPins()
      dispatch(setOpenedActivityAdminViewOpen(false))
    },
  })
  const { mutate: isUserParkDataExist, isPending: isCheckingParkUserData } = useMutation({
    mutationFn: () => parkSiteApi.isUserParkDataExist(selectedParkId),
    mutationKey: [ApiQueryKeys.parksites.IsParkDataExist],
    onError: () => enqueueSnackbar('Park does not exist', { variant: 'error' }),
    onSuccess: (count: number) => {
      if (count > 0) {
        dialogRef.current?.closeDialog!()
        ShowAlert(
          'question',
          `Continuing will result in this park being detached in ${count} user's accounts. Are you sure you wish to continue?`,
          {
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
          }
        ).then((x) => {
          if (x.isConfirmed) {
            deleteParkSite()
          }
        })
      } else {
        deleteParkSite()
      }
    },
  })
  const { mutate: UpdatePark, isPending: isUpdating } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.UpdatePark],
    mutationFn: (data: ParkUpdatePayload) => parkSiteApi.updatePark(data),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onSuccess: () => {
      enqueueSnackbar('Park updated successfully', { variant: 'success' })
      reset({ Id: selectedParkId })
      refetchDetails()
      getParkImages()
      dispatch(setParkGetActivityCenterInfoCount())
      dialogRef.current?.closeDialog!()
    },
  })
  const { data: parkImages, refetch: getParkImages } = useQuery({
    queryKey: [ApiQueryKeys.parksites.FetchParkImages],
    queryFn: () => parkSiteApi.GetParkImages(selectedParkId),
    select: (data) => {
      if (!!data) {
        return data.map((x) => {
          return {
            id: x.Id,
            sequence: x.Sequence ? +x.Sequence : 0,
            url: SdkConfig.ApiBaseUrl + '/' + x.Url!,
            // url: 'https://devapi.mhparks.com/' + x.Url!,
            uniqueId: x.Id!,
          } as ParkImageInternal
        })
      }
    },
  })

  const { mutate: updateParkImages, isPending: isUpdatingParkImages } = useMutation({
    mutationKey: [ApiQueryKeys.parksites.FetchParkParcelData],
    mutationFn: () => parkSiteApi.updateParkImages(watchImages()),
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })

  const openDialog = () => {
    if (!!parkDetail) {
      const clone = JSON.parse(JSON.stringify(parkDetail)) as ParkDetailViewModel
      clone.ParkFieldsDataObj = new ParkFieldsDataViewModel(
        clone.ParkFieldsData.map((x) => {
          if (x.Value != null) {
            return { [`${x.Key}`]: x.Value }
          }
          return {}
        }).reduce((r, c) => Object.assign(r, c))
      )

      reset(clone)
      resetImages(
        new ParkSiteImagesPayload({
          ParkId: selectedParkId,
          DeletedImageIds: [],
          OldImagesIds: parkImages?.map((x) => ({ Id: x.id!, Sequence: x.sequence })) ?? [],
          NewImages: [],
        })
      )
    }
    dialogRef.current?.openDialog!()
  }

  if (ref) {
    ;(ref as any).current = { openDialog }
  }

  return (
    <>
      <AppDialog
        ref={dialogRef}
        appDialogWrapperProps={{
          component: 'form',
          role: 'form',
          onSubmit: handleSubmit((data) => {
            const clone = JSON.parse(JSON.stringify(data)) as ParkUpdatePayload
            Object.keys(clone.ParkFieldsDataObj).forEach((parkKey) => {
              clone.ParkFieldsData?.map((dfv) => {
                if (dfv.Key === parkKey) {
                  dfv.Value = (clone.ParkFieldsDataObj as any)[parkKey]
                }
              })
            })

            UpdatePark(clone)
            updateParkImages()
            // } else {
            //   ReportPark()
            // }
          }),
        }}
        submitButtonProps={{
          isLoading: isUpdating || isUpdatingParkImages,
          children: 'Save',
        }}
        title={'Admin - Park Update'}
        maxWidth={'lg'}
        onClose={() => {
          reset({})
          resetImages({
            ParkId: selectedParkId,
            DeletedImageIds: [],
            OldImagesIds: [],
            NewImages: [],
          })
          dispatch(setOpenedActivityAdminViewOpen(false))
        }}
      >
        <AppBox display="flex" flexDirection="column" gap={2}>
          {isLoading && <LinearProgress />}
          <UpdateParkInfoCard
            control={control}
            watch={watch}
            isCheckingParkUserData={isCheckingParkUserData || isDeleting}
            isUserParkDataExist={isUserParkDataExist}
            parkDetail={parkDetail}
            isAdmin={true}
          />
          <UpdateParkBasicCard control={control} />
          <Grid container columnGap={2}>
            <UpdateParkMHLotsCard control={control} />
            <UpdateParkRVLotCard control={control} setValue={setValue} getValue={getValues} watch={watch} />
            <UpdateParkUtilitiesCard control={control} isAdmin={true} />
          </Grid>
          <UpdateParkParkImages parkImages={parkImages ?? []} imagePayload={watchImages()} reset={resetImages} />
        </AppBox>
      </AppDialog>
    </>
  )
})
