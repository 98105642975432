import { Grid } from '@mui/material'
import { AppDropZone, AppTextField, AppTypography } from 'components'
import { AppDatePicker } from 'components/base/date-picker/AppDatePicker'
import { AppTextArea } from 'components/base/text-area/AppTextArea'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { NewsAlertPayload } from 'sdk'

interface Props {
  control: Control<NewsAlertPayload>
}
export const AddEditNewsAlertForm: FC<Props> = ({ control }) => {
  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Alert Start Date
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppDatePicker name="StartDate" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Article Date
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppDatePicker name="ArticleDate" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Title
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppTextField name="Title" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Acrticle URL
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppTextField name="ArticleUrl" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          View All URL
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppTextField name="ViewAllUrl" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Description
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppTextArea name="Description" control={control} />
      </Grid>
      <Grid item xs={3}>
        <AppTypography fontSize={14} component={'span'}>
          Image
          <AppTypography component={'span'} color={'error'}>
            *
          </AppTypography>
        </AppTypography>
      </Grid>
      <Grid item xs={9}>
        <AppDropZone
          onFileSelect={function (files: File[]): void {
            throw new Error('Function not implemented.')
          }}
            acceptedFileTypes={{'image/*': []}}
        />
      </Grid>
    </Grid>
  )
}
