import {
  CountyModel,
  CountyPayload,
  CountyWithStateViewModel,
  UpdateCountyStatusModel,
  UpdateCountyWebsitePayload,
} from 'sdk'
import { apiUrls } from 'sdk/api-urls'
import { BaseApi } from '../base.api'

export class CountyApi extends BaseApi {
  public getCountyByState(id?: string) {
    return this.GET_Request<CountyWithStateViewModel[]>(apiUrls.county.getCountyByState(id))
  }

  public getById(id: string) {
    return this.GET_Request<CountyModel>(apiUrls.county.getById(id))
  }
  public addCounty(countyData: CountyPayload) {
    return this.POST_Request(apiUrls.county.addCounty(), countyData)
  }

  public update(countyData: CountyPayload) {
    return this.PUT_Request(apiUrls.county.update(), countyData)
  }

  public updateStatus(updateStatus: UpdateCountyStatusModel) {
    return this.PUT_Request(apiUrls.county.updateStatus(), updateStatus)
  }
  public updateCountyWebsite(updateCountyWebsite: UpdateCountyWebsitePayload) {
    return this.PUT_Request(apiUrls.county.updateCountyWebsite(), updateCountyWebsite)
  }
}
