import { Chip, Divider, Grid } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AppAutoComplete, AppBox, AppButton, AppIcon, AppTypography } from 'components'
import { InfoBox } from 'components/shared/info-box/InfoBox'
import { TagsListLoader } from 'components/shared/loaders'
import { ApiQueryKeys, InfoBoxSettingTypes, TagTypes } from 'globals/constants'
import { useCrmContactGroupTags } from 'hooks'
import { FC } from 'react'
import { Form } from 'react-hook-form'
import { TagApi, TagPayloadModel } from 'sdk'

interface Props {
  contactId: string
}

export const CrmContactLinkedtagsHelpModule: FC<Props> = ({ contactId }) => {
  const tagApi = new TagApi()

  const {
    getAllTags,
    addLinkedTag,
    handleDeleteParkTag,
    isTagsLoading,
    isContactTagsLoading,
    isAddingTag,
    unlinkedTags,
    linkedTags,
    control,
    reset,
    tempTag,
    setTempTag,
    clearErrors,
    setError,
  } = useCrmContactGroupTags(contactId)

  const { mutateAsync: addTag, isPending: isAddingNewTag } = useMutation({
    mutationFn: (data: TagPayloadModel) => tagApi.AddTag(data),
    mutationKey: [ApiQueryKeys.tags.AddTag],
    onSuccess: (id: string) => {
      getAllTags()
      addLinkedTag({ CrmContactsTableId: contactId, TagsTableId: id, Type: TagTypes.GROUP_TAG })
    },
  })

  return (
    <AppBox paddingY={1} display={'flex'} flexDirection={'column'} gap={1}>
      <InfoBox dismissable={true} settingUniqueKey={InfoBoxSettingTypes.ContactLinkedTagsDismissed}>
        Group Tags help to organize contacts. Use the search field to add <b>Group Tags</b> that have been set up in
        your <b>CRM Setting.</b>
      </InfoBox>
      <AppBox paddingTop={1}>
        <Form control={control} onSubmit={({ data }) => addLinkedTag(data)}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={9}>
              <AppAutoComplete
                label="Group Tags"
                options={unlinkedTags ?? []}
                fullWidth
                placeholder="Search Tags"
                control={control}
                name={'TagsTableId'}
                required
                hideDefaultErrorText={false}
                isLoading={isAddingTag || isAddingNewTag}
                onChange={(value) => {
                  setTempTag(value.target.value.toString())
                  clearErrors()
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    reset({ CrmContactsTableId: contactId, Type: TagTypes.GROUP_TAG })
                    if (!!tempTag && !unlinkedTags?.find((x) => x.value === tempTag)) {
                      addTag(new TagPayloadModel({ Tag: tempTag, TagType: TagTypes.GROUP_TAG }))
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AppButton isLoading={isAddingTag || isAddingNewTag} variant="contained" size="large" type="submit">
                Save
              </AppButton>
            </Grid>
          </Grid>
        </Form>
      </AppBox>
      <Divider />
      <AppBox>
        <AppTypography fontSize={14} fontWeight={500}>
          Group Tags
        </AppTypography>

        <AppBox
          marginTop={2}
          backgroundColor="grey.50"
          minHeight={'150px'}
          borderRadius={5}
          border={1}
          borderColor={'grey.200'}
        >
          <AppBox padding={4}>
            {isContactTagsLoading || isTagsLoading ? (
              <TagsListLoader />
            ) : (
              linkedTags?.map((tag, _index) => {
                return (
                  <Chip
                    key={_index}
                    label={tag.Tag}
                    variant="outlined"
                    style={{ backgroundColor: 'white', color: '#64748B', margin: '4px', fontWeight: 500 }}
                    deleteIcon={
                      <AppBox fontSize={12}>
                        <AppIcon size="small" icon={'octicon:x-16'} color="#64748B" />
                      </AppBox>
                    }
                    onDelete={() => {
                      handleDeleteParkTag(tag.Id)
                    }}
                  />
                )
              })
            )}
          </AppBox>
        </AppBox>
      </AppBox>
    </AppBox>
  )
}
