import { AppBox, AppTypography } from 'components'
import { AppTextArea } from 'components/base/text-area/AppTextArea'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import { ReportParkPayload } from 'sdk'

interface Props {
  control: Control<ReportParkPayload>
}

export const UpdateParkCommentSection: FC<Props> = ({ control }) => {
  return (
    <AppBox borderRadius={3} border={1} borderColor={'grey.200'} paddingX={2} paddingY={1} backgroundColor="grey.50">
      <AppTypography fontWeight={'bold'} fontSize={16}>
        Additional Comments
      </AppTypography>
      <AppTextArea
        inputProps={{ style: { height: '10%' } }}
        size="small"
        name="comments"
        control={control}
        placeholder="Type Comments"
      />
    </AppBox>
  )
}
