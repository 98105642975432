import { UserStatuses } from 'globals/constants'

export class SessionModel {
  public Email: string | null = null
  public RecoveryEmail: string | null = null
  public AccountId: number | null = null
  public Expiry: string | null = null
  public FirstName: string | null = null
  public LastName: string | null = null
  public JwtToken: string | null = null
  public PhoneNumber: string | null = null
  public RememberMe = false
  public DisclaimerAccepted: boolean | string | null = null
  public UserId: string | null = null
  public ProfileImage: string | null = null
  public UserName: string | null = null
  public Status: UserStatuses | null = null

  constructor(data?: Partial<SessionModel>) {
    Object.assign(this, data)
  }
}
