import { useMutation } from '@tanstack/react-query'
import { ApiQueryKeys, ParkSitesTools } from 'globals/constants'
import { useSelector } from 'react-redux'
import { ParkSitesApi } from 'sdk'
import { ParkSiteToolSliceSelector } from 'store/slices'

export const useParksListLoadMore = () => {
  const parkSiteApi = new ParkSitesApi()
  const { openedTool } = useSelector(ParkSiteToolSliceSelector)
  const mutationKey = () => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return ApiQueryKeys.parksites.labelledParks.LabelledParksLoadMore
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return ApiQueryKeys.parksites.taggedParks.TaggedParksLoadMore
    } else {
      return ApiQueryKeys.parksites.parksWithCrm.ParksWithCrmLoadMore
    }
  }
  const mutationFn = () => {
    if (openedTool === ParkSitesTools.PinnedParks) {
      return parkSiteApi.pinnedParksLoadMore()
    } else if (openedTool === ParkSitesTools.TaggedParks) {
      return parkSiteApi.taggedParksLoadMore()
    } else {
      return parkSiteApi.crmParksLoadMore()
    }
  }

  const { mutate: LoadMore } = useMutation({
    mutationKey: [mutationKey()],
    mutationFn: () => mutationFn(),
  })
  return { LoadMore }
}
