import { Divider } from '@mui/material'
import { AppBox, AppButton, AppCard, AppDialog, AppTypography } from 'components'
import { usePinnedParks } from 'hooks'
import { AddEditParkPinForm } from './AddEditParkPinForm'
import { ParkPinsTable } from './ParkPinsTable'

export const PinnedParksManage = () => {
  const {
    handleSubmit,
    handleFormSubmit,
    isAddingLabel,
    isEditingLabel,
    setIdToEdit,
    control,
    watch,
    idToEdit,
    ParkLabels,
    handleDeleteParkPin,
    setValue,
    dialogRef,
    isGettingLabels,
  } = usePinnedParks()
  return (
    <>
      <AppBox paddingTop={2} paddingX={1.5} paddingBottom={2}>
        <AppCard variant="outlined" raised>
          <AppBox height={'63vh'} paddingX={1} display={'flex'} flexDirection={'column'} gap={1}>
            <AppBox
              minHeight={'5vh'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <AppTypography fontWeight={'bold'} fontSize={14}>
                Custom Map Pins
              </AppTypography>
              <AppDialog
                ref={dialogRef}
                title={idToEdit ? 'Edit Pin' : 'Add Pin'}
                appDialogWrapperProps={{
                  component: 'form',
                  role: 'form',
                  onSubmit: handleSubmit((data) => handleFormSubmit(data)),
                }}
                submitButtonProps={{
                  isLoading: isAddingLabel || isEditingLabel,
                }}
                renderElement={(openDialog) => {
                  return (
                    <AppButton
                      prefixIcon="ic:baseline-plus"
                      variant="contained"
                      onClick={() => {
                        setIdToEdit('')
                        openDialog()
                      }}
                    >
                      Add New
                    </AppButton>
                  )
                }}
                renderChild={() => <AddEditParkPinForm control={control} watch={watch} isEdit={!!idToEdit} />}
              ></AppDialog>
            </AppBox>
            <Divider />
            <AppBox overflow={'auto'} minHeight={'56vh'}>
              <ParkPinsTable
                data={ParkLabels ?? []}
                isLoading={isGettingLabels}
                onDeleteClick={(id) => {
                  handleDeleteParkPin(id)
                }}
                onEditClick={(id, row) => {
                  setIdToEdit(id)
                  if (dialogRef.current) {
                    setValue('Color', row.Color)
                    setValue('Label', row.Label)
                    dialogRef.current.openDialog!()
                  }
                }}
              />
            </AppBox>
          </AppBox>
        </AppCard>
      </AppBox>
    </>
  )
}
