import { Grid, Skeleton } from '@mui/material'
import { AppBox } from 'components/base'
export const ParkListLoader = () => {
  return (
    <>
      {/* <Skeleton variant="rounded" width={'100%'} height={40} /> */}

      {Array.from(new Array(5)).map((x, index) => (
        <Grid container paddingX={1} marginTop={2} gap={1} key={index}>
          <Grid item xs={2}>
            <Skeleton variant="rounded" width={'100%'} height={80} />
          </Grid>
          <Grid item xs={9}>
            <AppBox display={'flex'} flexDirection={'column'} gap={1}>
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={15} />
              <Skeleton variant="rounded" height={15} />
            </AppBox>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
