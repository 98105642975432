import { AppBox } from 'components'
import { ReviewsTable } from './ReviewsTable'
import { useMutation } from '@tanstack/react-query'
import { FeedBackApi, PaginationFilterModel } from 'sdk'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { ApiQueryKeys } from 'globals/constants'
import { useSnackbar } from 'notistack'

interface Props {
  filter: PaginationFilterModel
  setFilter: Dispatch<SetStateAction<PaginationFilterModel>>
}
export const ReviewsListing: FC<Props> = ({ filter, setFilter }) => {
  const feedbackApi = new FeedBackApi()
  const { enqueueSnackbar } = useSnackbar()
  const {
    mutate,
    data,
    isPending: isLoading,
  } = useMutation({
    mutationFn: () => feedbackApi.GetAllFeedBacks(filter),
    mutationKey: [ApiQueryKeys.feedback.getFeedbacks],
    onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
  })
  useEffect(() => {
    mutate()
  }, [])
  useEffect(() => {
    mutate()
  }, [filter])
  return (
    <>
      <AppBox
        paddingY={2}
        minHeight={'inherit'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <ReviewsTable
          data={data?.Data ?? []}
          totalRecords={data?.TotalCount ?? 0}
          isLoading={isLoading}
          paginationFilter={filter}
          onCurrentPageChange={(value) => setFilter((prev) => ({ ...prev, PageSize: value }))}
          onPageSizeChange={(value) => setFilter((prev) => ({ ...prev, PageSize: value }))}
        />
      </AppBox>
    </>
  )
}
