import { ColumnDef, Row } from '@tanstack/react-table'
import { AppBox, AppIcon, AppTable, AppTableProps, AppTypography, SimpleCheckBox } from 'components'
import { CrmHelpModules, appRoutes } from 'globals/constants'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { CrmCompanyViewModel } from 'sdk'
import { useAppDispatch } from 'store/app.store'
import { setCrmListingHelpModule } from 'store/slices'

interface Props extends AppTableProps<CrmCompanyViewModel> {}
export const CrmCompanyTable: FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const navigateToDetailRoute = (companyId: string) => {
    navigate(appRoutes.crmCompanyDetail.path.replace(':companyId', companyId))
  }

  const columns: ColumnDef<CrmCompanyViewModel>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <SimpleCheckBox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.getToggleAllRowsSelectedHandler()(e)
            },
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <SimpleCheckBox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },

    {
      header: 'Name',
      cell: ({ row }) => {
        return (
          <AppTypography onClick={() => navigateToDetailRoute(row.original.Id!)} component={'a'}>
            {row.original.Name}
          </AppTypography>
        )
      },
    },
    {
      header: 'Address',
      accessorKey: 'Address',
    },

    {
      header: 'Phone',
      accessorKey: 'Phone',
    },
    {
      header: 'Alt Phone',
      accessorKey: 'AltPhone',
    },

    ...[
      { header: 'Contacts', icon: 'mdi:company', helpModule: CrmHelpModules.CompanyContacts },
      { header: 'Notes', icon: 'icon-park-solid:notes', helpModule: CrmHelpModules.CompanyNotes },
    ].map((x) => {
      return {
        header: x.header,
        cell: ({ row }: { row: Row<CrmCompanyViewModel> }) => {
          return (
            <AppBox color={'primary.200'}>
              <AppIcon
                icon={x.icon}
                size="medium"
                onClick={() =>
                  dispatch(
                    setCrmListingHelpModule({
                      key: x.helpModule,
                      options: { Title: row.original.Name, Id: row.original.Id },
                    })
                  )
                }
              />
            </AppBox>
          )
        },
      }
    }),
  ]
  return <AppTable columns={columns} {...props} />
}
