import Drawer, { DrawerProps } from '@mui/material/Drawer'
import * as React from 'react'
import { AppBox, DrawerControls } from '../..'
import { useState } from 'react'

interface AppDrawerProps extends Omit<DrawerProps, 'onClose' | 'open'> {
  onClose?: () => void
  title?: string
  showDrawerControls?: boolean
  renderElement: (openDialog: () => void, isOpen: boolean) => React.ReactNode
  renderChild?: (closeDialog: () => void) => React.ReactNode
}

export const AppDrawer = React.forwardRef(
  ({ showDrawerControls, title, onClose, renderElement, renderChild, ...props }: AppDrawerProps, ref) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      if (onClose) {
        onClose()
      }
      setIsDrawerOpen(false)
    }

    const openDrawer = () => {
      setIsDrawerOpen(true)
    }
    const closeDrawer = () => {
      setIsDrawerOpen(false)
    }

    const onDrawerClose = () => {
      setIsDrawerOpen(false)
      if (onClose) {
        onClose()
      }
    }
    if (ref) {
      ;(ref as any).current = { openDrawer, closeDrawer }
    }
    return (
      <AppBox>
        {renderElement(openDrawer, isDrawerOpen)}
        {isDrawerOpen && (
          <Drawer
            ModalProps={{ container: document.getElementById('root') }}
            variant={props.variant ?? 'temporary'}
            open={isDrawerOpen}
            {...props}
            onClose={toggleDrawer}
          >
            {showDrawerControls && <DrawerControls onClose={() => onDrawerClose()} />}
            <AppBox padding={0} height={'100%'} width={window.innerWidth * 0.2}>
              {renderChild ? renderChild(closeDrawer) : props.children}
            </AppBox>
          </Drawer>
        )}
      </AppBox>
    )
  }
)
