import { BaseApi } from 'sdk/api-services/base.api'
import { apiUrls } from 'sdk/api-urls'
import { CrmNotePayload, CrmNoteViewModel } from 'sdk/models'

const baseApi = new BaseApi()
export class CrmNotesApi {
  public getCrmNotes(type: 'Contact' | 'Company', typeId: string) {
    return baseApi.GET_Request<CrmNoteViewModel[]>(apiUrls.crm.notes.getNotes(type, typeId))
  }
  public addCrmNote(type: 'Contact' | 'Company', typeId: string, payload: CrmNotePayload) {
    return baseApi.POST_Request(apiUrls.crm.notes.addNote(type, typeId), payload)
  }
  public updateCrmNote(noteId: string, payload: CrmNotePayload) {
    return baseApi.PUT_Request(apiUrls.crm.notes.updateNote(noteId), payload)
  }
  public deleteCrmNote(noteId: string) {
    return baseApi.DELETE_Request(apiUrls.crm.notes.deleteNote(noteId))
  }
}
