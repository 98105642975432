import { Box, IconButton, Paper } from '@mui/material'
import { AppBox, AppIcon, AppTypography } from 'components'
import { imageToBase64 } from 'globals/functions'
import { useSnackbar } from 'notistack'
import { cloneElement, FC, useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDropzone } from 'react-dropzone'
import { UseFormReset } from 'react-hook-form'
import { ParkImageInternal, ParkSiteImagesPayload, UserPersonalInfoViewModel } from 'sdk'

interface Props {
  parkImages: ParkImageInternal[]
  imagePayload?: ParkSiteImagesPayload
  reset: UseFormReset<ParkSiteImagesPayload>
}

const generateRandomId = () => {
  return Math.floor(10000000 + Math.random() * 90000000).toString()
}

export const UpdateParkParkImages: FC<Props> = ({ parkImages, imagePayload, reset }) => {
  const [images, setImages] = useState<ParkImageInternal[]>([])
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (!parkImages) return
    if (images.length === 0) {
      setImages(parkImages)
    }
  }, [parkImages])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!imagePayload) return
      for (let i = 0; i < acceptedFiles.length; i++) {
        const image = acceptedFiles[i]
        const clone = JSON.parse(JSON.stringify(imagePayload)) as ParkSiteImagesPayload
        imageToBase64(image).then((base64) => {
          const uniqueRandomKey = generateRandomId()
          clone.NewImages?.push({ file: image, Sequence: images.length + i, UniqueKey: uniqueRandomKey })
          reset(clone)
          setImages((prevImages) => [
            ...prevImages,
            {
              url: base64,
              file: image,
              uniqueId: uniqueRandomKey,
              sequence: images.length + i,
              id: null,
            } as ParkImageInternal,
          ])
        })
      }
    },
    [imagePayload, reset, images.length]
  )

  const removeImage = (img: ParkImageInternal) => {
    const newImages = images.filter((image) => image.uniqueId !== img.uniqueId)
    const indexToBeDeleted = images.findIndex((image) => image.uniqueId === img.uniqueId)
    const reordered = newImages.map((image, _i) => ({
      ...image,
      sequence: _i,
    }))
    setImages(reordered)
    const clone = JSON.parse(JSON.stringify(imagePayload)) as ParkSiteImagesPayload
    if (img.id) {
      const oldIndex = clone.OldImagesIds?.findIndex((x) => x.Id === img.id)
      if (oldIndex !== -1) {
        clone.DeletedImageIds?.push(img.id)
        clone.OldImagesIds?.splice(oldIndex!, 1)
      }
    }
    clone.NewImages =
      clone.NewImages?.map((image, _i) => ({
        ...image,
        Sequence: image.Sequence > indexToBeDeleted ? image.Sequence - 1 : image.Sequence,
      })) ?? []
    clone.OldImagesIds =
      clone.OldImagesIds?.map((image, _i) => ({
        ...image,
        Sequence: image.Sequence > indexToBeDeleted ? image.Sequence - 1 : image.Sequence,
      })) ?? []
    reset(clone)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedImages = Array.from(images)
    const [removed] = reorderedImages.splice(result.source.index, 1)
    reorderedImages.splice(result.destination.index, 0, removed)

    // Update sequence based on new order
    const updatedImages = reorderedImages.map((image, index) => ({
      ...image,
      sequence: index,
    }))
    const clone = JSON.parse(JSON.stringify(imagePayload)) as ParkSiteImagesPayload
    for (const image of updatedImages) {
      if (image.id) {
        const oldIndex = clone.OldImagesIds?.findIndex((x) => x.Id === image.id)
        if (oldIndex !== -1) {
          clone.OldImagesIds![oldIndex!].Sequence = image.sequence
        }
      } else {
        const newIndex = clone.NewImages?.findIndex((x) => x.UniqueKey === image.uniqueId)
        if (newIndex !== -1) {
          clone.NewImages![newIndex!].Sequence = image.sequence
          clone.NewImages![newIndex!].file = image.file! // Maintain the File object
        }
      }
    }
    reset(clone)
    setImages(updatedImages)
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 3 * 1024 * 1024, // 3 MB limit per image
    accept: { 'images/*': [] },
  })
  useEffect(() => {
    fileRejections.forEach((file) => {
      enqueueSnackbar(file.errors[0].message, { variant: 'error' })
    })
  }, [fileRejections])

  return (
    <AppBox borderRadius={3} border={1} borderColor={'grey.200'} padding={2} backgroundColor="grey.50">
      <AppBox display={'flex'} justifyContent={'space-between'}>
        <AppTypography fontWeight={'bold'} fontSize={16} paddingBottom={1}>
          Park Images{' '}
        </AppTypography>
        <AppTypography component={'a'} color={'primary'}>
          (Maximum allowed size limit for each image is 3 MB)
        </AppTypography>
      </AppBox>
      <Box
        {...getRootProps()}
        sx={{
          cursor: 'pointer',
          p: 2,
          border: '1px dashed',
          borderColor: 'primary.200',
          mb: 2,
          backgroundColor: 'white',
        }}
      >
        <AppBox textAlign={'center'} cursor="pointer">
          <AppTypography fontSize={15}>
            Click or drag images here in the order you wish to see them display.{' '}
          </AppTypography>
        </AppBox>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, paddingY: 1 }}
            >
              {images.map((file, index) => (
                <Draggable key={file.uniqueId} draggableId={file.uniqueId} index={index}>
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        position: 'relative',
                        width: 100,
                        height: 100,
                        borderRadius: 1,
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        component="img"
                        src={file.url}
                        alt={`Preview ${index}`}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      <IconButton
                        onClick={() => removeImage(file)}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: 'primary.200',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                        size="small"
                      >
                        <AppIcon icon={'charm:cross'} fontSize="large" />
                      </IconButton>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </AppBox>
  )
}
