import { SettingPayload, SettingViewModel } from 'sdk'
import { BaseApi } from '../base.api'

export class SettingApi extends BaseApi {
  public getAll(types: string[]) {
    const params = new URLSearchParams()
    types.forEach((item, index) => {
      params.append(`types[${index}]`, item)
    })
    return this.GET_Request<SettingViewModel[]>(`${this.ApiUrl}/Setting?${params.toString()}`)
  }
  public update(settingData: Array<SettingPayload>) {
    return this.PUT_Request<SettingViewModel>(`${this.ApiUrl}/Setting`, settingData)
  }
  public resetHints() {
    return this.POST_Request<SettingViewModel>(`${this.ApiUrl}/Setting/ResetHints`, {})
  }
  public resetTours() {
    return this.POST_Request<SettingViewModel>(`${this.ApiUrl}/Setting/ResetTours`, {})
  }
}
