import { AppAutoComplete, AppAutoCompleteProps } from 'components'
import { useStates } from 'hooks'
import { FC } from 'react'

interface Props extends Omit<AppAutoCompleteProps, 'options'> {
  BindName?: boolean
}
export const StateField: FC<Props> = ({ BindName = false, handleChange, ...props }) => {
  const { isGettingState, States } = useStates()

  const handleStateChange = (event: any) => {
    if (handleChange) {
      handleChange(event)
    }
  }
  return (
    <AppAutoComplete
      {...props}
      isLoading={isGettingState}
      handleChange={handleStateChange}
      disablePortal={false}
      options={
        States?.map((x) => {
          return { label: x.Name!, value: BindName ? x.Name : x.Id! }
        }) || []
      }
    />
  )
}
