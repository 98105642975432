import { useQuery } from '@tanstack/react-query'
import { AppBox, AppTypography, InfoBox, ParkHistoryLoader } from 'components'
import { ApiQueryKeys, ParkActivityTypes } from 'globals/constants'
import { getMDYFormattedDate } from 'globals/functions'
import { FC } from 'react'
import { ParkSitesApi } from 'sdk'

interface Props {
  parkId: string
}
export const ParkSiteHistoryHelpModule: FC<Props> = ({ parkId }) => {
  const parkSiteApi = new ParkSitesApi()

  const { data, isPending } = useQuery({
    queryKey: [ApiQueryKeys.parksites.FetchParkActivityHistory, parkId],
    queryFn: () => parkSiteApi.getParkSiteHistory(parkId),
  })

  return (
    <>
      <InfoBox dismissable={true}>
        This is the history of record changes we have in our system for this Park's Details
      </InfoBox>
      {isPending ? (
        <ParkHistoryLoader />
      ) : (
        <AppBox paddingTop={1} display={'flex'} flexDirection={'column'} gap={2}>
          {data?.map((item, index) => (
            <AppBox
              paddingX={2}
              paddingY={2}
              backgroundColor="grey.50"
              borderRadius={3}
              border={1}
              borderColor={'grey.200'}
              key={index}
            >
              <AppBox
                borderRadius={9}
                display={'inline-table'}
                paddingX={2}
                paddingTop={0.3}
                paddingBottom={0.5}
                backgroundColor={ParkActivityTypes.find((x) => x.type === item.ActivityType)?.color}
              >
                <AppTypography component={'span'} color={'white'} fontWeight={500} fontSize={14}>
                  {ParkActivityTypes.find((x) => x.type === item.ActivityType)?.text.toLocaleUpperCase()}
                </AppTypography>
              </AppBox>
              <AppBox paddingTop={2}>
                <AppTypography color={'grey.500'}>
                  Date: <b>{getMDYFormattedDate(new Date(item.Date))}</b>
                </AppTypography>
                <AppTypography color={'grey.500'}>
                  Updated By: <b>{item.FullName}</b>
                </AppTypography>
                <AppTypography color={'grey.500'}>
                  System Status: <b>{item.ParkStatus ?? '-'}</b>
                </AppTypography>
              </AppBox>
            </AppBox>
          ))}
        </AppBox>
      )}
    </>
  )
}
