import { ColumnDef } from '@tanstack/react-table'
import { AppTable, AppTableProps } from 'components'
import { FC } from 'react'
import { PeopleInsightRelatedPark } from 'sdk'

interface Props extends AppTableProps<PeopleInsightRelatedPark> {}

export const PeopleInsightUserViewRelatedParksTable: FC<Props> = ({ ...props }) => {
  const columns: ColumnDef<PeopleInsightRelatedPark>[] = [
    {
      header: 'Park Role',
      accessorKey: 'ParkRole',
    },
    {
      header: 'Park Name',
      accessorKey: 'ParkName',
    },
    {
      header: 'Address',
      accessorKey: 'Address',
    },
    {
      header: 'Phone',
      accessorKey: 'ParkPhone',
    },
    {
      header: 'MH Lots',
      accessorKey: 'MHLots',
    },
    {
      header: 'RV Lots',
      accessorKey: 'RVLots',
    },
  ]
  return <AppTable columns={columns} {...props} />
}
